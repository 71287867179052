import { Grid2, Typography } from "components";

const Section4 = () => {
  return (
    <Grid2
      container
      py={10}
      alignItems={"center"}
      spacing={3}
      sx={{
        flexDirection: {
          xs: "column",
        },
        px: {
          xs: 3,
          lg: 10,
        },
        background: "#020707",
      }}
    >
      <Grid2 size={12}>
        <Typography fontWeight={700} fontSize={32} textAlign={"center"} mb={2}>
          Our Story
        </Typography>
        <Typography textAlign={"center"} color={"#CCCDCD"} fontSize={16}>
          The idea for Gridkey was born out of the need for a comprehensive
          wealth management platform that could track and manage multi-asset
          portfolios for multiple clients and families. Frustrated by the lack
          of efficient portfolio tracking tools, we set out to create a solution
          that would streamline operations, empower advisors, and enhance the
          client experience.
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export default Section4;
