/* eslint-disable import/no-named-as-default-member */
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  Card,
  CgMathPlus,
  FormControl,
  FormControlLabel,
  GKButton,
  Grid2,
  Radio,
  RadioGroup,
  Typography,
} from "components";
import GKGaugeChart from "components/GKGaugeChart";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { ThemeContainer } from "utils/Theme";
import ClientOnboardingRiskModal from "./ClientOnboardingRiskModal";

interface Props {
  questionAnswers: any;
  setQuestionAnswers: any;
  questionValidation: any;
  setQuestionValidation: any;
  data: any;
  handleNext: any;
}

const ClientOnboardingRisk = ({
  questionAnswers,
  setQuestionAnswers,
  questionValidation,
  setQuestionValidation,
  data,
  handleNext,
}: Props) => {
  const form = useFormContext();

  const { theme } = ThemeContainer();

  const [openRiskModal, setOpenRiskModal] = useState(false);

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(`/riskprofile-request/`, { client: data }),
    mutationKey: ["RISK_PROFILE_CLIENT_REQUEST"],
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
    onSuccess: (res: any) => {
      handleNext();
      toast.success(res?.data?.message || "Action Performed Successfully");
    },
  });

  const handleOptionChange = (questionId: any, optionItem: any) => {
    const dataToSubmit = {
      question: questionId,
      option: optionItem.id,
      optionPoints: optionItem.optionPoints,
    };

    const isExistQuestion = questionAnswers
      .map((item: any) => item.question)
      .includes(dataToSubmit.question);

    if (isExistQuestion) {
      const indexToRemove = questionAnswers.findIndex(
        (item: any) => item.question === dataToSubmit.question,
      );

      if (indexToRemove !== -1) {
        questionAnswers.splice(indexToRemove, 1);
      }
    }

    setQuestionAnswers((prevValue: any) => [...prevValue, dataToSubmit]);

    const updatedValidation = { ...questionValidation, [questionId]: true };
    setQuestionValidation(updatedValidation);
  };

  return (
    <>
      {form.getValues("riskProfile") ? (
        <Grid2 container spacing={1}>
          <Grid2
            size={6}
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <Typography fontSize={24} fontWeight={600}>
              Risk Profile
            </Typography>
          </Grid2>
          <Grid2
            size={6}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={1}
          >
            <GKButton
              variant="contained"
              onClick={() => setOpenRiskModal(true)}
              startIcon={<CgMathPlus size={14} />}
            >
              Risk Assessment
            </GKButton>
          </Grid2>
          <Grid2 size={4}>
            <Card>
              <Grid2 container spacing={1}>
                <Grid2 size={12}>
                  <Typography fontSize={16} fontWeight={700}>
                    {form.getValues("personalDetails.name") || "-"}
                  </Typography>
                </Grid2>
                <Grid2 size={12}>
                  <GKGaugeChart
                    value={form.getValues("riskProfile.points")}
                    maxValue={form.getValues("riskProfile.totalPoints")}
                  />
                </Grid2>
                <Grid2 container size={12}>
                  <Grid2 size={6} mt={"60px"}>
                    <Typography
                      fontSize={12}
                      fontWeight={400}
                      color={theme.palette.secondaryText.main}
                    >
                      Risk Score{" "}
                    </Typography>
                  </Grid2>
                  <Grid2 size={6} textAlign={"right"} mt={"60px"}>
                    <Typography fontWeight={600} fontSize={16}>
                      {form.getValues("riskProfile.points")}
                    </Typography>
                  </Grid2>
                  <Grid2 size={12}>
                    <Typography
                      fontSize={10}
                      fontWeight={400}
                      color={theme.palette.secondaryText.main}
                    >
                      {`(Min Value: 0 - Max Value : ${
                        form.getValues("riskProfile.totalPoints") || 0
                      })`}
                    </Typography>
                  </Grid2>
                  <Grid2
                    size={12}
                    mt={"20px"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography
                      fontSize={12}
                      fontWeight={400}
                      color={theme.palette.secondaryText.main}
                    >
                      Risk Status
                    </Typography>
                    <Typography fontSize={16} fontWeight={600}>
                      {form.getValues("riskProfile.riskStatus")}
                    </Typography>
                  </Grid2>
                </Grid2>
              </Grid2>
            </Card>
          </Grid2>
        </Grid2>
      ) : (
        <Grid2 container spacing={1}>
          <Grid2
            size={12}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontSize={24} fontWeight={600}>
              Risk Profile
            </Typography>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              onClick={() => {
                mutate(form.getValues("id"));
              }}
            >
              Send To Client
            </LoadingButton>
          </Grid2>
          <Grid2 size={12}>
            {data?.data?.data?.data?.riskQuestions?.map(
              (dataItem: any, index: number) => {
                return (
                  <Grid2 container key={dataItem?.id}>
                    <Grid2 size={12}>
                      <FormControl>
                        <Typography
                          color={theme.palette.primary.main}
                          fontWeight={800}
                        >
                          <span>{index + 1}. </span>
                          {dataItem.questionText}
                        </Typography>
                        <RadioGroup>
                          {dataItem?.options?.map((optionItem: any) => {
                            return (
                              <FormControlLabel
                                key={optionItem?.id}
                                value={optionItem?.id}
                                onChange={() => {
                                  handleOptionChange(dataItem.id, {
                                    ...optionItem,
                                  });
                                }}
                                control={<Radio />}
                                label={optionItem?.option}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </Grid2>
                  </Grid2>
                );
              },
            )}
          </Grid2>
        </Grid2>
      )}

      {openRiskModal && (
        <ClientOnboardingRiskModal
          setOpen={setOpenRiskModal}
          open={openRiskModal}
          form={form}
        />
      )}
    </>
  );
};

export default ClientOnboardingRisk;
