import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import {
  CgMathPlus,
  Divider,
  GKButton,
  GKTextField,
  Grid2,
  IconButton,
  LuCalendarClock,
  LuSearch,
  LuStickyNote,
  LuTable2,
  LuUserPlus,
  Popover,
  RiDeleteBinLine,
  Show,
  TbLayoutKanban,
} from "components";
import GKKanban from "components/GKKanban";
import GKNoDataFound from "components/GKNoDataFound";
import { useFetchQuery } from "hooks/useQueries";
import { groupBy } from "lodash";
import { useState } from "react";
import toast from "react-hot-toast";
import { paletteLightTheme } from "utils/ColorTheme";
import { ThemeContainer } from "utils/Theme";
import { GET_LEAD_STATUS } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { FetchDataURL } from "utils/common";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import AddNewMeeting from "./AddMeeting";
import AddNewNotes from "./AddNotes";
import AddStatusAndSouceModal from "./AddStatusAndSouceModal";
import CreateLeadModal from "./CreateLeadModal";

interface BoardType {
  columns: {
    id: number;
    title: string;
    cards: any[];
  }[];
}

interface Props {
  isLeadStatus: boolean;
  setIsLeadStatus: (isLeadStatus: boolean) => void;
}

const LeadStatusPage = ({ isLeadStatus, setIsLeadStatus }: Props) => {
  const {
    state: { commonFilter },
    dispatch,
  } = useAppContext();

  const [cardActionAnchor, setCardActionAnchor] =
    useState<HTMLButtonElement | null>(null);

  const { theme } = ThemeContainer();

  const [leadModalOpen, setLeadModalOpen] = useState(false);

  const [addStatusModal, setAddStuatusModal] = useState(false);

  const [controlledBoard, setBoard] = useState<BoardType>({
    columns: [],
  });
  console.log("🚀 ~ LeadStatusPage ~ controlledBoard:", controlledBoard);

  const [isScheduleMeeting, setIsScheduleMeeting] = useState(false);

  const [meetingModal, setMeetingModal] = useState(false);

  const [leadId, setLeadId] = useState(null);

  const [openNoteModal, setOpenNoteModal] = useState(false);

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const {
    isFetched: statusFetched,
    refetch: getStatusRefetch,
    isFetching: leadStatusLoading,
  } = useFetchQuery({
    key: [QueryKeys.GET_STATUS],
    route: GET_LEAD_STATUS,
    success: (data: any) => {
      const newColumns = data?.data?.data?.map((dataItem: any) => {
        return {
          id: dataItem?.id,
          title: dataItem?.leadstatus,
          // @ts-ignore
          cards: [],
        };
      });

      setBoard({
        columns: newColumns,
      });
      refetchQuery("GET_ALL_LEADS");
    },
  });
  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const { refetch: getLeadsRefetch, isFetching: leadsLoading } = useFetchQuery({
    key: ["GET_ALL_LEADS", commonFilter],
    route: FetchDataURL.LEAD_LIST,
    requestBody: {
      search: commonFilter?.lead.search,
    },
    enabled: statusFetched,
    success: (data: any) => {
      const groupedData = groupBy(data?.data?.data, "leadStatusStr");
      const newColumns = controlledBoard?.columns?.map((dataItem) => {
        return {
          ...dataItem,
          cards:
            groupedData[dataItem?.title]?.map((item: any) => {
              return {
                ...item,
                title: item?.name,
              };
            }) || [],
        };
      });
      setBoard({
        columns: newColumns,
      });
    },
  });
  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const { mutate: updateLead } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.patch(`/lead/${data?.id}/change-status/`, {
        leadStatus: data?.leadStatus,
      }),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery(QueryKeys.GET_LEAD);
      refetchQuery("GET_ALL_LEADS");
    },
    onError: () => {
      getLeadsRefetch();
      getStatusRefetch();
      toast.error("Sorry we cannot move lead to desired status.");
    },
  });
  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const { mutate: handledConvertLead } = useMutation({
    mutationFn: (da: any) => axiosInstance.post(`/client/${da}/convert/`),
    mutationKey: ["DELETE_LEAD"],
    onSuccess: (response) => {
      refetchQuery(QueryKeys.GET_LEAD);
      setCardActionAnchor(null);
      refetchQuery("GET_ALL_LEADS");
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again.",
      );
    },
  });
  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const { mutate: handledDeleteLead } = useMutation({
    mutationFn: (da: any) => axiosInstance.delete(`/lead/${da}`),
    mutationKey: ["DELETE_LEAD"],
    onSuccess: (response) => {
      getLeadsRefetch();
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again.",
      );
    },
  });
  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const loading = leadsLoading || leadStatusLoading;

  return (
    <>
      <Grid2
        container
        spacing={1}
        p={1}
        sx={{
          "& .react-kanban-column": {
            backgroundColor: `${theme.palette.light.main}`,
            padding: 0,
          },
        }}
      >
        <Grid2 size={2}>
          <GKTextField
            placeholder={"Search..."}
            sx={{
              "& .MuiInputBase-root ": {
                height: "35px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #E3E8EF",
              },
            }}
            value={commonFilter?.lead?.search}
            onChange={(event) => {
              dispatch({
                type: "FILTER",
                data: {
                  ...commonFilter,
                  lead: {
                    ...commonFilter.lead,
                    search: event.target.value,
                  },
                },
              });
            }}
            type="search"
            slotProps={{
              input: {
                startAdornment: (
                  <LuSearch size={26} color={theme.palette.grey[500]} />
                ),
              },
            }}
          />
        </Grid2>
        <Grid2 size={10}>
          <Grid2 container justifyContent={"flex-end"} gap={1}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 8,
                gap: 4,
                padding: 4,
                border: `1px solid ${paletteLightTheme.palette.border.main}`,
              }}
            >
              <IconButton
                sx={{
                  background: !isLeadStatus
                    ? theme.palette.light.main
                    : paletteLightTheme.palette.primary.main,
                  color: !isLeadStatus
                    ? theme.palette.secondaryText.main
                    : paletteLightTheme.palette.light.main,
                  borderRadius: 2,
                  ":hover": {
                    background: !isLeadStatus
                      ? theme.palette.light.main
                      : paletteLightTheme.palette.primary.main,
                    color: !isLeadStatus
                      ? theme.palette.secondaryText.main
                      : paletteLightTheme.palette.light.main,
                  },
                }}
                onClick={() => {
                  setIsLeadStatus(!isLeadStatus);
                }}
              >
                <TbLayoutKanban />
              </IconButton>
              <IconButton
                sx={{
                  background: isLeadStatus
                    ? theme.palette.light.main
                    : paletteLightTheme.palette.primary.main,
                  color: isLeadStatus
                    ? theme.palette.secondaryText.main
                    : paletteLightTheme.palette.light.main,
                  borderRadius: 2,
                  ":hover": {
                    background: isLeadStatus
                      ? theme.palette.light.main
                      : paletteLightTheme.palette.primary.main,
                    color: isLeadStatus
                      ? theme.palette.secondaryText.main
                      : paletteLightTheme.palette.light.main,
                  },
                }}
                onClick={() => {
                  setIsLeadStatus(!isLeadStatus);
                }}
              >
                <LuTable2 />
              </IconButton>
            </div>
            <GKButton
              variant="contained"
              size="medium"
              startIcon={<CgMathPlus size={14} />}
              onClick={() => setLeadModalOpen(true)}
            >
              Add New Lead
            </GKButton>
            <GKButton
              color="dark"
              size="medium"
              variant="outlined"
              startIcon={<CgMathPlus size={14} />}
              onClick={() => setAddStuatusModal(true)}
            >
              Add New Status
            </GKButton>
          </Grid2>
        </Grid2>
        <Grid2 size={12}>
          <Divider />
        </Grid2>
        {!loading &&
        controlledBoard.columns?.map((i) => i.cards)?.flat()?.length === 0 ? (
          <Grid2
            size={12}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <GKNoDataFound />
          </Grid2>
        ) : (
          <GKKanban
            updateLead={updateLead}
            columns={controlledBoard}
            setColumns={setBoard}
            setLeadId={setLeadId}
            setCardActionAnchor={setCardActionAnchor}
          />
        )}
      </Grid2>

      {leadModalOpen && (
        <CreateLeadModal open={leadModalOpen} setOpen={setLeadModalOpen} />
      )}

      {addStatusModal && (
        <AddStatusAndSouceModal
          open={addStatusModal}
          setOpen={setAddStuatusModal}
          type={"STATUS"}
        />
      )}

      <Popover
        open={Boolean(cardActionAnchor)}
        anchorEl={cardActionAnchor}
        onClose={() => {
          setCardActionAnchor(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: "14%", borderRadius: 8 },
        }}
      >
        <Grid2 container sx={{ py: "6px" }}>
          <Grid2
            size={12}
            borderBottom={`solid 1px ${paletteLightTheme.palette.border.main}`}
          >
            <GKButton
              onClick={() => {
                handledConvertLead(leadId);
              }}
              startIcon={
                <LuUserPlus
                  size={16}
                  color={theme.palette.secondaryText.main}
                />
              }
              color="dark"
              fullWidth
              sx={{
                px: 2,
                py: 1,
                borderRadius: 0,
                justifyContent: "flex-start",
              }}
            >
              Convert to Client
            </GKButton>
          </Grid2>
          <Grid2
            size={12}
            borderBottom={`solid 1px ${paletteLightTheme.palette.border.main}`}
          >
            <GKButton
              startIcon={
                <LuCalendarClock
                  size={16}
                  color={theme.palette.secondaryText.main}
                />
              }
              color="dark"
              fullWidth
              sx={{
                px: 2,
                py: 1,
                borderRadius: 0,
                justifyContent: "flex-start",
              }}
              onClick={() => {
                setMeetingModal(true);
                setIsScheduleMeeting(false);
                setCardActionAnchor(null);
              }}
            >
              Add Meeting
            </GKButton>
          </Grid2>
          <Grid2
            size={12}
            borderBottom={`solid 1px ${paletteLightTheme.palette.border.main}`}
          >
            <GKButton
              startIcon={
                <LuStickyNote
                  size={16}
                  color={theme.palette.secondaryText.main}
                />
              }
              color="dark"
              fullWidth
              sx={{
                px: 2,
                py: 1,
                borderRadius: 0,
                justifyContent: "flex-start",
              }}
              onClick={() => {
                setOpenNoteModal(true);
                setCardActionAnchor(null);
              }}
            >
              Add Notes
            </GKButton>
          </Grid2>
          <Grid2 size={12}>
            <GKButton
              onClick={() => {
                handledDeleteLead(leadId);
                setCardActionAnchor(null);
              }}
              startIcon={
                <RiDeleteBinLine
                  size={16}
                  color={theme.palette.secondaryText.main}
                />
              }
              color="dark"
              fullWidth
              sx={{
                px: 2,
                py: 1,
                borderRadius: 0,
                justifyContent: "flex-start",
              }}
            >
              Delete
            </GKButton>
          </Grid2>
        </Grid2>
      </Popover>

      <Show.When isTrue={meetingModal}>
        <AddNewMeeting
          open={meetingModal}
          setOpen={setMeetingModal}
          leadId={leadId}
          meetingData={null}
          refetch={getLeadsRefetch}
          schedule={isScheduleMeeting}
        />
      </Show.When>

      <Show.When isTrue={openNoteModal}>
        <AddNewNotes
          open={openNoteModal}
          setOpen={setOpenNoteModal}
          leadId={leadId}
          refetch={getLeadsRefetch}
          noteData={null}
        />
      </Show.When>
    </>
  );
};

export default LeadStatusPage;
