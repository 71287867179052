import { Checkbox, FormControlLabel, Grid2 } from "@mui/material";
import { GKModalProps } from "utils/commonInterface";
import GKButton from "./GKButton";
import GKModal from "./GKModal";

interface TableColumnModalProps {
  name: string;
  columns: any;
  columnVisibilityModel: any;
  dispatch: any;
}

const TableColumnModal = (props: TableColumnModalProps & GKModalProps) => {
  const { open, setOpen, columns, columnVisibilityModel, dispatch, name } =
    props;

  const handleCheckboxChange = (field: string, checked: boolean) => {
    dispatch({
      type: "COLUMN_PERSIST",
      data: {
        ...columnVisibilityModel,
        [name]: {
          ...columnVisibilityModel[name],
          [field]: checked,
        },
      },
    });
  };

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Columns"
      footer={
        <GKButton
          color="dark"
          variant="outlined"
          onClick={() => setOpen(false)}
        >
          Close
        </GKButton>
      }
    >
      <Grid2 container>
        {columns?.map((column: any) => {
          const isChecked =
            columnVisibilityModel[name]?.[column?.field] ?? false;

          return (
            <Grid2 size={4} key={column.field}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={(event) =>
                      handleCheckboxChange(column.field, event.target.checked)
                    }
                  />
                }
                label={column?.headerName}
              />
            </Grid2>
          );
        })}
      </Grid2>
    </GKModal>
  );
};

export default TableColumnModal;
