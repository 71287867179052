import { Grid2 } from "components";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import BranchAdmins from "Pages/Organisations/BranchAdmins";
import { QueryKeys } from "utils/queryKey";

const OrganisationMenu = () => {
  // const [selectedBranchData, setSelectedBranchData] = useState(null);

  // const [addNewBranchModal, setAddNewBranchModal] = useState(false);

  // const [isEditModal, setIsEditModal] = useState(false);

  const {
    data: branchList,
    isFetching,
    isFetched,
  } = useFetchQuery({
    key: [QueryKeys.BRANCH_LIST],
    route: "/branch/",
  });

  // useFetchQuery({
  //   key: ["BRANCH_ID", branchList?.data?.data[tabValueOfOrg]?.id],
  //   route: `/branch/${branchList?.data?.data[tabValueOfOrg]?.id}/`,
  //   success: (data: any) => {
  //     setSelectedBranchData(data?.data);
  //   },
  //   enabled: isFetched,
  // });

  useFetchQuery({
    key: ["BRANCH_ID"],
    route: `/branch/${branchList?.data?.data[0]?.id}/`,
    enabled: isFetched,
  });

  return (
    <DashboardLayout title="Dashboard" loading={isFetching}>
      <Grid2 container spacing={2}>
        {/* <Grid2 size={12}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tabs
              sx={{
                "& .MuiTabs-flexContainer": {
                  backgroundColor: theme.palette.light.main,
                },
              }}
              onChange={(e, val) => {
                setTabValueOfOrg(val);
              }}
              value={tabValueOfOrg}
            >
              {branchList?.data?.data?.map((items: any, index: any) => {
                return (
                  <Tab
                    key={index}
                    label={items?.name}
                    disableRipple
                    value={index}
                  />
                );
              })}
            </Tabs>
          </Box>
        </Grid2> */}
        {/* <Grid2 size={12} container>
          <Grid2 size={6}>
            <Grid2 size={12} display={"flex"} alignItems={"center"} gap={1}>
              <Typography fontWeight={600} fontSize={20}>
                {selectedBranchData?.name || "Branch Name"}
              </Typography>
              <Typography fontWeight={500} fontSize={12}>
                {selectedBranchData?.code || " "}
              </Typography>
            </Grid2>
            <Grid2 size={12} display={"flex"} alignItems={"center"} gap={1}>
              <Typography fontWeight={400} fontSize={12} color={theme.palette.secondaryText.main}>
                Branch
              </Typography>
              <Typography fontWeight={400} fontSize={14}>
                {selectedBranchData?.headBranch || " "}
              </Typography>
            </Grid2>
          </Grid2>
          <Grid2
     
            size={6}
            justifyContent={"flex-end"}
            display={"flex"}
            alignItems={"center"}
          >
            <Button
              variant="outlined"
              color="dark"
              onClick={() => {
                setAddNewBranchModal(true);
                setIsEditModal(true);
              }}
            >
              Edit
            </Button>
          </Grid2>
        </Grid2> */}
        {/* <Grid2 size={12}>
          <Divider />
        </Grid2> */}
        {/* <Grid2 size={12}>
          <Typography fontSize={18}>Admins</Typography>{" "}
        </Grid2> */}
        <Grid2 size={12}>
          <BranchAdmins
            path={`/branch/${branchList?.data?.data[0]?.id}/employees/`}
          />{" "}
        </Grid2>
      </Grid2>

      {/* {addNewBranchModal && (
        <AddBranchModal
          open={addNewBranchModal}
          setOpen={setAddNewBranchModal}
          updateData={selectedBranchData}
          isEditModal={isEditModal}
        />
      )} */}
    </DashboardLayout>
  );
};

export default OrganisationMenu;
