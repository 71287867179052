import { TablePagination } from "@mui/material";
import { useAppContext } from "AppContext";
import { CommonFilterState } from "interfaces/CommonFilter";

interface CommonPaginationProps {
  totalCount: number;
  name?: string;
}

const CommonPagination: React.FC<CommonPaginationProps> = (props) => {
  const { totalCount, name } = props;

  const {
    state: { commonFilter },
    dispatch,
  } = useAppContext();

  const handlePageChange = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    dispatch({
      type: "FILTER",
      data: {
        ...commonFilter,
        [name]: {
          ...commonFilter[name as keyof CommonFilterState],
          page: newPage,
          skip: newPage * commonFilter[name as keyof CommonFilterState]?.take,
        },
      },
    });
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch({
      type: "FILTER",
      data: {
        ...commonFilter,
        [name]: {
          ...commonFilter[name as keyof CommonFilterState],
          take: event.target.value,
        },
      },
    });
  };

  return (
    <TablePagination
      style={{ flex: "none" }}
      component="div"
      labelRowsPerPage="Rows"
      tabIndex={-1}
      count={totalCount}
      page={commonFilter[name as keyof CommonFilterState]?.page}
      rowsPerPage={commonFilter[name as keyof CommonFilterState]?.take}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      rowsPerPageOptions={totalCount > 25 ? [25, 50, 100] : []}
      labelDisplayedRows={({ from, to, count }) => {
        const newForm =
          from > count
            ? totalCount -
              commonFilter[name as keyof CommonFilterState].take +
              1
            : from;
        return `${newForm}–${to} of ${
          count !== -1 ? count : `more than ${to}`
        }`;
      }}
    />
  );
};

export default CommonPagination;
