import { Card, FormControl, GKTextField, Grid2, Tab, Tabs } from "components";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useDocumentTitle from "utils/useDocumentTitle";
import FeeAndCommissionTab from "./FeeAndCommissionTab";
import PortfolioCommissionTab from "./PortfolioCommissionTab";

const EditCommissionManager = () => {
  useDocumentTitle("Commission Details");

  const [tab, setTab] = useState(0);

  const form = useForm({
    defaultValues: {
      partnerName: "",
      billedRevenue: "",
      joiningDate: "",
      unbilledRevenue: "",
      totalAUM: "",
      commissionPaid: "",
    },
  });

  return (
    <DashboardLayout title="Edit Commission Manager">
      <Card>
        <Grid2 container spacing={1} columnSpacing={2}>
          <Grid2 size={4}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Partner Name"
                name="partnerName"
                onChange={(e) => form.setValue("partnerName", e.target.value)}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={4}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Billed Revenue"
                name="billedRevenue"
                onChange={(e) => form.setValue("billedRevenue", e.target.value)}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={4}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Joining Date"
                name="joiningDate"
                onChange={(e) => form.setValue("joiningDate", e.target.value)}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={4}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Unbilled Revenue"
                name="unbilledRevenue"
                onChange={(e) =>
                  form.setValue("unbilledRevenue", e.target.value)
                }
              />
            </FormControl>
          </Grid2>
          <Grid2 size={4}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Total AUM"
                name="totalAUM"
                onChange={(e) => form.setValue("totalAUM", e.target.value)}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={4}>
            <FormControl variant="standard" fullWidth>
              <GKTextField
                inputLabel="Commission Paid"
                name="commissionPaid"
                onChange={(e) =>
                  form.setValue("commissionPaid", e.target.value)
                }
              />
            </FormControl>
          </Grid2>
          <Grid2 size={12} mt={2}>
            <Tabs
              variant="fullWidth"
              value={tab}
              onChange={(e, val) => setTab(val)}
            >
              <Tab disableRipple label="Portfolios" />
              <Tab disableRipple label="Fee And Commission" />
            </Tabs>
          </Grid2>
        </Grid2>
        <Grid2 size={12}>
          {tab === 0 && <PortfolioCommissionTab />}
          {tab === 1 && <FeeAndCommissionTab />}
        </Grid2>
      </Card>
    </DashboardLayout>
  );
};

export default EditCommissionManager;
