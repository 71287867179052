/* eslint-disable react/no-this-in-sfc */
/* eslint-disable func-names */
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import HighchartsSunburst from "highcharts/modules/sunburst";
import { useEffect, useState } from "react";
import { numberToCurrency } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";
import GKTypography from "./GKTypography";
import { Show } from "./Show";

HighchartsSunburst(Highcharts);

const DoughnutChart = ({ data }: any) => {
  const [hoverData, setHoverData] = useState<any>();

  const { theme } = ThemeContainer();

  const chartOptions = {
    title: {
      text: " ",
      align: "center",
      verticalAlign: "middle",
      style: {
        fontSize: "1.1em",
      },
    },

    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    chart: {
      style: {
        fontFamily: "Inter",
      },
      width: 335,
      height: 220,
    },
    exporting: {
      enabled: false,
    },
    credits: {
      text: "",
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        animation: false,
      },
      pie: {
        dataLabels: {
          enabled: false,
        },
        point: {
          events: {
            mouseOut: function () {
              setHoverData({
                name: "Portfolio Value",
                y: data?.data?.portfolioValue,
              });
            },
            mouseOver: function (this) {
              setHoverData(this);
            },
          },
        },
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "100%"],
        size: "190%",
      },
    },
    colors: [theme.palette.primary.main, theme.palette.secondary.main],
    series: [
      {
        type: "pie",
        name: " ",
        innerSize: "75%",
        data: [
          ["All Holdings", data?.data?.currentValue],
          ["Ledger Balance", data?.data?.cash],
        ],
      },
    ],
  };

  useEffect(() => {
    setHoverData({
      name: "Portfolio Value",
      y: data?.data?.portfolioValue,
    });
  }, [data]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
        alignItems: "center",
      }}
    >
      <Show.When isTrue={hoverData}>
        <div
          style={{
            position: "absolute",
            bottom: 40,
            zIndex: 1,
          }}
        >
          <GKTypography
            color={theme.palette.secondaryText.main}
            fontSize={14}
            textAlign={"center"}
          >
            {hoverData?.name}
          </GKTypography>
          <GKTypography fontWeight={600} fontSize={18} textAlign={"center"}>
            {numberToCurrency(hoverData?.y || 0)}
          </GKTypography>
        </div>
      </Show.When>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default DoughnutChart;
