import { Card, Divider, Grid2, Typography } from "@mui/material";
import { EditIcon, LuClock } from "components";
import GKButton from "components/GKButton";
import { Show } from "components/Show";
import SimpleLoader from "components/SimpleLoader";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { TiArrowShuffle } from "react-icons/ti";
import { matchDate } from "utils/helpers";
import { numberToCurrency } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";
import RevenueActionModal from "./RevenueActionModal";

const RevenueDetailsTab = (props: { portfolioId: string }) => {
  const [openActionModal, setOpenActionModal] = useState({
    open: false,
    type: "",
    modalTitle: "",
  });

  const { theme } = ThemeContainer();

  const { data, isFetching } = useFetchQuery({
    key: ["REVENUE_PORTFOLIO_WISE_DATA"],
    route: `/portfolio-revenue/${props?.portfolioId}/`,
  });

  if (isFetching) return <SimpleLoader />;

  const feeManagementData = [
    {
      name: "High Watermark",
      value: {
        amount: data?.data?.data?.highWatermark?.highWatermark || 0,
        date: data?.data?.data?.highWatermark?.date || "-",
      },
      button: {
        name: "Edit",
        icon: <EditIcon size={14} />,
        onclick: () =>
          setOpenActionModal({
            open: true,
            type: "HIGH_WATERMARK",
            modalTitle: "High Watermark History",
          }),
      },
    },
    {
      name: "Advance Fees",
      value: {
        amount: data?.data?.data?.advanceFees?.advanceAmount || 0,
        date: data?.data?.data?.advanceFees?.date || "-",
      },
      button: {
        name: "History",
        icon: <LuClock size={14} />,
        onclick: () =>
          setOpenActionModal({
            open: true,
            type: "ADVANCE_FEE_HISTORY",
            modalTitle: "Advance Fee History",
          }),
      },
    },
    {
      name: "Fee Deposit",
      value: {
        amount: data?.data?.data?.advanceDeposit?.advanceDeposit || 0,
        date: data?.data?.data?.advanceDeposit?.updatedAt || "-",
      },
      button: {
        name: "History",
        icon: <LuClock size={14} />,
        onclick: () =>
          setOpenActionModal({
            open: true,
            type: "FEE_DEPOSIT_HISTORY",
            modalTitle: "Fee Deposit History",
          }),
      },
    },
  ];

  const billingDetails = [
    {
      name: "Total Billed Amount",
      value: { amount: data?.data?.data?.totalBilled },
    },
    {
      name: "Total Fee Collected",
      value: { amount: data?.data?.data?.totalCollected },
    },
  ];

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={3}>
        <Typography
          fontSize={16}
          fontWeight={500}
          color={theme.palette.secondaryText.main}
        >
          Fee plans
        </Typography>
      </Grid2>
      <Grid2
        container
        size={9}
        spacing={2}
        display={"flex"}
        alignItems={"center"}
      >
        <Grid2 size={12}>
          <Card sx={{ p: 2 }}>
            <Grid2
              container
              spacing={1}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid2>
                <Typography
                  fontSize={18}
                  fontWeight={600}
                  color={"#121926"}
                  gap={1}
                  display={"flex"}
                  alignItems={"center"}
                >
                  {data?.data?.data?.feePlan?.feePlan || "-"}
                </Typography>
                <Typography
                  fontSize={14}
                  fontWeight={400}
                  color={theme.palette.secondaryText.main}
                >
                  {data?.data?.data?.feePlan?.id
                    ? `${matchDate(
                        data?.data?.data?.feePlan?.startDate,
                        "DD-MM-YYYY",
                      )} - ${
                        matchDate(data?.data?.data?.feePlan?.endDate) === "N/A"
                          ? "Present"
                          : matchDate(data?.data?.data?.feePlan?.endDate)
                      }`
                    : "-"}
                </Typography>
              </Grid2>
              <Grid2>
                <GKButton
                  variant="contained"
                  startIcon={<TiArrowShuffle size={14} />}
                  onClick={() =>
                    setOpenActionModal({
                      open: true,
                      type: "FEE_PLAN_HISTORY",
                      modalTitle: "Fee Plan History",
                    })
                  }
                >
                  Change Plan
                </GKButton>
              </Grid2>
            </Grid2>
          </Card>
        </Grid2>
      </Grid2>
      <Grid2 size={12} my={1}>
        <Divider />
      </Grid2>

      <Grid2 size={3}>
        <Typography
          fontSize={16}
          fontWeight={500}
          color={theme.palette.secondaryText.main}
        >
          Fee Management
        </Typography>
      </Grid2>
      <Grid2
        container
        size={9}
        spacing={2}
        display={"flex"}
        alignItems={"center"}
      >
        {feeManagementData.map((dataItem) => {
          return (
            <Grid2 size={4}>
              <Card sx={{ p: 2 }} key={dataItem?.name}>
                <Grid2
                  container
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid2 size={8}>
                    <Typography color={theme.palette.secondaryText.main}>
                      {dataItem?.name}
                    </Typography>
                  </Grid2>
                  <Grid2 size={4} textAlign={"right"}>
                    <GKButton
                      variant="outlined"
                      color="dark"
                      startIcon={dataItem?.button?.icon}
                      size="extraSmall"
                      onClick={dataItem?.button.onclick}
                    >
                      {dataItem?.button?.name}
                    </GKButton>
                  </Grid2>
                  <Grid2 size={12}>
                    <Typography
                      fontSize={18}
                      fontWeight={600}
                      color={"#121926"}
                      display={"flex"}
                      alignItems={"center"}
                      gap={1}
                    >
                      {numberToCurrency(dataItem?.value?.amount || 0)}
                      <Typography
                        fontSize={12}
                        fontWeight={500}
                        color={theme.palette.secondaryText.main}
                      >
                        {matchDate(dataItem?.value?.date, "DD-MM-YYYY")}
                      </Typography>
                    </Typography>
                  </Grid2>
                </Grid2>
              </Card>
            </Grid2>
          );
        })}
      </Grid2>
      <Grid2 size={12} my={1}>
        <Divider />
      </Grid2>

      <Grid2 size={3}>
        <Typography
          fontSize={16}
          fontWeight={500}
          color={theme.palette.secondaryText.main}
        >
          Billing Details
        </Typography>
      </Grid2>
      <Grid2
        container
        size={9}
        spacing={2}
        display={"flex"}
        alignItems={"center"}
      >
        {billingDetails.map((dataItem) => {
          return (
            <Grid2 size={6}>
              <Card sx={{ p: 2 }} key={dataItem?.name}>
                <Grid2
                  container
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid2 size={12}>
                    <Typography color={theme.palette.secondaryText.main}>
                      {dataItem?.name}
                    </Typography>
                  </Grid2>
                  <Grid2 size={12}>
                    <Typography
                      fontSize={18}
                      fontWeight={600}
                      color={theme.palette.secondaryText.main}
                      display={"flex"}
                      alignItems={"center"}
                      gap={1}
                    >
                      {numberToCurrency(dataItem?.value?.amount || 0)}
                    </Typography>
                  </Grid2>
                </Grid2>
              </Card>
            </Grid2>
          );
        })}
      </Grid2>

      <Show.When isTrue={openActionModal.open}>
        <RevenueActionModal
          open={openActionModal}
          setOpen={setOpenActionModal}
        />
      </Show.When>
    </Grid2>
  );
};

export default RevenueDetailsTab;
