import { Grid2 } from "components";
import { motion } from "framer-motion";
import { useEffect, useRef } from "react";
import IntroSection from "./IntroSection";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";

const WealthBasket = () => {
  const myRef = useRef(null);

  useEffect(() => {
    myRef.current.scrollIntoView();
  });
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Grid2 container>
        {" "}
        <div ref={myRef} />
        <Grid2 size={12}>
          <IntroSection />
        </Grid2>
        <Grid2 size={12}>
          <Section1 />
        </Grid2>
        <Grid2 size={12}>
          <Section2 />
        </Grid2>
        <Grid2 size={12}>
          <Section3 />
        </Grid2>
        <Grid2 size={12}>
          <Section4 />
        </Grid2>
      </Grid2>
    </motion.div>
  );
};

export default WealthBasket;
