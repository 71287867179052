import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  CgMathPlus,
  Divider,
  FiTrash2,
  FormControl,
  GKButton,
  GKDatePicker,
  GKModal,
  GKSearchSelect,
  GKTextField,
  Grid2,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "components";
import dayjs from "dayjs";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { GET_COMPANY_LIST } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";

interface Props {
  portfolioDetailData: any;
}

const DividendModal = (props: Props & GKModalProps) => {
  const { open, setOpen, portfolioDetailData } = props;
  const { id } = useParams<{ id: string }>();
  const { theme } = ThemeContainer();

  const [companyData, setCompanyData] = useState([]);

  const { isFetching } = useFetchQuery({
    key: ["GET_ALL_COMPANY_BY_ETF"],
    route: GET_COMPANY_LIST(["ETF"]),
    success: (data: any) => {
      setCompanyData(
        data?.data?.data.map((dataItem: any) => {
          return {
            ...dataItem,
            name: dataItem?.companyName,
            value: dataItem?.gridCode,
          };
        }),
      );
    },
  });

  const { mutate: createETFTransaction, isLoading } = useMutation({
    mutationFn: (data: any) => axiosInstance.post("/etf_transaction/", data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery(QueryKeys.PORTFOLIO_EFT_TRANSACTION_LIST);
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const form = useForm({
    defaultValues: {
      portfolio: +id || "",
      transactions: [
        {
          date: "",
          isinCode: "",
          gridCode: "",
          quantity: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "transactions",
  });

  return (
    <GKModal
      modalTitle="Add ETF Interest"
      open={open}
      setOpen={setOpen}
      width={"65%"}
      footer={
        <Grid2
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
        >
          <GKButton
            variant="outlined"
            size="medium"
            form="cash-form"
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </GKButton>
          <LoadingButton
            color="dark"
            variant="outlined"
            size="medium"
            type="submit"
            form="dividend-form"
            loading={isLoading}
          >
            Save
          </LoadingButton>
        </Grid2>
      }
    >
      <form
        id="dividend-form"
        onSubmit={form.handleSubmit((values) => createETFTransaction(values))}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            <Typography fontWeight={600} fontSize={18} color={"#202939"}>
              {portfolioDetailData?.name}
            </Typography>
            <Typography
              color={theme.palette.secondaryText.main}
              fontWeight={500}
              fontSize={12}
            >
              {portfolioDetailData?.portfolioId}
            </Typography>
          </Grid2>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2 size={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Company</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Units</TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          style={{
                            minWidth: "300px",
                          }}
                        >
                          <Controller
                            name={`transactions.${index}.gridCode`}
                            control={form.control}
                            rules={{
                              required: {
                                value: true,
                                message: "Please Select Company",
                              },
                            }}
                            render={({ field, formState: { errors } }) => (
                              <GKSearchSelect
                                {...field}
                                requiredField
                                disableClearable
                                loading={isFetching}
                                renderOption={(props, option) => (
                                  <li
                                    {...props}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 4,
                                    }}
                                  >
                                    <Typography
                                      color={theme.palette.light.main}
                                      fontSize={8}
                                      style={{
                                        background:
                                          option?.instType === "EQ"
                                            ? theme.palette.secondary.main
                                            : theme.palette.warning.main,
                                        padding: "2px 4px",
                                        borderRadius: 4,
                                      }}
                                    >
                                      {option?.instType}
                                    </Typography>
                                    <Typography whiteSpace={"nowrap"}>
                                      {option?.name}
                                    </Typography>
                                  </li>
                                )}
                                options={companyData || []}
                                getOptionLabel={(option) => {
                                  return option?.name || option;
                                }}
                                onChange={(ev, val) => {
                                  field.onChange(val ? val?.value : null);
                                  form.setValue(
                                    `transactions.${index}.isinCode`,
                                    companyData?.find(
                                      (dataItem: any) =>
                                        dataItem?.value === val?.value,
                                    )?.isin,
                                  );
                                }}
                                value={
                                  companyData?.find(
                                    (data: any) => data?.value === field.value,
                                  )?.name
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder={handleReturnAutocompletePlaceholder(
                                      {
                                        placeholder: "Select ETF",
                                        value: field.value,
                                      },
                                    )}
                                    error={
                                      !!errors?.transactions?.[index]?.gridCode
                                    }
                                    helperText={
                                      errors?.transactions?.[index]?.gridCode
                                        ?.message
                                    }
                                  />
                                )}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <FormControl variant="standard" fullWidth>
                            <Controller
                              name={`transactions.${index}.date`}
                              control={form.control}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Please Select Transaction Date",
                                },
                              }}
                              render={({ field, formState: { errors } }) => (
                                <GKDatePicker
                                  {...field}
                                  requiredField
                                  value={dayjs(field.value)}
                                  onChange={(value) => {
                                    field.onChange(
                                      dayjs(value).format("YYYY-MM-DD"),
                                    );
                                  }}
                                  disableFuture
                                  slotProps={{
                                    textField: {
                                      error:
                                        !!errors?.transactions?.[index]?.date,
                                      helperText:
                                        errors?.transactions?.[index]?.date
                                          ?.message,
                                    },
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <FormControl variant="standard" fullWidth>
                            <Controller
                              name={`transactions.${index}.quantity`}
                              control={form.control}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Please Enter Units",
                                },
                              }}
                              render={({ field, formState: { errors } }) => (
                                <GKTextField
                                  {...field}
                                  type="number"
                                  requiredField
                                  error={
                                    !!errors.transactions?.[index]?.quantity
                                  }
                                  helperText={
                                    errors.transactions?.[index]?.quantity
                                      ?.message
                                  }
                                  onChange={(e: any) =>
                                    field.onChange(e.target.valueAsNumber)
                                  }
                                />
                              )}
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            className="outline"
                            color="error"
                            disabled={fields.length === 1}
                            onClick={() => remove(index)}
                            size="small"
                          >
                            <FiTrash2 size="18" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell colSpan={4}>
                      <GKButton
                        variant="outlined"
                        size="medium"
                        onClick={() => {
                          append({
                            date: "",
                            isinCode: "",
                            gridCode: "",
                            quantity: "",
                          });
                        }}
                        startIcon={<CgMathPlus size={18} />}
                      >
                        Add ETF Interest
                      </GKButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default DividendModal;
