import { GridColDef } from "@mui/x-data-grid-pro";
import { GKClientDataGrid, GKModal } from "components";
import { useFetchQuery } from "hooks/useQueries";
import { handleCapitalize } from "utils/common";
import { GKModalProps } from "utils/commonInterface";
import { extraColumnsField, matchDate } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

interface Props {
  transactionID: number;
}

const manualEntryColumns: GridColDef[] = [
  {
    ...extraColumnsField("Date of Transaction", "date", 0, 0),
    valueFormatter: (params) => matchDate(params?.value),
  },
  {
    ...extraColumnsField("Portfolio Name", "portfolioName", 0, 0),
  },
  {
    ...extraColumnsField("Broker Name", "brokerName", 0, 0),
  },
  {
    ...extraColumnsField("Contract Note", "contractNoteNumber", 0, 0),
  },
  {
    ...extraColumnsField("Company Name", "companyName", 0, 0),
  },
  {
    ...extraColumnsField("Transaction Type", "transactionType", 0, 0),
    valueFormatter: (params) => handleCapitalize(params?.value),
  },
  {
    ...extraColumnsField("Transaction Quantity", "quantity", 0, 0),
    type: "number",
  },
  {
    ...extraColumnsField("Transaction Price", "price", 0, 0),
    type: "number",
  },
  {
    ...extraColumnsField("Brokerage", "brokerage", 0, 0),
    type: "number",
  },
  {
    ...extraColumnsField("Other Charges", "otherCharges", 0, 0),
    type: "number",
  },
  {
    ...extraColumnsField("STT", "stt", 0, 0),
    type: "number",
  },
  {
    ...extraColumnsField("GST", "gst", 0, 0),
    type: "number",
  },
  {
    ...extraColumnsField("Stamp Charges", "stampCharges", 0, 0),
    type: "number",
  },
  {
    ...extraColumnsField("Transaction Charges", "transactionCharges", 0, 0),
    type: "number",
  },
  {
    ...extraColumnsField("SEBI Turnover Charges", "sebiTurnoverFees", 0, 0),
    type: "number",
  },
];

const ManualEntryModal = (props: Props & GKModalProps) => {
  const { open, setOpen, transactionID } = props;

  const { data: manualTransactions, isFetching } = useFetchQuery({
    key: [QueryKeys.UPLOAD_MANAGER_LIST, transactionID],
    route: `/transaction_upload/${transactionID}/transactions/`,
  });

  return (
    <GKModal open={open} setOpen={setOpen} modalTitle="Manual Entry Details">
      <GKClientDataGrid
        name="manualEntry"
        columns={manualEntryColumns}
        rows={manualTransactions?.data || []}
        loading={isFetching}
      />
    </GKModal>
  );
};
export default ManualEntryModal;
