import { Permission } from "components";
import { useParams } from "react-router-dom";
import { QueryKeys } from "utils/queryKey";

const ClientPermissions = () => {
  const { id } = useParams();

  return (
    <Permission
      headerTitle="Client Permission"
      route={`/client/${id}/permissions/`}
      queryKey={QueryKeys.CLIENT_PERMISSION}
      permissionType="CLIENT"
    />
  );
};

export default ClientPermissions;
