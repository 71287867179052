import { GridColDef } from "@mui/x-data-grid-pro";
import { GKDataGrid, Typography } from "components";
import { useClientFamily } from "hooks/useClientFamily";
import { useParams } from "react-router-dom";
import {
  moneyCommaSeparator,
  moneyCommaSeparatorPoint,
} from "utils/MathFunction";
import { handleReturnPositiveNegative } from "utils/colorHelper";
import { extraColumnsField } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";

const ClientList = () => {
  useDocumentTitle("Client Family");

  const { id } = useParams();

  const { familyData, isInitialLoading } = useClientFamily();

  const ClientFamilyColumns: GridColDef[] = [
    {
      ...extraColumnsField("Family Name", "name", 150, 1),
    },
    {
      ...extraColumnsField("Net Contribution", "netContribution", 150, 1),
      valueGetter: (params) => params?.row?.netContribution || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value) || "0",
    },
    {
      ...extraColumnsField("Invested Value", "investedValue", 150, 1),
      type: "number",
      valueFormatter: (params) => moneyCommaSeparator(params.value) || "0",
    },
    {
      ...extraColumnsField("Current Value", "currentValue", 150, 1),
      valueFormatter: (params) => moneyCommaSeparator(params.value) || "0",
    },
    {
      ...extraColumnsField("Portfolio Count", "portfolioCount", 150, 1),
      type: "number",
      valueFormatter: (params) => moneyCommaSeparatorPoint(params.value) || "0",
    },
    {
      ...extraColumnsField("Unrealised Gain", "unrealisedGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.unrealisedGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { unrealisedGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(unrealisedGain)}>
            {moneyCommaSeparator(unrealisedGain)}
          </Typography>
        );
      },
    },
    // {
    //   disableColumnMenu: true,
    //   headerName: "Unrealised Gain %",
    //   flex: 1,
    //   minWidth: 150,
    //   field: "absoluteReturn",
    //   type: "number",
    //   valueGetter: (params) => params.row.absoluteReturn,
    //   valueFormatter: (params) => moneyCommaSeparator(params.value),
    //   renderCell: (value) => {
    //     return (
    //       <Typography
    //         color={
    //           handleReturnPositiveNegative(value?.row?.absoluteReturn)
    //         }
    //       >
    //         {moneyCommaSeparator(value?.row?.absoluteReturn)}%
    //       </Typography>
    //     );
    //   },
    // },
    {
      ...extraColumnsField("Today's Gain", "todaysGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { todaysGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(todaysGain)}>
            {moneyCommaSeparator(todaysGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain %", "todaysGainPercentage", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGainPercentage,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(
              value?.row?.todaysGainPercentage,
            )}
          >
            {moneyCommaSeparator(value?.row?.todaysGainPercentage)}%
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Absolute Return", "absoluteReturn", 150, 1),
      type: "number",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
  ];

  return (
    <GKDataGrid
      rows={familyData?.data || []}
      maxHeight={"calc(100vh - 228px)"}
      columns={ClientFamilyColumns}
      loading={isInitialLoading}
      zeroHolding
      name="clientFamily"
      exportFile={{
        path: `/client/${id}/families/`,
        exportName: "ClientFamilyList",
      }}
    />
  );
};

export default ClientList;
