import { GKClientDataGrid, Grid2 } from "components";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import ComplianceKYCModal from "Pages/Client/ComplianceTabs/ComplianceKYCModal";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { CLIENT_BY_ID, KYC_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { clientKYCColumns } from "utils/columns";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import KYCCard from "./KYCCard";
import KYCExtendedRow from "./KYCExtendedRow";

const ClientKYC = () => {
  useDocumentTitle("Client KYC");

  const { id } = useParams();

  const [allKYCdata, setAllKYCData] = useState<any>([]);

  const [addClientKyc, setAddClientKyc] = useState(false);

  const { isLoading, refetch } = useFetchQuery({
    key: [QueryKeys.KYC_CLIENT],
    route: KYC_BY_ID(id),
    success: (data: any) => setAllKYCData(data?.data?.data),
  });

  const { data: clientData } = useFetchQuery({
    key: [QueryKeys.CLIENT_DETAILS],
    route: CLIENT_BY_ID(id),
  });

  const handleSubmit = async (status: string) => {
    await axiosInstance
      .post(`/kyc/status/`, { kyc_id: allKYCdata?.kycId, status })
      .then((response) => {
        refetch();
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      });
  };

  return (
    <DashboardLayout title="Client KYC">
      <KYCCard
        setAddClientKyc={setAddClientKyc}
        allKYCdata={allKYCdata}
        handleSubmit={handleSubmit}
      />
      <Grid2
        mt={2}
        container
        height={allKYCdata?.documents?.length === 0 ? "100%" : "auto"}
      >
        <Grid2 size={12}>
          <GKClientDataGrid
            name="clientKYC"
            showSearch={false}
            headerGridStyle={{
              justifyContent: "flex-end",
            }}
            getDetailPanelContent={KYCExtendedRow}
            loading={isLoading}
            showHeaderGrid={false}
            columns={clientKYCColumns}
            rows={allKYCdata?.documents || []}
            rowHeight={100}
          />
        </Grid2>

        {addClientKyc && (
          <ComplianceKYCModal
            setOpen={setAddClientKyc}
            open={addClientKyc}
            showData={allKYCdata}
            refetch={refetch}
            clientData={clientData?.data}
          />
        )}
      </Grid2>
    </DashboardLayout>
  );
};

export default ClientKYC;
