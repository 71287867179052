import { Grid2, Show, Tab, Tabs } from "components";
import { PortfolioDetailsInterface } from "interfaces/Portfolio";
import React, { useState } from "react";
import useDocumentTitle from "utils/useDocumentTitle";
import EditPortfolio from "./EditPortfolio";
import BankDetails from "./EditPortfolio/BankDetails";
import DematDetails from "./EditPortfolio/DematDetails";
import NomineeDetails from "./EditPortfolio/NomineeDetails";
import StockAsCapital from "./EditPortfolio/StockAsCapital";

interface Props {
  portfolioDetailData: PortfolioDetailsInterface;
}

const PortfolioProfileTab = (props: Props) => {
  useDocumentTitle("Portfolio Profile");

  const { portfolioDetailData } = props;

  const [portfolioProfileTab, setPortfolioProfileTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    setPortfolioProfileTab(value);
  };

  const tabsConfig = [
    {
      label: "Details",
      component: <EditPortfolio portfolioDetailData={portfolioDetailData} />,
      disable: false,
    },
    {
      label: "Stock as Capital",
      component: <StockAsCapital portfolioDetailData={portfolioDetailData} />,
      disable: !portfolioDetailData.stockAsCapital,
    },
    {
      label: "Bank",
      component: <BankDetails portfolioDetailData={portfolioDetailData} />,
      disable: false,
    },
    {
      label: "Demat",
      component: <DematDetails portfolioDetailData={portfolioDetailData} />,
      disable: false,
    },
    {
      label: "Nominee",
      component: <NomineeDetails portfolioDetailData={portfolioDetailData} />,
      disable: false,
    },
  ];

  const hideTab = tabsConfig.filter((dataItem) =>
    portfolioDetailData.stockAsCapital
      ? tabsConfig
      : dataItem.label !== "Stock as Capital",
  );

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12} display={"flex"}>
        <Tabs
          className="light"
          variant="standard"
          TabIndicatorProps={{ style: { display: "none" } }}
          value={portfolioProfileTab}
          onChange={handleTabChange}
        >
          {hideTab.map((tab, index) => (
            <Tab
              key={index}
              disabled={tab?.disable}
              label={tab.label}
              disableTouchRipple
              sx={{ "& .MuiButtonBase-root": { textTransform: "none" } }}
              wrapped
            />
          ))}
        </Tabs>
      </Grid2>
      <Grid2 size={12}>
        {hideTab.map((tab, index) => (
          <Show.When key={index} isTrue={portfolioProfileTab === index}>
            {tab.component}
          </Show.When>
        ))}
      </Grid2>
    </Grid2>
  );
};

export default PortfolioProfileTab;
