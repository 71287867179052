import { LoadingButton } from "@mui/lab";
import {
  Divider,
  FormControl,
  FormControlLabel,
  GKRadio,
  GKTextField,
  Grid2,
  IconButton,
  IoEyeOffOutline,
  IoMdEye,
  RadioGroup,
  Switch,
  Typography,
} from "components";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ThemeContainer } from "utils/Theme";
import { validateNotWhitespaceOnly } from "utils/validationSchema";
import EmailConfigureStepModal from "./EmailConfigureStepModal";

interface EmailConfigFormValues {
  isDefault: boolean;
  host: string;
  senderName: string;
  email: string;
  emailHost: string;
  port: number;
  password: string;
  useTls: boolean;
  dailyEmail: string;
}

interface Props {
  data: {
    company: number;
    dailyEmail: string;
    email: string;
    footer: string;
    host: string;
    emailHost: string;
    port: number;
    id: string;
    introduction: string;
    isConnection: false;
    isDefault: boolean;
    password: string;
    senderName: string;
    useTls: boolean;
  };
  handleUpdate: any;
  isLoading: boolean;
  isFetching: boolean;
}

const EmailConfig = (props: Props) => {
  const { data, handleUpdate, isLoading, isFetching } = props;

  const { theme } = ThemeContainer();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [stepsModal, setStepModal] = useState(false);

  const form = useForm<EmailConfigFormValues>({
    defaultValues: {
      isDefault: true,
      emailHost: "",
      senderName: "",
      port: null,
      email: "",
      password: "",
      useTls: true,
      dailyEmail: "",
    },
    values: {
      isDefault: data?.isDefault,
      host: data?.host,
      port: data?.port,
      emailHost: data?.emailHost,
      senderName: data?.senderName,
      email: data?.email,
      password: data?.password,
      useTls: Boolean(data?.useTls),
      dailyEmail: data?.dailyEmail,
    },
  });

  form.watch();

  const onSubmit: SubmitHandler<EmailConfigFormValues> = (values) =>
    handleUpdate(values);

  return (
    <>
      <form id="email-config-form" onSubmit={form.handleSubmit(onSubmit)}>
        <Grid2 container spacing={2}>
          <Grid2 size={3}>
            <Typography
              fontSize={16}
              fontWeight={500}
              color={theme.palette.secondaryText.main}
            >
              Setup your own email
            </Typography>
          </Grid2>

          <Grid2
            container
            size={9}
            spacing={2}
            display={"flex"}
            alignItems={"center"}
          >
            <Grid2 size={12}>
              <Controller
                name="isDefault"
                control={form.control}
                render={({ field }) => (
                  <FormControl variant="standard" fullWidth>
                    <RadioGroup
                      {...field}
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      onChange={(e) =>
                        field.onChange(e.target.value === "true")
                      }
                    >
                      <FormControlLabel
                        value
                        control={<GKRadio />}
                        label="Use noreply@gridkey.in to send all emails"
                        sx={{ color: "#000000" }}
                      />
                      <FormControlLabel
                        value={false}
                        control={<GKRadio />}
                        label="Use my SMTP mail server to send all emails"
                        sx={{ color: "#000000" }}
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid2>
            {!form.watch("isDefault") && (
              <>
                <Grid2 size={4}>
                  <Controller
                    name="emailHost"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please Enter Email Host",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <FormControl variant="standard" fullWidth>
                          <GKTextField
                            {...field}
                            inputLabel={"Email Host"}
                            error={!!error}
                            helperText={!!error && error.message}
                          />
                        </FormControl>
                      );
                    }}
                  />
                </Grid2>
                <Grid2 size={4}>
                  <Controller
                    name="port"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please Add Port",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <FormControl variant="standard" fullWidth>
                          <GKTextField
                            {...field}
                            inputLabel={"Port"}
                            error={!!error}
                            helperText={!!error && error.message}
                          />
                        </FormControl>
                      );
                    }}
                  />
                </Grid2>
                <Grid2 size={4}>
                  <Controller
                    name="senderName"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please Enter Name",
                      },
                      validate: validateNotWhitespaceOnly,
                    }}
                    render={({ field, formState: { errors } }) => (
                      <FormControl variant="standard" fullWidth>
                        <GKTextField
                          {...field}
                          loading={isFetching}
                          inputLabel={"Name"}
                          requiredField
                          error={!!errors.senderName}
                          helperText={errors.senderName?.message}
                        />
                      </FormControl>
                    )}
                  />
                </Grid2>
                <Grid2 size={4}>
                  <Controller
                    name="email"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please Enter Email",
                      },
                    }}
                    render={({ field, formState: { errors } }) => (
                      <FormControl variant="standard" fullWidth>
                        <GKTextField
                          {...field}
                          loading={isFetching}
                          inputLabel={"Email"}
                          requiredField
                          error={!!errors.email}
                          helperText={errors.email?.message}
                        />
                      </FormControl>
                    )}
                  />
                </Grid2>
                <Grid2 size={4}>
                  <Controller
                    name="password"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please Enter Password",
                      },
                    }}
                    render={({ field, formState: { errors } }) => (
                      <FormControl variant="standard" fullWidth>
                        <GKTextField
                          {...field}
                          loading={isFetching}
                          type={showPassword ? "text" : "password"}
                          inputLabel={"Password"}
                          requiredField
                          slotProps={{
                            input: {
                              style: {
                                paddingRight: 12,
                              },
                              endAdornment: (
                                <IconButton
                                  onClick={() => {
                                    setShowPassword(!showPassword);
                                  }}
                                  style={{
                                    padding: 0,
                                  }}
                                >
                                  {!showPassword ? (
                                    <IoMdEye />
                                  ) : (
                                    <IoEyeOffOutline />
                                  )}
                                </IconButton>
                              ),
                            },
                          }}
                          error={!!errors.password}
                          helperText={errors.password?.message}
                        />
                      </FormControl>
                    )}
                  />
                </Grid2>
                <Grid2 size={4}>
                  <Controller
                    name="dailyEmail"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please Enter Daily Limit",
                      },
                    }}
                    render={({ field, formState: { errors } }) => (
                      <FormControl variant="standard" fullWidth>
                        <GKTextField
                          {...field}
                          loading={isFetching}
                          inputLabel={"Daily Limit"}
                          requiredField
                          error={!!errors.dailyEmail}
                          helperText={errors.dailyEmail?.message}
                        />
                      </FormControl>
                    )}
                  />
                </Grid2>
                <Grid2 size={12}>
                  <Controller
                    name="useTls"
                    control={form.control}
                    render={({ field }) => (
                      <FormControlLabel
                        {...field}
                        control={
                          <Switch
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                          />
                        }
                        label="TLS"
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={12}>
                  <Typography
                    fontSize={12}
                    color={theme.palette.secondaryText.main}
                    display={"flex"}
                  >
                    Don’t know which password? Follow this &nbsp;
                    <Typography
                      fontSize={12}
                      color={theme.palette.primary.main}
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() => setStepModal(true)}
                    >
                      Steps{" "}
                    </Typography>
                  </Typography>
                </Grid2>
              </>
            )}
          </Grid2>

          <Grid2 size={12} display={"flex"} justifyContent={"flex-end"} mt={1}>
            <LoadingButton
              type="submit"
              variant="contained"
              size="medium"
              form="email-config-form"
              loading={isLoading}
              onClick={(e) => e.stopPropagation()}
            >
              Save
            </LoadingButton>
          </Grid2>

          <Grid2 size={12} my={1}>
            <Divider />
          </Grid2>
        </Grid2>
      </form>

      {stepsModal && (
        <EmailConfigureStepModal open={stepsModal} setOpen={setStepModal} />
      )}
    </>
  );
};

export default EmailConfig;
