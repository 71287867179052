import {
  FormControl,
  FormControlLabel,
  GKRadio,
  Grid2,
  RadioGroup,
  Typography,
} from "components";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { ThemeContainer } from "utils/Theme";

interface PortfolioMetricsComponentProps {
  control: Control<any>;
  name: string;
  label: string;
  trueLabel: string;
  falseLabel: string;
  descriptionTrue: string;
  descriptionFalse: string;
}

const PortfolioMetricsComponent: React.FC<PortfolioMetricsComponentProps> = ({
  control,
  name,
  label,
  trueLabel,
  falseLabel,
  descriptionTrue,
  descriptionFalse,
}) => {
  const { theme } = ThemeContainer();

  return (
    <Grid2 container spacing={1} size={12} overflow={"hidden"}>
      <Grid2 size={12} mb={1}>
        <Typography
          fontSize={16}
          fontWeight={500}
          color={theme.palette.secondaryText.main}
        >
          {label}
        </Typography>
      </Grid2>
      <FormControl component="fieldset">
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <RadioGroup
              {...field}
              value={field.value ? "trueValue" : "falseValue"}
              onChange={(e) => field.onChange(e.target.value === "trueValue")}
            >
              <Grid2 container size={12} spacing={1}>
                <Grid2 size={12}>
                  <FormControlLabel
                    value="trueValue"
                    control={<GKRadio />}
                    label={
                      <Typography
                        fontSize={16}
                        fontWeight={400}
                        display="inline"
                      >
                        {trueLabel}
                      </Typography>
                    }
                  />
                  <Grid2 size={12} ml={"34px"}>
                    <Typography
                      fontSize={12}
                      color={theme.palette.secondaryText.main}
                    >
                      {descriptionTrue}
                    </Typography>
                  </Grid2>
                </Grid2>
                <Grid2 size={12}>
                  <FormControlLabel
                    value="falseValue"
                    control={<GKRadio />}
                    label={
                      <Typography fontSize={16} fontWeight={400}>
                        {falseLabel}
                      </Typography>
                    }
                  />
                  <Grid2 size={12} ml={"34px"}>
                    <Typography
                      fontSize={12}
                      color={theme.palette.secondaryText.main}
                    >
                      {descriptionFalse}
                    </Typography>
                  </Grid2>
                </Grid2>
              </Grid2>
            </RadioGroup>
          )}
        />
      </FormControl>
    </Grid2>
  );
};

export default PortfolioMetricsComponent;
