import {
  CgMathPlus,
  EditIcon,
  GKButton,
  GKClientDataGrid,
  Grid2,
  IconButton,
  IoIosSend,
  LuDownload,
  Tooltip,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { GET_ALL_MARKETING_MATERIAL_LIST } from "utils/apiRoutes";
import { extraColumnsField } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import MarketingMaterialModal from "./MarketingMaterialModal";
import MarketingMaterialSendNotificationModal from "./MarketingMaterialSendNotificationModal";

const MarketingMaterial = () => {
  useDocumentTitle("Marketing Material");

  const [addMaterialModal, setAddMaterialModal] = useState(false);

  const [addMaterialData, setAddMaterialData] = useState(null);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const { data: allMaterialData } = useFetchQuery({
    route: GET_ALL_MARKETING_MATERIAL_LIST,
    key: ["GET_ALL_MARKETING_MATERIAL"],
  });

  return (
    <Grid2 container>
      <GKClientDataGrid
        name="marketingMaterial"
        rows={allMaterialData?.data?.data || []}
        columnsButton={false}
        columns={[
          {
            ...extraColumnsField("File Name", "fileName", 0, 1),
          },
          {
            ...extraColumnsField("Marketing Text", "marketingText", 0, 1),
            renderCell: (params) => params.row.marketingText || "-",
          },
          {
            ...extraColumnsField("Actions", "actions", 0, 1),
            headerAlign: "right",
            align: "right",
            renderCell: (params) => {
              const { marketingMaterials, fileName } = params?.row;

              return (
                <Grid2
                  container
                  display={"flex"}
                  justifyContent={"flex-end"}
                  gap={1}
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setAddMaterialData(params.row);
                      setAddMaterialModal(true);
                    }}
                  >
                    <EditIcon size={18} />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setAddMaterialData(params.row);
                      setIsOpenModal(true);
                    }}
                  >
                    <IoIosSend size={20} />
                  </IconButton>
                  <Tooltip arrow title="Download">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        fetch(marketingMaterials)
                          .then((response) => response.blob())
                          .then((blob) => {
                            const link = document.createElement("a");
                            link.href = window.URL.createObjectURL(blob);
                            link.download = fileName;
                            link.click();
                          });
                      }}
                    >
                      <LuDownload />
                    </IconButton>
                  </Tooltip>
                </Grid2>
              );
            },
          },
        ]}
        headerComponent={
          <Grid2 container justifyContent={"flex-end"}>
            <GKButton
              variant="contained"
              onClick={() => {
                setAddMaterialModal(true);
                setAddMaterialData(null);
              }}
              startIcon={<CgMathPlus size={18} />}
            >
              Add Material
            </GKButton>
          </Grid2>
        }
      />

      {addMaterialModal && (
        <MarketingMaterialModal
          open={addMaterialModal}
          setOpen={setAddMaterialModal}
          updateData={addMaterialData}
        />
      )}

      {isOpenModal && (
        <MarketingMaterialSendNotificationModal
          open={isOpenModal}
          setOpen={setIsOpenModal}
          addMaterialData={addMaterialData}
        />
      )}
    </Grid2>
  );
};

export default MarketingMaterial;
