/* eslint-disable import/no-cycle */
import {
  Avatar,
  Badge,
  BadgeProps,
  Box,
  Fade,
  Grid2,
  IconButton,
  Popper,
  styled,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import ChangePasswordModal from "Pages/Authentication/ChangePasswordModal";
import axios from "axios";
import CustomeBreadCrumbs from "components/CustomeBreadCrumbs";
import ProfileMenu from "components/ProfileMenu";
import SideMenu from "components/SideMenu";
import SimpleLoader from "components/SimpleLoader";
import { motion } from "framer-motion";
import { useClickOutside } from "hooks/useClickOutside";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { TbSmartHome } from "react-icons/tb";
import { ThemeContainer } from "utils/Theme";
import { UserRoleTypes } from "utils/common";
import AvatarImg from "../assets/avatar.png";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 8,
    top: 42,
    border: `3px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    textTransform: "uppercase",
    fontWeight: 600,
  },
}));

interface AdvisorCompanyMasterLayoutProps {
  title: string;
  loading?: boolean;
  children?: React.ReactNode;
  toolbar?: React.ReactNode;
}

const AdvisorCompanyMasterLayout = (props: AdvisorCompanyMasterLayoutProps) => {
  const { theme } = ThemeContainer();

  const { toolbar, children, loading } = props;

  const { outSideModalOpen, setOutSideModalOpen, popperRef } =
    useClickOutside();

  const {
    state: { sidebarOpen, documentTitle, userData: userInfo },
    dispatch,
  } = useAppContext();

  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { mutate: handleGetOPT, isLoading: otpLoading } = useMutation({
    mutationFn: () =>
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/otp/${userInfo?.countryCode}-${userInfo?.phone}/send/`,
      ),
    onSuccess: () => {
      setOpenPasswordModal(true);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOutSideModalOpen(true);
  };

  const canBeOpen = outSideModalOpen && Boolean(anchorEl);

  const id = canBeOpen ? "transition-popper" : undefined;

  const AdvisorItems = [
    {
      name: "Company Master",
      icon: TbSmartHome,
      to: "/company-master/dashboard",
      role: [UserRoleTypes.advisor],
    },
    // {
    //   name: "Settings",
    //   icon: IoSettingsOutline,
    //   to: "/company-master/setting",
    //   role: [UserRoleTypes.advisor],
    // },
  ];

  return (
    <>
      <Grid2 container>
        <Grid2
          size={12}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          px={1}
          position={"sticky"}
          top={0}
          maxWidth={"100vw"}
          zIndex={1}
          height={"66px"}
          sx={{
            background: theme.palette.bgColor.main,
            borderBottom: "solid 1px",
            borderColor: theme.palette.grey[300],
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 15,
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "232px",
                height: "100%",
                borderRight: `1px solid ${theme.palette.grey[300]}`,
              }}
            >
              <IconButton
                className="outline"
                onClick={() => {
                  dispatch({ type: "SIDEBAR_OPEN", data: !sidebarOpen });
                  localStorage.setItem("sidebar", JSON.stringify(!sidebarOpen));
                }}
              >
                <img
                  src={
                    sidebarOpen
                      ? "/LandingPage/expandLess.svg"
                      : "/LandingPage/expandMore.svg"
                  }
                  alt=""
                />
              </IconButton>
              <img
                src={userInfo?.companyLogo || "/Logos/iconFullLogo.png"}
                alt=""
                style={{
                  transition: "all .4s linear",
                  padding: 16,
                  maxWidth: 174,
                  maxHeight: "-webkit-fill-available",
                }}
                draggable="false"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography fontWeight={600}>
                {documentTitle || "Other Assets"}
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <CustomeBreadCrumbs />

            <span>{toolbar}</span>

            <IconButton className="outline" onClick={handleClick}>
              <StyledBadge
                badgeContent={userInfo?.userRole?.charAt(0)}
                color="primary"
              >
                <Avatar
                  alt=""
                  src={
                    userInfo?.profilePic?.includes("s3")
                      ? userInfo?.profilePic
                      : AvatarImg
                  }
                  sx={{ width: 50, height: 50, border: "1px solid #eee" }}
                />
                {/* <img
                  onError={({ currentTarget }) => {
                    currentTarget.src = "/Logos/errorImg.svg";
                  }}
                  alt=""
                  src={
                    userInfo?.profilePic?.includes("s3")
                      ? userInfo?.profilePic
                      : AvatarImg
                  }
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "50%",
                  }}
                /> */}
              </StyledBadge>
            </IconButton>
          </div>
        </Grid2>
        <Grid2 container size={12} maxWidth={"100vw"}>
          <div
            style={{
              background: theme.palette.light.main,
              display: "flex",
              maxWidth: "100%",
              minWidth: "100%",
            }}
          >
            <SideMenu menuList={AdvisorItems} />
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              style={{
                flexGrow: 1,
                minWidth: 0,
                padding: "8px 16px",
                maxHeight: "calc(100vh - 82px)",
                minHeight: "calc(100vh - 82px)",
                overflow: "auto !important",
              }}
              className="overFlowAuto"
            >
              {loading ? <SimpleLoader /> : children}
            </motion.div>
          </div>
        </Grid2>
      </Grid2>
      <Popper
        id={id}
        open={outSideModalOpen}
        anchorEl={anchorEl}
        transition
        placement="bottom-start"
        style={{
          zIndex: 4,
          width: `260px`,
          marginTop: "2px",
        }}
        ref={popperRef}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              sx={{
                borderRadius: "8px",
                bgcolor: theme.palette.light.main,
                boxShadow: `4px 2px 8px 0px rgba(0, 0, 0, 0.13)`,
                border: `1px solid`,
                borderColor: theme.palette.border.main,
              }}
            >
              <ProfileMenu
                handleGetOPT={handleGetOPT}
                otpLoading={otpLoading}
              />
            </Box>
          </Fade>
        )}
      </Popper>

      {openPasswordModal && (
        <ChangePasswordModal
          open={openPasswordModal}
          setOpen={setOpenPasswordModal}
        />
      )}
    </>
  );
};

export default AdvisorCompanyMasterLayout;
