/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/destructuring-assignment */
import { GridRowParams } from "@mui/x-data-grid-pro";
import { GKClientDataGrid, Typography } from "components";
import GKCheckbox from "components/GKCheckbox";
import { handleCapitalize } from "utils/common";
import {
  moneyCommaSeparator,
  moneyCommaSeparatorPoint,
} from "utils/MathFunction";

interface Props {
  responseData: ResponseData[];
  form: any;
  freezeData: any;
  setFreezeData: any;
  holdData: any;
  setHoldData: any;
}

interface ResponseData {
  id: number;
  value: number;
  holdings: number;
  availableCash: number;
  percentage: number;
  plan: {
    allocation: number;
    amountAllocated: number;
    currentAmount: number;
    deviation: number;
    quantity: number;
    amount: number;
    cmp: number;
    "transaction type": string;
    gridCode: string;
    date: string;
    company: string;
    sector: string;
    isin: string;
    bse: string;
    nse: string;
    status: string;
    "portfolio code": string;
    "portfolio name": string;
  }[];
}

const PortfolioWiseExit = (props: Props) => {
  const { responseData, holdData, setHoldData } = props;

  const CompaniesTable = (params: GridRowParams) => {
    const { row } = params;

    return (
      <div
        style={{
          padding: 10,
        }}
      >
        <GKClientDataGrid
          name="PortfolioWiseExit"
          pagination={false}
          showHeaderGrid={false}
          density="compact"
          columns={[
            {
              field: "company",
              headerName: "Company Name",
              flex: 1,
              minWidth: 150,
              disableColumnMenu: true,
              editable: false,
            },
            // {
            //   field: "versionWeightage",
            //   headerName: "Model Weightage",
            //   flex: 1,
            //   minWidth: 150,
            //   disableColumnMenu: true,
            //   editable: false,
            //   renderCell: (value) => {
            //     return (
            //       <Typography>
            //         {Number(value?.row.versionWeightage || 0)?.toFixed(2)}%
            //       </Typography>
            //     );
            //   },
            // },
            {
              field: "portfolioWeightage",
              headerName: "Portfolio Weightage",
              flex: 1,
              minWidth: 160,
              disableColumnMenu: true,
              editable: false,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.portfolioWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              field: "transaction type",
              headerName: "Transaction Type",
              flex: 1,
              minWidth: 150,
              valueFormatter: (params) => handleCapitalize(params?.value),
              disableColumnMenu: true,
              editable: false,
            },
            {
              field: "quantity",
              headerName: "Quantity",
              valueFormatter: (params) =>
                moneyCommaSeparatorPoint(params.value),
              flex: 1,
              valueGetter: (params) => params.row.quantity || "0",
              minWidth: 100,
              disableColumnMenu: true,
              editable: false,
            },
            {
              field: "cmp",
              headerName: "CMP",
              flex: 1,
              minWidth: 130,
              disableColumnMenu: true,
              editable: false,
              valueGetter: (value) => moneyCommaSeparator(value.value),
            },
            {
              field: "amount",
              headerName: "Exit Amount",
              flex: 1,
              minWidth: 150,
              disableColumnMenu: true,
              editable: false,
              valueGetter: (value) => moneyCommaSeparator(value.value),
            },
            {
              field: "afterTradeWeightage",
              headerName: "After Trade Weightage",
              flex: 1,
              minWidth: 200,
              disableColumnMenu: true,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.afterTradeWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },

            {
              field: "hold",
              headerName: "Hold",
              editable: false,
              disableColumnMenu: true,
              align: "right",
              headerAlign: "right",
              maxWidth: 80,
              renderCell: (params) => {
                return (
                  <GKCheckbox
                    checked={
                      holdData?.filter(
                        (item: any) =>
                          item?.id === row?.id &&
                          item?.gridCode === params?.row?.gridCode,
                      )?.length === 1
                    }
                    onChange={(e) => {
                      const newData = {
                        id: row?.id,
                        cash: row?.cash,
                        gridCode: params?.row?.gridCode,
                      };

                      const filterData = holdData?.filter((dataItem: any) => {
                        return (
                          dataItem?.id === row?.id &&
                          dataItem?.gridCode !== params?.row?.gridCode
                        );
                      });

                      if (e.target.checked) {
                        setHoldData([...holdData, newData]);
                      } else {
                        setHoldData(filterData);
                      }
                    }}
                  />
                );
              },
            },
          ]}
          rows={
            row?.plan?.map((dataItem: any, i: any) => {
              return {
                ...dataItem,
                id: i,
              };
            }) || []
          }
        />
      </div>
    );
  };

  return (
    <GKClientDataGrid
      name="PortfolioWiseExit"
      showHeaderGrid={false}
      columns={[
        {
          field: "portfolioCode",
          headerName: "Portfolio Name / Code",
          flex: 1,
          disableColumnMenu: true,
          renderCell: (params) => {
            return (
              <div>
                <Typography fontWeight={700} whiteSpace={"nowrap"}>
                  {params?.row?.plan && params?.row?.plan[0][`portfolio name`]}
                </Typography>
                <Typography whiteSpace={"nowrap"}>
                  {params?.row?.plan && params?.row?.plan[0][`portfolio code`]}
                </Typography>{" "}
              </div>
            );
          },
        },

        {
          field: "ledgerBalance",
          headerName: "Ledger Balance",
          flex: 1,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { ledgerBalance } = params?.row;
            return (
              <Typography>{moneyCommaSeparator(ledgerBalance)}</Typography>
            );
          },
        },
        {
          field: "currentAmount",
          headerName: "Current Holdings",
          flex: 1,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { currentAmount } = params?.row;
            return (
              <Typography>{moneyCommaSeparator(currentAmount)}</Typography>
            );
          },
        },
        // {
        //   field: "exitPercentage",
        //   headerName: "Exit %",
        //   flex: 1,
        //   disableColumnMenu: true,
        //   renderCell: (params) => {
        //     const { exitWeightage } = params?.row;
        //     return <Typography>{Number(exitWeightage).toFixed(2)}%</Typography>;
        //   },
        // },
        {
          field: "totalSellValue",
          headerName: "Exit Amount",
          flex: 1,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { totalSellValue } = params?.row;
            return (
              <Typography>{moneyCommaSeparator(totalSellValue)}</Typography>
            );
          },
        },
        {
          field: "afterTradeLedgerBalance",
          headerName: "After Trade Ledger Balance",
          flex: 1,
          disableColumnMenu: true,
          valueGetter: (value) => moneyCommaSeparator(value.value),
        },
      ]}
      getDetailPanelContent={CompaniesTable}
      rows={
        responseData?.map((data: any, i: number) => {
          return {
            ...data,
            index: i,
          };
        }) || []
      }
    />
  );
};

export default PortfolioWiseExit;
