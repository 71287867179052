import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  FormControlLabel,
  GKEditor,
  GKModal,
  Grid2,
  Switch,
} from "components";
import { Controller } from "react-hook-form";
import { GKModalProps } from "utils/commonInterface";

interface Props {
  submit: any;
  form: any;
}

const OrderRationaleModal = (props: Props & GKModalProps) => {
  const { open, setOpen, submit, form } = props;

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Generate Order Sheet"
      footer={
        <Grid2 container display={"flex"} justifyContent={"flex-end"}>
          <LoadingButton variant="contained" onClick={submit}>
            Submit
          </LoadingButton>
        </Grid2>
      }
    >
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Controller
            control={form.control}
            name="rationale"
            render={({ field }) => {
              return (
                <FormControl variant="standard" fullWidth>
                  <GKEditor {...field} inputLabel="Rationale" />
                </FormControl>
              );
            }}
          />
        </Grid2>
        <Grid2 size={12}>
          <Controller
            control={form.control}
            name="limitOrder"
            render={({ field }) => {
              return (
                <FormControlLabel
                  control={<Switch {...field} />}
                  label="Generate order using limit value"
                />
              );
            }}
          />
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default OrderRationaleModal;
