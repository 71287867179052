import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { useParams } from "react-router-dom";
import { FAMILY_ID_BY_SUMMARY } from "utils/apiRoutes";
import { paletteLightTheme } from "utils/ColorTheme";
import { moneyCommaSeparator, numberToCurrency } from "utils/MathFunction";
import { QueryKeys } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";

const FamilyCard = () => {
  const { id } = useParams<{ id: string }>();

  const { theme } = ThemeContainer();

  const { data } = useFetchQuery({
    key: [QueryKeys.FAMILY_LIST],
    route: FAMILY_ID_BY_SUMMARY(id),
  });

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell
              sx={{
                p: "16px !important",
                borderBottom: "none",
                borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
              }}
              width={"20%"}
            >
              <Typography
                fontWeight={400}
                fontSize={12}
                color={theme.palette.secondaryText.main}
              >
                Net Contribution
              </Typography>
              <Typography fontWeight={600} fontSize={18}>
                {numberToCurrency(data?.data?.netContribution)}
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                p: "16px !important",
                borderBottom: "none",
                borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
              }}
              width={"20%"}
            >
              <Typography
                fontWeight={400}
                fontSize={12}
                color={theme.palette.secondaryText.main}
              >
                Invested Value
              </Typography>
              <Typography fontWeight={600} fontSize={18}>
                {numberToCurrency(data?.data?.investedValue)}
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                p: "16px !important",
                borderBottom: "none",
                borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
              }}
              width={"20%"}
            >
              <Typography
                fontWeight={400}
                fontSize={12}
                color={theme.palette.secondaryText.main}
              >
                Current Value
              </Typography>
              <Typography fontWeight={600} fontSize={18}>
                {numberToCurrency(data?.data?.currentValue)}
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                p: "16px !important",
                borderBottom: "none",
                borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
              }}
              width={"20%"}
            >
              <Typography
                fontWeight={400}
                fontSize={12}
                color={theme.palette.secondaryText.main}
              >
                Unrealised Gain
              </Typography>
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 4,
                }}
              >
                <Typography fontWeight={600} fontSize={18}>
                  {numberToCurrency(data?.data?.unrealisedGain)}
                </Typography>
                <Typography
                  fontSize={10}
                  color={
                    data?.data?.absoluteReturn > 0
                      ? paletteLightTheme.palette.success.main
                      : paletteLightTheme.palette.error.main
                  }
                >
                  ({moneyCommaSeparator(data?.data?.absoluteReturn)}% )
                </Typography>
              </span>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                p: "16px !important",
              }}
              width={"20%"}
            >
              <Typography
                fontWeight={400}
                fontSize={12}
                color={theme.palette.secondaryText.main}
              >
                Today&apos;s Gain
              </Typography>
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 4,
                }}
              >
                <Typography fontWeight={600} fontSize={18}>
                  {numberToCurrency(data?.data?.todaysGain)}
                </Typography>
                <Typography
                  fontSize={10}
                  color={
                    data?.data?.todaysGainPercentage > 0
                      ? paletteLightTheme.palette.success.main
                      : paletteLightTheme.palette.error.main
                  }
                >
                  ({moneyCommaSeparator(data?.data?.todaysGainPercentage)}% )
                </Typography>
              </span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FamilyCard;
