import {
  AiOutlineBranches,
  FiCommand,
  RiLineChartFill,
  SlPeople,
  TbCurrencyRupee,
} from "components";
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting";
import { useState } from "react";
import { AUMS_AGGREGATE } from "utils/apiRoutes";
import { moneyCommaSeparatorPoint, numberToCurrency } from "utils/MathFunction";
import { QueryKeys } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";
import { useFetchQuery } from "./useQueries";

exporting(Highcharts);

export const useDashboard = () => {
  const { theme } = ThemeContainer();

  const [isPortfolioTab, setIsPortfolioTab] = useState(0);

  const [isCurrentTab, setIsCurrentTab] = useState(0);

  // const [chartOptions, setChartOptions] = useState({
  //   accessibility: {
  //     enabled: false,
  //   },
  //   chart: {
  //     type: "bar",
  //     backgroundColor: theme.palette.light.main,
  //     borderRadius: 1,
  //     padding: 1,
  //     height: 400,
  //     style: {
  //       fontFamily: "Inter",
  //     },
  //   },
  //   exporting: {
  //     enabled: true,
  //   },
  //   colors: ["#00658088"],
  //   credits: {
  //     text: "",
  //     enabled: false,
  //   },
  //   title: {
  //     text: "Top 10 Available Cash",
  //     enabled: true,
  //     align: "left",
  //     style: {
  //       color: theme.palette.primary.main,
  //       fontWeight: "bold",
  //       fontSize: 18,
  //     },
  //   },
  //   tooltip: {
  //     pointPrecision: 2,
  //     valueDecimals: 2,
  //     pointFormatter(this: any) {
  //       return `<span style="color:${this.color}">\u25CF</span> ${
  //         this.series.name
  //       }: <b>${moneyCommaSeparator(this.y)}</b><br/>`;
  //     },
  //   },
  //   xAxis: {
  //     type: "category",
  //     title: {
  //       text: null,
  //     },
  //     gridLineColor: theme.palette.light?.main,
  //   },
  //   yAxis: {
  //     title: {
  //       text: "Cash",
  //     },
  //     gridLineColor: theme.palette.light?.main,
  //     labels: {
  //       overflow: "justify",
  //       formatter(this: any) {
  //         return formatYAxisLabel(this?.value);
  //       },
  //     },
  //   },
  //   legend: {
  //     reversed: true,
  //   },
  //   plotOptions: {
  //     bar: {
  //       width: 1,
  //       pointWidth: 30,
  //     },
  //     series: {
  //       animation: true,
  //       groupPadding: 0,
  //       pointPadding: 0.1,
  //       borderWidth: 0,
  //       colorByPoint: true,
  //       dataSorting: {
  //         enabled: true,
  //         matchByName: true,
  //       },
  //       type: "bar",
  //       dataLabels: {
  //         enabled: true,
  //       },
  //     },
  //   },
  //   chartData: [],
  //   series: [
  //     {
  //       name: "Cash",
  //       data: [],
  //       dataLabels: {
  //         enabled: true,
  //         formatter(this: any) {
  //           return moneyCommaSeparator(this?.y);
  //         },
  //       },
  //     },
  //   ],
  // });

  const { data: AUMData, isLoading: isCardsLoading } = useFetchQuery({
    key: [QueryKeys.DASHBOARD_AUMS],
    route: AUMS_AGGREGATE,
    // success: (data: any) => {
    //   setChartOptions({
    //     ...chartOptions,
    //     chartData: data?.data?.top10?.balance
    //       // ?.filter((item: any) => item.y !== 0)
    //       ?.sort((a: { y: number }, b: { y: number }) => b.y - a.y),
    //     series: [
    //       {
    //         ...chartOptions.series[0],
    //         name: "Cash Balance",
    //         data: data?.data?.top10?.balance
    //           ?.filter((item: any) => item.y !== 0)
    //           ?.sort((a: { y: number }, b: { y: number }) => a.y - b.y),
    //       },
    //     ],
    //   });
    // },
  });

  const { data: LineChart, isLoading: isLineLoading } = useFetchQuery({
    key: ["GET_LINECHART_DATA"],
    route: `aums/timeline/`,
  });

  const convertedData = [
    {
      id: 1,
      name: "Assets Under Management",
      value: numberToCurrency(AUMData?.data?.cards?.aum) || "-",
      icon: RiLineChartFill,
    },
    {
      id: 2,
      name: `Equity`,
      value: numberToCurrency(AUMData?.data?.cards?.equity) || "-",
      icon: FiCommand,
    },
    {
      id: 3,
      name: `Cash`,
      value: numberToCurrency(AUMData?.data?.cards?.cash) || "-",
      icon: TbCurrencyRupee,
    },
    {
      id: 3,
      name: `Portfolios`,
      value: moneyCommaSeparatorPoint(AUMData?.data?.cards?.portfolios) || "-",
      icon: AiOutlineBranches,
    },
    {
      id: 4,
      name: "Clients ",
      value: moneyCommaSeparatorPoint(AUMData?.data?.cards?.clients) || "-",
      icon: SlPeople,
      name_2: "Portfolios ",
      value_2: AUMData?.data?.cards?.portfolios || "-",
    },
  ];

  const returnTopTenData = (isPortfolioTab: number) => {
    switch (isPortfolioTab) {
      case 0:
        return AUMData?.data?.top10?.portfolios;
      case 1:
        return AUMData?.data?.top10?.clients;

      default:
        return [];
    }
  };
  const loading = isLineLoading || isCardsLoading;

  return {
    convertedData,
    AUMData,
    loading,
    isPortfolioTab,
    setIsPortfolioTab,
    isCurrentTab,
    setIsCurrentTab,
    returnTopTenData,
    theme,
    LineChart,
  };
};
