/* eslint-disable react/no-danger */
import { Grid2, Chip as MUIChip, Show, styled, Typography } from "components";
import { useFormContext } from "react-hook-form";
import { handleCapitalize } from "utils/common";
import { handleRemoveUnderscore } from "utils/StringFunctions";

interface Props {
  benchmarkList: any;
}

const Chip = styled(MUIChip)(() => {
  return {
    borderRadius: 8,
    marginRight: 8,
    marginTop: 8,
    "& .MuiChip-label": { textTransform: "capitalize" },
  };
});

const CustomReportPreview = (props: Props) => {
  const form = useFormContext();

  const { benchmarkList } = props;

  return (
    <Show.When
      isTrue={form.getValues("selectedReported")?.reportType === "template"}
    >
      {form.getValues("selectedReported.customData") && (
        <Grid2 size={12} container alignItems={"baseline"}>
          <Grid2 size={12}>
            <Typography fontWeight={600} fontSize={22}>
              Report Preferences
            </Typography>
          </Grid2>
        </Grid2>
      )}
      {form.getValues("selectedReported.customData.availableTimePeriods")
        ?.length !== 0 && (
        <Grid2 size={12} container alignItems={"baseline"}>
          <Grid2 size={4}>
            <Typography>Available Time Periods</Typography>
          </Grid2>
          <Grid2 size={8}>
            {form
              .getValues("selectedReported.customData.availableTimePeriods")
              ?.map((dataItem: any) => {
                return (
                  <Chip
                    label={handleCapitalize(handleRemoveUnderscore(dataItem))}
                  />
                );
              })}
          </Grid2>
        </Grid2>
      )}
      {form.getValues("selectedReported.customData.optionsToInclude")
        ?.length !== 0 && (
        <Grid2 size={12} container alignItems={"baseline"}>
          <Grid2 size={4}>
            <Typography>Section to includes</Typography>
          </Grid2>
          <Grid2 size={8}>
            {form
              .getValues("selectedReported.customData.optionsToInclude")
              ?.map((dataItem: string) => {
                return (
                  <Chip
                    label={handleCapitalize(handleRemoveUnderscore(dataItem))}
                  />
                );
              })}
          </Grid2>
        </Grid2>
      )}
      {form.getValues("selectedReported.customData.optionalField")?.length !==
        0 && (
        <Grid2 size={12} container alignItems={"baseline"}>
          <Grid2 size={4}>
            <Typography>Optional Field</Typography>
          </Grid2>
          <Grid2 size={8}>
            {form
              .getValues("selectedReported.customData.optionalField")
              ?.map((dataItem: string) => {
                return (
                  <Chip
                    sx={{
                      "& .MuiChip-label": {
                        textTransform: ["stt", "twrr"].includes(dataItem)
                          ? "uppercase"
                          : "capitalize",
                      },
                    }}
                    label={handleCapitalize(handleRemoveUnderscore(dataItem))}
                  />
                );
              })}
          </Grid2>
        </Grid2>
      )}
      {form.getValues("selectedReported.customData.benchmark") && (
        <Grid2 size={12} container alignItems={"baseline"}>
          <Grid2 size={4}>
            <Typography>Benchmark</Typography>
          </Grid2>
          <Grid2 size={8}>
            <Chip
              label={
                benchmarkList?.find(
                  (dataItem: any) =>
                    form.getValues("selectedReported.customData.benchmark") ===
                    dataItem.value,
                )?.name || ""
              }
            />
          </Grid2>
        </Grid2>
      )}
      {form.getValues("selectedReported.customData.benchmark") && (
        <Grid2 size={12} container alignItems={"baseline"}>
          <Grid2 size={4}>
            <Typography>Portfolio Calculation Preference</Typography>
          </Grid2>
          <Grid2 size={8}>
            <Chip
              label={
                form.getValues("selectedReported.customData.tradesPlusCash")
                  ? "Trades + Cash"
                  : "Trades"
              }
            />
          </Grid2>
        </Grid2>
      )}
      {form.getValues("selectedReported.customData.returnMetrics") && (
        <Grid2 size={12} container alignItems={"baseline"}>
          <Grid2 size={4}>
            <Typography>Return Metrics</Typography>
          </Grid2>
          <Grid2 size={8}>
            <Chip
              label={form
                .getValues("selectedReported.customData.returnMetrics")
                ?.toUpperCase()}
            />
          </Grid2>
        </Grid2>
      )}
      {form.getValues("selectedReported.customData.quote") && (
        <Grid2 size={12} container alignItems={"baseline"}>
          <Grid2 size={4}>
            <Typography>Quote</Typography>
          </Grid2>
          <Grid2 size={8}>
            <Typography>
              {handleCapitalize(
                handleRemoveUnderscore(
                  form.getValues("selectedReported.customData.quote"),
                ),
              )}
            </Typography>
          </Grid2>
        </Grid2>
      )}
      {form.getValues("selectedReported.customData.disclaimer") && (
        <Grid2 size={12} container alignItems={"baseline"}>
          <Grid2 size={4}>
            <Typography> Disclaimer </Typography>
          </Grid2>
          <Grid2 size={8} height={"350px"} overflow={"auto"} pr={1}>
            <Typography>
              <div
                dangerouslySetInnerHTML={{
                  __html: form.getValues(
                    "selectedReported.customData.disclaimer",
                  ),
                }}
              />
            </Typography>
          </Grid2>
        </Grid2>
      )}
    </Show.When>
  );
};

export default CustomReportPreview;
