import { useParams } from "react-router-dom";
import { GET_LEAD_NOTE_BY_ID } from "utils/apiRoutes";
import { QueryKeys } from "utils/queryKey";
import { useFetchQuery } from "./useQueries";

export const useLeadNotes = () => {
  const { id } = useParams<string>();

  const {
    data: getLeadNotes,
    isLoading: isLeadNotesLoading,
    refetch,
  } = useFetchQuery({
    key: [QueryKeys.NOTES_LIST],
    retry: 0,
    route: GET_LEAD_NOTE_BY_ID(id),
  });

  return { getLeadNotes, isLeadNotesLoading, refetch };
};
