import { useQueries, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import axiosInstance from "../utils/axiosInstance";

interface PropsState {
  route: string;
  requestBody?: any;
  key: any[];
  retry?: number | boolean;
  success?: any;
  keepPreviousData?: boolean;
  staleTime?: any;
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
  cacheTime?: any;
  networkMode?: any;
  headers?: any;
}

type HttpMethod = "get" | "post" | "put" | "delete" | "patch";

interface Query {
  key: any[];
  route: string;
  requestBody?: any;
  success?: any;
  enabled?: boolean;
  retry?: number | boolean;
  keepPreviousData?: boolean;
  method?: HttpMethod;
}

interface MultiQueryProps {
  queries: Query[];
}

export const useFetchQuery = <T = any>(props: PropsState) => {
  const {
    requestBody,
    route,
    key,
    retry,
    success,
    keepPreviousData = false,
    enabled,
    staleTime,
    cacheTime,
    networkMode,
    headers,
  } = props;

  return useQuery<T>({
    queryKey: key,
    retry: retry || false,
    queryFn: ({ signal }) =>
      axiosInstance.get(route, {
        params: requestBody,
        signal,
        headers: headers,
      }),
    enabled: enabled,
    staleTime: staleTime || 0,
    cacheTime: cacheTime || 0,
    keepPreviousData: keepPreviousData || false,
    networkMode: networkMode,
    onSuccess: (data) => success && success(data),
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });
};

export const useMultiQueriesFetch = (props: MultiQueryProps) => {
  const { queries } = props;

  const httpMethods: { [key: string]: any } = {
    get: axiosInstance.get,
    post: axiosInstance.post,
    put: axiosInstance.put,
    delete: axiosInstance.delete,
    patch: axiosInstance.patch,
  };

  const query = useQueries({
    queries: queries.map((query: any) => {
      const axiosMethod = httpMethods[query.method || "get"];

      return {
        queryKey: query?.key,
        retry: query?.retry || false,
        queryFn: async () =>
          axiosMethod(query?.route, {
            params: query?.requestBody,
          }),
        enabled: query?.enabled,
        keepPreviousData: query?.keepPreviousData || false,
        onSuccess: query?.success,
        onError: (error: any) => {
          toast.error(
            error?.response?.data?.message ||
              "Something went wrong, please try again.",
          );
        },
      };
    }),
  });

  return query;
};
