import { GridColDef } from "@mui/x-data-grid-pro";
import AutoImportModalParent from "Pages/AutoImport/AutoImportModalParent";
import {
  Button,
  CommonPagination,
  FiDownloadCloud,
  GKButton,
  GKDataGrid,
  Grid2,
  LuArchive,
  PiDownload,
  Show,
  Skeleton,
  StatusField,
  Typography,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useReducer, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { handleRemoveUnderscore } from "utils";
import { handleReturnTripleDots } from "utils/StringFunctions";
import { TableFiltering } from "utils/TableFilters";
import axiosInstance from "utils/axiosInstance";
import { handleCapitalize } from "utils/common";
import { extraColumnsField, matchDate } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import ManualEntryModal from "./ManualEntryModal";
import UploadManagerModal from "./UploadManagerModal";
import ViewUpload from "./UploadManagerViewUpload";
import ActionsPopover from "./UploadPopOverActions";

const UploadManager = () => {
  const navigate = useNavigate();

  useDocumentTitle("Import Manager ");

  const [openUploadManagerModal, setOpenUploadManagerModal] = useState(false);

  const [loadingStates, updateLoadingState] = useReducer(
    (oldPath: any, newStates: any) => ({
      ...oldPath,
      ...newStates,
    }),
    {
      isAutoImportLoading: false,
      isArchiveLoading: false,
      isViewFileLoading: false,
    },
  );

  const [isAutoImportModal, setIsAutoImportModal] = useState(false);

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const [clickedButtonId, setClickedButtonId] = useState<number>();

  const [viewFileFormat, setViewFileFormat] = useState<string>();

  const [viewModalData, setViewModalData] = useState<any>();

  const [isManualEntryViewModal, setIsManualEntryViewModal] = useState(false);

  const [manualEntryTransactionID, setManualEntryTransactionID] =
    useState<number>();

  const handleViewFileClick = async (
    id: number,
    fileType: string,
    fileFormat?: string,
  ) => {
    updateLoadingState({ ...loadingStates, isViewFileLoading: true });
    await axiosInstance
      ?.post(`/transaction_upload/review/?id=${id}`)
      .then((reviewResponse) => {
        setViewModalData(reviewResponse?.data?.transactions);
        setClickedButtonId(id);
        setIsViewModalOpen(true);
        setViewFileFormat(fileFormat);
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      });
    updateLoadingState({ ...loadingStates, isViewFileLoading: false });
  };

  // ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++===
  const requestBody = useGenerateQueryParams("importManager");

  // ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++===
  const { data, isFetching, refetch } = useFetchQuery({
    route: "/transaction_upload/",
    key: [QueryKeys.UPLOAD_MANAGER_LIST, requestBody],
    requestBody,
  });

  // ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++===
  const uploadManagerColumns: GridColDef[] = [
    {
      ...extraColumnsField("Uploaded Date", "uploadedOn", 130, 1),
      valueFormatter: (params) => matchDate(params?.value),
    },
    {
      ...extraColumnsField("File Name", "fileName", 320, 1),
      renderCell: (item) => {
        return loadingStates?.isViewFileLoading &&
          clickedButtonId === item?.row?.id ? (
          <Skeleton width={"100%"} animation="wave" />
        ) : (
          <Button
            variant="link"
            color="dark"
            onClick={() => {
              if (item?.row?.source === "Manual Entry") {
                setManualEntryTransactionID(item?.row?.id);
                setIsManualEntryViewModal(true);
              } else
                handleViewFileClick(
                  item?.row?.id,
                  item?.row?.source,
                  item?.row?.fileFormat,
                );
              setClickedButtonId(item?.row?.id);
            }}
          >
            <Typography
              fontWeight={600}
              style={{
                textTransform: "capitalize",
                textDecoration: "underline",
              }}
            >
              {handleReturnTripleDots({
                str: item?.row?.fileName,
              })}
            </Typography>
          </Button>
        );
      },
    },

    {
      ...extraColumnsField("File Format", "brokerFormat", 150, 1),
      valueFormatter: (params) =>
        handleCapitalize(handleRemoveUnderscore(params?.value)),
    },
    {
      ...extraColumnsField("Upload Type", "source", 150, 1),
    },
    {
      ...extraColumnsField("Records", "remarks", 110, 1),
    },
    {
      ...extraColumnsField("Status", "status", 140, 1),
      renderCell: (item) => <StatusField statusString={item?.row?.status} />,
    },
    {
      ...extraColumnsField("Uploaded By", "userUploaded", 150, 1),
    },
    {
      ...extraColumnsField("Action", "action", 90, 0),
      type: "action",
      align: "right",
      headerAlign: "right",
      renderCell: (item) => {
        return <ActionsPopover item={item.row} refetch={refetch} />;
      },
    },
  ];

  // ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++=== ===+++===
  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={data?.data?.totalCount || 0}
        name="importManager"
      />
    );
  };

  return (
    <>
      <GKDataGrid
        loading={isFetching}
        columns={uploadManagerColumns}
        rows={data?.data?.data || []}
        CustomPagination={!isFetching && cusPagination}
        maxHeight={"calc(100vh - 321px)"}
        headerComponent={
          <Grid2
            container
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            gap={1}
          >
            <GKButton
              variant="outlined"
              color="dark"
              size="medium"
              onClick={() => {
                navigate("/app/upload/archive-manager");
              }}
              startIcon={<LuArchive size={16} />}
            >
              Archive
            </GKButton>
            <GKButton
              variant="outlined"
              color="dark"
              size="medium"
              onClick={() => {
                setIsAutoImportModal(true);
              }}
              startIcon={<FiDownloadCloud size={16} />}
            >
              Set Auto Import
            </GKButton>
            <GKButton
              variant="contained"
              size="medium"
              onClick={() => {
                setOpenUploadManagerModal(true);
              }}
              className="upload-step-1"
              startIcon={<PiDownload size={16} />}
            >
              Import
            </GKButton>
          </Grid2>
        }
        exportFile={{
          path: "/transaction_upload/",
          exportName: "TransactionUpload",
        }}
        name="importManager"
        tableName="import_manager_list"
        headerFilter={<TableFiltering name="importManager" />}
      />

      {openUploadManagerModal && (
        <UploadManagerModal
          setOpen={setOpenUploadManagerModal}
          open={openUploadManagerModal}
        />
      )}
      {isAutoImportModal && (
        <AutoImportModalParent
          isModalOpen={isAutoImportModal}
          setIsModalOpen={setIsAutoImportModal}
        />
      )}
      {isManualEntryViewModal && (
        <ManualEntryModal
          open={isManualEntryViewModal}
          setOpen={setIsManualEntryViewModal}
          transactionID={manualEntryTransactionID}
        />
      )}

      <Show.When isTrue={isViewModalOpen}>
        <ViewUpload
          open={isViewModalOpen}
          setOpen={setIsViewModalOpen}
          recordData={viewModalData}
          recordID={clickedButtonId}
          recordName={
            data?.data?.data?.find((item: any) => item?.id === clickedButtonId)
              ?.fileName
          }
          recordFormat={viewFileFormat}
        />
      </Show.When>
    </>
  );
};

export default UploadManager;
