import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControl,
  GKButton,
  GKModal,
  GKSelect,
  GKTextField,
  Grid2,
  MenuItem,
} from "components";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { bankAccountSchema } from "utils/validationSchema";

interface Props {
  clientId?: any;
  refetch?: any;
  updateData?: any;
  allAccounts?: AccountsInterface;
}

interface AccountsInterface {
  bankAccounts: {
    id?: number;
    name?: string;
    type?: string;
    ifsc?: string;
    accountNum?: string;
    bank?: string;
    client?: number;
  }[];
}

const BankAccountModal = ({
  open,
  setOpen,
  updateData,
  allAccounts,
  clientId,
  refetch,
}: Props & GKModalProps) => {
  const form = useForm({
    defaultValues: {
      accountNum: updateData?.id ? updateData?.accountNum : "",
      bank: updateData?.id ? updateData?.bank : "",
      ifsc: updateData?.id ? updateData?.ifsc : "",
      name: updateData?.id ? updateData?.name : "",
      type: updateData?.id ? updateData?.type : "",
    },
    resolver: yupResolver(bankAccountSchema),
  });

  const onSubmit = async (values: any) => {
    const newAcc = updateData?.id
      ? allAccounts.bankAccounts?.map((data) => {
          if (data?.id === updateData?.id) {
            return {
              name: values?.name,
              type: values?.type,
              ifsc: values?.ifsc,
              accountNum: values?.accountNum,
              bank: values?.bank,
              client: updateData?.client,
              id: updateData?.id,
            };
          }
          return data;
        })
      : [...allAccounts.bankAccounts, { ...values, client: clientId }];
    await axiosInstance
      .patch(`/client/${clientId}/banks/`, { bankAccounts: newAcc })
      .then(() => {
        refetch();
        setOpen(false);
      });
  };

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={updateData?.id ? "Update Bank Account" : "Add Bank Account"}
      footer={
        <Grid2 container justifyContent={"flex-end"} gap={1}>
          <GKButton
            type="submit"
            variant="contained"
            size="medium"
            form="bank-account-form"
          >
            Submit
          </GKButton>
        </Grid2>
      }
    >
      <form id="bank-account-form" onSubmit={form.handleSubmit(onSubmit)}>
        <Grid2 container spacing={1}>
          <Grid2 size={6}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="bank"
                control={form.control}
                render={({ field, formState: { errors } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Bank Name"
                    error={Boolean(errors?.bank)}
                    helperText={errors?.bank?.message}
                  />
                )}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={6}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="name"
                control={form.control}
                render={({ field, formState: { errors } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Bank Account Name"
                    error={Boolean(errors?.name)}
                    helperText={errors?.name?.message}
                  />
                )}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={6}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="accountNum"
                control={form.control}
                render={({ field, formState: { errors } }) => (
                  <GKTextField
                    {...field}
                    type="number"
                    inputLabel="Bank Account Number"
                    error={Boolean(errors?.accountNum)}
                    helperText={errors?.accountNum?.message}
                  />
                )}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={6}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="type"
                control={form.control}
                render={({ field, formState: { errors } }) => (
                  <GKSelect
                    {...field}
                    inputLabel="Account Type"
                    error={Boolean(errors?.type)}
                    helperText={errors?.type?.message}
                  >
                    <MenuItem value={"Savings"}>Savings</MenuItem>
                    <MenuItem value={"Current"}>Current</MenuItem>
                  </GKSelect>
                )}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={6}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="ifsc"
                control={form.control}
                render={({ field, formState: { errors } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="IFSC Code"
                    error={Boolean(errors?.ifsc)}
                    helperText={errors?.ifsc?.message}
                  />
                )}
              />
            </FormControl>
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default BankAccountModal;
