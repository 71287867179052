import {
  AppBar,
  Box,
  Collapse,
  CssBaseline,
  Drawer,
  GKButton,
  Grid2,
  Hidden,
  IoClose,
  List,
  ListItemButton,
  ListItemText,
  MdExpandLess,
  MdExpandMore,
  RxTextAlignJustify,
  Toolbar,
} from "components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContainer } from "utils/Theme";
import Nav from "../Nav";

const Header = ({ scrollPosition }: any) => {
  const navigate = useNavigate();

  const { theme } = ThemeContainer();

  const token = localStorage?.getItem("token");

  const [showNestedMenu, setShowNestedMenu] = useState(false);

  const [showMenu, setShowMenu] = useState(false);

  return (
    <Box position={"relative"}>
      <CssBaseline />
      <AppBar
        sx={{
          background:
            scrollPosition?.scrollTop > 20 ? "#030807" : "transparent",
          transition: "background  0.4s",
          boxShadow: "none",
        }}
      >
        <Hidden mdDown>
          <Toolbar
            sx={{
              height: "83px",
              borderBottom: "1px solid #FFFFFF1A",
              paddingX: "80px !important",
            }}
          >
            <Grid2
              container
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Grid2>
                <img
                  loading="lazy"
                  src={"/LandingPage/newLogo.svg"}
                  alt="GK"
                  width={200}
                />
              </Grid2>
              <Grid2
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <Nav />
              </Grid2>
              <Grid2
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <GKButton
                  onClick={() => {
                    navigate(!token ? "/login" : `/app/dashboard`);
                  }}
                  variant="landingBtn"
                  size="large"
                >
                  {!token ? "Login / Register" : " Get Started"}
                </GKButton>{" "}
              </Grid2>
            </Grid2>
          </Toolbar>
        </Hidden>
        <Hidden mdUp>
          <Toolbar
            sx={{
              height: "83px",
              borderBottom: "1px solid #FFFFFF1A",
              paddingX: "20px !important",
            }}
          >
            <Grid2
              container
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid2>
                <img
                  loading="lazy"
                  src={"/LandingPage/newLogo.svg"}
                  alt="GK"
                  width={150}
                />
              </Grid2>
              <Grid2>
                <GKButton
                  onClick={() => {
                    setShowMenu(true);
                  }}
                >
                  <RxTextAlignJustify
                    size={24}
                    color={theme.palette.light.main}
                  />
                </GKButton>
              </Grid2>
            </Grid2>
          </Toolbar>
        </Hidden>
      </AppBar>
      <Drawer
        anchor="right"
        open={showMenu}
        onClose={() => {
          setShowMenu(false);
        }}
        sx={{
          "& .MuiDrawer-paper": {
            background: "transparent",
            backdropFilter: "blur(20px)",
          },
        }}
      >
        <Grid2 container>
          <Box
            sx={{
              width: "100vw",
            }}
          >
            <Grid2 container>
              <Grid2
                size={12}
                p={2.1}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                borderBottom={`solid 1px #323232`}
              >
                <img
                  loading="lazy"
                  src={"/LandingPage/newLogo.svg"}
                  alt="GK"
                  width={150}
                />
                <GKButton
                  onClick={() => {
                    setShowMenu(false);
                  }}
                >
                  <IoClose size={26} color={theme.palette.light.main} />
                </GKButton>
              </Grid2>
              <Grid2 size={12}>
                <List sx={{ width: "100%" }} component="nav">
                  <ListItemButton
                    onClick={() => {
                      navigate("/");
                      setShowMenu(false);
                    }}
                    sx={{ borderBottom: `1px solid #323232` }}
                  >
                    <ListItemText primary="Home" />
                  </ListItemButton>

                  <ListItemButton
                    sx={{ borderBottom: `1px solid #323232` }}
                    onClick={() => {
                      setShowNestedMenu(!showNestedMenu);
                    }}
                  >
                    <ListItemText primary="Products" />
                    {showNestedMenu ? (
                      <MdExpandLess size={20} />
                    ) : (
                      <MdExpandMore size={20} />
                    )}
                  </ListItemButton>
                  <Collapse in={showNestedMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        onClick={() => {
                          navigate("/product/wealth-wise");
                          setShowNestedMenu(false);
                          setShowMenu(false);
                        }}
                        sx={{ pl: 4, color: "#999999" }}
                      >
                        <ListItemText primary="Wealth Wise" />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          navigate("/product/wealth-basket");
                          setShowNestedMenu(false);
                          setShowMenu(false);
                        }}
                        sx={{ pl: 4, color: "#999999" }}
                      >
                        <ListItemText primary="Wealth Basket" />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          navigate("/product/wealth-partner");
                          setShowNestedMenu(false);
                          setShowMenu(false);
                        }}
                        sx={{ pl: 4, color: "#999999" }}
                      >
                        <ListItemText primary="Wealth Partner" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  <ListItemButton
                    onClick={() => {
                      navigate("/about-us");
                      setShowMenu(false);
                    }}
                    sx={{ borderBottom: `1px solid #323232` }}
                  >
                    <ListItemText primary="About Us" />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      setShowMenu(false);
                      navigate(!token ? "/login" : `/app/dashboard`);
                    }}
                    sx={{ borderBottom: `1px solid #323232` }}
                  >
                    <ListItemText
                      primary={!token ? "Login / Register" : " Get Started"}
                    />
                  </ListItemButton>
                </List>
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>
      </Drawer>
    </Box>
  );
};

export default Header;
