import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { GET_FAMILY } from "utils/apiRoutes";
import { QueryKeys } from "utils/queryKey";
import useGenerateQueryParams from "./useGenerateQueryParams";

export const useFamily = () => {
  const [isFamilyCreateModalOpen, setIsFamilyCreateModalOpen] = useState(false);

  const requestBody = useGenerateQueryParams("family");

  const { data: familyData, isLoading: isInitialLoading } = useFetchQuery({
    key: [QueryKeys.FAMILY_LIST, requestBody],
    route: GET_FAMILY,
    requestBody,
  });

  return {
    familyData: familyData?.data,
    isInitialLoading,
    isFamilyCreateModalOpen,
    setIsFamilyCreateModalOpen,
  };
};
