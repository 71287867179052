import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import {
  ConfirmDeleteModal,
  FormControl,
  GKButton,
  GKDatePicker,
  GKModal,
  GKSearchSelect,
  GKTextField,
  Grid2,
  Show,
  TextField,
  Typography,
} from "components";
import dayjs from "dayjs";
import { useFetchQuery } from "hooks/useQueries";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { GET_COMPANY_LIST } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { mutualFundTransactionType } from "utils/constants";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";

interface Props {
  mutualFundData: any;
  portfolioId?: number | string;
}

const buyRemarkOption = [
  { name: "SIP", value: "SIP" },
  { name: "Lumpsum Purchase", value: "LUMPSUM_PURCHASE" },
];

const sellRemarkOption = [
  { name: "SWP", value: "SWP" },
  { name: "Lumpsum Redemption", value: "LUMPSUM_REDEMPTION" },
];

const MutualFundModal = (props: Props & GKModalProps) => {
  const { open, setOpen, mutualFundData, portfolioId } = props;

  const {
    state: { portfolios },
  } = useAppContext();

  const [confirmationModal, setConfirmationModal] = useState(false);

  const { data: companyMasterData, isFetching } = useFetchQuery({
    key: [QueryKeys.COMPANY_NAMES],
    route: GET_COMPANY_LIST(["MF"]),
  });

  const form = useForm({
    defaultValues: {
      gridCode: "",
      date: "",
      portfolio: "",
      price: "",
      quantity: "",
      stampCharges: "",
      folioNo: "",
      transactionType: "",
      billAmount: 0,
      transactionRemarks: "",
    },
    values: {
      gridCode: mutualFundData?.gridCode || "",
      date: mutualFundData?.date || "",
      portfolio: +portfolioId || mutualFundData?.portfolio || "",
      folioNo: mutualFundData?.folioNo || "",
      price: mutualFundData?.price || "",
      quantity: mutualFundData?.quantity || "",
      stampCharges: mutualFundData?.stampCharges || "",
      transactionType: mutualFundData?.transactionType || "",
      billAmount: mutualFundData?.billAmount || 0,
      transactionRemarks: mutualFundData?.transactionRemarks || "",
    },
  });

  const { mutate: createMutualFund, isLoading } = useMutation({
    mutationKey: [QueryKeys.ADD_MUTUAL_FUND_ENTRY],
    mutationFn: (data: any) =>
      axiosInstance.post("/mutual_funds_transaction/", data),
    onSuccess: (response) => {
      setOpen(false);
      refetchQuery(QueryKeys.MUTUAL_FUND_LIST);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: updateMutualFund, isLoading: updateMutualFundLoading } =
    useMutation({
      mutationKey: [QueryKeys.UPDATE_MUTUAL_FUND_ENTRY],
      mutationFn: (data: any) =>
        axiosInstance.patch(
          `/mutual_funds_transaction/${mutualFundData?.id}/`,
          data,
        ),
      onSuccess: (response) => {
        setOpen(false);
        refetchQuery(QueryKeys.MUTUAL_FUND_LIST);
        toast.success(
          response.data?.message || "Action completed successfully.",
        );
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  const { mutate: deleteMutualFund, isLoading: deleteMutualFundLoading } =
    useMutation({
      mutationKey: [QueryKeys.DELETE_MUTUAL_FUND_ENTRY],
      mutationFn: () =>
        axiosInstance.delete(
          `/mutual_funds_transaction/${mutualFundData?.id}/`,
        ),
      onSuccess: (response) => {
        setOpen(false);
        refetchQuery(QueryKeys.MUTUAL_FUND_LIST);
        toast.success(
          response.data?.message || "Action completed successfully.",
        );
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  const formChange = form.watch([
    "price",
    "quantity",
    "stampCharges",
    "transactionType",
  ]);

  useMemo(() => {
    const transactionType = form.getValues("transactionType");
    const price = +form.getValues("price");
    const quantity = +form.getValues("quantity");
    const stampCharges = +form.getValues("stampCharges");

    const billAmount =
      transactionType === "BUY"
        ? price * quantity + stampCharges
        : price * quantity - stampCharges;

    form.setValue("billAmount", +billAmount.toFixed(2));
  }, [formChange]);

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={mutualFundData?.id ? "Update Trade" : "Add Trade"}
      titleComponent={
        mutualFundData?.id && (
          <LoadingButton
            loading={deleteMutualFundLoading}
            color="error"
            variant="outlined"
            onClick={() => setConfirmationModal(true)}
          >
            Delete
          </LoadingButton>
        )
      }
      footer={
        <Grid2
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
        >
          <GKButton
            variant="outlined"
            size="medium"
            form="cash-form"
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </GKButton>
          <LoadingButton
            variant="contained"
            size="medium"
            type="submit"
            form="mutual-fund-form"
            loading={isLoading || updateMutualFundLoading}
          >
            {mutualFundData?.id ? "Update" : "Save"}
          </LoadingButton>
        </Grid2>
      }
    >
      <form
        id="mutual-fund-form"
        onSubmit={form.handleSubmit((data) => {
          if (dayjs(data?.date).year() < 1980) {
            toast.error(
              "You are not allowed to add transaction for this date.",
            );
          } else {
            mutualFundData?.id
              ? updateMutualFund(data)
              : createMutualFund(data);
          }
        })}
      >
        <Grid2 container spacing={2}>
          <Grid2 size={6}>
            <Controller
              name="portfolio"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please Select Portfolio",
                },
              }}
              render={({ field, formState: { errors } }) => (
                <GKSearchSelect
                  {...field}
                  disabled={!!portfolioId}
                  requiredField
                  disableClearable
                  inputLabel="Select Portfolio"
                  renderOption={(props, option) => {
                    return (
                      <li
                        {...props}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography fontWeight={600}>
                          {option.clientName}
                        </Typography>
                        <Typography>{option.name}</Typography>
                      </li>
                    );
                  }}
                  options={portfolios || []}
                  getOptionLabel={(option) => option?.name || option}
                  onChange={(e, val) => field.onChange(val?.value)}
                  value={
                    portfolios?.find(
                      (dataItem: any) => dataItem.value === field.value,
                    ) || ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Portfolio",
                        value: field.value,
                      })}
                      error={Boolean(errors.portfolio)}
                      helperText={errors.portfolio?.message as string}
                    />
                  )}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="date"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please Select Date",
                },
              }}
              render={({ field, formState: { errors } }) => (
                <FormControl variant="standard" fullWidth>
                  <GKDatePicker
                    {...field}
                    requiredField
                    inputLabel="Trade Date"
                    value={dayjs(field.value)}
                    onChange={(value) => {
                      field.onChange(dayjs(value).format("YYYY-MM-DD"));
                    }}
                    disableFuture
                    maxDate={dayjs()}
                    slotProps={{
                      textField: {
                        error: Boolean(errors?.date),
                        helperText: errors.date?.message as string,
                      },
                    }}
                  />
                </FormControl>
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="gridCode"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please Select Mutual Fund",
                },
              }}
              render={({ field, formState: { errors } }) => (
                <GKSearchSelect
                  {...field}
                  requiredField
                  disableClearable
                  inputLabel="Select Mutual Fund"
                  loading={isFetching}
                  options={
                    companyMasterData?.data?.data?.map((item: any) => ({
                      ...item,
                      name: item?.companyName,
                      value: item?.gridCode,
                    })) || []
                  }
                  getOptionLabel={(option) => option?.name || option}
                  onChange={(e, val) => field.onChange(val?.value)}
                  value={
                    companyMasterData?.data?.data?.find(
                      (dataItem: any) => dataItem.gridCode === field.value,
                    ) || ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Mututal Fund",
                        value: field.value,
                      })}
                      error={Boolean(errors.gridCode)}
                      helperText={errors.gridCode?.message as string}
                    />
                  )}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="transactionType"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please Select Trade Type",
                },
              }}
              render={({ field, formState: { errors } }) => (
                <GKSearchSelect
                  {...field}
                  requiredField
                  disableClearable
                  inputLabel="Trade Type"
                  options={mutualFundTransactionType || []}
                  getOptionLabel={(option) => option?.name || option}
                  onChange={(e, val) => {
                    field.onChange(val?.value);
                    form.setValue("transactionRemarks", "");
                  }}
                  value={
                    mutualFundTransactionType?.find(
                      (dataItem: any) => dataItem.value === field.value,
                    ) || ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Trade Type",
                        value: field.value,
                      })}
                      error={Boolean(errors.transactionType)}
                      helperText={errors.transactionType?.message as string}
                    />
                  )}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="transactionRemarks"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please Select Transaction Remarks",
                },
              }}
              render={({ field, formState: { errors } }) => {
                const transactionType = form.getValues("transactionType");
                const options =
                  transactionType === "BUY"
                    ? buyRemarkOption
                    : sellRemarkOption;

                return (
                  <GKSearchSelect
                    {...field}
                    requiredField
                    disableClearable
                    inputLabel="Select Transaction Remarks"
                    getOptionLabel={(option) => option?.name || option}
                    options={options || []}
                    onChange={(e, val) => field.onChange(val?.value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Remark",
                          value: field.value,
                        })}
                        error={Boolean(errors.transactionRemarks)}
                        helperText={
                          errors.transactionRemarks?.message as string
                        }
                      />
                    )}
                  />
                );
              }}
            />
          </Grid2>

          <Grid2 size={6}>
            <Controller
              name="quantity"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please Enter Quantity",
                },
              }}
              render={({ field, formState: { errors } }) => (
                <FormControl variant="standard" fullWidth>
                  <GKTextField
                    {...field}
                    requiredField
                    type="number"
                    inputLabel="Quantity"
                    onChange={(e: any) =>
                      field.onChange(e.target.valueAsNumber)
                    }
                    error={Boolean(errors.quantity)}
                    helperText={errors.quantity?.message as string}
                  />
                </FormControl>
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="price"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please Enter Price",
                },
              }}
              render={({ field, formState: { errors } }) => (
                <FormControl variant="standard" fullWidth>
                  <GKTextField
                    {...field}
                    requiredField
                    type="number"
                    inputLabel="Price"
                    onChange={(e: any) =>
                      field.onChange(e.target.valueAsNumber)
                    }
                    error={Boolean(errors.price)}
                    helperText={errors.price?.message as string}
                  />
                </FormControl>
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="stampCharges"
              control={form.control}
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <GKTextField
                    {...field}
                    type="number"
                    inputLabel="Stamp Charges"
                    onChange={(e: any) =>
                      field.onChange(e.target.valueAsNumber)
                    }
                  />
                </FormControl>
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="billAmount"
              control={form.control}
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <GKTextField
                    {...field}
                    disabled
                    type="number"
                    inputLabel="Bill Amount"
                  />
                </FormControl>
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="folioNo"
              control={form.control}
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <GKTextField {...field} inputLabel="Folio Number" />
                </FormControl>
              )}
            />
          </Grid2>

          <Show.When isTrue={confirmationModal}>
            <ConfirmDeleteModal
              open={confirmationModal}
              setOpen={setConfirmationModal}
              onDelete={() => deleteMutualFund()}
              isDelete
              title={"Mutual Fund"}
            />
          </Show.When>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default MutualFundModal;
