import { GridColDef } from "@mui/x-data-grid-pro";
import { GKClientDataGrid, Grid2, Typography } from "components";
import { useFetchQuery } from "hooks/useQueries";
import { useParams } from "react-router-dom";
import { moneyCommaSeparator } from "utils/MathFunction";
import { LONG_TEAM_HOLDING_BY_PORTFOLIO_ID } from "utils/apiRoutes";
import { handleReturnPositiveNegative } from "utils/colorHelper";
import { extraColumnsField, matchDate } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";

const PortfolioLongTermHolding = ({ portfolioId }: any) => {
  useDocumentTitle("Holding Inventory Age");

  const { id } = useParams();

  const { data, isFetching } = useFetchQuery({
    key: ["LONG_TERM_HOLDING"],
    route: LONG_TEAM_HOLDING_BY_PORTFOLIO_ID(portfolioId),
    requestBody: {
      gcode: id,
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Date", "purchaseDate", 150, 1),
      valueGetter: (row) => row?.row?.purchaseDate || "N/A",
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("Quantity", "quantity", 150, 1),
      align: "right",
      valueGetter: (params) => params.row.rawQuantity || "0",
      headerAlign: "right",
      type: "number",
    },
    {
      ...extraColumnsField("Price", "avgUnitCost", 150, 1),
      align: "right",
      headerAlign: "right",
      type: "number",
      valueGetter: (params) => params.row.avgUnitCost || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Age (Days)", "age", 150, 1),
      align: "right",
      valueGetter: (params) => params.row.age || "0",
      headerAlign: "right",
      type: "number",
    },
    {
      ...extraColumnsField("Unrealised Gain", "unrealisedGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.unrealisedGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { unrealisedGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(unrealisedGain)}>
            {moneyCommaSeparator(unrealisedGain)}
          </Typography>
        );
      },
    },
  ];

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={12}>
        <GKClientDataGrid
          name="longTermHolding"
          density="compact"
          loading={isFetching}
          columns={columns}
          rows={
            data?.data?.data.map((dataItem: any, index: number) => {
              return {
                id: index,
                ...dataItem,
              };
            }) || []
          }
        />
      </Grid2>
    </Grid2>
  );
};

export default PortfolioLongTermHolding;
