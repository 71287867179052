import { Divider, Switch, Typography } from "@mui/material";
import { GKEditor, Grid2 } from "components";
import GKButton from "components/GKButton";
import useAccountSetting from "Pages/AccountSetting/useAccountSetting";
import { Controller, useForm } from "react-hook-form";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";

type FormFields = {
  managementFees: boolean;
  performanceFees: boolean;
  cashFlows: boolean;
  xirrReturn: boolean;
  termCondition: string;
};

export const revenueManagerSetting = [
  {
    name: "Management Fees",
    fieldName: "managementFees",
    description: "Toggle to include management fees in the generated invoice.",
  },
  {
    name: "Performance Fees",
    fieldName: "performanceFees",
    description:
      "Toggle to include performance fee details in the generated invoice.",
  },
  {
    name: "Cash Flows",
    fieldName: "cashFlows",
    description:
      "Toggle to include cash flow movements in the generated invoice.",
  },
  {
    name: "Performance/Returns",
    fieldName: "xirrReturn",
    description:
      "Toggle to display performance and returns summary in the generated invoice.",
  },
];

const RevenueManagerSettings = () => {
  useDocumentTitle("Invoice Preferences");
  const { theme } = ThemeContainer();

  const { getCompanySetting, handleUpdateCompanySetting } = useAccountSetting();

  const form = useForm<FormFields>({
    defaultValues: {
      managementFees: false,
      performanceFees: false,
      cashFlows: false,
      xirrReturn: false,
      termCondition: "",
    },
    values: {
      managementFees: getCompanySetting?.data?.managementFees,
      performanceFees: getCompanySetting?.data?.performanceFees,
      cashFlows: getCompanySetting?.data?.cashFlows,
      xirrReturn: getCompanySetting?.data?.xirrReturn,
      termCondition: getCompanySetting?.data?.termCondition || "",
    },
  });

  return (
    <form
      onSubmit={form.handleSubmit((values) =>
        handleUpdateCompanySetting(values),
      )}
    >
      <Grid2 container spacing={2}>
        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Invoice Annexure Settings
          </Typography>
        </Grid2>
        {revenueManagerSetting.map((dataItem, index) => (
          <>
            <Grid2 size={12} key={index}>
              <div style={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Controller
                  name={dataItem.fieldName as keyof FormFields}
                  control={form.control}
                  render={({ field }) => (
                    <Switch
                      {...field}
                      // checked={field.value}
                      checked={Boolean(field.value)}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  textTransform={"none"}
                  color={theme.palette.dark?.main}
                >
                  {dataItem.name}
                </Typography>
              </div>
              <Typography
                ml={"42px"}
                fontSize={12}
                color={theme.palette.secondaryText.main}
              >
                {dataItem.description}
              </Typography>
            </Grid2>
          </>
        ))}

        <Grid2 size={12} display={"flex"} justifyContent={"flex-end"}>
          <GKButton variant="contained" type="submit">
            Save
          </GKButton>
        </Grid2>

        <Grid2 size={12} my={1}>
          <Divider />
        </Grid2>

        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Terms and Conditions
          </Typography>
        </Grid2>

        <Grid2
          container
          size={9}
          spacing={2}
          display={"flex"}
          alignItems={"center"}
        >
          <Grid2 size={12}>
            <Controller
              name="termCondition"
              control={form.control}
              render={({ field }) => (
                <GKEditor
                  {...field}
                  inputLabel="The content you enter here will be present at the end of all invoices sent to the clients."
                />
              )}
            />
          </Grid2>
        </Grid2>
        <Grid2 size={12} display={"flex"} justifyContent={"flex-end"}>
          <GKButton variant="contained" type="submit">
            Save
          </GKButton>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default RevenueManagerSettings;
