import { Grid2, Typography } from "components";

const Section1 = () => {
  return (
    <Grid2
      container
      py={10}
      alignItems={"center"}
      spacing={3}
      sx={{
        flexDirection: {
          xs: "column-reverse",
          lg: "row",
        },
        px: {
          xs: 3,
          lg: 10,
        },
        background: "#020707",
      }}
    >
      <Grid2 size={{ xs: 12, lg: 6 }}>
        <Typography
          fontSize={32}
          fontWeight={700}
          sx={{
            textAlign: {
              xs: "center",
              lg: "left",
            },
          }}
        >
          Empowering Wealth Managers: A Data-Driven Platform for Financial
          Decision-Making{" "}
        </Typography>{" "}
      </Grid2>
      <Grid2
        size={{ xs: 12, lg: 6 }}
        container
        display={"flex"}
        justifyContent={"center"}
      >
        <Grid2
          size={12}
          sx={{
            justifyContent: {
              xs: "center",
              lg: "flex-start",
            },
            alignItems: {
              xs: "center",
              lg: "flex-start",
            },
            display: {
              xs: "flex",
              lg: "unset",
            },
            flexDirection: {
              xs: "column",
              lg: "row",
            },
          }}
        >
          <Typography mt={3} fontWeight={600} fontSize={16}>
            Our Mission
          </Typography>
          <Typography fontWeight={400} fontSize={16} color={"#CCCDCD"} mt={1}>
            We want to revolutionize wealth management by providing intuitive,
            data-driven solutions that empower advisors and investors to achieve
            their financial goals. We are committed to delivering cutting-edge
            technology, unparalleled support, and actionable insights to our
            users, helping them navigate the complexities of the financial world
            with confidence and ease.
          </Typography>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default Section1;
