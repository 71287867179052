import { Grid2 } from "components";
import { useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import useDocumentTitle from "utils/useDocumentTitle";
import FooterSection from "./Footer/FooterSection";
import WealthAdvisory from "./Footer/WealthAdvisory";
import Header from "./Header";

const Website = () => {
  useDocumentTitle("Explorer");

  const [scrollPosition, setScrollPosition] = useState({
    scrollTop: 0,
    scrollLeft: 0,
  });
  const scrollDemoRef = useRef(null);

  const handleScroll = () => {
    if (scrollDemoRef.current) {
      const { scrollTop, scrollLeft } = scrollDemoRef.current;
      setScrollPosition({ scrollTop, scrollLeft });
    }
  };

  return (
    <Grid2
      onScroll={handleScroll}
      ref={scrollDemoRef}
      container
      maxHeight={"100vh"}
      maxWidth={"100vw"}
      overflow={" auto"}
    >
      <Grid2 size={12}>
        <Header scrollPosition={scrollPosition} />
      </Grid2>
      <Grid2 size={12}>
        <Outlet />
      </Grid2>
      <Grid2 size={12}>
        <WealthAdvisory />
      </Grid2>
      <Grid2 size={12}>
        <FooterSection />
      </Grid2>
    </Grid2>
  );
};

export default Website;
