import { useAppContext } from "AppContext";
import { Divider, Grid2, Tab, Tabs } from "components";
import { useUpdateLead } from "hooks/useUpdateLead";
import DashboardLayout from "layouts/DashboardLayout";
import { useParams } from "react-router-dom";
import useDocumentTitle from "utils/useDocumentTitle";
import LeadMeeting from "../LeadMeeting";
import LeadNotes from "../LeadNotes";
import LeadCard from "./LeadCard";
import UpdateNewLead from "./UpdateNewLead";

const LeadDetail = () => {
  useDocumentTitle("Lead Details");

  const { id } = useParams<string>();

  const {
    state: { tab },
    dispatch,
  } = useAppContext();

  const { leadData } = useUpdateLead();

  return (
    <DashboardLayout title="Lead">
      <Grid2 container spacing={1}>
        <Grid2 size={12}>
          <LeadCard leadData={leadData?.data?.data} />
        </Grid2>
        <Grid2 size={12}>
          <Tabs
            value={tab?.leadDetailsTabs}
            onChange={(e, val) => {
              dispatch({
                type: "TAB",
                data: { ...tab, leadDetailsTabs: val },
              });
            }}
          >
            <Tab disableRipple label="Summary" />
            <Tab disableRipple label="Meetings" />
            <Tab disableRipple label="Notes" />
          </Tabs>
          <Divider />
        </Grid2>
        <Grid2 size={12}>
          {tab?.leadDetailsTabs === 0 && <UpdateNewLead />}
          {tab?.leadDetailsTabs === 1 && <LeadMeeting lead_id={id} />}
          {tab?.leadDetailsTabs === 2 && <LeadNotes lead_id={id} />}
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default LeadDetail;
