import {
  CommonPagination,
  EditIcon,
  GKDataGrid,
  Grid2,
  IconButton,
  Show,
  Typography,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { moneyCommaSeparator } from "utils/MathFunction";
import { TableFiltering } from "utils/TableFilters";
import { PORTFOLIO_AVAILABLE_BALANCE } from "utils/apiRoutes";
import { extraColumnsField, matchDate } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import AvailableMarginModal from "./AvailableMarginModal";

interface LedgerBalanceInterface {
  portfolioId: number;
  brokerLedgerBalance: number;
}

const AvailableCash = () => {
  useDocumentTitle("Ledger Balance");
  const navigate = useNavigate();

  const [openMarginModal, setOpenMarginModal] = useState(false);
  const [ledgerBalanceData, setLedgerBalanceData] =
    useState<LedgerBalanceInterface>();

  const avlRequestBody = useGenerateQueryParams("avlCashBalance");

  const { data: portfolioCash, isFetching: portfolioLoading } = useFetchQuery({
    route: PORTFOLIO_AVAILABLE_BALANCE,
    key: [QueryKeys.PORTFOLIO_CASH_LIST, avlRequestBody],
    requestBody: avlRequestBody,
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={portfolioCash?.data?.totalCount || 0}
        name="avlCashBalance"
      />
    );
  };

  return (
    <>
      <GKDataGrid
        rows={portfolioCash?.data?.data || []}
        maxHeight={"calc(100vh - 256px)"}
        loading={portfolioLoading}
        getRowId={(params) => params?.portfolioId}
        exportFile={{
          path: "/portfolios/avl_balance/",
          exportName: "CashBalanceList",
        }}
        zeroHolding
        CustomPagination={!portfolioLoading && cusPagination}
        name="avlCashBalance"
        tableName="available_cash_list"
        onRowClick={(params) => {
          navigate(`/app/nav/${params.row.portfolioId}/`, {
            state: params?.row,
          });
        }}
        columns={[
          {
            ...extraColumnsField("Portfolio", "clientName", 250, 1),
            renderCell: (params) => {
              return (
                <Grid2
                  container
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-start"}
                >
                  <Typography
                    fontWeight={700}
                    maxWidth={"100%"}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                  >
                    {params?.row?.clientName}
                  </Typography>
                  <Typography fontSize={12}>
                    {params?.row?.portfolioName} - {params?.row?.portfolioCode}
                  </Typography>
                </Grid2>
              );
            },
          },
          {
            ...extraColumnsField(
              "Last Cash Entry Date",
              "lastCashDate",
              100,
              1,
            ),
            valueGetter: (params) => matchDate(params?.row?.lastCashDate),
          },
          {
            ...extraColumnsField(
              "Available Balance",
              "availableLedgerBalance",
              150,
              1,
            ),
            type: "number",
            valueFormatter: (params) =>
              moneyCommaSeparator(params?.value) || "0",
          },
          {
            ...extraColumnsField(
              "Available Margin",
              "brokerLedgerBalance",
              150,
              1,
            ),
            type: "number",
            valueFormatter: (params) =>
              moneyCommaSeparator(params?.value) || "0",
          },
          {
            ...extraColumnsField("Actions", "action", 100, 1),
            align: "right",
            headerAlign: "right",
            renderCell: (params) => (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setLedgerBalanceData(params?.row);
                  setOpenMarginModal(true);
                }}
              >
                <EditIcon size={18} />
              </IconButton>
            ),
          },
        ]}
        headerFilter={<TableFiltering name="avlCashBalance" />}
      />

      <Show.When isTrue={openMarginModal}>
        <AvailableMarginModal
          open={openMarginModal}
          setOpen={setOpenMarginModal}
          ledgerBalanceData={ledgerBalanceData}
        />
      </Show.When>
    </>
  );
};

export default AvailableCash;
