import { LoadingButton } from "@mui/lab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { GKButton, GKModal, GKTextField, Grid2, Typography } from "components";
import GKCheckbox from "components/GKCheckbox";
import dayjs from "dayjs";
import * as React from "react";
import { Controller } from "react-hook-form";
import { GKModalProps } from "utils/commonInterface";
import { numberToCurrency } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";

const headCells = [
  {
    id: "feePlanName",
    numeric: false,
    disablePadding: true,
    label: "Fee Plan Name",
  },
  {
    id: "startDate",
    numeric: true,
    disablePadding: false,
    label: "Date Range",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <GKCheckbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface Props {
  form?: any;
  feeDeductionData?: any;
  selected: any;
  setSelected: any;
}

export default function ManagementDeductionPage(props: Props & GKModalProps) {
  const { form, feeDeductionData, open, setOpen, selected, setSelected } =
    props;

  const { theme } = ThemeContainer();

  const calculateTotalFee = (
    selectedItems: readonly number[],
    additionalFee = 0,
  ) => {
    const selectedItemsData =
      feeDeductionData?.data?.data?.feeCalculation?.filter(
        (item: any) => selectedItems.indexOf(item.id) !== -1,
      );
    const totalFee = selectedItemsData?.reduce(
      (acc: any, curr: any) => acc + curr?.managementFee,
      0,
    );
    form.setValue("managementFee", totalFee + additionalFee);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = feeDeductionData?.data?.data?.feeCalculation?.map(
        (n: any) => n.id,
      );
      setSelected(newSelected);
      calculateTotalFee(newSelected, form.getValues("additionalFee"));
      return;
    }
    setSelected([]);
    form.setValue("managementFee", form.getValues("additionalFee"));
  };

  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const selectedIndex = selected.indexOf(row?.id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row?.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    calculateTotalFee(newSelected, form.getValues("additionalFee"));
  };

  const handleAdditionalFeeChange = (value: number) => {
    form.setValue("additionalFee", value);
    calculateTotalFee(selected, value);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={"Deduct Management Fees"}
      width={"70%"}
      footer={
        <Grid2
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
        >
          <GKButton
            color="dark"
            variant="outlined"
            size="medium"
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </GKButton>
          <LoadingButton
            variant="contained"
            size="medium"
            type="submit"
            onClick={() => {
              setOpen(false);
            }}
          >
            Save
          </LoadingButton>
        </Grid2>
      }
    >
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" stickyHeader>
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={feeDeductionData?.data?.data?.feeCalculation?.length}
          />
          <TableBody>
            {feeDeductionData?.data?.data?.feeCalculation?.map(
              (row: any, index: number) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <GKCheckbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <Typography color={theme.palette.dark.main}>
                        {row?.name || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color={theme.palette.dark.main}>
                        {`${dayjs(row.startDate).format(
                          "DD-MM-YYYY",
                        )} - ${dayjs(row.endDate).format("DD-MM-YYYY")}`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color={theme.palette.dark.main}>
                        {numberToCurrency(row.managementFee)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              },
            )}
            <TableRow>
              <TableCell colSpan={1} />
              <TableCell colSpan={1} />
              <TableCell colSpan={1}>
                <Typography fontWeight={500}>
                  Additional Management Fee
                </Typography>
              </TableCell>
              <TableCell>
                <Controller
                  name="additionalFee"
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      type="number"
                      onChange={(e: any) => {
                        const value = e.target.valueAsNumber || 0;
                        field.onChange(value);
                        handleAdditionalFeeChange(value);
                      }}
                    />
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={1} />
              <TableCell colSpan={1} />
              <TableCell colSpan={1}>
                <Typography fontWeight={500}>Total Amount</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={500}>
                  {numberToCurrency(form.getValues("managementFee"))}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </GKModal>
  );
}
