export const handleDowloadFile = ({
  fileData,
  applicaionType = "applicaion/pdf",
  fileName = "gridkey_file",
  extensionType = "pdf",
}: {
  fileData: any;
  applicaionType: string;
  extensionType: string;
  fileName: string;
}) => {
  const url = window.URL.createObjectURL(
    new Blob([fileData], { type: applicaionType }),
  );
  const link = document.createElement("a");
  link.href = url;
  link.download = `${fileName}.${extensionType}`;
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};
