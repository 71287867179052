import { useAppContext } from "AppContext";
import { AnimationComponent, Box, Grid2, Show, Tab, Tabs } from "components";
import DashboardLayout from "layouts/DashboardLayout";
import { FeeCollection, FeePlans } from "Pages";
import SubscribedPortfolio from "Pages/Portfolio/SubscribedPortfolio";
import InvoiceTab from "Pages/RevenueManagement/Invoice/InvoiceTab";
import PerformaInvoiceTab from "Pages/RevenueManagement/Invoice/PerformaInvoiceTab";
import RevenueFeeCalculations from "Pages/RevenueManagement/RevenueManager/RevenueFeeCalculation";
import RevenuePortfolioTab from "Pages/RevenueManagement/RevenueManager/RevenuePortfolioTab";
import RevenueMandate from "Pages/RevenueManagement/RevenueMandate";
import { ThemeContainer } from "utils/Theme";

const RevenueMenu = () => {
  const {
    state: { tab: tabValue },
    dispatch,
  } = useAppContext();

  const { theme } = ThemeContainer();

  const tabs = [
    { label: "Fee Plan Setup", component: FeePlans },
    { label: "Revenue Manager", component: RevenuePortfolioTab },
    { label: "Fee Calculation", component: RevenueFeeCalculations },
    { label: "Proforma", component: PerformaInvoiceTab },
    { label: "Invoice", component: InvoiceTab },
    { label: "Fee Collection", component: FeeCollection },
    { label: "Mandate", component: RevenueMandate },
    { label: "Pending Approvals", component: SubscribedPortfolio },
  ];

  return (
    <DashboardLayout title="Dashboard">
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              sx={{
                "& .MuiTabs-flexContainer": {
                  backgroundColor: theme.palette.light.main,
                },
              }}
              onChange={(e, val) => {
                dispatch({
                  type: "TAB",
                  data: { ...tabValue, tabValueOfRevenue: val },
                });
              }}
              value={tabValue?.tabValueOfRevenue}
              variant="scrollable"
            >
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab.label} disableTouchRipple />
              ))}
            </Tabs>
          </Box>
        </Grid2>
        <Grid2 size={12}>
          {tabs.map((tab, index) => (
            <Show.When
              key={index}
              isTrue={tabValue?.tabValueOfRevenue === index}
            >
              <AnimationComponent>
                <tab.component />
              </AnimationComponent>
            </Show.When>
          ))}
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default RevenueMenu;
