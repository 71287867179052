import { useAppContext } from "AppContext";
import { AnimationComponent, Box, Grid2, Show, Tab, Tabs } from "components";
import DashboardLayout from "layouts/DashboardLayout";
import { CompliancePage, Lead } from "Pages";
import Client from "Pages/Client";
import Family from "Pages/Family";
import React from "react";
import { ThemeContainer } from "utils/Theme";

const CRMMenu = () => {
  const {
    state: { tab: tabValue },
    dispatch,
  } = useAppContext();

  const { theme } = ThemeContainer();

  const tabs = [
    { label: "Client", component: Client },
    { label: "Lead", component: Lead },
    { label: "Onboarding", component: CompliancePage },
    { label: "Family", component: Family },
  ];

  return (
    <DashboardLayout title="Dashboard">
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              sx={{
                "& .MuiTabs-flexContainer": {
                  backgroundColor: theme.palette.light.main,
                },
              }}
              onChange={(e, val) => {
                dispatch({
                  type: "TAB",
                  data: { ...tabValue, tabValueOfCRM: val },
                });
              }}
              value={tabValue?.tabValueOfCRM}
            >
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab.label} disableRipple />
              ))}
            </Tabs>
          </Box>
        </Grid2>
        <Grid2 size={12}>
          {tabs.map((tab, index) => (
            <Show.When key={index} isTrue={tabValue?.tabValueOfCRM === index}>
              <AnimationComponent>
                {React.createElement(tab.component)}
              </AnimationComponent>
            </Show.When>
          ))}
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default CRMMenu;
