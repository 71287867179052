import {
  GKModal,
  SimpleLoader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { sortBy } from "lodash";
import { useState } from "react";
import { WALLET_RATE_CARD } from "utils/apiRoutes";
import { GKModalProps } from "utils/commonInterface";
import { moneyCommaSeparator } from "utils/MathFunction";

const RateCardModal = (props: GKModalProps) => {
  const { open, setOpen } = props;

  const { data, isFetching } = useFetchQuery({
    key: ["GET_RATE_CARD_CHARGES"],
    route: WALLET_RATE_CARD,
  });

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  }>({
    key: "type",
    direction: "asc",
  });

  const sortedData = data?.data?.data
    ? sortBy(
        data.data.data.map((dataItem: any) => {
          return { ...dataItem, rate: +dataItem.rate };
        }),
        [sortConfig.key],
      )
    : [];

  if (sortConfig.direction === "desc") {
    sortedData.reverse();
  }

  const handleSort = (key: string) => {
    setSortConfig((prevSortConfig) => ({
      key,
      direction:
        prevSortConfig.key === key && prevSortConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  return (
    <GKModal open={open} setOpen={setOpen} modalTitle={"Add-ons"} width={"40%"}>
      {isFetching ? (
        <SimpleLoader />
      ) : (
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  onClick={() => handleSort("type")}
                  style={{ cursor: "pointer" }}
                >
                  Particulars{" "}
                  {sortConfig.key === "type"
                    ? sortConfig.direction === "asc"
                      ? "↑"
                      : "↓"
                    : ""}
                </TableCell>
                <TableCell
                  onClick={() => handleSort("rate")}
                  align="right"
                  style={{ cursor: "pointer" }}
                >
                  Rate{" "}
                  {sortConfig.key === "rate"
                    ? sortConfig.direction === "asc"
                      ? "↑"
                      : "↓"
                    : ""}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((dataItem: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography>{`${dataItem.type} ${
                      dataItem.subType ? ` - ${dataItem.subType}` : ""
                    } `}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {moneyCommaSeparator(+dataItem.rate)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </GKModal>
  );
};

export default RateCardModal;
