import {
  GKModal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "components";
import { GKModalProps } from "utils/commonInterface";
import { matchDate } from "utils/helpers";

interface Props {
  paymentData?: {
    paymentDate?: string;
    transferProcessedAt?: string;
    createdAt?: string;
  };
}

const PaymentStatusModal = (props: Props & GKModalProps) => {
  const { open, setOpen, paymentData } = props;

  return (
    <GKModal open={open} setOpen={setOpen} modalTitle={"Payment Details"}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ padding: 10 }}>
                Payment Generation Date
              </TableCell>
              <TableCell style={{ padding: 10 }}>
                Transfer Processed Date
              </TableCell>
              <TableCell style={{ padding: 10 }}>Payment Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={{ padding: 10 }}>
                {matchDate(paymentData?.createdAt) || "-"}
              </TableCell>
              <TableCell style={{ padding: 10 }}>
                {matchDate(paymentData?.transferProcessedAt) || "-"}
              </TableCell>
              <TableCell style={{ padding: 10 }}>
                {matchDate(paymentData?.paymentDate) || "-"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </GKModal>
  );
};

export default PaymentStatusModal;
