import { GKOverlayLoader } from "components";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { currentVersion } from "utils/constants";

const RALayout = () => {
  const { search } = useLocation();

  const token = new URLSearchParams(search).get("token");

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      localStorage.setItem("product-version", currentVersion);
      window.location.href = "/app/holdings";
    }
  }, [token]);

  return <GKOverlayLoader />;
};

export default RALayout;
