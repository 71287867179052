import { InputLabel, Typography, styled } from "@mui/material";
import {
  DateTimePicker,
  DateTimePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { FiCalendar } from "react-icons/fi";
import { ThemeContainer } from "utils/Theme";

interface GKDatePickerProps {
  inputLabel?: string;
  id?: string;
  name?: string;
  requiredField?: boolean;
  error?: boolean;
  helperText?: string;
}

const CustomDatePicker = styled(DateTimePicker)(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-root .MuiInputBase-input": {
    padding: "12px 14px",
  },
  "& .MuiOutlinedInput-notchedOutline ": {
    border: `solid 1px`,
    borderColor: theme.palette.grey[400],
  },
  "& .MuiOutlinedInput-root": {
    height: "40px !important",
    borderRadius: 8,
  },
  "& .MuiFormHelperText-root ": {
    margin: 0,
    color: theme.palette.error.main,
    fontWeight: 400,
  },
  "& .MuiButtonBase-root": {
    background: theme.palette.light.main,
    color: theme.palette.grey[600],
    "&:hover": {
      color: theme.palette.primary.main,
      background: `${theme.palette.light.main}`,
      borderColor: theme.palette.grey[400],
    },
  },
}));

const GKDateTimePicker = (
  props: GKDatePickerProps & DateTimePickerProps<any>,
) => {
  const { theme } = ThemeContainer();
  const { inputLabel, id, name, requiredField, helperText, error, minDate } =
    props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoItem
        label={
          inputLabel && (
            <InputLabel
              style={{
                color: theme.palette.dark.main,
                fontSize: "18px",
                fontFamily: "Inter !important",
                textTransform: "capitalize",
              }}
              shrink
              htmlFor={name || id}
            >
              {inputLabel}
              {requiredField && (
                <span
                  style={{
                    color: theme.palette.error.main,
                  }}
                >
                  *
                </span>
              )}
            </InputLabel>
          )
        }
      >
        <CustomDatePicker
          minDate={minDate || dayjs("01-01-1980")}
          disableFuture
          slots={{
            openPickerIcon: FiCalendar,
          }}
          slotProps={{
            openPickerIcon: {
              fontSize: 20,
              color: theme.palette.secondaryText.main,
            },
          }}
          sx={{
            marginTop: inputLabel ? "19px !important" : 0,
          }}
          error={error}
          {...props}
          format="DD/MM/YYYY hh:mm a"
        />
        {Boolean(helperText) && (
          <Typography
            color={theme.palette.error.main}
            fontSize={12}
            fontWeight={550}
            sx={{
              marginTop: "0 !important",
            }}
          >
            {helperText}
          </Typography>
        )}
      </DemoItem>
    </LocalizationProvider>
  );
};

export default GKDateTimePicker;
