/* eslint-disable react/no-danger */
import { GKModal, Grid2, NoData } from "components";
import { GKModalProps } from "utils/commonInterface";

interface Props {
  content: string;
}

const RationaleModal = (props: Props & GKModalProps) => {
  const { open, setOpen, content } = props;

  return (
    <GKModal open={open} setOpen={setOpen} modalTitle={"Rationale"}>
      <Grid2 container>
        {content ? (
          <Grid2 size={12}>
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </Grid2>
        ) : (
          <Grid2 size={12}>
            <NoData />
          </Grid2>
        )}
      </Grid2>
    </GKModal>
  );
};

export default RationaleModal;
