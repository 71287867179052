/* eslint-disable react/no-this-in-sfc */
import { NoData } from "components";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { useMemo, useState } from "react";
import { paletteLightTheme } from "utils/ColorTheme";
import { formatYAxisLabel, handleReturnData } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";

interface Props {
  data: {
    holdings: [number, number][];
    payIn: [number, number][];
    payOut: [number, number][];
    total: [number, number][];
    equity: [number, number][];
    mutualFunds: [number, number][];
    privateEquity: [number, number][];
  };
}

const PortfolioSummaryMultiLineChart = ({ data }: Props) => {
  const { theme } = ThemeContainer();

  const [chartOptions, setChartOptions] = useState<any>({
    chart: {
      borderRadius: 5,
      backgroundColor: theme.palette.light.main,
      height: 650,
      spacing: [10, 10, 10, 10],
      marginRight: 50,
      style: {
        fontFamily: "Inter",
      },
    },
    tooltip: {
      formatter(this: any) {
        const ydata = this.points.map((dataItem: any) => dataItem?.y);

        const formattedDate = Highcharts.dateFormat("%b %e, %Y", this.x);

        let content = `<b>${formattedDate}</b><br/>`;

        this.points.forEach((point: any) => {
          content += `<span style="color:${point.color}">\u25CF</span> ${
            point.series.name
          }: <b>${moneyCommaSeparator(point.y)}</b><br/>`;
        });
        content += `<span style="color:"${
          theme.palette.secondary.main
        }"}">\u25CF</span> ${" Ledger Balance "}: <b>${moneyCommaSeparator(
          ydata[0] - ydata[1],
        )}</b><br/>`;

        return content;
      },
      pointFormatter(this: any) {
        return `<span style="color:${this?.series?.color}">${
          this?.series?.name
        }</span>: ${moneyCommaSeparator(this?.y)}<br/>`;
      },
      valueDecimals: 2,
      split: true,
    },
    title: {
      text: "",
      enabled: false,
      align: "left",
      style: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
        fontSize: 18,
      },
    },
    accessibility: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    credits: {
      text: "",
      enabled: false,
    },
    legend: {
      enabled: true,
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
      itemStyle: {
        color: theme.palette.dark.main,
        fontWeight: "bold",
      },
      symbolWidth: 20,
      symbolHeight: 10,
    },
    rangeSelector: {
      inputStyle: {
        color: theme.palette.primary.main,
        fontWeight: "normal",
      },
      labelStyle: {
        color: theme.palette.primary.main,
        fontWeight: "normal",
      },
      buttonTheme: {
        width: 50,
        fill: `#ffffff`,
        style: {
          background: theme.palette.light.main,
          color: theme.palette.grey[600],
          fontWeight: "normal",
          textTransform: "uppercase",
        },
        states: {
          select: {
            fill: `#F8FAFC`,
            style: {
              fontWeight: "normal",
              color: theme.palette.grey[900],
            },
          },
        },
      },
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        animation: false,
      },
    },
    xAxis: {
      type: "date",
      dateTimeLabelFormats: {
        day: "%b %e, %Y",
        week: "%b %e, %Y",
        month: "%b %Y",
        year: "%Y",
      },
      labels: {
        style: {
          color: theme.palette.primary.main,
          fontSize: "12px",
          fontWeight: "bold",
        },
        formatter(this: any) {
          return Highcharts.dateFormat("%b %e, %Y", this.value);
        },
      },
    },
    yAxis: {
      opposite: false,
      gridLineColor: `${theme.palette.border.main}44`,
      color: theme.palette.error.main,
      labels: {
        style: {
          color: theme.palette.primary.main,
          fontSize: "12px",
          fontWeight: "bold",
        },
        formatter(this: any) {
          return formatYAxisLabel(this?.value);
        },
      },
    },
  });

  useMemo(() => {
    setChartOptions(() => ({
      ...chartOptions,
      series: [
        {
          name: "Portfolio Value",
          data: handleReturnData(data?.total),
          color: theme.palette.primary.main,
          fillOpacity: 0.5,
        },
        {
          name: "Equity",
          data: handleReturnData(data?.equity),
          color: "#63a387",
        },
        {
          name: "Mutual Fund",
          data: handleReturnData(data?.mutualFunds),
          color: paletteLightTheme.palette.warning.main,
        },
        {
          name: "Private Equity",
          data: data?.privateEquity,
          color: "#8e4ec6",
        },
      ],
    }));
  }, [data]);

  return !data?.total ? (
    <NoData />
  ) : (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      constructorType="stockChart"
    />
  );
};

export default PortfolioSummaryMultiLineChart;
