import { GKButton, Grid2, SimpleLoader } from "components";
import { useCompanyInformation } from "hooks/useCompanyInformation";
import { FormProvider } from "react-hook-form";
import useDocumentTitle from "utils/useDocumentTitle";
import EditLogoModal from "./EditLogoModal";
import EssentialPageForm from "./EssentialPageForm";

const CompanyInformationPage = () => {
  useDocumentTitle("Company Information");

  const {
    companyFetching,
    data,
    showCropModal,
    setShowCropModal,
    form,
    onSubmit,
    handleUpdate,
  } = useCompanyInformation();

  if (companyFetching) return <SimpleLoader />;

  return (
    <>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          id="company-information-form"
        >
          <Grid2 container spacing={2}>
            <Grid2 size={12}>
              <EssentialPageForm handleUpdate={handleUpdate} />
            </Grid2>
            <Grid2
              size={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              my={1}
            >
              <GKButton
                type="submit"
                variant="contained"
                form={"company-information-form"}
              >
                Save
              </GKButton>
            </Grid2>
          </Grid2>
        </form>
      </FormProvider>

      {showCropModal && (
        <EditLogoModal
          open={showCropModal}
          setOpen={setShowCropModal}
          img={data?.data?.logo}
        />
      )}
    </>
  );
};

export default CompanyInformationPage;
