import { GridColDef } from "@mui/x-data-grid-pro";
import { useAppContext } from "AppContext";
import {
  Box,
  CgMathPlus,
  FormControl,
  FormControlLabel,
  GKButton,
  GKSearchSelect,
  GKSideModal,
  GKTextField,
  Grid2,
  Show,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "components";
import GKSimpleDataGrid from "components/GKSimpleDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import {
  HoldingDataInterface,
  PortfolioDetailsInterface,
} from "interfaces/Portfolio";
import { useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import { PORTFOLIO_HOLDING_PREVIEW_ID } from "utils/apiRoutes";
import { handleReturnPositiveNegative } from "utils/colorHelper";
import { paletteDarkTheme } from "utils/ColorTheme";
import { SMALL_CASE_DIRECT_TRADE_TRANSACTION_TYPE } from "utils/constants";
import { moneyCommaSeparator } from "utils/MathFunction";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import PortfolioPreviewCardDetails from "./PortfolioPreviewCardDetails";
import SendOrderTypeModal from "./SendOrderTypeModal";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  holdingSummaryDetails?: HoldingDataInterface;
  portfolioDetailData?: PortfolioDetailsInterface;
}

const PortfolioHoldingPreviewSideModal = (props: Props) => {
  const { open, setOpen, holdingSummaryDetails, portfolioDetailData } = props;

  const {
    state: { companies },
  } = useAppContext();

  const { theme } = ThemeContainer();

  const { id } = useParams();

  const [sendOrderModal, setSendOrderModal] = useState(false);
  const [showWeighage, setShowWeighage] = useState(false);

  const { data, isFetching } = useFetchQuery({
    key: ["PORTFOLIO_HOLDING_PREVIEW"],
    route: PORTFOLIO_HOLDING_PREVIEW_ID(id),
  });

  const form = useForm({
    defaultValues: {
      portfolioId: +id,
      isEmail: true,
      rationale: "",
      isOrderExecution: true,
      stockList: [],
    },
    values: {
      portfolioId: +id,
      isEmail: true,
      rationale: "",
      isOrderExecution: true,
      stockList: data?.data?.data.map((dataItem: any) => {
        return {
          ...dataItem,
          gcode: dataItem.gcode,
          transactionType: "",
          quantity: "",
          price: dataItem.cmp,
          amount: "",
        };
      }),
    },
    mode: "onChange",
  });

  const { fields, replace } = useFieldArray({
    control: form.control,
    name: "stockList",
  });

  const watchStockList = form.watch("stockList");

  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchStockList[index],
  }));

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: `Company (${data?.data?.totalCount})`,
      disableColumnMenu: true,
      width: 230,
      renderCell: (params) => {
        const { rowIndex, rawQuantity } = params?.row;

        return rawQuantity ? (
          <Grid2
            container
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography>{params?.row?.name}</Typography>
            <Tooltip title="Long Term Holding" arrow placement="top">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: theme.palette.primary.main,
                  pr: 3,
                }}
              >
                {params?.row?.ltHoldingsCount > 0 && (
                  <>
                    <img loading="lazy" src="/Logos/longTerm.svg" alt="" />
                    &nbsp;
                    {params?.row?.instSubType !== "CE" ||
                    params?.row.isin === "INGridkeyCash"
                      ? params?.row?.ltHoldingsCount
                      : params?.row?.ltHoldingsCount}
                  </>
                )}
              </Box>
            </Tooltip>
          </Grid2>
        ) : (
          <Controller
            name={`stockList.${rowIndex}.gcode`}
            control={form.control}
            rules={{
              required: +form.getValues("stockList")[rowIndex]?.quantity > 0,
            }}
            render={({ field, fieldState: { error } }) => (
              <GKSearchSelect
                {...field}
                sx={{ width: "100%" }}
                size="small"
                disabled={params.row.cmp === 0}
                disableClearable={!field.value}
                options={companies}
                onChange={(e, val) => {
                  field.onChange(val?.value);
                  form.setValue(`stockList.${rowIndex}.cmp`, val?.cmp);
                  form.setValue(`stockList.${rowIndex}.price`, val?.cmp);
                  form.trigger([`stockList.${rowIndex}.price`]);
                  replace(form?.getValues("stockList"));
                }}
                value={companies?.find(
                  (data: any) => data.value === field.value,
                )}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select Type",
                      value: field.value,
                    })}
                    error={!!error}
                  />
                )}
                clearIcon={
                  <Typography
                    fontSize={10}
                    fontWeight={600}
                    color={paletteDarkTheme.palette.primary.main}
                  >
                    Clear
                  </Typography>
                }
              />
            )}
          />
        );
      },
    },
    {
      headerName: "Quantity",
      minWidth: 80,
      field: "rawQuantity",
      disableColumnMenu: true,
      type: "number",
      renderCell: (params) => (
        <Typography>
          {params?.row?.rawQuantity ? params?.row?.rawQuantity : ""}
        </Typography>
      ),
    },
    {
      headerName: "CMP",
      minWidth: 110,
      field: "cmp",
      disableColumnMenu: true,
      type: "number",
      valueGetter: (params) => params?.row?.cmp || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      headerName: "Avg. Buy Price",
      minWidth: 150,
      field: "avgBuyPrice",
      disableColumnMenu: true,
      type: "number",
      valueGetter: (params) => params?.row?.avgBuyPrice || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      headerName: "Invested Value",
      minWidth: 150,
      field: "investedAmount",
      disableColumnMenu: true,
      type: "number",
      valueGetter: (params) => params?.row?.investedAmount || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      headerName: "Current Value",
      minWidth: 140,
      field: "currentAmount",
      disableColumnMenu: true,
      type: "number",
      valueGetter: (params) => params?.row?.currentAmount || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      disableColumnMenu: true,
      headerName: "Unrealised Gain",
      minWidth: 140,
      field: "unrealisedGain",
      type: "number",
      renderCell: (params) => (
        <Typography
          color={handleReturnPositiveNegative(params?.row?.unrealisedGain)}
        >
          {moneyCommaSeparator(params?.row?.unrealisedGain)}
        </Typography>
      ),
    },
    {
      disableColumnMenu: true,
      headerName: "Unrealised Gain %",
      minWidth: 150,
      field: "absoluteReturn",
      type: "number",
      renderCell: (params) => (
        <Typography
          color={handleReturnPositiveNegative(params?.row?.absoluteReturn)}
        >
          ({moneyCommaSeparator(params?.row?.absoluteReturn)}%)
        </Typography>
      ),
    },
    {
      headerName: "Weightage(%)",
      minWidth: 130,
      field: "weightage",
      type: "number",
      disableColumnMenu: true,
      valueGetter: (params) => params?.row?.weightage || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      // headerName: showWeighage ? "Model Weightage(%)" : "Current Weightage(%)",
      headerName: "Model Weightage(%)",
      minWidth: 150,
      field: showWeighage ? "modelWeightage" : "currentWeightage",
      type: "number",
      disableColumnMenu: true,
      valueGetter: (params) =>
        showWeighage
          ? params?.row?.modelWeightage || "0"
          : params?.row?.currentWeightage || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      headerName: "Deviation(%)",
      minWidth: 80,
      field: "deviation",
      type: "number",
      disableColumnMenu: true,
      valueGetter: (params) =>
        moneyCommaSeparator(
          params?.row?.weightage -
            (showWeighage
              ? params?.row?.modelWeightage || "0"
              : params?.row?.currentWeightage || "0"),
        ),
    },
    {
      headerName: "Transaction Type",
      minWidth: 160,
      field: "transactionType",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { rowIndex } = params.row;
        return (
          <Controller
            name={`stockList.${rowIndex}.transactionType`}
            control={form.control}
            rules={{
              required: +form.getValues("stockList")[rowIndex]?.quantity > 0,
            }}
            render={({ field, fieldState: { error } }) => (
              <GKSearchSelect
                {...field}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": { padding: "9px !important" },
                }}
                size="small"
                disabled={params.row.cmp === 0}
                disableClearable={!field.value}
                options={SMALL_CASE_DIRECT_TRADE_TRANSACTION_TYPE}
                onChange={(e, val) => {
                  field.onChange(val?.value);
                }}
                value={SMALL_CASE_DIRECT_TRADE_TRANSACTION_TYPE?.find(
                  (data: any) => data.value === field.value,
                )}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select Type",
                      value: field.value,
                    })}
                    error={!!error}
                  />
                )}
                clearIcon={
                  <Typography
                    fontSize={10}
                    fontWeight={600}
                    color={paletteDarkTheme.palette.primary.main}
                  >
                    Clear
                  </Typography>
                }
              />
            )}
          />
        );
      },
    },
    {
      headerName: "Quantity",
      minWidth: 130,
      field: "quantity",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { rowIndex } = params.row;

        return (
          <Controller
            name={`stockList.${rowIndex}.quantity`}
            control={form.control}
            rules={{
              required:
                !!form.getValues("stockList")[rowIndex]?.transactionType,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl variant="standard" fullWidth>
                <GKTextField
                  {...field}
                  size="small"
                  disabled={params.row.cmp === 0}
                  type="number"
                  placeholder="Enter Quantity"
                  onChange={(e: any) => {
                    field.onChange(e.target.valueAsNumber);

                    form.setValue(
                      `stockList.${rowIndex}.amount`,
                      (e.target.valueAsNumber * +params.row.cmp).toFixed(2),
                    );
                  }}
                  error={!!error}
                />
              </FormControl>
            )}
          />
        );
      },
    },
    {
      headerName: "Amount",
      minWidth: 130,
      field: "amount",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const { rowIndex } = params.row;

        return (
          <Controller
            name={`stockList.${rowIndex}.amount`}
            control={form.control}
            render={({ field, fieldState: { error } }) => (
              <FormControl variant="standard" fullWidth>
                <GKTextField
                  {...field}
                  size="small"
                  disabled={params.row.cmp === 0}
                  type="number"
                  placeholder="Enter Amount"
                  onChange={(e: any) => {
                    field.onChange(e.target.valueAsNumber);
                    const quantity = Math.floor(
                      e.target.valueAsNumber / +params.row.cmp,
                    );

                    form.setValue(`stockList.${rowIndex}.quantity`, quantity);
                  }}
                  error={!!error}
                />
              </FormControl>
            )}
          />
        );
      },
    },
  ];

  return (
    <GKSideModal
      open={open}
      setOpen={setOpen}
      onClose={() => setOpen(false)}
      width="100%"
      modalTitle="Generate Advice"
      titleComponent={
        <FormControlLabel
          style={{
            marginLeft: 2,
          }}
          control={
            <Switch
              onChange={(event) => {
                setShowWeighage(event.target.checked);
              }}
              checked={showWeighage}
            />
          }
          labelPlacement="end"
          label={
            <b
              style={{
                fontSize: 12,
              }}
            >
              Show Allocated Model Weightage
            </b>
          }
        />
      }
      footerButton={
        <Grid2 container justifyContent={"space-between"}>
          <GKButton
            variant="outlined"
            color="dark"
            startIcon={<CgMathPlus size={18} />}
            onClick={async () => {
              form.setValue("stockList", [
                ...form.getValues("stockList"),
                {
                  gcode: "",
                  transactionType: "",
                  quantity: "",
                  price: "",
                  amount: "",
                },
              ]);
            }}
          >
            Add Company
          </GKButton>
          <GKButton
            variant="contained"
            onClick={async () => {
              const isValid = await form.trigger(["stockList"]);

              if (isValid) setSendOrderModal(true);
            }}
          >
            Send
          </GKButton>
        </Grid2>
      }
    >
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <PortfolioPreviewCardDetails
            portfolioDetailData={portfolioDetailData}
            holdingSummaryDetails={holdingSummaryDetails}
          />
        </Grid2>
        <Grid2 size={12}>
          <FormProvider {...form}>
            <GKSimpleDataGrid
              loading={isFetching}
              showHeaderGrid={false}
              columns={columns}
              getRowClassName={(params) => {
                return params.row.cmp === 0 ? "small-case-row-background" : "";
              }}
              rows={
                controlledFields?.map((d: any, ind: number) => {
                  return {
                    ...d,
                    rowIndex: ind,
                  };
                }) || []
              }
              pagination
              pinnedColumns={{
                left: ["name"],
                right: ["transactionType", "quantity", "amount"],
              }}
              pageSize={100000}
              maxHeight="calc(100vh - 344px)"
            />

            <Show.When isTrue={sendOrderModal}>
              <SendOrderTypeModal
                open={sendOrderModal}
                setOpen={setSendOrderModal}
                setHoldingOpen={setOpen}
              />
            </Show.When>
          </FormProvider>
        </Grid2>
      </Grid2>
    </GKSideModal>
  );
};

export default PortfolioHoldingPreviewSideModal;
