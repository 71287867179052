import { useMutation } from "@tanstack/react-query";

import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  BRANCH_HAS_MANAGER,
  CUSTOM_AGREEMENT_MODAL_PORTFOLIO,
} from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { SelectOption } from "utils/common";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { emailRegex, panRegex } from "utils/validationSchema";
import { array, object, string } from "yup";
import { useFetchQuery } from "./useQueries";

interface Props {
  setOpen: any;
}

interface FormValues {
  branch?: string;
  family?: string;
  personalDetails?: {
    id?: number;
    bankAccount?: any;
    email?: string;
    name?: string;
    gender?: string;
    maritalStatus?: string;
    fatherName?: string;
    motherName?: string;
    dateOfBirth?: string;
    nomineeName?: string;
    address?: string;
    income?: number;
    occupation?: string;
    pan?: string;
  };
  portfolioCount?: number;
  phone?: string;
  countryCode?: string;
  type?: string;
  code?: string;
  basket?: number[];
}

const useAddClient = ({ setOpen }: Props) => {
  const [branchList, setBranchList] = useState<SelectOption[]>([]);

  const [isBranchCreateModalOpen, setIsBranchCreateModalOpen] =
    useState<boolean>(false);

  const [isFamilyCreateModalOpen, setIsFamilyCreateModalOpen] =
    useState<boolean>(false);

  // =====================================================================================================================
  const { isFetching: branchStateLoading } = useFetchQuery({
    key: [QueryKeys.GET_ALL_BRANCHES],
    route: BRANCH_HAS_MANAGER,
    success: (data: any) => {
      setBranchList(
        data?.data?.map((x: any) => ({
          name: x.name,
          value: x.id,
        })),
      );
    },
  });

  const { data: basketList, isFetching: modalPortfolioLoading } = useFetchQuery(
    {
      key: ["CUSTOM_AGREEMENT_MODAL_PORTFOLIOS"],
      route: CUSTOM_AGREEMENT_MODAL_PORTFOLIO,
    },
  );

  // =====================================================================================================================
  const { mutate: handleAddClient, isLoading: isSubmitting } = useMutation({
    mutationFn: (clientState: any) =>
      axiosInstance.post("/client/", {
        ...clientState,
        branch: branchList[0]?.value,
        family: clientState.family ? [clientState.family] : [],
      }),
    onSuccess: (response) => {
      setOpen(false);
      toast.success(
        response?.data?.message || "Action completed successfully.",
      );
      refetchQuery(QueryKeys.CLIENTS_LIST);
      refetchQuery(QueryKeys.CLIENTS_SHORT_DATA);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const validationSchema = object({
    personalDetails: object({
      name: string()
        .required("Please enter name")
        .test(
          "no-empty-spaces",
          "Field cannot contain only spaces",
          (value) => {
            return value && value.trim().length > 0;
          },
        ),
      email: string()
        .required("Please enter Email")
        .matches(emailRegex, "Please enter a valid email"),
      pan: string()
        .matches(panRegex, "PAN should have this format ABCDE1234A")
        .min(10, "PAN should have 10 character")
        .max(10, "PAN should have 10 character"),
    }),
    phone: string().required("Please enter a valid phone number"),
    code: string().when("type", {
      is: (params: string) => params === "PMS",
      then: (schema) => schema.required("Please Enter Client Code"),
      otherwise: (schema) => schema.notRequired(),
    }),
    basket: array().when("type", {
      is: (params: string) => params === "PMS",
      then: (schema) => schema.min(1, "Please select at least one basket"),
      otherwise: (schema) => schema.min(0),
    }),
  });

  // =====================================================================================================================
  const form = useForm<FormValues>({
    defaultValues: {
      branch: "" as any,
      family: "" as any,
      personalDetails: {
        id: 0,
        bankAccount: null,
        email: "",
        name: "",
        gender: "",
        maritalStatus: "",
        fatherName: "",
        motherName: "",
        dateOfBirth: null,
        nomineeName: "",
        address: "",
        income: 0,
        occupation: "",
        pan: "",
      },
      portfolioCount: 0,
      phone: "",
      countryCode: "+91",
      type: "ADVISORY",
      code: "",
      basket: [],
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (values: FormValues) => {
    handleAddClient(values);
  };

  // =====================================================================================================================
  const loading = branchStateLoading || modalPortfolioLoading;

  return {
    isSubmitting,
    setIsBranchCreateModalOpen,
    setIsFamilyCreateModalOpen,
    branchList,
    isBranchCreateModalOpen,
    isFamilyCreateModalOpen,
    loading,
    form,
    onSubmit,
    basketList,
  };
};

export default useAddClient;
