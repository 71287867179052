/* eslint-disable import/no-named-as-default-member */
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  GKButton,
  GKClientDataGrid,
  GKNoDataFound,
  GKOverlayLoader,
  Grid2,
  MdOutlineCheck,
  RxCross2,
  StatusField,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { CLIENT_BY_ID, KYC_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { handleDowloadFile } from "utils/fileHelper";
import { extraColumnsField, matchDate, refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";
import ComplianceKYCModal from "./ComplianceKYCModal";
import PreviewKYCModal from "./PreviewKYCModal";

const ComplianceKYCPage = () => {
  const { id } = useParams();

  const { theme } = ThemeContainer();

  const [addClientKyc, setAddClientKyc] = useState(false);

  const [previewDocument, setPreviewDocument] = useState(false);

  const [selectedKyc, setSelectedKyc] = useState(null);

  const [selectedDocument, setSelectedDocument] = useState(null);

  const [allKYCdata, setAllKYCData] = useState<any>([]);

  const [isRejectOrAccept, setIsRejectOrAccept] = useState("");

  const [selectedIndex, setSelectedIndex] = useState(null);

  const { refetch } = useFetchQuery({
    key: [QueryKeys.KYC_CLIENT],
    route: KYC_BY_ID(id),
    success: (data: any) => {
      setSelectedKyc(data?.data?.data[0]);
      setAllKYCData(data?.data?.data);
    },
  });

  const { data: clientData } = useFetchQuery({
    key: [QueryKeys.CLIENT_DETAILS],
    route: CLIENT_BY_ID(id),
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (status: any) =>
      axiosInstance.post(`/kyc/status/`, {
        kyc_id: selectedKyc?.kycId,
        status,
      }),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery(QueryKeys.CLIENT_DETAILS);
      setIsRejectOrAccept("");
      setSelectedIndex(null);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
      setIsRejectOrAccept("");
      setSelectedIndex(null);
    },
  });

  const { mutate: handleDownloadKyc, isLoading: kycDownloadLoading } =
    useMutation({
      mutationFn: (dataId: any) => {
        return axiosInstance.get(`/download-kyc-doc/${dataId}/`, {
          responseType: "arraybuffer",
        });
      },
      retry: false,
      onSuccess: (data: any) => {
        handleDowloadFile({
          fileData: data?.data,
          applicaionType: "application/zip",
          fileName: "kyc_documents",
          extensionType: "zip",
        });
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  return (
    <>
      {kycDownloadLoading && <GKOverlayLoader />}
      {allKYCdata?.length === 0 ? (
        <GKNoDataFound
          additionalComponent={
            <GKButton
              variant="contained"
              onClick={() => {
                setAddClientKyc(true);
              }}
            >
              Request KYC
            </GKButton>
          }
        />
      ) : (
        <Grid2 container spacing={1}>
          <Grid2 size={5} display={"flex"} flexDirection={"column"}>
            {allKYCdata?.map((dataItem: any, index: any) => {
              return (
                <GKButton
                  fullWidth
                  variant="outlined"
                  color="dark"
                  key={index}
                  style={{
                    margin: "0 0 4px 0",
                    padding: 16,
                  }}
                  onClick={() => {
                    setSelectedKyc(dataItem);
                  }}
                >
                  <Grid2 container spacing={1}>
                    <Grid2 size={8}>
                      <StatusField
                        statusString={dataItem?.kycStatus || "Pending"}
                      />
                    </Grid2>
                    <Grid2 size={4} textAlign={"right"}>
                      <Typography
                        fontWeight={400}
                        fontSize={12}
                        color={theme.palette.secondaryText.main}
                      >
                        {matchDate(dataItem?.createdAt)}
                      </Typography>
                    </Grid2>
                    <Grid2 container size={7}>
                      <Grid2
                        size={12}
                        textAlign={"left"}
                        color={
                          selectedKyc?.id !== dataItem?.id
                            ? theme.palette.secondaryText.main
                            : "#121926"
                        }
                      >
                        <Typography fontWeight={600} fontSize={16}>
                          {dataItem?.identificationType}
                        </Typography>
                      </Grid2>
                      <Grid2 size={12} textAlign={"left"}>
                        <Typography
                          fontSize={12}
                          fontWeight={500}
                          color={
                            selectedKyc?.id !== dataItem?.id
                              ? theme.palette.secondaryText.main
                              : "#000"
                          }
                        >
                          <span
                            style={{
                              fontWeight: 400,
                              color: theme.palette.secondaryText.main,
                              fontSize: 12,
                            }}
                          >
                            Id:{" "}
                          </span>
                          {dataItem?.kycId}
                        </Typography>
                      </Grid2>
                    </Grid2>
                    {
                      <Grid2
                        size={5}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                        gap={1}
                      >
                        <LoadingButton
                          loading={
                            isRejectOrAccept === "APPROVED" &&
                            isLoading &&
                            selectedIndex === index
                          }
                          color="success"
                          variant="outlined"
                          sx={{ p: 1, minWidth: "1px" }}
                          disabled={
                            dataItem?.kycStatus !== "requested" &&
                            dataItem?.kycStatus !== "approval_pending"
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            mutate("APPROVED");
                            setIsRejectOrAccept("APPROVED");
                            setSelectedIndex(index);
                          }}
                        >
                          <MdOutlineCheck />
                        </LoadingButton>
                        <LoadingButton
                          loading={
                            isRejectOrAccept === "REJECTED" &&
                            isLoading &&
                            selectedIndex === index
                          }
                          color="error"
                          variant="outlined"
                          sx={{ p: 1, minWidth: "1px" }}
                          disabled={
                            dataItem?.kycStatus !== "requested" &&
                            dataItem?.kycStatus !== "approval_pending"
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            mutate("REJECTED");
                            setIsRejectOrAccept("REJECTED");
                            setSelectedIndex(index);
                          }}
                        >
                          <RxCross2 />
                        </LoadingButton>
                      </Grid2>
                    }
                  </Grid2>
                </GKButton>
              );
            })}
          </Grid2>
          <Grid2 size={7}>
            <GKClientDataGrid
              rows={selectedKyc?.documents || []}
              columns={[
                {
                  ...extraColumnsField("preview", "preview", 0, 1),
                  renderCell: (params) => {
                    const { document } = params?.row;
                    return (
                      <img
                        height={40}
                        width={"auto"}
                        alt=" "
                        onError={({ currentTarget }) => {
                          currentTarget.src = "/Logos/errorImg.svg";
                        }}
                        src={document}
                      />
                    );
                  },
                },
                {
                  ...extraColumnsField("Document Type", "type", 0, 1),
                  renderCell: (params) => {
                    const { type } = params?.row;
                    return (
                      <Typography textTransform={"capitalize"}>
                        {type}
                      </Typography>
                    );
                  },
                },
                {
                  ...extraColumnsField("Status", "status", 0, 1),

                  renderCell: (params) => {
                    const { status } = params?.row;
                    return <StatusField statusString={status} />;
                  },
                },
              ]}
              name="clientKYC"
              headerComponent={
                <div style={{ display: "flex", gap: 8 }}>
                  <GKButton
                    variant="contained"
                    onClick={() => {
                      setAddClientKyc(true);
                    }}
                  >
                    Request KYC
                  </GKButton>
                  <GKButton
                    variant="outlined"
                    onClick={() => {
                      handleDownloadKyc(selectedKyc?.id);
                    }}
                  >
                    Download All Documents
                  </GKButton>
                </div>
              }
              onRowClick={(params) => {
                setSelectedDocument(params?.row);
                setPreviewDocument(true);
              }}
            />
          </Grid2>
        </Grid2>
      )}

      {previewDocument && (
        <PreviewKYCModal
          open={previewDocument}
          setOpen={setPreviewDocument}
          data={selectedDocument}
        />
      )}

      {addClientKyc && (
        <ComplianceKYCModal
          setOpen={setAddClientKyc}
          open={addClientKyc}
          showData={allKYCdata}
          refetch={refetch}
          clientData={clientData?.data}
        />
      )}
    </>
  );
};

export default ComplianceKYCPage;
