/* eslint-disable @typescript-eslint/no-unused-expressions */
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  AiOutlineDelete,
  Button,
  Card,
  CgMathPlus,
  DeleteIcon,
  Divider,
  DragDrop,
  FiTrash2,
  FormControl,
  GKButton,
  GKClientDataGrid,
  GKEditor,
  GKSearchSelect,
  GKTextField,
  Grid2,
  IconButton,
  IoCloudDownloadOutline,
  MenuItem,
  Show,
  styled,
  Switch,
  Typography,
} from "components";
import GKSelect from "components/GKSelect";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { replace, split } from "lodash";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { paletteLightTheme } from "utils/ColorTheme";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import { GET_COMPANY_LIST } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import {
  INVESTMENT_STRATEGIES,
  minimumInvestmentData,
  MODEL_PORTFOLIO_REBALANCING_TIME,
} from "utils/constants";
import { handleReturnFormData } from "utils/formHelpers";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import EquityBasketFeePlanSelection from "./EquityBasketFeePlanSelection";
import {
  basketTypeDropdown,
  riskDropdown,
  subscriptionDropdown,
  timePerdioDropdown,
  volatilityDropdown,
} from "./equityBasketHelper";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const EquityBasketEdit = (props: any) => {
  const { updateData } = props;

  useDocumentTitle("Edit Basket");

  const [uploadError, setUploadError] = useState<string | null>(null);

  const [openFeePlanModal, setOpenFeePlanModal] = useState(false);

  const { id } = useParams();

  const { theme } = ThemeContainer();

  const navigate = useNavigate();
  /** ========================================================================================================= */
  const form = useForm({
    defaultValues: {
      name: "",
      code: "",
      rebalanceTime: "",
      overview: "",
      investmentPhilosophy: "",
      benchmark: "",
      managedBy: "",
      rationale: "",
      minimumInvestmentValueCheck: "true",
      ownerType: "",
      investmentStyle: [],
      managementStyle: "",
      feePlansList: [],
      riskProfile: [],
      researchFiles: [],
      feePlans: [],
      timePeriod: "",
      volatility: "",
      basketType: "",
      factSheet: [],
    },
  });

  /** ========================================================================================================= */
  const { data: companyMasterData, isFetching } = useFetchQuery({
    key: [QueryKeys.COMPANY_NAMES],
    route: GET_COMPANY_LIST(["INDEX"]),
  });
  /** ========================================================================================================= */
  const { mutate: publishFile } = useMutation({
    mutationKey: ["PUBLISH_RESEARCH_FILES"],
    mutationFn: (fileId) =>
      axiosInstance.patch(
        `/model_portfolio/${id}/update_meta_research_file/?file_id=${fileId}`,
      ),
    onSuccess: (response) => {
      refetchQuery("GET_MODEL_PORTFOLIO_DETAILS_DATA");
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "File delete failed.");
    },
  });
  /** ========================================================================================================= */
  const { mutate: deleteFile } = useMutation({
    mutationKey: ["DELETE_FILE"],
    mutationFn: (fileId) =>
      axiosInstance.delete(
        `/model_portfolio/${id}/delete_meta_research_file/?file_id=${fileId}`,
      ),
    onSuccess: (response) => {
      refetchQuery("GET_MODEL_PORTFOLIO_DETAILS_DATA");
      form.setValue("researchFiles", []);
      toast.success(response.data?.message || "Action completed successfully.");
      toast.dismiss();
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "File delete failed.");
      toast.dismiss();
    },
  });
  /** ========================================================================================================= */
  const { mutate: handleSetFeeDefault } = useMutation({
    mutationKey: ["MAKE_FEE_PLAN_DEFAULT"],
    mutationFn: (feePlan) =>
      axiosInstance.patch(`/model_portfolio/${id}/fee_plan/`, {
        feePlan,
      }),
    onSuccess: (response) => {
      refetchQuery("GET_MODEL_PORTFOLIO_DETAILS_DATA");
      form.setValue("researchFiles", []);
      toast.success(response.data?.message || "Action completed successfully.");
      toast.dismiss();
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "File delete failed.");
      toast.dismiss();
    },
  });

  /** ========================================================================================================= */
  const { mutate: handleCreateEquityBasket, isLoading: createLoading } =
    useMutation({
      mutationFn: (data: any) => axiosInstance.post(`/model_portfolio/`, data),
      onSuccess: (response) => {
        refetchQuery("GET_MODEL_PORTFOLIO_DETAILS_DATA");
        form.setValue("researchFiles", []);
        toast.success(
          response.data?.message || "Action completed successfully.",
        );
        navigate(-1);
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  /** ========================================================================================================= */
  const { mutate: handleUpdateEquityBasket, isLoading: updateLoading } =
    useMutation({
      mutationFn: (data: any) =>
        axiosInstance.patch(`/model_portfolio/${id}/`, data),
      onSuccess: (response) => {
        refetchQuery("GET_MODEL_PORTFOLIO_DETAILS_DATA");
        form.setValue("researchFiles", []);
        toast.success(
          response.data?.message || "Action completed successfully.",
        );
        if (openFeePlanModal) {
          setOpenFeePlanModal(false);
        } else {
          navigate(-1);
        }
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  /** ========================================================================================================= */
  const { data } = useFetchQuery({
    key: ["GET_MODEL_PORTFOLIO_DETAILS_DATA"],
    route: `/model_portfolio/${id}/details/`,
    enabled: Boolean(id),
    success: (res: any) => {
      form.reset(res.data);
      form.setValue("researchFiles", []);
      form.setValue("factSheet", [
        {
          name: replace(
            split(res.data?.factSheet, "?")[0],
            "https://s3.ap-south-1.amazonaws.com/gridkey-file-upload-stage/fact_sheets/",
            "",
          ),
          file: res.data?.factSheet,
        },
      ]);
    },
  });

  /** ========================================================================================================= */
  const handleMetaSubmit = (values: {
    name: any;
    code: any;
    rebalanceTime: any;
    overview: any;
    investmentPhilosophy: any;
    benchmark: any;
    managedBy: any;
    rationale: any;
    minimumInvestmentValueCheck: string;
    ownerType: any;
    investmentStyle: any[];
    managementStyle: any;
    feePlansList: any[];
    riskProfile: any[];
    researchFiles: any[];
    feePlans: any[];
    timePeriod: any;
    volatility: any;
    basketType: any;
    factSheet: any;
  }) => {
    console.log("factSheet", values.factSheet[0]);
    if (data?.data?.id) {
      handleUpdateEquityBasket(
        handleReturnFormData({
          obj: { ...values, factSheet: values.factSheet[0] },
          dirtyFields: Object.keys(form.formState.dirtyFields),
          multipleFilesField: "researchFiles",
          isEditForm: true,
        }),
      );
    } else {
      handleCreateEquityBasket(
        handleReturnFormData({
          obj: { ...values, factSheet: values.factSheet[0] },
          dirtyFields: Object.keys(form.formState.dirtyFields),
          multipleFilesField: "researchFiles",
          isEditForm: false,
        }),
      );
    }
  };

  form.watch();

  return (
    <DashboardLayout title="Basket Edit" loading={isFetching}>
      <FormProvider {...form}>
        <Grid2 container spacing={1}>
          <Grid2
            size={12}
            overflow={"hidden auto"}
            minHeight={`calc(100vh - 129px)`}
            maxHeight={`calc(100vh - 129px)`}
            pr={1}
          >
            <form
              id="equity-basket-meta-form"
              onSubmit={form.handleSubmit(handleMetaSubmit)}
            >
              <Grid2 container spacing={1}>
                <Grid2 size={2.4}>
                  <FormControl variant="standard" fullWidth>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Please Enter Name",
                        },
                      }}
                      name="name"
                      control={form.control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <GKTextField
                            {...field}
                            requiredField
                            inputLabel="Basket Name"
                            placeholder="Basket Name"
                            error={!!error}
                            helperText={error?.message}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Grid2>
                <Grid2 size={2.4}>
                  <FormControl variant="standard" fullWidth>
                    <Controller
                      name="code"
                      control={form.control}
                      rules={{
                        required: {
                          value: true,
                          message: "Please Enter Name",
                        },
                      }}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <GKTextField
                            {...field}
                            requiredField
                            inputLabel="Basket Code"
                            placeholder="Basket Code"
                            error={!!error}
                            helperText={error?.message}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Grid2>
                <Grid2 size={2.4}>
                  <Controller
                    name="basketType"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <GKSearchSelect
                          {...field}
                          onChange={(e, val) => {
                            field.onChange(val?.value);
                          }}
                          disableClearable={false}
                          value={
                            basketTypeDropdown?.find(
                              (item) => item?.value === field.value,
                            )?.name || []
                          }
                          options={basketTypeDropdown}
                          getOptionLabel={(opt) => opt?.name || opt}
                          inputLabel="Basket Type"
                          renderInput={(params) => {
                            return (
                              <GKTextField
                                {...params}
                                placeholder={handleReturnAutocompletePlaceholder(
                                  {
                                    placeholder: "Select Basket Type",
                                    value: field.value,
                                  },
                                )}
                              />
                            );
                          }}
                        />
                      );
                    }}
                  />
                </Grid2>
                <Grid2 size={2.4}>
                  <Controller
                    name="managementStyle"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <GKSearchSelect
                          {...field}
                          onChange={(e: any, val) => {
                            field.onChange(val);
                          }}
                          disableClearable={false}
                          value={field.value}
                          options={subscriptionDropdown}
                          inputLabel="Select Product Styles"
                          renderInput={(params) => {
                            return (
                              <GKTextField
                                {...params}
                                placeholder={handleReturnAutocompletePlaceholder(
                                  {
                                    placeholder: "Select Product Styles",
                                    value: field.value,
                                  },
                                )}
                              />
                            );
                          }}
                        />
                      );
                    }}
                  />
                </Grid2>
                <Grid2 size={2.4}>
                  <FormControl variant="standard" fullWidth>
                    <Controller
                      name="managedBy"
                      control={form.control}
                      render={({ field }) => {
                        return (
                          <GKTextField
                            {...field}
                            inputLabel="Managed By"
                            placeholder="Managed By"
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Grid2>
                <Grid2 size={12}>
                  <FormControl variant="standard" fullWidth>
                    <Controller
                      name="overview"
                      control={form.control}
                      render={({ field }) => {
                        return (
                          <GKTextField
                            {...field}
                            inputLabel="Overview"
                            placeholder="Overview"
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Grid2>
                <Grid2 size={12}>
                  <Controller
                    name="rationale"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <GKEditor
                          {...field}
                          inputLabel="Rationale"
                          toolbar={[
                            "undo",
                            "redo",
                            "|",
                            "heading",
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                            "|",
                            "fontColor",
                            "fontBackgroundColor",
                            "|",
                            "alignment",
                            "link",
                            "|",
                            "bulletedList",
                            "numberedList",
                          ]}
                        />
                      );
                    }}
                  />
                </Grid2>
                <Grid2 size={12}>
                  <Controller
                    name="investmentPhilosophy"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <GKEditor
                          {...field}
                          inputLabel="Investment Philosophy"
                          toolbar={[
                            "undo",
                            "redo",
                            "|",
                            "heading",
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                            "|",
                            "fontColor",
                            "fontBackgroundColor",
                            "|",
                            "alignment",
                            "link",
                            "|",
                            "bulletedList",
                            "numberedList",
                          ]}
                        />
                      );
                    }}
                  />
                </Grid2>
                <Grid2 size={4}>
                  <Controller
                    name="riskProfile"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <GKSearchSelect
                          {...field}
                          limitTags={1}
                          multiple
                          onChange={(e, val) => {
                            field.onChange(val);
                          }}
                          options={riskDropdown}
                          disableClearable={false}
                          inputLabel="Risk Profile"
                          renderInput={(params) => {
                            return (
                              <GKTextField
                                {...params}
                                placeholder={handleReturnAutocompletePlaceholder(
                                  {
                                    placeholder: "Select Risk Profile",
                                    value: field.value,
                                  },
                                )}
                              />
                            );
                          }}
                        />
                      );
                    }}
                  />
                </Grid2>
                <Grid2 size={4}>
                  <Controller
                    name="volatility"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <GKSearchSelect
                          {...field}
                          onChange={(e, val) => {
                            field.onChange(val?.value);
                          }}
                          value={
                            volatilityDropdown?.find(
                              (da) => da?.value === field?.value,
                            )?.name || []
                          }
                          options={volatilityDropdown}
                          disableClearable={false}
                          getOptionLabel={(opt) => opt?.name || opt}
                          inputLabel="Volatility"
                          renderInput={(params) => {
                            return (
                              <GKTextField
                                {...params}
                                placeholder={handleReturnAutocompletePlaceholder(
                                  {
                                    placeholder: "Select Volatility",
                                    value: field.value,
                                  },
                                )}
                              />
                            );
                          }}
                        />
                      );
                    }}
                  />
                </Grid2>
                <Grid2 size={4}>
                  <Controller
                    name="timePeriod"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <GKSearchSelect
                          {...field}
                          onChange={(e, val) => {
                            field.onChange(val?.value);
                          }}
                          value={
                            timePerdioDropdown?.find(
                              (da) => da?.value === field?.value,
                            )?.name || []
                          }
                          options={timePerdioDropdown}
                          disableClearable={false}
                          getOptionLabel={(opt) => opt?.name || opt}
                          inputLabel="Time Horizon"
                          renderInput={(params) => {
                            return (
                              <GKTextField
                                {...params}
                                placeholder={handleReturnAutocompletePlaceholder(
                                  {
                                    placeholder: "Select Time Horizon",
                                    value: field.value,
                                  },
                                )}
                              />
                            );
                          }}
                        />
                      );
                    }}
                  />
                </Grid2>
                <Grid2 size={4}>
                  <Controller
                    name="rebalanceTime"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <FormControl variant="standard" fullWidth>
                          <GKSelect
                            {...field}
                            inputLabel="Rebalancing Time"
                            placeholder="Rebalancing Time"
                            displayEmpty
                            renderValue={(fieldValue: any) =>
                              fieldValue || "Select time"
                            }
                          >
                            <MenuItem style={{ display: "none" }} value="">
                              Select Rebalance Time
                            </MenuItem>
                            {MODEL_PORTFOLIO_REBALANCING_TIME?.map(
                              (dataItem, i) => (
                                <MenuItem key={i} value={dataItem.value}>
                                  {dataItem.name}
                                </MenuItem>
                              ),
                            )}
                          </GKSelect>
                        </FormControl>
                      );
                    }}
                  />
                </Grid2>
                <Grid2 size={4}>
                  <Controller
                    name="benchmark"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <GKSearchSelect
                          {...field}
                          onChange={(e, val) => {
                            field.onChange(val?.value);
                          }}
                          value={
                            companyMasterData?.data?.data?.find(
                              (item: any) => item?.gridCode === field.value,
                            )?.companyName || []
                          }
                          options={companyMasterData?.data?.data?.map(
                            (item: any) => ({
                              ...item,
                              name: item?.companyName,
                              value: item?.gridCode,
                            }),
                          )}
                          getOptionLabel={(opt) => opt?.name || opt}
                          disableClearable={false}
                          inputLabel="Benchmark"
                          renderInput={(params) => {
                            return (
                              <GKTextField
                                {...params}
                                placeholder={handleReturnAutocompletePlaceholder(
                                  {
                                    placeholder: "Select Benchmark",
                                    value: field.value,
                                  },
                                )}
                              />
                            );
                          }}
                        />
                      );
                    }}
                  />
                </Grid2>
                <Grid2 size={4}>
                  <Controller
                    name="minimumInvestmentValueCheck"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <GKSearchSelect
                          {...field}
                          onChange={(e, val) => {
                            field.onChange(val?.value);
                          }}
                          value={
                            minimumInvestmentData?.find(
                              (da) => da?.value === `${field?.value}`,
                            )?.name || []
                          }
                          options={minimumInvestmentData}
                          disableClearable={false}
                          getOptionLabel={(opt) => opt?.name || opt}
                          inputLabel="Minimum Invested Amount"
                          renderInput={(params) => {
                            return (
                              <GKTextField
                                {...params}
                                placeholder={handleReturnAutocompletePlaceholder(
                                  {
                                    placeholder:
                                      "Select Minimum Invested Amount",
                                    value: field.value,
                                  },
                                )}
                              />
                            );
                          }}
                        />
                      );
                    }}
                  />
                </Grid2>
                <Grid2 size={12} textAlign={"end"}>
                  <Divider />
                </Grid2>
                <Grid2 size={12} textAlign={"end"}>
                  <Divider />
                </Grid2>
                <Grid2 size={12}>
                  <Typography color={theme.palette.primary.main}>
                    Investment Strategies
                  </Typography>
                  <Controller
                    name="investmentStyle"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <>
                          {INVESTMENT_STRATEGIES?.map((dataItem) => (
                            <Button
                              size="medium"
                              key={dataItem.value}
                              style={{ margin: 2 }}
                              variant={
                                field.value?.includes(dataItem.value)
                                  ? "contained"
                                  : "outlined"
                              }
                              color={
                                field.value?.includes(dataItem.value)
                                  ? "primary"
                                  : "dark"
                              }
                              onClick={() => {
                                const updatedOptions = form
                                  .getValues("investmentStyle")
                                  .includes(dataItem.value)
                                  ? form
                                      .getValues("investmentStyle")
                                      .filter(
                                        (item: any) => item !== dataItem.value,
                                      )
                                  : [
                                      ...form.getValues("investmentStyle"),
                                      dataItem.value,
                                    ];

                                field?.onChange(updatedOptions);
                              }}
                            >
                              {dataItem?.name}
                            </Button>
                          ))}
                        </>
                      );
                    }}
                  />
                </Grid2>
                <Grid2 size={12}>
                  <Divider />
                </Grid2>
                <Grid2 size={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography>Select Fee Plans</Typography>
                    <Button
                      variant="contained"
                      startIcon={<CgMathPlus size={16} />}
                      onClick={() => {
                        setOpenFeePlanModal(true);
                      }}
                    >
                      Add Fee Plans
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 8,
                    }}
                  >
                    {form?.getValues("feePlansList")?.map((item, index) => {
                      return (
                        <Card
                          sx={{ p: 1, minWidth: "250px", maxWidth: "250px" }}
                          key={index}
                        >
                          <Grid2
                            container
                            justifyContent={"space-between"}
                            flexDirection={"row"}
                            spacing={1}
                          >
                            <Grid2>
                              <Button
                                variant={
                                  item?.isDefault ? "contained" : "outlined"
                                }
                                color={item?.isDefault ? "primary" : "dark"}
                                size="extraSmall"
                                onClick={() => {
                                  handleSetFeeDefault(item?.id);
                                  handleUpdateEquityBasket(
                                    handleReturnFormData({
                                      obj: form.getValues(),
                                      dirtyFields: Object.keys(
                                        form.formState.dirtyFields,
                                      ),
                                      multipleFilesField: "researchFiles",
                                      isEditForm: true,
                                    }),
                                  );
                                }}
                                disabled={!id}
                              >
                                {item?.isDefault ? "Default" : "Set As Default"}
                              </Button>
                            </Grid2>
                            <Grid2>
                              {!item?.isDefault && (
                                <Controller
                                  name="feePlans"
                                  control={form.control}
                                  render={({ field }) => {
                                    return (
                                      <IconButton
                                        color="error"
                                        onClick={() => {
                                          const newFeePlans = form
                                            ?.getValues("feePlans")
                                            ?.filter(
                                              (filterData) =>
                                                +item?.id !== +filterData,
                                            );
                                          const newFeePlansList = form
                                            ?.getValues("feePlansList")
                                            ?.filter(
                                              (filterData) =>
                                                +item?.id !== +filterData?.id,
                                            );
                                          field.onChange(newFeePlans);
                                          form.setValue(
                                            "feePlansList",
                                            newFeePlansList,
                                          );
                                        }}
                                      >
                                        <AiOutlineDelete />
                                      </IconButton>
                                    );
                                  }}
                                />
                              )}
                            </Grid2>
                            <Grid2 size={12}>
                              <Typography fontWeight={600} fontSize={16}>
                                {item.name || "-"}
                              </Typography>
                            </Grid2>
                            {item.managementFee?.fee && (
                              <Grid2
                                size={5}
                                display={"flex"}
                                justifyContent={"center"}
                                flexDirection={"column"}
                              >
                                <Typography
                                  textTransform={"capitalize"}
                                  color={theme.palette.secondaryText.main}
                                  fontSize={12}
                                >
                                  {item.managementFee?.name || "-"}
                                </Typography>
                                <Typography fontWeight={600}>
                                  {item.managementFee?.fee || "-"}
                                </Typography>
                              </Grid2>
                            )}
                            {item.performanceFee?.fee &&
                              item.managementFee?.fee && (
                                <Grid2
                                  size={2}
                                  display={"flex"}
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                >
                                  <div
                                    style={{
                                      border: `1px solid ${paletteLightTheme.palette.border.main}`,
                                      borderRadius: 4,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      padding: 4,
                                    }}
                                  >
                                    <CgMathPlus
                                      color={theme.palette.secondaryText.main}
                                    />
                                  </div>
                                </Grid2>
                              )}
                            {item.performanceFee?.fee && (
                              <Grid2
                                size={5}
                                display={"flex"}
                                justifyContent={"center"}
                                flexDirection={"column"}
                              >
                                <Typography
                                  textTransform={"capitalize"}
                                  color={theme.palette.secondaryText.main}
                                  fontSize={12}
                                >
                                  {item.performanceFee?.name || "-"}
                                </Typography>
                                <Typography fontWeight={600}>
                                  {item.performanceFee?.fee || "-"}
                                </Typography>
                              </Grid2>
                            )}
                          </Grid2>
                        </Card>
                      );
                    })}
                  </div>
                </Grid2>
                <Grid2 size={12}>
                  <Divider />
                </Grid2>
                <Grid2
                  size={12}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography>Fact Sheet</Typography>
                  <Controller
                    name="factSheet"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <GKButton
                          variant="outlined"
                          component="label"
                          role={undefined}
                          tabIndex={-1}
                        >
                          Upload Factsheet
                          <VisuallyHiddenInput
                            type="file"
                            accept={".jpg, .png, .svg"}
                            onChange={(e) => {
                              field.onChange(e.target.files);
                            }}
                          />
                        </GKButton>
                      );
                    }}
                  />
                </Grid2>
                {form.getValues("factSheet")?.length === 1 && (
                  <Grid2 size={12}>
                    <Card
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        p: 1,
                      }}
                    >
                      <img
                        src={`/FileIcons/${form
                          .getValues("factSheet")[0]
                          ?.name.split(".")
                          .pop()}.svg`}
                        alt=""
                      />
                      <Typography sx={{ mr: "auto" }}>
                        {form.getValues("factSheet")?.length === 1 &&
                          form.getValues("factSheet")[0]?.name}
                      </Typography>
                      <IconButton
                        color="error"
                        onClick={() => form.setValue("factSheet", [])}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Card>
                  </Grid2>
                )}
                <Grid2 size={12}>
                  <Divider />
                </Grid2>
                <Grid2 size={12}>
                  <Typography color={theme.palette.primary.main}>
                    Research File
                  </Typography>
                  <Controller
                    name="researchFiles"
                    control={form.control}
                    render={({ field }) => {
                      return (
                        <DragDrop
                          uploadError={uploadError}
                          supportName={"CSV & PDF"}
                          setUploadError={setUploadError}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          multiple
                        />
                      );
                    }}
                  />
                </Grid2>
                <Show.When
                  isTrue={updateData?.data?.researchFiles?.length !== 0}
                >
                  <Grid2 size={12} textAlign={"end"}>
                    <GKClientDataGrid
                      name="researchFiles"
                      density="compact"
                      pagination={false}
                      showHeaderGrid={false}
                      rows={data?.data?.researchFiles || []}
                      columns={[
                        {
                          field: "name",
                          headerName: "File name",
                          flex: 1,
                          disableColumnMenu: true,
                          disableExport: true,
                        },
                        {
                          field: "uploadedBy",
                          headerName: "Uploaded By",
                          flex: 1,
                          disableColumnMenu: true,
                          disableExport: true,
                        },
                        {
                          field: "visible",
                          headerName: "Published",
                          flex: 1,
                          disableColumnMenu: true,
                          disableExport: true,
                          renderCell: (params) => {
                            return (
                              <Switch
                                onChange={() => {
                                  publishFile(params?.row?.id);
                                  toast.loading(
                                    `Please wait we are publishing ${params?.row?.name} file`,
                                  );
                                }}
                                checked={params?.row?.visible}
                              />
                            );
                          },
                        },
                        {
                          field: "actions",
                          headerName: "Action",
                          align: "right",
                          headerAlign: "right",
                          flex: 1,
                          disableColumnMenu: true,
                          disableExport: true,
                          renderCell: (params) => {
                            return (
                              <Grid2
                                container
                                justifyContent={"flex-end"}
                                alignItems={"center"}
                                gap={1}
                              >
                                <IconButton
                                  className="outline"
                                  color="error"
                                  onClick={() => {
                                    deleteFile(params?.row?.id);
                                    toast.loading(
                                      `Please wait we are deleting ${params?.row?.name} file`,
                                    );
                                  }}
                                >
                                  <FiTrash2 />
                                </IconButton>
                                <IconButton href={params.row?.file}>
                                  <IoCloudDownloadOutline />
                                </IconButton>
                              </Grid2>
                            );
                          },
                        },
                      ]}
                    />
                  </Grid2>
                </Show.When>
              </Grid2>
              {openFeePlanModal && (
                <EquityBasketFeePlanSelection
                  open={openFeePlanModal}
                  setOpen={setOpenFeePlanModal}
                  updateLoading={updateLoading}
                  handleUpdateEquityBasket={handleUpdateEquityBasket}
                />
              )}
            </form>
          </Grid2>
          <Grid2 size={12} textAlign={"end"}>
            <Divider />
          </Grid2>
          <Grid2 size={12} textAlign={"end"}>
            <LoadingButton
              type="submit"
              form="equity-basket-meta-form"
              variant="contained"
              size="medium"
              loading={createLoading || updateLoading}
            >
              {id ? "Save" : "Submit"}
            </LoadingButton>
          </Grid2>
        </Grid2>
      </FormProvider>
    </DashboardLayout>
  );
};

export default EquityBasketEdit;
