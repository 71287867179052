import { Divider, Grid2, Typography } from "components";
import { ThemeContainer } from "utils/Theme";

interface Props {
  clientName: string;
  portfolioId: string;
  portfolioName: string;
}

const MandateDetailsPageCard = ({
  clientName,
  portfolioId,
  portfolioName,
}: Props) => {
  const { theme } = ThemeContainer();

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={12}>
        <Typography fontWeight={600} fontSize={20} color={"#202939"}>
          {clientName || "-"}
        </Typography>
      </Grid2>
      <Grid2 size={12} display={"flex"} alignItems={"center"} gap={1}>
        <Typography color={"#000000"} fontWeight={600} fontSize={12}>
          <span
            style={{
              color: theme.palette.secondaryText.main,
              marginRight: 4,
              fontWeight: 400,
            }}
          >
            Portfolio Name:
          </span>
          {portfolioName || "-"}
        </Typography>
        <Typography color={"#000000"} fontWeight={600} fontSize={12}>
          <span
            style={{
              color: theme.palette.secondaryText.main,
              marginRight: 4,
              fontWeight: 400,
            }}
          >
            Portfolio Code:
          </span>
          {portfolioId || "-"}
        </Typography>
      </Grid2>
      <Grid2 size={12} py={1}>
        <Divider />
      </Grid2>
    </Grid2>
  );
};

export default MandateDetailsPageCard;
