/* eslint-disable import/no-named-as-default-member */
import { GKButton, GKClientDataGrid, Grid2, Show } from "components";
import { useFetchQuery } from "hooks/useQueries";
import ClientCustomAgreement from "Pages/Compliance/ClientCustomAgreement";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { GET_AGREEMENT_BY_ID } from "utils/apiRoutes";
import { ClientAgreementColumns } from "utils/columns";
import { QueryKeys } from "utils/queryKey";
import ComplianceAgreementModal from "./ComplianceAgreementModal";

const ComplianceAgreement = () => {
  const { id } = useParams();

  const [customAgreement, setCustomAgreement] = useState(false);

  const [addClientAgreement, setAddClientAgreement] = useState(false);

  const { data: getAgreement, isLoading: isAgreementLoading } = useFetchQuery({
    key: [QueryKeys.AGREEMENT_LIST],
    route: GET_AGREEMENT_BY_ID(id),
    enabled: Boolean(id),
  });

  return (
    <>
      <GKClientDataGrid
        name="clientAgreement"
        headerGridStyle={{
          justifyContent: "flex-end",
        }}
        loading={isAgreementLoading}
        columns={ClientAgreementColumns()}
        rows={getAgreement?.data?.data?.response || []}
        headerComponent={
          <Grid2
            container
            display={
              getAgreement?.data?.data?.response?.length === 0 ? "none" : "flex"
            }
            justifyContent={"end"}
            alignItems={"center"}
            gap={1}
          >
            <GKButton
              onClick={() => setCustomAgreement(true)}
              variant="outlined"
              color="dark"
            >
              Generate Agreement
            </GKButton>
            <GKButton
              onClick={() => setAddClientAgreement(true)}
              variant="outlined"
              color="dark"
            >
              Upload Agreement
            </GKButton>
          </Grid2>
        }
        emptyButtons={
          <Grid2
            container
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={1}
          >
            <GKButton
              onClick={() => setCustomAgreement(true)}
              variant="outlined"
              size="small"
              color="dark"
            >
              Generate Agreement
            </GKButton>
            <GKButton
              onClick={() => setAddClientAgreement(true)}
              variant="outlined"
              size="small"
              color="dark"
            >
              Upload Agreement
            </GKButton>
          </Grid2>
        }
      />

      <Show.When isTrue={addClientAgreement}>
        <ComplianceAgreementModal
          setOpen={setAddClientAgreement}
          open={addClientAgreement}
        />
      </Show.When>
      <Show.When isTrue={customAgreement}>
        <ClientCustomAgreement
          setOpen={setCustomAgreement}
          open={customAgreement}
        />
      </Show.When>
    </>
  );
};

export default ComplianceAgreement;
