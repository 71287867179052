import { useQueries } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import { GKSearchSelect, Grid2, TextField, Typography } from "components";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";
import { PortfolioDropdownInterface } from "utils/types";
import useDocumentTitle from "utils/useDocumentTitle";
import InsigntsPageContent from "./InsigntsPageContent";

const HoldingsInsights = () => {
  useDocumentTitle("Holdings Insights");

  const { theme } = ThemeContainer();

  const [selectedItems, setSelectedItems] = useState<
    PortfolioDropdownInterface[]
  >([]);

  const navigate = useNavigate();

  const {
    state: { commonFilter },
    dispatch,
  } = useAppContext();

  const [
    { data: portfolioList, isFetching: isPortfolioLoading },
    { data: pieChartData },
  ] = useQueries({
    queries: [
      {
        queryKey: [QueryKeys.PORTFOLIO_OVERVIEW],
        queryFn: () => axiosInstance.get(`/portfolios/dropdown/`),
        onError: (error: any) => {
          toast.error(
            error?.response?.data?.message ||
              "Something went wrong, please try again.",
          );
        },
      },
      {
        queryKey: [selectedItems, QueryKeys.INSIGHTS_HOLDINGS],
        queryFn: () =>
          axiosInstance.post(`/holdings/selected/`, {
            portfolios: selectedItems
              ?.map((data: any) => data?.portfolios)
              ?.flat()
              ?.map((dataItem) => dataItem?.value),
          }),
        keepPreviousData: true,
        onError: (error: any) => {
          toast.error(
            error?.response?.data?.message ||
              "Something went wrong, please try again.",
          );
        },
      },
    ],
  });

  return (
    <DashboardLayout title="Holdings Insights" loading={isPortfolioLoading}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 10,
          marginBottom: 10,
          background: theme.palette.light?.main,
          borderRadius: 10,
          border: "solid 1px #25778544",
          padding: "10px",
          position: "sticky",
          top: -8,
          boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)",
          zIndex: 2,
        }}
      >
        <GKSearchSelect
          multiple
          disableClearable
          style={{
            minWidth: "400px",
            maxWidth: "500px",
          }}
          loading={isPortfolioLoading}
          onChange={(event, val) => {
            setSelectedItems(val);
            dispatch({
              type: "FILTER",
              data: {
                ...commonFilter,
                insights: {
                  skip: 0,
                  page: 0,
                  take: 25,
                  sortModel: [],
                  filterColumn: [],
                  search: "",
                  showNonZero: commonFilter.insights.showNonZero,
                  customFilter: {},
                },
              },
            });
          }}
          value={selectedItems}
          groupBy={(option: any) => option.type?.toUpperCase()}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography fontWeight={600}>{option.clientName}</Typography>
                <Typography>{option.name}</Typography>
              </li>
            );
          }}
          options={
            portfolioList?.data?.map((item: any) => ({
              name: `${item?.name} - ${item?.code}`,
              value: item?.id,
              type: item?.type,
              clientName: item?.clientName,
              portfolios: item?.portfolios?.map((portfolio: any) => ({
                label: portfolio?.name,
                value: portfolio?.id,
                portfolioId: portfolio?.portfolioId,
              })),
            })) || []
          }
          getOptionLabel={(option) => option.name || option}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={handleReturnAutocompletePlaceholder({
                placeholder: "Select..",
                value: selectedItems,
              })}
            />
          )}
        />
      </div>
      <Grid2 container spacing={1} maxHeight={`calc(100vh - 154px)`}>
        <Grid2 size={12}>
          <InsigntsPageContent
            allHoldingsInsightsData={pieChartData?.data || []}
            isLoading={isPortfolioLoading}
            onRowClick={(row) => {
              navigate(`/app/insights-holdings/${row?.row?.gcode}/details`, {
                state: {
                  portfoliosIds: selectedItems
                    ?.map((data: any) => data?.portfolios)
                    ?.flat()
                    ?.map((dataItem) => dataItem?.value),
                },
              });
            }}
            selectedItems={selectedItems}
          />
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default HoldingsInsights;
