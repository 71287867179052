/* eslint-disable react/no-danger */
import { GKModal } from "components";
import { GKModalProps } from "utils/commonInterface";
import { convertQuillToEditorJS } from "utils/helpers";

interface Props {
  content: string;
}

const CustomAgreementPreviewFile = ({
  open,
  setOpen,
  content,
}: Props & GKModalProps) => {
  // const { theme } = ThemeContainer();

  // const editorRef = useRef<any>(null);

  return (
    <>
      <GKModal open={open} setOpen={setOpen} modalTitle="Preview Content">
        <div
          style={{ maxHeight: "400px", overflowY: "auto" }}
          dangerouslySetInnerHTML={{ __html: convertQuillToEditorJS(content) }}
        />
      </GKModal>
    </>
  );
};

export default CustomAgreementPreviewFile;
