import { useParams } from "react-router-dom";

import { GKClientDataGrid, GKModal } from "components";
import { useFetchQuery } from "hooks/useQueries";
import { GET_LEAD_STATUS_SOURCE_LOGS } from "utils/apiRoutes";
import { leadStatusColumns } from "utils/columns";
import { GKModalProps } from "utils/commonInterface";
import { QueryKeys } from "utils/queryKey";

const LogsModal = (props: GKModalProps) => {
  const { id } = useParams();

  const { open, setOpen } = props;

  const { data, isFetching } = useFetchQuery({
    key: [QueryKeys.STATUS_LOG],
    route: GET_LEAD_STATUS_SOURCE_LOGS,
    requestBody: {
      lead_id: id,
      type: "Status",
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={"Status History"}
      width={"60%"}
    >
      <GKClientDataGrid
        name="logsModal"
        showSearch={false}
        showHeaderGrid={false}
        loading={isFetching}
        columns={leadStatusColumns}
        rows={data?.data?.data || []}
      />
    </GKModal>
  );
};

export default LogsModal;
