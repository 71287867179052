import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { GKModal, GKOtpInput, Grid2 } from "components";
import { PaymentGatewayFormValues } from "interfaces/PaymentGateway";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";

const VerifyAccountModal = (props: GKModalProps) => {
  const { open, setOpen } = props;
  const navigate = useNavigate();

  const form = useFormContext();

  const [otpCode, setOtpCode] = useState("");
  const [isDoneTyping, setIsDoneTyping] = useState(false);

  const { mutate: razorPayAccountLink } = useMutation({
    mutationKey: ["RAZOR_PAY_ACCOUNT_LINK"],
    mutationFn: (data: PaymentGatewayFormValues) =>
      axiosInstance.post("/razorpay-linked-account/", data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      navigate("/app/user-profile/settings");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again.",
      );
      navigate("/app/user-profile/settings");
    },
  });

  const { mutate: handleVerifyOPT } = useMutation({
    mutationFn: (otpCode: any) =>
      axiosInstance.post(`/razorpay-otp/verify/`, {
        otp: otpCode,
      }),
    onSuccess: (response) => {
      form.setValue("token", response?.data?.data?.token);
      razorPayAccountLink(form.getValues() as PaymentGatewayFormValues);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again.",
      );
    },
  });

  const { mutate: handleGetOPT, isLoading } = useMutation({
    mutationFn: () => axiosInstance.get(`/razorpay-otp/resend/`),
  });

  useEffect(() => {
    if (isDoneTyping) handleVerifyOPT(otpCode);
  }, [isDoneTyping]);

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Verify OTP"
      titleComponent={
        <LoadingButton
          variant="outlined"
          size="medium"
          loading={isLoading}
          onClick={() => {
            handleGetOPT();
          }}
        >
          Resend OTP
        </LoadingButton>
      }
    >
      <Grid2 container spacing={1}>
        <Grid2 size={12}>
          <GKOtpInput
            value={otpCode}
            onChange={setOtpCode}
            setIsTyping={setIsDoneTyping}
          />
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default VerifyAccountModal;
