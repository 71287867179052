/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  Card,
  CgMathPlus,
  ConfirmDeleteModal,
  GKButton,
  GKClientDataGrid,
  Grid2,
  IconButton,
  LuRefreshCw,
  Show,
  StatusField,
  Switch,
  Tooltip,
  Typography,
} from "components";
import SimpleLoader from "components/SimpleLoader";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, matchDate } from "utils/helpers";
import AddBrokerModal from "./AddBrokerModal";
import DirectTradeModal from "./DirectTradeModal";
import OrderTradeModal from "./OrderTradeModal";
import ResendOrderModal from "./ResendOrderModal";

const BrokerIntegration = () => {
  const { theme } = ThemeContainer();
  const { id } = useParams();

  const [openBrokerModal, setOpenBrokerModal] = useState(false);
  const [brokerData, setBrokerData] = useState<any>({});
  const [tradeModal, setTradeModal] = useState(false);
  const [orderId, setOrderId] = useState(0);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [createTradeModal, setCreateTradeModal] = useState(false);
  const [resendConfirmModal, setResendConfirmModal] = useState(false);
  const [tradeData, setTradeData] = useState({});

  const {
    data,
    isFetching,
    refetch,
    isLoading: brokerListLoading,
  } = useFetchQuery({
    key: ["GET_BROKER_DETAILS"],
    route: `/smallcase-gateway/portfolio-broker-connect/?portfolio_id=${id}`,
    success: (response: any) => {
      setBrokerData(response.data.data[0]);
    },
  });

  const { data: getOrderList, isFetching: orderFetching } = useFetchQuery({
    key: ["GET_ORDER_LIST"],
    route: `/smallcase-order/${id}/list/`,
  });

  const { mutate: handleSendEmail, isLoading } = useMutation({
    mutationKey: ["SEND_BROKER-ACTIVATE-EMAIL"],
    mutationFn: (brokerId: number | string) =>
      axiosInstance.post(
        `/smallcase-gateway/${brokerId}/send-broker-activate-email/`,
      ),
    onSuccess: (response) => {
      refetch();
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: handleBrokerDelete, isLoading: brokerDeleteLoading } =
    useMutation({
      mutationKey: ["BROKER_DELETE"],
      mutationFn: (brokerId: number | string) =>
        axiosInstance.delete(`/smallcase-gateway/${brokerId}/`),
      onSuccess: (response) => {
        refetch();
        toast.success(
          response.data?.message || "Action completed successfully.",
        );
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  const { mutate: handleAutoApprove } = useMutation({
    mutationKey: ["UPDATE_AUTO_APPROVE"],
    mutationFn: (data) =>
      axiosInstance.patch(`/smallcase-gateway/${brokerData?.id}/`, {
        isAutoApprove: data,
      }),
    onSuccess: (response) => {
      refetch();
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  if (brokerListLoading) return <SimpleLoader />;

  return (
    <Grid2 container spacing={1} alignItems={"center"}>
      <Show.When isTrue={data?.data?.data.length}>
        <Grid2 size={12} my={1}>
          <Card
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "16px",
            }}
          >
            <Grid2 container size={12} justifyContent={"space-between"}>
              <Grid2
                size={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      padding: "0 16px",
                    }}
                  >
                    <Typography
                      fontWeight={400}
                      fontSize={12}
                      color={theme.palette.grey[500]}
                      display={"flex"}
                      alignItems={"center"}
                      // justifyContent={"space-between"}
                    >
                      Client Email
                      {/* <IconButton
                        onClick={() => {
                          setBrokerData(data?.data?.data[0]);
                          setOpenBrokerModal(true);
                        }}
                        className="outline"
                      >
                        <Edit2 size={14} />
                      </IconButton> */}
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      {data?.data?.data[0]?.email || "-"}
                    </Typography>
                  </div>
                  <div
                    style={{
                      padding: "0 16px",
                      borderLeft: `1px solid ${theme.palette.grey[400]}`,
                    }}
                  >
                    <Typography
                      fontWeight={400}
                      fontSize={12}
                      color={theme.palette.grey[500]}
                    >
                      Broker{" "}
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      {data?.data?.data[0]?.broker || "-"}
                    </Typography>
                  </div>
                  <div
                    style={{
                      padding: "0 16px",
                      borderLeft: `1px solid ${theme.palette.grey[400]}`,
                    }}
                  >
                    <Typography
                      fontWeight={400}
                      fontSize={12}
                      color={theme.palette.grey[500]}
                    >
                      UCC{" "}
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      {data?.data?.data[0]?.ucc || "-"}
                    </Typography>
                  </div>
                  <div
                    style={{
                      padding: "0 16px",
                      borderLeft: `1px solid ${theme.palette.grey[400]}`,
                    }}
                  >
                    <Typography
                      fontWeight={400}
                      fontSize={12}
                      color={theme.palette.grey[500]}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      Status
                      <Tooltip title="Resend Email">
                        <IconButton
                          disabled={data?.data?.data[0]?.status || isLoading}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSendEmail(data?.data?.data[0]?.id);
                          }}
                          className="outline"
                        >
                          <LuRefreshCw size={14} />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      <StatusField
                        statusString={data?.data?.data[0]?.status.toString()}
                      />
                    </Typography>
                  </div>
                  <div
                    style={{
                      padding: "0 16px",
                      borderLeft: `1px solid ${theme.palette.grey[400]}`,
                    }}
                  >
                    <Typography
                      fontWeight={400}
                      fontSize={12}
                      color={theme.palette.grey[500]}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      Auto Approve Trades
                    </Typography>
                    <Typography fontWeight={600} fontSize={14}>
                      <Switch
                        checked={data?.data?.data[0]?.isAutoApprove}
                        onChange={(e: any) => {
                          handleAutoApprove(e.target.checked);
                        }}
                      />
                    </Typography>
                  </div>
                </div>
                <div>
                  <LoadingButton
                    loading={brokerDeleteLoading}
                    onClick={() => setConfirmationModal(true)}
                    variant="lightBg"
                    color="error"
                  >
                    Delete Broker
                  </LoadingButton>
                </div>
              </Grid2>
            </Grid2>
          </Card>
        </Grid2>
      </Show.When>
      <Grid2 size={12}>
        <GKClientDataGrid
          name="brokerIntegration"
          loading={isFetching || orderFetching}
          rows={getOrderList?.data?.data || []}
          columns={[
            {
              ...extraColumnsField("Order", "orderDate", 0, 1),
              valueFormatter: (value) => matchDate(value.value),
              renderCell: (item) =>
                `Trades - ${matchDate(item?.row?.orderDate)} - ${
                  item.row.id
                } ` || "-",
            },
            {
              ...extraColumnsField("Basket", "modelPortfolio", 0, 1),
            },
            {
              ...extraColumnsField("Model Portfolio", "version", 0, 1),
            },
            {
              ...extraColumnsField("Status", "smallcaseStatus", 0, 1),
              renderCell: (item) => item?.row?.smallcaseStatus || "-",
            },
            {
              ...extraColumnsField("Actions", "action", 50, 1),
              align: "right",
              headerAlign: "right",
              renderCell: (params) => {
                return (
                  <Tooltip title="Resend order">
                    <IconButton
                      disabled={orderId === params.row.id}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOrderId(params.row.id);
                        setResendConfirmModal(true);
                      }}
                    >
                      <LuRefreshCw size={20} />
                    </IconButton>
                  </Tooltip>
                );
              },
            },
          ]}
          onRowClick={(params) => {
            setOrderId(params.row.id);
            setTradeData(params?.row);
            setTradeModal(true);
          }}
          headerComponent={
            <Grid2 container justifyContent={"flex-end"}>
              <Show.When isTrue={data?.data?.data.length !== 1}>
                <GKButton
                  onClick={() => {
                    setBrokerData({});
                    setOpenBrokerModal(true);
                  }}
                  variant="contained"
                >
                  Add Broker Integration
                </GKButton>{" "}
                &nbsp;
              </Show.When>
              <GKButton
                variant="contained"
                onClick={() => setCreateTradeModal(true)}
                startIcon={<CgMathPlus size={14} />}
              >
                Add Trade
              </GKButton>
            </Grid2>
          }
        />
      </Grid2>

      <Show.When isTrue={openBrokerModal}>
        <AddBrokerModal
          open={openBrokerModal}
          setOpen={setOpenBrokerModal}
          brokerData={brokerData}
        />
      </Show.When>

      <Show.When isTrue={tradeModal}>
        <OrderTradeModal
          open={tradeModal}
          setOpen={setTradeModal}
          orderId={orderId}
          setOrderId={setOrderId}
          tradeData={tradeData}
        />
      </Show.When>

      <Show.When isTrue={confirmationModal}>
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => handleBrokerDelete(data?.data?.data[0]?.id)}
          title={"Broker Integration"}
          isDelete
        />
      </Show.When>

      <Show.When isTrue={createTradeModal}>
        <DirectTradeModal
          open={createTradeModal}
          setOpen={setCreateTradeModal}
        />
      </Show.When>

      <Show.When isTrue={resendConfirmModal}>
        <ResendOrderModal
          open={resendConfirmModal}
          setOpen={setResendConfirmModal}
          orderId={orderId}
          setOrderId={setOrderId}
        />
      </Show.When>
    </Grid2>
  );
};

export default BrokerIntegration;
