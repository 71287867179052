import { useAppContext } from "AppContext";
import { Grid2, Show, Tab, Tabs } from "components";
import { UserRoleTypes } from "utils/common";
import useDocumentTitle from "utils/useDocumentTitle";
import BrokerIntegration from "../ReportsPages/BrokerIntegration";
import AutoImportTab from "./AutoImportTab";
import ContractNotes from "./ContractNotes";

const PortfolioUtilityEdit = () => {
  useDocumentTitle("Portfolio Import");

  const {
    state: { tab, userData },
    dispatch,
  } = useAppContext();

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={12} display={"flex"}>
        <Tabs
          className="light"
          variant="standard"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
          value={tab?.portfolioUtilityTabs}
          onChange={(e, val) => {
            dispatch({
              type: "TAB",
              data: { ...tab, portfolioUtilityTabs: val },
            });
          }}
        >
          <Tab label="Manual Import" disableRipple />
          <Tab label="Auto Import" disableRipple />
          {userData?.userRole !== UserRoleTypes.client && (
            <Tab label="Broker Integration" disableRipple />
          )}
        </Tabs>
      </Grid2>
      <Grid2 size={12}>
        <Show.When isTrue={tab?.portfolioUtilityTabs === 0}>
          <ContractNotes />
        </Show.When>
        <Show.When isTrue={tab?.portfolioUtilityTabs === 1}>
          <AutoImportTab />
        </Show.When>
        <Show.When isTrue={tab?.portfolioUtilityTabs === 2}>
          <BrokerIntegration />
        </Show.When>
      </Grid2>
    </Grid2>
  );
};

export default PortfolioUtilityEdit;
