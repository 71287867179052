import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { FetchDataURL } from "utils/common";
import { QueryKeys } from "utils/queryKey";
import useGenerateQueryParams from "./useGenerateQueryParams";

interface Props {
  path?: string;
}

export const useClient = ({ path }: Props) => {
  const [isClientCreateModalOpen, setIsClientCreateModalOpen] = useState(false);

  const requestBody = useGenerateQueryParams("client");

  const { data: getClientList, isLoading: isClientLoading } = useFetchQuery({
    key: [QueryKeys.CLIENTS_LIST, requestBody],
    retry: 0,
    route: path || FetchDataURL.CLIENTS_LIST,
    requestBody,
  });

  return {
    getClientList: getClientList?.data,
    isClientLoading,
    isClientCreateModalOpen,
    setIsClientCreateModalOpen,
  };
};
