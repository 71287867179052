import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  BiCopy,
  Box,
  Divider,
  EditIcon,
  FormControl,
  GKTextField,
  Grid2,
  IconButton,
  LuCheck,
  MdOutlineClose,
  Show,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import ViewStepsModal from "./ViewStepsModal";

const MutualFundAutoImportSettings = () => {
  const [passwordField, setPasswordField] = useState("");

  const [isEditing, setIsEdting] = useState(false);

  const [openStepsModal, setOpenStepsModal] = useState(false);

  const { data } = useFetchQuery({
    key: ["GET_MUTUAL_FUND_EMAIL"],
    route: `/advisor-auto-forward/`,
    success: (res: any) => {
      setPasswordField(res?.data?.data?.password);
      setIsEdting(!res?.data?.data?.password);
    },
  });

  const { mutate: updatePassword } = useMutation({
    mutationFn: (requestData: any) =>
      axiosInstance.post(`/advisor-auto-forward/`, requestData),
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
      refetchQuery("GET_MUTUAL_FUND_EMAIL");
    },
    onSuccess: (response: any) => {
      toast.success(
        response?.data?.message || "Action Performed Successfully.",
      );
      refetchQuery("GET_MUTUAL_FUND_EMAIL");
    },
  });

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={5}>
        <FormControl variant="standard" fullWidth>
          <GKTextField
            inputLabel={"Email"}
            value={data?.data?.data?.email}
            slotProps={{
              input: {
                readOnly: true,
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${data?.data?.data?.email}`,
                      );
                    }}
                  >
                    <BiCopy />
                  </IconButton>
                ),
              },
            }}
          />
        </FormControl>
      </Grid2>
      <Grid2 size={5}>
        <FormControl variant="standard" fullWidth>
          <GKTextField
            disabled={!isEditing}
            inputLabel={"Password"}
            placeholder="Enter Password"
            onChange={({ target }) => {
              setPasswordField(target.value);
            }}
            value={passwordField}
            slotProps={{
              input: {
                endAdornment: isEditing ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      color="success"
                      onClick={() => {
                        updatePassword({
                          password: passwordField,
                        });
                      }}
                    >
                      <LuCheck />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => {
                        setIsEdting(false);
                      }}
                    >
                      <MdOutlineClose />
                    </IconButton>
                  </Box>
                ) : (
                  <IconButton
                    onClick={() => {
                      setIsEdting(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                ),
              },
            }}
          />
        </FormControl>
      </Grid2>
      <Grid2 size={12}>
        <LoadingButton
          variant="contained"
          onClick={() => {
            setOpenStepsModal(true);
          }}
        >
          Configure Steps
        </LoadingButton>
      </Grid2>
      <Grid2 size={12}>
        <Divider />
      </Grid2>
      <Show.When isTrue={openStepsModal}>
        <ViewStepsModal open={openStepsModal} setOpen={setOpenStepsModal} />
      </Show.When>
    </Grid2>
  );
};

export default MutualFundAutoImportSettings;
