import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { GKButton, GKModal, Grid2, Show } from "components";
import SimpleLoader from "components/SimpleLoader";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import ImportStep1 from "./ImportStep1";
import ReviewDataTable from "./ReviewDataTable";

const handleModalTitle = (step: number) => {
  switch (step) {
    case 0:
      return { form: "", title: "Import Fees" };
    case 1:
      return { form: "", title: "Review imported data" };

    default:
      return { form: "", title: "Import Fees" };
  }
};

type FormValues = {
  activeStep: number;
  fileObject: any;
  step1?: {
    file: any[];
  };
  step2: {
    invoiceList: any[];
  };
};

const ImportModal = (props: GKModalProps) => {
  const { open, setOpen } = props;

  const form = useForm<FormValues>({
    defaultValues: {
      activeStep: 0,
      fileObject: {},
      step1: {
        file: [],
      },
      step2: {
        invoiceList: [],
      },
    },
  });

  form.watch();

  const getActiveStep = form.getValues("activeStep");

  const handleNextStep = () => {
    form.setValue("activeStep", getActiveStep + 1);
  };

  // DELETE FILE
  const { mutate: deleteSheet } = useMutation({
    mutationKey: ["DELETE_SHEET"],
    mutationFn: (id: string) =>
      axiosInstance.delete(`/historical-invoice-migrate/${id}/`),
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: handleReviewSubmit, isLoading: handleReviewSubmitLoading } =
    useMutation({
      mutationKey: ["HANDLE_REVIEW_SUBMIT"],
      mutationFn: (data: any) =>
        axiosInstance.post(`/historical-invoice-migrate/review/`, data),
      onSuccess: (response) => {
        toast.success(
          response.data?.message || "Action completed successfully.",
        );
        deleteSheet(form.getValues("fileObject")?.fileKey);
        refetchQuery("GET_LIST_OF_FEE_COLLECTION");
        setOpen(false);
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  const { mutate: reviewMapping, isLoading: reviewMappingLoading } =
    useMutation({
      mutationKey: ["GET_REVIEW_SHEET"],
      mutationFn: (data: any) =>
        axiosInstance.post(`/historical-invoice-migrate/review/`, data),
      onSuccess: (response) => {
        form.setValue("step2.invoiceList", response.data?.data);
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  const { mutate: uploadFile, isLoading: uploadFileLoading } = useMutation({
    mutationKey: ["HISTORICAL_INVOICE_MIGRATE"],
    mutationFn: (data) =>
      axiosInstance.post("/historical-invoice-migrate/", data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      form.setValue("fileObject", response.data?.data);
      reviewMapping({ file_key: response.data?.data?.fileKey, commit: false });
      handleNextStep();
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const loading =
    uploadFileLoading || reviewMappingLoading || handleReviewSubmitLoading;

  return (
    <GKModal
      modalTitle={handleModalTitle(getActiveStep)?.title}
      open={open}
      setOpen={setOpen}
      width={"70%"}
      escClose={false}
      onCloseModal={() => {
        if (form.getValues("fileObject")) {
          deleteSheet(form.getValues("fileObject")?.fileKey);
        }
        setOpen(false);
      }}
      footer={
        <Grid2
          container
          justifyContent={getActiveStep !== 0 ? "space-between" : "flex-end"}
          gap={1}
        >
          {getActiveStep !== 0 && (
            <GKButton
              variant="outlined"
              color="dark"
              size={"medium"}
              onClick={() => {
                if (form.getValues("fileObject")) {
                  deleteSheet(form.getValues("fileObject")?.fileKey);
                }
                setOpen(false);
              }}
            >
              Cancel
            </GKButton>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 8,
            }}
          >
            {[0].includes(getActiveStep) && (
              <LoadingButton
                variant="contained"
                size={"medium"}
                type="submit"
                form="import-collection-form"
                disabled={form.getValues("step1.file")?.length === 0}
                loading={loading}
              >
                Next
              </LoadingButton>
            )}

            {[1].includes(getActiveStep) && (
              <LoadingButton
                variant="contained"
                size={"medium"}
                type="submit"
                form="import-collection-form"
                loading={loading}
              >
                Finish
              </LoadingButton>
            )}
          </div>
        </Grid2>
      }
    >
      <FormProvider {...form}>
        <form
          id="import-collection-form"
          onSubmit={form.handleSubmit((values: any) => {
            if (getActiveStep === 0) {
              const fileArray: any = new FormData();
              fileArray.append("file", values?.step1?.file[0]);
              uploadFile(fileArray);
            } else {
              handleReviewSubmit({
                file_key: values?.fileObject?.fileKey,
                commit: true,
              });
            }
          })}
        >
          <Grid2 container spacing={1}>
            <Grid2 size={12}>
              <Show.When isTrue={loading}>
                <SimpleLoader />
              </Show.When>
            </Grid2>
            <Grid2 size={12}>
              <Show.When isTrue={!loading && getActiveStep === 0}>
                <ImportStep1 />
              </Show.When>
            </Grid2>
            <Grid2 size={12}>
              <Show.When isTrue={!loading && getActiveStep === 1}>
                <ReviewDataTable />
              </Show.When>
            </Grid2>
          </Grid2>
        </form>
      </FormProvider>
    </GKModal>
  );
};

export default ImportModal;
