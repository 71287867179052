import { Card, FiArrowRight, GKButton, Grid2, Typography } from "components";
import { useNavigate } from "react-router-dom";
import { ThemeContainer } from "utils/Theme";

const OnboardingCards = () => {
  const navigate = useNavigate();
  const { theme } = ThemeContainer();

  return (
    <Grid2 container spacing={3} size={12}>
      <Grid2 size={6}>
        <Card sx={{ p: 2, borderRadius: "14px" }}>
          <Grid2 container spacing={2}>
            <Grid2 size={3.5}>
              <GKButton sx={{ p: 0 }}>
                <img
                  height={"auto"}
                  width={"145px"}
                  style={{
                    borderRadius: 16,
                  }}
                  alt=""
                  src="/Logos/mailCard.svg"
                  draggable="false"
                />
              </GKButton>
            </Grid2>
            <Grid2
              size={8.5}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"flex-start"}
              flexDirection={"column"}
              gap={1}
            >
              <Typography fontWeight={600} fontSize={18}>
                Need assistance?
              </Typography>
              <Typography
                fontWeight={400}
                fontSize={14}
                color={theme.palette.secondaryText.main}
              >
                {`Write to us at info@gridkey.in and we'll answer you.`}
              </Typography>
            </Grid2>
          </Grid2>
        </Card>
      </Grid2>
      <Grid2 size={6}>
        <Card sx={{ p: 2, borderRadius: "14px" }}>
          <Grid2 container spacing={2}>
            <Grid2 size={3.5}>
              <GKButton sx={{ p: 0 }}>
                <img
                  height={"auto"}
                  width={"145px"}
                  style={{
                    borderRadius: 16,
                  }}
                  alt=""
                  src="/Logos/faqCard.svg"
                  draggable="false"
                />
              </GKButton>
            </Grid2>
            <Grid2
              size={8.5}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"flex-start"}
              gap={1}
              flexDirection={"column"}
            >
              <Typography fontWeight={600} fontSize={18}>
                Get answers to your common questions.{" "}
              </Typography>

              <GKButton
                variant="link"
                sx={{
                  textDecoration: "none",
                }}
                onClick={() => {
                  navigate("/faq");
                }}
                endIcon={<FiArrowRight />}
              >
                FAQ
              </GKButton>
            </Grid2>
          </Grid2>
        </Card>
      </Grid2>
    </Grid2>
  );
};

export default OnboardingCards;
