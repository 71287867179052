import { CommonPagination, GKDataGrid } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useLocation } from "react-router-dom";
import { portfolioHoldingTransactionsColumns } from "utils/columns";
import useDocumentTitle from "utils/useDocumentTitle";

const PortfolioHoldingTradeBook = () => {
  useDocumentTitle("Holding Trade Book");

  const { state } = useLocation();

  const requestBody = useGenerateQueryParams("portfolioHoldingTransaction", {
    folioNo: state?.folioNo,
  });

  const { data, isFetching, isLoading } = useFetchQuery({
    key: ["HOLDINGS_TRANSACTIONS", requestBody],
    route: `/${state?.type}/${state?.holdingId}/company_transactions/`,
    requestBody: {
      gcode: state?.gcode,
      ...requestBody,
    },
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={data?.data?.totalCount || 0}
        name="portfolioHoldingTransaction"
      />
    );
  };

  return (
    <GKDataGrid
      loading={isFetching}
      maxHeight={"calc(100vh - 312px)"}
      columns={portfolioHoldingTransactionsColumns}
      getRowClassName={(params) => {
        return params?.row?.valid ? "" : "back-red";
      }}
      rows={
        data?.data.data?.map((data: any, index: number) => {
          return {
            ...data,
            id: index,
          };
        }) || []
      }
      density="compact"
      CustomPagination={!isLoading && cusPagination}
      name="portfolioHoldingTransaction"
      exportFile={{
        path: `/${state?.type}/${state?.holdingId}/company_transactions/`,
        exportName: "PortfoioHolingsList",
        paramsValue: { key: "gcode", value: state?.gcode },
      }}
    />
  );
};

export default PortfolioHoldingTradeBook;
