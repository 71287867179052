import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { GKModal, GKSearchSelect, Grid2, TextField } from "components";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { GET_ALL_MARKETING_MATERIAL_LIST } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

interface Props {
  id: string | number;
}

const SendMarketingMaterial = ({ open, setOpen, id }: Props & GKModalProps) => {
  const [selectedMaterial, setSelectedMaterial] = useState(null);

  const { data } = useFetchQuery({
    key: ["GET_ALL_MARKETING_MATERIAL"],
    route: GET_ALL_MARKETING_MATERIAL_LIST,
  });

  const { mutate: handleSend } = useMutation({
    mutationFn: () =>
      axiosInstance?.post(`/send_marketing_material/`, {
        lead_id: [+id],
        marketingMaterialId: selectedMaterial,
      }),
    onSuccess: (response) => {
      refetchQuery(QueryKeys.GET_LEAD);
      setOpen(false);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again.",
      );
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Send Marketing Material"
      footer={
        <LoadingButton
          disabled={selectedMaterial === null}
          variant="contained"
          onClick={() => {
            handleSend();
          }}
        >
          Send
        </LoadingButton>
      }
    >
      <Grid2 container>
        <Grid2 size={12}>
          <GKSearchSelect
            inputLabel="Select Material"
            getOptionLabel={(opt) => opt?.name || opt}
            renderInput={(er) => <TextField {...er} />}
            onChange={(e, val) => {
              setSelectedMaterial(val?.value);
            }}
            options={
              data?.data?.data?.map((da: any) => {
                return {
                  ...da,
                  name: da?.marketingText,
                  value: da?.id,
                };
              }) || []
            }
          />
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default SendMarketingMaterial;
