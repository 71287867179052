import { FormControl, Grid2, Typography } from "@mui/material";
import GKTextField from "components/GKTextField";
import { Controller, useFormContext } from "react-hook-form";

const AdvanceDeposit = () => {
  const form = useFormContext();

  return (
    <Grid2 container rowSpacing={2} spacing={1}>
      <Grid2 size={12}>
        <Typography fontSize={18} fontWeight={500}>
          Advance Deposit
        </Typography>
      </Grid2>
      <Grid2 size={4}>
        <Controller
          name="advanceDeposit.productName"
          control={form.control}
          render={({ field }) => (
            <FormControl variant="standard" fullWidth>
              <GKTextField {...field} inputLabel={"productName"} />
            </FormControl>
          )}
        />
      </Grid2>
      <Grid2 size={4}>
        <Controller
          name="advanceDeposit.hsnCode"
          control={form.control}
          render={({ field }) => (
            <FormControl variant="standard" fullWidth>
              <GKTextField {...field} inputLabel={"HSN Code"} />
            </FormControl>
          )}
        />
      </Grid2>
      <Grid2 size={4}>
        <FormControl variant="standard" fullWidth>
          <Controller
            name="advanceDeposit.fee"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Please enter fee",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <GKTextField
                {...field}
                inputLabel="fee"
                type="number"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FormControl>
      </Grid2>
    </Grid2>
  );
};

export default AdvanceDeposit;
