import {
  Card,
  FcApproval,
  GKButton,
  Grid2,
  Tooltip,
  Typography,
} from "components";
import dayjs from "dayjs";
import { ThemeContainer } from "utils/Theme";
import { kycStatus } from "utils/constants";

interface Props {
  setAddClientKyc: any;
  handleSubmit: any;
  allKYCdata: any;
}

const KYCCard = (props: Props) => {
  const { setAddClientKyc, allKYCdata, handleSubmit } = props;
  const { theme } = ThemeContainer();
  const KYCData = [
    {
      name: "Identification Type",
      value: kycStatus(allKYCdata?.identificationType) || "-",
      width: 3,
    },
    {
      name: " KYC Id",
      value: allKYCdata?.kycId || "-",
      width: 6,
    },
    {
      name: "Date",
      value: dayjs(allKYCdata?.createdAt).format("DD MMM YYYY") || "-",
      width: 3,
    },
  ];

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={12}>
        <Card
          style={{
            display: "flex",
          }}
        >
          <Grid2 size={6}>
            <Typography fontSize={14} fontWeight={500}>
              Client Name
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={700}
              color={theme.palette.primary.main}
              gap={0.5}
              style={{ display: "flex", alignItems: "center" }}
            >
              {allKYCdata?.name || "-"}
              {allKYCdata?.kycStatus === "approved" && (
                <Tooltip title="Approved" placement="right">
                  <GKButton style={{ padding: 0, minWidth: 0 }}>
                    <FcApproval fontSize={22} />
                  </GKButton>
                </Tooltip>
              )}
            </Typography>
          </Grid2>
          <Grid2
            size={6}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={1}
          >
            <GKButton
              onClick={() => setAddClientKyc(true)}
              variant="contained"
              size="medium"
            >
              Request KYC
            </GKButton>
            {allKYCdata?.documents?.length > 0 && (
              <GKButton
                variant="outlined"
                color="success"
                size="medium"
                disabled={
                  allKYCdata?.kycStatus !== "requested" &&
                  allKYCdata?.kycStatus !== "approval_pending"
                }
                onClick={() => handleSubmit("APPROVED")}
              >
                Approve All
              </GKButton>
            )}
            {allKYCdata?.documents?.length > 0 && (
              <GKButton
                variant="outlined"
                color="error"
                size="medium"
                disabled={
                  allKYCdata?.kycStatus !== "requested" &&
                  allKYCdata?.kycStatus !== "approval_pending"
                }
                onClick={() => handleSubmit("REJECTED")}
              >
                Reject All
              </GKButton>
            )}
          </Grid2>
        </Card>
      </Grid2>
      {KYCData.map((dataItem, index) => {
        return (
          <Grid2 size={dataItem.width} key={index}>
            <Card>
              <Typography fontSize={14} fontWeight={500}>
                {dataItem.name}
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={700}
                color={theme.palette.primary.main}
              >
                {dataItem.value}
              </Typography>
            </Card>
          </Grid2>
        );
      })}
    </Grid2>
  );
};

export default KYCCard;
