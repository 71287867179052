import {
  BsDot,
  Grid2,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from "components";

const Section1 = () => {
  return (
    <Grid2
      container
      py={10}
      alignItems={"center"}
      spacing={3}
      pt={0}
      sx={{
        flexDirection: {
          xs: "column-reverse",
          lg: "row",
        },
        px: {
          xs: 3,
          lg: 10,
        },
        background: "#020707",
      }}
    >
      <Grid2 size={{ xs: 12, lg: 6 }}>
        <img
          width={"100%"}
          src="/LandingPage/wps1.svg"
          alt=""
          style={{
            padding: 30,
            borderRadius: 20,
            border: "1px solid #323232",
          }}
        />
      </Grid2>
      <Grid2
        size={{ xs: 12, lg: 6 }}
        container
        display={"flex"}
        justifyContent={"center"}
      >
        <Grid2
          size={12}
          display={"flex"}
          gap={2}
          sx={{
            flexDirection: {
              xs: "column",
              lg: "row",
            },
            justifyContent: {
              xs: "center",
              lg: "flex-start",
            },
            alignItems: {
              xs: "center",
              lg: "flex-start",
            },
          }}
        >
          <img
            loading="lazy"
            width={"74px"}
            src="/LandingPage/stayAhead.svg"
            alt=""
          />
          <Typography
            fontSize={32}
            fontWeight={700}
            sx={{
              textAlign: {
                xs: "center",
                lg: "left",
              },
            }}
          >
            Stay ahead of competition with technology exclusive to you
          </Typography>
        </Grid2>
        <Grid2
          size={12}
          sx={{
            justifyContent: {
              xs: "center",
              lg: "flex-start",
            },
            alignItems: {
              xs: "center",
              lg: "flex-start",
            },
            display: {
              xs: "flex",
              lg: "unset",
            },
          }}
        >
          <List>
            <ListItem>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                }}
              >
                <BsDot size={30} color="#999999" />
              </ListItemIcon>
              <Typography fontWeight={400} fontSize={16} color={"#999999"}>
                Integrate API based white-label solutions to your business.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                }}
              >
                <BsDot size={30} color="#999999" />
              </ListItemIcon>
              <Typography fontWeight={400} fontSize={16} color={"#999999"}>
                Offer your own Mobile & Web App to clients customized according
                to your business needs.
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                }}
              >
                <BsDot size={30} color="#999999" />
              </ListItemIcon>
              <Typography fontWeight={400} fontSize={16} color={"#999999"}>
                Integrate API based white-label solutions to your business.
              </Typography>
            </ListItem>
          </List>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default Section1;
