import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { handleRemoveUnderscore } from "utils";

import QuoteModal from "Pages/ReportManager/QuoteModal";
import {
  AiFillStar,
  AiOutlineStar,
  ConfirmDeleteModal,
  Divider,
  GKButton,
  Grid2,
  IconButton,
  LuEye,
  LuTrash2,
  NoData,
  Show,
  Tab,
  Tabs,
  Typography,
  ViewPdf,
} from "components";
import dayjs from "dayjs";
import { useFetchQuery } from "hooks/useQueries";
import useReport from "hooks/useReport";
import DashboardLayout from "layouts/DashboardLayout";
import { groupBy, orderBy } from "lodash";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { paletteLightTheme } from "utils/ColorTheme";
import { ThemeContainer } from "utils/Theme";
import { GET_REPORTS } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import useDocumentTitle from "utils/useDocumentTitle";
import { reportValidationSchema } from "utils/validationSchema";
import GenerateReportForm from "./GenerateReportForm";

type FormValues = {
  selectedReported?: any;
  portfolio?: any[];
  from_date?: string;
  to_date?: string;
  currentOption?: string;
  optionsToInclude?: any[];
  disclaimer?: string;
  quote?: string;
  holdingsStatement?: any[];
  bankStatement?: any[];
  availableTimePeriods?: any[];
  benchmark?: string;
  end_date?: string;
  custom_date?: { start_date?: string; end_date?: string };
  show_zero_holding?: boolean;
  optionalField?: any[];
  tradesPlusCash?: boolean;
  gcode?: string;
  instType?: string[];
  returnMetrics?: string;
  benchmarkComparisionTimePeriods?: string[];
  advisorCategory?: string;
};

const GenerateReport = () => {
  useDocumentTitle("Generate New Report");

  const { theme } = ThemeContainer();

  const { quoteList, benchmarkList, benchmarkLoading } = useReport();

  const [tabs, setTabs] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState({
    index: 0,
    loading: false,
  });
  const [allReportsTabs, setAllReportsTabs] = useState(0);
  const [currentTab, setCurrentTab] = useState("performance");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [deleteReportId, setDeleteReportId] = useState(null);
  const [openQuoteModal, setOpenQuoteModal] = useState(false);
  const [editQuoteValue, setEditQuoteValue] = useState({});
  const [showReportPreview, setShowReportPreview] = useState("");
  const [openPdfView, setOpenPdfView] = useState(false);

  const {
    data: getAllReportsData,
    isFetching,
    refetch,
  } = useFetchQuery({
    key: ["GET_ALL_GENERATE_REPORT_LIST"],
    route: GET_REPORTS,
  });

  const loadingValue = () => {
    setSelectedIndex((prev: any) => {
      return {
        ...prev,
        loading: false,
      };
    });
  };

  const form = useForm<FormValues>({
    defaultValues: {
      selectedReported: {},
      portfolio: [],
      from_date: "",
      to_date: "",
      currentOption: "",
      optionsToInclude: [],
      disclaimer: "",
      quote: "",
      holdingsStatement: [],
      bankStatement: [],
      availableTimePeriods: [],
      benchmark: "",
      end_date: "",
      custom_date: { start_date: "", end_date: "" },
      show_zero_holding: false,
      optionalField: [],
      tradesPlusCash: false,
      gcode: "",
      instType: [],
      returnMetrics: "xirr",
      benchmarkComparisionTimePeriods: [],
      advisorCategory: "",
    },
    resolver: yupResolver(reportValidationSchema),
  });

  form.watch();

  const { mutate: handleFavorite } = useMutation({
    mutationKey: ["FAVORITE_REPORT"],
    mutationFn: (data: any) =>
      axiosInstance.patch(`/report/${data.id}/`, { isFavorite: data.favorite }),
    onSuccess: (response) => {
      refetch();
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
      loadingValue();
    },
  });

  const { mutate: handleReportDelete } = useMutation({
    mutationKey: ["REPORT_DELETE"],
    mutationFn: () => axiosInstance.delete(`/report/${deleteReportId}/`),
    onSuccess: (response) => {
      refetch();
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
      loadingValue();
    },
  });

  const { mutate } = useMutation({
    mutationKey: ["GENERATE_REPORT"],
    mutationFn: (data: any) =>
      form.getValues("portfolio")?.length === 1
        ? axiosInstance.post(data.route, data.data)
        : axiosInstance.post(data.route, data.data, {
            responseType: "arraybuffer",
          }),
    onSuccess: async (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      if (form.getValues("portfolio")?.length === 1) {
        await fetch(response?.data?.url)
          .then(async (response) => {
            return response.blob();
          })
          .then((blob: any) => {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `${response?.data?.name}`;
            link.click();
          });
      } else {
        const url = window.URL.createObjectURL(
          new Blob([response?.data], { type: "application/zip" }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.download = `${form.getValues("selectedReported")?.name}.zip`;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
      loadingValue();
    },
    onError: (error: any) => {
      // const arrayBuffer = error?.response?.data;
      // const textDecoder = new TextDecoder();
      // const errorMessage = JSON.parse(textDecoder.decode(arrayBuffer))?.message;
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
      loadingValue();
    },
  });

  const handleFormSubmit = async (values: any) => {
    delete values.name;

    if (
      values.to_date &&
      values.from_date &&
      dayjs(values.to_date).isBefore(values.from_date)
    ) {
      toast.error("End date cannot be greater than start date");
      return false;
    }
    setSelectedIndex((prev: any) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const { selectedReported } = values;

    switch (selectedReported.reportName) {
      case "custom_report":
        return mutate({
          data: {
            portfolio: values?.portfolio
              ?.map((da: any) => da?.portfolios)
              ?.flat()
              ?.map((d: any) => d?.id),
            end_date: values?.end_date,
            custom_date: {
              start_date: values.custom_date.start_date,
              end_date: values.custom_date.end_date,
            },
          },
          route: `/report/${values?.selectedReported?.id}/generate/`,
        });
      case "stock_wise_holdings_report":
        return mutate({
          data: {
            gcode: values?.gcode,
            to_date: values?.to_date,
            format: selectedIndex.index === 0 ? "pdf" : "excel",
          },
          route: `/report/${values?.selectedReported?.id}/generate/`,
        });
      default:
        return mutate({
          data: {
            portfolio: values?.portfolio
              ?.map((da: any) => da?.portfolios)
              ?.flat()
              ?.map((d: any) => d?.id),
            from_date: values?.from_date,
            instType: values?.instType,
            to_date: values?.to_date,
            format: selectedIndex.index === 0 ? "pdf" : "excel",
            end_date: values?.end_date,
          },
          route: `/report/${values?.selectedReported?.id}/generate/`,
        });
    }
  };

  const handleReturnSortKey = (val: string) => {
    switch (val) {
      case "performance":
        return "a";
      case "transactions":
        return "b";
      case "capital_gain":
        return "c";
      default:
        return "d";
    }
  };

  return (
    <DashboardLayout title="Generate New Report" loading={isFetching}>
      <FormProvider {...form}>
        <form
          id="report-form"
          onSubmit={form.handleSubmit((values) => handleFormSubmit(values))}
        >
          <Grid2 container spacing={2}>
            <Grid2 size={12} container>
              <Grid2 size={6}>
                <Tabs onChange={(e, val) => setTabs(val)} value={tabs}>
                  <Tab label="Favorites" />
                  <Tab label="All Reports" />
                </Tabs>
                <Divider />
              </Grid2>
            </Grid2>
            <Show.When isTrue={tabs === 1}>
              <Grid2 size={12} display={"flex"}>
                <Tabs
                  variant="standard"
                  className="light"
                  TabIndicatorProps={{
                    style: {
                      display: "none",
                    },
                  }}
                  onChange={(e, val) => {
                    setAllReportsTabs(val);
                  }}
                  value={allReportsTabs}
                >
                  {orderBy(
                    Object.entries(
                      groupBy(
                        getAllReportsData?.data?.data?.filter(
                          (dataItem: any) => dataItem.reportType !== "template",
                        ),
                        "reportType",
                      ),
                    ).map(([key]) => ({
                      name: handleRemoveUnderscore(key),
                      value: key,
                      order: handleReturnSortKey(key),
                    })),
                    ["order"],
                    ["asc"],
                  )?.map((dataItem: any, index: number) => {
                    return (
                      <Tab
                        key={dataItem?.name}
                        label={dataItem?.name}
                        value={index}
                        onClick={() => {
                          setCurrentTab(dataItem?.value);
                        }}
                      />
                    );
                  })}
                </Tabs>
              </Grid2>
            </Show.When>
            <Grid2 size={5.5}>
              <Show.When isTrue={tabs === 1}>
                <Typography fontWeight={600} fontSize={18} mb={2}>
                  Select Report Type{" "}
                </Typography>
              </Show.When>
              {(tabs === 0
                ? getAllReportsData?.data?.data?.filter((data: any) =>
                    tabs === 0 ? data?.isFavorite : !data?.isFavorite,
                  )
                : getAllReportsData?.data?.data?.filter(
                    (data: any) => data?.reportType === currentTab,
                  )
              )?.length === 0 ? (
                <NoData
                  customImg={
                    <img loading="lazy" alt="" src="/LandingPage/noFav.svg" />
                  }
                  text="No Favourites Added"
                  subText="Select the star icon in the reports to add them to your favorite reports."
                />
              ) : (
                (tabs === 0
                  ? getAllReportsData?.data?.data?.filter((data: any) =>
                      tabs === 0 ? data?.isFavorite : !data?.isFavorite,
                    )
                  : getAllReportsData?.data?.data?.filter(
                      (data: any) => data?.reportType === currentTab,
                    )
                )?.map((item: any) => {
                  return (
                    <GKButton
                      onClick={() => {
                        form.reset();
                        form.setValue("selectedReported", item);
                      }}
                      key={item?.id}
                      fullWidth
                      variant={"outlined"}
                      color="dark"
                      style={{
                        padding: 8,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 8,
                        background:
                          form.getValues("selectedReported")?.id === item?.id
                            ? `${paletteLightTheme.palette.primary.main}1A`
                            : `#fff`,
                      }}
                      endIcon={
                        <div>
                          <IconButton>
                            <LuEye
                              color={theme.palette.primary.main}
                              size={16}
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowReportPreview(item?.sampleFile);
                                setOpenPdfView(true);
                              }}
                            />
                          </IconButton>
                          <Show.When isTrue={item.reportType !== "template"}>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFavorite({
                                  id: item.id,
                                  favorite: !item.isFavorite,
                                });
                              }}
                            >
                              {!item.isFavorite ? (
                                <AiOutlineStar
                                  size={16}
                                  color={theme.palette.border.main}
                                />
                              ) : (
                                <AiFillStar
                                  color={theme.palette.primary.main}
                                  size={16}
                                />
                              )}
                            </IconButton>
                          </Show.When>
                          <Show.When isTrue={item.reportType === "template"}>
                            <IconButton
                              color="error"
                              onClick={(e) => {
                                e.stopPropagation();
                                setDeleteReportId(item.id);
                                setConfirmationModal(true);
                              }}
                            >
                              <LuTrash2 size={16} />
                            </IconButton>
                          </Show.When>
                        </div>
                      }
                    >
                      <Typography
                        whiteSpace={"nowrap"}
                        fontWeight={400}
                        fontSize={14}
                        textTransform={"capitalize"}
                      >
                        {item?.name}
                      </Typography>
                    </GKButton>
                  );
                })
              )}
            </Grid2>
            <Grid2 size={0.5} display={"flex"} justifyContent={"center"}>
              <Divider orientation="vertical" />
            </Grid2>
            <Show.When isTrue={form.getValues("selectedReported")}>
              <Grid2 size={6}>
                <GenerateReportForm
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  quoteList={quoteList}
                  benchmarkList={benchmarkList}
                  benchmarkLoading={benchmarkLoading}
                  setOpenQuoteModal={setOpenQuoteModal}
                  setEditQuoteValue={setEditQuoteValue}
                />
              </Grid2>
            </Show.When>

            <Show.When isTrue={confirmationModal}>
              <ConfirmDeleteModal
                open={confirmationModal}
                setOpen={setConfirmationModal}
                onDelete={() => handleReportDelete()}
                title={"Template"}
                isDelete
              />
            </Show.When>
          </Grid2>
        </form>
      </FormProvider>

      <Show.When isTrue={openQuoteModal}>
        <QuoteModal
          open={openQuoteModal}
          setOpen={setOpenQuoteModal}
          editQuoteValue={editQuoteValue}
        />
      </Show.When>

      <Show.When isTrue={openPdfView}>
        <ViewPdf
          open={openPdfView}
          setOpen={setOpenPdfView}
          file={showReportPreview}
        />
      </Show.When>
    </DashboardLayout>
  );
};

export default GenerateReport;
