import { Grid2, Typography } from "components";
import { motion } from "framer-motion";
import { useEffect, useRef } from "react";

const Disclaimer = () => {
  const myRef = useRef(null);

  useEffect(() => {
    myRef.current.scrollIntoView();
  });
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div ref={myRef} />
      <Grid2
        container
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        sx={{
          background: `linear-gradient(180deg,#102E2A 12%, rgba(0,0,0) 100%)`,
          px: {
            xs: 3,
            lg: 10,
          },
        }}
        py={"140px"}
        pt={"223px"}
      >
        <Grid2 size={12}>
          <Typography
            textAlign={"center"}
            fontSize={44}
            fontWeight={700}
            style={{
              background:
                "linear-gradient(90deg, rgba(0,101,128,1) 0%, rgba(37,131,130,1) 50%, rgba(72,159,132,1) 100%)",
              WebkitBackgroundClip: " text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Disclaimer{" "}
          </Typography>
        </Grid2>
        <Grid2 size={12}>
          <Typography textAlign={"center"} fontSize={16}>
            We are not SEBI-registered Research Analysts (RA) or Investment
            Advisers (RIA). Our role is solely as a technology and software
            solutions provider. We do not solicit investments, provide financial
            advice, or recommend any investment products or strategies in any
            manner. Users are advised to consult with their financial advisors
            or other professionals before making any investment decisions.
          </Typography>
        </Grid2>
      </Grid2>{" "}
    </motion.div>
  );
};

export default Disclaimer;
