import {
  Avatar,
  Card,
  Chip,
  Divider,
  Grid2,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiPhone } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import { HiOutlineMail } from "react-icons/hi";
import { LuCalendarClock, LuStickyNote } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { paletteLightTheme } from "utils/ColorTheme";
import { getHexaColor } from "utils/constants";
import { ThemeContainer } from "utils/Theme";
import GKButton from "./GKButton";

interface BoardType {
  columns: {
    id: number;
    title: string;
    cards: any[];
  }[];
}

interface TagLists {
  branch: number;
  createdAt: string;
  id: number;
  tag: string;
  updatedAt: string;
}

interface Props {
  columns: BoardType;
  setColumns: any;
  updateLead: any;
  setCardActionAnchor: any;
  setLeadId: any;
}

const GKKanban = (props: Props) => {
  const { columns, setColumns, updateLead, setCardActionAnchor, setLeadId } =
    props;

  const navigate = useNavigate();

  const { theme } = ThemeContainer();

  const [draggedCard, setDraggedCard] = useState(null);

  const handleDragStart = (card: any, columnId: any) => {
    setDraggedCard({ card, columnId });
    setLeadId(card.id);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleDrop = (targetColumnData: any) => {
    updateLead({
      leadStatus: targetColumnData?.id,
      id: draggedCard?.card?.id,
    });
    if (draggedCard) {
      setColumns((prevColumns: any) => {
        const updatedColumns = [...prevColumns.columns];

        const sourceColumn = updatedColumns.find(
          (col) => col.id === draggedCard.columnId,
        );
        const targetColumn = updatedColumns.find(
          (col) => col.id === targetColumnData?.id,
        );

        if (!sourceColumn || !targetColumn) return prevColumns;

        sourceColumn.cards = sourceColumn.cards.filter(
          (card: any) => card.id !== draggedCard.card.id,
        );

        targetColumn.cards = [...targetColumn.cards, draggedCard.card];

        return { ...prevColumns, columns: updatedColumns };
      });

      setDraggedCard(null);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: "10px",
        width: "100%",
        overflowX: "auto",
      }}
    >
      {columns.columns.map((column: any) => (
        <div
          key={column.id}
          style={{
            minWidth: "300px",
            padding: "10px",
            borderRadius: "5px",
          }}
          onDragOver={handleDragOver}
          onDrop={() => handleDrop(column)}
        >
          <div
            style={{
              background: `${getHexaColor(column.title)}1`,
              border: `solid 1px ${getHexaColor(column.title)}`,
              borderRadius: 10,
              padding: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            <GoDotFill color={getHexaColor(column.title)} />
            <Typography
              fontWeight={600}
              fontSize={14}
              style={{
                color: getHexaColor(column.title),
              }}
            >
              {column.title}
              <span
                style={{
                  color: theme.palette.secondaryText.main,
                  fontWeight: 500,
                }}
              >
                {` (${column?.cards?.length})`}
              </span>
            </Typography>
          </div>
          <div>
            {column.cards.map((card: any) => (
              <div
                draggable
                onDragStart={() => handleDragStart(card, column.id)}
              >
                <Grid2
                  container
                  minWidth={150}
                  style={{
                    background: theme.palette.light.main,
                  }}
                  borderRadius={2}
                  border={`solid 1px #E3E8EF`}
                  width={300}
                  mt={2}
                  spacing={1}
                  p={2}
                  onClick={() => {
                    navigate(`/app/lead/detail/${card?.id}/summary`);
                  }}
                >
                  <Grid2
                    container
                    size={12}
                    pl={"0 !important"}
                    pt={"0 !important"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Card
                      sx={{
                        p: "2px",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: 100,
                      }}
                    >
                      {card?.leadAssignedToStr && (
                        <Avatar
                          sx={{
                            width: 20,
                            height: 20,
                            color: paletteLightTheme.palette.primary.main,
                            background: `${paletteLightTheme.palette.primary.main}11`,
                          }}
                        >
                          <Typography fontSize={10}>
                            {card?.leadAssignedToStr?.charAt(0)}
                            {card?.leadAssignedToStr?.charAt(1)}
                          </Typography>
                        </Avatar>
                      )}
                      <Typography
                        fontWeight={400}
                        fontSize={10}
                        color={theme.palette.secondaryText.main}
                      >
                        {card?.leadAssignedToStr || "-"}
                      </Typography>
                    </Card>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <LuCalendarClock
                        size={14}
                        color={
                          card?.hasMeeting
                            ? paletteLightTheme.palette.success.main
                            : paletteLightTheme.palette.grey[400]
                        }
                      />
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          setCardActionAnchor(event.currentTarget);
                        }}
                      >
                        <BsThreeDotsVertical size={16} />
                      </IconButton>
                    </div>
                  </Grid2>
                  <Grid2 size={12} pl={"0 !important"}>
                    <Typography fontWeight={600} fontSize={16}>
                      {card?.name}
                    </Typography>
                    <Typography
                      fontWeight={400}
                      fontSize={12}
                      color={theme.palette.secondaryText.main}
                    >
                      {card?.companyName}
                    </Typography>
                  </Grid2>
                  <Grid2 size={12} pl={"0 !important"}>
                    <Typography
                      fontSize={12}
                      fontWeight={400}
                      color={theme.palette.secondaryText.main}
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      gap={1}
                    >
                      <HiOutlineMail size={16} />
                      {card?.email || "-"}
                    </Typography>{" "}
                  </Grid2>
                  <Grid2 size={12} pl={"0 !important"}>
                    <Typography
                      fontSize={12}
                      fontWeight={400}
                      color={theme.palette.secondaryText.main}
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      gap={1}
                    >
                      <FiPhone size={16} />
                      {card?.phoneNumber || "-"}
                    </Typography>{" "}
                  </Grid2>
                  <Grid2 size={12} mt={1} mb={1} pl={"0 !important"}>
                    <Divider />
                  </Grid2>
                  <Grid2
                    pl={"0 !important"}
                    container
                    size={12}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Grid2>
                      {card?.tagsList
                        ?.slice(0, 2)
                        ?.map((dataItem: TagLists) => {
                          return (
                            <Chip
                              sx={{
                                padding: 0,
                                height: 26,
                                background: `${getHexaColor(dataItem?.tag)}22`,
                                color: `${getHexaColor(dataItem?.tag)}`,
                                borderRadius: 2,
                                fontWeight: 600,
                                "& .MuiChip-deleteIcon": {
                                  color: `${getHexaColor(dataItem?.tag)}`,
                                },
                              }}
                              label={dataItem?.tag}
                            />
                          );
                        })}
                      {card?.tagsList?.length > 2 && (
                        <GKButton
                          sx={{
                            padding: "12px 6px",
                            lineHeight: 0,
                            minWidth: "1px !important",
                          }}
                          variant="outlined"
                          color="dark"
                        >
                          +
                          {card?.tagsList?.length > 2 &&
                            card?.tagsList?.length - 2}
                        </GKButton>
                      )}
                    </Grid2>
                    <Grid2
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      gap={0.2}
                    >
                      <Typography color={theme.palette.secondaryText.main}>
                        {card?.notesCount}
                      </Typography>
                      <LuStickyNote color={theme.palette.secondaryText.main} />
                    </Grid2>
                  </Grid2>
                </Grid2>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default GKKanban;
