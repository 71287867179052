import { CommonPagination, GKDataGrid } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { PORTFOLIO_ACCESS_BY_ID } from "utils/apiRoutes";
import { PortfolioAccessClientListColumn } from "utils/columns";
import { QueryKeys } from "utils/queryKey";

const PortfolioAccess = () => {
  const { id } = useParams();

  const [employeeList, setEmployeeList]: any = useState([]);

  // const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);

  // ===#===#===#===#===#===#===#===#===#===#===#===#===#===#===#===#===#===#
  const requestBody = useGenerateQueryParams("portfolioAccess");

  const {
    isLoading,
    // refetch
  } = useFetchQuery({
    key: [QueryKeys.PORTFOLIO_ACCESS, requestBody],
    route: PORTFOLIO_ACCESS_BY_ID(id),
    requestBody,
    success: (response: any) => {
      setEmployeeList(response?.data);
      // setSelectedEmployeeList(response?.data?.employees);
    },
  });

  // ===#===#===#===#===#===#===#===#===#===#===#===#===#===#===#===#===#===#
  // const { mutate: portfolioDisable, isLoading: portfolioDisableLoading } =
  //   useMutation({
  //     mutationKey: ["PORTFOLIO_DISABLE"],
  //     mutationFn: () =>
  //       axiosInstance.patch(`/portfolios/access/${id}/all_employees/`),
  //     onSuccess: () => refetch(),
  //     onError: (error: any) => {
  //       toast.error(
  //         error?.response?.data?.message ||
  //           "Something went wrong, please try again."
  //       );
  //     },
  //   });

  // ===#===#===#===#===#===#===#===#===#===#===#===#===#===#===#===#===#===#
  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={employeeList.totalCount || 0}
        name="portfolioAccess"
      />
    );
  };

  return (
    <GKDataGrid
      columns={
        PortfolioAccessClientListColumn()
        // selectedEmployeeList,
        // id,
        // refetch
      }
      rows={employeeList?.data || []}
      loading={isLoading}
      // headerComponent={
      //   <Grid2>
      //     <FormControlLabel
      //       control={
      //         <Switch
      //           checked={employeeList?.enableAll}
      //           disabled={portfolioDisableLoading}
      //           onChange={() => portfolioDisable()}
      //         />
      //       }
      //       label={"Enable All"}
      //       labelPlacement="end"
      //     />
      //   </Grid2>
      // }
      CustomPagination={!isLoading && cusPagination}
      name="portfolioAccess"
    />
  );
};

export default PortfolioAccess;
