import { useAppContext } from "AppContext";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  GKDatePicker,
  GKSearchSelect,
  Grid2,
  Radio,
  RadioGroup,
  TextField,
} from "components";
import dayjs from "dayjs";
import { Controller, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";

interface FormErrors {
  step1: {
    fromDate?: {
      value: boolean;
      message: string;
    };
    toDate?: {
      value: boolean;
      message: string;
    };
  };
}

const ImportBrokerStepOne = ({ type }: { type?: string }) => {
  const { id } = useParams();
  const form = useFormContext();

  const { theme } = ThemeContainer();

  const {
    state: {
      userData: { broker },
    },
  } = useAppContext();

  const {
    formState: { errors },
  } = useFormContext<FormErrors>();

  const BROKER_TRADE_OPTION = [
    { name: "Aditya Birla Money", value: "ADITYA_BIRLA_MONEY" },
    { name: "Latin Manharlal", value: "LATIN_MANHARLAL" },
  ];

  const BROKER_LEDGER_OPTION = [
    { name: "Aditya Birla Money", value: "ADITYA_BIRLA_MONEY_LEDGER" },
    { name: "Latin Manharlal", value: "LATIN_MANHARLAL_LEDGER" },
  ];

  const handleDateChange = (field: any, value: any) => {
    const formattedDate = dayjs(value).format("YYYY-MM-DD");
    field.onChange(formattedDate);
    if (!id) {
      form.setValue("step1.toDate", formattedDate);
    }
  };

  return (
    <Grid2 container spacing={1}>
      {!id && (
        <Grid2 size={12}>
          <Controller
            name="step1.type"
            control={form.control}
            render={({ field }) => (
              <FormControl>
                <FormLabel
                  style={{
                    color: theme.palette.dark.main,
                    fontSize: "14px",
                  }}
                >
                  Type
                </FormLabel>
                <RadioGroup
                  {...field}
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="ADITYA_BIRLA_MONEY"
                    control={<Radio />}
                    label="Trades"
                  />
                  <FormControlLabel
                    value="ADITYA_BIRLA_MONEY_LEDGER"
                    control={<Radio />}
                    label="Ledger Entries"
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Grid2>
      )}

      {id && broker?.adityaBirlaSupport && broker?.lmSupport && (
        <Grid2 size={12}>
          <Controller
            name="step1.type"
            control={form.control}
            render={({ field }) => (
              <GKSearchSelect
                {...field}
                disableClearable
                inputLabel="Broker"
                options={
                  type === "TRADE"
                    ? BROKER_TRADE_OPTION
                    : BROKER_LEDGER_OPTION || []
                }
                onChange={(event, val) => field.onChange(val?.value)}
                value={
                  type === "TRADE"
                    ? BROKER_TRADE_OPTION?.find(
                        (option) => option.value === field.value,
                      )?.name
                    : BROKER_LEDGER_OPTION?.find(
                        (option) => option.value === field.value,
                      )?.name || ""
                }
                getOptionLabel={(option) => option.name || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select Broker",
                      value: field.value,
                    })}
                  />
                )}
              />
            )}
          />
        </Grid2>
      )}

      <Grid2 size={!id ? 12 : 6}>
        <FormControl variant="standard" fullWidth>
          <Controller
            name="step1.fromDate"
            control={form.control}
            rules={{
              required: { value: true, message: "Please select start date" },
            }}
            render={({ field }) => (
              <GKDatePicker
                {...field}
                requiredField
                inputLabel={!id ? "Date" : "Start Date"}
                value={dayjs(field.value)}
                onChange={(value) => handleDateChange(field, value)}
                disableFuture
                slotProps={{
                  textField: {
                    error: !!errors.step1?.fromDate,
                    helperText: errors.step1?.fromDate?.message as string,
                  },
                }}
              />
            )}
          />
        </FormControl>
      </Grid2>
      {id && (
        <Grid2 size={6}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="step1.toDate"
              control={form.control}
              rules={{
                required: { value: !!id, message: "Please select end date" },
              }}
              render={({ field }) => (
                <GKDatePicker
                  {...field}
                  requiredField
                  inputLabel="End Date"
                  value={dayjs(field.value)}
                  minDate={
                    dayjs(form.getValues("step1.fromDate")).isValid()
                      ? dayjs(form.getValues("step1.fromDate"))
                      : null
                  }
                  onChange={(value) =>
                    field.onChange(dayjs(value).format("YYYY-MM-DD"))
                  }
                  disableFuture
                  slotProps={{
                    textField: {
                      error: !!errors.step1?.toDate,
                      helperText: errors.step1?.toDate?.message as string,
                    },
                  }}
                />
              )}
            />
          </FormControl>
        </Grid2>
      )}
    </Grid2>
  );
};

export default ImportBrokerStepOne;
