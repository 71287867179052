import {
  Button,
  GKClientDataGrid,
  GKModal,
  Grid2,
  Typography,
} from "components";
import { useState } from "react";
import { GKModalProps } from "utils/commonInterface";
import { moneyCommaSeparatorPoint, numberToCurrency } from "utils/MathFunction";

interface Props {
  setResponseData: any;
  setCompanyWiseRebalanceData: any;
  form: any;
  data: {
    availableCash: any;
    availableCashEquivalent: any;
    availableCashEquivalentPercentage: any;
    availableCashPercentage: any;
    currentAmount: any;
    currentValueNoEtfCe: any;
    investedAmount: any;
    portfolioCode: any;
    portfolioId: any;
    portfolioName: any;
    portfolioValue: any;
    totalCash: any;
    totalCashPercentageTotal: any;
  }[];
  replace: any;
}

const SelectPortfolioModal = (props: Props & GKModalProps) => {
  const {
    open,
    setOpen,
    data,
    replace,
    form,
    setResponseData,
    setCompanyWiseRebalanceData,
  } = props;

  // const [value, setValue] = useState<number[]>([0, 100]);

  // const handleChange = (event: Event, newValue: number | number[]) => {
  //   setValue(newValue as number[]);
  // };

  const [selectedIds, setSelectedIds] = useState(
    form?.getValues("portfolios")?.map((data: any) => data?.portfolioId),
  );

  form.watch();

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Select Portfolio"
      width={"90%"}
      maxHeight="450px"
      // titleComponent={
      //   <div
      //     style={{
      //       display: "flex",
      //       alignItems: "center",
      //       gap: 8,
      //     }}
      //   >
      //     <Typography>Percentage Of Ledger Balance:</Typography>
      //     <div
      //       style={{
      //         display: "flex",
      //         alignItems: "center",
      //         justifyContent: "flex-end",
      //         borderRadius: 16,
      //         gap: 8,
      //         width: "220px",
      //         marginLeft: "auto",
      //       }}
      //     >
      //       <Typography
      //         sx={{
      //           borderRadius: 2,
      //           border: `1px solid ${paletteLightTheme.palette.border.main}`,
      //           width: "30px",
      //           height: "30px",
      //           display: "flex",
      //           justifyContent: "center",
      //           alignItems: "center",
      //         }}
      //       >
      //         {value[0]}
      //       </Typography>
      //       <Slider
      //         value={value}
      //         size="small"
      //         onChange={handleChange}
      //         getAriaLabel={() => "Temperature range"}
      //         sx={{
      //           width: "70%",
      //         }}
      //       />
      //       <Typography
      //         sx={{
      //           borderRadius: 2,
      //           border: `1px solid ${paletteLightTheme.palette.border.main}`,
      //           width: "30px",
      //           height: "30px",
      //           display: "flex",
      //           justifyContent: "center",
      //           alignItems: "center",
      //         }}
      //       >
      //         {value[1]}
      //       </Typography>
      //     </div>
      //   </div>
      // }
      footer={
        <Button
          onClick={() => {
            const filteredData = data?.filter(
              (row: any) =>
                !form
                  ?.getValues("portfolios")
                  ?.map((data: any) => data?.portfolioId)
                  ?.includes(row.portfolioId),
            );

            const selectedRowData = [
              ...filteredData,
              ...form.getValues("portfolios"),
            ]
              ?.map((selecedtData) => {
                return {
                  ...selecedtData,
                  cash:
                    form.getValues("allocationType") === "rebalance" &&
                    selecedtData?.availableCash > 0
                      ? selecedtData?.availableCash
                      : selecedtData?.cash || 0,
                };
              })
              ?.filter((row: any) => selectedIds.includes(row.portfolioId));

            replace(selectedRowData);
            form?.setValue("portfolios", selectedRowData);
            setOpen(false);
            setResponseData([]);
            setCompanyWiseRebalanceData([]);
          }}
          variant="contained"
        >
          Finish
        </Button>
      }
    >
      <Grid2 container width={"99%"} spacing={2}>
        <Grid2 size={12}>
          <GKClientDataGrid
            name="selectPortfolioModal"
            density="compact"
            rows={data || []}
            getRowId={(op) => op?.portfolioId}
            maxHeight={`calc(100vh - 390px)`}
            checkboxSelection
            keepNonExistentRowsSelected
            rowSelectionModel={selectedIds}
            onRowSelectionModelChange={(params) => {
              setSelectedIds(params);
            }}
            columns={[
              {
                field: "clientName",
                headerName: "Portfolio",
                sortable: false,
                disableColumnMenu: true,
                minWidth: 300,
                renderCell: (params) => {
                  return (
                    <Grid2
                      container
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"flex-start"}
                    >
                      <Typography
                        fontWeight={700}
                        maxWidth={"100%"}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                      >
                        {params?.row?.clientName}
                      </Typography>
                      <Typography fontSize={12}>
                        {params?.row?.portfolioName} -{" "}
                        {params?.row?.portfolioCode}
                      </Typography>
                    </Grid2>
                  );
                },
              },
              {
                field: "portfolioValue",
                headerName: "Portfolio Value",
                sortable: false,
                disableColumnMenu: true,
                flex: 1,
                minWidth: 190,
                valueFormatter: (params) => numberToCurrency(params.value),
              },
              {
                field: "currentAmount",
                headerName: "Current Value",
                sortable: false,
                disableColumnMenu: true,
                flex: 1,
                minWidth: 190,
                valueFormatter: (params) => numberToCurrency(params.value),
              },
              {
                field: "availableCash",
                headerName: "Available Cash",
                sortable: false,
                disableColumnMenu: true,
                flex: 1,
                minWidth: 190,
                valueFormatter: (params) => numberToCurrency(params.value),
              },
              {
                field: "availableCashPercentage",
                headerName: "Available Cash Percentage",
                sortable: false,
                disableColumnMenu: true,
                flex: 1,
                minWidth: 190,
                valueFormatter: (params) =>
                  moneyCommaSeparatorPoint(params.value),
              },
              {
                field: "availableCashEquivalent",
                headerName: "Cash Equivalent",
                sortable: false,
                disableColumnMenu: true,
                flex: 1,
                minWidth: 190,
                valueFormatter: (params) => numberToCurrency(params.value),
              },
              {
                field: "totalCash",
                headerName: "Total Cash",
                sortable: false,
                disableColumnMenu: true,
                flex: 1,
                minWidth: 190,
                valueFormatter: (params) => numberToCurrency(params.value),
              },
            ]}
          />
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default SelectPortfolioModal;
