import { useMutation } from "@tanstack/react-query";
import { Grid2, SimpleLoader } from "components";
import { useFetchQuery } from "hooks/useQueries";
import toast from "react-hot-toast";
import { EMAIL_CONFIGURATION, REPLY_EMAIL_PORTFOLIO } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import useDocumentTitle from "utils/useDocumentTitle";
import EmailConfig from "./EmailConfig";
import EmailHeaderSection from "./EmailHeaderSection";
import EmailReply from "./EmailReply";

const SmtpSetup = () => {
  useDocumentTitle("Email Configuration");

  const { data, refetch, isFetching } = useFetchQuery({
    key: ["EMAIL_CONFIGURATION"],
    route: EMAIL_CONFIGURATION,
  });

  const { data: handleReplyTo, isFetching: replyToFetching } = useFetchQuery({
    key: ["GET_REPLAY_EMAIL_LIST"],
    route: REPLY_EMAIL_PORTFOLIO,
  });

  const { mutate: handleUpdate, isLoading } = useMutation({
    mutationKey: ["UPDATE_EMAIL_CONFIGURATION"],
    mutationFn: (values: any) =>
      axiosInstance.patch(
        `/email-configuration/${data?.data?.data?.id}/`,
        values,
      ),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetch();
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: handleUpdateEmailConfig, isLoading: emailConfigLoading } =
    useMutation({
      mutationKey: ["HANDLE_UPDATE_EMAIL_CONFIGURATION"],
      mutationFn: (values: any) =>
        axiosInstance.patch(
          `/email-configuration-header/${data?.data?.data?.id}/`,
          values,
        ),
      onSuccess: (response) => {
        toast.success(
          response.data?.message || "Action completed successfully.",
        );
        refetch();
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  if (isFetching) return <SimpleLoader />;

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <EmailConfig
          data={data?.data?.data}
          handleUpdate={handleUpdate}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      </Grid2>
      <Grid2 size={12}>
        <EmailHeaderSection
          data={data?.data?.data}
          handleUpdate={handleUpdateEmailConfig}
          isLoading={emailConfigLoading}
        />
      </Grid2>
      <Grid2 size={12}>
        <EmailReply
          data={handleReplyTo?.data?.data}
          isLoading={replyToFetching}
        />
      </Grid2>
    </Grid2>
  );
};

export default SmtpSetup;
