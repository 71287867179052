import AdvisorCompanyMasterLayout from "layouts/AdvisorCompanyMasterLayout";
import useDocumentTitle from "utils/useDocumentTitle";

const CompanyMasterSetting = () => {
  useDocumentTitle("Company Master Setting");

  return (
    <AdvisorCompanyMasterLayout title="Company Master Setting">
      <div>Company Master Setting</div>
    </AdvisorCompanyMasterLayout>
  );
};

export default CompanyMasterSetting;
