/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  AiOutlineLineChart,
  BiTachometer,
  Button,
  Chip,
  GKTypography,
  Grid2,
  LuSparkle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleRemoveUnderscore } from "utils";
import { paletteLightTheme } from "utils/ColorTheme";
import { handleCapitalize } from "utils/common";
import { getHexaColor } from "utils/constants";
import { matchDate } from "utils/helpers";
import { numberToCurrency } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";
import EquityBasketAllocation from "./EquityBasketAllocation";

interface DataInterface {
  name: string;
  id: number;
  portfoliosValue: PortfoliosValue;
  totalMappedPortfolios: number;
  xirr: number;
  stocksTotal: number;
  lastChange: string[];
  mappedRevenue: number;
  code: string;
  minInvestment: number;
  investmentPhilosophy: any;
  methodology: any;
  clientRebalanceTime: any;
  feePlans: any[];
  feePlansList: any[];
  productType: any;
  ownerType: string;
  managedBy: string;
  investmentStyle: any[];
  riskProfile: any[];
  managementStyle: any[];
  overview: string;
  rationale: string;
  rebalanceTime: string;
  benchmark: string;
  createdOn: string;
  researchFiles: any[];
  volatility: any;
  timePeriod: any;
  mappedAum: number;
}

interface PortfoliosValue {
  totalInvestment: number;
  unrealGain: number;
  currentValue: number;
  availableCash: number;
}

interface Props {
  id?: any;
  key?: any;
  modelPortfolio?: DataInterface;
  isFetching?: any;
}

const EquityBasketCard = ({ id: key, modelPortfolio, isFetching }: Props) => {
  const navigate = useNavigate();

  const [openAllocationModal, setOpenAllocationModal] = useState(false);

  const { theme } = ThemeContainer();

  return (
    <>
      <TableContainer key={key}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={2}
                sx={{
                  padding: "16px !important",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                  }}
                >
                  <GKTypography
                    loading={isFetching}
                    fontSize={20}
                    fontWeight={600}
                  >
                    {modelPortfolio?.name}
                  </GKTypography>
                  {modelPortfolio?.riskProfile?.map((dataItem, index) => {
                    return (
                      <Chip
                        key={index}
                        icon={
                          <BiTachometer
                            color={`${getHexaColor(dataItem)}`}
                            size={16}
                          />
                        }
                        label={dataItem || "-"}
                        sx={{
                          borderRadius: 2,
                          p: "4px 8px 4px 3px",
                          background: `${getHexaColor(dataItem)}22`,
                          height: "unset",
                          color: `${getHexaColor(dataItem)}`,
                          "& .MuiChip-label": {
                            pr: 0,
                            pl: "10px",
                          },
                        }}
                      />
                    );
                  })}
                  {modelPortfolio?.volatility && (
                    <Chip
                      icon={<LuSparkle color="#8C1823" size={16} />}
                      label={
                        `${handleCapitalize(
                          modelPortfolio?.volatility,
                        )} volatility` || "-"
                      }
                      sx={{
                        borderRadius: 2,
                        p: "4px 8px 4px 3px",
                        background: `#8C182322`,
                        height: "unset",
                        color: "#8C1823",
                        textTransform: "capitalize",
                        "& .MuiChip-label": {
                          pr: 0,
                          pl: "10px",
                        },
                      }}
                    />
                  )}
                  {modelPortfolio?.timePeriod && (
                    <Chip
                      icon={<AiOutlineLineChart color="#0F172A" size={16} />}
                      label={
                        handleCapitalize(
                          handleRemoveUnderscore(modelPortfolio?.timePeriod),
                        ) || "-"
                      }
                      sx={{
                        borderRadius: 2,
                        p: "4px 8px 4px 3px",
                        background: `#0F172A22`,
                        height: "unset",
                        textTransform: "capitalize",
                        color: "#0F172A",
                        "& .MuiChip-label": {
                          pr: 0,
                          pl: "10px",
                        },
                      }}
                    />
                  )}
                </div>
              </TableCell>
              <TableCell colSpan={3} align="right">
                <Grid2 container gap={1} justifyContent={"flex-end"}>
                  <Button
                    variant="outlined"
                    color="dark"
                    size="small"
                    onClick={() => {
                      navigate(
                        `/app/equity-basket/${modelPortfolio?.id}/model-portfolio`,
                      );
                    }}
                  >
                    Model Portfolio
                  </Button>
                  <Button
                    variant="outlined"
                    color="dark"
                    size="small"
                    onClick={() => {
                      navigate(
                        `/app/equity-basket/${modelPortfolio?.id}/assets`,
                      );
                    }}
                  >
                    Assets
                  </Button>
                  <Button
                    variant="outlined"
                    color="dark"
                    size="small"
                    onClick={() => {
                      navigate(`/app/equity-basket/${modelPortfolio?.id}/`);
                    }}
                    sx={{
                      minWidth: "unset",
                    }}
                  >
                    Profile
                  </Button>
                </Grid2>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                width={"16.6%"}
                sx={{
                  padding: "16px !important",
                  width: "16.6%",
                  borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
                  background: "#F8FAFC",
                }}
              >
                <GKTypography
                  loading={isFetching}
                  fontSize={12}
                  color={theme.palette.secondaryText.main}
                >
                  Portfolios Mapped
                </GKTypography>
                <GKTypography
                  loading={isFetching}
                  fontSize={14}
                  fontWeight={600}
                >
                  {modelPortfolio.totalMappedPortfolios || "-"}
                </GKTypography>
              </TableCell>
              <TableCell
                width={"16.6%"}
                sx={{
                  padding: "16px !important",
                  width: "16.6%",
                  borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
                  background: "#F8FAFC",
                }}
              >
                <GKTypography
                  loading={isFetching}
                  fontSize={12}
                  color={theme.palette.secondaryText.main}
                >
                  AUM Mapped
                </GKTypography>
                <GKTypography
                  loading={isFetching}
                  fontSize={14}
                  fontWeight={600}
                >
                  {numberToCurrency(modelPortfolio.mappedAum) || "-"}
                </GKTypography>
              </TableCell>
              <TableCell
                width={"16.6%"}
                sx={{
                  padding: "16px !important",
                  width: "16.6%",
                  borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
                  background: "#F8FAFC",
                }}
              >
                <GKTypography
                  loading={isFetching}
                  fontSize={12}
                  color={theme.palette.secondaryText.main}
                >
                  Revenue Mapped
                </GKTypography>
                <GKTypography
                  loading={isFetching}
                  fontSize={14}
                  fontWeight={600}
                >
                  {numberToCurrency(modelPortfolio.mappedRevenue) || "-"}
                </GKTypography>
              </TableCell>
              <TableCell
                width={"16.6%"}
                sx={{
                  padding: "16px !important",
                  width: "16.6%",
                  borderRight: `solid 1px ${paletteLightTheme.palette.border.main}`,
                  background: "#F8FAFC",
                }}
              >
                <GKTypography
                  loading={isFetching}
                  fontSize={12}
                  color={theme.palette.secondaryText.main}
                >
                  XIRR
                </GKTypography>
                <GKTypography
                  loading={isFetching}
                  fontSize={14}
                  fontWeight={600}
                >
                  {modelPortfolio.xirr || "-"}
                </GKTypography>
              </TableCell>
              <TableCell
                width={"16.6%"}
                sx={{
                  width: "16.6%",
                  padding: "16px !important",
                  background: "#F8FAFC",
                }}
              >
                <GKTypography
                  loading={isFetching}
                  fontSize={12}
                  color={theme.palette.secondaryText.main}
                >
                  Last Updated on
                </GKTypography>
                <GKTypography
                  loading={isFetching}
                  fontSize={14}
                  fontWeight={600}
                >
                  {matchDate(modelPortfolio.lastChange[1]) || "-"}
                </GKTypography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                colSpan={6}
                sx={{
                  padding: "16px !important",
                }}
              >
                <Grid2 container gap={1} justifyContent={"space-between"}>
                  <Button
                    onClick={() => {
                      // navigate(
                      //   `/app/equity-basket/${modelPortfolio?.id}/new-allocation`,
                      //   {
                      //     state: {
                      //       name: modelPortfolio?.name || "-",
                      //     },
                      //   }
                      // );
                      setOpenAllocationModal(true);
                    }}
                    size="small"
                    variant="outlined"
                    color="dark"
                  >
                    Generate Trades
                  </Button>
                  <Button
                    onClick={() => {
                      navigate(
                        `/app/equity-basket/${modelPortfolio?.id}/order-manager`,
                      );
                    }}
                    sx={{ mr: "auto" }}
                    variant="outlined"
                    color="dark"
                    size="small"
                  >
                    Order Management
                  </Button>
                  <Button
                    variant="outlined"
                    color="dark"
                    size="small"
                    onClick={() => {
                      navigate(
                        `/app/equity-basket/${modelPortfolio?.id}/portfolio`,
                      );
                    }}
                  >
                    Portfolio Mapping
                  </Button>
                </Grid2>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {openAllocationModal && (
        <EquityBasketAllocation
          open={openAllocationModal}
          setOpen={setOpenAllocationModal}
          modelData={modelPortfolio}
        />
      )}
    </>
  );
};

export default EquityBasketCard;
