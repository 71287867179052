import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import Authentication from "./Authentication";
import LmOrderPlace from "./LmOrderPlace";

interface Props {
  orderId: string | number;
  token: string;
}

const LMOrderDetails = ({ orderId, token }: Props) => {
  const location = useLocation();

  const portfolioCode = new URLSearchParams(location.search).get(
    "portfolio_code",
  );
  const logo = location.search.split("logo=")[1];

  const form = useForm({
    defaultValues: {
      auth: {
        phoneNumber: "",
        email: "",
        countryCode: "+91",
        portfolioCode,
      },
      isVerified: false,
      emailOtp: "",
      phoneOtp: "",
    },
  });

  const watchVerified = form.watch("isVerified");

  return (
    <FormProvider {...form}>
      {watchVerified ? (
        <LmOrderPlace
          orderId={orderId}
          token={token}
          portfolioCode={portfolioCode}
          logo={logo}
        />
      ) : (
        <Authentication token={token} logo={logo} />
      )}
    </FormProvider>
  );
};

export default LMOrderDetails;
