import ComplianceAgreementModal from "Pages/Client/ComplianceTabs/ComplianceAgreementModal";
import {
  Card,
  GKButton,
  GKClientDataGrid,
  Grid2,
  Show,
  Typography,
} from "components";
import { useAgreement } from "hooks/useAgreement";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { ThemeContainer } from "utils/Theme";
import { ClientAgreementColumns } from "utils/columns";
import useDocumentTitle from "utils/useDocumentTitle";
import ClientCustomAgreement from "./ClientCustomAgreement";

const ClientAgreement = () => {
  useDocumentTitle("Client Agreement");

  const [addClientAgreement, setAddClientAgreement] = useState(false);

  const [customAgreement, setCustomAgreement] = useState(false);

  const { getAgreement, isAgreementLoading } = useAgreement();

  const { theme } = ThemeContainer();

  return (
    <DashboardLayout title="Client Agreement">
      <Card>
        <Grid2 container>
          <Grid2 size={12} display={"flex"} alignItems={"center"}>
            <Grid2 size={6}>
              <Typography fontSize={14} fontWeight={500}>
                Client Name
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={700}
                color={theme.palette.primary.main}
              >
                {getAgreement?.data?.data?.name || "-"}
              </Typography>
            </Grid2>

            <Grid2
              size={6}
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
              gap={1}
            >
              <GKButton
                onClick={() => setCustomAgreement(true)}
                variant="outlined"
                color="dark"
              >
                Generate Agreement
              </GKButton>
              <GKButton
                onClick={() => setAddClientAgreement(true)}
                variant="outlined"
                color="dark"
              >
                Upload Agreement
              </GKButton>
            </Grid2>
          </Grid2>
        </Grid2>
      </Card>
      <Grid2
        mt={2}
        container
        height={
          getAgreement?.data?.data?.response?.length === 0 ? "100%" : "auto"
        }
      >
        <GKClientDataGrid
          name="clientAgreement"
          showSearch={false}
          headerGridStyle={{
            justifyContent: "flex-end",
          }}
          loading={isAgreementLoading}
          columns={ClientAgreementColumns()}
          rows={getAgreement?.data?.data?.response || []}
          showHeaderGrid={false}
        />

        <Show.When isTrue={addClientAgreement}>
          <ComplianceAgreementModal
            setOpen={setAddClientAgreement}
            open={addClientAgreement}
          />
        </Show.When>
        <Show.When isTrue={customAgreement}>
          <ClientCustomAgreement
            setOpen={setCustomAgreement}
            open={customAgreement}
          />
        </Show.When>
      </Grid2>
    </DashboardLayout>
  );
};

export default ClientAgreement;
