import { useMutation } from "@tanstack/react-query";
import {
  Chip,
  GKButton,
  GKTextField,
  Grid2,
  LuSearch,
  MdDelete,
  MdOutlineClose,
  Popover,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { useUpdateLead } from "hooks/useUpdateLead";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { GET_ALL_LEAD_TAG } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { paletteLightTheme } from "utils/ColorTheme";
import { getHexaColor } from "utils/constants";
import { ThemeContainer } from "utils/Theme";

interface Props {
  leadData: {
    id: number;
    leadStatusStr: string;
    leadAssignedToStr: string;
    sourceOfLeadStr: string;
    tagsList: any[];
    hasMeeting: boolean;
    hasNotes: boolean;
    meetingCount: number;
    notesCount: number;
    name: string;
    email: string;
    companyName: string;
    countryCode: string;
    phoneNumber: string;
    lastContacted: string;
    panNumber: string;
    position: string;
    address: string;
    city: string;
    state: string;
    pincode: string;
    country: string;
    leadValue: string;
    description: string;
    conversionStatus: false;
    createdAt: string;
    updatedAt: string;
    branch: number;
    leadStatus: number;
    leadAssignedTo: number;
    sourceOfLead: number;
    tags: any[];
  };
}

interface TagLists {
  branch: number;
  createdAt: string;
  id: number;
  tag: string;
  updatedAt: string;
}

const LeadTagsPopup = ({ leadData }: Props) => {
  const { updateLead } = useUpdateLead();

  const { theme } = ThemeContainer();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [searchText, setSearchText] = useState("");

  const { data: getLeadTags, refetch } = useFetchQuery({
    key: ["GET_LEAD_TAGS"],
    route: GET_ALL_LEAD_TAG,
  });

  const { mutate: handleCreateLeadTag } = useMutation({
    mutationKey: ["ADD_LEAD_TAG"],
    mutationFn: (data: { tag: string }) =>
      axiosInstance.post("/lead-tags/", data),
    onSuccess: (response) => {
      refetch();
      setSearchText("");
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again.",
      );
    },
  });

  const { mutate: handleDeleteLeadTag } = useMutation({
    mutationKey: ["ADD_LEAD_TAG"],
    mutationFn: (id: number) => axiosInstance.delete(`/lead-tags/${id}/`),
    onSuccess: (response) => {
      refetch();
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again.",
      );
    },
  });

  const form = useForm({
    defaultValues: {
      tags: leadData?.tagsList,
    },
    values: {
      tags: leadData?.tagsList,
    },
  });

  form.watch();

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 8,
        }}
      >
        <Typography color={theme.palette.secondaryText.main} fontSize={12}>
          Tags
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          {form
            ?.getValues("tags")
            ?.slice(0, 2)
            ?.map((dataItem) => {
              return (
                <Chip
                  sx={{
                    padding: 0,
                    height: 26,
                    background: `${getHexaColor(dataItem?.tag)}22`,
                    color: `${getHexaColor(dataItem?.tag)}`,
                    borderRadius: 2,
                    fontWeight: 600,
                    "& .MuiChip-deleteIcon": {
                      color: `${getHexaColor(dataItem?.tag)}`,
                    },
                  }}
                  label={dataItem?.tag}
                />
              );
            })}
          <GKButton
            onClick={(e) => setAnchorEl(e.currentTarget)}
            sx={{
              padding: "12px 6px",
              lineHeight: 0,
              minWidth: "1px !important",
            }}
            variant="outlined"
            color="dark"
          >
            +
            {form?.getValues("tags")?.length > 2 &&
              form?.getValues("tags")?.length - 2}
          </GKButton>
        </div>
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        PaperProps={{
          style: { width: "15%", borderRadius: 8 },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid2 container sx={{ py: "6px" }}>
          <Grid2
            size={12}
            p={1}
            display={"flex"}
            alignItems={"center"}
            gap={0.5}
            flexWrap={"wrap"}
            borderBottom={`solid 1px ${paletteLightTheme.palette.border.main}`}
          >
            {form?.getValues("tags")?.map((dataItem) => {
              return (
                <Chip
                  deleteIcon={<MdOutlineClose size={14} />}
                  sx={{
                    background: `${getHexaColor(dataItem?.tag)}22`,
                    color: `${getHexaColor(dataItem?.tag)}`,
                    borderRadius: 2,
                    fontWeight: 600,
                    "& .MuiChip-deleteIcon": {
                      color: `${getHexaColor(dataItem?.tag)}`,
                    },
                  }}
                  label={dataItem?.tag}
                  onDelete={() => {
                    updateLead({
                      tags: [
                        ...form
                          .getValues("tags")
                          .filter((tag) => +tag.id !== +dataItem.id)
                          .map((tagValue) => tagValue.id),
                      ],
                    });
                  }}
                />
              );
            })}
          </Grid2>
          <Grid2
            size={12}
            p={1}
            borderBottom={`solid 1px ${paletteLightTheme.palette.border.main}`}
          >
            <GKTextField
              placeholder="Search"
              slotProps={{
                input: {
                  startAdornment: (
                    <LuSearch
                      size={18}
                      color={theme.palette.secondaryText.main}
                    />
                  ),
                },
              }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
              onKeyDown={(e) => {
                if (e.key === "Enter" && searchText?.length > 0) {
                  handleCreateLeadTag({ tag: searchText });
                }
              }}
            />
          </Grid2>
          <Grid2
            size={12}
            p={1}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            gap={1}
          >
            {getLeadTags?.data?.data?.filter((item: TagLists) =>
              item?.tag?.toLowerCase()?.includes(searchText?.toLowerCase()),
            )?.length === 0 ? (
              <Typography>Press enter to add tag</Typography>
            ) : (
              getLeadTags?.data?.data
                ?.filter((item: TagLists) =>
                  item?.tag?.toLowerCase()?.includes(searchText?.toLowerCase()),
                )
                ?.map((dataItem: TagLists) => {
                  return (
                    <Chip
                      deleteIcon={<MdDelete size={14} />}
                      sx={{
                        background: `${getHexaColor(dataItem?.tag)}22`,
                        color: `${getHexaColor(dataItem?.tag)}`,
                        borderRadius: 2,
                        fontWeight: 600,
                        display: "flex",
                        justifyContent: "space-between",
                        minWidth: 120,
                        "& .MuiChip-deleteIcon": {
                          color: `${getHexaColor(dataItem?.tag)}`,
                        },
                      }}
                      clickable
                      onClick={() => {
                        updateLead({
                          tags: [
                            ...form
                              .getValues("tags")
                              .map((dataItem) => dataItem.id),
                            dataItem.id,
                          ],
                        });
                      }}
                      label={dataItem?.tag}
                      onDelete={() => handleDeleteLeadTag(dataItem.id)}
                    />
                  );
                })
            )}
          </Grid2>
        </Grid2>
      </Popover>
    </>
  );
};

export default LeadTagsPopup;
