import { Grid2, Typography } from "@mui/material";

const NoInternetConnection = () => {
  return (
    <Grid2
      container
      height={"100vh"}
      width={"100vw"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <img
        src={"/Logos/Gridkey_BrandGuidelines-removebg-preview.png"}
        alt=""
        style={{
          height: "50px",
          transition: "all .4s linear",
        }}
      />
      <Typography
        fontWeight={600}
        fontSize={14}
        width={"50%"}
        textAlign={"center"}
      >
        It seems that you ran out of your connection. Please check you internet
        and continue your journey with us.
      </Typography>
    </Grid2>
  );
};

export default NoInternetConnection;
