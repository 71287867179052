import { GKModal, Grid2 } from "components";
import { useState } from "react";
import ReactCrop, { type Crop } from "react-image-crop";
import { GKModalProps } from "utils/commonInterface";

interface Props {
  img?: any;
}

const EditLogoModal = (props: Props & GKModalProps) => {
  const { open, setOpen, img } = props;
  const [crop, setCrop] = useState<Crop>();

  return (
    <GKModal open={open} setOpen={setOpen} modalTitle="Crop Logo">
      <Grid2 container>
        <Grid2 size={12}>
          <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
            <img
              onError={({ currentTarget }) => {
                currentTarget.src = "/Logos/errorImg.svg";
              }}
              src={img}
              alt=""
            />
          </ReactCrop>
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default EditLogoModal;
