import { GridColDef } from "@mui/x-data-grid-pro";
import { GKClientDataGrid } from "components";
import { extraColumnsField } from "utils/helpers";

const FeeAndCommissionTab = () => {
  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Client Name", "clientName", 150, 1),
    },
    {
      ...extraColumnsField("Invoice No.", "invoiceNo", 150, 1),
    },
    {
      ...extraColumnsField("Invoice Date", "invoiceDate", 150, 1),
      type: "date",
    },
    {
      ...extraColumnsField("Bill Amount", "billAmount", 150, 1),
    },
    {
      ...extraColumnsField("Commission Amount", "commissionAmount", 150, 1),
    },
    {
      ...extraColumnsField("Payment Status", "paymentStatus", 150, 1),
    },
    {
      ...extraColumnsField("Actions", "actions", 150, 1),
    },
  ];

  return <GKClientDataGrid name="feeCommission" columns={columns} rows={[]} />;
};

export default FeeAndCommissionTab;
