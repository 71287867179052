import { useAppContext } from "AppContext";
import {
  CircularProgress,
  Grid2,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TbRefresh,
  Typography,
} from "components";
import { HoldingDataInterface } from "interfaces/Portfolio";
import { paletteLightTheme } from "utils/ColorTheme";
import { numberToCurrency } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";

interface Props {
  cardDataLoading: boolean;
  holdingSummaryDetails: HoldingDataInterface;
  xirrLoading: boolean;
  setIsXirrRefresh: (value: boolean) => void;
}

const PortfolioDetailsHeaderCard = (props: Props) => {
  const {
    cardDataLoading,
    holdingSummaryDetails,
    xirrLoading,
    setIsXirrRefresh,
  } = props;

  const { theme } = ThemeContainer();

  const {
    state: { userData },
  } = useAppContext();

  return (
    <Grid2 size={12}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                style={{
                  borderRight: `solid 1px ${paletteLightTheme.palette.grey[300]}`,
                  borderBottom: "none",
                  padding: 16,
                  width: "16%",
                }}
              >
                <Typography
                  fontSize={12}
                  color={theme.palette.secondaryText.main}
                >
                  Net Contribution
                </Typography>
                <Typography fontWeight={600} fontSize={18}>
                  {cardDataLoading ? (
                    <Skeleton />
                  ) : (
                    numberToCurrency(
                      holdingSummaryDetails?.netContribution || 0,
                    )
                  )}
                </Typography>
              </TableCell>
              <TableCell
                style={{
                  borderRight: `solid 1px ${paletteLightTheme.palette.grey[300]}`,
                  borderBottom: "none",
                  padding: 16,
                  width: "16%",
                }}
              >
                <Typography
                  fontSize={12}
                  color={theme.palette.secondaryText.main}
                >
                  Ledger Balance
                </Typography>
                <Typography fontWeight={600} fontSize={18}>
                  {cardDataLoading ? (
                    <Skeleton />
                  ) : (
                    numberToCurrency(holdingSummaryDetails?.availableCash || 0)
                  )}
                </Typography>
              </TableCell>
              <TableCell
                style={{
                  borderRight: `solid 1px ${paletteLightTheme.palette.grey[300]}`,
                  borderBottom: "none",
                  padding: 16,
                  width: "16%",
                }}
              >
                <Typography
                  fontSize={12}
                  color={theme.palette.secondaryText.main}
                >
                  Dividend
                </Typography>
                <Typography fontWeight={600} fontSize={18}>
                  {cardDataLoading ? (
                    <Skeleton />
                  ) : (
                    numberToCurrency(holdingSummaryDetails?.otherGains || 0)
                  )}
                </Typography>
              </TableCell>
              <TableCell
                style={{
                  borderRight: `solid 1px ${paletteLightTheme.palette.grey[300]}`,
                  borderBottom: "none",
                  padding: 16,
                  width: "16%",
                }}
              >
                <Typography
                  fontSize={12}
                  color={theme.palette.secondaryText.main}
                >
                  Portfolio Value
                </Typography>
                <Typography fontWeight={600} fontSize={18}>
                  {cardDataLoading ? (
                    <Skeleton />
                  ) : (
                    numberToCurrency(holdingSummaryDetails?.portfolioValue || 0)
                  )}
                </Typography>
              </TableCell>
              <TableCell
                style={{
                  borderRight: `solid 1px ${paletteLightTheme.palette.grey[300]}`,
                  borderBottom: "none",
                  padding: 16,
                  width: "16%",
                }}
              >
                <Typography
                  fontSize={12}
                  color={theme.palette.secondaryText.main}
                >
                  Total Gain
                </Typography>
                <Typography fontWeight={600} fontSize={18}>
                  {cardDataLoading ? (
                    <Skeleton />
                  ) : (
                    numberToCurrency(
                      holdingSummaryDetails?.realisedGain +
                        holdingSummaryDetails?.unrealisedGain +
                        holdingSummaryDetails?.otherGains || 0,
                    )
                  )}
                </Typography>
              </TableCell>
              <TableCell
                style={{
                  padding: 16,
                  width: "20%",
                  borderBottom: "none",
                }}
              >
                <Typography
                  fontSize={12}
                  color={theme.palette.secondaryText.main}
                >
                  {userData?.showTwrr ? "TWRR" : "XIRR"}{" "}
                  {!userData?.showTwrr && (
                    <IconButton
                      disabled={xirrLoading}
                      onClick={() => {
                        setIsXirrRefresh(true);
                      }}
                    >
                      {xirrLoading ? (
                        <CircularProgress size={12} />
                      ) : (
                        <TbRefresh size={12} />
                      )}
                    </IconButton>
                  )}
                </Typography>
                <Typography fontWeight={600} fontSize={18}>
                  {userData?.showTwrr
                    ? +holdingSummaryDetails?.twrr?.toFixed(2) || "0"
                    : +holdingSummaryDetails?.xirr?.toFixed(2) || "0"}
                  <span
                    style={{
                      fontSize: "10px",
                      color: theme.palette.secondaryText.main,
                    }}
                  >
                    (*As of{" "}
                    {holdingSummaryDetails?.lastTwrrRefresh
                      ? holdingSummaryDetails?.lastTwrrRefresh
                      : holdingSummaryDetails?.lastRefreshTime}
                    )
                  </span>
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid2>
  );
};

export default PortfolioDetailsHeaderCard;
