import {
  Card,
  CgMathPlus,
  GKButton,
  Grid2,
  StatusField,
  Typography,
} from "components";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { paletteLightTheme } from "utils/ColorTheme";

interface Props {
  setActiveStep: (activeStep: number) => void;
}

const ClientOnboardingFinished = (props: Props) => {
  const { setActiveStep } = props;

  const navigate = useNavigate();

  const form = useFormContext();

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Grid2
        container
        spacing={1}
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          width: "50%",
        }}
      >
        <Grid2
          size={12}
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img loading="lazy" alt="" src="/Images/successImg.svg" />
          <Typography fontWeight={600} fontSize={18} mt={1} mb={2}>
            {form.getValues("kyc.kycStatus") === "completed" &&
            form.getValues("risk.riskStatus") === "completed" &&
            form.getValues("agreement.agreementStatus.status") === "completed"
              ? "You have successfully onboarded client"
              : "You have partially onboarded client"}
          </Typography>
        </Grid2>
        <Grid2
          size={12}
          width={"100%"}
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Card>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 8,
              }}
            >
              <Typography fontSize={18} fontWeight={600}>
                {form.getValues("personalDetails.name")}
              </Typography>
              <Typography fontSize={14} fontWeight={500}>
                {form.getValues("clientCode")}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 8,
                margin: "8px 0",
              }}
            >
              <Typography fontSize={14} color={"#475467"}>
                Phone:{" "}
              </Typography>
              <Typography fontSize={14} fontWeight={500}>
                {form.getValues("phone")}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 16,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 8,
                  border: `1px solid ${paletteLightTheme.palette.border.main}`,
                  padding: 8,
                  borderRadius: 8,
                }}
              >
                KYC{" "}
                <StatusField
                  statusString={form.getValues("kyc.kycStatus") || "Pending"}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 8,
                  border: `1px solid ${paletteLightTheme.palette.border.main}`,
                  padding: 8,
                  borderRadius: 8,
                }}
              >
                Risk Profile{" "}
                <StatusField
                  statusString={
                    form.getValues("riskProfile.riskStatus") || "Pending"
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 8,
                  border: `1px solid ${paletteLightTheme.palette.border.main}`,
                  padding: 8,
                  borderRadius: 8,
                }}
              >
                Agreement{" "}
                <StatusField
                  statusString={
                    form.getValues("agreement.agreementStatus.status") ||
                    "Pending"
                  }
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 16,
              }}
            >
              <GKButton
                variant="outlined"
                color="dark"
                size="small"
                onClick={() => {
                  form.reset(
                    {
                      isExistingClient: false,
                      branch: null,
                      id: null,
                      family: null,
                      personalDetails: {
                        email: "",
                        name: "",
                        gender: "",
                        maritalStatus: "",
                        fatherName: "",
                        motherName: "",
                        dateOfBirth: "",
                        address: "",
                        city: "",
                        state: "",
                        country: "",
                        pincode: "",
                        income: null,
                        occupation: "",
                        pan: "",
                        aadhar: "",
                        emergencyContact: "",
                        emergencyContactNum: "",
                      },
                      phone: "",
                      identification_type: "",
                      countryCode: "+91",
                      bankAccounts: [],
                      dematAccounts: [],
                      nominees: [],
                      signatureType: "",
                      sendOptions: "",
                      document: [],
                      kyc: null,
                      manual: false,
                      riskProfile: null,
                      agreement: null,
                    },
                    { keepValues: false },
                  );
                  setActiveStep(0);
                }}
                startIcon={<CgMathPlus size={14} />}
              >
                Add New Client
              </GKButton>
              <GKButton
                variant="contained"
                onClick={() => navigate("/app/dashboard")}
              >
                Finish
              </GKButton>
            </div>
          </Card>
        </Grid2>
      </Grid2>
    </div>
  );
};

export default ClientOnboardingFinished;
