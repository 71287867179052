/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/destructuring-assignment */
import { GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import { GKClientDataGrid, Typography } from "components";
import GKCheckbox from "components/GKCheckbox";
import { handleCapitalize } from "utils/common";
import {
  moneyCommaSeparator,
  moneyCommaSeparatorPoint,
} from "utils/MathFunction";

interface Props {
  responseData: ResponseData[];
  form: any;
  freezeData: any;
  setFreezeData: any;
  holdData: any;
  setHoldData: any;
}

interface ResponseData {
  id: number;
  value: number;
  holdings: number;
  availableCash: number;
  percentage: number;
  plan: {
    allocation: number;
    amountAllocated: number;
    currentAmount: number;
    deviation: number;
    quantity: number;
    amount: number;
    cmp: number;
    "transaction type": string;
    gridCode: string;
    date: string;
    company: string;
    sector: string;
    isin: string;
    bse: string;
    nse: string;
    status: string;
    "portfolio code": string;
    "portfolio name": string;
  }[];
}

const EquityBasketCreatOrder = (props: Props) => {
  const {
    responseData,
    freezeData,
    setFreezeData,
    holdData,
    setHoldData,
    form,
  } = props;

  const CompaniesTable = (params: GridRowParams) => {
    const { row } = params;

    const handleChildColumns = (type: string) => {
      switch (type) {
        case "allocation":
          return [
            {
              field: "company",
              headerName: "Company Name",
              flex: 1,
              minWidth: 150,
              disableColumnMenu: true,
              editable: false,
            },
            {
              field: "versionWeightage",
              headerName: "Model Weightage",
              flex: 1,
              minWidth: 150,
              disableColumnMenu: true,
              editable: false,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.versionWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              field: "portfolioWeightage",
              headerName: "Portfolio Weightage",
              flex: 1,
              minWidth: 160,
              disableColumnMenu: true,
              editable: false,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.portfolioWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              field: "allocationWeightage",
              headerName: "Allocation Weightage",
              flex: 1,
              minWidth: 170,
              disableColumnMenu: true,
              editable: false,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.allocationWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              field: "transaction type",
              headerName: "Transaction Type",
              flex: 1,
              minWidth: 150,
              valueFormatter: (params) => handleCapitalize(params?.value),
              disableColumnMenu: true,
              editable: false,
            },
            {
              field: "quantity",
              headerName: "Quantity",
              valueFormatter: (params) =>
                moneyCommaSeparatorPoint(params.value),
              flex: 1,
              valueGetter: (params) => params.row.quantity || "0",
              minWidth: 100,
              disableColumnMenu: true,
              editable: false,
            },
            {
              field: "cmp",
              headerName: "CMP",
              flex: 1,
              minWidth: 130,
              disableColumnMenu: true,
              editable: false,
              valueGetter: (value) => moneyCommaSeparator(value.value),
            },
            {
              field: "amount",
              headerName: "Trade Amount",
              flex: 1,
              minWidth: 150,
              disableColumnMenu: true,
              editable: false,
              valueGetter: (value) => moneyCommaSeparator(value.value),
            },
            {
              field: "afterTradeWeightage",
              headerName: "After Trade Weightage",
              flex: 1,
              minWidth: 200,
              disableColumnMenu: true,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.afterTradeWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              field: "freeze",
              headerName: "Freeze",
              editable: false,
              disableColumnMenu: true,
              maxWidth: 150,
              renderHeader: () => {
                return (
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 4 }}
                  >
                    <GKCheckbox
                      checked={
                        freezeData?.filter((dataItem: any) => {
                          return dataItem?.id === row?.id;
                        })?.length === row?.plan?.length
                      }
                      onChange={(e) => {
                        e.stopPropagation();
                        if (e.target.checked) {
                          const selectedData = [
                            ...freezeData?.filter((dataItem: any) => {
                              return dataItem?.id !== row?.id;
                            }),
                            ...row?.plan?.map((dataItem: any) => {
                              return {
                                id: row?.id,
                                cash: row?.cash,
                                gridCode: dataItem?.gridCode,
                              };
                            }),
                          ];
                          setFreezeData(selectedData);
                        } else {
                          setFreezeData([
                            ...freezeData?.filter((dataItem: any) => {
                              return dataItem?.id !== row?.id;
                            }),
                          ]);
                        }
                      }}
                    />
                    <Typography>Freeze</Typography>
                  </div>
                );
              },
              renderCell: (params) => {
                return (
                  <GKCheckbox
                    sx={{ padding: "0" }}
                    checked={
                      freezeData?.filter(
                        (item: any) =>
                          item?.id === row?.id &&
                          item?.gridCode === params?.row?.gridCode,
                      )?.length === 1
                    }
                    onChange={(e) => {
                      const newData = {
                        id: row?.id,
                        cash: row?.cash,
                        gridCode: params?.row?.gridCode,
                      };

                      const filterData = freezeData?.filter((dataItem: any) => {
                        return dataItem?.id !== row?.id;
                      });

                      const filterSameData = freezeData?.filter(
                        (dataItem: any) => {
                          return (
                            dataItem?.id === row?.id &&
                            dataItem?.gridCode !== params?.row?.gridCode
                          );
                        },
                      );
                      if (e.target.checked) {
                        setFreezeData([...freezeData, newData]);
                      } else {
                        setFreezeData([...filterData, ...filterSameData]);
                      }
                    }}
                  />
                );
              },
            },
            {
              field: "hold",
              headerName: "Hold",
              editable: false,
              disableColumnMenu: true,
              maxWidth: 150,
              renderHeader: () => {
                return (
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 4 }}
                  >
                    <GKCheckbox
                      checked={
                        holdData?.filter((dataItem: any) => {
                          return dataItem?.id === row?.id;
                        })?.length === row?.plan?.length
                      }
                      onChange={(e) => {
                        e.stopPropagation();
                        if (e.target.checked) {
                          setHoldData([
                            ...holdData?.filter((dataItem: any) => {
                              return dataItem?.id !== row?.id;
                            }),
                            ...row?.plan?.map((dataItem: any) => {
                              return {
                                id: row?.id,
                                cash: row?.cash,
                                gridCode: dataItem?.gridCode,
                              };
                            }),
                          ]);
                        } else {
                          setHoldData([
                            ...holdData?.filter((dataItem: any) => {
                              return dataItem?.id !== row?.id;
                            }),
                          ]);
                        }
                      }}
                    />
                    <Typography>Hold</Typography>
                  </div>
                );
              },
              renderCell: (params) => {
                return (
                  <GKCheckbox
                    sx={{ padding: 0 }}
                    checked={
                      holdData?.filter(
                        (item: any) =>
                          item?.id === row?.id &&
                          item?.gridCode === params?.row?.gridCode,
                      )?.length === 1
                    }
                    onChange={(e) => {
                      const newData = {
                        id: row?.id,
                        cash: row?.cash,
                        gridCode: params?.row?.gridCode,
                      };

                      const filterSameData = holdData?.filter(
                        (dataItem: any) => {
                          return dataItem?.id !== row?.id;
                        },
                      );

                      const filterData = holdData?.filter((dataItem: any) => {
                        return (
                          dataItem?.id === row?.id &&
                          dataItem?.gridCode !== params?.row?.gridCode
                        );
                      });

                      if (e.target.checked) {
                        setHoldData([...holdData, newData]);
                      } else {
                        setHoldData([...filterData, ...filterSameData]);
                      }
                    }}
                  />
                );
              },
            },
          ] as GridColDef[];
        case "exit":
          return [
            {
              field: "company",
              headerName: "Company Name",
              flex: 1,
              minWidth: 150,
              disableColumnMenu: true,
              editable: false,
            },
            {
              field: "versionWeightage",
              headerName: "Model Weightage",
              flex: 1,
              minWidth: 150,
              disableColumnMenu: true,
              editable: false,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.versionWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              field: "portfolioWeightage",
              headerName: "Portfolio Weightage",
              flex: 1,
              minWidth: 160,
              disableColumnMenu: true,
              editable: false,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.portfolioWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              field: "transaction type",
              headerName: "Transaction Type",
              flex: 1,
              minWidth: 150,
              valueFormatter: (params) => handleCapitalize(params?.value),
              disableColumnMenu: true,
              editable: false,
            },
            {
              field: "quantity",
              headerName: "Quantity",
              valueFormatter: (params) =>
                moneyCommaSeparatorPoint(params.value),
              flex: 1,
              valueGetter: (params) => params.row.quantity || "0",
              minWidth: 100,
              disableColumnMenu: true,
              editable: false,
            },
            {
              field: "cmp",
              headerName: "CMP",
              flex: 1,
              minWidth: 130,
              disableColumnMenu: true,
              editable: false,
              valueGetter: (value) => moneyCommaSeparator(value.value),
            },
            {
              field: "amount",
              headerName: "Exit Amount",
              flex: 1,
              minWidth: 150,
              disableColumnMenu: true,
              editable: false,
              valueGetter: (value) => moneyCommaSeparator(value.value),
            },
            {
              field: "afterTradeWeightage",
              headerName: "After Trade Weightage",
              flex: 1,
              minWidth: 200,
              disableColumnMenu: true,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.afterTradeWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              field: "hold",
              headerName: "Hold",
              editable: false,
              disableColumnMenu: true,
              align: "right",
              headerAlign: "right",
              maxWidth: 80,
              renderCell: (params) => {
                return (
                  <GKCheckbox
                    sx={{ padding: 0 }}
                    checked={
                      holdData?.filter(
                        (item: any) =>
                          item?.id === row?.id &&
                          item?.gridCode === params?.row?.gridCode,
                      )?.length === 1
                    }
                    onChange={(e) => {
                      const newData = {
                        id: row?.id,
                        cash: row?.cash,
                        gridCode: params?.row?.gridCode,
                      };

                      const filterSameData = holdData?.filter(
                        (dataItem: any) => {
                          return dataItem?.id !== row?.id;
                        },
                      );

                      const filterData = holdData?.filter((dataItem: any) => {
                        return (
                          dataItem?.id === row?.id &&
                          dataItem?.gridCode !== params?.row?.gridCode
                        );
                      });

                      if (e.target.checked) {
                        setHoldData([...holdData, newData]);
                      } else {
                        setHoldData([...filterData, ...filterSameData]);
                      }
                    }}
                  />
                );
              },
            },
          ] as GridColDef[];
        case "rebalance":
          return [
            {
              field: "company",
              headerName: "Company Name",
              flex: 1,
              minWidth: 150,
              disableColumnMenu: true,
              editable: false,
            },
            {
              field: "versionWeightage",
              headerName: "Model Weightage",
              flex: 1,
              minWidth: 150,
              disableColumnMenu: true,
              editable: false,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.versionWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              field: "portfolioWeightage",
              headerName: "Portfolio Weightage",
              flex: 1,
              minWidth: 160,
              disableColumnMenu: true,
              editable: false,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.portfolioWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              field: "deviationPercent",
              headerName: "Deviation Weightage",
              flex: 1,
              minWidth: 170,
              disableColumnMenu: true,
              editable: false,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.deviationPercent || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              field: "deviation",
              headerName: "Deviation Amount",
              flex: 1,
              minWidth: 170,
              disableColumnMenu: true,
              editable: false,
              renderCell: (value) => {
                return (
                  <Typography>
                    {moneyCommaSeparator(Number(value?.row.deviation || 0))}
                  </Typography>
                );
              },
            },
            {
              field: "transaction type",
              headerName: "Transaction Type",
              flex: 1,
              minWidth: 150,
              valueFormatter: (params) => handleCapitalize(params?.value),
              disableColumnMenu: true,
              editable: false,
            },
            {
              field: "quantity",
              headerName: "Quantity",
              valueFormatter: (params) =>
                moneyCommaSeparatorPoint(params.value),
              flex: 1,
              valueGetter: (params) => params.row.quantity || "0",
              minWidth: 100,
              disableColumnMenu: true,
              editable: false,
            },
            {
              field: "cmp",
              headerName: "CMP",
              flex: 1,
              minWidth: 130,
              disableColumnMenu: true,
              editable: false,
              valueGetter: (value) => moneyCommaSeparator(value.value),
            },
            {
              field: "amount",
              headerName: "Trade Amount",
              flex: 1,
              minWidth: 150,
              disableColumnMenu: true,
              editable: false,
              valueGetter: (value) => moneyCommaSeparator(value.value),
            },
            {
              field: "afterTradeWeightage",
              headerName: "After Trade Weightage",
              flex: 1,
              minWidth: 200,
              disableColumnMenu: true,
              renderCell: (value) => {
                return (
                  <Typography>
                    {Number(value?.row.afterTradeWeightage || 0)?.toFixed(2)}%
                  </Typography>
                );
              },
            },
            {
              field: "freeze",
              headerName: "Freeze",
              editable: false,
              disableColumnMenu: true,
              maxWidth: 150,
              renderHeader: () => {
                return (
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 4 }}
                  >
                    <GKCheckbox
                      checked={
                        freezeData?.filter((dataItem: any) => {
                          return dataItem?.id === row?.id;
                        })?.length === row?.plan?.length
                      }
                      onChange={(e) => {
                        e.stopPropagation();
                        if (e.target.checked) {
                          setFreezeData([
                            ...freezeData?.filter((dataItem: any) => {
                              return dataItem?.id !== row?.id;
                            }),
                            ...row?.plan?.map((dataItem: any) => {
                              return {
                                id: row?.id,
                                cash: row?.cash,
                                gridCode: dataItem?.gridCode,
                              };
                            }),
                          ]);
                        } else {
                          setFreezeData([
                            ...freezeData?.filter((dataItem: any) => {
                              return dataItem?.id !== row?.id;
                            }),
                          ]);
                        }
                      }}
                    />
                    <Typography>Freeze</Typography>
                  </div>
                );
              },
              renderCell: (params) => {
                return (
                  <GKCheckbox
                    checked={
                      freezeData?.filter(
                        (item: any) =>
                          item?.id === row?.id &&
                          item?.gridCode === params?.row?.gridCode,
                      )?.length === 1
                    }
                    onChange={(e) => {
                      const newData = {
                        id: row?.id,
                        cash: row?.cash,
                        gridCode: params?.row?.gridCode,
                      };

                      const filterData = freezeData?.filter((dataItem: any) => {
                        return dataItem?.id !== row?.id;
                      });

                      const filterSameData = freezeData?.filter(
                        (dataItem: any) => {
                          return (
                            dataItem?.id === row?.id &&
                            dataItem?.gridCode !== params?.row?.gridCode
                          );
                        },
                      );

                      if (e.target.checked) {
                        setFreezeData([...freezeData, newData]);
                      } else {
                        setFreezeData([...filterData, ...filterSameData]);
                      }
                    }}
                  />
                );
              },
            },
            {
              field: "hold",
              headerName: "Hold",
              editable: false,
              disableColumnMenu: true,
              maxWidth: 150,
              renderHeader: () => {
                return (
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 4 }}
                  >
                    <GKCheckbox
                      checked={
                        holdData?.filter((dataItem: any) => {
                          return dataItem?.id === row?.id;
                        })?.length === row?.plan?.length
                      }
                      onChange={(e) => {
                        e.stopPropagation();
                        if (e.target.checked) {
                          setHoldData([
                            ...holdData?.filter((dataItem: any) => {
                              return dataItem?.id !== row?.id;
                            }),
                            ...row?.plan?.map((dataItem: any) => {
                              return {
                                id: row?.id,
                                cash: row?.cash,
                                gridCode: dataItem?.gridCode,
                              };
                            }),
                          ]);
                        } else {
                          setHoldData([
                            ...holdData?.filter((dataItem: any) => {
                              return dataItem?.id !== row?.id;
                            }),
                          ]);
                        }
                      }}
                    />
                    <Typography>Hold</Typography>
                  </div>
                );
              },
              renderCell: (params) => {
                return (
                  <GKCheckbox
                    checked={
                      holdData?.filter(
                        (item: any) =>
                          item?.id === row?.id &&
                          item?.gridCode === params?.row?.gridCode,
                      )?.length === 1
                    }
                    onChange={(e) => {
                      const newData = {
                        id: row?.id,
                        cash: row?.cash,
                        gridCode: params?.row?.gridCode,
                      };
                      const filterData = holdData?.filter((dataItem: any) => {
                        return dataItem?.id !== row?.id;
                      });
                      const filterSameData = holdData?.filter(
                        (dataItem: any) => {
                          return (
                            dataItem?.id === row?.id &&
                            dataItem?.gridCode !== params?.row?.gridCode
                          );
                        },
                      );
                      if (e.target.checked) {
                        setHoldData([...holdData, newData]);
                      } else {
                        setHoldData([...filterSameData, ...filterData]);
                      }
                    }}
                  />
                );
              },
            },
          ] as GridColDef[];

        default:
          return [] as GridColDef[];
      }
    };
    return (
      <div
        style={{
          padding: 10,
        }}
      >
        <GKClientDataGrid
          name="modelAllocationCreateOrder"
          pagination={false}
          showHeaderGrid={false}
          density="compact"
          columns={handleChildColumns(form.getValues("allocationType"))}
          rows={
            row?.plan?.map((dataItem: any, i: any) => {
              return {
                ...dataItem,
                id: i,
              };
            }) || []
          }
        />
      </div>
    );
  };

  const handleParentColumnsRetuurn = (type: string) => {
    switch (type) {
      case "exit":
        return [
          {
            field: "portfolioCode",
            headerName: "Portfolio Name / Code",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <div>
                  <Typography fontWeight={700} whiteSpace={"nowrap"}>
                    {params?.row?.plan &&
                      params?.row?.plan[0][`portfolio name`]}
                  </Typography>
                  <Typography whiteSpace={"nowrap"}>
                    {params?.row?.plan &&
                      params?.row?.plan[0][`portfolio code`]}
                  </Typography>{" "}
                </div>
              );
            },
          },

          {
            field: "ledgerBalance",
            headerName: "Ledger Balance",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              const { ledgerBalance } = params?.row;
              return (
                <Typography>{moneyCommaSeparator(ledgerBalance)}</Typography>
              );
            },
          },
          {
            field: "currentAmount",
            headerName: "Current Holdings",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              const { currentAmount } = params?.row;
              return (
                <Typography>{moneyCommaSeparator(currentAmount)}</Typography>
              );
            },
          },

          {
            field: "exitPercentage",
            headerName: "Exit % of holdings",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              const { exitWeightage } = params?.row;
              return (
                <Typography>{Number(exitWeightage).toFixed(2)}%</Typography>
              );
            },
          },
          {
            field: "totalSellValue",
            headerName: "Exit Amount",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              const { totalSellValue } = params?.row;
              return (
                <Typography>{moneyCommaSeparator(totalSellValue)}</Typography>
              );
            },
          },
          {
            field: "afterTradeLedgerBalance",
            headerName: "After Trade Ledger Balance",
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (value) => moneyCommaSeparator(value.value),
          },
        ] as GridColDef[];
      case "rebalance":
        return [
          {
            field: "portfolioCode",
            headerName: "Portfolio Name / Code",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <div>
                  <Typography fontWeight={700} whiteSpace={"nowrap"}>
                    {params?.row?.plan &&
                      params?.row?.plan[0][`portfolio name`]}
                  </Typography>
                  <Typography whiteSpace={"nowrap"}>
                    {params?.row?.plan &&
                      params?.row?.plan[0][`portfolio code`]}
                  </Typography>{" "}
                </div>
              );
            },
          },

          {
            field: "Current Holding Value",
            headerName: "Current Holding Value",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              const { currentAmount } = params?.row;
              return (
                <Typography>{moneyCommaSeparator(currentAmount)}</Typography>
              );
            },
          },
          {
            field: "ledgerBalance",
            headerName: "Ledger Balance",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              const { ledgerBalance } = params?.row;
              return (
                <Typography>{moneyCommaSeparator(ledgerBalance)}</Typography>
              );
            },
          },

          {
            field: "Trade Amount",
            headerName: "Net Trade Amount",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              const { totalBuyValue, totalSellValue } = params?.row;

              return (
                <Typography>
                  {moneyCommaSeparator(
                    Number(totalBuyValue) - Number(totalSellValue),
                  )}
                </Typography>
              );
            },
          },
          {
            field: "Rebalance Amount",
            headerName: "Rebalance Amount",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              const { rebalanceValue } = params?.row;
              return (
                <Typography>
                  {moneyCommaSeparator(Number(rebalanceValue))}
                </Typography>
              );
            },
          },
          {
            field: "afterTradeLedgerBalance",
            headerName: "After Trade Ledger Balance",
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (value) => moneyCommaSeparator(value.value),
          },
        ] as GridColDef[];
      case "allocation":
        return [
          {
            field: "portfolioCode",
            headerName: "Portfolio Name / Code",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <div>
                  <Typography fontWeight={700} whiteSpace={"nowrap"}>
                    {params?.row?.plan &&
                      params?.row?.plan[0][`portfolio name`]}
                  </Typography>
                  <Typography whiteSpace={"nowrap"}>
                    {params?.row?.plan &&
                      params?.row?.plan[0][`portfolio code`]}
                  </Typography>{" "}
                </div>
              );
            },
          },

          {
            field: "portfolioValue",
            headerName: "Portfolio Value",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <Typography>
                  {moneyCommaSeparator(params?.row?.portfolioValue || 0)}
                </Typography>
              );
            },
          },
          {
            field: "ledgerBalance",
            headerName: "Ledger Balance",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <Typography>
                  {`${moneyCommaSeparator(params?.row?.ledgerBalance || 0)} [${
                    params?.row?.availableCashPercentage || 0
                  }]`}
                </Typography>
              );
            },
          },

          {
            field: "allocationPercentage",
            headerName: "Allocation % of Ledger",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <Typography>
                  {Number(params?.row?.percentage)?.toFixed(2)}%
                </Typography>
              );
            },
          },
          {
            field: "amount",
            headerName: "Trade Amount",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <Typography>
                  {moneyCommaSeparator(params?.row?.totalBuyValue)}{" "}
                </Typography>
              );
            },
          },
          {
            field: "afterTradeLedgerBalance",
            headerName: "After Trade Ledger Balance",
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (value) => moneyCommaSeparator(value.value),
          },
        ] as GridColDef[];
      default:
        return [] as GridColDef[];
    }
  };

  return (
    <GKClientDataGrid
      name="modelAllocationCreateOrder"
      showHeaderGrid={false}
      columns={handleParentColumnsRetuurn(form.getValues("allocationType"))}
      getDetailPanelContent={CompaniesTable}
      rows={
        responseData?.map((data: any, i: number) => {
          return {
            ...data,
            index: i,
          };
        }) || []
      }
    />
  );
};

export default EquityBasketCreatOrder;
