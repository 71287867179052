import { useAppContext } from "AppContext";
import {
  FormControl,
  GKSearchSelect,
  GKTextField,
  Grid2,
  TextField,
} from "components";
import { Controller, useFormContext } from "react-hook-form";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";

const brokerList = [
  { name: "Latin Manharlal", value: true },
  { name: "Non Latin Manharlal", value: false },
];

const BasicInfoTab = ({ isDisable }: { isDisable: boolean }) => {
  const form = useFormContext();

  const {
    state: {
      clientList,
      userData: { broker },
    },
  } = useAppContext();

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={broker?.lmSupport ? 4 : 3}>
        <FormControl variant="standard" fullWidth>
          <Controller
            name="portfolio.name"
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                inputLabel="Portfolio name"
                slotProps={{
                  htmlInput: {
                    readOnly: isDisable,
                  },
                }}
              />
            )}
          />
        </FormControl>
      </Grid2>
      <Grid2 size={broker?.lmSupport ? 4 : 3}>
        <FormControl variant="standard" fullWidth>
          <Controller
            name="portfolio.portfolioId"
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                inputLabel="Portfolio Code"
                slotProps={{
                  htmlInput: {
                    readOnly: isDisable,
                  },
                }}
              />
            )}
          />
        </FormControl>
      </Grid2>
      <Grid2 size={broker?.lmSupport ? 4 : 3}>
        <FormControl variant="standard" fullWidth>
          <Controller
            name="portfolio.committedValue"
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                inputLabel="Committed Value"
                type="number"
                slotProps={{
                  htmlInput: {
                    readOnly: isDisable,
                  },
                }}
              />
            )}
          />
        </FormControl>
      </Grid2>
      <Grid2 size={broker?.lmSupport ? 6 : 3}>
        <Controller
          name="portfolio.client"
          control={form.control}
          render={({ field }) => (
            <GKSearchSelect
              {...field}
              disableClearable
              options={clientList || []}
              getOptionLabel={(option) => option?.name || option}
              renderInput={(params) => <TextField {...params} />}
              inputLabel="Client"
              onChange={(event, val) => field.onChange(val?.id)}
              value={
                clientList?.find((data: any) => data?.id === field.value)?.name
              }
              readOnly={isDisable}
            />
          )}
        />
      </Grid2>
      {broker?.lmSupport && (
        <Grid2 size={6}>
          <Controller
            name="portfolio.isLm"
            control={form.control}
            render={({ field }) => (
              <GKSearchSelect
                disableClearable
                inputLabel="Select Broker"
                options={brokerList}
                value={
                  brokerList.find((broker) => broker.value === field.value)
                    ?.name || ""
                }
                onChange={(e, val) => field.onChange(val?.value)}
                getOptionLabel={(option) => option.name || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select Broker",
                      value: field.value,
                    })}
                  />
                )}
              />
            )}
          />
        </Grid2>
      )}
    </Grid2>
  );
};

export default BasicInfoTab;
