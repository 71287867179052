import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  GKModal,
  GKSearchSelect,
  GKTextField,
  Grid2,
  TextField,
  Typography,
} from "components";
import { Controller } from "react-hook-form";
import { GKModalProps } from "utils/commonInterface";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import useAutoImport from "./useAutoImport";

const AutoImportModal = (props: GKModalProps) => {
  const { open, setOpen } = props;

  const {
    handleFormSubmit,
    addLoading,
    portfoliosDataLoading,
    portfolioList,
    brokersList,
    brokerLoading,
    control,
    handleSubmit,
    id,
  } = useAutoImport({ setOpen });

  return (
    <GKModal
      modalTitle="Setup Auto Import"
      open={open}
      setOpen={setOpen}
      footer={
        <LoadingButton
          form="auto-import-form"
          type="submit"
          loading={addLoading}
          variant="contained"
        >
          Save
        </LoadingButton>
      }
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} id="auto-import-form">
        <Grid2 container spacing={1}>
          <Grid2 size={4}>
            <Controller
              control={control}
              name="portfolio"
              render={({ field }) => {
                return (
                  <GKSearchSelect
                    {...field}
                    loading={portfoliosDataLoading || brokerLoading}
                    disableClearable
                    disabled={Boolean(id)}
                    renderOption={(props, option) => {
                      return (
                        <li
                          {...props}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography fontWeight={600}>
                            {option.clientName}
                          </Typography>
                          <Typography>{option.name}</Typography>
                        </li>
                      );
                    }}
                    onChange={(e, val) => {
                      field.onChange(val);
                    }}
                    inputLabel="Select Portfolio"
                    value={portfolioList?.data?.find(
                      (dataItem: any) => dataItem?.id === Number(field?.value),
                    )}
                    options={portfolioList?.data || []}
                    getOptionLabel={(option) => option?.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Portfolio",
                          value: field.value,
                        })}
                      />
                    )}
                  />
                );
              }}
            />
          </Grid2>
          <Grid2 size={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                control={control}
                name="password"
                render={({ field }) => {
                  return (
                    <GKTextField
                      {...field}
                      loading={portfoliosDataLoading || brokerLoading}
                      inputLabel="Contract Notes Password"
                    />
                  );
                }}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={4}>
            <Controller
              control={control}
              name="broker"
              render={({ field }) => {
                return (
                  <GKSearchSelect
                    {...field}
                    disableClearable
                    onChange={(e, val) => {
                      field.onChange(val);
                    }}
                    loading={portfoliosDataLoading || brokerLoading}
                    inputLabel="Select broker"
                    options={brokersList?.data || []}
                    getOptionLabel={(option) => option?.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Broker",
                          value: field.value,
                        })}
                      />
                    )}
                  />
                );
              }}
            />
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default AutoImportModal;
