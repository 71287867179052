import { LoadingButton } from "@mui/lab";
import { Card, Divider, GKButton, Grid2, NoData } from "components";
import DashboardLayout from "layouts/DashboardLayout";
import { UserRoleTypes } from "utils/common";
import { NotesColor } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import NoteModal from "./NoteModal";
import NotesList from "./NotesList";
import useAdviceRegister from "./useAdviceRegister";

const AdviceRegisterNotes = () => {
  const {
    theme,
    openNoteModel,
    setOpenNoteModel,
    noteDetails,
    setNoteDetails,
    dispatch,
    userData,
    noteList,
    selectedFilter,
    setSelectedFilter,
    data,
    refetch,
    isFetching,
    isLoading,
    pageData,
    setPageData,
    noteFilter,
    getNoteTypeList,
    noteTypesLoading,
  } = useAdviceRegister();
  useDocumentTitle("Notes");

  return (
    <DashboardLayout title={"Notes"}>
      <Card style={{ height: "cal(1000vh - 86px)", overflow: "auto" }}>
        <Grid2 container position={"relative"}>
          <Grid2 container size={12}>
            <Grid2 size={8}>
              <LoadingButton
                onClick={() => {
                  setPageData(10);
                  dispatch({ type: "NOTE_FILTER", data: [] });
                  setSelectedFilter([]);
                }}
                style={{
                  border: "1px solid",
                  background:
                    !noteFilter.length && selectedFilter?.length === 0
                      ? `#53B795`
                      : theme.palette.light.main,
                  color:
                    !noteFilter.length && selectedFilter?.length === 0
                      ? theme.palette.light.main
                      : `#53B795`,
                  borderColor: `#53B795`,
                  marginRight: 10,
                }}
                loading={
                  isFetching && noteTypesLoading && selectedFilter?.length === 0
                }
              >
                {"#All"}
              </LoadingButton>
              {getNoteTypeList?.data?.data.map(
                (dataItem: any, index: number) => {
                  return (
                    <LoadingButton
                      onClick={() => {
                        setPageData(10);
                        if (
                          selectedFilter?.length !== 0 &&
                          selectedFilter?.includes(dataItem?.id)
                        ) {
                          dispatch({
                            type: "NOTE_FILTER",
                            data: noteFilter?.filter(
                              (item: any) => dataItem?.id !== item,
                            ),
                          });
                          setSelectedFilter(
                            selectedFilter?.filter(
                              (item) => item !== dataItem?.id,
                            ),
                          );
                        } else {
                          dispatch({
                            type: "NOTE_FILTER",
                            data: [...noteFilter, dataItem?.id],
                          });
                          setSelectedFilter([...selectedFilter, dataItem?.id]);
                        }
                      }}
                      key={index}
                      style={{
                        border: "1px solid",
                        background:
                          noteFilter.length &&
                          noteFilter
                            .map((element: any) => element)
                            ?.includes(dataItem?.id)
                            ? dataItem?.color
                            : theme.palette.light.main,
                        color:
                          noteFilter.length &&
                          noteFilter
                            .map((element: any) => element)
                            ?.includes(dataItem?.id)
                            ? theme.palette.light.main
                            : `${dataItem?.color}`,
                        borderColor: `${dataItem?.color}`,
                        marginRight: 10,
                      }}
                      loading={
                        isFetching &&
                        noteTypesLoading &&
                        selectedFilter?.includes(dataItem?.name)
                      }
                    >
                      {dataItem?.name}
                    </LoadingButton>
                  );
                },
              )}
            </Grid2>
            <Grid2 size={4} display={"flex"} justifyContent={"flex-end"}>
              {userData?.userRole !== UserRoleTypes.client && (
                <GKButton
                  variant="outlined"
                  onClick={() => {
                    setNoteDetails({});
                    setOpenNoteModel(true);
                  }}
                >
                  Add New Note
                </GKButton>
              )}
            </Grid2>
          </Grid2>
          <Grid2 size={12} my={2}>
            <Divider />
          </Grid2>
          {!isLoading && !noteList.length && (
            <Grid2 size={12}>
              <NoData />
            </Grid2>
          )}
          {noteList?.map((dataItem: any, index: number) => {
            return (
              <NotesList
                dataItem={{
                  ...dataItem,
                  noteTypesList: dataItem?.noteTypesList?.map(
                    (dataItem: any) => {
                      return {
                        ...dataItem,
                        color: NotesColor[dataItem?.name],
                      };
                    },
                  ),
                }}
                index={index}
                refetch={refetch}
                setOpenNoteModel={setOpenNoteModel}
                setNoteDetails={setNoteDetails}
              />
            );
          })}
          {noteList.length < data?.data?.totalCount && (
            <Grid2 size={12}>
              <LoadingButton
                fullWidth
                variant="outlined"
                loading={isFetching}
                onClick={() => {
                  setPageData(pageData + 10);
                }}
              >
                Load More
              </LoadingButton>
            </Grid2>
          )}
        </Grid2>
      </Card>

      {openNoteModel && (
        <NoteModal
          open={openNoteModel}
          setOpen={setOpenNoteModel}
          noteDetails={noteDetails}
        />
      )}
    </DashboardLayout>
  );
};

export default AdviceRegisterNotes;
