import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import {
  FormControl,
  FormControlLabel,
  GKDatePicker,
  GKModal,
  GKSearchSelect,
  GKTextArea,
  GKTextField,
  Grid2,
  Switch,
  TextField,
} from "components";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { statesJson } from "utils/stateJSON";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { validateNotWhitespaceOnly } from "utils/validationSchema";

interface Props {
  calculationData?: any;
  isDefault?: boolean;
  refetch?: any;
  portfolioId?: string | number;
}

const CalculationProformaModal = (props: Props & GKModalProps) => {
  const {
    open,
    setOpen,
    calculationData = {},
    isDefault = true,
    refetch,
    portfolioId,
  } = props;

  const navigate = useNavigate();

  const {
    state: { tab },
    dispatch,
  } = useAppContext();

  const { mutate: createProforma, isLoading } = useMutation({
    mutationKey: ["CREATE_PROFORMA"],
    mutationFn: (data) => axiosInstance.post(`/proforma-history/`, data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      setOpen(false);
      if (portfolioId) {
        navigate(-1);
        dispatch({ type: "TAB", data: { ...tab, tabOfPortfolioRevenue: 2 } });
      } else {
        dispatch({ type: "TAB", data: { ...tab, tabValueOfRevenue: 3 } });
        navigate("/app/revenue");
      }
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: createDirectProforma, isLoading: directProformaLoading } =
    useMutation({
      mutationKey: ["CREATE_DIRECT_PROFORMA"],
      mutationFn: (data) =>
        axiosInstance.post(
          `/fee-calculation-history/${calculationData}/proforma_convert/`,
          data,
        ),
      onSuccess: (response) => {
        toast.success(
          response.data?.message || "Action completed successfully.",
        );
        setOpen(false);
        refetch();
        if (portfolioId) {
          dispatch({ type: "TAB", data: { ...tab, tabOfPortfolioRevenue: 2 } });
        } else {
          dispatch({ type: "TAB", data: { ...tab, tabValueOfRevenue: 3 } });
          navigate("/app/revenue");
        }
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  const form = useForm({
    defaultValues: {
      advisorState: "",
      clientState: "",
      proforma_date: dayjs(),
      proforma_number: "",
      isUpdateHighwaterMark: false,
      includeGst: true,
      remarks: "",
    },
  });

  form.watch();

  const stateOptions = statesJson.map((dataItem) => {
    return {
      ...dataItem,
      name: dataItem.state,
      value: dataItem.state,
    };
  });

  return (
    <GKModal
      modalTitle={"Proforma Invoice Details"}
      open={open}
      setOpen={setOpen}
      footer={
        <Grid2
          container
          size={12}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Controller
            name="includeGst"
            control={form.control}
            defaultValue={false}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Switch {...field} checked={field.value} color="primary" />
                }
                label="Include GST"
                labelPlacement="end"
              />
            )}
          />
          <LoadingButton
            loading={isLoading || directProformaLoading}
            type="submit"
            size="medium"
            variant="contained"
            form="proforma-state-form"
          >
            Save
          </LoadingButton>
        </Grid2>
      }
    >
      <form
        onSubmit={form.handleSubmit((values: any) => {
          if (isDefault) {
            createDirectProforma({
              ...values,
              proforma_date: dayjs(values.proforma_date).format("YYYY-MM-DD"),
            });
          } else {
            createProforma({
              ...values,
              calculationData: calculationData,
              portfolio: calculationData?.portfolio,
              clientName: calculationData?.details?.clientName,
              feeAmount: calculationData?.totalAmount,
              feePlanName: calculationData?.feePlanList,
              feeDateRange: calculationData?.feeDateRange,
              proforma_date: dayjs(values.proforma_date).format("YYYY-MM-DD"),
            });
          }
        })}
        id="proforma-state-form"
      >
        <Grid2 container spacing={1}>
          {form.getValues("includeGst") && (
            <>
              <Grid2 size={6}>
                <Controller
                  name="advisorState"
                  control={form.control}
                  rules={{
                    required: {
                      value: form.getValues("includeGst"),
                      message: "Advisor state is require",
                    },
                  }}
                  render={({ field }) => (
                    <GKSearchSelect
                      requiredField
                      inputLabel="Advisor State"
                      options={stateOptions || []}
                      value={
                        stateOptions.find(
                          (dataItem) =>
                            dataItem.value.toLowerCase() ===
                            field.value.toLowerCase(),
                        ) || ""
                      }
                      onChange={(e, val) => field.onChange(val?.value)}
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="advisorState"
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select State",
                            value: field.value,
                          })}
                          error={Boolean(form?.formState?.errors.advisorState)}
                          helperText={
                            form?.formState?.errors.advisorState
                              ?.message as string
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={6}>
                <Controller
                  name="clientState"
                  control={form.control}
                  rules={{
                    required: {
                      value: form.getValues("includeGst"),
                      message: "Client state is require",
                    },
                  }}
                  render={({ field }) => (
                    <GKSearchSelect
                      requiredField
                      inputLabel="Client State"
                      options={stateOptions || []}
                      value={
                        stateOptions.find(
                          (dataItem) =>
                            dataItem.value.toLowerCase() ===
                            field.value.toLowerCase(),
                        ) || ""
                      }
                      onChange={(e, val) => field.onChange(val?.value)}
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...field}
                          name="clientState"
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select State",
                            value: field.value,
                          })}
                          error={Boolean(form?.formState?.errors.clientState)}
                          helperText={
                            form?.formState?.errors.clientState
                              ?.message as string
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid2>
            </>
          )}
          <Grid2 size={6}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="proforma_date"
                control={form.control}
                render={({ field }) => (
                  <GKDatePicker
                    {...field}
                    inputLabel="Proforma Invoice Date"
                    value={dayjs(field.value)}
                    onChange={(value) => {
                      field.onChange(dayjs(value));
                    }}
                    disableFuture
                    slotProps={{
                      textField: {
                        error: false,
                        helperText: false,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={6}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="proforma_number"
                control={form.control}
                rules={{
                  required: "Invoice number is required",
                  minLength: {
                    value: 1,
                    message: "Minimum length is 1 characters",
                  },
                  maxLength: {
                    value: 49,
                    message: "Maximum length is 49 characters",
                  },
                  validate: validateNotWhitespaceOnly,
                }}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Proforma Invoice Number"
                    error={Boolean(form?.formState?.errors.proforma_number)}
                    helperText={
                      form?.formState?.errors.proforma_number?.message
                    }
                  />
                )}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={12}>
            <FormControl fullWidth variant="standard">
              <Controller
                name="remarks"
                control={form.control}
                render={({ field }) => (
                  <GKTextArea {...field} inputLabel="Remarks" />
                )}
              />
            </FormControl>
          </Grid2>
          {/* <Grid item xs={6}>
            <Controller
              name="isUpdateHighwaterMark"
              control={form.control}
              render={({ field }) => {
                return (
                  <FormControlLabel
                    {...field}
                    label="Update High waterMark"
                    control={
                      <GKCheckbox
                        value={field.value}
                        onChange={(event) => {
                          field.onChange(event.target.checked);
                        }}
                      />
                    }
                  />
                );
              }}
            />
          </Grid2> */}
        </Grid2>
      </form>
    </GKModal>
  );
};

export default CalculationProformaModal;
