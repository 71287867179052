/* eslint-disable prefer-destructuring */
import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  Divider,
  FormControl,
  GKButton,
  GKDatePicker,
  GKSearchSelect,
  Grid2,
  NoData,
  Show,
  TextField,
  Typography,
  ViewPdf,
} from "components";
import dayjs from "dayjs";
import useReport from "hooks/useReport";
import { isEmpty } from "lodash";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import CustomReportForm from "./CustomReportForm";
import CustomReportPreview from "./CustomReportPreview";
import TemplateModal from "./TemplateModal";

interface Props {
  selectedIndex: {
    index: number;
    loading: boolean;
  };
  setSelectedIndex: any;
  quoteList: any;
  benchmarkList: any;
  benchmarkLoading: boolean;
  setEditQuoteValue: any;
  setOpenQuoteModal: (openQuoteModal: boolean) => void;
}

enum PeriodOptions {
  inception = "inception",
  lastFY = "lastFY",
  custom = "custom",
  thisFY = "thisFY",
}

const GenerateReportForm = (props: Props) => {
  const form = useFormContext();
  const { handleDeleteQuote, generateQuote, portfolioList, isInitialLoading } =
    useReport();
  const { theme } = ThemeContainer();

  const {
    state: { companies },
  } = useAppContext();
  const [openPdfView, setOpenPdfView] = useState(false);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);

  const {
    selectedIndex,
    setSelectedIndex,
    quoteList,
    benchmarkList,
    benchmarkLoading,
    setEditQuoteValue,
    setOpenQuoteModal,
  } = props;

  const { setValue, getValues } = form;

  const MFOption = [
    {
      name: "Equity",
      value: "EQ",
    },
    {
      name: "Mutual Fund",
      value: "MF",
    },
    {
      name: "Private Equity",
      value: "PEQ",
    },
  ];

  const MFOptionList = MFOption?.filter(
    (data: any) =>
      !form
        .getValues("instType")
        ?.map((dataItem: any) => dataItem)
        .includes(data.value),
  );

  const handleClickPeriods = (val: string) => {
    setValue("currentOption", val);

    if (val === PeriodOptions.inception) {
      setValue("from_date", "1990-01-01");
      setValue("to_date", new Date().toISOString().split("T")[0]);
    }

    if (val === PeriodOptions.lastFY) {
      let fromDate = "";
      let toDate = "";
      if (dayjs().date() >= 3) {
        fromDate = new Date(new Date().getFullYear() - 1, 3, 2)
          .toISOString()
          .split("T")[0];
        toDate = new Date(new Date().getFullYear() - 0, 3, 1)
          .toISOString()
          .split("T")[0];
      } else {
        fromDate = new Date(new Date().getFullYear() - 2, 3, 2)
          .toISOString()
          .split("T")[0];
        toDate = new Date(new Date().getFullYear() - 1, 3, 1)
          .toISOString()
          .split("T")[0];
      }

      setValue("from_date", fromDate);
      setValue("to_date", toDate);
    }

    if (val === PeriodOptions.thisFY) {
      let fromDate = "";
      if (dayjs().date() >= 3) {
        fromDate = new Date(new Date().getFullYear() - 0, 3, 2)
          .toISOString()
          .split("T")[0];
      } else {
        fromDate = new Date(new Date().getFullYear() - 1, 3, 2)
          .toISOString()
          .split("T")[0];
      }

      setValue("from_date", fromDate);
      setValue("to_date", new Date().toISOString().split("T")[0]);
    }

    if (val === PeriodOptions.custom) {
      setValue("from_date", "");
      setValue("to_date", "");
    }
  };

  const selectedReport = getValues("selectedReported");

  const notAccessThisSectionReport = ![
    "custom_report",
    "portfolio_performance_report",
    "portfolio_summary_detailed_report",
    "xirr_report",
    "unrealized_capital_gain_summary_report",
    "unrealized_capital_gain_detailed_report",
    "historical_valuation_report",
    "stock_wise_holdings_report",
  ].includes(selectedReport?.reportName);

  return (
    <>
      <Show.When isTrue={isEmpty(selectedReport)}>
        <NoData
          customImg={
            <img loading="lazy" src="/LandingPage/noReport.svg" alt="" />
          }
          text="Report Preview"
          subText="Please select the report type for see the preview of report"
        />
      </Show.When>
      <Show.When isTrue={!isEmpty(selectedReport)}>
        <Grid2 container spacing={1.5}>
          <Grid2 size={12}>
            <Typography
              fontWeight={600}
              fontSize={18}
              textTransform={"capitalize"}
            >
              {selectedReport?.name}
            </Typography>
            <Typography>{selectedReport?.description}</Typography>
          </Grid2>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          {selectedReport?.reportName !== "stock_wise_holdings_report" &&
            (selectedReport?.reportName !== "custom_report" ||
              selectedReport?.reportType === "template") && (
              <Grid2 size={12}>
                <Controller
                  name="portfolio"
                  control={form.control}
                  render={({ field, formState: { errors } }) => (
                    <GKSearchSelect
                      {...field}
                      disabled={selectedIndex.loading}
                      inputLabel="Portfolio, Client or Family"
                      multiple
                      loading={isInitialLoading}
                      onChange={(event, val) => {
                        field.onChange(val);
                      }}
                      groupBy={(option: any) => option.type?.toUpperCase()}
                      renderOption={(props, option) => {
                        return (
                          <li
                            {...props}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography fontWeight={600}>
                              {option.clientName}
                            </Typography>
                            <Typography>{option.name}</Typography>
                          </li>
                        );
                      }}
                      options={
                        portfolioList?.data?.map((dataItem: any) => {
                          return {
                            ...dataItem,
                            name: `${dataItem.name} - ${dataItem.code}`,
                            value: dataItem.id,
                          };
                        }) || []
                      }
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select Portfolio, Client or Family",
                            value: field.value,
                          })}
                          error={!!errors?.portfolio}
                          helperText={errors?.portfolio?.message as string}
                        />
                      )}
                    />
                  )}
                />
              </Grid2>
            )}

          {selectedReport?.reportName !== "stock_wise_holdings_report" &&
            selectedReport?.reportName !== "income_report" &&
            selectedReport?.reportName !== "xirr_report" &&
            selectedReport?.reportName !== "portfolio_performance_report" &&
            selectedReport?.reportName !== "custom_report" &&
            selectedReport?.reportType !== "template" && (
              <Grid2 size={12}>
                <Controller
                  name="instType"
                  control={form.control}
                  render={({ field, formState: { errors } }) => (
                    <GKSearchSelect
                      {...field}
                      multiple
                      inputLabel="Select Equity, Mutual Fund or Both"
                      value={
                        MFOption?.filter((option) =>
                          field.value?.includes(option.value),
                        ) || []
                      }
                      onChange={(event, val) => {
                        const selectedIds = val.map(
                          (option: any) => option.value,
                        );
                        field.onChange(selectedIds);
                      }}
                      options={MFOptionList}
                      getOptionLabel={(option) => option.name || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={handleReturnAutocompletePlaceholder({
                            placeholder: "Select Equity, Mutual Fund or Both",
                            value: field.value,
                          })}
                          error={!!errors?.portfolio}
                          helperText={errors?.portfolio?.message as string}
                        />
                      )}
                    />
                  )}
                />
              </Grid2>
            )}

          {notAccessThisSectionReport && (
            <>
              <Controller
                name="currentOption"
                control={form.control}
                render={({ field }) => (
                  <Grid2 container size={12} display={"flex"} spacing={1}>
                    <Grid2 size={12}>
                      <Typography color={theme.palette.dark.main}>
                        Available Time Periods
                      </Typography>
                    </Grid2>
                    <Grid2 size={3}>
                      <GKButton
                        style={{
                          height: "100%",
                        }}
                        fullWidth
                        variant={
                          field.value === "inception" ? "contained" : "outlined"
                        }
                        onClick={() => handleClickPeriods("inception")}
                      >
                        Since Inception
                      </GKButton>
                    </Grid2>
                    <Grid2 size={3}>
                      <GKButton
                        style={{
                          height: "100%",
                        }}
                        fullWidth
                        variant={
                          field.value === "thisFY" ? "contained" : "outlined"
                        }
                        onClick={() => handleClickPeriods("thisFY")}
                      >
                        This FY
                      </GKButton>
                    </Grid2>
                    <Grid2 size={3}>
                      <GKButton
                        style={{
                          height: "100%",
                        }}
                        fullWidth
                        variant={
                          field.value === "lastFY" ? "contained" : "outlined"
                        }
                        onClick={() => handleClickPeriods("lastFY")}
                      >
                        Previous FY
                      </GKButton>
                    </Grid2>
                    <Grid2 size={3}>
                      <GKButton
                        style={{
                          height: "100%",
                        }}
                        variant={
                          field.value === "custom" ? "contained" : "outlined"
                        }
                        fullWidth
                        onClick={() => handleClickPeriods("custom")}
                      >
                        Custom
                      </GKButton>
                    </Grid2>
                  </Grid2>
                )}
              />
              {getValues("currentOption") === "custom" && (
                <Grid2 size={12} mt={1} display={"flex"} gap={2}>
                  <FormControl variant="standard" fullWidth>
                    <Controller
                      name="from_date"
                      control={form.control}
                      render={({ field }) => (
                        <GKDatePicker
                          {...field}
                          inputLabel="Start Date"
                          value={dayjs(field.value)}
                          onChange={(value: any) =>
                            field.onChange(dayjs(value).format("YYYY-MM-DD"))
                          }
                          disableFuture
                          slotProps={{
                            textField: {
                              error: false,
                              helperText: false,
                            },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl variant="standard" fullWidth>
                    <Controller
                      name="to_date"
                      control={form.control}
                      render={({ field }) => (
                        <GKDatePicker
                          {...field}
                          inputLabel="End Date"
                          value={dayjs(field.value)}
                          minDate={
                            dayjs(field.value).isValid()
                              ? dayjs(field.value)
                              : null
                          }
                          onChange={(value: any) =>
                            field.onChange(dayjs(value).format("YYYY-MM-DD"))
                          }
                          disableFuture
                          slotProps={{
                            textField: {
                              error: false,
                              helperText: false,
                            },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid2>
              )}
            </>
          )}
          {(selectedReport?.reportName === "xirr_report" ||
            selectedReport?.reportName === "historical_valuation_report" ||
            selectedReport?.reportName ===
              "unrealized_capital_gain_detailed_report" ||
            (selectedReport?.reportName ===
              "unrealized_capital_gain_summary_report" &&
              selectedReport?.reportName !== "custom_report") ||
            selectedReport?.reportType === "template") && (
            <Grid2 size={12}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="end_date"
                  control={form.control}
                  render={({ field, fieldState: { error } }) => (
                    <GKDatePicker
                      {...field}
                      inputLabel="Date of Generation"
                      value={dayjs(field.value)}
                      onChange={(value) =>
                        field.onChange(dayjs(value).format("YYYY-MM-DD"))
                      }
                      disableFuture
                      slotProps={{
                        textField: {
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid2>
          )}

          {selectedReport?.reportName === "custom_report" &&
            selectedReport?.reportType === "template" &&
            (getValues(
              "selectedReported.customData.availableTimePeriods",
            )?.includes("custom_date_range") ||
              getValues(
                "selectedReported.customData.benchmarkComparisionTimePeriods",
              )?.includes("custom_date_range")) && (
              <>
                <Grid2 container size={6}>
                  <FormControl variant="standard" fullWidth>
                    <Controller
                      name="custom_date.start_date"
                      control={form.control}
                      render={({ field }) => (
                        <GKDatePicker
                          {...field}
                          inputLabel="Start Date"
                          value={dayjs(field.value)}
                          onChange={(value) =>
                            field.onChange(
                              value ? dayjs(value).format("YYYY-MM-DD") : "",
                            )
                          }
                          disableFuture
                          slotProps={{
                            textField: {
                              error: false,
                              helperText: false,
                            },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid2>
                <Grid2 container size={6}>
                  <FormControl variant="standard" fullWidth>
                    <Controller
                      name="custom_date.end_date"
                      control={form.control}
                      render={({ field }) => (
                        <GKDatePicker
                          {...field}
                          inputLabel="End Date"
                          value={dayjs(field.value)}
                          onChange={(value) =>
                            field.onChange(
                              value ? dayjs(value).format("YYYY-MM-DD") : "",
                            )
                          }
                          maxDate={dayjs()}
                          disableFuture
                          slotProps={{
                            textField: {
                              error: false,
                              helperText: false,
                            },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid2>
              </>
            )}

          <Show.When
            isTrue={
              selectedReport?.reportType !== "template" &&
              selectedReport?.reportName === "custom_report"
            }
          >
            <CustomReportForm
              benchmarkList={benchmarkList}
              benchmarkLoading={benchmarkLoading}
              quoteList={quoteList}
              handleDeleteQuote={handleDeleteQuote}
              generateQuote={generateQuote}
              setEditQuoteValue={setEditQuoteValue}
              setOpenQuoteModal={setOpenQuoteModal}
            />
          </Show.When>

          <Show.When
            isTrue={selectedReport?.reportName === "stock_wise_holdings_report"}
          >
            <Grid2 size={12}>
              <Controller
                name={"gcode"}
                control={form.control}
                render={({ field, formState: { errors } }) => (
                  <GKSearchSelect
                    {...field}
                    disableClearable
                    inputLabel="Company"
                    onChange={(event, val) => {
                      field.onChange(val?.value);
                    }}
                    value={
                      companies.find((data: any) => data?.value === field.value)
                        ?.name || []
                    }
                    options={companies || []}
                    getOptionLabel={(option) =>
                      Array.isArray(option) ? "" : option.name || option
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Company",
                          value: field.value,
                        })}
                        error={!!errors?.gcode}
                        helperText={errors?.gcode?.message as string}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={12}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name={"to_date"}
                  control={form.control}
                  render={({ field, formState: { errors } }) => (
                    <GKDatePicker
                      inputLabel="Date"
                      name="to_date"
                      value={dayjs(field.value)}
                      onChange={(value: any) =>
                        field.onChange(dayjs(value).format("YYYY-MM-DD"))
                      }
                      disableFuture
                      slotProps={{
                        textField: {
                          error: !!errors?.to_date,
                          helperText: errors?.to_date?.message as string,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid2>
          </Show.When>

          <Grid2 size={12}>
            <Divider />
          </Grid2>

          <Show.When
            isTrue={
              selectedReport?.reportName !== "custom_report" ||
              selectedReport?.reportType === "template"
            }
          >
            <Grid2 size={12} display={"flex"} gap={1}>
              <LoadingButton
                variant="contained"
                loading={selectedIndex.index === 0 && selectedIndex.loading}
                form="report-form"
                type="submit"
                disabled={
                  selectedIndex.loading ||
                  (selectedReport?.reportName === "custom_report" &&
                    selectedReport?.reportType !== "template")
                }
                onClick={() =>
                  setSelectedIndex((prev: any) => {
                    return {
                      ...prev,
                      index: 0,
                    };
                  })
                }
              >
                {`Generate PDF`}
              </LoadingButton>
              <LoadingButton
                variant="contained"
                type="submit"
                form="report-form"
                onClick={() =>
                  setSelectedIndex((prev: any) => {
                    return {
                      ...prev,
                      index: 1,
                    };
                  })
                }
                disabled={
                  selectedReport?.reportName === "custom_report" ||
                  selectedReport?.reportName ===
                    "portfolio_performance_report" ||
                  selectedReport?.reportName === "stock_wise_holdings_report" ||
                  selectedIndex.loading
                }
                loading={selectedIndex.index === 1 && selectedIndex.loading}
              >
                {`Generate EXCEL`}
              </LoadingButton>
            </Grid2>
          </Show.When>

          <Show.When
            isTrue={
              selectedReport?.reportName === "custom_report" &&
              selectedReport?.reportType !== "template"
            }
          >
            <Grid2 size={12}>
              <GKButton
                variant="contained"
                onClick={async () => {
                  const isValid = await form.trigger(["benchmark"]);

                  if (isValid) setOpenTemplateModal(true);
                }}
              >
                save as template
              </GKButton>
            </Grid2>
          </Show.When>

          <Grid2 size={12}>
            <Divider />
          </Grid2>

          <CustomReportPreview benchmarkList={benchmarkList} />
        </Grid2>
      </Show.When>

      <Show.When isTrue={openPdfView}>
        <ViewPdf
          open={openPdfView}
          setOpen={setOpenPdfView}
          file={selectedReport?.sampleFile}
        />
      </Show.When>

      <Show.When isTrue={openTemplateModal}>
        <TemplateModal
          open={openTemplateModal}
          setOpen={setOpenTemplateModal}
        />
      </Show.When>
    </>
  );
};

export default GenerateReportForm;
