import { GKButton, Grid2, Typography } from "components";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import SelectClientModal from "./SelectClientModal";

interface Props {
  handleNext?: any;
}

const ClientTypeSelection = (props: Props) => {
  const { handleNext } = props;

  const form = useFormContext();

  const [openSelectModal, setOpenSelectModal] = useState(false);

  const cardsData = [
    {
      name: "Add new client",
      img: "/Images/createClient.svg",
      disabled: false,
      onClick: () => {
        handleNext();
      },
    },
    {
      name: "Select from Existing",
      img: "/Images/selectClient.svg",
      disabled: false,
      onClick: () => {
        setOpenSelectModal(true);
      },
    },
    {
      name: "Client creation using KRA",
      img: "/Images/kra.svg",
      disabled: true,
      onClick: () => {
        setOpenSelectModal(true);
      },
    },
    {
      name: "Convert lead to client",
      img: "/Images/convertClient.svg",
      disabled: true,
      onClick: () => {
        setOpenSelectModal(true);
      },
    },
  ];

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2
          size={12}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography fontSize={24} fontWeight={600}>
            Choose how you want to create a client{" "}
          </Typography>
        </Grid2>
        {cardsData?.map((dataItem) => {
          return (
            <Grid2 size={6} key={dataItem?.name}>
              <GKButton
                onClick={dataItem?.onClick}
                disabled={dataItem?.disabled}
                variant="outlined"
                color="dark"
                fullWidth
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 8,
                  padding: 16,
                  borderRadius: 12,
                }}
                startIcon={
                  <img loading="lazy" alt="" src={dataItem?.img} height={40} />
                }
              >
                <Typography
                  fontWeight={600}
                  fontSize={14}
                  color={dataItem?.disabled ? "" : "#000"}
                >
                  {dataItem?.name}
                </Typography>
              </GKButton>
            </Grid2>
          );
        })}
      </Grid2>
      <SelectClientModal
        open={openSelectModal}
        setOpen={setOpenSelectModal}
        handleNext={handleNext}
        form={form}
      />
    </>
  );
};

export default ClientTypeSelection;
