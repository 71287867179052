import { GridColDef } from "@mui/x-data-grid-pro";
import { CommonPagination, GKDataGrid, Grid2, Typography } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { PORTFOLIO_FEE_PLAN_MAPPING } from "utils/apiRoutes";
import { extraColumnsField, matchDate } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import useDocumentTitle from "utils/useDocumentTitle";

const RevenuePortfolioTab = () => {
  useDocumentTitle("Revenue Manager");

  const requestBody = useGenerateQueryParams("portfolioMapping");

  const {
    data: portfolioFeePlanMappingList,
    isFetching: portfolioMappingFetching,
  } = useFetchQuery({
    key: ["PORTFOLIO_FEE_PLAN_MAPPING_LIST", requestBody],
    route: PORTFOLIO_FEE_PLAN_MAPPING,
    requestBody,
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        name="portfolioMapping"
        totalCount={portfolioFeePlanMappingList?.data?.totalCount || 0}
      />
    );
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Portfolio Name", "clientName", 250, 1),
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography
              fontWeight={700}
              maxWidth={"100%"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
            >
              {params?.row?.clientName}
            </Typography>
            <Typography fontSize={12}>
              {params?.row?.portfolioName} - {params?.row?.portfolioId}
            </Typography>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Fee Plan", "feePlan", 200, 1),
      renderCell: (param) => {
        return <Typography>{param?.row?.feePlan?.name || "-"}</Typography>;
      },
    },
    {
      ...extraColumnsField("Start Date", "startDate", 100, 1),
      type: "date",
      valueFormatter: (value) => matchDate(value?.value),
    },
    {
      ...extraColumnsField("Total Fee Collected", "totalFeeCollected", 150, 1),
      valueGetter: (row) => row?.row?.totalFeeCollected,
      valueFormatter: (params) => moneyCommaSeparator(params.value || 0),
      type: "number",
    },
    {
      ...extraColumnsField("Total Billed Generated", "feeGenerated", 150, 1),
      valueGetter: (row) => row?.row?.feeGenerated,
      valueFormatter: (params) => moneyCommaSeparator(params.value || 0),
      type: "number",
    },
    {
      ...extraColumnsField("Partner", "partner", 150, 1),
    },
  ];

  return (
    <GKDataGrid
      loading={portfolioMappingFetching}
      columns={columns}
      rows={portfolioFeePlanMappingList?.data?.data || []}
      maxHeight={"calc(100vh - 312px)"}
      CustomPagination={!portfolioMappingFetching && cusPagination}
      name="portfolioMapping"
    />
  );
};

export default RevenuePortfolioTab;
