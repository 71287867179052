import { GKClientDataGrid, StatusField, Switch } from "components";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useParams } from "react-router-dom";
import { ALL_MAPPED_PORTFOLIO_BY_ORDER_FORMAT_BY } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";

const SheetPortfolioMapping = () => {
  const { id } = useParams();

  const {
    data: allPortfolioData,
    isFetching: portfoliosLoading,
    refetch: portfoliosRefetch,
  } = useFetchQuery({
    key: ["ORDER_MANAGER_MAPPING_PORTFOLIO", id],
    route: ALL_MAPPED_PORTFOLIO_BY_ORDER_FORMAT_BY,
    requestBody: {
      order_format: id,
    },
  });
  return (
    <DashboardLayout title="Portfolio to Sheet Mapping">
      <GKClientDataGrid
        name="sheetPortfolioMappings"
        loading={portfoliosLoading}
        columns={[
          {
            ...extraColumnsField("name", "name", 0, 1),
          },
          {
            ...extraColumnsField("status", "status", 0, 1),
            renderCell: (params) => {
              return <StatusField statusString={params.row.status} />;
            },
          },
          {
            ...extraColumnsField("action", "action", 0, 1),
            align: "right",
            headerAlign: "right",
            renderCell: (params) => {
              return (
                <Switch
                  checked={params?.row?.status === "MAPPED"}
                  onChange={() => {
                    if (params?.row?.status === "UNMAPPED") {
                      axiosInstance
                        .post(`/order-manager-mappings/${id}/map_portfolio/`, {
                          portfolio: params?.row?.id,
                        })
                        .then(() => {
                          portfoliosRefetch();
                        });
                    } else {
                      axiosInstance
                        .delete(
                          `/order-manager-mappings/${id}/map_portfolio/?portfolio=${params?.row?.id}`,
                        )
                        .then(() => {
                          portfoliosRefetch();
                        });
                    }
                  }}
                />
              );
            },
          },
        ]}
        rows={allPortfolioData?.data || []}
      />
    </DashboardLayout>
  );
};

export default SheetPortfolioMapping;
