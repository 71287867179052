import { BsEyeFill, Grid2, IconButton, Typography, styled } from "components";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { AdviceRegisterColor } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";

interface Props {
  dataItem: any;
  index: number;
}

const CustomContainer = styled(Grid2)(() => () => ({
  padding: "16px",
  borderRadius: 10,
  border: "1px solid #E0E0E0",
  marginBottom: 4,
  "&:hover": {
    cursor: "pointer",
  },
}));

const AgreementAdviceRegister = (props: Props) => {
  const navigate = useNavigate();
  const { theme } = ThemeContainer();

  const { dataItem, index } = props;

  return (
    <CustomContainer
      key={index}
      position={"relative"}
      size={12}
      onClick={() => {
        navigate(`/app/clients/${dataItem?.clientId}/agreement`);
      }}
    >
      <Grid2 container>
        <Grid2 size={6} display={"flex"}>
          <Typography
            fontWeight={600}
            fontSize={12}
            p={"4px"}
            textTransform={"capitalize"}
            borderRadius={1}
            color={AdviceRegisterColor[dataItem?.type]}
            bgcolor={`${AdviceRegisterColor[dataItem?.type]}22`}
          >
            {dataItem?.type}
          </Typography>
        </Grid2>
        <Grid2
          size={6}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
        >
          <IconButton
            style={{
              borderRadius: 4,
            }}
            onClick={(e) => {
              e.stopPropagation();
              window.open(dataItem?.data?.document, "_blank");
            }}
          >
            <BsEyeFill size={14} />
          </IconButton>
        </Grid2>
        <Grid2
          size={10}
          display={"flex"}
          alignItems={"center"}
          gap={2}
          justifyContent={"flex-start"}
        >
          <Typography
            fontWeight={500}
            fontSize={12}
            display={"flex"}
            alignItems="center"
          >
            Signature Type :&nbsp;
            <Typography fontWeight={700} fontSize={12}>
              {dataItem?.data?.signatureType}
            </Typography>
          </Typography>
          <Typography
            fontWeight={500}
            fontSize={12}
            display={"flex"}
            alignItems="center"
          >
            Agreement Status :&nbsp;
            <Typography fontWeight={700} fontSize={12}>
              {dataItem?.data?.agreementStatus}
            </Typography>
          </Typography>
        </Grid2>
        <Grid2
          size={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Typography
            fontWeight={400}
            fontSize={12}
            color={theme.palette.secondaryText.main}
          >
            {dayjs(dataItem?.data?.createdAt).format("DD MMM YYYY  hh:mm a") ||
              "-"}
          </Typography>
        </Grid2>
      </Grid2>
    </CustomContainer>
  );
};

export default AgreementAdviceRegister;
