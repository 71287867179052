import {
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  GKButton,
  GKEditor,
  GKTextField,
  Grid2,
  Switch,
  Typography,
} from "components";
import GKCheckbox from "components/GKCheckbox";
import useRevenueManager from "hooks/useRevenueManager";
import DashboardLayout from "layouts/DashboardLayout";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { revenuePlanType } from "utils/constants";
import useDocumentTitle from "utils/useDocumentTitle";
import { validateNotWhitespaceOnly } from "utils/validationSchema";
import AdvanceDeposit from "./AdvanceDeposit";
import ManagementFee from "./ManagementFee";
import PerformanceFee from "./PerformanceFee";
import SubscriptionPlan from "./SubscriptionPlan";

type SubscriptionPlanType = {
  frequency?: string;
  amount?: string;
  discountAmount?: number;
  taxAmount?: string;
  withoutTaxAmount?: string;
};

type FormValues = {
  name?: string;
  description?: string;
  status?: boolean;
  revenueProducts?: any[];
  managementFee?: {
    feeType?: string;
    feeMethod?: string;
    calculationBenchmark?: string;
    calculationFrequency?: string;
    fee?: string;
    billFrequency?: string;
    outOfPocketExpense?: string;
    minimumFee?: number;
    maximumFee?: number;
    highwatermark?: boolean;
    managementFeeSlab?: any[];
    calculationMethod?: string;
    // highwatermarkAmount?: number;
    softHurdle?: number;
    hardHurdle?: number;
    outOfPocketExpenses?: number;
    productName?: string;
    hsnCode?: string;
  };
  performanceFee?: {
    feeType?: string;
    feeMethod?: string;
    calculationBenchmark?: string;
    calculationFrequency?: string;
    fee?: string;
    billFrequency?: string;
    outOfPocketExpense?: string;
    minimumFee?: number;
    maximumFee?: number;
    highwatermark?: boolean;
    performanceFeeSlab?: any[];
    calculationMethod?: string;
    // highwatermarkAmount?: number;
    softHurdle?: boolean;
    hardHurdle?: boolean;
    outOfPocketExpenses?: number;
    minesManagementFee?: boolean;
    productName?: string;
    hsnCode?: string;
    hurdle?: number;
  };
  brokerage?: {
    brokerage?: number;
    transaction?: string;
  };
  exitLoad?: {
    exitCharge?: number;
    minimumInvestmentTime?: string;
  };
  subscriptionFee?: {
    isFree: boolean;
    productName?: string;
    hsnCode?: string;
    subscriptionPlan: SubscriptionPlanType[];
  };
  advanceDeposit?: {
    productName: string;
    hsnCode: string;
    fee: string;
  };
};

const CreateFeePlans = () => {
  useDocumentTitle("Create Fee Plan");

  const { createFeePlan } = useRevenueManager();

  const form = useForm<FormValues>({
    defaultValues: {
      name: "",
      description: "",
      status: true,
      revenueProducts: [],
      managementFee: {
        feeType: "",
        feeMethod: "simple",
        calculationBenchmark: "",
        fee: "",
        calculationFrequency: "",
        billFrequency: "",
        highwatermark: false,
        minimumFee: 0,
        maximumFee: 0,
        outOfPocketExpenses: 0,
        // managementFeeSlab: [{ lowerLimit: "", upperLimit: "", slabFee: "" }],
        // highwatermarkAmount: 0,
        calculationMethod: "",
        softHurdle: 0,
        hardHurdle: 0,
        productName: "",
        hsnCode: "",
      },
      performanceFee: {
        feeType: "aum_based",
        feeMethod: "simple",
        calculationBenchmark: "",
        fee: "",
        calculationFrequency: "",
        billFrequency: "",
        highwatermark: true,
        minimumFee: 0,
        maximumFee: 0,
        outOfPocketExpenses: 0,
        // performanceFeeSlab: [{ lowerLimit: "", upperLimit: "", slabFee: "" }],
        // highwatermarkAmount: 0,
        calculationMethod: "trailing",
        softHurdle: false,
        hardHurdle: true,
        minesManagementFee: false,
        productName: "",
        hsnCode: "",
        hurdle: 0,
      },
      brokerage: {
        brokerage: 0,
        transaction: "",
      },
      exitLoad: {
        exitCharge: 0,
        minimumInvestmentTime: "",
      },
      subscriptionFee: {
        isFree: false,
        productName: "",
        hsnCode: "",
        subscriptionPlan: [
          {
            frequency: "",
            amount: "",
            discountAmount: 0,
            taxAmount: "",
            withoutTaxAmount: "",
          },
        ],
      },
      advanceDeposit: {
        productName: "Advance Fee",
        hsnCode: "998311",
        fee: "",
      },
    },
  });

  const onSubmit = (data: any) => {
    const newValue: any = {
      name: data.name,
      revenueProducts: data.revenueProducts,
      status: data.status,
      description: data?.description,
    };

    if (data.revenueProducts.includes("management_fee")) {
      newValue.managementFee = data.managementFee;
    }
    if (data.revenueProducts.includes("performance_fee")) {
      newValue.performanceFee = data.performanceFee;
    }
    if (data.revenueProducts.includes("brokerage")) {
      newValue.brokerage = data.brokerage;
    }
    if (data.revenueProducts.includes("exit_load")) {
      newValue.exitLoad = data.exitLoad;
    }
    if (data.revenueProducts.includes("subscription_fee")) {
      newValue.subscriptionFee = data.subscriptionFee;
    }
    if (data.revenueProducts.includes("advance_deposit")) {
      newValue.advanceDeposit = data.advanceDeposit;
    }

    createFeePlan(newValue);
  };

  return (
    <DashboardLayout title="Create Fee Plan">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Grid2 container spacing={1}>
            <Grid2
              size={12}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              my={1}
            >
              <Typography fontSize={18} fontWeight={600}>
                Set Up Fee Plan
              </Typography>
            </Grid2>
            <Grid2 size={6}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="name"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter name",
                    },
                    validate: validateNotWhitespaceOnly,
                  }}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      requiredField
                      inputLabel="Fee Plan Name"
                      name="name"
                      error={Boolean(form?.formState?.errors?.name)}
                      helperText={form?.formState?.errors?.name?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={6}>
              <Controller
                name="status"
                control={form.control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ marginTop: "32px" }}
                    control={
                      <Switch
                        {...field}
                        checked={field.value}
                        color="primary"
                      />
                    }
                    label="Active"
                    labelPlacement="end"
                  />
                )}
              />
            </Grid2>
            <Grid2 size={12}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="description"
                  control={form.control}
                  render={({ field }) => (
                    <GKEditor
                      value={field.value}
                      onChange={field.onChange}
                      inputLabel="Description"
                    />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={12}>
              <FormGroup row>
                {revenuePlanType.map((option) => (
                  <Controller
                    key={option.value}
                    name="revenueProducts"
                    control={form.control}
                    render={({ field: { onChange, value } }) => {
                      const handleCheckboxChange = (
                        e: React.ChangeEvent<HTMLInputElement>,
                        optionValue: string,
                      ) => {
                        const isChecked = e.target.checked;

                        if (
                          ["subscription_fee", "advance_deposit"].includes(
                            optionValue,
                          )
                        ) {
                          onChange(isChecked ? [optionValue] : []);
                        } else {
                          const updatedValue = isChecked
                            ? [
                                ...value.filter(
                                  (item) =>
                                    ![
                                      "subscription_fee",
                                      "advance_deposit",
                                    ].includes(item),
                                ),
                                optionValue,
                              ]
                            : value.filter((item) => item !== optionValue);

                          onChange(updatedValue);
                        }
                      };
                      return (
                        <FormControl component="fieldset">
                          <FormControlLabel
                            key={option.value}
                            control={
                              <GKCheckbox
                                name="revenueProducts"
                                checked={value.includes(option.value)}
                                onChange={(e) =>
                                  handleCheckboxChange(e, option.value)
                                }
                                // onChange={(e) =>
                                //   onChange(
                                //     e.target.checked
                                //       ? [...value, option.value]
                                //       : value.filter(
                                //           (dataItem: string) =>
                                //             dataItem !== option.value
                                //         )
                                //   )
                                // }
                              />
                            }
                            label={option.name}
                          />
                        </FormControl>
                      );
                    }}
                  />
                ))}
              </FormGroup>
            </Grid2>

            {form.watch("revenueProducts").includes("management_fee") && (
              <>
                <Grid2 size={12}>
                  <Divider />
                </Grid2>
                <ManagementFee />
              </>
            )}
            {form.watch("revenueProducts").includes("performance_fee") && (
              <>
                <Grid2 size={12}>
                  <Divider />
                </Grid2>
                <PerformanceFee />
              </>
            )}
            {form.watch("revenueProducts").includes("subscription_fee") && (
              <>
                <Grid2 size={12}>
                  <Divider />
                </Grid2>
                <SubscriptionPlan />
              </>
            )}
            {form.watch("revenueProducts").includes("advance_deposit") && (
              <>
                <Grid2 size={12}>
                  <Divider />
                </Grid2>
                <AdvanceDeposit />
              </>
            )}
            {/* {form.watch("revenueProducts").includes("brokerage") && (
              <>
                <Grid2 size={12}>
                  <Divider />
                </Grid2>
                <Brokerage />
              </>
            )}
            {form.watch("revenueProducts").includes("exit_Loan") && (
              <>
                <Grid2 size={12}>
                  <Divider />
                </Grid2>
                <ExitLoad />
              </>
            )} */}
            {form.getValues("revenueProducts").length !== 0 && (
              <Grid2
                size={12}
                display={"flex"}
                justifyContent={"flex-end"}
                mt={2}
              >
                <GKButton variant="contained" type="submit" size="medium">
                  Submit
                </GKButton>
              </Grid2>
            )}
          </Grid2>
        </form>
      </FormProvider>
    </DashboardLayout>
  );
};

export default CreateFeePlans;
