import {
  CgMathPlus,
  FiTrash2,
  FormControl,
  FormControlLabel,
  GKButton,
  GKSearchSelect,
  GKTextField,
  Grid2,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "components";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import { GoInfo } from "react-icons/go";
import { SUBSCRIPTION_FREQUENCY } from "utils/constants";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";

const SubscriptionPlan = () => {
  const form = useFormContext();

  const { theme } = ThemeContainer();

  const watchSubscriptionIsFree = form.watch("subscriptionFee.isFree");

  const { fields, remove, append } = useFieldArray({
    control: form.control,
    name: "subscriptionFee.subscriptionPlan",
  });

  const percentageOff = (price: number, index: number) => {
    const tax = price / 1.18;
    const withoutTax = price - tax;

    form.setValue(
      `subscriptionFee.subscriptionPlan.${index}.taxAmount`,
      +withoutTax.toFixed(2),
    );
    form.setValue(
      `subscriptionFee.subscriptionPlan.${index}.withoutTaxAmount`,
      +tax.toFixed(2),
    );
  };

  const option = (index: number) => {
    form.watch(`subscriptionFee.subscriptionPlan.${index}.frequency`);

    return SUBSCRIPTION_FREQUENCY?.filter(
      (data) =>
        !form
          .getValues(`subscriptionFee.subscriptionPlan`)
          ?.map((dataItem: { frequency: string }) => dataItem.frequency)
          .includes(data.value),
    );
  };

  return (
    <Grid2 container rowSpacing={2} spacing={1}>
      <Grid2
        size={12}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography fontSize={18} fontWeight={500}>
          Subscription Fee
        </Typography>
        <Controller
          name="subscriptionFee.isFree"
          control={form.control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    form.clearErrors("subscriptionFee.subscriptionPlan");
                  }}
                />
              }
              label={"Free Plan"}
            />
          )}
        />
      </Grid2>

      <Grid2 size={6}>
        <FormControl variant="standard" fullWidth>
          <Controller
            name="subscriptionFee.productName"
            control={form.control}
            render={({ field }) => (
              <GKTextField
                {...field}
                placeholder="Product Name"
                inputLabel={
                  <Typography fontSize={18}>
                    Product Name
                    <Tooltip
                      arrow
                      title="This name will appear on the invoices generated for your clients."
                    >
                      <IconButton color="primary">
                        <GoInfo size={17} />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                }
              />
            )}
          />
        </FormControl>
      </Grid2>

      <Grid2 size={6}>
        <FormControl variant="standard" fullWidth>
          <Controller
            name="subscriptionFee.hsnCode"
            control={form.control}
            rules={{
              required: {
                value: !watchSubscriptionIsFree,
                message: "Please select frequency",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <GKTextField
                {...field}
                requiredField
                placeholder="HSN Code"
                inputLabel={
                  <Typography fontSize={18}>
                    HSN Code{" "}
                    <span
                      style={{
                        color: theme.palette.error.main,
                      }}
                    >
                      *
                    </span>
                    <Tooltip
                      arrow
                      title="This code will appear on invoices to classify your product for tax compliance."
                    >
                      <IconButton color="primary">
                        <GoInfo size={17} />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                }
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FormControl>
      </Grid2>

      {fields?.map((row, index) => {
        return (
          <Grid2 container size={12} rowSpacing={2} spacing={1} key={row.id}>
            <Grid2 size={3}>
              <Controller
                name={`subscriptionFee.subscriptionPlan.${index}.frequency`}
                control={form.control}
                rules={{
                  required: {
                    value: !watchSubscriptionIsFree,
                    message: "Please select frequency",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKSearchSelect
                    {...field}
                    requiredField
                    disabled={watchSubscriptionIsFree}
                    inputLabel="Frequency"
                    disableClearable={false}
                    options={option(index) || []}
                    onChange={(e, val) => {
                      field.onChange(val?.value);
                    }}
                    value={
                      SUBSCRIPTION_FREQUENCY.find(
                        (dataItem) => dataItem.value === field.value,
                      ) || ""
                    }
                    getOptionLabel={(option) => option.name || option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="managementFee.feeType"
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Frequency",
                          value: field.value,
                        })}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
            <Grid2 size={2}>
              <Controller
                name={`subscriptionFee.subscriptionPlan.${index}.withoutTaxAmount`}
                control={form.control}
                render={({ field }) => (
                  <FormControl variant="standard" fullWidth>
                    <GKTextField
                      {...field}
                      disabled={watchSubscriptionIsFree}
                      requiredField
                      inputLabel="Taxable Amount"
                      type="number"
                      slotProps={{ htmlInput: { readOnly: true } }}
                    />
                  </FormControl>
                )}
              />
            </Grid2>
            <Grid2 size={2}>
              <Controller
                name={`subscriptionFee.subscriptionPlan.${index}.taxAmount`}
                control={form.control}
                render={({ field }) => (
                  <FormControl variant="standard" fullWidth>
                    <GKTextField
                      {...field}
                      disabled={watchSubscriptionIsFree}
                      requiredField
                      inputLabel="Tax Amount"
                      type="number"
                      slotProps={{ htmlInput: { readOnly: true } }}
                    />
                  </FormControl>
                )}
              />
            </Grid2>
            <Grid2 size={2}>
              <Controller
                name={`subscriptionFee.subscriptionPlan.${index}.amount`}
                control={form.control}
                rules={{
                  required: {
                    value: !watchSubscriptionIsFree,
                    message: "Please enter total amount",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl variant="standard" fullWidth>
                    <GKTextField
                      {...field}
                      disabled={watchSubscriptionIsFree}
                      requiredField
                      inputLabel="Total Amount"
                      type="number"
                      onChange={(
                        event: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >,
                      ) => {
                        if (event.target instanceof HTMLInputElement) {
                          const { valueAsNumber } = event.target;
                          field.onChange(valueAsNumber);

                          if (
                            form.getValues(
                              `subscriptionFee.subscriptionPlan.${index}.discountAmount`,
                            ) > 0
                          ) {
                            percentageOff(
                              form.getValues(
                                `subscriptionFee.subscriptionPlan.${index}.discountAmount`,
                              ),
                              index,
                            );
                          } else {
                            percentageOff(valueAsNumber, index);
                          }
                        } else {
                          toast.error(
                            "Not an input element that supports valueAsNumber",
                          );
                        }
                      }}
                      error={!!error}
                      helperText={error?.message}
                    />
                  </FormControl>
                )}
              />
            </Grid2>
            <Grid2 size={2}>
              <Controller
                name={`subscriptionFee.subscriptionPlan.${index}.discountAmount`}
                control={form.control}
                rules={{
                  validate: (value) => {
                    const watchDiscountAmount = form.watch(
                      `subscriptionFee.subscriptionPlan.${index}.discountAmount`,
                    );

                    const totalAmount = form.getValues(
                      `subscriptionFee.subscriptionPlan.${index}.amount`,
                    );

                    if (watchDiscountAmount && value > totalAmount) {
                      return "Discount should be less than the total amount";
                    }
                    return true;
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl variant="standard" fullWidth>
                    <GKTextField
                      {...field}
                      disabled={watchSubscriptionIsFree}
                      inputLabel="Discount Amount"
                      type="number"
                      onChange={(
                        event: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >,
                      ) => {
                        if (event.target instanceof HTMLInputElement) {
                          const { valueAsNumber } = event.target;

                          if (valueAsNumber > 0) {
                            percentageOff(valueAsNumber, index);
                          } else {
                            percentageOff(
                              form.getValues(
                                `subscriptionFee.subscriptionPlan.${index}.amount`,
                              ),
                              index,
                            );
                          }

                          field.onChange(valueAsNumber);
                        } else {
                          toast.error(
                            "Not an input element that supports valueAsNumber",
                          );
                        }
                      }}
                      error={!!error}
                      helperText={error?.message}
                    />
                  </FormControl>
                )}
              />
            </Grid2>
            <Grid2
              size={1}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              mt={"16px"}
            >
              <IconButton
                onClick={() => remove(index)}
                color="error"
                disabled={fields?.length === 1}
              >
                <FiTrash2 />
              </IconButton>
            </Grid2>
          </Grid2>
        );
      })}

      {!watchSubscriptionIsFree && (
        <Grid2 size={12}>
          <GKButton
            variant="outlined"
            size="small"
            color="dark"
            startIcon={<CgMathPlus size={18} />}
            onClick={() =>
              append({
                frequency: "",
                amount: "",
                discountAmount: 0,
                taxAmount: "",
                withoutTaxAmount: "",
              })
            }
          >
            Add Row
          </GKButton>
        </Grid2>
      )}
    </Grid2>
  );
};

export default SubscriptionPlan;
