import { useAppContext } from "AppContext";
import { CommonFilterState } from "interfaces/CommonFilter";

const includeZeroHoldingKey = [
  "portfolio",
  "client",
  "clientFamily",
  "family",
  "portfolioHoldingsTable",
  "holdingSummary",
  "clientHolding",
  "equityBasketOrderManagerPortfolio",
  "familyClient",
  "familyHolding",
  "holdingDetails",
  "insights",
  "anchorTransaction",
  "portfolioGroupHolding",
  "portfolioGroup",
  "avlCashBalance",
];

type ExtraKeyInterface = {
  [key: string]: string | boolean | string[] | number;
};

const useGenerateQueryParams = (
  filterKey: string,
  extraKey?: ExtraKeyInterface,
) => {
  const {
    state: { commonFilter },
  } = useAppContext();

  const filter = commonFilter[filterKey as keyof CommonFilterState];

  let requestBody: {
    skip?: number;
    take?: number;
    sort?: string;
    search?: string;
    filter?: string;
    show_zero_holding?: boolean;
    extraKey?: ExtraKeyInterface;
  } = {
    skip: filter?.skip,
    take: filter?.take,
    sort: JSON.stringify(
      filter?.sortModel?.map((data: { field: string; sort: string }) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      }),
    ),
    search: filter?.search || "",
    filter: JSON.stringify(filter?.customFilter),
  };

  if (includeZeroHoldingKey.includes(filterKey)) {
    requestBody = {
      ...requestBody,
      show_zero_holding:
        !!extraKey?.defaultShowZeroHolding || filter?.showNonZero,
    };
  }

  if (extraKey) {
    if (extraKey?.defaultShowZeroHolding)
      delete extraKey?.defaultShowZeroHolding;

    requestBody = {
      ...requestBody,
      ...extraKey,
    };
  }

  return requestBody;
};

export default useGenerateQueryParams;
