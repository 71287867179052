import { useFetchQuery } from "hooks/useQueries";
import { useParams } from "react-router-dom";
import { CLIENT_ID_BY_FAMILY } from "utils/apiRoutes";
import { QueryKeys } from "utils/queryKey";
import useGenerateQueryParams from "./useGenerateQueryParams";

export const useClientFamily = () => {
  const { id } = useParams();

  const requestBody = useGenerateQueryParams("clientFamily");

  const { data: familyData, isLoading: isInitialLoading } = useFetchQuery({
    key: [QueryKeys.FAMILY_LIST, requestBody],
    route: CLIENT_ID_BY_FAMILY(id),
    requestBody,
  });

  return {
    familyData: familyData?.data,
    isInitialLoading,
  };
};
