import {
  Card,
  CgMathPlus,
  CiSettings,
  GKButton,
  Grid2,
  NoData,
  SimpleLoader,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { PortfolioDetailsInterface } from "interfaces/Portfolio";
import { useState } from "react";
import { CLIENT_NOMINEE_BY_ID } from "utils/apiRoutes";
import { ThemeContainer } from "utils/Theme";
import NomineeModal from "./NomineeModal";

interface Props {
  portfolioDetailData: PortfolioDetailsInterface;
}

interface NomineeInterface {
  name: string;
  percentage: number;
  relationship: string;
  client: number;
  id: number;
}

const NomineeDetails = (props: Props) => {
  const { portfolioDetailData } = props;

  const { theme } = ThemeContainer();

  const [nomineeData, setOpenNomineeData] = useState([]);
  const [openNomineeModal, setOpenNomineeModal] = useState(false);

  const { data, isFetching } = useFetchQuery({
    key: ["CLIENT_NOMINEE_ACCOUNT"],
    route: CLIENT_NOMINEE_BY_ID(portfolioDetailData?.client),
  });

  if (isFetching) return <SimpleLoader />;

  return (
    <Grid2 container spacing={2}>
      {data?.data?.nominees?.map((dataItem: NomineeInterface) => {
        return (
          <Grid2 size={6}>
            <Card sx={{ p: 2 }}>
              <Grid2 container spacing={2}>
                <Grid2 size={12} display={"flex"} alignItems={"center"} gap={3}>
                  <Typography color={theme.palette.secondaryText.main}>
                    Nominee Name
                  </Typography>
                  <Typography color={"#121926"}>
                    {dataItem?.name || "-"}
                  </Typography>
                </Grid2>
                <Grid2 size={12} display={"flex"} alignItems={"center"} gap={3}>
                  <Typography color={theme.palette.secondaryText.main}>
                    Nominee Percentage
                  </Typography>
                  <Typography color={"#121926"}>
                    {dataItem?.percentage || "-"}
                  </Typography>
                </Grid2>
                <Grid2 size={12} display={"flex"} alignItems={"center"} gap={3}>
                  <Typography color={theme.palette.secondaryText.main}>
                    Relationship
                  </Typography>
                  <Typography color={"#121926"}>
                    {dataItem?.relationship || "-"}
                  </Typography>
                </Grid2>
              </Grid2>
            </Card>
          </Grid2>
        );
      })}

      <Grid2 size={12}>
        {data?.data?.nominees?.length === 0 ? (
          <NoData
            text="No Nominee Added"
            additionalComponent={
              <GKButton
                variant="contained"
                startIcon={<CgMathPlus size={16} />}
                onClick={() => {
                  setOpenNomineeData([]);
                  setOpenNomineeModal(true);
                }}
              >
                Add Nominee
              </GKButton>
            }
          />
        ) : (
          <GKButton
            variant="outlined"
            color="dark"
            startIcon={<CiSettings size={16} />}
            onClick={() => {
              setOpenNomineeData(data?.data?.nominees);
              setOpenNomineeModal(true);
            }}
          >
            Edit Nominee
          </GKButton>
        )}
      </Grid2>

      {openNomineeModal && (
        <NomineeModal
          open={openNomineeModal}
          setOpen={setOpenNomineeModal}
          nomineeData={nomineeData}
          clientId={portfolioDetailData?.client}
        />
      )}
    </Grid2>
  );
};

export default NomineeDetails;
