import { useEffect, useState } from "react";
import NoInternetConnection from "./NoInternetConnection";

const NoInternetWrapper = ({ children }: any) => {
  const [isOnline, setOnline] = useState(true);

  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  return isOnline ? children : <NoInternetConnection />;
};

export default NoInternetWrapper;
