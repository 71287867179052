import { useMutation } from "@tanstack/react-query";
import {
  CgMathPlus,
  ConfirmDeleteModal,
  GKButton,
  GKClientDataGrid,
  Grid2,
} from "components";
import { useMeeting } from "hooks/useMeeting";
import { useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { LeadMeetingColumns } from "utils/columns";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import AddNewMeeting from "./AddMeeting";

const LeadMeeting = ({ lead_id }: any) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isScheduleMeeting, setIsScheduleMeeting] = useState(false);

  const [itemId, setItemId] = useState<any>(0);

  const [meetingModal, setMeetingModal] = useState(false);

  const [meetingData, setMeetingData] = useState({});

  const { getAllLeadMeeting, isLeadMeetingLoading, refetch } = useMeeting();

  const { mutate: deleteLead } = useMutation({
    mutationFn: (id) =>
      axiosInstance.delete(`/lead/${lead_id}/meetings/${id}/`),
    onSuccess: (response) => {
      refetchQuery(QueryKeys.MEETING_LIST);
      refetchQuery(QueryKeys.GET_LEAD);
      refetch();
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: getMeeting } = useMutation({
    mutationFn: (id) => axiosInstance.get(`/meeting/${id}/`),
    onSuccess: (data: any) => {
      setMeetingData(data?.data?.data);
      setMeetingModal(true);
    },
  });

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={12}>
        <GKClientDataGrid
          name="leadMeetingList"
          rows={getAllLeadMeeting?.data?.data || []}
          columns={LeadMeetingColumns(setIsDeleteModalOpen, setItemId)}
          loading={isLeadMeetingLoading}
          onRowClick={(props) => {
            getMeeting(props.row.id);
          }}
          exportFile={{
            path: `/lead/${lead_id}/meetings/`,
            exportName: "LeadMeetingList",
          }}
          headerComponent={
            <Grid2 container justifyContent={"flex-end"} gap={1}>
              <GKButton
                className="lead-meeting"
                variant="outlined"
                color="dark"
                startIcon={<CgMathPlus size={16} />}
                onClick={() => {
                  setItemId(lead_id);
                  setMeetingData({});
                  setMeetingModal(true);
                  setIsScheduleMeeting(false);
                }}
              >
                Add Meeting
              </GKButton>
            </Grid2>
          }
        />
      </Grid2>

      {isDeleteModalOpen && (
        <ConfirmDeleteModal
          open={isDeleteModalOpen}
          setOpen={setIsDeleteModalOpen}
          title="Meeting"
          onDelete={() => deleteLead(itemId)}
          isDelete
        />
      )}

      {meetingModal && (
        <AddNewMeeting
          open={meetingModal}
          setOpen={setMeetingModal}
          leadId={itemId}
          meetingData={meetingData}
          refetch={refetch}
          schedule={isScheduleMeeting}
        />
      )}
    </Grid2>
  );
};

export default LeadMeeting;
