export interface FilterValue {
  skip?: number;
  page?: number;
  take?: number;
  sortModel?: any[];
  filterColumn?: any[];
  search?: string;
  showNonZero?: boolean;
  customFilter?: any;
}

export const defaultFilterValue: FilterValue = {
  skip: 0,
  page: 0,
  take: 25,
  sortModel: [],
  filterColumn: [],
  search: "",
  showNonZero: false,
  customFilter: {},
};

export interface CommonFilterState {
  portfolio: FilterValue;
  portfolioGroup: FilterValue;
  importManager: FilterValue;
  bankStatement: FilterValue;
  client: FilterValue;
  report: FilterValue;
  tradeBook: FilterValue;
  family: FilterValue;
  compliance: FilterValue;
  lead: FilterValue;
  branch: FilterValue;
  employee: FilterValue;
  clientAccess: FilterValue;
  clientFamily: FilterValue;
  familyClient: FilterValue;
  portfolioAccess: FilterValue;
  branchPortfolio: FilterValue;
  branchClient: FilterValue;
  employeePortfolioAccess: FilterValue;
  familyAccess: FilterValue;
  employeeAccess: FilterValue;
  employeeFamilyAccess: FilterValue;
  employeeClientAccess: FilterValue;
  familyPortfolio: FilterValue;
  holdingSummary: FilterValue;
  avlCashBalance: FilterValue;
  portfolioHolding: FilterValue;
  portfolioHoldingTransaction: FilterValue;
  portfolioGroupHolding: FilterValue;
  clientHolding: FilterValue;
  familyHolding: FilterValue;
  branchHolding: FilterValue;
  employeeHolding: FilterValue;
  portfolioHoldingCasheEquivalent: FilterValue;
  portfolioHoldingEquity: FilterValue;
  holdingDetails: FilterValue;
  feePlans: FilterValue;
  portfolioImportManager: FilterValue;
  portfolioTradeBook: FilterValue;
  feeCalculation: FilterValue;
  proformaInvoice: FilterValue;
  portfolioMapping: FilterValue;
  invoice: FilterValue;
  feeCollection: FilterValue;
  feePlanPortfolioMapping: FilterValue;
  walletHistory: FilterValue;
  walletFullHistory: FilterValue;
  reconManager: FilterValue;
  mutualFund: FilterValue;
  portfolioMutualFunds: FilterValue;
  incomeExpense: FilterValue;
  bankLedger: FilterValue;
  portfolioBankLedger: FilterValue;
  portfolioInterest: FilterValue;
  portfolioCashEntry: FilterValue;
  customBroker: FilterValue;
  reportScheduler: FilterValue;
  portfolioInvoice: FilterValue;
  portfolioProforma: FilterValue;
  portfolioCalculation: FilterValue;
  portfolioIncomeExpense: FilterValue;
  paymentLink: FilterValue;
  equityBasketOrderManagerPortfolio: FilterValue;
  insights: FilterValue;
  portfolioHoldingsTable: FilterValue;
  portfolioFamilyAccess: FilterValue;
  familyPortfolioAccess: FilterValue;
  familyAdminAccess: FilterValue;
  revenueMandate: FilterValue;
  mandatePresentation: FilterValue;
  mandatePresentationTransaction: FilterValue;
  privateEquity: FilterValue;
  identifiers: FilterValue;
  corporateAction: FilterValue;
  anchorTransaction: FilterValue;
  privateEquityList: FilterValue;
  portfolioPrivateEquityList: FilterValue;
  subscribedPortfolio: FilterValue;
}

export interface FeePlanDropdownInterface {
  data: {
    status: boolean;
    message: string;
    data: FeePlanDropdownDetailsInterface[];
  };
}

export interface FeePlanDropdownDetailsInterface {
  value: number;
  name: string;
  isGetManagement: boolean;
  description: string;
}

export const createCommonFilter = (): FilterValue => ({
  ...defaultFilterValue,
});

export const initialCommonFilterState: CommonFilterState = {
  portfolio: createCommonFilter(),
  portfolioGroup: createCommonFilter(),
  importManager: createCommonFilter(),
  bankStatement: createCommonFilter(),
  client: createCommonFilter(),
  report: createCommonFilter(),
  tradeBook: createCommonFilter(),
  family: createCommonFilter(),
  compliance: createCommonFilter(),
  lead: createCommonFilter(),
  branch: createCommonFilter(),
  employee: createCommonFilter(),
  clientAccess: createCommonFilter(),
  clientFamily: createCommonFilter(),
  familyClient: createCommonFilter(),
  portfolioAccess: createCommonFilter(),
  branchPortfolio: createCommonFilter(),
  branchClient: createCommonFilter(),
  employeePortfolioAccess: createCommonFilter(),
  familyAccess: createCommonFilter(),
  employeeAccess: createCommonFilter(),
  employeeFamilyAccess: createCommonFilter(),
  employeeClientAccess: createCommonFilter(),
  familyPortfolio: createCommonFilter(),
  holdingSummary: createCommonFilter(),
  avlCashBalance: createCommonFilter(),
  portfolioHolding: createCommonFilter(),
  portfolioHoldingTransaction: createCommonFilter(),
  portfolioGroupHolding: createCommonFilter(),
  clientHolding: createCommonFilter(),
  familyHolding: createCommonFilter(),
  branchHolding: createCommonFilter(),
  employeeHolding: createCommonFilter(),
  portfolioHoldingCasheEquivalent: createCommonFilter(),
  portfolioHoldingEquity: createCommonFilter(),
  holdingDetails: createCommonFilter(),
  feePlans: createCommonFilter(),
  portfolioImportManager: createCommonFilter(),
  portfolioTradeBook: createCommonFilter(),
  feeCalculation: createCommonFilter(),
  proformaInvoice: createCommonFilter(),
  portfolioMapping: createCommonFilter(),
  invoice: createCommonFilter(),
  feeCollection: createCommonFilter(),
  feePlanPortfolioMapping: createCommonFilter(),
  walletHistory: createCommonFilter(),
  walletFullHistory: createCommonFilter(),
  reconManager: createCommonFilter(),
  mutualFund: createCommonFilter(),
  portfolioMutualFunds: createCommonFilter(),
  incomeExpense: createCommonFilter(),
  bankLedger: createCommonFilter(),
  portfolioBankLedger: createCommonFilter(),
  portfolioInterest: createCommonFilter(),
  portfolioCashEntry: createCommonFilter(),
  customBroker: createCommonFilter(),
  reportScheduler: createCommonFilter(),
  portfolioInvoice: createCommonFilter(),
  portfolioProforma: createCommonFilter(),
  portfolioCalculation: createCommonFilter(),
  portfolioIncomeExpense: createCommonFilter(),
  paymentLink: createCommonFilter(),
  equityBasketOrderManagerPortfolio: createCommonFilter(),
  insights: createCommonFilter(),
  portfolioHoldingsTable: createCommonFilter(),
  portfolioFamilyAccess: createCommonFilter(),
  familyPortfolioAccess: createCommonFilter(),
  familyAdminAccess: createCommonFilter(),
  revenueMandate: createCommonFilter(),
  mandatePresentation: createCommonFilter(),
  mandatePresentationTransaction: createCommonFilter(),
  privateEquity: createCommonFilter(),
  identifiers: createCommonFilter(),
  corporateAction: createCommonFilter(),
  anchorTransaction: createCommonFilter(),
  privateEquityList: createCommonFilter(),
  portfolioPrivateEquityList: createCommonFilter(),
  subscribedPortfolio: createCommonFilter(),
};
