import { useMutation } from "@tanstack/react-query";

import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

interface FormData {
  isLm?: string;
  name?: string;
  portfolioId?: string;
  client?: number;
}

const useAddPortfolioModal = ({ setOpen }: any) => {
  const [isClientCreateModalOpen, setIsClientCreateModalOpen] = useState(false);

  const { id } = useParams();

  /** =========================================================================================== */
  const { mutate, isLoading: isSubmitting } = useMutation({
    mutationFn: (data: any) => axiosInstance.post(`/portfolios/`, data),
    onSuccess: (response) => {
      setOpen(false);
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery("GET_ALL_PORTFOLIOS");
      refetchQuery("GET_UPLOAD_PORTFOLIO_DROPDOWN");
      refetchQuery(QueryKeys.PORTFOLIOS_SHORT_DATA);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  /** =========================================================================================== */

  const form = useForm<FormData>({
    defaultValues: {
      isLm: "false",
      name: "",
      portfolioId: "",
      client: Number(id) || undefined,
    },
  });

  const onSubmit = (data: FormData) => mutate(data);

  /** =========================================================================================== */
  return {
    isSubmitting,
    isClientCreateModalOpen,
    setIsClientCreateModalOpen,
    id,
    form,
    onSubmit,
  };
};

export default useAddPortfolioModal;
