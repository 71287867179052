import { useAppContext } from "AppContext";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  GKDatePicker,
  GKSearchSelect,
  GKSelect,
  GKTextField,
  Grid2,
  MdExpandMore,
  MenuItem,
  PhoneInput,
  TextField,
  Typography,
} from "components";
import dayjs from "dayjs";
import { useFetchQuery } from "hooks/useQueries";
import FamilyCreateModal from "Pages/Client/FamilyCreateModal";
import AddBranchModal from "Pages/Organisations/AddBranchModal";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { GET_ALL_FAMILY } from "utils/apiRoutes";
import { MARITAL_STATUS, PERSONAL_GENDER } from "utils/constants";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import {
  emailRegex,
  panRegex,
  validateNotWhitespaceOnly,
} from "utils/validationSchema";
import ClientBank from "./ClientBank";
import ClientDemat from "./ClientDemat";
import ClientNominee from "./ClientNominee";

const ClientDetails = () => {
  const form = useFormContext();

  const {
    dispatch,
    state: { cityList, stateList, countryList },
  } = useAppContext();

  const [isBranchCreateModalOpen, setIsBranchCreateModalOpen] =
    useState<boolean>(false);

  const [isFamilyCreateModalOpen, setIsFamilyCreateModalOpen] =
    useState<boolean>(false);

  // =====================================================================================================================
  const { data: familyList, isFetching: familyStateLoading } = useFetchQuery({
    key: ["GET_ALL_FAMILY"],
    route: GET_ALL_FAMILY,
  });

  // =====================================================================================================================
  const loading = familyStateLoading;

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Typography fontSize={24} fontWeight={600}>
            Provide client information below
          </Typography>
        </Grid2>
        <Grid2 container spacing={1}>
          <Grid2 size={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="personalDetails.name"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter name",
                  },
                  validate: validateNotWhitespaceOnly,
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    loading={loading}
                    inputLabel="Name"
                    requiredField
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="phone"
                rules={{
                  required: {
                    value: true,
                    message: "Please enter phone number",
                  },
                }}
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <PhoneInput
                    {...field}
                    onSelectChange={(event: any, val: any) => {
                      form.setValue("countryCode", val?.callingCodes);
                    }}
                    onTextChange={(event: any) => {
                      field.onChange(event.target.value);
                    }}
                    onTextValue={field.value}
                    requiredField
                    onSelectValue={form.getValues("countryCode")}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="personalDetails.email"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter email",
                  },
                  pattern: {
                    value: emailRegex,
                    message: "Please enter a valid mail",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    loading={loading}
                    inputLabel="Email"
                    requiredField
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="personalDetails.pan"
                rules={{
                  required: {
                    value: true,
                    message: "Please enter PAN",
                  },
                  pattern: {
                    value: panRegex,
                    message: "PAN should have this format ABCDE1234A",
                  },
                  maxLength: {
                    value: 10,
                    message: "PAN should have 10 character",
                  },
                  minLength: {
                    value: 10,
                    message: "PAN should have 10 character",
                  },
                }}
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    loading={loading}
                    inputLabel="PAN"
                    onChange={(e) =>
                      field.onChange(e.target.value.toUpperCase())
                    }
                    requiredField
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="family"
              control={form.control}
              render={({ field, formState: { errors } }) => (
                <GKSearchSelect
                  {...field}
                  disableClearable
                  inputLabel="Family"
                  loading={loading}
                  options={
                    familyList?.data?.map((x: any) => ({
                      name: x.name,
                      value: x.id,
                    })) || []
                  }
                  onChange={(e, val) => {
                    field.onChange(val?.value);
                  }}
                  value={
                    familyList?.data?.find(
                      (data: any) => +data?.id === +field.value,
                    )?.name
                  }
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="Family"
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Family",
                        value: field.value,
                      })}
                      error={Boolean(errors?.family)}
                      helperText={errors?.family?.message as string}
                    />
                  )}
                  disabled={loading}
                />
              )}
            />
          </Grid2>
          <Grid2 size={12}>
            <Accordion>
              <AccordionSummary expandIcon={<MdExpandMore />}>
                Personal Details
              </AccordionSummary>
              <AccordionDetails>
                <Grid2 size={12} container spacing={1}>
                  <Grid2 size={6}>
                    <Controller
                      name="personalDetails.gender"
                      control={form.control}
                      render={({ field }) => (
                        <GKSearchSelect
                          {...field}
                          disableClearable={false}
                          inputLabel="Gender"
                          options={PERSONAL_GENDER || []}
                          onChange={(event, val) => {
                            field.onChange(val?.value);
                          }}
                          getOptionLabel={(option) => option.name || option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={handleReturnAutocompletePlaceholder({
                                placeholder: "Select Gender",
                                value: field.value,
                              })}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={6}>
                    <FormControl variant="standard" fullWidth>
                      <Controller
                        name="personalDetails.maritalStatus"
                        control={form.control}
                        render={({ field }) => (
                          <GKSelect {...field} inputLabel="Marital Status">
                            <MenuItem style={{ display: "none" }} value="">
                              Select Marital Status
                            </MenuItem>
                            {MARITAL_STATUS?.map((data) => (
                              <MenuItem value={data.value} key={data.value}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </GKSelect>
                        )}
                      />
                    </FormControl>
                  </Grid2>
                  <Grid2 size={6}>
                    <FormControl variant="standard" fullWidth>
                      <Controller
                        name="personalDetails.fatherName"
                        control={form.control}
                        render={({ field }) => (
                          <GKTextField
                            {...field}
                            loading={loading}
                            inputLabel="Father's Name"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid2>
                  <Grid2 size={6}>
                    <FormControl variant="standard" fullWidth>
                      <Controller
                        name="personalDetails.motherName"
                        control={form.control}
                        render={({ field }) => (
                          <GKTextField
                            {...field}
                            loading={loading}
                            inputLabel="Mother's Name"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid2>
                  <Grid2 size={6}>
                    <FormControl variant="standard" fullWidth>
                      <Controller
                        name="personalDetails.dateOfBirth"
                        control={form.control}
                        render={({ field }) => (
                          <GKDatePicker
                            {...field}
                            disableFuture
                            inputLabel="Date Of Birth"
                            value={dayjs(field.value)}
                            onChange={(value) => {
                              field.onChange(dayjs(value).format("YYYY-MM-DD"));
                            }}
                            minDate={dayjs("01-01-1901")}
                            slotProps={{
                              textField: {
                                error: false,
                                helperText: false,
                              },
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid2>
                  <Grid2 size={6}>
                    <FormControl variant="standard" fullWidth>
                      <Controller
                        name="personalDetails.emergencyContact"
                        control={form.control}
                        render={({ field }) => (
                          <GKTextField
                            {...field}
                            type="number"
                            loading={loading}
                            inputLabel="ُEmergency Contact Number"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid2>
                  <Grid2 size={6}>
                    <FormControl variant="standard" fullWidth>
                      <Controller
                        name="personalDetails.income"
                        control={form.control}
                        render={({ field }) => (
                          <GKTextField
                            {...field}
                            loading={loading}
                            type="number"
                            inputLabel="Income"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid2>
                  <Grid2 size={6}>
                    <FormControl variant="standard" fullWidth>
                      <Controller
                        name="personalDetails.occupation"
                        control={form.control}
                        render={({ field }) => (
                          <GKTextField
                            {...field}
                            loading={loading}
                            inputLabel="Occupation"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid2>
                  <Grid2 size={12}>
                    <FormControl variant="standard" fullWidth>
                      <Controller
                        name="personalDetails.address"
                        control={form.control}
                        render={({ field }) => (
                          <GKTextField
                            {...field}
                            loading={loading}
                            inputLabel="Address"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid2>
                  <Grid2 size={3}>
                    <Controller
                      name="personalDetails.country"
                      control={form.control}
                      render={({ field, fieldState: { error } }) => (
                        <GKSearchSelect
                          {...field}
                          inputLabel="Country"
                          renderInput={(er) => (
                            <TextField
                              {...er}
                              placeholder={handleReturnAutocompletePlaceholder({
                                placeholder: "Select Country",
                                value: field.value,
                              })}
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                          options={countryList || []}
                          getOptionLabel={(e) => e.name || e}
                          onChange={(e, val) => {
                            field.onChange(val.name);
                            form.setValue("city", "");
                            form.setValue("state", "");
                            dispatch({
                              type: "SELECTED_COUNTRY",
                              data: val.id,
                            });
                          }}
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={3}>
                    <Controller
                      name="personalDetails.state"
                      control={form.control}
                      render={({ field, fieldState: { error } }) => (
                        <GKSearchSelect
                          {...field}
                          inputLabel="State"
                          renderInput={(er) => (
                            <TextField
                              {...er}
                              placeholder={handleReturnAutocompletePlaceholder({
                                placeholder: "Select State",
                                value: field.value,
                              })}
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                          options={stateList || []}
                          getOptionLabel={(e) => e.name || e}
                          onChange={(e, val) => {
                            field.onChange(val.name);
                            form.setValue("city", "");
                            dispatch({
                              type: "SELECTED_STATE",
                              data: val.id,
                            });
                          }}
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={3}>
                    <Controller
                      name="personalDetails.city"
                      control={form.control}
                      render={({ field, fieldState: { error } }) => (
                        <GKSearchSelect
                          {...field}
                          inputLabel="City"
                          renderInput={(er) => (
                            <TextField
                              {...er}
                              placeholder={handleReturnAutocompletePlaceholder({
                                placeholder: "Select City",
                                value: field.value,
                              })}
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                          options={cityList || []}
                          getOptionLabel={(e) => e.name || e}
                          onChange={(e, val) => {
                            field.onChange(val.name);
                            dispatch({
                              type: "SELECTED_CITY",
                              data: val.id,
                            });
                          }}
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={3}>
                    <FormControl variant="standard" fullWidth>
                      <Controller
                        name="personalDetails.pincode"
                        control={form.control}
                        render={({ field }) => (
                          <GKTextField
                            {...field}
                            loading={loading}
                            inputLabel="Pincode"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid2>
                </Grid2>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<MdExpandMore />}>
                Bank
              </AccordionSummary>
              <AccordionDetails>
                <ClientBank />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<MdExpandMore />}>
                Demat
              </AccordionSummary>
              <AccordionDetails>
                <ClientDemat />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<MdExpandMore />}>
                Nominee
              </AccordionSummary>
              <AccordionDetails>
                <ClientNominee />
              </AccordionDetails>
            </Accordion>
            {/* <Accordion>
              <AccordionSummary expandIcon={<MdExpandMore />}>
                Basket
              </AccordionSummary>
              <AccordionDetails>
                <BasketDetails />
              </AccordionDetails>
            </Accordion> */}
          </Grid2>
        </Grid2>
      </Grid2>
      {isBranchCreateModalOpen && (
        <AddBranchModal
          open={isBranchCreateModalOpen}
          updateData={null}
          setOpen={setIsBranchCreateModalOpen}
          isEditModal={false}
        />
      )}
      {isFamilyCreateModalOpen && (
        <FamilyCreateModal
          open={isFamilyCreateModalOpen}
          setOpen={setIsFamilyCreateModalOpen}
        />
      )}
    </>
  );
};

export default ClientDetails;
