import {
  DragDrop,
  FormControl,
  GKButton,
  GKModal,
  GKTextField,
  Grid2,
  Typography,
} from "components";
import { useFormik } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import { ThemeContainer } from "utils/Theme";
import axiosInstance from "utils/axiosInstance";
import SheetMappingModal from "./SheetMappingModal";

const UploadSheetModal = ({ open, setOpen, formik, setAllFiles }: any) => {
  const { theme } = ThemeContainer();

  const [showSheetMappingModal, setShowSheetMappingModal] = useState(false);

  const [showSheetMappingData, setShowSheetMappingData] = useState(null);

  const [uploadError, setUploadError] = useState<string | null>(null);

  const uploadSheetFormik = useFormik({
    initialValues: {
      formatName: "",
      files: [],
      format: {},
      brokerage_per_qty: false,
    },
    onSubmit: async (values) => {
      const data = new FormData();
      data.append("files", values?.files[0]);
      data.append("format_name", values.formatName);
      data.append("format", "{}");
      data.append("brokerage_per_qty", "false");
      formik?.setFieldValue(`sheetTypeName`, values?.formatName);
      formik.setFieldValue("file", values?.files);
      formik.setFieldValue("fileIndex", 0);

      await axiosInstance
        .post("/transactions-upload-format-fields/", data)
        .then((response) => {
          setShowSheetMappingData(response?.data?.data);
          setShowSheetMappingModal(true);
        })
        .catch((error: any) => {
          toast.error(
            error?.response?.data?.message ||
              "Something went wrong, please try again.",
          );
        });
    },
  });
  return (
    <>
      <GKModal
        modalTitle={"Custom Template"}
        open={open}
        setOpen={setOpen}
        width={"40%"}
        footer={
          <Grid2 container justifyContent={"flex-end"} gap={1}>
            <GKButton
              variant="contained"
              size={"medium"}
              type="submit"
              form="upload-sheet-form"
              disabled={uploadSheetFormik.values.formatName.length === 0}
            >
              Submit
            </GKButton>
          </Grid2>
        }
      >
        <form id="upload-sheet-form" onSubmit={uploadSheetFormik.handleSubmit}>
          <Grid2 container p={1} spacing={1}>
            <Grid2 size={12}>
              <FormControl variant="standard" fullWidth>
                <GKTextField
                  type="text"
                  name="formatName"
                  inputLabel="Template Name"
                  placeholder="Template Name"
                  onChange={uploadSheetFormik.handleChange}
                  onBlur={uploadSheetFormik.handleBlur}
                  value={uploadSheetFormik.values.formatName}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={12}>
              <Typography fontSize={14} color={theme.palette.primary.main}>
                Upload File
              </Typography>
              <Typography color={theme.palette.grey[700]} fontSize={12}>
                Please ensure that the files are in proper format selected in
                the previous step.
              </Typography>
              <DragDrop
                uploadError={uploadError}
                setUploadError={setUploadError}
                supportName={"CSV & PDF"}
                acceptType={".csv,.pdf"}
                value={uploadSheetFormik?.values?.files}
                onChange={(event) => {
                  uploadSheetFormik.setFieldValue("files", event);
                  setAllFiles(
                    event?.map((data) => {
                      return {
                        file: data,
                      };
                    }),
                  );
                }}
              />
            </Grid2>
          </Grid2>
        </form>
      </GKModal>
      {showSheetMappingModal && (
        <SheetMappingModal
          open={showSheetMappingModal}
          setOpen={setShowSheetMappingModal}
          data={showSheetMappingData}
          formik={formik}
          setUploadModal={setOpen}
          otherData={uploadSheetFormik.values.formatName}
        />
      )}
    </>
  );
};

export default UploadSheetModal;
