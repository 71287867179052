import { BsDot, Grid2, List, ListItem, Typography } from "components";

const Condition = () => {
  const data = [
    {
      title: "DEFINITIONS:",
      firstChild: [
        {
          description: `Agreement means a legal agreement between you as an individual, firm, association of persons, company or legal entity (hereinafter referred to as “You/User”) and Gridkey Software Private Limited, a company incorporated under the provisions of the Companies Act, 1956, having its registered office at 108-B Mittal Tower, Nariman Point, Mumbai-400021, India (hereinafter referred to as "GRIDKEY") with the terms of use described in this Agreement and terms set in GRIDKEY's Privacy Policy for the use of Gridkey Software (as defined in this Agreement) and Gridkey Services (as defined in this Agreement) supplied with this Agreement.`,
        },
        {
          description: `You/User refers to you as an individual, firm, association of persons, company or legal entity that chooses to use Gridkey Software (as defined in this Agreement) and/or Gridkey Services (as defined in this Agreement) by any of the following means:`,
          secondChild: [
            {
              text: `Creating an account with GRIDKEY online with a unique email ID and password to get access to any version of GRIDKEY's Portfolio Management Cloud Application as well as any version of GRIDKEY's Mobile Applications`,
            },
            {
              text: `Downloading and/or using any version of GRIDKEY's Portfolio Management Software Application for Desktop To avail the use of Gridkey Software and Gridkey Services, You/User must fall under any one of the following categories - Free User, Trial User and Paid User, all of which are defined in this Agreement. Free Users, Trial Users and Paid Users of Gridkey Software and/or Gridkey Services are collectively referred to as Users.`,
            },
          ],
        },
        {
          description: `Gridkey Software shall include any version of GRIDKEY's Portfolio Management Software Application for Desktop, any version of GRIDKEY's Portfolio Management Cloud Application as well as any version of GRIDKEY's Mobile Applications, including documentation & user manuals that may be supplied to You/User along with this Agreement. Gridkey Software also includes any free or paid ancillary software products provided to You/User by GRIDKEY, updates, upgrades, bug fixes and enhancements to such software products which GRIDKEY, in its sole discretion, may choose to provide to You/User.`,
        },
        {
          description: `Gridkey Services means the services covered under this Agreement that GRIDKEY makes available to Users. Gridkey Services may include`,
          secondChild: [
            {
              text: "Internet-based and Cloud-based services with the GRIDKEY Software",
            },
            {
              text: "Software updates, stock prices, mutual funds NAVs, prices of other financial assets, addition of stocks names, mutual fund names & other asset names in the database, name change of any assets, and other information related to financial assets or financial market information",
            },
            {
              text: `GRIDKEY data storage on Cloud provided to Users`,
            },
            {
              text: `Upload or import of Your/User"s financial transaction statements into Gridkey Software`,
            },
            {
              text: `Modifications to this Agreement`,
            },
          ],
        },
        {
          description: `Gridkey Websites refers to any website that falls under a domain under the ownership of GRIDKEY including:`,
          secondChild: [
            {
              text: `https://www.gridkey.in`,
            },
            {
              text: `http://www.gridkey.org`,
            },
            {
              text: `http://gridkey.com`,
            },
          ],
        },
        {
          description: `Trial Period means a period of limited days during which You/User can have access to functionality of Gridkey Software and Gridkey Services enabled for the version of Gridkey Software availed without the payment of Subscription Charges. The length of the Trial Period as well as the scope of Gridkey Software functionality and Gridkey Services offered to Trial Users will be decided at the sole discretion of GRIDKEY. “Limited Period” means a period of limited days during which You/User can have access to functionality of Gridkey Software and Gridkey Services enabled for the version of Gridkey Software subscribed to by You/User, conditioned on the payment of Subscription Charges by You/User for the duration of the Limited Period as per GRIDKEY"s pricing policies. The length of the Limited Period as well as the scope of Gridkey Software functionality and Gridkey Services offered to Trial Users will be decided at the sole discretion of GRIDKEY.`,
        },
        {
          description: `Subscription Charges means payments to GRIDKEY mandated as per GRIDKEY"s pricing policies which may be revised at the sole discretion of GRIDKEY from time to time and will be made available on GRIDKEY"s website https://www.gridkey.in`,
        },
        {
          description: `Free User means any User of Gridkey Software and/or Gridkey Services who chooses to avail limited functionality of Gridkey Software and Gridkey Services without the payment of Subscription Charges. The scope of Gridkey Software functionality and Gridkey Services offered to Free Users is decided at the sole discretion of GRIDKEY and is subject to change anytime. "Trial User" means any User of Gridkey Software and/or Gridkey Services who chooses to avail functionality of Gridkey Software and Gridkey Services only during a Trial Period. The scope of Gridkey Software functionality and Gridkey Services offered to Trial Users is decided at the sole discretion of GRIDKEY and is subject to change anytime. "Paid User" means any User of Gridkey Software and/or Gridkey Services who pays the required Subscription Charges associated with Gridkey Software to avail functionality of Gridkey Software and Gridkey Services enabled for the version of Gridkey Software subscribed to, for a Limited Period. The scope of Gridkey Software functionality and Gridkey Services offered to Paid Users is decided at the sole discretion of GRIDKEY and is subject to change anytime. "Users" is a collective term used to refer to all Free Users, Trial Users and Paid Users of Gridkey Software and Gridkey Services.`,
        },
        {
          description: `Third Party &/or Open Source Software" means Intellectual Property not owned by GRIDKEY which may be used by You/User or GRIDKEY for the purpose of integrating Gridkey Software and/or Services on Users" Computers/Devices.`,
        },
      ],
    },
    {
      title: `USE OF GRIDKEY SOFTWARE AND GRIDKEY SERVICES`,
      firstChild: [
        {
          description: `After Your/User"s acceptance of this Agreement, You/User will have certain rights to use GRIDKEY Software as well as Gridkey Services as described below:`,
          secondChild: [
            {
              text: `Free User: If You/User is a Free User, GRIDKEY hereby grants You/User a non-exclusive, non-assignable limited license to use Gridkey Software as well Gridkey Services on the terms set forth in this Agreement. Free Users will have access to limited functionality of Gridkey Software and Gridkey Services. GRIDKEY at its sole discretion may at any time revise the scope of Gridkey Software functionality and the scope of Gridkey Services available to Free Users.`,
            },
            {
              text: `Trial User: If You/User is a Trial User, GRIDKEY hereby grants You/User a non-exclusive, non-assignable limited license to use Gridkey Software as well Gridkey Services during a Trial Period on the terms set forth in this Agreement. Trial Users will have access to full or limited functionality of Gridkey Software and Gridkey Services for evaluation purposes during the Trial Period. At the expiry of the Trial Period, the Trial User has the option to pay the required Subscription Charges associated with Gridkey Software, as listed on GRIDKEY"s website https://www.gridkey.in to continue uninterrupted use of Gridkey Software and Gridkey Services. At the expiry of the Trial Period, if the Trial User chooses to not pay the required Subscription Charges associated with Gridkey Software, the Trial User will only be able to continue availing limited functionality of Gridkey Software and Gridkey Services as a Free User. GRIDKEY at its sole discretion may at any time revise the scope of Gridkey Software functionality and the scope of Gridkey Services offered to Trial Users.`,
            },
            {
              text: `Paid User: If You/User is a Paid User, GRIDKEY hereby grants You/User a non-exclusive, non-assignable limited license to use Gridkey Software as well Gridkey Services during the period for which the Subscription Charges, as listed on GRIDKEY’s website https://www.gridkey.in, are paid by the Paid User. Paid Users will have access to functionality of Gridkey Software and Gridkey Services enabled for the version of Gridkey Software subscribed to, during a Limited Period. GRIDKEY at its sole discretion may at any time revise the scope of Gridkey Software functionality and the scope of Gridkey Services offered to Paid Users.
              You/User is bound by the terms of the Privacy Policy while using Gridkey Software, availing Gridkey Services and/or visiting/viewing Gridkey Websites.`,
            },
          ],
        },
      ],
    },
    {
      title: `CHARGES`,
      firstChild: [
        {
          description: `You/User has the option of subscribing to Gridkey Software by filling in all required User details, providing details about the mode of payment and paying the Subscription Charges as per GRIDKEY"s Pricing policies, detailed on GRIDKEY"s website{' '} https://www.gridkey.in.`,
        },
        {
          description: `GRIDKEY at its sole discretion may change the pricing, scope of Gridkey Software functionality and scope of Gridkey Services for any of its subscription plans at any time, and You/User shall be bound to observe and comply with such changes. Information will likely be updated on Gridkey Websites when GRIDKEY makes such changes. GST and/or other applicable taxes will be payable by You/User at the applicable rate.`,
        },
        {
          description: `The use of Gridkey Software and Gridkey Services, after the expiry of the Trial Period, will only be made available to You/User after the payment of the required Subscription Charges.`,
        },
        {
          description: `All payments due to GRIDKEY under this Agreement shall be made in full, without deduction for any reason, including but not limited to set-off, counterclaim or other equitable or lawful claim.`,
        },
      ],
    },
    {
      title: `USER OBLIGATIONS`,
      firstChild: [
        {
          description: `During the term of this Agreement, You/User shall not copy any documentation which accompanies Gridkey Software or is generated by Gridkey Software.`,
        },
        {
          description: `You/User shall not sublicense, rent, sell or lease any portion of Gridkey Software or otherwise provide Gridkey Software to a third party`,
        },
        {
          description: `You/User will not, and will not allow third parties under Your/User"s control to: (a) copy, modify, create a derivative work of, reverse engineer, decompile, translate, disassemble, or otherwise attempt to extract any or all of the source code of Gridkey Software, (b) use Gridkey Software or Gridkey Services for High Risk Activities; (c) sublicense, resell, or distribute any or all of the Gridkey Software or Gridkey Services separate from any integrated Application; (d) create multiple Applications, Accounts, or Projects to simulate or act as a single Application, Account, or Project (respectively) or otherwise access the Services in a manner intended to avoid incurring necessary Subscription Charges or exceed usage limits or quotas; (e) process or store any data that is illegal/unauthorized/against any law for the time being in force.`,
        },
        {
          description: `You/User may not compile or use Gridkey Software and associated Gridkey Services for purposes of direct marketing, spamming, unsolicited contacting of sellers or customers, or other impermissible advertising, marketing or other activities, including, without limitation, any activities that violate anti-spamming regulations.`,
        },
        {
          description: `You/User may not remove, obscure, or alter any notice of any Trademark, Copyright or other Intellectual Property or proprietary right designation appearing on or contained within Gridkey Services or on Gridkey Software.`,
        },
        {
          description: `Subject to the terms and conditions of this Agreement, You/User may generally publicize the benefit of use of the Gridkey Software and associated Gridkey Services; however, You/User may not issue any press release with respect to Gridkey Software and associated Gridkey Services or with respect to this Agreement without our prior written consent.`,
        },
      ],
    },
    {
      title: `INTELLECTUAL PROPERTY`,
      firstChild: [
        {
          description: `You/User acknowledges and agrees that GRIDKEY shall retain on behalf of itself and its licensors all rights, titles and interest to Gridkey Software, including but not limited to any and all copyrights, patents, trademarks, trade secrets, feedback provided by You/User and all other intellectual property rights, and You/User acquires no rights of any nature to any intellectual property rights or other rights in Gridkey Software.`,
        },
        {
          description: `For installation and use of Gridkey Software, You/User may require Third Party &/or Open Source Software, which are redistributed/bundled along with Gridkey Software. You/User cannot modify, translate, reverse engineer, decompile, disassemble or create derivative works based on Gridkey Software and associated Gridkey Services. You/User cannot rent, lease, or otherwise transfer rights to any third party or remove any proprietary notices or labels on or in Gridkey Software, associated Gridkey Services or any Third Party &/or Open Source Software.`,
        },
        {
          description: `GRIDKEY acknowledges that it may use Third Party &/or Open Source Software for the purpose of this Agreement. GRIDKEY makes no claims on the Intellectual Property of the Third Party &/or Open Source Software.`,
        },
        {
          description: `GRIDKEY will at all times give credit to any Third Party &/or Open Source Software used by GRIDKEY for the purpose of this Agreement. The list of Third Party &/or Open Source Software used by GRIDKEY as well as their copyright notices are listed and can viewed here.`,
        },
        {
          description: `You/User acknowledges that there may be links to third party sites in Gridkey Software, Gridkey Services or Gridkey Websites that may allow You/User to leave Gridkey Software, Gridkey Services or Gridkey Websites. The linked sites of third parties are not under the control of GRIDKEY and GRIDKEY has neither reviewed, nor approved these sites and is not responsible for the contents or omissions of any linked site or any links contained in a linked site. The inclusion of any linked sites does not imply endorsement by GRIDKEY of the linked sites.`,
        },
        {
          description: `In the event You/User elects, at Your/User"s sole discretion, in connection with Gridkey Software and/or Gridkey Services, to communicate to us suggestions for improvements to Gridkey Software and Gridkey Services (collectively, “Feedback”), GRIDKEY shall own all rights, titles, and interest in and to the same, even if You/User have designated the Feedback as confidential, and GRIDKEY shall be entitled to use the Feedback without restriction. GRIDKEY also reserves the right to publish any Feedback received from You/User for marketing or other purposes on Gridkey Websites or on social media platforms, and modify any received Feedback prior to publishing, as deemed necessary for purposes of correcting grammatical or factual inaccuracies or making the Feedback more succinct or readable.`,
        },
      ],
    },
    {
      title: `PERSONAL INFORMATION`,
      firstChild: [
        {
          description: `The Privacy Policy defines the scope for the sharing of Personal Information of Users. Keeping this in mind, GRIDKEY will not disclose Personal Information outside of the scope of what is defined in the Privacy Policy. Notwithstanding any provision to the contrary in this Agreement, GRIDKEY may also disclose Personal Information to the extent required by any applicable legal process.`,
        },
      ],
    },
    {
      title: `INFORMATION DISCLAIMER`,
      firstChild: [
        {
          description: `You/User acknowledges that the information provided through the use of Gridkey Software and Gridkey Services is compiled from sources that are beyond the control of GRIDKEY. Though such information is recognized by You/User and GRIDKEY to be generally reliable, You/User acknowledges that inaccuracies may occur and GRIDKEY does not warrant the accuracy or suitability of the information. For this reason as well as the possibility of human and mechanical errors and other factors, GRIDKEY expressly disclaims any and all warranties, whether expressed, oral, implied, statutory or otherwise, of any kind to Users and/or any third party, including any implied warranties of accuracy, timeliness, completeness, merchantability and fitness for a particular purpose, transmission of data, as well as any warranties arising by virtue of custom of trade or course of dealing and any implied warranties of title or non-infringement. In addition, GRIDKEY, in providing information to Users or third parties makes no endorsement of any particular security, market participant, financial instrument or brokerage in any jurisdiction. Further, GRIDKEY does not represent or warrant that it will meet Your/User"s requirements or is suitable for Your/User"s needs`,
        },
      ],
    },
    {
      title: `LIABILITIES & DISCLAIMERS`,
      firstChild: [
        {
          description: `Under this Agreement, You/User assumes all risk of errors and/or omissions out of the control of GRIDKEY, including the transmission or translation of information, if any/all documentation or data is lost at any time. You/User assumes full responsibility for implementing sufficient procedures and checks to satisfy Your/User"s requirements for the accuracy, suitability and installation of Gridkey Software and Gridkey Services, including any information and for maintaining any means that You/User may require for the reconstruction of lost data or subsequent manipulations or analyses of the information under this Agreement, or for safeguard of Your/User"s personal data.`,
        },
        {
          description: `You/User agrees that GRIDKEY (including but not limited to its officers, directors, employees, affiliates, group companies agents, representatives or subcontractors) shall not in any event be liable for any special, incidental or consequential damages arising out of Your/User"s use or inability to use Gridkey Software and Gridkey Services for any purpose whatsoever. GRIDKEY (including but not limited to its officers, directors, employees, affiliates, group companies agents, representatives or subcontractors) shall have no liability in tort, contract, or otherwise to You/User and/or any third party.`,
        },
        {
          description: `You/User expressly agrees that use of Gridkey Software, Gridkey Services and Gridkey Websites is at Your/User"s sole risk. The contents, information, software, products, features and services published on or in Gridkey Software, Gridkey Services or Gridkey Websites may include inaccuracies or typographical errors. Changes may periodically be added to the contents herein. GRIDKEY may make improvements and/or changes in Gridkey Software, Gridkey Services or Gridkey Websites at any time. For this reason, Gridkey Software, Gridkey Services or Gridkey Websites may temporarily be unavailable from time to time due to required maintenance, telecommunications interruptions, or other disruptions. GRIDKEY shall not be liable to You/User or any third party should GRIDKEY exercise its right to modify or discontinue any or all of the contents, information, software, products, features and services published on or included as part of Gridkey Software, Gridkey Services or Gridkey Websites.`,
        },
        {
          description: `GRIDKEY and any third party Cloud Service Provider(s), that facilitate data storage for GRIDKEY or Users, assume no liability whatsoever for any damages suffered by You/User including but not limited to loss or breach of any of all of Your/User"s data, errors and/or omissions in Your/User"s data as well as any special, incidental or consequential damages arising out of Your/User"s use or inability to use Gridkey Software and Gridkey Services for any purpose whatsoever.`,
        },
        {
          description: `GRIDKEY and any Payment Service Provider(s) that facilitates payments to GRIDKEY for Users assume no liability whatsoever for any monetary and/or other damages suffered by You/User on account of:`,
          secondChild: [
            {
              text: `the delay, failure, interruption, or corruption of any data or other information transmitted for any reason whatsoever in connection with the use of the Payment Gateway or Services provided by GRIDKEY or any Payment Service Provider(s) in connection thereto; and/or`,
            },
            {
              text: `any interruption or errors in the operation of the Payment Gateway or Services provided by GRIDKEY or any Payment Service Provider(s); and/or`,
            },
            {
              text: `inaccurate/incomplete payment details provided by You/User.`,
            },
          ],
        },
        {
          description: `You/User agrees that GRIDKEY (including but not limited to its officers, directors, employees, affiliates, group companies agents, representatives or subcontractors) will not be held liable by You/User for any loss or damages arising from Your/User"s failure to comply with these Terms and Conditions where such failure is due to circumstances beyond GRIDKEY"s reasonable control.`,
        },
        {
          description: `GRIDKEY shall not be held liable by You/User for any error in transactions / sessions time out / failure while making payment, in connection with use of the Payment Gateway or Services provided by GRIDKEY or any Payment Service Provider(s) in connection thereto. Further, GRIDKEY disclaims all liability for any loss caused to You/User due to any technical error/malfunction of the Payment Gateway or Services provided by GRIDKEY or any Payment Service Provider(s) in connection thereto.`,
        },
      ],
    },
    {
      title: `WARRANTY`,
      firstChild: [
        {
          description: `Gridkey Software and Gridkey Services are provided to You/User "AS IS", and GRIDKEY disclaims any warranty or liability obligations of any kind to You/User. The entire risk as to the quality and performance of Gridkey Software and Gridkey Services is borne by You/User. Should Gridkey Software or Gridkey Services prove defective in any respect, You/User and not GRIDKEY (or its suppliers) assumes the entire cost of any service and repair. In addition, the security mechanisms implemented by Gridkey Software and Gridkey Services have inherent limitations, and You/User must determine that Gridkey Software and Gridkey Services sufficiently meet Your/Use"s requirements.`,
        },
        {
          description: `GRIDKEY warrants that Gridkey Software and Gridkey Services will substantially perform in accordance with the details provided with regards to Gridkey Software and Gridkey Services during the time of usage of Gridkey Software and Gridkey Services by You/User, provided that Your/Use"s sole remedy for breach of this performance warranty is that GRIDKEY will use commercially reasonable efforts to cure such nonconformities in the expected performance of Gridkey Software or Gridkey Services as soon as practicable after they are reported in writing to GRIDKEY. This warranty covers identification and correction of nonconformities only (as set forth above) and it does not include assistance in installing or using GRIDKEY Software.`,
        },
        {
          description: `GRIDKEY shall have no responsibility for warranty service if a defect in Gridkey Software or Gridkey Services is the result of accident, abuse or misuse, or is caused by a modification of Gridkey Software or Gridkey Services, or is caused by using any third party hardware or software, or for failure to use the most current version of Gridkey Software or Gridkey Services.`,
        },
        {
          description: `GRIDKEY will strive to keep Your/User"s content secure, but cannot guarantee that GRIDKEY will be successful in doing so, given the nature of the Internet. GRIDKEY strongly encourages You/User, where available and appropriate, to (a) use encryption technology to protect Your/User"s content from unauthorized access, (b) routinely archive Your/User"s content, (c) keep Your/User"s applications or any software that You/User uses or runs in conjunction with Gridkey Software and Gridkey Services current, with the latest security patches or updates and (d) use a secure password when applicable to create a login on GRIDKEY"s Portfolio Management Cloud Application and GRIDKEY"s Mobile Applications. GRIDKEY will have no liability to You/User for any unauthorized access or use, corruption, deletion, destruction or loss of any of Your/User"s data, content or applications.`,
        },
      ],
    },
    {
      title: `INDEMNITY`,
      firstChild: [
        {
          description: `You/User agrees to indemnify, defend and hold GRIDKEY (including but not limited to its officers, directors, employees, affiliates, group companies agents, representatives or subcontractors) harmless from and against any and all claims, losses, damages, liabilities, judgments, penalties, fines, costs and expenses (including reasonable attorneys fees), arising out of or in connection with any claim regarding (i) Your/User"s use of Gridkey Software and/or Gridkey Services in a manner not authorized by this Agreement, and/or in violation of the applicable restrictions, and/or applicable law, (ii) Your/User"s upgradation or combination of either Gridkey Software or Gridkey Services with other applications, content or processes, including but not limited to any claim involving infringement or misappropriation of third-party rights and/or the use, development, design, manufacture, production, advertising, promotion and/or marketing of Your/User"s Application and/or Your/User"s Content, (iii) Your/User"s violation of any term or condition of this Agreement or any applicable additional policies, including without limitation, Your/User"s representations and warranties, or (iv) Your/User"s negligence or willful misconduct or Your/User"s employees" or personnel"s negligence or willful misconduct.`,
        },
        {
          description: `Both Parties - GRIDKEY and You/User agree to promptly notify each other of any claim subject to indemnification, provided that failure to promptly notify shall not affect the obligations hereunder. Either parties - GRIDKEY or You/User may take reasonable steps to defend against any such claim.`,
        },
      ],
    },
    {
      title: `TERM, TERMINATION AND SUSPENSION`,
      firstChild: [
        {
          description: `This Agreement will come into force from the moment You/User becomes a Free User / becomes a Trial User / becomes a Paid User, whichever comes first, as per the terms set in this Agreement by GRIDKEY. This Agreement will remain in effect so long as You/User remains a Free User / remains a Trial User / remains a Paid User, as per the terms set in this Agreement by GRIDKEY.`,
        },
        {
          description: `GRIDKEY may immediately suspend Your/User"s right, license and account to use Gridkey Software and/or Gridkey Services, or terminate this Agreement in its entirety (and, accordingly, Your/Users right to use Gridkey Software and Gridkey Services), for cause effective as set forth below:`,
          secondChild: [
            {
              text: `Your/User"s failure to comply with these Terms and Conditions where such failure is due to circumstances beyond GRIDKEY"s reasonable control; or`,
            },
            {
              text: `You/User seeks to hack or break any security mechanism on any of Gridkey Software, Gridkey Services or Gridkey Websites or GRIDKEY otherwise determines that Your/User"s use of Gridkey Software, Gridkey Services or Gridkey Websites poses a security or service risk to GRIDKEY, to other Users, to any third party sellers on any of Gridkey Websites, or to any of our or their respective customers, or may subject GRIDKEY or any third party to liability, damages or danger;`,
            },
            {
              text: `You/User uses Gridkey Software, Gridkey Services or Gridkey Websites in a way that disrupts or threatens Gridkey Software, Gridkey Services or Gridkey Websites;`,
            },
            {
              text: `You/User is in default of Your/User"s payment obligations with respect to the payment of Subscription Charges required by GRIDKEY, when applicable;`,
            },
            {
              text: `GRIDKEY determines, in its sole reasonable discretion, there is evidence of fraud with respect to Your/User"s GRIDKEY account;`,
            },
            {
              text: `You/User uses any of the Intellectual Property of GRIDKEY so as to infringe/cause damage to the same;`,
            },
            {
              text: `GRIDKEY receives notice or otherwise determines, in its sole reasonable discretion, that You/User may be using Gridkey Software, Gridkey Services or Gridkey Websites for any illegal purpose or in a way that violates the law or violates, infringes, or misappropriates the rights of any third party;`,
            },
            {
              text: ` GRIDKEY determines, in its sole reasonable discretion, that its provision of any of Gridkey Software, Gridkey Services or Gridkey Websites to You/User is prohibited by applicable law, or has become impractical or unfeasible for any reason not limited to legal or regulatory; or`,
            },
            {
              text: `subject to applicable law, upon Your/User"s liquidation, commencement of dissolution proceedings, disposal of Your/User"s assets, failure to continue Your/User"s business, assignment for the benefit of creditors, or if You/User becomes the subject of a voluntary or involuntary bankruptcy or similar proceeding.`,
            },
          ],
        },
      ],
    },
    {
      title: `REFUND OF CHARGES`,
      firstChild: [
        {
          description: `Please refer to the following link to learn more about Gridkey"s refund policy:{' '} Gridkey Refund Policy`,
        },
      ],
    },
    {
      title: `EFFECT OF TERMINATION AND SUSPENSION`,
      firstChild: [
        {
          description: `Upon GRIDKEY"s termination/suspension of Your/User"s account and use of Gridkey Software and/or Gridkey Services, in whole or in part, for any reason, (i) You/User remains liable for all fees, charges and any other obligations that You/User have incurred through the date of suspension with respect to the use of Gridkey Software and/or Gridkey Services; and (ii) all of Your/User"s rights with respect to the use of Gridkey Software and/or Gridkey Services shall be terminated during the period of termination/suspension (iii) You/User shall immediately return, or if instructed by GRIDKEY, destroy all Confidential Information belonging to GRIDKEY and any copies of Gridkey Software and/or Gridkey Services then in Your/User"s possession.
          `,
        },
      ],
    },
    {
      title: `SURVIVAL`,
      firstChild: [
        {
          description: `The following clauses of this Agreement will survive termination/suspension of Your/User"s account with GRIDKEY or Your/User"s use of Gridkey Software and/or Gridkey Services:`,
          secondChild: [
            {
              text: `Intellectual Property - clause 5`,
            },
            {
              text: `Personal Information - clause 6`,
            },
            {
              text: `Information Disclaimer - clause 7`,
            },
            {
              text: `Liabilities and Disclaimer - clause 8`,
            },
            {
              text: `Indemnity - clause 10`,
            },
            {
              text: `Term, Termination and Suspension - clause 11`,
            },
            {
              text: `Refund of Charges - Clause 12`,
            },
            {
              text: `Effect of Termination and Suspension - Clause 13`,
            },
          ],
        },
      ],
    },
    {
      title: `GENERAL`,
      firstChild: [
        {
          description: `The terms and conditions of this Agreement shall be governed by and construed in accordance with the laws of India and subject only to the jurisdiction of the competent courts in Mumbai, Maharashtra.`,
        },
        {
          description: `In the event that it is determined by a court of competent jurisdiction as part of a final non-appeal-able judgment that any provision of this Agreement (or part thereof) is invalid, illegal, or otherwise unenforceable, such provision will be enforced as nearly as possible in accordance with the stated intention of the parties, while the remainder of the Agreement will remain in full force and effect.`,
        },
        {
          description: `This Agreement constitutes the entire understanding and agreement between the parties with respect to the subject matter addressed herein and supersedes any and all prior or contemporaneous oral or written communications with respect to the subject matter hereof, all of which are merged herein. You/User may not assign Your/User"s rights and obligations under this Agreement without the prior written consent of an authorized representative of GRIDKEY.`,
        },
      ],
    },
  ];

  return (
    <Grid2
      container
      py={10}
      pt={0}
      sx={{
        px: {
          xs: 3,
          lg: 10,
        },
        background: "#020707",
      }}
    >
      <Grid2 size={12}>
        <List sx={{ padding: 0 }}>
          {data?.map((item, index) => {
            return (
              <ListItem
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  padding: 0,
                }}
              >
                <Typography
                  textAlign={"justify"}
                  fontSize={24}
                  fontWeight={600}
                >
                  {index + 1}. {item.title}
                </Typography>
                <List sx={{ padding: 0 }}>
                  {item.firstChild.map((fChild, fIndex) => {
                    return (
                      <ListItem
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          padding: 0,
                        }}
                      >
                        <Typography textAlign={"justify"} fontSize={14}>
                          {index + 1}.{fIndex + 1} {fChild.description}
                        </Typography>
                        <List>
                          {fChild.secondChild &&
                            fChild.secondChild.map((sChild) => {
                              return (
                                <ListItem>
                                  <Typography
                                    textAlign={"justify"}
                                    color={"#999999"}
                                    display={"flex"}
                                    fontSize={12}
                                  >
                                    <BsDot
                                      style={{
                                        minWidth: 20,
                                        minHeight: 20,
                                      }}
                                    />
                                    {sChild.text}
                                  </Typography>
                                </ListItem>
                              );
                            })}
                        </List>
                      </ListItem>
                    );
                  })}
                </List>
              </ListItem>
            );
          })}
        </List>
      </Grid2>
    </Grid2>
  );
};

export default Condition;
