import { CommonPagination, GKDataGrid } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useNavigate, useParams } from "react-router-dom";
import { TableFiltering } from "utils/TableFilters";
import { FAMILY_ID_BY_HOLDING } from "utils/apiRoutes";
import { holdingsMainPageColumns } from "utils/columns";
import { QueryKeys } from "utils/queryKey";

const HoldingTab = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const requestBody = useGenerateQueryParams("familyHolding");

  const { data: familyState, isLoading } = useFetchQuery({
    key: [QueryKeys.FAMILY_HOLDINGS, requestBody],
    route: FAMILY_ID_BY_HOLDING(id),
    requestBody,
  });

  const totalCurrentAmount = () =>
    !isLoading &&
    familyState?.data?.data?.reduce(
      (acc: number, obj: any) => acc + obj.currentAmount,
      0,
    );

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={familyState?.data?.totalCount || 0}
        name="familyHolding"
      />
    );
  };

  return (
    <GKDataGrid
      loading={isLoading}
      columns={holdingsMainPageColumns(totalCurrentAmount())}
      rows={familyState?.data?.data || []}
      getRowId={(row) => row?.isin}
      zeroHolding
      onRowClick={(row: any) => {
        navigate(`/app/holdings/${id}/transactions`, {
          state: {
            holdingId: id,
            name: row?.row?.name,
            gcode: row?.row?.gcode,
            currentAmount: row?.row?.currentAmount,
            investedAmount: row?.row?.investedAmount,
            quantity: row?.row?.quantity,
            type: "family",
          },
        });
      }}
      CustomPagination={!isLoading && cusPagination}
      name="familyHolding"
      tableName="family_holding_list"
      headerFilter={<TableFiltering name="familyHolding" />}
      exportFile={{
        path: FAMILY_ID_BY_HOLDING(id),
        exportName: "FamilyHoldingsList",
      }}
    />
  );
};

export default HoldingTab;
