import {
  DragDrop,
  FormControl,
  GKButton,
  GKDatePicker,
  Grid2,
  MdOutlineFileDownload,
} from "components";
import dayjs from "dayjs";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { paletteLightTheme } from "utils/ColorTheme";

// const transactionUploadType = [{ name: "Holding Wise", value: "Holdings" }];

const PortfolioImportStepOne = () => {
  const form = useFormContext();

  const [uploadError, setUploadError] = useState<string | null>(null);

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={12}>
        <Controller
          name="step1.anchorDate"
          control={form.control}
          rules={{
            required: { value: true, message: "Please select anchor date" },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl variant="standard" fullWidth>
              <GKDatePicker
                {...field}
                requiredField
                inputLabel={"Select Anchor Date"}
                value={dayjs(field.value)}
                onChange={(value) =>
                  field.onChange(dayjs(value).format("YYYY-MM-DD"))
                }
                disableFuture
                slotProps={{
                  textField: {
                    error: !!error,
                    helperText: error?.message,
                  },
                }}
              />
            </FormControl>
          )}
        />
      </Grid2>
      {/* <Grid2 size={6}>
        <Controller
          name="step1.transactionType"
          control={form.control}
          render={({ field, formState: { errors } }) => (
            <GKSearchSelect
              {...field}
              inputLabel="Select Transaction Type"
              requiredField
              disableClearable
              options={transactionUploadType}
              onChange={(e, val) => {
                field.onChange(val?.value);
              }}
              value={
                transactionUploadType.find(
                  (dataItem) => dataItem.value === field.value
                )?.name || ""
              }
              getOptionLabel={(option) => option.name || option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select transaction type"
                  error={!!errors?.client}
                  helperText={errors?.client?.message as string}
                />
              )}
            />
          )}
        />
      </Grid2> */}
      <Grid2 size={12}>
        <Controller
          name="step1.files"
          control={form.control}
          rules={{
            required: {
              value: true,
              message: "Please upload a document",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <DragDrop
              {...field}
              uploadError={uploadError}
              setUploadError={setUploadError}
              acceptType={".csv, .pdf,.xlsx,.xls"}
              supportName="CSV or PDF or XLSX"
              onChange={(value) => field.onChange(value)}
              error={Boolean(error)}
            />
          )}
        />
        <GKButton
          sx={{
            py: 0,
            color: paletteLightTheme.palette.grey[500],
            display: "flex",
            alignItems: "center",
            "&:hover": { background: "transparent" },
          }}
          href={`https://frontend-logo.s3.us-east-1.amazonaws.com/Template/${
            form.getValues("step1.transactionType") === "Holdings"
              ? "GRIDKEY_HOLDINGS_CSV"
              : "TRADE_GRIDKEY"
          }.csv`}
          target="_blank"
          startIcon={<MdOutlineFileDownload size={14} />}
        >
          Download Sample File
        </GKButton>
      </Grid2>
    </Grid2>
  );
};

export default PortfolioImportStepOne;
