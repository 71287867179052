import { Box, Divider, Grid2, Grow, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useAppContext } from "AppContext";
import { useState } from "react";
import { RiShareBoxLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeContainer } from "utils/Theme";
import { UserRoleTypes } from "utils/common";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#DDEAEE",
    color: theme.palette.primary.main,
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      backgroundColor: "#DDEAEE",
    },
  },
}));

const DesignedDrawer = styled("div")((props: any) => {
  return {
    flexShrink: 0,
    borderRight: `solid 1px`,
    borderColor: props.theme.palette.border.main,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    width: props["data-isopen"] ? 241 : 65,
    zIndex: 5,
    transition: "all 0.3s",
    padding: 8,
    maxHeight: "calc(100vh - 66px)",
  };
});

interface Props {
  menuList: {
    name: string;
    icon: any;
    to: string;
    role: string[];
  }[];
}

const SideMenu = ({ menuList }: Props) => {
  const { theme } = ThemeContainer();

  const {
    state: { sidebarOpen, userData: userInfo },
  } = useAppContext();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const [hoverIndex, setHoverIndex] = useState(null);

  return (
    <DesignedDrawer
      onMouseLeave={() => setHoverIndex(null)}
      data-isopen={sidebarOpen}
    >
      <List
        sx={{
          padding: 0,
          overflow: "hidden",
          transition: "overflow 0.3s",
          minHeight:
            userInfo?.userRole !== UserRoleTypes.client
              ? "calc(100vh - 194px)"
              : "calc(100vh - 130px)",
          maxHeight:
            userInfo?.userRole !== UserRoleTypes.client
              ? "calc(100vh - 194px)"
              : "calc(100vh - 130px)",
          "&:hover": {
            overflow: "hidden  auto",
            transition: "overflow 0.3s",
          },
        }}
      >
        {menuList
          .filter((dataItem) => dataItem?.role?.includes(userInfo?.userRole))
          .map((text, index) => (
            <span key={index}>
              <LightTooltip
                key={index}
                open={index === hoverIndex && !sidebarOpen}
                title={text.name}
                placement={"right"}
                arrow
                disableHoverListener={sidebarOpen}
              >
                <ListItem
                  onMouseEnter={() => setHoverIndex(index)}
                  onClick={() => {
                    navigate(text.to);
                  }}
                  key={text.name}
                  disablePadding
                  sx={{
                    display: "block",
                    transition: "all 0.3s",
                    "&:hover": {
                      transition: "all 0.3s",
                    },
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: sidebarOpen ? "initial" : "center",
                      px: 2.5,
                      fontWeight: 600,
                      borderRadius: "10px",
                      background: pathname.includes(text.to)
                        ? `${theme.palette.primary.main}22`
                        : ` ${theme.palette.light.main}`,
                      color: pathname.includes(text.to)
                        ? `${theme.palette.primary.main}`
                        : theme.palette.grey[700],
                      "&:hover": {
                        background: pathname.includes(text.to)
                          ? `${theme.palette.primary.main}22`
                          : ` ${theme.palette.grey[200]}`,
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: sidebarOpen ? 2 : "auto",
                        justifyContent: "center",
                        color: pathname.includes(text.to)
                          ? `${theme.palette.primary.main}`
                          : theme.palette.grey[700],
                      }}
                    >
                      <text.icon size={20} />
                    </ListItemIcon>
                    <ListItemText
                      primary={text.name}
                      sx={{
                        opacity: sidebarOpen ? 1 : 0,
                        "& .MuiTypography-root": {
                          fontWeight: 600,
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </LightTooltip>
            </span>
          ))}
      </List>
      <Box sx={{ marginTop: "auto" }}>
        <Grid2 container>
          {userInfo?.userRole !== UserRoleTypes.client && (
            <Grid2 size={12}>
              <Divider />
            </Grid2>
          )}
          {userInfo?.userRole !== UserRoleTypes.client && (
            <Grid2 size={12} mt={1}>
              <ListItemButton
                onClick={() => {
                  navigate(`/app/company-onboarding`);
                  refetchQuery(QueryKeys.GET_USER_DETAIL);
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: sidebarOpen ? "initial" : "center",
                  px: 2.5,
                  fontWeight: 600,
                  borderRadius: "10px",
                  background: pathname.includes("/app/company-onboarding")
                    ? `${theme.palette.primary.main}22`
                    : ` ${theme.palette.light.main}`,
                  color: pathname.includes("/app/company-onboarding")
                    ? `${theme.palette.primary.main}`
                    : theme.palette.grey[700],
                  "&:hover": {
                    background: pathname.includes("/app/company-onboarding")
                      ? `${theme.palette.primary.main}22`
                      : ` ${theme.palette.grey[200]}`,
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: sidebarOpen ? 2 : "auto",
                    justifyContent: "center",
                    color: pathname.includes("/app/company-onboarding")
                      ? `${theme.palette.primary.main}`
                      : theme.palette.grey[700],
                  }}
                >
                  <RiShareBoxLine size={20} />
                </ListItemIcon>
                <ListItemText
                  primary={"Get Started"}
                  sx={{
                    display: sidebarOpen ? "block" : "none",
                    "& .MuiTypography-root": {
                      fontWeight: 600,
                    },
                  }}
                />
              </ListItemButton>
            </Grid2>
          )}
          <Grid2 size={12} mt={1}>
            <Divider />
          </Grid2>
          <Grid2
            size={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 1,
              marginTop: "auto",
            }}
          >
            <Grow timeout={300} in={sidebarOpen}>
              <Typography
                sx={{ display: sidebarOpen ? "flex" : "none" }}
                fontSize={12}
              >
                Powered By
              </Typography>
            </Grow>
            <Grow timeout={300} in={sidebarOpen}>
              <img
                onError={({ currentTarget }) => {
                  currentTarget.src = "/Logos/errorImg.svg";
                }}
                alt=""
                src={"/Logos/iconFullLogo.png"}
                width={"50%"}
                style={{ display: sidebarOpen ? "flex" : "none" }}
                draggable="false"
              />
            </Grow>
            <Grow timeout={300} in={!sidebarOpen}>
              <img
                onError={({ currentTarget }) => {
                  currentTarget.src = "/Logos/errorImg.svg";
                }}
                alt=""
                src={"/Logos/iconLogo.png"}
                width={"30px"}
                style={{ display: !sidebarOpen ? "flex" : "none" }}
                draggable="false"
              />
            </Grow>
          </Grid2>
        </Grid2>
      </Box>
    </DesignedDrawer>
  );
};
export default SideMenu;
