import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  ConfirmDeleteModal,
  Divider,
  FiTrash2,
  FormControl,
  GKButton,
  GKTextField,
  Grid2,
  IconButton,
} from "components";
import { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

const NomineeTab = ({ portfolioData }: any) => {
  const form = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "nominees",
  });

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [clientBankId, setClientBankId] = useState(0);

  const { mutate: handleDeleteMutation } = useMutation({
    mutationKey: ["DELETE_CLIENT_NOMINEE"],
    mutationFn: () => axiosInstance.delete(`/client/${clientBankId}/nominees/`),
    onSuccess: (response) => {
      refetchQuery(QueryKeys.CLIENT_NOMINEE_ACCOUNT);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: handleAssign, isLoading } = useMutation({
    mutationKey: ["ASSIGN_CLIENT_NOMINEE"],
    mutationFn: (data: any) =>
      axiosInstance.patch(`/portfolios/${portfolioData?.id}/`, data),
    onSuccess: (response) => {
      refetchQuery("PORTFOLIO_UPDATE_DATA_BY_ID");
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  return (
    <>
      <Grid2 size={12} container spacing={1}>
        <Grid2 size={12}>
          {fields.map((row: any, index: number) => (
            <Grid2
              container
              size={12}
              spacing={1}
              my={1}
              display={"flex"}
              alignItems={"center"}
            >
              <Grid2 size={3}>
                <Controller
                  control={form.control}
                  name={`nominees.${index}.name`}
                  render={({ field }) => (
                    <FormControl variant="standard" fullWidth>
                      <GKTextField {...field} inputLabel="Nominee Name" />
                    </FormControl>
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  control={form.control}
                  name={`nominees.${index}.percentage`}
                  render={({ field }) => (
                    <FormControl variant="standard" fullWidth>
                      <GKTextField {...field} inputLabel="Nominee Percentage" />
                    </FormControl>
                  )}
                />
              </Grid2>
              <Grid2 size={3}>
                <Controller
                  control={form.control}
                  name={`nominees.${index}.relationship`}
                  render={({ field }) => (
                    <FormControl variant="standard" fullWidth>
                      <GKTextField {...field} inputLabel="Relationship" />
                    </FormControl>
                  )}
                />
              </Grid2>
              <Grid2
                container
                size={3}
                spacing={1}
                key={index}
                my={1}
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gap={2}
              >
                <LoadingButton
                  style={{
                    marginTop: 18,
                  }}
                  size="small"
                  variant={"link"}
                  loading={isLoading}
                  color="dark"
                  onClick={(e) => {
                    e.stopPropagation();
                    let data = {};
                    if (portfolioData?.nominees?.includes(row?.rowId)) {
                      data = {
                        ...portfolioData,
                        nominees: portfolioData?.nominees?.filter(
                          (data: any) => data !== row?.rowId,
                        ),
                      };
                    } else {
                      data = {
                        ...portfolioData,
                        nominees: [...portfolioData?.nominees, row?.rowId],
                      };
                    }

                    handleAssign(data);
                  }}
                >
                  {portfolioData?.nominees?.includes(row?.rowId)
                    ? "Unassign"
                    : "Assign"}
                </LoadingButton>
                <IconButton
                  className="outline"
                  style={{
                    marginTop: 18,
                  }}
                  color="error"
                  disabled={fields?.length === 1}
                  onClick={() => {
                    if (row?.rowId) {
                      setClientBankId(row?.rowId);
                      setConfirmationModal(true);
                    } else {
                      remove(index);
                    }
                  }}
                >
                  <FiTrash2 size={18} />
                </IconButton>
              </Grid2>
            </Grid2>
          ))}
        </Grid2>
        <Grid2 size={12}>
          <Divider>
            <GKButton
              onClick={() =>
                append({ name: "", percentage: "", relationship: "" })
              }
              variant="outlined"
              size="small"
              color="dark"
            >
              Add New
            </GKButton>
          </Divider>
        </Grid2>
      </Grid2>

      {confirmationModal && (
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => handleDeleteMutation()}
          title={"Nominee"}
          isDelete
        />
      )}
    </>
  );
};

export default NomineeTab;
