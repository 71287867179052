import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more"; // Required for gauges
import Highcharts from "highcharts/highstock";
import SolidGauge from "highcharts/modules/solid-gauge"; // Required for solid gauges
import { ThemeContainer } from "utils/Theme";

HighchartsMore(Highcharts);
SolidGauge(Highcharts);

const GKGaugeChart = ({
  maxValue,
  value,
}: {
  maxValue: number;
  value: number;
}) => {
  const { theme } = ThemeContainer();

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        chart: {
          type: "gauge",
        },
        credits: {
          text: "",
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
        accessibility: {
          enabled: false,
        },
        title: {
          text: " ",
        },
        pane: {
          startAngle: -90,
          endAngle: 90,
          background: null,
          center: ["50%", "80%"],
          size: "100%",
        },
        yAxis: {
          min: 0,
          max: maxValue,
          tickPixelInterval: 0,
          tickPosition: "inside",
          tickLength: 0,
          tickWidth: 0,
          minorTickInterval: null,
          labels: {
            enabled: false,
            distance: 200,
            style: {
              fontSize: "0px",
            },
          },
          lineWidth: 0,
          plotBands: [
            {
              from: 0,
              to: maxValue / 2,
              color: {
                linearGradient: { x1: 0, y1: 0, x2: 1, y2: 0 },
                stops: [
                  [0, "rgb(100, 240, 1)"],
                  [1, "rgb(239, 240, 1)"],
                ],
              },
              thickness: 25,
            },
            {
              from: maxValue / 2,
              to: maxValue,
              color: {
                linearGradient: { x1: 0, y1: 0, x2: 1, y2: 0 },
                stops: [
                  [0, "rgb(239, 240, 1)"],
                  [1, "rgb(255, 100, 1)"],
                ],
              },
              thickness: 25,
            },
          ],
        },

        series: [
          {
            name: "Points",
            data: [value],
            dataLabels: {
              format: " ",
              borderWidth: 0,
              color:
                (Highcharts.defaultOptions.title &&
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color) ||
                theme.palette.secondaryText.main,
              style: {
                fontSize: "16px",
              },
            },
            dial: {
              radius: "80%",
              backgroundColor: "gray",
              baseWidth: 12,
              baseLength: "0%",
              rearLength: "0%",
            },
            pivot: {
              backgroundColor: "gray",
              radius: 6,
            },
          },
        ],
      }}
      containerProps={{ style: { width: "300px", height: "300px" } }}
    />
  );
};

export default GKGaugeChart;
