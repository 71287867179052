import { useAppContext } from "AppContext";
import { Grid2, Show, Tab, Tabs } from "components";
import IncomeExpense from "Pages/IncomeExpense";
import MutualFunds from "Pages/MutualFunds";
import { useParams } from "react-router-dom";
import { PORTFOLIO_TREAD_BOOK_LIST } from "utils/apiRoutes";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import CashEntry from "./CashEntry";
import PortfolioLedger from "./PortfolioLedger";
import PortfolioSummaryTradeBookTab from "./PortfolioSummaryTradeBookTab";

const ReportsPages = () => {
  useDocumentTitle("Portfolio Reports");

  const { id } = useParams();

  const {
    state: { tab },
    dispatch,
  } = useAppContext();

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={12} display={"flex"}>
        <Tabs
          className="light"
          variant="standard"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
          value={tab?.portfolioReportTabs}
          onChange={(e, val) => {
            dispatch({
              type: "TAB",
              data: { ...tab, portfolioReportTabs: val },
            });
          }}
        >
          <Tab label="Ledger" disableRipple />
          <Tab label="Equity/ETF" disableRipple />
          <Tab label="Mutual Funds" disableRipple />
          <Tab label="Cash Entry" disableRipple />
          <Tab label="Income/Expense" disableRipple />
          <Tab label="Private Equity" disableRipple />
        </Tabs>
      </Grid2>
      <Grid2 size={12}>
        <Show.When isTrue={tab?.portfolioReportTabs === 0}>
          <PortfolioLedger />
        </Show.When>
        <Show.When isTrue={tab?.portfolioReportTabs === 1}>
          <PortfolioSummaryTradeBookTab
            name={"portfolioTradeBook"}
            apiRoute={PORTFOLIO_TREAD_BOOK_LIST(id)}
            apiRouteKey={QueryKeys.TRANSACTIONS_LIST}
            tableName="portfolio_trade_book_list"
            bulkDelete={"/transaction/bulk_delete/"}
            exportRoute={`/portfolios/${id}/transactions/`}
            portfolioId={id}
          />
        </Show.When>
        <Show.When isTrue={tab?.portfolioReportTabs === 2}>
          <MutualFunds
            id={id}
            name="portfolioMutualFunds"
            tableName="portfolio_mutual_funds"
          />
        </Show.When>
        <Show.When isTrue={tab?.portfolioReportTabs === 3}>
          <CashEntry />
        </Show.When>
        <Show.When isTrue={tab?.portfolioReportTabs === 4}>
          <IncomeExpense
            id={id}
            name="portfolioIncomeExpense"
            tableName="portfolio_income_expense"
          />
        </Show.When>
        <Show.When isTrue={tab?.portfolioReportTabs === 5}>
          <PortfolioSummaryTradeBookTab
            name={"portfolioPrivateEquityList"}
            apiRoute={`/portfolios/${id}/other_assets/transactions/?asset_type=PEQ`}
            apiRouteKey={QueryKeys.PORTFOLIO_PRIVATE_EQUITY}
            tableName="portfolio_private_equity_list"
            bulkDelete={"/other_assets/bulk_delete/?asset_type=PEQ"}
            exportRoute={""}
            routeNavigate={{
              transactionType: "OTHER-ASSETS",
              assetType: "PEQ",
            }}
            portfolioId={id}
          />
        </Show.When>
      </Grid2>
    </Grid2>
  );
};

export default ReportsPages;
