import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import {
  FiTrash2,
  FormControl,
  GKButton,
  GKModal,
  GKSearchSelect,
  GKTextField,
  Grid2,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "components";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { emailRegex } from "utils/validationSchema";

interface Props {
  data?: any;
}

const EmailEditModal = (props: Props & GKModalProps) => {
  const { open, setOpen, data } = props;

  const {
    state: { portfolios },
  } = useAppContext();

  const [dataToSubmit, setDataToSubmit] = useState<any[]>(
    data?.portfolio || [],
  );

  const { mutate: handleCreateReplyEmail, isLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(`/reply-email-portfolio/`, data),
    onSuccess: (response) => {
      setOpen(false);
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery("GET_REPLAY_EMAIL_LIST");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: handleUpdateReplyEmail, isLoading: updateReplyEmail } =
    useMutation({
      mutationFn: (value: any) =>
        axiosInstance.patch(`/reply-email-portfolio/${data.id}/`, value),
      onSuccess: (response) => {
        setOpen(false);
        toast.success(
          response.data?.message || "Action completed successfully.",
        );
        refetchQuery("GET_REPLAY_EMAIL_LIST");
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  const form = useForm({
    defaultValues: {
      email: data.email || "",
      portfolio: [],
    },
  });

  const clientOptions = portfolios?.filter(
    (portfolio: any) => !dataToSubmit.some((item) => item.id === portfolio.id),
  );

  const handleDeleteFromTable = (portfolioId: string) => {
    const newData = dataToSubmit.filter((item) => item.id !== portfolioId);
    setDataToSubmit(newData);
    form.setValue(
      "portfolio",
      form.getValues("portfolio").filter((id) => id !== portfolioId),
    );
  };

  return (
    <GKModal
      modalTitle={`Add New "reply to" Field`}
      open={open}
      setOpen={setOpen}
      footer={
        <Grid2
          container
          gap={1}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <GKButton
            variant="outlined"
            size="medium"
            color="dark"
            onClick={() => setOpen(false)}
          >
            Cancel
          </GKButton>
          <LoadingButton
            variant="contained"
            size="medium"
            disabled={isLoading || updateReplyEmail}
            type="submit"
            form="client-add-family-form"
          >
            Save
          </LoadingButton>
        </Grid2>
      }
    >
      <form
        id="client-add-family-form"
        onSubmit={form.handleSubmit((values) => {
          if (data.id) {
            handleUpdateReplyEmail({
              email: values.email,
              portfolio: dataToSubmit.map((item) => item.id),
            });
          } else {
            handleCreateReplyEmail({
              email: values.email,
              portfolio: dataToSubmit.map((item) => item.id),
            });
          }
        })}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            <Grid2 size={12}>
              <Controller
                name="email"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter email",
                  },
                  pattern: {
                    value: emailRegex,
                    message: "Please enter a valid mail",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl variant="standard" fullWidth>
                    <GKTextField
                      {...field}
                      requiredField
                      inputLabel="Email"
                      error={!!error}
                      helperText={error?.message}
                    />
                  </FormControl>
                )}
              />
            </Grid2>
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="portfolio"
              control={form.control}
              rules={{
                required: {
                  value: dataToSubmit.length === 0,
                  message: "Please select portfolio",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  requiredField={dataToSubmit.length === 0}
                  disableClearable={false}
                  multiple
                  getOptionLabel={(option) => option.name || ""}
                  inputLabel="Select Portfolio"
                  renderOption={(props, option) => {
                    return (
                      <li
                        {...props}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography fontWeight={600}>
                          {option.clientName}
                        </Typography>
                        <Typography>{option.name}</Typography>
                      </li>
                    );
                  }}
                  options={clientOptions || []}
                  value={clientOptions?.filter((option: any) =>
                    field.value.includes(option.id),
                  )}
                  onChange={(e, val) => {
                    field.onChange(val.map((client: any) => client.id));
                    setDataToSubmit([...val, ...dataToSubmit]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Portfolio",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid2>

          {dataToSubmit.length !== 0 && (
            <>
              <Grid2 size={12}>
                <Typography fontSize={12} fontWeight={400} color={"#98A4B3"}>
                  Existing Clients
                </Typography>
              </Grid2>
              <Grid2 size={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ padding: 10 }}>Client</TableCell>
                        <TableCell align="right" style={{ padding: 10 }}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataToSubmit.map((dataItem, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ padding: 10 }}>
                            {dataItem.name}
                          </TableCell>
                          <TableCell align="right" style={{ padding: 10 }}>
                            <IconButton
                              className="outline"
                              onClick={() => handleDeleteFromTable(dataItem.id)}
                              color="error"
                            >
                              <FiTrash2 size={20} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid2>
            </>
          )}
        </Grid2>
      </form>
    </GKModal>
  );
};

export default EmailEditModal;
