import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  Avatar,
  Divider,
  FiTrash2,
  FormControl,
  GKButton,
  GKDatePicker,
  GKSearchSelect,
  GKTextField,
  Grid2,
  IconButton,
  PhoneInput,
  SimpleLoader,
  styled,
  TextField,
  Typography,
} from "components";
import dayjs from "dayjs";
import { ProfileAPI } from "hooks/ProfileAPI";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { USER_PROFILE_DETAILS_BY_ID } from "utils/apiRoutes";
import { PERSONAL_GENDER } from "utils/constants";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UserProfile = () => {
  useDocumentTitle("User Profile");

  const { theme } = ThemeContainer();

  const {
    state: { userData, countryList, stateList, cityList },
    dispatch,
  } = useAppContext();

  const { handleUpdateProfile, updateProfileUpdateLoading } = ProfileAPI();

  const [profilePicPreview, setProfilePicPreview] = useState<string | null>(
    null,
  );

  const { data: profileData, isLoading } = useFetchQuery({
    route: USER_PROFILE_DETAILS_BY_ID(userData?.id),
    key: ["GET_PROFILE_DETAILS"],
    enabled: Boolean(userData?.id),
  });

  const form = useForm({
    defaultValues: {
      profilePic: [],
      email: profileData?.data?.data?.email || "",
      name: profileData?.data?.data?.name || "",
      gender: profileData?.data?.data?.gender || "",
      maritalStatus: profileData?.data?.data?.maritalStatus || "",
      fatherName: profileData?.data?.data?.fatherName || "",
      motherName: profileData?.data?.data?.motherName || "",
      dateOfBirth: profileData?.data?.data?.dateOfBirth || "",
      address: profileData?.data?.data?.address || "",
      city: profileData?.data?.data?.city || "",
      state: profileData?.data?.data?.state || "",
      country: profileData?.data?.data?.country || "",
      pincode: profileData?.data?.data?.pincode || "",
      occupation: profileData?.data?.data?.occupation || "",
      pan: profileData?.data?.data?.pan || "",
      aadhar: profileData?.data?.data?.aadhar || "",
      emergencyCountryCode:
        profileData?.data?.data?.emergencyCountryCode || "+91",
      emergencyContact: profileData?.data?.data?.emergencyContact || "",
      emergencyContactNum: profileData?.data?.data?.emergencyContactNum || "",
      emergencyContactEmail:
        profileData?.data?.data?.emergencyContactEmail || "",
    },
    values: {
      profilePic: [],
      email: profileData?.data?.data?.email || "",
      name: profileData?.data?.data?.name || "",
      gender: profileData?.data?.data?.gender || "",
      maritalStatus: profileData?.data?.data?.maritalStatus || "",
      fatherName: profileData?.data?.data?.fatherName || "",
      motherName: profileData?.data?.data?.motherName || "",
      dateOfBirth: profileData?.data?.data?.dateOfBirth || "",
      address: profileData?.data?.data?.address || "",
      city: profileData?.data?.data?.city || "",
      state: profileData?.data?.data?.state || "",
      country: profileData?.data?.data?.country || "",
      pincode: profileData?.data?.data?.pincode || "",
      occupation: profileData?.data?.data?.occupation || "",
      pan: profileData?.data?.data?.pan || "",
      aadhar: profileData?.data?.data?.aadhar || "",
      emergencyCountryCode:
        profileData?.data?.data?.emergencyCountryCode || "+91",
      emergencyContact: profileData?.data?.data?.emergencyContact || "",
      emergencyContactNum: profileData?.data?.data?.emergencyContactNum || "",
      emergencyContactEmail: profileData?.data?.data?.emergencyContactNum || "",
    },
  });

  if (isLoading) return <SimpleLoader />;

  const onSubmit = (values: any) => {
    const payload = {
      email: values?.email,
      name: values?.name,
      gender: values?.gender,
      maritalStatus: values?.maritalStatus,
      fatherName: values?.fatherName,
      motherName: values?.motherName,
      dateOfBirth: values?.dateOfBirth,
      address: values?.address,
      city: values?.city,
      state: values?.state,
      country: values?.country,
      pincode: values?.pincode,
      occupation: values?.occupation,
      pan: values?.pan,
      aadhar: values?.aadhar,
      emergencyContact: values?.emergencyContact,
      emergencyContactNum: values?.emergencyContactNum,
      emergencyContactEmail: values?.emergencyContactEmail,
    };

    handleUpdateProfile(payload);
  };

  const handleProfilePicChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
      form.setValue("profilePic", [file]);

      const formData = new FormData();
      formData.append("profilePic", file);
      handleUpdateProfile(formData);

      event.target.value = "";
    }
  };

  const handleProfilePicDelete = () => {
    const formData = new FormData();
    formData.append("profilePic", "");
    handleUpdateProfile(formData);
    setProfilePicPreview(null);
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} id="profile-form">
      <Grid2 container spacing={2}>
        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Personal info
          </Typography>
        </Grid2>
        <Grid2
          container
          size={9}
          spacing={2}
          display={"flex"}
          alignItems={"center"}
        >
          <Grid2
            container
            size={12}
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            <Grid2>
              <Avatar
                alt=""
                src={profilePicPreview || userData?.profilePic}
                sx={{ width: 72, height: 72, border: "1px solid #eee" }}
              />
            </Grid2>
            <Grid2 ml={1}>
              <GKButton
                variant="contained"
                component="label"
                role={undefined}
                tabIndex={-1}
              >
                Upload Profile Photo{" "}
                <VisuallyHiddenInput
                  type="file"
                  accept={".jpg, .png, .svg"}
                  onChange={handleProfilePicChange}
                />
              </GKButton>
            </Grid2>
            <Grid2>
              <IconButton
                sx={{ border: "1px solid #CDD5DF", borderRadius: "8px", p: 1 }}
                onClick={handleProfilePicDelete}
              >
                <FiTrash2 size={18} />
              </IconButton>
            </Grid2>
          </Grid2>

          <Grid2 size={6}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="name"
                control={form.control}
                // rules={{
                //   required: {
                //     value: true,
                //     message: "Please enter a valid name",
                //   },
                //   validate: validateNotWhitespaceOnly,
                // }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <GKTextField
                      {...field}
                      inputLabel="Name"
                      // requiredField
                      error={!!error}
                      helperText={error?.message}
                    />
                  );
                }}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={6}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="aadhar"
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel="Aadhar Number" />
                )}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="dateOfBirth"
                control={form.control}
                // rules={{
                //   required: {
                //     value: true,
                //     message: "Please Select Birth of Date",
                //   },
                // }}
                render={({ field, fieldState: { error } }) => (
                  <GKDatePicker
                    {...field}
                    // requiredField
                    inputLabel="Date Of Birth"
                    value={dayjs(field.value)}
                    onChange={(value) => {
                      field.onChange(dayjs(value).format("YYYY-MM-DD"));
                    }}
                    minDate={dayjs("01-01-1901")}
                    slotProps={{
                      textField: {
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={4}>
            <Controller
              name="gender"
              control={form.control}
              // rules={{
              //   required: {
              //     value: true,
              //     message: "Please Enter Gender",
              //   },
              // }}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  disableClearable={false}
                  inputLabel="Gender"
                  options={PERSONAL_GENDER || []}
                  onChange={(event, val) => {
                    field.onChange(val?.value);
                  }}
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Gender",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={!!error && error.message}
                    />
                  )}
                />
              )}
            />
          </Grid2>
          <Grid2 size={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="occupation"
                control={form.control}
                // rules={{
                //   required: {
                //     value: true,
                //     message: "Please Enter Occupation",
                //   },
                // }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Occupation"
                    // requiredField
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={6}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="fatherName"
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel="Father's name" />
                )}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={6}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="motherName"
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel="Mother's name" />
                )}
              />
            </FormControl>
          </Grid2>
        </Grid2>

        <Grid2 size={12} my={1}>
          <Divider />
        </Grid2>

        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Address Details
          </Typography>
        </Grid2>
        <Grid2
          container
          size={9}
          spacing={2}
          display={"flex"}
          alignItems={"center"}
        >
          <Grid2 size={12}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="address"
                control={form.control}
                // rules={{
                //   required: {
                //     value: true,
                //     message: "Please Enter Address",
                //   },
                //   validate: validateNotWhitespaceOnly,
                // }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Address"
                    // requiredField
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </FormControl>
          </Grid2>

          <Grid2 size={3}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="pincode"
                control={form.control}
                // rules={{
                //   required: {
                //     value: true,
                //     message: "Please Enter Pincode",
                //   },
                // }}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    type="number"
                    inputLabel="Pincode"
                    // requiredField
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={3}>
            <Controller
              name="country"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  inputLabel="Country"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Country",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                  options={countryList || []}
                  getOptionLabel={(e) => e.name || e}
                  onChange={(e, val) => {
                    field.onChange(val.name);
                    form.setValue("city", "");
                    form.setValue("state", "");
                    dispatch({
                      type: "SELECTED_COUNTRY",
                      data: val.id,
                    });
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={3}>
            <Controller
              name="state"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  inputLabel="State"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select State",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                  options={stateList || []}
                  getOptionLabel={(e) => e.name || e}
                  onChange={(e, val) => {
                    field.onChange(val.name);
                    form.setValue("city", "");
                    dispatch({
                      type: "SELECTED_STATE",
                      data: val.id,
                    });
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={3}>
            <Controller
              name="city"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <GKSearchSelect
                  {...field}
                  inputLabel="City"
                  renderInput={(er) => (
                    <TextField
                      {...er}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select City",
                        value: field.value,
                      })}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                  options={cityList || []}
                  getOptionLabel={(e) => e.name || e}
                  onChange={(e, val) => {
                    field.onChange(val.name);
                    dispatch({
                      type: "SELECTED_CITY",
                      data: val.id,
                    });
                  }}
                />
              )}
            />
          </Grid2>
        </Grid2>

        <Grid2 size={12} my={1}>
          <Divider />
        </Grid2>

        <Grid2 size={3}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color={theme.palette.secondaryText.main}
          >
            Secondary Contact Details
          </Typography>
        </Grid2>

        <Grid2
          container
          size={9}
          spacing={2}
          display={"flex"}
          alignItems={"center"}
        >
          <Grid2 size={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="emergencyContact"
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel="Contact Name" />
                )}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="emergencyContactNum"
                control={form.control}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    onSelectChange={(event: any, val: any) => {
                      form.setValue("emergencyCountryCode", val?.callingCodes);
                    }}
                    onTextChange={(event: any) => {
                      field.onChange(event.target.value);
                    }}
                    onTextValue={field.value}
                    onSelectValue={form.getValues("emergencyCountryCode")}
                  />
                )}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="emergencyContactEmail"
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel="Contact Email" />
                )}
              />
            </FormControl>
          </Grid2>
        </Grid2>

        <Grid2 size={12} textAlign={"right"} my={1}>
          <LoadingButton
            form="profile-form"
            variant="contained"
            type="submit"
            loading={updateProfileUpdateLoading}
          >
            Save
          </LoadingButton>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default UserProfile;
