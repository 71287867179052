import dayjs from "dayjs";
import { array, date, number, object, string } from "yup";

export const panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/gm;
export const gstRegex =
  /^[0123][0-9][a-zA-Z]{5}[0-9]{4}[a-zA-Z][0-9][a-zA-Z0-9][a-zA-Z0-9]$/;

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;

/** ========================================================================================================= */
export const bankAccountSchema = object({
  accountNum: string().required("Please enter account number"),
  bank: string().required("Please enter bank"),
  ifsc: string().required("Please enter ifsc"),
  name: string().required("Please enter name"),
  type: string().required("Please enter type"),
});
/** ========================================================================================================= */
export const uploadPasswordSchema = object({
  password: string().required("Please enter password"),
});

/** ========================================================================================================= */
export const allocationSchema = object({
  allocationType: string().required("Please select a type"),
  portfolio: array().min(1),
});

/** ========================================================================================================= */
export const addCashTransactionSchema = object({
  date: date().required("Please select transaction date"),
  portfolio: string().required("Portfolio Code is required"),
  transactionType: string().required("Please Select transaction type"),
});

/** ========================================================================================================= */
export const addPortfolioGroupSchema = object({
  name: string()
    .required("Name is required")
    .test(
      "whitespace-only",
      "Name cannot be empty or whitespace only",
      (value) => {
        return typeof value === "string" && value.trim().length > 0;
      },
    ),
  portfolios: array()
    .min(2, "Please select at least 2 items")
    .required("Select portfolios"),
});

/** ========================================================================================================= */
export const addEmployeeSchema = object({
  user: object({
    personalDetails: object({
      name: string()
        .required("Please enter name")
        .test(
          "whitespace-only",
          "Name cannot be empty or whitespace only",
          (value) => {
            return typeof value === "string" && value.trim().length > 0;
          },
        ),
      email: string()
        .required("Please enter email")
        .email("Please enter a valid email"),
    }),
    phone: string().required("Please enter phone number"),
  }),
});

/** ========================================================================================================= */
export const scheduleMeetingSchema = object({
  meetingType: string().required("Please select a meeting type."),
  startDate: string().required("Please select a date."),
});
/** ========================================================================================================= */
export const newMeetingSchema = object({
  meetingType: string().required("Please select a meeting type."),
  startDate: string().required("Please select a date."),
  startTime: string().required("Please select a time."),
  meetingTitle: string()
    .required("Please add meeting title.")
    .test(
      "whitespace-only",
      "Meeting Title cannot be empty or whitespace only",
      (value) => {
        return typeof value === "string" && value.trim().length > 0;
      },
    ),
  agenda: string()
    .required("Please add meeting's agenda.")
    .test(
      "whitespace-only",
      "Agenda cannot be empty or whitespace only",
      (value) => {
        return typeof value === "string" && value.trim().length > 0;
      },
    ),
  meetingPerson: string().required("Please add attendees."),
});
/** ========================================================================================================= */
export const LeadSchema = object({
  email: string().email("Please enter a valid email"),
  panNumber: string()
    .max(10, "Please enter valid pan")
    .matches(panRegex, "Please enter valid pan"),
});

/** ========================================================================================================= */
export const addClientSchema = object({
  // branch: string().required("Please select branch"),
  // family: string().required("Please select family"),
  personalDetails: object({
    name: string()
      .required("Please enter Name")
      .test(
        "whitespace-only",
        "Name cannot be empty or whitespace only",
        (value) => {
          return typeof value === "string" && value.trim().length > 0;
        },
      ),
    pan: string()
      .required("Please enter PAN")
      .max(10, "Please enter valid pan")
      .matches(panRegex, "Please enter valid pan"),
    email: string()
      .required("Please enter Email")
      .email("Please enter a valid email"),
  }),
  // phone: string()
  //   .required("Please enter Phone Number")
  //   .matches(phoneRegex, "Phone number should be 10 digits"),
});

/** ========================================================================================================= */
export const modalPortfolioMetaSchema = object({
  name: string().required("Name is required"),
});

/** ========================================================================================================= */
export const modelPortfolioVersionSchema = object({
  versionName: string().required("Name is required"),
  stocks: array().of(
    object().shape({
      isinCode: string().required("Please select stock"),
    }),
  ),
});

/** ========================================================================================================= */
export const dividendBookSchema = object({
  stocks: array().of(
    object().shape({
      portfolio: string().required("Please select portfolio"),
      date: string().required("Please enter date"),
      isinCode: string().required("Please select company"),
      quantity: number()
        .required("Please enter quantity")
        .moreThan(0, "Quantity must be greater than zero"),
    }),
  ),
});
/** ========================================================================================================= */
export const modelPortfolioStockSchema = object({
  stocks: array().of(
    object().shape({
      isinCode: string().required("Please select stock"),
    }),
  ),
});

/** ========================================================================================================= */
export const clientKycModalFormSchema = object({
  pan: string()
    .matches(panRegex, "PAN should have this format ABCDE1234A")
    .min(10, "PAN should have 10 character")
    .max(10, "PAN should have 10 character"),
  identification_type: string().required("Please select identification type"),
});

export const noteCreateValidationSchema = object({
  client: string().required("Please select a type"),
  note_type: string().required("Please select a type"),
  content: string().required("Please enter content"),
  dateTime: string().required("Please enter date"),
});

export const reportValidationSchema = object({
  portfolio: array()
    .min(1)
    .when("selectedReported.reportName", {
      is: (params: any) => params !== "stock_wise_holdings_report",
      then: (schema) => schema.min(1),
      otherwise: (schema) => schema.min(0),
    })
    .when("selectedReported", {
      is: (params: any) =>
        params?.reportName === "custom_report" &&
        params?.reportType === "template",
      then: (schema) => schema.min(1) && schema.max(1),
    }),
  // benchmark: string().when("selectedReported.reportName", {
  //   is: "custom_report",
  //   then: (schema) => schema.required("Please select a benchmark"),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
  gcode: string().when("selectedReported.reportName", {
    is: "stock_wise_holdings_report",
    then: (schema) => schema.required("Please select company"),
    otherwise: (schema) => schema.notRequired(),
  }),
  to_date: string().when("selectedReported.reportName", {
    is: (reportName: string) =>
      ["stock_wise_holdings_report"].includes(reportName),
    then: (schema) => schema.required("Please select date"),
    otherwise: (schema) => schema.notRequired(),
  }),
  end_date: string().when("selectedReported.reportName", {
    is: (reportName: string) =>
      [
        "xirr_report",
        "historical_valuation_report",
        "unrealized_capital_gain_detailed_report",
        "unrealized_capital_gain_summary_report",
      ].includes(reportName),
    then: (schema) => schema.required("Please select date"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

// export const createFeePlanValidationSchema = object({
//   managementFee: object({
//     highwatermarkAmount: string().when("highwatermark", {
//       is: true,
//       then: (schema) =>
//         schema.required(
//           "High water mark amount is required when highwatermark is active"
//         ),
//       otherwise: (schema) => schema.notRequired(),
//     }),
//   }),
// });

export const validateNotWhitespaceOnly = <T>(value: T): true | string =>
  typeof value === "string"
    ? value.trim().length > 0 || "Whitespace is not allowed"
    : true;

export const schema = (field: string) =>
  object({
    [field]: string()
      .required("Please select transaction date")
      .test("is-valid-date", "Invalid date", (value) => {
        return dayjs(value).isValid();
      })
      .test(
        "is-valid-date",
        "You are not allowed to add a transaction for this date.",
        (value) => dayjs(value).year() >= 1980,
      ),
  });
