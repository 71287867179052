/* eslint-disable import/no-named-as-default-member */
import { LoadingButton } from "@mui/lab";
import {
  Card,
  DragDrop,
  FormControl,
  FormControlLabel,
  GKButton,
  GKCheckbox,
  GKSelect,
  Grid2,
  MenuItem,
  Show,
  StatusField,
  Typography,
} from "components";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  AGREEMENT_SEND_OPTIONS_SOURCE,
  AGREEMENT_SIGNATURE_SOURCE,
} from "utils/constants";

const ClientOnboardingAgreement = ({ setCustomAgreement }: any) => {
  const form = useFormContext();

  const [uploadError, setUploadError] = useState<string | null>(null);

  return (
    <>
      {form.getValues("agreement") ? (
        <Grid2 container spacing={1}>
          <Grid2
            size={6}
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <Typography fontSize={24} fontWeight={600}>
              Agreement
            </Typography>
          </Grid2>
          <Grid2
            size={6}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={1}
          >
            <GKButton
              variant="contained"
              onClick={() => {
                form.setValue("agreement", null);
              }}
            >
              Upload Agreement
            </GKButton>
          </Grid2>
          <Grid2 size={6}>
            <Card>
              <Grid2 container spacing={1}>
                <Grid2
                  size={6}
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <StatusField
                    statusString={
                      form.getValues("agreement.agreementStatus.status") ||
                      "Pending"
                    }
                  />
                </Grid2>
                <Grid2 size={6} display={"flex"} justifyContent={"flex-end"}>
                  <LoadingButton
                    variant="outlined"
                    color="dark"
                    size="small"
                    href={
                      form.getValues(
                        "agreement.agreementStatus.signedDocument",
                      ) || form.getValues("agreement.document")
                    }
                    target="_blank"
                  >
                    View Document
                  </LoadingButton>
                </Grid2>
                <Grid2 size={3}>
                  <Typography textTransform={"capitalize"}>
                    Signature Type:
                  </Typography>
                </Grid2>
                <Grid2 size={9} display={"flex"} justifyContent={"flex-end"}>
                  <Typography
                    textTransform={"capitalize"}
                    textAlign={"left"}
                    fontWeight={600}
                  >
                    {form.getValues("agreement.signatureType") || "-"}{" "}
                  </Typography>
                </Grid2>
                <Grid2 size={3} display={"flex"}>
                  <Typography textTransform={"capitalize"}>
                    Send Option :
                  </Typography>
                </Grid2>
                <Grid2 size={9} display={"flex"} justifyContent={"flex-end"}>
                  <Typography
                    textTransform={"capitalize"}
                    textAlign={"left"}
                    fontWeight={600}
                  >
                    {form.getValues("agreement.sendOptions") || "-"}
                  </Typography>
                </Grid2>
              </Grid2>
            </Card>
          </Grid2>
        </Grid2>
      ) : (
        <Grid2 container spacing={1} overflow={`hidden auto`}>
          <Grid2 container size={12} spacing={1}>
            <Grid2
              size={12}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={1}
            >
              <Typography fontSize={24} fontWeight={600}>
                Agreement
              </Typography>
              <GKButton
                variant="outlined"
                color="dark"
                onClick={() => setCustomAgreement(true)}
              >
                Generate Agreement
              </GKButton>
            </Grid2>
            <Grid2 container size={12} spacing={1}>
              <Grid2 size={12}>
                <Controller
                  control={form.control}
                  name="document"
                  rules={{
                    required: {
                      value: true,
                      message: "Please upload a document",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <DragDrop
                        uploadError={uploadError}
                        setUploadError={setUploadError}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        supportName=".png, .jpg, .pdf"
                        acceptType={".pdf,.png,.jpg"}
                        error={!!error}
                      />
                    );
                  }}
                />
              </Grid2>
              <Grid2 size={12}>
                <Controller
                  name="manual"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormControlLabel
                        {...field}
                        style={{
                          marginLeft: 0,
                        }}
                        label="Skip Signature"
                        control={
                          <GKCheckbox
                            checked={field.value}
                            onChange={(event) => {
                              field.onChange(event.target.checked);
                            }}
                          />
                        }
                      />
                    );
                  }}
                />
              </Grid2>
              <Show.When isTrue={!form.getValues("manual")}>
                <Grid2 size={6}>
                  <Controller
                    control={form.control}
                    name="signatureType"
                    render={({ field }) => {
                      return (
                        <FormControl variant="standard" fullWidth>
                          <GKSelect
                            {...field}
                            inputLabel="Sign Type"
                            displayEmpty
                          >
                            <MenuItem style={{ display: "none" }} value="">
                              Select Sign Type
                            </MenuItem>
                            {AGREEMENT_SIGNATURE_SOURCE?.map((dataItem, i) => (
                              <MenuItem key={i} value={dataItem.value}>
                                {dataItem.name}
                              </MenuItem>
                            ))}
                          </GKSelect>
                        </FormControl>
                      );
                    }}
                  />
                </Grid2>
              </Show.When>
              <Show.When isTrue={!form.getValues("manual")}>
                <Grid2 size={6}>
                  <Controller
                    control={form.control}
                    name="sendOptions"
                    render={({ field }) => {
                      return (
                        <FormControl variant="standard" fullWidth>
                          <GKSelect
                            {...field}
                            inputLabel="Send Agreement Via"
                            displayEmpty
                          >
                            <MenuItem style={{ display: "none" }} value="">
                              Send Agreement Via
                            </MenuItem>
                            {AGREEMENT_SEND_OPTIONS_SOURCE?.map(
                              (dataItem, i) => (
                                <MenuItem key={i} value={dataItem.value}>
                                  {dataItem.name}
                                </MenuItem>
                              ),
                            )}
                          </GKSelect>
                        </FormControl>
                      );
                    }}
                  />
                </Grid2>
              </Show.When>
            </Grid2>
          </Grid2>
        </Grid2>
      )}
    </>
  );
};

export default ClientOnboardingAgreement;
