import { useMutation } from "@tanstack/react-query";
import { GKButton, GKClientDataGrid } from "components";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { CLIENT_BANK_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import BankAccountModal from "./BankAccountModal";

const BankInfoTab = ({ portfolioData }: any) => {
  const [openModal, setOpenModal] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  const { data, isFetching, refetch } = useFetchQuery({
    key: [QueryKeys.PORTFOLIO_BANK_ACCOUNT],
    route: CLIENT_BANK_BY_ID(portfolioData?.client),
  });

  const { mutate: handleAssignMutation, isLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.patch(`/portfolios/${portfolioData?.id}/`, {
        ...portfolioData,
        bankAccount: data,
      }),
    onSuccess: () => {
      refetchQuery("PORTFOLIO_UPDATE_DATA_BY_ID");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  return (
    <>
      <GKClientDataGrid
        name="bankInfo"
        density="compact"
        headerComponent={
          <GKButton
            variant="outlined"
            size="small"
            color="dark"
            onClick={() => {
              setUpdateData(null);
              setOpenModal(true);
            }}
          >
            Add Bank Account
          </GKButton>
        }
        loading={isLoading || isFetching}
        rows={data?.data?.bankAccounts || []}
        onRowClick={(params) => {
          setOpenModal(true);
          setUpdateData(params.row);
        }}
        columns={[
          {
            ...extraColumnsField("Bank Account Name", "name", 150, 1),
          },
          { ...extraColumnsField("Bank Name", "bank", 150, 1) },
          {
            ...extraColumnsField("Bank Account Number", "accountNum", 150, 1),
          },
          {
            ...extraColumnsField("Type", "type", 100, 1),
          },
          {
            ...extraColumnsField("IFSC Code", "ifsc", 100, 1),
          },

          {
            ...extraColumnsField("Assign", "Assign", 100, 1),
            align: "right",
            headerAlign: "right",
            renderCell: (params) => {
              return (
                params?.row?.id !== portfolioData?.bankAccount && (
                  <GKButton
                    variant="text"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAssignMutation(params?.row?.id);
                    }}
                  >
                    Assign
                  </GKButton>
                )
              );
            },
          },
        ]}
      />
      {openModal && (
        <BankAccountModal
          open={openModal}
          setOpen={setOpenModal}
          allAccounts={data?.data}
          updateData={updateData}
          clientId={portfolioData?.client}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default BankInfoTab;
