import { Grid2, TableContainer, TablePagination } from "@mui/material";
import {
  DataGridPro,
  DataGridProProps,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { useCallback, useMemo, useState } from "react";
import { LuSearch } from "react-icons/lu";
import { ThemeContainer } from "utils/Theme";
import { CustomLoader, EmptyData } from "./DatagridUtils";
import GKTextField from "./GKTextField";
import { Show } from "./Show";

interface Props {
  headerComponent?: any;
  showSearch?: boolean;
  showHeaderGrid?: any;
  initialPageSize?: any;
  headerTitle?: any;
  emptyButtons?: any;
  pageSize?: number;
  maxHeight?: string;
  minHeight?: string;
  height?: string;
}

const GKSimpleDataGrid = (props: DataGridProProps & Props) => {
  const { theme } = ThemeContainer();

  const {
    rows,
    headerComponent,
    showSearch = true,
    loading,
    showHeaderGrid = true,
    pagination = true,
    initialPageSize,
    columns,
    headerTitle,
    emptyButtons,
    pageSize,
    maxHeight,
    minHeight,
    onRowClick,
    height,
    initialState,
  } = props;

  const [searchText, setSearchText] = useState("");

  const [isFocused, setIsFocused] = useState(false);

  const filteredRows =
    rows?.length !== 0 &&
    rows?.filter((row) => {
      return Object.values(row).some((value) => {
        return String(value)
          .toLowerCase()
          ?.includes(searchText?.toLowerCase().trim());
      });
    });

  const handleRowsReturn = useMemo(() => {
    if (searchText) {
      return filteredRows;
    }
    if (loading) {
      return [];
    }
    return rows || [];
  }, [rows, searchText, loading]);

  const CustomToolbar = useCallback(
    (pgProps: any) => {
      const { value, onInputChange } = pgProps;
      return (
        <Show.When isTrue={showHeaderGrid}>
          <Grid2
            minHeight={"52px"}
            container
            justifyContent={"space-between"}
            p={1}
            alignItems={"center"}
            borderBottom={`solid 1px ${theme.palette.grey[300]}`}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 10,
                width: "100%",
              }}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                }}
              >
                <Show.When isTrue={headerTitle}>{headerTitle}</Show.When>
                <Show.When isTrue={showSearch}>
                  <GKTextField
                    style={{ width: 200 }}
                    placeholder={"Search..."}
                    value={value}
                    sx={{
                      "& .MuiInputBase-root ": {
                        height: "35px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #E3E8EF",
                      },
                    }}
                    onChange={onInputChange}
                    type="search"
                    autoFocus={isFocused}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    slotProps={{
                      input: {
                        startAdornment: (
                          <LuSearch size={26} color={theme.palette.grey[500]} />
                        ),
                      },
                    }}
                  />
                </Show.When>
              </span>
              <Show.When isTrue={headerComponent}>{headerComponent}</Show.When>
            </div>
          </Grid2>
        </Show.When>
      );
    },
    [showHeaderGrid, headerTitle, showSearch, headerComponent],
  );

  const Pagination = (props: any) => {
    const apiRef = useGridApiContext();

    const handlePageChange = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number,
    ) => {
      apiRef?.current?.setPage(newPage);
    };

    const handleRowsPerPageChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      const newRowsPerPage = parseInt(event.target.value, 10);
      apiRef?.current?.setPageSize(newRowsPerPage);
    };

    return pagination ? (
      <TablePagination
        {...props}
        component="div"
        labelRowsPerPage="Rows"
        count={apiRef?.current.getRowsCount()}
        page={apiRef?.current.state.pagination.paginationModel.page}
        rowsPerPage={apiRef?.current.state.pagination.paginationModel.pageSize}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={initialPageSize ? [] : [10, 20, 30]}
      />
    ) : (
      <div> </div>
    );
  };

  return (
    <TableContainer
    // sx={{
    //   "& .MuiDataGrid-pinnedColumnHeaders": {
    //     paddingRight: "0px !important",
    //   },
    // }}
    >
      <DataGridPro
        {...props}
        pageSizeOptions={[10, 20]}
        pagination
        rows={handleRowsReturn}
        columns={columns?.map((dataItem) => {
          return {
            ...dataItem,
            disableColumnMenu: true,
            disableExport: true,
          };
        })}
        slots={{
          toolbar: CustomToolbar,
          noRowsOverlay: () => <EmptyData emptyButtons={emptyButtons} />,
          loadingOverlay: CustomLoader,
          footer: Pagination,
        }}
        initialState={{
          ...initialState,
          pagination: {
            paginationModel: {
              pageSize: pageSize || 10,
            },
          },
        }}
        slotProps={{
          toolbar: {
            value: searchText,
            onInputChange: (event: any) => setSearchText(event.target.value),
          },
        }}
        sx={{
          "& .MuiDataGrid-virtualScroller": {
            maxHeight: maxHeight || "calc(100vh - 256px)",
            minHeight: minHeight || "10px",
          },
          "&.MuiDataGrid-root .MuiTablePagination-root": {
            padding: "0 8px",
          },
          "& .MuiDataGrid-row": {
            "&:hover": {
              cursor: onRowClick ? "pointer" : "",
            },
          },
          minHeight: height,
          maxHeight: height,
        }}
      />
    </TableContainer>
  );
};

export default GKSimpleDataGrid;
