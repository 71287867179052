import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { FormControl, GKDatePicker, GKModal, Grid2 } from "components";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { v4 as uuid } from "uuid";

interface Props {
  form: any;
  selectedId: any;
  id: any;
}

const UpdateRebalanceDate = ({
  open,
  setOpen,
  form,
  selectedId,
  id,
}: Props & GKModalProps) => {
  // =+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+
  const { mutate: handleUpdateModelPortfolio, isLoading } = useMutation({
    mutationFn: (item: any) =>
      axiosInstance.post(`/model_portfolio/${id}/get_version_on_date/`, {
        id: item?.id,
        date: item?.date,
      }),
    onSuccess: (response: any) => {
      const da = form
        .getValues("stocks")
        ?.filter(
          (d: any) =>
            !response?.data?.allocItem
              ?.map((inD: any) => inD?.isinCode)
              ?.includes(d?.isinCode),
        );

      form.setValue("stocks", [
        ...response?.data?.allocItem?.map((item: any) => {
          return {
            ...item,
            uuid: uuid(),
          };
        }),
        ...da,
      ]);
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  // =+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+=+
  const handleUpdateVersion = (values: any) => {
    handleUpdateModelPortfolio({
      id: selectedId,
      date: dayjs(values.date).format("YYYY-MM-DD"),
    });
  };

  return (
    <GKModal
      open={open}
      modalTitle="Rebalance Date"
      setOpen={setOpen}
      footer={
        <LoadingButton
          loading={isLoading}
          onClick={() => {
            handleUpdateVersion(form.getValues());
          }}
          variant="contained"
        >
          Submit
        </LoadingButton>
      }
    >
      <Grid2 container>
        <Grid2 size={12}>
          <Controller
            control={form.control}
            name="date"
            render={({ field }) => (
              <FormControl variant="standard" fullWidth>
                <GKDatePicker
                  {...field}
                  onChange={(e) => {
                    field.onChange(dayjs(e).format("YYYY-MM-DD"));
                  }}
                  value={dayjs(field.value)}
                  inputLabel="Model Portfolio start date"
                  disableFuture={false}
                />
              </FormControl>
            )}
          />
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default UpdateRebalanceDate;
