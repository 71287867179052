/* eslint-disable no-debugger */
import { LoadingButton } from "@mui/lab";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  Box,
  Button,
  GKTypography,
  Grid2,
  IoIosArrowBack,
  IoIosArrowForward,
  Slide,
  Typography,
} from "components";
import { useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { paletteLightTheme } from "utils/ColorTheme";
import { ThemeContainer } from "utils/Theme";

interface Answer {
  question: number;
  option: number;
  optionPoints: number;
}

const ExternalRiskProfile = () => {
  const { search } = useLocation();

  const { theme } = ThemeContainer();

  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [checked, setChecked] = useState(true);
  const [answers, setAnswers] = useState<Answer[]>([]);

  const token = new URLSearchParams(search).get("token");

  const advisorId = new URLSearchParams(search).get("advisor_id");

  const clientId = new URLSearchParams(search).get("client_id");

  const [isError, setIsError] = useState(false);

  const { data } = useQuery({
    queryKey: ["GET_CLIENT_RISK_PROFILE_QUESTION"],
    queryFn: () =>
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/riskprofile-request/questionaire/?advisor_id=${advisorId}&client_id=${clientId}`,
        {
          headers: {
            Authorization: `Token ${token}`,
            "x-gridkey-user-role": "client",
          },
        },
      ),
    onError: () => {
      setIsError(true);
    },
  });

  // ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*
  const { mutate: riskProfileResponse, isLoading: postLoading } = useMutation({
    mutationKey: ["RISK_PROFILE_QUESTION"],
    mutationFn: (data: any) =>
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/riskprofile-request/${clientId}/questionaire-response/`,
        data,
        {
          headers: {
            Authorization: `Token ${token}`,
            "x-gridkey-user-role": "client",
          },
        },
      ),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      setTimeout(() => {
        window.close();
      }, 3000);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  // XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===
  const current = data?.data?.data?.riskQuestions[currentQuestion];
  // XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===
  const handleNext = (option: any) => {
    const isOptionExist =
      answers?.filter((d) => d?.question === current?.id)?.length !== 0;

    setAnswers((prevAnswers) => {
      return isOptionExist
        ? answers?.map((d) => {
            return {
              ...d,
              option: d?.question === current?.id ? option.id : d?.option,
            };
          })
        : [
            ...prevAnswers,
            {
              question: current?.id,
              option: option.id,
              optionPoints: option.optionPoints,
            },
          ];
    });

    setChecked(false);

    if (currentQuestion + 1 === data?.data?.data?.riskQuestions?.length) {
      setCurrentQuestion((prev) => prev);
      setChecked(true);
    } else {
      setTimeout(() => {
        setChecked(true);
        setCurrentQuestion((prev) => prev + 1);
      }, 300);
    }
  };
  // XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===XXX===
  const riskAnswers = answers[currentQuestion];

  return isError ? (
    <Grid2 container p={1} height={`100vh`} width={`100vw`} overflow={"auto"}>
      <Grid2
        p={1}
        size={12}
        container
        borderRadius={2}
        maxHeight={`calc(100vh - 8px)`}
        maxWidth={`calc(100vw - 8px)`}
        border={`solid 1px ${paletteLightTheme.palette.border.main}`}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <img
          src={data?.data?.data?.companyLogo || "LandingPage/newLogo.svg"}
          alt=""
          height={"auto"}
          width={"80px"}
        />
        <Typography>
          You have already submitted the questionnaire. Thank you!
        </Typography>
      </Grid2>
    </Grid2>
  ) : (
    <Grid2
      container
      p={1}
      height={`100vh`}
      width={`100vw`}
      justifyContent={"center"}
      alignItems={"center"}
      overflow={"auto"}
    >
      <Grid2
        p={3}
        size={12}
        container
        borderRadius={2}
        maxHeight={`calc(100vh - 8px)`}
        sx={{
          maxWidth: {
            sm: "50%",
            xs: "100%",
          },
        }}
      >
        <Grid2
          size={12}
          display={"flex"}
          alignItems={"center"}
          gap={1}
          flexDirection={"column"}
        >
          <img
            src={data?.data?.data?.companyLogo || "Logos/iconLogo.png"}
            alt=""
            height={"auto"}
            width={"100px"}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: {
                xs: "2px",
                sm: "16px",
              },
              marginBottom: "16px",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            <Typography fontWeight={400} fontSize={12} color={"#999"}>
              Advisor Name:
              <span
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  color: theme.palette.dark.main,
                  marginLeft: 4,
                }}
              >
                {data?.data?.data?.advisorName || "advisor name"}
              </span>
            </Typography>
            <Typography fontWeight={400} fontSize={12} color={"#999"}>
              Client Name:
              <span
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  color: theme.palette.dark.main,
                  marginLeft: 4,
                }}
              >
                {data?.data?.data?.clientName || "Client name"}
                {" - "}
                {data?.data?.data?.clientCode || "0000000"}
              </span>
            </Typography>
          </Box>
        </Grid2>
        <Grid2
          size={12}
          borderTop={`solid 1px ${paletteLightTheme.palette.border.main}`}
          pt={2}
        >
          <Grid2 container spacing={1}>
            <Grid2 size={12}>
              {currentQuestion < data?.data?.data?.riskQuestions.length && (
                <Slide direction="left" in={checked} mountOnEnter unmountOnExit>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      gap: 16,
                      overflow: "none",
                    }}
                  >
                    <GKTypography
                      fontSize={16}
                      color={"#0D121C"}
                      textAlign={"center"}
                    >
                      {currentQuestion + 1}. {current.questionText}
                    </GKTypography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        gap: 8,
                        overflow: "none",
                      }}
                    >
                      {current?.options.map((opt: any, index: any) => (
                        <Button
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "70%",
                            },
                            p: "12px",
                            margin: "auto",
                          }}
                          key={index}
                          onClick={() => handleNext(opt)}
                          color={
                            riskAnswers?.option === +opt.id ? "primary" : "dark"
                          }
                          variant={
                            riskAnswers?.option === +opt.id
                              ? "contained"
                              : "outlined"
                          }
                        >
                          {opt.option}
                        </Button>
                      ))}
                    </div>
                  </div>
                </Slide>
              )}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  marginTop: "24px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 8,
                  }}
                >
                  <Button
                    variant="outlined"
                    color="dark"
                    onClick={() => setCurrentQuestion((p) => p - 1)}
                    disabled={currentQuestion === 0}
                    sx={{
                      minWidth: "1px",
                    }}
                  >
                    <IoIosArrowBack />
                  </Button>
                  <Button
                    variant="outlined"
                    color="dark"
                    onClick={() => setCurrentQuestion((p) => p + 1)}
                    disabled={
                      data?.data?.data?.data?.riskprofileQuestionnaire?.data
                        ?.riskQuestions?.length -
                        1 ===
                      currentQuestion
                    }
                    sx={{
                      minWidth: "1px",
                    }}
                  >
                    <IoIosArrowForward />
                  </Button>
                </div>
                <LoadingButton
                  loading={postLoading}
                  variant="contained"
                  onClick={() => {
                    riskProfileResponse({
                      client: clientId,
                      response: answers,
                    });
                  }}
                >
                  Confirm & Continue
                </LoadingButton>
              </div>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default ExternalRiskProfile;
