import { LoadingButton } from "@mui/lab";
import { GKClientDataGrid, GKModal, Grid2 } from "components";
import { useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { returnReviewHeaders } from "utils/helpers";

interface ViewUploadProps {
  recordData: any;
  recordName: string;
  recordFormat: string;
  recordID: number;
}

const UploadManagerViewUpload = (props: ViewUploadProps & GKModalProps) => {
  const { open, setOpen, recordData, recordName, recordFormat, recordID } =
    props;

  const hasErrors =
    recordData?.filter((dataItem: any) => dataItem?.error)?.length !== 0;

  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  const [isDownloadErrorLoading, setIsDownloadErrorLoading] = useState(false);

  const downloadRecordErrors = async (id: number) => {
    const exactFileName = recordName.split(".csv")[0];
    setIsDownloadErrorLoading(true);
    await axiosInstance
      ?.get(`/transaction_upload/${id}/download_errors/?full=false`)
      .then((response: any) => {
        setIsDownloadErrorLoading(false);
        const a = document.createElement("a");
        a.href = window?.URL?.createObjectURL(new Blob([response.data]));
        a.download = `${exactFileName}.csv`;
        a.click();
        a.remove();
      })
      .catch((response: any) => {
        setIsDownloadErrorLoading(false);
        toast.error(
          response?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      });
  };

  const downloadRecord = async (id: number) => {
    const exactFileName = recordName.split(".csv")[0];
    setIsDownloadLoading(true);

    await axiosInstance
      ?.get(`/transaction_upload/${id}/download_errors/?full=true`)
      .then((response: any) => {
        setIsDownloadLoading(false);
        const a = document.createElement("a");
        a.href = window?.URL?.createObjectURL(new Blob([response.data]));
        a.download = `${exactFileName}.csv`;
        a.click();
        a.remove();
      })
      .catch((response: any) => {
        setIsDownloadLoading(false);
        toast.error(
          response?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      });
  };

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="View Upload Details"
      width={"70%"}
      footer={
        <Grid2 container justifyContent={"flex-end"} gap={1}>
          {hasErrors && (
            <LoadingButton
              variant="outlined"
              color="error"
              onClick={() => downloadRecordErrors(recordID)}
              disabled={recordData?.length === 0 || isDownloadErrorLoading}
              loading={isDownloadErrorLoading}
              size="medium"
            >
              {"Download Error Sheet"}
            </LoadingButton>
          )}
          <LoadingButton
            variant="contained"
            onClick={() => downloadRecord(recordID)}
            disabled={recordData?.length === 0 || isDownloadLoading}
            loading={isDownloadLoading}
            size="medium"
          >
            {"Download Sheet"}
          </LoadingButton>
        </Grid2>
      }
    >
      <GKClientDataGrid
        name="uploadManagerViewUpload"
        columns={returnReviewHeaders(recordFormat)}
        maxHeight={"calc(100vh - 400px)"}
        density="compact"
        initialState={{
          columns: {
            columnVisibilityModel: {
              stt: false,
              gst: false,
              stampCharges: false,
              otherCharges: false,
              transactionCharges: false,
              sebiTurnoverFees: false,
            },
          },
        }}
        rows={
          recordData?.map((d: any, index: number) => {
            return {
              ...d,
              id: index,
            };
          }) || []
        }
      />
    </GKModal>
  );
};

export default UploadManagerViewUpload;
