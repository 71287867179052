import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import {
  GKModal,
  GoCheckCircle,
  Grid2,
  MdReportGmailerrorred,
  Step,
  StepLabel,
  Stepper,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { some } from "lodash";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { paletteLightTheme } from "utils/ColorTheme";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";

const InvestorSettingsConfirmation = (
  props: GKModalProps & { investorData: any },
) => {
  const { open, setOpen, investorData } = props;

  const {
    state: { userData },
  } = useAppContext();

  const { data } = useFetchQuery({
    key: ["GET_CHECK_INVESTOR_DATA"],
    route: `/company/${userData?.companyId}/publish_settings/verify/`,
  });

  const steps = [
    {
      label: "Investor Settings",
      isCompleted: data?.data?.isEquityBasket,
    },
    {
      label: "Equity Basket",
      isCompleted: data?.data?.isEquityBasket,
    },
    {
      label: "Risk Profile",
      isCompleted: data?.data?.hasRiskQuestions,
    },
    {
      label: "Aggrement Settings",
      isCompleted: data?.data?.agreementGenerated,
    },
    {
      label: "Mandate",
      isCompleted: data?.data?.hasMandate,
    },
  ];

  const { mutate: handlePubish, isLoading: pubishLoading } = useMutation({
    mutationKey: ["HANDLE_PUBLISH_INVESTOR_WEBSITe"],
    mutationFn: () =>
      axiosInstance.post(`/company/${userData?.companyId}/publish_website/`),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery("GET_INVESTOR_PROFILE");
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.data?.message || "Something went wrong, please try again.",
      );
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Settings Confirmation"
      width={"30%"}
      footer={
        <LoadingButton
          loading={pubishLoading}
          variant={investorData?.published ? "outlined" : "contained"}
          color={investorData?.published ? "error" : "primary"}
          disabled={some(data?.data, (value) => value === false)}
          onClick={() => handlePubish()}
        >
          {investorData?.published ? "Unpublish Website" : "Publish Website"}
        </LoadingButton>
      }
    >
      <Grid2 container>
        <Grid2
          size={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Stepper activeStep={0} orientation="vertical">
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel
                  error={!step.isCompleted}
                  sx={{
                    "& .MuiStepLabel-label": {
                      color: paletteLightTheme.palette.dark.main,
                    },
                  }}
                  slots={{
                    stepIcon: () => (
                      <div>
                        {!step.isCompleted && (
                          <MdReportGmailerrorred
                            color={paletteLightTheme.palette.error.main}
                            size={26}
                          />
                        )}
                        {step.isCompleted && (
                          <GoCheckCircle
                            color={paletteLightTheme.palette.success.main}
                            size={24}
                          />
                        )}
                      </div>
                    ),
                  }}
                >
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default InvestorSettingsConfirmation;
