import { useAppContext } from "AppContext";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AiOutlinePlus,
  CgMathPlus,
  FaCircleCheck,
  FiChevronDown,
  FiChevronUp,
  GKButton,
  GKStaticProgress,
  GoDash,
  Grid2,
  IconButton,
  styled,
  Typography,
} from "components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { paletteLightTheme } from "utils/ColorTheme";
import { handleOnboardingCalculation } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";

const ParentAccordion = styled(Accordion)(({ theme }) => {
  return {
    borderRadius: "14px !important",
    boxShadow: "none",
    border: `1px solid #E1E8F1`,
    "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 10,
      color: theme.palette.dark.main,
    },
    "& .MuiAccordionDetails-root": {
      padding: 0,
      "& .MuiPaper-root:before": {
        backgroundColor: "transparent",
      },
    },
  };
});

const ChildAccordion = styled(Accordion)(() => {
  return {
    boxShadow: "none",
    padding: 0,
    border: `0px solid #E1E8F1`,
    "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 10,
      color: paletteLightTheme.palette.primary.main,
      background: "#F2F7F9",
      padding: "8px 24px",
    },
    "& .MuiButtonBase-root.MuiAccordionSummary-root": {
      padding: "8px 24px",
    },
    "& .MuiAccordionDetails-root": {
      background: "#F2F7F9",
      padding: "0px 24px 24px 24px",
      "& .MuiPaper-root:before": {
        backgroundColor: "transparent",
      },
    },
  };
});

const OnboardingOrgAccordian = ({ handleMarkAsComplete }: any) => {
  const {
    state: { userData },
    dispatch,
  } = useAppContext();

  const { theme } = ThemeContainer();

  const navigate = useNavigate();

  const [selectedParentAccordian, setSelectedParentAccordian] = useState([0]);

  const [selectedChildAccordian, setSelectedChildAccordian] = useState([0]);

  useDocumentTitle("Company Onboarding");

  return (
    <ParentAccordion
      expanded={selectedParentAccordian?.includes(1)}
      onChange={() => {
        if (selectedParentAccordian?.includes(1)) {
          setSelectedParentAccordian(
            selectedParentAccordian?.filter((e) => e !== 1),
          );
        } else {
          setSelectedParentAccordian([...selectedParentAccordian, 1]);
        }
      }}
    >
      <AccordionSummary
        sx={{
          "& .MuiAccordionSummary-content": {
            display: "flex",
            alignItems: "center",
          },
        }}
      >
        <Grid2 container size={12}>
          <Grid2
            size={6}
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            {selectedParentAccordian?.includes(1) ? (
              <GoDash />
            ) : (
              <AiOutlinePlus />
            )}
            &nbsp;Step 1: Setup Organisation and User Management
          </Grid2>
          <Grid2
            size={6}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={0.5}
          >
            {userData?.profileDetails?.organizationDetails?.companyProfile &&
            userData?.profileDetails?.organizationDetails
              ?.rolesAndPermissions ? (
              <IconButton color="success">
                <FaCircleCheck size={20} />
              </IconButton>
            ) : (
              <GKStaticProgress
                value={
                  userData?.profileDetails &&
                  handleOnboardingCalculation({
                    obj: userData?.profileDetails,
                    objKey: "organizationDetails",
                  }).value
                }
                size={18}
              />
            )}
            <Typography>
              {userData?.profileDetails &&
                handleOnboardingCalculation({
                  obj: userData?.profileDetails,
                  objKey: "organizationDetails",
                }).str}
            </Typography>
          </Grid2>
        </Grid2>
      </AccordionSummary>
      <AccordionDetails>
        <ChildAccordion
          sx={{
            borderBottom: `1px solid #E1E8F1`,
          }}
          expanded={selectedChildAccordian?.includes(0)}
          onChange={() => {
            if (selectedChildAccordian?.includes(0)) {
              setSelectedChildAccordian(
                selectedChildAccordian?.filter((e) => e !== 0),
              );
            } else {
              setSelectedChildAccordian([...selectedChildAccordian, 0]);
            }
          }}
        >
          <AccordionSummary
            sx={{
              "& .MuiAccordionSummary-content": {
                display: "flex",
                alignItems: "center",
              },
            }}
          >
            <Grid2 container alignItems={"center"} size={12}>
              <Grid2
                size={8}
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                {selectedChildAccordian?.includes(0) ? (
                  <FiChevronUp />
                ) : (
                  <FiChevronDown />
                )}
                &nbsp;Complete your Company Profile
              </Grid2>
              <Grid2
                size={4}
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                {!userData?.profileDetails?.organizationDetails
                  ?.companyProfile && (
                  <GKButton
                    variant="link"
                    sx={{ textTransform: "none" }}
                    color="primary"
                    onClick={() => {
                      handleMarkAsComplete("company_profile");
                    }}
                  >
                    Mark as Complete
                  </GKButton>
                )}
              </Grid2>
            </Grid2>
          </AccordionSummary>
          <AccordionDetails>
            <Grid2 container spacing={1}>
              <Grid2 size={2}>
                <GKButton sx={{ p: 0, display: "contents" }}>
                  <img
                    height={"99%"}
                    width={"99%"}
                    style={{
                      borderRadius: 16,
                    }}
                    alt=""
                    src="/Logos/comingSoon.svg"
                    draggable="false"
                  />
                </GKButton>
              </Grid2>
              <Grid2 size={10} container spacing={1}>
                <Grid2
                  size={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  flexDirection={"column"}
                >
                  <Typography
                    fontWeight={600}
                    fontSize={14}
                    color={theme.palette.secondaryText.main}
                  >
                    Complete your company profile with essential details.{" "}
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize={12}
                    color={theme.palette.secondaryText.main}
                  >
                    Pro Tip: Ensure all fields are accurately filled to
                    personalize your experience.
                  </Typography>
                </Grid2>
                <Grid2 size={12}>
                  <GKButton
                    variant="contained"
                    size="extraSmall"
                    onClick={() => {
                      navigate(`/app/user-profile/settings/`);
                      dispatch({
                        type: "SETTING_TAB",
                        data: {
                          name: "Company Profile",
                          type: "COMPANY_PROFILE",
                        },
                      });
                    }}
                  >
                    Complete Company Profile
                  </GKButton>
                </Grid2>
              </Grid2>
            </Grid2>
          </AccordionDetails>
        </ChildAccordion>
        <ChildAccordion
          sx={{
            borderRadius: "14px !important",
          }}
          expanded={selectedChildAccordian?.includes(1)}
          onChange={() => {
            if (selectedChildAccordian?.includes(1)) {
              setSelectedChildAccordian(
                selectedChildAccordian?.filter((e) => e !== 1),
              );
            } else {
              setSelectedChildAccordian([...selectedChildAccordian, 1]);
            }
          }}
        >
          <AccordionSummary
            sx={{
              "& .MuiAccordionSummary-content": {
                display: "flex",
                alignItems: "center",
              },
            }}
          >
            <Grid2 container alignItems={"center"} size={12}>
              <Grid2
                size={8}
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                {selectedChildAccordian?.includes(1) ? (
                  <FiChevronUp />
                ) : (
                  <FiChevronDown />
                )}
                &nbsp;Create Users and Setup Roles & Permissions
              </Grid2>
              <Grid2
                size={4}
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                {!userData?.profileDetails?.organizationDetails
                  ?.rolesAndPermissions && (
                  <GKButton
                    variant="link"
                    sx={{ textTransform: "none" }}
                    color="primary"
                    onClick={() => {
                      handleMarkAsComplete("roles_and_permissions");
                    }}
                  >
                    Mark as Complete
                  </GKButton>
                )}
              </Grid2>
            </Grid2>
          </AccordionSummary>
          <AccordionDetails>
            <Grid2 container spacing={1}>
              <Grid2 size={2}>
                <GKButton sx={{ p: 0, display: "contents" }}>
                  <img
                    height={"99%"}
                    width={"99%"}
                    style={{
                      borderRadius: 16,
                    }}
                    alt=""
                    src="/Logos/comingSoon.svg"
                    draggable="false"
                  />
                </GKButton>
              </Grid2>
              <Grid2 size={10} container spacing={1}>
                <Grid2
                  size={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  flexDirection={"column"}
                >
                  <Typography
                    fontWeight={600}
                    fontSize={14}
                    color={theme.palette.secondaryText.main}
                  >
                    Create Roles and Add admins, assigning specific permissions
                    to each.{" "}
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize={12}
                    color={theme.palette.secondaryText.main}
                  >
                    Pro Tip: Tailor roles to match your team structure for
                    seamless management.
                  </Typography>
                </Grid2>
                <Grid2
                  size={12}
                  style={{ display: "flex", alignItems: "center", gap: 8 }}
                >
                  <GKButton
                    variant="contained"
                    size="small"
                    onClick={() => {
                      navigate(`/app/admin`);
                    }}
                  >
                    <CgMathPlus size={14} />
                    &nbsp;Add Admins
                  </GKButton>
                  <GKButton
                    variant="outlined"
                    color="dark"
                    size="small"
                    onClick={() => {
                      dispatch({
                        type: "SETTING_TAB",
                        data: {
                          name: "Employee",
                          type: "EMPLOYEE",
                        },
                      });
                      navigate(`/app/user-profile/settings`);
                    }}
                  >
                    Create Role
                  </GKButton>
                </Grid2>
              </Grid2>
            </Grid2>
          </AccordionDetails>
        </ChildAccordion>
      </AccordionDetails>
    </ParentAccordion>
  );
};

export default OnboardingOrgAccordian;
