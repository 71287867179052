import { useAppContext } from "AppContext";
import {
  Button,
  Card,
  CgMathPlus,
  GKTextField,
  Grid2,
  NoData,
  PiTagBold,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import EquityBasketCard from "./EquityBasketCard";

const EquityBasket = () => {
  const navigate = useNavigate();

  useDocumentTitle("Basket");

  const {
    state: { tab },
    dispatch,
  } = useAppContext();

  const [searchText, setSearchText] = useState("");

  const { data, isLoading: isFetching } = useFetchQuery({
    key: [QueryKeys.MODEL_PORTFOLIO_LIST],
    route: "/model_portfolio/summary/",
  });

  return (
    <DashboardLayout title="Basket" loading={isFetching}>
      <Grid2 container spacing={3}>
        <Grid2 size={12}>
          <Grid2 container spacing={1} mt={1}>
            <Grid2 size={6}>
              <GKTextField
                placeholder="Search..."
                style={{
                  width: "40%",
                }}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </Grid2>
            <Grid2
              size={6}
              justifyContent={"flex-end"}
              alignItems={"center"}
              display={"flex"}
              gap={1}
            >
              <Button
                variant="contained"
                size="medium"
                onClick={() => {
                  dispatch({
                    type: "TAB",
                    data: { ...tab, modelPortfolioTabs: 0 },
                  });
                  navigate("/app/equity-basket/form");
                }}
                startIcon={<CgMathPlus size={14} />}
              >
                Create Basket
              </Button>
              <Button
                variant="outlined"
                color="dark"
                size="medium"
                onClick={() => {
                  navigate("/app/equity-basket/orders");
                }}
                startIcon={<PiTagBold size={14} />}
              >
                Total Orders
              </Button>
            </Grid2>
          </Grid2>
        </Grid2>

        <Grid2
          size={12}
          style={{
            maxHeight: "calc(100vh - 144px)",
            overflow: "auto",
            borderRadius: 10,
          }}
        >
          {data?.data?.length !== 0 ? (
            <Grid2 container spacing={3}>
              {data?.data
                ?.filter((dataItems: any) =>
                  dataItems?.name
                    ?.toLowerCase()
                    ?.includes(searchText?.toLowerCase()),
                )
                ?.map((item: any, index: number) => (
                  <Grid2 size={12} key={item?.id}>
                    <EquityBasketCard
                      key={index}
                      id={item?.id}
                      modelPortfolio={item}
                      isFetching={isFetching}
                    />
                  </Grid2>
                ))}{" "}
            </Grid2>
          ) : (
            <Grid2 container>
              <Grid2 size={12}>
                <Card
                  style={{
                    height: "100%",
                  }}
                >
                  <NoData text="No equity basket found." />
                </Card>
              </Grid2>
            </Grid2>
          )}
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default EquityBasket;
