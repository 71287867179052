/* eslint-disable react/no-danger */
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import {
  AiOutlineEdit,
  ConfirmDeleteModal,
  Divider,
  FiTrash2,
  FormControl,
  GKButton,
  GKDatePicker,
  GKModal,
  GKSearchSelect,
  GKTextField,
  GKTimePicker,
  Grid2,
  Show,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "components";
import GKEditor from "components/GKEditor";
import dayjs, { Dayjs } from "dayjs";
import { Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { UserRoleTypes } from "utils/common";
import { GKModalProps } from "utils/commonInterface";
import { matchDate, refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import { array, number, object, string } from "yup";
import useAdviceRegister from "./useAdviceRegister";

interface Props {
  portfolioId?: any;
  clientId?: any;
  noteDetails?: any;
}

type FormData = {
  client?: number;
  title?: string;
  noteTypes?: string[];
  content?: string;
  portfolios?: number[];
  dateTime?: Dayjs;
  date?: any;
  time?: Dayjs;
};

const NoteModal = (props: Props & GKModalProps) => {
  const { pathname } = useLocation();

  const {
    state: { userData, clientList },
  } = useAppContext();

  const { theme } = ThemeContainer();

  const { open, setOpen, noteDetails = {}, clientId, portfolioId } = props;

  const [notedId, setNotedId] = useState();

  const [portfolioList, setPortfolioList] = useState([]);

  const [confirmationModal, setConfirmationModal] = useState(false);

  const [editNote, setEditNote] = useState(!noteDetails?.id);

  const { getNoteTypeList, noteTypesLoading } = useAdviceRegister();

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const { mutate: handleDeleteNote } = useMutation({
    mutationKey: ["DELETE_NOTES"],
    mutationFn: () =>
      axiosInstance.delete(`/advice-register-notes/${notedId}/`),
    onSuccess: (response) => {
      setOpen(false);
      if (pathname.includes("/app/advice-register-notes")) {
        refetchQuery(QueryKeys.GET_ALL_NOTES_LISTS);
      } else {
        refetchQuery("CLIENT_NOTES_LISTS");
      }
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const { mutate: handleCreateNote, isLoading: createLoading } = useMutation({
    mutationKey: ["CREATE_NOTE"],
    mutationFn: (data) => axiosInstance.post("/advice-register-notes/", data),
    onSuccess: (response) => {
      if (pathname.includes("/app/advice-register-notes")) {
        refetchQuery(QueryKeys.GET_ALL_NOTES_LISTS);
      } else {
        refetchQuery("CLIENT_NOTES_LISTS");
      }
      refetchQuery("PORTFOLIO_NOTES_LISTS");
      setOpen(false);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const { mutate: handleUpdateNote, isLoading: updateLoading } = useMutation({
    mutationKey: ["UPDATE_NOTE"],
    mutationFn: (data) =>
      axiosInstance.patch(`/advice-register-notes/${noteDetails?.id}/`, data),
    onSuccess: (response) => {
      if (pathname.includes("/app/advice-register-notes")) {
        refetchQuery(QueryKeys.GET_ALL_NOTES_LISTS);
      } else {
        refetchQuery("ADVISE_REGISTER_LIST");
        refetchQuery("CLIENT_NOTES_LISTS");
      }
      refetchQuery("PORTFOLIO_NOTES_LISTS");
      setOpen(false);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const validationSchema = object().shape({
    title: string()
      .required("Please enter title")
      .test("no-empty-spaces", "Field cannot contain only spaces", (value) => {
        return value && value.trim().length > 0;
      }),
    noteTypes: array()
      .min(1, "Please select at least 1 items")
      .required("Please select type"),
    date: string()
      .required("Please select date")
      .test("is-valid-date", "Invalid date", (value) => {
        return dayjs(value).isValid();
      }),
    client: number().required("Please select client"),
    content: string().required("Please enter content"),
  });

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const form = useForm<FormData>({
    defaultValues: {
      client:
        Number(clientId) || noteDetails?.id
          ? clientId || noteDetails.client?.id
          : undefined,
      title: noteDetails?.id ? noteDetails?.title : "",
      noteTypes: noteDetails?.id ? noteDetails?.noteTypes : [],
      content: noteDetails?.id ? noteDetails?.content : "",
      portfolios: Number(portfolioId)
        ? [Number(portfolioId)]
        : noteDetails?.id
          ? noteDetails?.portfolios
          : [],
      dateTime: dayjs(),
      date: noteDetails?.id ? dayjs(noteDetails?.date) : dayjs(),
      time: noteDetails?.id
        ? dayjs(noteDetails.time, "HH:mm")
        : dayjs().format("HH:mm:ss"),
    },
    resolver: yupResolver(validationSchema),
  });

  form.watch("client");

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const { data: clientsByIdData } = useQuery({
    queryKey: ["CLIENT_BY_PORTFOLIO_ID", portfolioId],
    queryFn: () =>
      axiosInstance.get(`/portfolio-based-client/?portfolio_id=${portfolioId}`),
    onSuccess: (data) => {
      form.setValue("client", data?.data?.data[0]?.id);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
    enabled:
      Boolean(portfolioId) && pathname.includes("app/holdings/portfolio/"),
  });

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const { isFetching: clientPortfolioFetching } = useQuery({
    queryKey: ["CLIENT_PORTFOLIO", clientId, form.getValues("client")],
    queryFn: () =>
      axiosInstance.get(
        `/client/${form.getValues("client") || clientId}/portfolios/`,
      ),
    enabled: Boolean(clientId) || !!form.getValues("client"),
    onSuccess: (data) => {
      setPortfolioList(
        data?.data?.data?.map((dataItem: any) => ({
          ...dataItem,
          name: `${dataItem.name} - ${dataItem.portfolioId}`,
          value: dataItem.id,
        })),
      );
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const SkeletonLoader = (length: number) => {
    const skeletonItems = [];

    for (let index = 0; index < length; index++) {
      skeletonItems.push(
        <Grid2 size={12} key={index}>
          <Skeleton animation="wave" variant="rounded" height={"60px"} />
        </Grid2>,
      );
    }

    return skeletonItems;
  };

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const noteList = getNoteTypeList?.data?.data;

  // ===###===###===###===###===###===###===###===###===###===###===###===###===###===###
  const returnNoteTitle = () => {
    if (noteDetails?.id && editNote) {
      return "Edit Note";
    }
    if (noteDetails?.id && !editNote) {
      return "Preview";
    }
    return "Add New Note";
  };

  return (
    <>
      <GKModal
        modalTitle={returnNoteTitle()}
        open={open}
        setOpen={setOpen}
        width={"70%"}
        footer={
          <Grid2 container justifyContent={"flex-end"} gap={1}>
            <Show.When isTrue={editNote && noteDetails?.id}>
              <GKButton
                type="submit"
                variant="outlined"
                size="medium"
                color="dark"
                onClick={() => {
                  setEditNote(false);
                }}
              >
                Cancel
              </GKButton>
            </Show.When>
            <Show.When isTrue={noteDetails?.id}>
              <GKButton
                variant="outlined"
                size="medium"
                color="error"
                onClick={() => {
                  setNotedId(noteDetails.id);
                  setConfirmationModal(true);
                }}
                startIcon={<FiTrash2 size={18} />}
              >
                Delete Note
              </GKButton>
            </Show.When>
            <Show.When
              isTrue={!editNote && userData?.userRole !== UserRoleTypes.client}
            >
              <GKButton
                variant="outlined"
                size="medium"
                color="dark"
                onClick={() => {
                  setEditNote(true);
                }}
                startIcon={<AiOutlineEdit size={18} />}
              >
                Edit Note
              </GKButton>
            </Show.When>
            <Show.When
              isTrue={editNote && userData?.userRole !== UserRoleTypes.client}
            >
              <LoadingButton
                loading={createLoading || updateLoading}
                type="submit"
                variant="contained"
                size="medium"
                form="notes-form"
              >
                {noteDetails?.id ? "Update" : "Save"}
              </LoadingButton>
            </Show.When>
          </Grid2>
        }
      >
        {editNote ? (
          <form
            onSubmit={form.handleSubmit((values: any) => {
              if (noteDetails?.id) {
                handleUpdateNote(values);
              } else {
                handleCreateNote(values);
              }
            })}
            id="notes-form"
          >
            {noteTypesLoading ? (
              <Grid2 container spacing={1} my={2}>
                {SkeletonLoader(4)}
              </Grid2>
            ) : (
              <Grid2 container spacing={1}>
                <Grid2 size={12}>
                  <Controller
                    name="title"
                    control={form.control}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl fullWidth variant="standard">
                        <GKTextField
                          {...field}
                          inputLabel="Title"
                          requiredField
                          error={!!error}
                          helperText={error?.message}
                        />
                      </FormControl>
                    )}
                  />
                </Grid2>
                <Grid2 size={12}>
                  <Controller
                    name="noteTypes"
                    control={form.control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <Grid2 container>
                          <Grid2 size={12}>
                            <Typography>
                              Select Note Type
                              <span
                                style={{
                                  color: theme.palette.error.main,
                                }}
                              >
                                *
                              </span>
                            </Typography>
                          </Grid2>
                          <Grid2 size={12} display={"flex"} gap={1}>
                            {noteList?.map((item: any) => {
                              return (
                                <GKButton
                                  key={item.id}
                                  sx={{ borderColor: error ? "#e84141" : "" }}
                                  variant={
                                    field.value.includes(item.id)
                                      ? "contained"
                                      : "outlined"
                                  }
                                  size="small"
                                  onClick={() => {
                                    if (field.value.includes(item.id)) {
                                      field.onChange(
                                        field.value.filter(
                                          (val: any) => val !== item.id,
                                        ),
                                      );
                                    } else {
                                      field.onChange([...field.value, item.id]);
                                    }
                                  }}
                                >
                                  {item.name || "-"}
                                </GKButton>
                              );
                            })}
                          </Grid2>
                          <Grid2 size={12}>
                            <Typography
                              fontSize={10}
                              color={"#e84141"}
                              mt={"2px"}
                            >
                              {!!error && error?.message}
                            </Typography>
                          </Grid2>
                        </Grid2>
                      );
                    }}
                  />
                </Grid2>
                <Grid2 size={6}>
                  <FormControl fullWidth variant="standard">
                    <Controller
                      name="date"
                      control={form.control}
                      render={({ field, fieldState: { error } }) => (
                        <GKDatePicker
                          {...field}
                          inputLabel="Date"
                          value={dayjs(field.value)}
                          onChange={(value) =>
                            field.onChange(dayjs(value).format("YYYY-MM-DD"))
                          }
                          disableFuture
                          maxDate={dayjs()}
                          requiredField
                          slotProps={{
                            textField: {
                              error: !!error,
                              helperText: error?.message,
                            },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid2>
                <Grid2 size={6}>
                  <FormControl fullWidth variant="standard">
                    <Controller
                      name="time"
                      control={form.control}
                      render={({ field }) => (
                        <GKTimePicker
                          {...field}
                          inputLabel="Time"
                          value={dayjs(field.value)}
                          onChange={(value) =>
                            field.onChange(
                              dayjs(value, "hh:mm:ss a").format("HH:mm"),
                            )
                          }
                          disableFuture
                          slotProps={{
                            textField: {
                              error: false,
                              helperText: false,
                            },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid2>
                {!clientId && (
                  <Grid2 size={12}>
                    <Controller
                      name="client"
                      control={form.control}
                      render={({ field, formState: { errors } }) => (
                        <GKSearchSelect
                          {...field}
                          inputLabel="Client"
                          requiredField
                          disabled={!!clientId}
                          disableClearable
                          options={
                            clientId
                              ? clientsByIdData?.data?.data?.map(
                                  (item: any) => ({
                                    name: `${item.name} - ${item.clientCode}`,
                                    value: item.id,
                                  }),
                                )
                              : clientList
                          }
                          onChange={(e, val) => {
                            field.onChange(val?.value);
                          }}
                          value={
                            clientList?.find(
                              (data: any) => data?.id === field.value,
                            )?.name
                          }
                          getOptionLabel={(option) => option.name || option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Client"
                              placeholder={handleReturnAutocompletePlaceholder({
                                placeholder: "Select Client",
                                value: field.value,
                              })}
                              error={!!errors?.client}
                              helperText={errors?.client?.message as string}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid2>
                )}
                {!portfolioId && (
                  <Grid2 size={12} spacing={1}>
                    <Controller
                      name="portfolios"
                      control={form.control}
                      render={({ field }) => (
                        <GKSearchSelect
                          {...field}
                          multiple
                          disableClearable
                          disabled={Boolean(portfolioId)}
                          inputLabel="Portfolio"
                          loading={clientPortfolioFetching}
                          renderOption={(props, option) => {
                            return (
                              <li
                                {...props}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <Typography fontWeight={600}>
                                  {option.clientName}
                                </Typography>
                                <Typography>{option.name}</Typography>
                              </li>
                            );
                          }}
                          options={portfolioList || []}
                          onChange={(e, val) => {
                            const selectedIds = val.map(
                              (option: any) => option.value,
                            );
                            field.onChange(selectedIds);
                          }}
                          value={
                            portfolioList?.filter((option) =>
                              field.value.includes(option.value),
                            ) || []
                          }
                          getOptionLabel={(option) => option.name || option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="portfolio"
                              placeholder={handleReturnAutocompletePlaceholder({
                                placeholder: "Select Portoflio",
                                value: field.value,
                              })}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid2>
                )}

                <Grid2 size={12}>
                  <Controller
                    name="content"
                    control={form.control}
                    render={({ field, fieldState: { error } }) => (
                      <GKEditor
                        value={field.value}
                        onChange={field.onChange}
                        inputLabel="Description"
                        requiredField
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid2>
              </Grid2>
            )}
          </form>
        ) : (
          <Grid2 container spacing={2}>
            <Grid2 size={12} display={"flex"} alignItems={"center"} gap={1}>
              <Tooltip title={noteDetails?.title}>
                <Typography
                  fontWeight={600}
                  fontSize={20}
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  whiteSpace={"nowrap"}
                  maxWidth={"70%"}
                >
                  {noteDetails?.title || "-"}
                </Typography>
              </Tooltip>
              {noteDetails?.noteTypesList?.map((item: any) => {
                return (
                  <div
                    key={item.name}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 4,
                      padding: "2px 6px",
                      fontWeight: 400,
                      fontSize: 12,
                      background: `${item?.color}11`,
                      color: item?.color,
                      textTransform: "capitalize",
                      width: "max-content",
                    }}
                  >
                    {item?.name}
                  </div>
                );
              })}
            </Grid2>
            <Grid2
              size={10}
              display={"flex"}
              alignItems={"center"}
              gap={2}
              justifyContent={"flex-start"}
            >
              <Typography
                fontWeight={400}
                fontSize={12}
                display={"flex"}
                alignItems="center"
                color={theme.palette.secondaryText.main}
              >
                Client Name:&nbsp;
                <Typography color={"#202939"} fontSize={12}>
                  {noteDetails?.client?.clientName || "-"}
                </Typography>
                {" / "}
                <Typography color={"#202939"} fontSize={12}>
                  {noteDetails?.client?.clientCode || "-"}
                </Typography>
              </Typography>

              <Typography
                fontWeight={400}
                fontSize={12}
                display={"flex"}
                color={theme.palette.secondaryText.main}
                alignItems="center"
              >
                Portfolio Name :&nbsp;
                {noteDetails?.portfolioList?.length > 1 && (
                  <Tooltip
                    title={noteDetails?.portfolioList?.map(
                      (data: any, index: any) => {
                        return (
                          <Fragment key={index}>
                            <Typography fontSize={12} m={1}>
                              {index + 1}. {data?.name}
                              {" / "}
                              {data?.portfolioId}
                            </Typography>
                          </Fragment>
                        );
                      },
                    )}
                  >
                    <Typography fontSize={12} color={"#202939"}>
                      {noteDetails?.portfolioList[0]?.name || "-"}
                      {" / "}
                      {noteDetails?.portfolioList[0]?.portfolioId || "-"}
                      {`...   +`}
                      {noteDetails?.portfolioList?.length - 1}
                    </Typography>
                  </Tooltip>
                )}
                {noteDetails?.portfolioList?.length === 1 && (
                  <Typography
                    fontSize={12}
                    key={noteDetails?.id}
                    color={"#202939"}
                  >
                    {noteDetails?.portfolioList[0]?.name || "-"}
                    {" / "}
                    {noteDetails?.portfolioList[0]?.portfolioId || "-"}
                  </Typography>
                )}
              </Typography>
            </Grid2>
            <Grid2 size={2} textAlign={"right"}>
              <Typography
                fontWeight={400}
                fontSize={12}
                color={theme.palette.secondaryText.main}
              >
                {matchDate(noteDetails?.dateTime, "DD MMM YYYY  hh:mm a") ||
                  "-"}
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Divider />
            </Grid2>
            <Grid2 size={12}>
              <div
                dangerouslySetInnerHTML={{
                  __html: noteDetails?.content,
                }}
              />
            </Grid2>
          </Grid2>
        )}
      </GKModal>
      {confirmationModal && (
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onDelete={() => handleDeleteNote()}
          title={"Advice Register Note"}
          isDelete
        />
      )}
    </>
  );
};

export default NoteModal;
