import { GridColDef } from "@mui/x-data-grid-pro";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import {
  CircularProgress,
  GKClientDataGrid,
  Grid2,
  IconButton,
  LuEye,
  Switch,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { PortfolioDetailsInterface } from "interfaces/Portfolio";
import { useState } from "react";
import toast from "react-hot-toast";
import { handleRemoveUnderscore, numberToCurrency } from "utils";
import { GET_PORTFOLIO_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import AddPortfolioModal from "./AddPortfolioModal";
import PortfolioProfileEdit from "./ProfilePages";

interface Props {
  rowData?: any[];
}

const SubscribedPortfolio = ({ rowData }: Props) => {
  useDocumentTitle("Portfolio");

  const {
    state: { commonFilter },
  } = useAppContext();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const [openPortfolioModal, setOpenPortfolioModal] = useState(false);

  const [portfolioData, setPortfolioData] =
    useState<PortfolioDetailsInterface>();

  const [editPortfolioId, setEditPortfolioId] = useState<string | number>();

  const requestBody = {
    requireTotalCount: true,
    take: commonFilter?.subscribedPortfolio?.take,
    skip: commonFilter?.subscribedPortfolio?.skip,
    sort: JSON.stringify(
      commonFilter?.subscribedPortfolio?.sortModel?.map((data: any) => {
        return {
          selector: data.field,
          desc: data.sort !== "asc",
        };
      }),
    ),
    search: commonFilter?.subscribedPortfolio?.search || "",
    show_zero_holding: commonFilter?.subscribedPortfolio?.showNonZero,
    filter: JSON.stringify(commonFilter?.subscribedPortfolio?.customFilter),
    portfolio_type: "subscription",
  };

  const { data, isFetching, refetch } = useFetchQuery({
    key: ["GET_ALL_SUBSCRIBED_PORTFOLIOS", requestBody],
    route: `/ra-subscription-payment/`,
    requestBody,
  });

  useFetchQuery({
    key: ["PORTFOLIO_UPDATE_DATA_BY_ID"],
    route: GET_PORTFOLIO_BY_ID(editPortfolioId),
    enabled: Boolean(editPortfolioId),
    success: (data: any) => {
      setPortfolioData(data?.data);
      setOpenPortfolioModal(true);
    },
  });

  const { mutate: handleActivateSubscription, isLoading: subscriptionLoading } =
    useMutation({
      mutationFn: (id) =>
        axiosInstance.post(`/portfolios/${id}/portfolio-subscription/`),
      onSuccess: (response) => {
        refetch();
        toast.success(
          response?.data?.message || "Action Successfully Performed.",
        );
      },
      onError: (error: any) => {
        refetch();
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Portfolio", "clientName", 250, 1),
      renderCell: (params) => {
        return (
          <Grid2 container justifyContent={"flex-start"}>
            <Grid2 size={12}>
              <Typography
                fontWeight={700}
                maxWidth={"100%"}
                textOverflow={"ellipsis"}
                overflow={"hidden"}
              >
                {params?.row?.clientName}
              </Typography>
              <Typography fontSize={12}>
                {params?.row?.portfolioName} - {params?.row?.portfolioCode}
              </Typography>
            </Grid2>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Basket Name", "basketName", 200, 1),
    },
    {
      ...extraColumnsField("Amount", "withGstAmount", 200, 1),
      valueFormatter: (params) => numberToCurrency(params.value),
    },
    {
      ...extraColumnsField("Execution Sheet", "lastExecutionSheet", 200, 1),
      valueFormatter: (params) => handleRemoveUnderscore(params?.value),
      valueGetter: (row) => row?.row?.lastExecutionSheet || "-",
    },

    {
      ...extraColumnsField("Actions", "action", 80, 1),
      headerAlign: "right",
      align: "right",
      renderCell: (item) => {
        return subscriptionLoading && editPortfolioId === item.row.id ? (
          <CircularProgress size={18} />
        ) : (
          <div>
            <IconButton href={item.row.paymentUpload} target="_blank">
              <LuEye size={20} />
            </IconButton>
            &nbsp;
            <Switch
              disabled={item?.row?.isDone}
              checked={item?.row?.isDone}
              onChange={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleActivateSubscription(item.row.portfolio);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <GKClientDataGrid
        loading={isFetching}
        columns={columns}
        rows={rowData || data?.data?.data || []}
        maxHeight={"calc(100vh - 321px)"}
        name="subscribedPortfolio"
        initialState={{
          columns: {
            columnVisibilityModel: {
              lastOrderDate: true,
              lastOrderVersion: false,
              lastOrderTemplate: false,
              lastOrderType: false,
              lastExecutionSheet: false,
            },
          },
        }}
      />

      {openPortfolioModal && (
        <PortfolioProfileEdit
          open={openPortfolioModal}
          setOpen={setOpenPortfolioModal}
          portfolioData={portfolioData}
          portfolioId={editPortfolioId}
          setPortfolioId={setEditPortfolioId}
        />
      )}

      {isCreateModalOpen && (
        <AddPortfolioModal
          setOpen={setIsCreateModalOpen}
          open={isCreateModalOpen}
        />
      )}
    </>
  );
};

export default SubscribedPortfolio;
