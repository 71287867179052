import { useAppContext } from "AppContext";
import { Grid2, Typography, styled } from "components";
import dayjs from "dayjs";
import { kycStatus } from "utils/constants";
import { AdviceRegisterColor } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";

interface Props {
  dataItem: any;
  index: number;
}
const CustomContainer = styled(Grid2)(() => () => ({
  padding: "16px",
  borderRadius: 10,
  border: "1px solid #E0E0E0",
  marginBottom: 4,
  "&:hover": {
    cursor: "pointer",
  },
}));

const KYCAdviceRegister = (props: Props) => {
  const { dataItem, index } = props;
  const { theme } = ThemeContainer();

  const {
    state: { tab },
    dispatch,
  } = useAppContext();

  return (
    <CustomContainer
      key={index}
      position={"relative"}
      size={12}
      onClick={() => {
        dispatch({
          type: "TAB",
          data: { ...tab, complianceTabs: 0 },
        });
      }}
    >
      <Grid2 container spacing={1}>
        <Grid2 size={6} display={"flex"}>
          <Typography
            fontWeight={600}
            fontSize={12}
            p={"4px"}
            textTransform={"capitalize"}
            borderRadius={1}
            color={AdviceRegisterColor[dataItem?.type]}
            bgcolor={`${AdviceRegisterColor[dataItem?.type]}22`}
          >
            {dataItem?.type}
          </Typography>
        </Grid2>
        <Grid2
          size={10}
          display={"flex"}
          alignItems={"center"}
          gap={2}
          justifyContent={"flex-start"}
        >
          <Typography
            fontWeight={500}
            fontSize={12}
            display={"flex"}
            alignItems="center"
          >
            KYC Id :&nbsp;
            <Typography fontWeight={700} fontSize={12}>
              {dataItem?.data?.kycId}
            </Typography>
          </Typography>
          <Typography
            fontWeight={500}
            fontSize={12}
            display={"flex"}
            alignItems="center"
          >
            KYC Status :&nbsp;
            <Typography fontWeight={700} fontSize={12}>
              {dataItem?.data?.kycStatus}
            </Typography>
          </Typography>
          <Typography
            fontWeight={500}
            fontSize={12}
            display={"flex"}
            alignItems="center"
          >
            Identification Type :&nbsp;
            <Typography fontWeight={700} fontSize={12}>
              {kycStatus(dataItem?.data?.identificationType)}
            </Typography>
          </Typography>
        </Grid2>
        <Grid2
          size={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Typography
            fontWeight={400}
            fontSize={12}
            color={theme.palette.secondaryText.main}
          >
            {dayjs(dataItem?.data?.createdAt).format("DD MMM YYYY  hh:mm a") ||
              "-"}
          </Typography>
        </Grid2>
      </Grid2>
    </CustomContainer>
  );
};

export default KYCAdviceRegister;
