import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import {
  FormControl,
  FormControlLabel,
  GKDatePicker,
  GKSearchSelect,
  GKTextField,
  Grid2,
  TextField,
} from "components";
import GKCheckbox from "components/GKCheckbox";
import dayjs from "dayjs";
import { PortfolioDetailsInterface } from "interfaces/Portfolio";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import useDocumentTitle from "utils/useDocumentTitle";

const brokerList = [
  { name: "Latin Manharlal", value: true },
  { name: "Non Latin Manharlal", value: false },
];

const portfolioType = [
  { name: "Advisory", value: "Advisory" },
  { name: "Subscription", value: "Subscription" },
  { name: "Mutual fund distributor", value: "Tracking " },
];

interface Props {
  portfolioDetailData: PortfolioDetailsInterface;
}

const EditPortfolio = (props: Props) => {
  const { portfolioDetailData } = props;

  useDocumentTitle("Create Portfolio");

  const {
    state: {
      clientList,
      userData: { broker },
    },
  } = useAppContext();

  const form = useForm({
    defaultValues: {
      portfolioType: portfolioDetailData?.portfolioType || "",
      stockAsCapital: portfolioDetailData?.stockAsCapital || false,
      isLm: portfolioDetailData?.isLm || false,
      name: portfolioDetailData?.name || "",
      committedValue: portfolioDetailData?.committedValue || 0,
      portfolioId: portfolioDetailData?.portfolioId || "",
      client: portfolioDetailData?.client || "",
      anchorDate: portfolioDetailData?.anchorDate || "",
      // familyId: portfolioDetailData?.familyId || null,
    },
  });

  const { mutate: handleUpdatePortfolio, isLoading } = useMutation({
    mutationFn: (values) =>
      axiosInstance.patch(`/portfolios/${portfolioDetailData?.id}/`, values),
    onSuccess: (response) => {
      refetchQuery("GET_PORTFOLIO_DETAIL_BY_ID");
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const watchPortfolioType = form.watch("portfolioType");

  return (
    <form
      onSubmit={form.handleSubmit((values: any) =>
        handleUpdatePortfolio(values),
      )}
    >
      <Grid2 container spacing={2}>
        <Grid2 size={4}>
          <Controller
            control={form.control}
            name="portfolioType"
            rules={{
              required: {
                value: true,
                message: "Please select portfolio type",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <GKSearchSelect
                disableClearable
                inputLabel="Portfolio Type"
                requiredField
                getOptionLabel={(option) => option.name || option}
                options={portfolioType || []}
                value={
                  portfolioType.find(
                    (dataItem) => dataItem.value === field?.value,
                  )?.name || ""
                }
                onChange={(e, val) => {
                  field.onChange(val?.value);
                  form.setValue("stockAsCapital", false);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select  portfolio type",
                      value: field.value,
                    })}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="name"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Please enter portfolio name",
              },
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl variant="standard" fullWidth>
                  <GKTextField
                    {...field}
                    inputLabel="Portfolio Name"
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select  portfolio name",
                      value: field.value,
                    })}
                    requiredField
                    error={!!error}
                    helperText={error?.message}
                  />
                </FormControl>
              );
            }}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="portfolioId"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Please enter portfolio id",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl variant="standard" fullWidth>
                <GKTextField
                  {...field}
                  requiredField
                  onChange={(event) => {
                    field.onChange(event.target.value.trim());
                  }}
                  inputLabel="Portfolio Code"
                  placeholder={handleReturnAutocompletePlaceholder({
                    placeholder: "Select  UCC of Demat A/c",
                    value: field.value,
                  })}
                  error={!!error}
                  helperText={error?.message}
                />
              </FormControl>
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="client"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: "Please select client",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <GKSearchSelect
                disableClearable
                inputLabel="Client"
                requiredField
                options={clientList || []}
                value={
                  clientList?.find((dataItem) => dataItem.value === field.value)
                    ?.name || ""
                }
                onChange={(e, val) => field.onChange(val?.value)}
                getOptionLabel={(option) => option.name || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select  Client",
                      value: field.value,
                    })}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            )}
          />
        </Grid2>
        <Grid2 size={4}>
          <Controller
            name="committedValue"
            control={form.control}
            render={({ field, fieldState: { error } }) => (
              <FormControl variant="standard" fullWidth>
                <GKTextField
                  {...field}
                  requiredField
                  type="number"
                  onChange={(event) => field.onChange(event.target.value)}
                  inputLabel="Committed Value"
                  error={!!error}
                  helperText={error?.message}
                />
              </FormControl>
            )}
          />
        </Grid2>
        {broker?.lmSupport && (
          <Grid2 size={4}>
            <Controller
              name="isLm"
              control={form.control}
              render={({ field }) => (
                <GKSearchSelect
                  disableClearable
                  inputLabel="Broker"
                  options={brokerList}
                  value={
                    brokerList.find((broker) => broker.value === field.value)
                      ?.name || ""
                  }
                  onChange={(e, val) => field.onChange(val?.value)}
                  getOptionLabel={(option) => option.name || option}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        placeholder={handleReturnAutocompletePlaceholder({
                          placeholder: "Select Broker",
                          value: field.value,
                        })}
                      />
                    );
                  }}
                />
              )}
            />
          </Grid2>
        )}
        <Grid2 size={4}>
          <FormControl variant="standard" fullWidth>
            <Controller
              name="anchorDate"
              control={form.control}
              render={({ field }) => (
                <GKDatePicker
                  {...field}
                  inputLabel="Anchor Date"
                  value={dayjs(field.value)}
                  onChange={(value) => {
                    field.onChange(dayjs(value).format("YYYY-MM-DD"));
                  }}
                  disableFuture
                  slotProps={{
                    textField: {
                      error: false,
                      helperText: false,
                    },
                  }}
                />
              )}
            />
          </FormControl>
        </Grid2>
        {watchPortfolioType === "Advisory" && (
          <Grid2 size={12}>
            <Controller
              name="stockAsCapital"
              control={form.control}
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  label="Stock as Capital"
                  control={
                    <GKCheckbox
                      disableLeftPadding
                      checked={field.value}
                      onChange={(event) => field.onChange(event.target.checked)}
                    />
                  }
                />
              )}
            />
          </Grid2>
        )}

        <Grid2 size={12} display={"flex"} justifyContent={"flex-end"} mt={2}>
          <LoadingButton variant="contained" type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default EditPortfolio;
