import { Grid2, Tab, Tabs } from "components";
import { useState } from "react";
import ReportsManager from ".";
import ReportScheduler from "./ReportScheduler";

const ReportTab = () => {
  const [reportTab, setReportTab] = useState(0);

  const tabs = ["Report", "Scheduler"];

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={12} display={"flex"}>
        <Tabs
          className="light"
          variant="standard"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
          value={reportTab}
          onChange={(e, val) => {
            setReportTab(val);
          }}
        >
          {tabs?.map((dataItem, index) => (
            <Tab key={index} label={dataItem} disableRipple />
          ))}
        </Tabs>
      </Grid2>
      <Grid2 size={12}>
        {reportTab === 0 && <ReportsManager />}
        {reportTab === 1 && <ReportScheduler />}
      </Grid2>
    </Grid2>
  );
};

export default ReportTab;
