import { LoadingButton } from "@mui/lab";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  BsExclamationOctagon,
  Button,
  Divider,
  FiTrash2,
  FormControl,
  FormControlLabel,
  FormLabel,
  GKClientDataGrid,
  GKModal,
  GKRadio,
  GKSearchSelect,
  GKSideModal,
  GKSimpleDataGrid,
  GKTextField,
  GoInfo,
  Grid2,
  IconButton,
  RadioGroup,
  Stack,
  styled,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "components";
import GKNoDataFound from "components/GKNoDataFound";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { paletteLightTheme } from "utils/ColorTheme";
import { GKModalProps } from "utils/commonInterface";
import { validationForPercentage } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import useDocumentTitle from "utils/useDocumentTitle";
import CompanyAllocationCreatOrder from "./CompanyCreateOrder";
import EquityBasketCreatOrder from "./EquityBasketCreatOrder";
import {
  AllocationBy,
  AllocationReferenceValueCompany,
  AllocationReferenceValueModel,
  allocationTypee,
  ExitBy,
  ExitReferenceValue,
  ModelReferenceWeightage,
  RebalanceBy,
  RebalanceReferenceValueCompany,
  RebalanceReferenceValueModel,
  WeightageDistributionMethod,
} from "./Info";
import OrderRationaleModal from "./OrderRationaleModal";
import PortfolioWiseExit from "./PortfolioWiseExit";
import SelectPortfolioModal from "./SelectPortfolioModal";
import StockWiseRebalanceOrders from "./StockWiseRebalanceOrders";
import useModelAllocation from "./useModelAllocation";

interface DataInterface {
  name: string;
  id: number;
  portfoliosValue: PortfoliosValue;
  totalMappedPortfolios: number;
  xirr: number;
  stocksTotal: number;
  lastChange: string[];
  mappedRevenue: number;
  code: string;
  minInvestment: number;
  investmentPhilosophy: any;
  methodology: any;
  clientRebalanceTime: any;
  feePlans: any[];
  feePlansList: any[];
  productType: any;
  ownerType: string;
  managedBy: string;
  investmentStyle: any[];
  riskProfile: any[];
  managementStyle: any[];
  overview: string;
  rationale: string;
  rebalanceTime: string;
  benchmark: string;
  createdOn: string;
  researchFiles: any[];
  volatility: any;
  timePeriod: any;
  mappedAum: number;
}

interface PortfoliosValue {
  totalInvestment: number;
  unrealGain: number;
  currentValue: number;
  availableCash: number;
}

interface Props {
  modelData?: DataInterface;
}

const Radio = styled(GKRadio)(() => {
  return {
    padding: "4px",
  };
});

const EquityBasketAllocation = ({
  open,
  setOpen,
  modelData,
}: Props & GKModalProps) => {
  const {
    openPortfolioModal,
    setOpenPortfolioModal,
    fields,
    replace,
    companiesFields,
    companyReplace,
    form,
    formSubmit,
    allocationType,
    transactionType,
    companiesData,
    companiedFetching,
    allVersionsData,
    portfolioDataData,
    responseData,
    allocationByData,
    rebalanceRefrenceType,
    formCompanySubmit,
    setResponseData,
    isLoading,
    freezeData,
    setFreezeData,
    holdData,
    setHoldData,
    handleRecalculate,
    createLoading,
    handleCreateOrder,
    companyWiseRebalanceData,
    setCompanyWiseRebalanceData,
    handleRecalculateStockRebalance,
    handleCreateRebalanceOrder,
    allocationByExitData,
    openGenerateRationaleModal,
    setOpenGenerateRationaleModal,
    exitRefrenceType,
    rebalanceByData,
    userData,
  } = useModelAllocation({ modelData });

  useDocumentTitle("Basket Allocation");

  const [alllocationTypeTab, setAllocationTypeTab] = useState(0);

  const [openToolTipModal, setOpenToolTipModal] = useState(false);

  const [toolTipText, setToolTipText] = useState(null);

  const [modalTitle, setModalTitle] = useState("");

  form.watch();

  const returnPortfolioTableColumns = (type: any) => {
    switch (type) {
      case "allocation":
        return [
          {
            field: "portfolioCode",
            headerName: "Portfolio",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <div>
                  <Typography fontWeight={700} whiteSpace={"nowrap"}>
                    {params?.row?.portfolioName}
                  </Typography>
                  <Typography whiteSpace={"nowrap"}>
                    {params?.row?.portfolioCode}
                  </Typography>{" "}
                </div>
              );
            },
          },
          {
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            field: "availableCash",
            headerName: "Ledger Balance",
            valueGetter: (val) => val?.value || 0,
            valueFormatter: (val) => moneyCommaSeparator(val?.value) || 0,
          },
          {
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            field: "committedValue",
            headerName: "Committed Value",
            valueGetter: (val) => val?.value || 0,
            valueFormatter: (val) => moneyCommaSeparator(val?.value) || 0,
          },
          {
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            field: "totalCash",
            headerName: "Ledger Balance",
            valueGetter: (val) => val?.value || 0,
            valueFormatter: (val) => moneyCommaSeparator(val?.value) || 0,
          },
          {
            field: "percentage",
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            headerName: "Allocation Percentage",
            renderCell: (params) => {
              const { index, totalCash, committedValue } = params?.row;
              const cashValue =
                form.getValues("rebalance_using") === "committedValue" ||
                form.getValues("rebalance_using") === "commitedValueAbsolute"
                  ? committedValue
                  : totalCash;

              return (
                <Controller
                  control={form.control}
                  name={`portfolios.${index}.percentage`}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      placeholder="Allocation Percentage"
                      type="number"
                      onChange={(e) => {
                        form.setValue(`portfolios.${index}`, {
                          ...params.row,
                          percentage: +e.target.value,
                          cash:
                            (
                              (parseFloat(cashValue) *
                                parseFloat(e.target.value)) /
                              100
                            )?.toFixed(2) || 0,
                        });
                      }}
                    />
                  )}
                />
              );
            },
          },
          {
            field: "allocationAmount",
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            headerName: "Allocation amount",
            renderCell: (params) => {
              const { index, committedValue, totalCash } = params?.row;

              const cashValue =
                form.getValues("rebalance_using") === "committedValue" ||
                form.getValues("rebalance_using") === "commitedValueAbsolute"
                  ? committedValue
                  : totalCash;

              return (
                <Controller
                  control={form.control}
                  name={`portfolios.${index}.cash`}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      placeholder="Amount"
                      type="number"
                      onChange={(e) => {
                        form.setValue(`portfolios.${index}`, {
                          ...params.row,
                          cash: +e.target.value,
                          percentage:
                            (
                              (Number(e.target.value) / Number(cashValue)) *
                              100
                            )?.toFixed(2) || 0,
                        });
                      }}
                    />
                  )}
                />
              );
            },
          },
          {
            field: "action",
            disableColumnMenu: true,
            sortable: false,
            align: "right",
            headerAlign: "right",
            headerName: "Action",
            renderCell: (params) => {
              const { index } = params?.row;
              return (
                <IconButton
                  color="error"
                  className="outline"
                  onClick={() => {
                    const newArr = form
                      .getValues("portfolios")
                      ?.filter((d, i) => i !== index);
                    replace(newArr);
                  }}
                >
                  <FiTrash2 />
                </IconButton>
              );
            },
          },
        ] as GridColDef[];
      case "rebalance":
        return [
          {
            field: "portfolioCode",
            headerName: "Portfolio",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <div>
                  <Typography fontWeight={700} whiteSpace={"nowrap"}>
                    {params?.row?.portfolioName}
                  </Typography>
                  <Typography whiteSpace={"nowrap"}>
                    {params?.row?.portfolioCode}
                  </Typography>{" "}
                </div>
              );
            },
          },
          {
            field: "currentAmount",
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            headerName: "Current Holdings",
          },
          {
            field: "Cash for Rebalance",
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            headerName: "Cash for Rebalance",
            renderCell: (params) => {
              const { index, totalCash, currentAmount } = params.row;
              return (
                <Controller
                  control={form.control}
                  name={`portfolios.${index}.cash`}
                  defaultValue={totalCash}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      placeholder="Cash for Rebalance"
                      type="number"
                      onChange={(e) => {
                        form.setValue(`portfolios.${index}`, {
                          ...params.row,
                          cash: +e.target.value,
                          portfolioValue: +currentAmount + +e.target.value,
                        });
                      }}
                    />
                  )}
                />
              );
            },
          },
          {
            field: "portfolioValue",
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            headerName: "Total amount",
            renderCell: (params) => {
              const { index } = params?.row;
              return (
                <Controller
                  control={form.control}
                  name={`portfolios.${index}.portfolioValue`}
                  render={({ field }) => (
                    <GKTextField
                      disabled
                      {...field}
                      placeholder="Cash for Rebalance"
                      type="number"
                    />
                  )}
                />
              );
            },
          },
          {
            field: "action",
            disableColumnMenu: true,
            sortable: false,
            align: "right",
            headerAlign: "right",
            headerName: "Action",
            renderCell: (params) => {
              const { index } = params?.row;
              return (
                <IconButton
                  color="error"
                  className="outline"
                  onClick={() => {
                    const newArr = form
                      .getValues("portfolios")
                      ?.filter((d, i) => i !== index);
                    replace(newArr);
                  }}
                >
                  <FiTrash2 />
                </IconButton>
              );
            },
          },
        ] as GridColDef[];
      case "exit":
        return [
          {
            field: "portfolioCode",
            headerName: "Portfolio",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <div>
                  <Typography fontWeight={700} whiteSpace={"nowrap"}>
                    {params?.row?.portfolioName}
                  </Typography>
                  <Typography whiteSpace={"nowrap"}>
                    {params?.row?.portfolioCode}
                  </Typography>{" "}
                </div>
              );
            },
          },
          {
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            field: "currentAmount",
            headerName: "Current Holdings",
            valueGetter: (val) => val?.value || 0,
            valueFormatter: (val) => moneyCommaSeparator(val?.value),
          },
          {
            field: "percentage",
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            headerName: "Exit Percentage",
            renderCell: (params) => {
              const { index, currentAmount } = params?.row;

              return (
                <Controller
                  control={form.control}
                  name={`portfolios.${index}.percentage`}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      placeholder="Exit Percentage"
                      type="number"
                      onChange={(e) => {
                        form.setValue(`portfolios.${index}`, {
                          ...params.row,
                          percentage: +e.target.value,
                          cash:
                            (
                              (parseFloat(currentAmount) *
                                parseFloat(e.target.value)) /
                              100
                            )?.toFixed(2) || 0,
                        });
                      }}
                    />
                  )}
                />
              );
            },
          },
          {
            field: "allocationAmount",
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            headerName: "Exit amount",
            renderCell: (params) => {
              const { index, currentAmount } = params?.row;
              return (
                <Controller
                  control={form.control}
                  name={`portfolios.${index}.cash`}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      type="number"
                      placeholder="Exit Amount"
                      onChange={(e) => {
                        form.setValue(`portfolios.${index}`, {
                          ...params.row,
                          cash: +e.target.value,
                          percentage:
                            (
                              (Number(e.target.value) / Number(currentAmount)) *
                              100
                            )?.toFixed(2) || 0,
                        });
                      }}
                    />
                  )}
                />
              );
            },
          },
          {
            field: "action",
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            align: "right",
            headerAlign: "right",
            headerName: "Action",
            renderCell: (params) => {
              const { index } = params?.row;
              return (
                <IconButton
                  color="error"
                  className="outline"
                  onClick={() => {
                    const newArr = form
                      .getValues("portfolios")
                      ?.filter((d, i) => i !== index);
                    replace(newArr);
                  }}
                >
                  <FiTrash2 />
                </IconButton>
              );
            },
          },
        ] as GridColDef[];
      default:
        return [];
    }
  };

  return (
    <GKSideModal
      open={open}
      setOpen={setOpen}
      modalTitle={modelData?.name || "-"}
      width={"100%"}
      footerButton={
        <Grid2
          size={12}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
        >
          {(companyWiseRebalanceData?.length !== 0 ||
            responseData?.length !== 0) && (
            <LoadingButton
              onClick={() => {
                setOpenGenerateRationaleModal(true);
              }}
              color="dark"
              variant="outlined"
              loading={createLoading}
            >
              Generate Trade Order Sheet
            </LoadingButton>
          )}
          {companyWiseRebalanceData?.length === 0 &&
            responseData?.length === 0 && (
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                form="equity-bakset-form"
                loading={isLoading}
              >
                Calculate
              </LoadingButton>
            )}
          {(companyWiseRebalanceData?.length !== 0 ||
            responseData?.length !== 0) && (
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={
                form?.getValues("rebalanceType")
                  ? handleRecalculateStockRebalance
                  : handleRecalculate
              }
              loading={isLoading}
            >
              Re-Calculate
            </LoadingButton>
          )}
        </Grid2>
      }
    >
      <form
        id="equity-bakset-form"
        onSubmit={form.handleSubmit(
          form.getValues?.("allocationBy") === "company" ||
            form.getValues?.("allocationBy") === "companyWeightage"
            ? formCompanySubmit
            : formSubmit,
        )}
      >
        <Grid2 container spacing={2} size={12}>
          <Grid2
            size={3}
            borderRight={`1px solid ${paletteLightTheme.palette.border.main}`}
            maxHeight={`calc(100vh - 132px)`}
            minHeight={`calc(100vh - 132px)`}
            overflow={"hidden auto"}
            pr={2}
          >
            <Grid2 container spacing={2}>
              <Grid2 size={12}>
                <Controller
                  control={form.control}
                  name="allocationType"
                  render={({ field }) => (
                    <FormControl>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FormLabel
                          sx={{
                            mb: 1,
                          }}
                        >
                          Allocation Type
                          <span>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setToolTipText(allocationTypee);
                                setModalTitle("Allocation Type");
                                setOpenToolTipModal(true);
                              }}
                            >
                              <GoInfo size={15} />
                            </IconButton>
                          </span>
                        </FormLabel>
                      </div>
                      <GKModal
                        open={openToolTipModal}
                        setOpen={setOpenToolTipModal}
                        modalTitle={modalTitle}
                      >
                        <Grid2 container p={1} spacing={2}>
                          {toolTipText}
                        </Grid2>
                      </GKModal>
                      <Tabs
                        value={alllocationTypeTab}
                        variant="standard"
                        className="light"
                        TabIndicatorProps={{
                          style: {
                            display: "none",
                          },
                        }}
                        onChange={(e, val) => {
                          setAllocationTypeTab(val);
                        }}
                      >
                        {allocationType?.map((transData, index) => {
                          return (
                            <Tab
                              onClick={() => {
                                form.reset();
                                setResponseData([]);
                                setCompanyWiseRebalanceData([]);
                                setTimeout(() => {
                                  if (transData.value === "rebalance") {
                                    form.setValue("allocationBy", "version");
                                  }
                                  field.onChange(transData.value);
                                }, 100);
                              }}
                              value={index}
                              label={transData.name}
                            />
                          );
                        })}
                      </Tabs>
                    </FormControl>
                  )}
                />
              </Grid2>

              {/** REBALANCE TYPE================= */}
              {form.getValues("allocationType") === "rebalance" && (
                <Grid2 size={12}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Controller
                      name="rebalanceType"
                      control={form.control}
                      render={({ field }) => (
                        <FormControl>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FormLabel
                              sx={{
                                mb: 1,
                              }}
                            >
                              Rebalance By
                              <span>
                                <IconButton
                                  onClick={() => {
                                    setToolTipText(RebalanceBy);
                                    setOpenToolTipModal(true);
                                    setModalTitle("Rebalance By");
                                  }}
                                >
                                  <BsExclamationOctagon size={14} />
                                </IconButton>
                              </span>
                            </FormLabel>
                          </div>
                          <RadioGroup row>
                            {rebalanceByData?.map((transData) => {
                              return (
                                <FormControlLabel
                                  onChange={() => {
                                    setResponseData([]);
                                    setCompanyWiseRebalanceData([]);
                                    form.setValue("companies", []);
                                    form.setValue("transactionType", "");
                                    form.setValue("portfolios", []);
                                    field.onChange(JSON.parse(transData.value));
                                  }}
                                  value={transData.value}
                                  control={
                                    <Radio
                                      checked={
                                        `${field.value}` === transData?.value
                                      }
                                    />
                                  }
                                  label={transData.name}
                                />
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </Stack>
                </Grid2>
              )}
              {form.getValues("allocationType") === "rebalance" && (
                <Grid2 size={12}>
                  <Divider />
                </Grid2>
              )}
              {/** ALLOCATION BY WITHOUT EXIT ================== */}
              {form.getValues("allocationType") !== "" &&
                form.getValues("allocationType") === "allocation" && (
                  <Grid2 size={12}>
                    <Controller
                      control={form.control}
                      name="allocationBy"
                      render={({ field }) => (
                        <FormControl>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FormLabel
                              sx={{
                                mb: 1,
                                mr: 1,
                              }}
                            >
                              Allocation By
                              <span>
                                <IconButton
                                  onClick={() => {
                                    setToolTipText(AllocationBy);
                                    setOpenToolTipModal(true);
                                    setModalTitle("Allocation By");
                                  }}
                                >
                                  <GoInfo size={15} />
                                </IconButton>
                              </span>
                            </FormLabel>
                          </div>

                          <RadioGroup row>
                            {allocationByData
                              ?.filter((item) =>
                                form.getValues("allocationType") === "rebalance"
                                  ? item?.value !== "company"
                                  : item,
                              )
                              ?.map((transData) => {
                                return (
                                  <FormControlLabel
                                    onChange={() => {
                                      field.onChange(transData?.value);
                                      form.resetField("companies");
                                      form.resetField("portfolios");
                                      setResponseData([]);
                                      setCompanyWiseRebalanceData([]);
                                    }}
                                    value={transData.value}
                                    control={<Radio />}
                                    label={transData.name}
                                  />
                                );
                              })}
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </Grid2>
                )}
              {form.getValues("allocationType") !== "" &&
                form.getValues("allocationType") === "allocation" && (
                  <Grid2 size={12}>
                    <Divider />
                  </Grid2>
                )}
              {/** ALLOCATION BY WITHOUT EXIT ================== */}
              {form.getValues("allocationType") !== "" &&
                form.getValues("allocationType") === "exit" && (
                  <Grid2 size={12}>
                    <Controller
                      control={form.control}
                      name="allocationBy"
                      render={({ field }) => (
                        <FormControl>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FormLabel
                              sx={{
                                mb: 1,
                              }}
                            >
                              Exit By
                              <span>
                                <IconButton
                                  onClick={() => {
                                    setToolTipText(ExitBy);
                                    setOpenToolTipModal(true);
                                    setModalTitle("Exit By");
                                  }}
                                >
                                  <GoInfo size={15} />
                                </IconButton>
                              </span>
                            </FormLabel>
                          </div>
                          <RadioGroup>
                            {allocationByExitData?.map((transData) => {
                              return (
                                <FormControlLabel
                                  onChange={() => {
                                    field.onChange(transData?.value);
                                    setResponseData([]);
                                    setCompanyWiseRebalanceData([]);
                                  }}
                                  value={transData.value}
                                  control={<Radio />}
                                  label={transData.name}
                                />
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </Grid2>
                )}
              {form.getValues("allocationType") !== "" &&
                form.getValues("allocationType") === "exit" && (
                  <Grid2 size={12}>
                    <Divider />
                  </Grid2>
                )}
              {/** Version Refrence Weightage ===== */}
              {form.getValues("allocationBy") !== "" &&
                form.getValues("allocationType") !== "exit" &&
                form.getValues("allocationBy") === "version" && (
                  <Grid2 size={12}>
                    <Controller
                      control={form.control}
                      name="transactionType"
                      render={({ field }) => (
                        <FormControl>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FormLabel
                              sx={{
                                mb: 1,
                              }}
                            >
                              Model Reference Weightage
                              <span>
                                <IconButton
                                  onClick={() => {
                                    setToolTipText(ModelReferenceWeightage);
                                    setOpenToolTipModal(true);
                                    setModalTitle("Model Reference Weightage");
                                  }}
                                >
                                  <GoInfo size={15} />
                                </IconButton>
                              </span>
                            </FormLabel>
                          </div>
                          <RadioGroup row>
                            {transactionType?.map((transData) => {
                              return (
                                <FormControlLabel
                                  onChange={() => {
                                    field.onChange(transData?.value);
                                    setResponseData([]);
                                    setCompanyWiseRebalanceData([]);
                                  }}
                                  value={transData.value}
                                  control={<Radio />}
                                  label={transData.name}
                                />
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </Grid2>
                )}
              {form.getValues("allocationBy") !== "" &&
                form.getValues("allocationType") !== "exit" &&
                form.getValues("allocationBy") === "version" && (
                  <Grid2 size={12}>
                    <Divider />
                  </Grid2>
                )}

              {/** Rebalance/Allocation Refrence Weightage === */}
              {form.getValues("allocationType") !== " " &&
                form.getValues("allocationBy") !== "" &&
                form.getValues("allocationType") !== "exit" && (
                  <Grid2 size={12}>
                    <Controller
                      control={form.control}
                      name="rebalance_using"
                      render={({ field }) => (
                        <FormControl>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FormLabel
                              sx={{
                                mb: 1,
                                textTransform: "capitalize",
                              }}
                            >
                              {`${form.getValues(
                                "allocationType",
                              )} Reference Value`}
                              {/** allocation Reference Value Model ================== */}
                              {form.getValues("allocationType") ===
                                "allocation" &&
                                form.getValues("allocationBy") ===
                                  "version" && (
                                  <span>
                                    <IconButton
                                      onClick={() => {
                                        setToolTipText(
                                          AllocationReferenceValueModel,
                                        );
                                        setOpenToolTipModal(true);
                                        setModalTitle(
                                          "Allocation Reference Value",
                                        );
                                      }}
                                    >
                                      <GoInfo size={15} />
                                    </IconButton>
                                  </span>
                                )}
                              {/** allocation Reference Value Company ================== */}
                              {form.getValues("allocationType") ===
                                "allocation" &&
                                form.getValues("allocationBy") ===
                                  "company" && (
                                  <span>
                                    <IconButton
                                      onClick={() => {
                                        setToolTipText(
                                          AllocationReferenceValueCompany,
                                        );
                                        setOpenToolTipModal(true);
                                        setModalTitle(
                                          "Allocation Reference Value",
                                        );
                                      }}
                                    >
                                      <GoInfo size={15} />
                                    </IconButton>
                                  </span>
                                )}
                              {/** rebalance Reference Value Model ================== */}
                              {form.getValues("allocationType") ===
                                "rebalance" &&
                                form.getValues("allocationBy") === "version" &&
                                !form.getValues("rebalanceType") && (
                                  <span>
                                    <IconButton
                                      onClick={() => {
                                        setToolTipText(
                                          RebalanceReferenceValueModel,
                                        );
                                        setOpenToolTipModal(true);
                                        setModalTitle(
                                          "Rebalance Reference Value",
                                        );
                                      }}
                                    >
                                      <GoInfo size={15} />
                                    </IconButton>
                                  </span>
                                )}
                              {/** rebalance Reference Value company ================== */}
                              {form.getValues("allocationType") ===
                                "rebalance" &&
                                form.getValues("allocationBy") === "version" &&
                                form.getValues("rebalanceType") && (
                                  <span>
                                    <IconButton
                                      onClick={() => {
                                        setToolTipText(
                                          RebalanceReferenceValueCompany,
                                        );
                                        setOpenToolTipModal(true);
                                        setModalTitle(
                                          "Rebalance Reference Value",
                                        );
                                      }}
                                    >
                                      <GoInfo size={15} />
                                    </IconButton>
                                  </span>
                                )}
                            </FormLabel>
                          </div>

                          <RadioGroup>
                            {rebalanceRefrenceType?.map((transData) => {
                              return (
                                transData?.allocationType?.includes(
                                  form.getValues("allocationType"),
                                ) &&
                                // @ts-ignore
                                transData[
                                  form.getValues("allocationType")
                                ]?.includes(form.getValues("allocationBy")) && (
                                  <FormControlLabel
                                    onChange={() => {
                                      field.onChange(transData?.value);
                                      setResponseData([]);
                                      setCompanyWiseRebalanceData([]);
                                    }}
                                    value={transData.value}
                                    control={<Radio />}
                                    label={transData.name}
                                  />
                                )
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </Grid2>
                )}
              {form.getValues("allocationType") !== " " &&
                form.getValues("allocationBy") !== "" &&
                form.getValues("allocationType") !== "exit" && (
                  <Grid2 size={12}>
                    <Divider />
                  </Grid2>
                )}
              {/** Rebalance Refrence Weightage === */}
              {form.getValues("allocationType") !== " " &&
                form.getValues("allocationBy") === "companyWeightage" &&
                form.getValues("allocationType") === "exit" && (
                  <Grid2 size={12}>
                    <Controller
                      control={form.control}
                      name="rebalance_using"
                      render={({ field }) => (
                        <FormControl>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FormLabel
                              sx={{
                                mb: 1,
                              }}
                            >
                              Exit Reference Value
                              <span>
                                <IconButton
                                  onClick={() => {
                                    setToolTipText(ExitReferenceValue);
                                    setOpenToolTipModal(true);
                                    setModalTitle("Exit Reference Value");
                                  }}
                                >
                                  <GoInfo size={15} />
                                </IconButton>
                              </span>
                            </FormLabel>
                          </div>
                          <RadioGroup row>
                            {exitRefrenceType?.map((transData) => {
                              return (
                                <FormControlLabel
                                  onChange={() => {
                                    field.onChange(transData?.value);
                                    setResponseData([]);
                                    setCompanyWiseRebalanceData([]);
                                  }}
                                  value={transData.value}
                                  control={<Radio />}
                                  label={transData.name}
                                />
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </Grid2>
                )}
              {form.getValues("allocationType") !== " " &&
                form.getValues("allocationBy") === "companyWeightage" &&
                form.getValues("allocationType") === "exit" && (
                  <Grid2 size={12}>
                    <Divider />
                  </Grid2>
                )}
              {/** Rebalance Refrence Weightage === */}
              {(form.getValues("allocationType") === "allocation" ||
                form.getValues("allocationType") === "rebalance") &&
                form.getValues("allocationBy") !== "" &&
                form.getValues("allocationBy") === "version" &&
                form.getValues("allocationType") !== "exit" && (
                  <Grid2 size={12}>
                    <Controller
                      control={form.control}
                      name="weightageDistributionMethod"
                      render={({ field }) => (
                        <FormControl>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FormLabel
                              sx={{
                                mb: 1,
                              }}
                            >
                              Weightage Distribution Method
                              <span>
                                <IconButton
                                  onClick={() => {
                                    setToolTipText(WeightageDistributionMethod);
                                    setOpenToolTipModal(true);
                                    setModalTitle(
                                      "Weightage Distribution Method",
                                    );
                                  }}
                                >
                                  <GoInfo size={15} />
                                </IconButton>
                              </span>
                            </FormLabel>
                          </div>
                          <RadioGroup row>
                            {[
                              { value: "PRORATA", name: "Prorata" },
                              { value: "FIXED", name: "No Redistribution" },
                            ]?.map((transData) => {
                              return (
                                <FormControlLabel
                                  onChange={() => {
                                    field.onChange(transData?.value);
                                    setResponseData([]);
                                    setCompanyWiseRebalanceData([]);
                                  }}
                                  value={transData.value}
                                  control={<Radio />}
                                  label={transData.name}
                                />
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </Grid2>
                )}
              {(form.getValues("allocationType") === "allocation" ||
                form.getValues("allocationType") === "rebalance") &&
                form.getValues("allocationBy") !== "" &&
                form.getValues("allocationBy") === "version" &&
                form.getValues("allocationType") !== "exit" && (
                  <Grid2 size={12}>
                    <Divider />
                  </Grid2>
                )}
              {/** REBALANCE COMPANY DD============ */}
              {form.getValues("allocationType") === "rebalance" &&
                form.getValues("rebalanceType") && (
                  <Grid2 size={12}>
                    <Controller
                      control={form.control}
                      name="companies"
                      render={({ field }) => (
                        <GKSearchSelect
                          limitTags={2}
                          loading={companiedFetching}
                          onChange={(e, val) => {
                            setResponseData([]);
                            setCompanyWiseRebalanceData([]);
                            companyReplace(val);
                          }}
                          options={companiesData?.data?.data || []}
                          getOptionLabel={(option) => option?.name || option}
                          disableClearable={false}
                          inputLabel="Select Companies"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={handleReturnAutocompletePlaceholder({
                                placeholder: "Select Companies",
                                value: field.value,
                              })}
                              error={Boolean(form.formState.errors.companies)}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid2>
                )}
              {form.getValues("allocationType") === "rebalance" &&
                form.getValues("rebalanceType") && (
                  <Grid2 size={12}>
                    <Divider />
                  </Grid2>
                )}
              {/** COMPANY DD====================== */}
              {form.getValues("allocationType") !== "" &&
                form.getValues("allocationBy") === "company" && (
                  <Grid2 size={12}>
                    <Controller
                      control={form.control}
                      name="companies"
                      render={({ field }) => (
                        <GKSearchSelect
                          multiple
                          limitTags={1}
                          loading={companiedFetching}
                          disableClearable={false}
                          onChange={(e, val) => {
                            companyReplace(val);
                            setResponseData([]);
                            setCompanyWiseRebalanceData([]);
                          }}
                          options={companiesData?.data?.data || []}
                          getOptionLabel={(option) => option?.name || option}
                          inputLabel="Select Companies"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={handleReturnAutocompletePlaceholder({
                                placeholder: "Select Companies",
                                value: field.value,
                              })}
                              error={Boolean(form.formState.errors.companies)}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid2>
                )}
              {form.getValues("allocationType") !== "" &&
                form.getValues("allocationBy") === "company" && (
                  <Grid2 size={12}>
                    <Divider />
                  </Grid2>
                )}
              {/** COMPANY DD====================== */}
              {form.getValues("allocationType") !== "" &&
                form.getValues("allocationBy") === "companyWeightage" && (
                  <Grid2 size={12}>
                    <Controller
                      control={form.control}
                      name="companies"
                      render={({ field }) => (
                        <GKSearchSelect
                          multiple
                          limitTags={1}
                          loading={companiedFetching}
                          disableClearable
                          onChange={(e, val) => {
                            companyReplace(val);
                            setResponseData([]);
                            setCompanyWiseRebalanceData([]);
                          }}
                          options={companiesData?.data?.data || []}
                          getOptionLabel={(option) => option?.name || option}
                          inputLabel="Select Companies"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={handleReturnAutocompletePlaceholder({
                                placeholder: "Select Companies",
                                value: field.value,
                              })}
                              error={Boolean(form.formState.errors.companies)}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid2>
                )}
              {form.getValues("allocationType") !== "" &&
                form.getValues("allocationBy") === "companyWeightage" && (
                  <Grid2 size={12}>
                    <Divider />
                  </Grid2>
                )}
              {/** VERSION DD======================= */}
              {form.getValues("allocationType") !== "" &&
                form.getValues("allocationBy") === "version" && (
                  <Grid2 size={12}>
                    <Controller
                      control={form.control}
                      name="version"
                      render={({ field }) => (
                        <GKSearchSelect
                          onChange={(e, val) => {
                            field.onChange(val);
                            setResponseData([]);
                            setCompanyWiseRebalanceData([]);
                          }}
                          loading={companiedFetching}
                          disableClearable={false}
                          options={allVersionsData?.data?.data || []}
                          getOptionLabel={(option) => option?.version || option}
                          inputLabel="Select Model"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={handleReturnAutocompletePlaceholder({
                                placeholder: "Select Model",
                                value: field.value,
                              })}
                              error={Boolean(form.formState.errors.version)}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid2>
                )}
              {form.getValues("allocationType") !== "" &&
                form.getValues("allocationBy") === "version" && (
                  <Grid2 size={12}>
                    <Divider />
                  </Grid2>
                )}
              {/** WEIGHTAGE TEXTFIELD======================= */}
              {!form.getValues("rebalanceType") &&
                form.getValues("allocationBy") === "version" &&
                form.getValues("allocationType") !== "" &&
                form.getValues("allocationType") === "exit" && (
                  <Grid2 size={12}>
                    <Controller
                      control={form.control}
                      name="exitWeightage"
                      render={({ field }) => (
                        <FormControl variant="standard" fullWidth>
                          <GKTextField
                            {...field}
                            inputLabel="Weightage"
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              setResponseData([]);
                              setCompanyWiseRebalanceData([]);
                            }}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid2>
                )}
              {!form.getValues("rebalanceType") &&
                form.getValues("allocationBy") === "version" &&
                form.getValues("allocationType") !== "" &&
                form.getValues("allocationType") === "exit" && (
                  <Grid2 size={12}>
                    <Divider />
                  </Grid2>
                )}
              {/** PORTOLIO DD======================= */}
              {form.getValues("allocationBy") !== "" &&
                form.getValues("allocationType") !== "" && (
                  <Grid2 size={12} mt={"16px"}>
                    <Button
                      variant="contained"
                      size="large"
                      className="extra-large"
                      fullWidth
                      onClick={() => {
                        setOpenPortfolioModal(true);
                      }}
                    >
                      Select Portfolio
                    </Button>
                  </Grid2>
                )}
            </Grid2>
          </Grid2>
          <Grid2 size={9}>
            {responseData?.length === 0 &&
              form.getValues("portfolios").length === 0 &&
              form.getValues("companies").length === 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <GKNoDataFound
                    text="No Rebalance, Allocation or Exit done yet."
                    subText="Please fill up the form to proceed forward"
                  />
                </div>
              )}
            {/** COMPANY TABLE================== */}
            {responseData?.length === 0 &&
              !form.getValues("rebalanceType") &&
              form.getValues("companies").length !== 0 &&
              (form.getValues("allocationBy") === "company" ||
                form.getValues("allocationBy") === "companyWeightage") &&
              form.getValues("allocationType") !== "" && (
                <GKClientDataGrid
                  name="companyAllocation"
                  showHeaderGrid={false}
                  getRowId={(op) => op?.gridCode}
                  rows={
                    companiesFields?.map((da, i) => {
                      return {
                        ...da,
                        index: i,
                        limitValue: da.limitValue || "",
                      };
                    }) || []
                  }
                  columns={[
                    {
                      field: "name",
                      disableColumnMenu: true,
                      sortable: false,
                      flex: 1,
                      headerName: "Company",
                    },
                    {
                      field: "weightage",
                      disableColumnMenu: true,
                      sortable: false,
                      flex: 1,
                      headerName: "Weightage",
                      renderCell: (params) => {
                        const { index } = params?.row;

                        return (
                          <Controller
                            control={form.control}
                            rules={{
                              max: 100,
                            }}
                            name={`companies.${index}.weightage`}
                            render={({ field }) => (
                              <GKTextField
                                {...field}
                                type="number"
                                placeholder="Weightage"
                                onChange={(e) => {
                                  field.onChange(
                                    validationForPercentage(+e.target.value),
                                  );
                                }}
                              />
                            )}
                          />
                        );
                      },
                    },
                    // Conditionally add the Limit value column
                    ...(form.getValues("allocationType") === "allocation"
                      ? [
                          {
                            field: "limitValue",
                            disableColumnMenu: true,
                            sortable: false,
                            flex: 1,
                            headerName: "Limit value",
                            renderCell: (params: { row: { index: any } }) => {
                              const { index } = params?.row;
                              return (
                                <Controller
                                  control={form.control}
                                  name={`companies.${index}.limitValue`}
                                  render={({ field }) => (
                                    <GKTextField
                                      {...field}
                                      type="number"
                                      placeholder="Limit value"
                                      onChange={(e) => {
                                        field.onChange(Number(e.target.value));
                                      }}
                                    />
                                  )}
                                />
                              );
                            },
                          },
                        ]
                      : []),
                  ]}
                />
              )}
            {/** PORTFOLIO TABLE ================== */}
            {!form.getValues("rebalanceType") &&
              responseData?.length === 0 &&
              form.getValues("portfolios").length !== 0 &&
              form.getValues("allocationBy") === "version" &&
              form.getValues("allocationType") !== "" &&
              form.getValues("allocationType") !== "exit" && (
                <GKSimpleDataGrid
                  showHeaderGrid={false}
                  getRowId={(op) => op?.portfolioId}
                  rows={
                    fields?.map((da: any, i: any) => {
                      return {
                        ...da,
                        index: i,
                      };
                    }) || []
                  }
                  columns={returnPortfolioTableColumns(
                    form.getValues("allocationType"),
                  )}
                  columnVisibilityModel={{
                    totalCash:
                      userData?.useCeAmount &&
                      form.getValues("rebalance_using") === "ledgerBalance",
                    committedValue:
                      form.getValues("rebalance_using") === "committedValue" ||
                      form.getValues("rebalance_using") ===
                        "commitedValueAbsolute",
                    availableCash:
                      !userData?.useCeAmount &&
                      form.getValues("rebalance_using") === "ledgerBalance",
                  }}
                />
              )}
            {/** PORTFOLIO TABLE ================== */}
            {!form.getValues("rebalanceType") &&
              responseData?.length === 0 &&
              form.getValues("portfolios").length !== 0 &&
              form.getValues("allocationBy") === "portfolio" &&
              form.getValues("allocationType") !== "" &&
              form.getValues("allocationType") === "exit" && (
                <GKClientDataGrid
                  name="portfolioRebalanceAllocation"
                  showHeaderGrid={false}
                  getRowId={(op) => op?.portfolioId}
                  rows={
                    fields?.map((da: any, i: any) => {
                      return {
                        ...da,
                        index: i,
                      };
                    }) || []
                  }
                  columns={[
                    {
                      field: "portfolioCode",
                      headerName: "Portfolio",
                      flex: 1,
                      disableColumnMenu: true,
                      renderCell: (params) => {
                        return (
                          <div>
                            <Typography fontWeight={700} whiteSpace={"nowrap"}>
                              {params?.row?.portfolioName}
                            </Typography>
                            <Typography whiteSpace={"nowrap"}>
                              {params?.row?.portfolioCode}
                            </Typography>{" "}
                          </div>
                        );
                      },
                    },
                    {
                      field: "exitWeightage",
                      disableColumnMenu: true,
                      sortable: false,
                      flex: 1,
                      headerName: "Exit Weightage",
                      renderCell: (params) => {
                        const { index } = params?.row;
                        return (
                          <Controller
                            control={form.control}
                            name={`portfolios.${index}.exitWeightage`}
                            render={({ field }) => (
                              <GKTextField
                                {...field}
                                placeholder="Amount"
                                type="number"
                                onChange={(e) => {
                                  form.setValue(`portfolios.${index}`, {
                                    ...params.row,
                                    exitWeightage: +e.target.value,
                                  });
                                }}
                              />
                            )}
                          />
                        );
                      },
                    },
                    {
                      field: "action",
                      disableColumnMenu: true,
                      sortable: false,
                      align: "right",
                      headerAlign: "right",
                      headerName: "Action",
                      renderCell: (params) => {
                        const { index } = params?.row;
                        return (
                          <IconButton
                            color="error"
                            className="outline"
                            onClick={() => {
                              const newArr = form
                                .getValues("portfolios")
                                ?.filter((d, i) => i !== index);
                              replace(newArr);
                            }}
                          >
                            <FiTrash2 />
                          </IconButton>
                        );
                      },
                    },
                  ]}
                />
              )}

            {/** RESPONSE TABLE================================================== */}
            {form.getValues("allocationBy") === "version" &&
              !form.getValues("rebalanceType") &&
              responseData?.length !== 0 && (
                <EquityBasketCreatOrder
                  responseData={responseData}
                  form={form}
                  freezeData={freezeData}
                  setFreezeData={setFreezeData}
                  holdData={holdData}
                  setHoldData={setHoldData}
                />
              )}
            {/** RESPONSE TABLE================== */}
            {form.getValues("allocationBy") === "portfolio" &&
              !form.getValues("rebalanceType") &&
              responseData?.length !== 0 && (
                <PortfolioWiseExit
                  responseData={responseData}
                  form={form}
                  freezeData={freezeData}
                  setFreezeData={setFreezeData}
                  holdData={holdData}
                  setHoldData={setHoldData}
                />
              )}
            {/** RESPONSE TABLE================== */}
            {(form.getValues("allocationBy") === "company" ||
              form.getValues("allocationBy") === "companyWeightage") &&
              !form.getValues("rebalanceType") &&
              responseData?.length !== 0 && (
                <CompanyAllocationCreatOrder
                  responseData={responseData}
                  form={form}
                  freezeData={freezeData}
                  setFreezeData={setFreezeData}
                  holdData={holdData}
                  setHoldData={setHoldData}
                />
              )}
            {/** REBALANCE COMPANY RESPONSE TABLE================== */}
            {form.getValues("rebalanceType") &&
              companyWiseRebalanceData?.length !== 0 && (
                <StockWiseRebalanceOrders
                  setCompanyWiseRebalanceData={setCompanyWiseRebalanceData}
                  companyWiseRebalanceData={companyWiseRebalanceData || []}
                />
              )}
          </Grid2>
        </Grid2>
      </form>
      {openPortfolioModal && (
        <SelectPortfolioModal
          open={openPortfolioModal}
          setOpen={setOpenPortfolioModal}
          data={portfolioDataData?.data?.data}
          replace={replace}
          form={form}
          setResponseData={setResponseData}
          setCompanyWiseRebalanceData={setCompanyWiseRebalanceData}
        />
      )}

      {openGenerateRationaleModal && (
        <OrderRationaleModal
          open={openGenerateRationaleModal}
          setOpen={setOpenGenerateRationaleModal}
          form={form}
          submit={() => {
            form?.getValues("rebalanceType")
              ? handleCreateRebalanceOrder()
              : handleCreateOrder();
          }}
        />
      )}
    </GKSideModal>
  );
};

export default EquityBasketAllocation;
