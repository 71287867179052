import { GridColDef } from "@mui/x-data-grid-pro";
import {
  AnimationComponent,
  Box,
  BsExclamationOctagon,
  GKTextField,
  Grid2,
  IconButton,
  LuSearch,
  MdOutlineKeyboardArrowRight,
  Show,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "components";
import SimpleLoader from "components/SimpleLoader";
import { useFetchQuery } from "hooks/useQueries";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { handleReturnPositiveNegative } from "utils/colorHelper";
import { moneyCommaSeparator } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";

type Order = "asc" | "desc";

interface Props {
  selectedId: any;
  oldIdSelected: any;
}

const ModelHoldings = ({ selectedId, oldIdSelected }: Props) => {
  const { id } = useParams();

  const { theme } = ThemeContainer();

  const [order, setOrder] = useState<Order>("desc");

  const [searchText, setSearchText] = useState("");

  const [isRowOpened, setIsRowOpened] = useState({
    cash: true,
    equity: true,
  });

  const [orderBy, setOrderBy] = useState<any>("companyName");

  const { isLoading, data } = useFetchQuery({
    key: ["GET_MODEL_PORTFOLIO_HOLDINGS", oldIdSelected],
    route: `/model_portfolio/${id}/version/${
      oldIdSelected?.snapshotVersion || selectedId
    }/version_holdings/`,
    enabled: Boolean(selectedId),
  });

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 1;
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number,
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const visibleRows = React.useMemo(
    () =>
      stableSort(
        data?.data?.data?.data?.map((item: any) => {
          return {
            ...item,
            unrealisedGain:
              item?.isin === "INGridkeyCash" ? "" : item?.unrealisedGain,
            realisedGain:
              item?.isin === "INGridkeyCash" ? "" : item?.realisedGain,
            otherGains: item?.isin === "INGridkeyCash" ? "" : item?.otherGains,
            quantity: item?.isin === "INGridkeyCash" ? "" : item?.quantity,
            rawQuantity:
              item?.isin === "INGridkeyCash" ? "" : item?.rawQuantity,
            investedAmount:
              item?.isin === "INGridkeyCash" ? "" : item?.investedAmount,
            totalNetInvestment:
              item?.isin === "INGridkeyCash" ? "" : item?.totalNetInvestment,
            currentAmount: item?.currentAmount,
            totalAmount:
              item?.isin === "INGridkeyCash" ? "" : item?.totalAmount,
            todaysGain: item?.isin === "INGridkeyCash" ? "" : item?.todaysGain,
            todaysGainPercentage:
              item?.isin === "INGridkeyCash" ? "" : item?.todaysGainPercentage,
            lastTradedPrice:
              item?.isin === "INGridkeyCash" ? "" : item?.lastTradedPrice,
            avgBuyPrice:
              item?.isin === "INGridkeyCash" ? "" : item?.avgBuyPrice,
            cmp: item?.isin === "INGridkeyCash" ? "" : item?.cmp,
            yesterdayValue:
              item?.isin === "INGridkeyCash" ? "" : item?.yesterdayValue,
            unrealisedGainPercentage:
              item?.isin === "INGridkeyCash"
                ? ""
                : item?.unrealisedGainPercentage,
            absoluteReturn:
              item?.isin === "INGridkeyCash" ? "" : item?.absoluteReturn,
            weightage: item?.weightage,
            modelWeightage: item?.modelWeightage,
            ltHoldingsCount:
              item?.isin === "INGridkeyCash" ? "" : item?.ltHoldingsCount,
          };
        }) || [],
        getComparator(order || "asc", orderBy || "companyName"),
      ),
    [order, orderBy, data?.data?.data?.data],
  );

  const returnEquityTotal = data?.data?.data?.equity;

  const returnCashTotal = data?.data?.data?.cash;

  const transactionColumns: GridColDef[] = [
    {
      headerName: `Company Name (${visibleRows?.length})`,
      field: "name",
    },
    {
      headerName: " Current weightage(%)",
      field: "weightage",
      type: "number",
    },
    {
      headerName: "Allocation Weightage(%)",
      type: "number",
      field: "modelWeightage",
    },
    {
      headerName: "Quantity",
      type: "number",
      field: "rawQuantity",
    },
    {
      headerName: "Avg. Buy Price",
      type: "number",
      field: "avgPrice",
    },
    {
      headerName: "Invested Amt.",
      type: "number",
      field: "investedAmount",
    },
    {
      headerName: "CMP",
      type: "number",
      field: "cmp",
    },
    {
      headerName: "Current Value",
      type: "number",
      field: "currentAmount",
    },
    {
      headerName: "Unrealised Gain",
      type: "number",
      field: "unrealisedGain",
    },
    {
      headerName: "Unrealised Gain %",
      type: "number",
      field: "absoluteReturn",
    },
    {
      headerName: "Today's Gain",
      type: "number",
      field: "todaysGain",
    },
    {
      headerName: "Today's Gain %",
      type: "number",
      field: "todaysGainPercentage",
    },
  ];
  function EnhancedTableHead(props: any) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow
          sx={{
            ":hover": {
              cursor: "pointer",
            },
          }}
        >
          {transactionColumns.map((headCell) => (
            <TableCell
              key={headCell.field}
              sortDirection={orderBy === headCell.field ? order : false}
              align={headCell.type !== "number" ? "left" : "right"}
            >
              <TableSortLabel
                active={orderBy === headCell.field}
                direction={orderBy === headCell.field ? order : "desc"}
                onClick={createSortHandler(headCell.field)}
              >
                {headCell.headerName}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={12}>
        <Grid2
          container
          spacing={1}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid2 display={"flex"} alignItems={"center"} gap={1}>
            <GKTextField
              placeholder="Search..."
              slotProps={{
                input: {
                  startAdornment: <LuSearch color={theme.palette.grey[400]} />,
                },
              }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 size={12}>
        <AnimationComponent>
          {isLoading ? (
            <SimpleLoader />
          ) : (
            <TableContainer sx={{ maxHeight: "calc(100vh - 236px)" }}>
              <Table stickyHeader>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  <TableRow
                    sx={{
                      ":hover": {
                        cursor: "pointer",
                      },
                    }}
                    style={{
                      background: theme.palette.grey[100],
                    }}
                    onClick={() => {
                      setIsRowOpened({
                        ...isRowOpened,
                        equity: !isRowOpened.equity,
                      });
                    }}
                  >
                    <TableCell
                      style={{
                        background: theme.palette.grey[100],
                      }}
                    >
                      <Typography
                        fontWeight={600}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <MdOutlineKeyboardArrowRight
                          size={18}
                          style={{
                            transform: isRowOpened?.equity
                              ? "rotate(90deg)"
                              : "rotate(0deg)",
                            transition: "transform 0.3s",
                          }}
                        />
                        Equity{" "}
                        {`(${
                          visibleRows?.filter(
                            (item) => item?.instSubType === "NCE",
                          )?.length
                        })`}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography fontWeight={600} fontSize={14}>
                        {moneyCommaSeparator(returnEquityTotal?.weightage) ||
                          "weightage"}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography fontWeight={600} fontSize={14}>
                        {moneyCommaSeparator(
                          returnEquityTotal?.modelWeightage,
                        ) || "modelWeightage"}
                      </Typography>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell align="right">
                      <Typography fontWeight={600} fontSize={14}>
                        {moneyCommaSeparator(returnEquityTotal?.investedAmount)}
                      </Typography>
                    </TableCell>
                    <TableCell />
                    <TableCell align="right">
                      <Typography fontWeight={600} fontSize={14}>
                        {moneyCommaSeparator(returnEquityTotal?.currentAmount)}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        fontWeight={600}
                        fontSize={14}
                        color={handleReturnPositiveNegative(
                          returnEquityTotal?.unrealisedGain,
                        )}
                      >
                        {moneyCommaSeparator(returnEquityTotal?.unrealisedGain)}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        fontWeight={600}
                        fontSize={14}
                        color={handleReturnPositiveNegative(
                          returnEquityTotal?.absoluteReturn,
                        )}
                      >
                        {returnEquityTotal?.absoluteReturn}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        fontWeight={600}
                        fontSize={14}
                        color={handleReturnPositiveNegative(
                          returnEquityTotal?.todaysGain,
                        )}
                      >
                        {moneyCommaSeparator(returnEquityTotal?.todaysGain)}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        fontWeight={600}
                        fontSize={14}
                        color={handleReturnPositiveNegative(
                          returnEquityTotal?.todaysGainPercentage,
                        )}
                      >
                        {returnEquityTotal?.todaysGainPercentage}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <Show.When isTrue={isRowOpened?.equity}>
                    {visibleRows
                      ?.filter((item) => item?.instSubType === "NCE")
                      ?.map((dataItem: any, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell align="left">
                              <Typography
                                display={"flex"}
                                alignItems={"center"}
                                gap={1}
                              >
                                {dataItem?.name}
                                {!dataItem?.isValid &&
                                  Boolean(dataItem?.isin) && (
                                    <Tooltip title="There might be some discrepancies in the trades of this asset.">
                                      <IconButton
                                        className="outline"
                                        color="error"
                                        sx={{
                                          background: "transparent",
                                          "&:hover": {
                                            background: "transparent",
                                            borderColor: "transparent",
                                            color: theme.palette.error.main,
                                          },
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      >
                                        <BsExclamationOctagon size={14} />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              {moneyCommaSeparator(dataItem?.weightage)}
                            </TableCell>
                            <TableCell align="right">
                              {moneyCommaSeparator(dataItem?.modelWeightage)}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Typography>
                                {dataItem?.rawQuantity || 0}
                              </Typography>
                              {dataItem?.ltHoldingsCount > 0 && (
                                <Tooltip
                                  title="Long Term Holding"
                                  arrow
                                  placement="top"
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginRight: "auto",
                                      color: theme.palette.primary.main,
                                      padding: 0,
                                      pr: 3,
                                    }}
                                  >
                                    <img
                                      loading="lazy"
                                      src="/Logos/longTerm.svg"
                                      alt=""
                                    />
                                    &nbsp;
                                    {dataItem?.instSubType !== "CE" ||
                                    dataItem.isin === "INGridkeyCash"
                                      ? dataItem?.ltHoldingsCount
                                      : dataItem?.ltHoldingsCount.toFixed(2)}
                                  </Box>
                                </Tooltip>
                              )}
                            </TableCell>
                            <TableCell align="right">
                              {moneyCommaSeparator(dataItem?.avgBuyPrice)}
                            </TableCell>
                            <TableCell align="right">
                              {moneyCommaSeparator(dataItem?.investedAmount)}
                            </TableCell>
                            <TableCell align="right">
                              {moneyCommaSeparator(dataItem?.cmp)}
                            </TableCell>
                            <TableCell align="right">
                              {moneyCommaSeparator(dataItem?.currentAmount)}
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                color={handleReturnPositiveNegative(
                                  dataItem?.unrealisedGain,
                                )}
                              >
                                {moneyCommaSeparator(dataItem?.unrealisedGain)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                color={handleReturnPositiveNegative(
                                  dataItem?.absoluteReturn,
                                )}
                              >
                                {moneyCommaSeparator(dataItem?.absoluteReturn)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                color={handleReturnPositiveNegative(
                                  dataItem?.todaysGain,
                                )}
                              >
                                {moneyCommaSeparator(dataItem?.todaysGain)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                color={handleReturnPositiveNegative(
                                  dataItem?.todaysGainPercentage,
                                )}
                              >
                                {moneyCommaSeparator(
                                  dataItem?.todaysGainPercentage,
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </Show.When>
                  <TableRow
                    sx={{
                      ":hover": {
                        cursor: "pointer",
                      },
                    }}
                    style={{
                      background: theme.palette.grey[100],
                    }}
                    onClick={() => {
                      setIsRowOpened({
                        ...isRowOpened,
                        cash: !isRowOpened.cash,
                      });
                    }}
                  >
                    <TableCell
                      style={{
                        background: theme.palette.grey[100],
                        fontWeight: 600,
                      }}
                    >
                      <Typography
                        fontWeight={600}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <MdOutlineKeyboardArrowRight
                          size={18}
                          style={{
                            transform: isRowOpened?.cash
                              ? "rotate(90deg)"
                              : "rotate(0deg)",
                            transition: "transform 0.3s",
                          }}
                        />
                        Cash & Cash Equivalent{" "}
                        {`(${
                          visibleRows?.filter(
                            (item) => item?.instSubType === "CE",
                          )?.length
                        })`}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography fontWeight={600} fontSize={14}>
                        {moneyCommaSeparator(returnCashTotal?.weightage) ||
                          "weightage"}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography fontWeight={600} fontSize={14}>
                        {moneyCommaSeparator(returnCashTotal?.modelWeightage) ||
                          "modelWeightage"}
                      </Typography>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell align="right">
                      <Typography fontWeight={600} fontSize={14}>
                        {moneyCommaSeparator(returnCashTotal?.currentAmount)}
                      </Typography>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                  <Show.When isTrue={isRowOpened?.cash}>
                    {visibleRows
                      ?.filter((item) => item?.instSubType === "CE")
                      ?.map((dataItem: any, index) => {
                        return (
                          <TableRow
                            sx={{
                              ":hover": {
                                cursor: "pointer",
                              },
                            }}
                            key={index}
                          >
                            <TableCell align="left">
                              <Typography
                                display={"flex"}
                                alignItems={"center"}
                                gap={1}
                              >
                                {dataItem?.name}
                                {!dataItem?.isValid &&
                                  Boolean(dataItem?.isin) && (
                                    <Tooltip title="There might be some discrepancies in the trades of this asset.">
                                      <IconButton
                                        className="outline"
                                        color="error"
                                        sx={{
                                          background: "transparent",
                                          "&:hover": {
                                            background: "transparent",
                                            borderColor: "transparent",
                                            color: theme.palette.error.main,
                                          },
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      >
                                        <BsExclamationOctagon size={14} />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              {moneyCommaSeparator(dataItem?.weightage)}
                            </TableCell>
                            <TableCell align="right">
                              {moneyCommaSeparator(dataItem?.modelWeightage)}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Typography>
                                {dataItem?.rawQuantity
                                  ? (dataItem?.rawQuantity).toFixed(2)
                                  : ""}
                              </Typography>
                              {dataItem?.ltHoldingsCount > 0 && (
                                <Tooltip
                                  title="Long Term Holding"
                                  arrow
                                  placement="top"
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginRight: "auto",
                                      color: theme.palette.primary.main,
                                      padding: 0,
                                      pr: 3,
                                    }}
                                  >
                                    <img
                                      loading="lazy"
                                      src="/Logos/longTerm.svg"
                                      alt=""
                                    />
                                    &nbsp;
                                    {dataItem?.instSubType !== "CE" ||
                                    dataItem.isin === "INGridkeyCash"
                                      ? dataItem?.ltHoldingsCount
                                      : dataItem?.ltHoldingsCount.toFixed(2)}
                                  </Box>
                                </Tooltip>
                              )}
                            </TableCell>
                            <TableCell align="right">
                              {moneyCommaSeparator(dataItem?.avgBuyPrice)}
                            </TableCell>
                            <TableCell align="right">
                              {moneyCommaSeparator(dataItem?.investedAmount)}
                            </TableCell>
                            <TableCell align="right">
                              {moneyCommaSeparator(dataItem?.cmp)}
                            </TableCell>
                            <TableCell align="right">
                              {moneyCommaSeparator(dataItem?.currentAmount)}
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                color={handleReturnPositiveNegative(
                                  dataItem?.unrealisedGain,
                                )}
                              >
                                {moneyCommaSeparator(dataItem?.unrealisedGain)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                color={handleReturnPositiveNegative(
                                  dataItem?.absoluteReturn,
                                )}
                              >
                                {moneyCommaSeparator(dataItem?.absoluteReturn)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                color={handleReturnPositiveNegative(
                                  dataItem?.todaysGain,
                                )}
                              >
                                {moneyCommaSeparator(dataItem?.todaysGain)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                color={handleReturnPositiveNegative(
                                  dataItem?.todaysGainPercentage,
                                )}
                              >
                                {moneyCommaSeparator(
                                  dataItem?.todaysGainPercentage,
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </Show.When>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </AnimationComponent>
      </Grid2>
    </Grid2>
  );
};

export default ModelHoldings;
