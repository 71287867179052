import { Grid2 } from "@mui/material";
import GKModal from "components/GKModal";
import AdvanceFeeHistory from "./AdvanceFeeHistory";
import FeeDepositHistory from "./FeeDepositHistory";
import FeePlansTab from "./FeePlansTab";
import HighWaterMarkHistory from "./HighWaterMarkHistory";

interface Props {
  open: {
    open: boolean;
    type: string;
    modalTitle: string;
  };
  setOpen: (open: { open: boolean; type: string; modalTitle: string }) => void;
}

const RevenueActionModal = (props: Props) => {
  const { open, setOpen } = props;

  const handleReturnComponent = (type: string) => {
    switch (type) {
      case "FEE_PLAN_HISTORY":
        return <FeePlansTab action />;
      case "HIGH_WATERMARK":
        return <HighWaterMarkHistory />;
      case "ADVANCE_FEE_HISTORY":
        return <AdvanceFeeHistory />;
      case "FEE_DEPOSIT_HISTORY":
        return <FeeDepositHistory />;

      default:
        return <FeePlansTab action />;
    }
  };

  return (
    <GKModal
      modalTitle={open.modalTitle}
      open={open.open}
      setOpen={() => setOpen({ open: false, type: "", modalTitle: "" })}
      width={"60%"}
    >
      <Grid2 container>{handleReturnComponent(open.type)}</Grid2>
    </GKModal>
  );
};

export default RevenueActionModal;
