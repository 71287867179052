import { useAppContext } from "AppContext";
import { ErrorPage, Loader, NoInternetWrapper } from "components";
import CompanyNameModal from "Pages/CompanyInformation/CompanyNameModal";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  error,
  resetErrorBoundary,
}) => <ErrorPage error={error} resetErrorBoundary={resetErrorBoundary} />;

const AppLayout: React.FC = () => {
  const {
    state: { userLoading, isFirstLogin },
    dispatch,
  } = useAppContext();

  const closeModal = () => {
    dispatch({ type: "IS_FIRST_LOGIN", data: !isFirstLogin });
  };

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {userLoading ? (
          <Loader />
        ) : (
          <NoInternetWrapper>
            <Outlet />
          </NoInternetWrapper>
        )}
      </ErrorBoundary>

      {isFirstLogin && <CompanyNameModal open setOpen={closeModal} />}
    </>
  );
};

export default AppLayout;
