import { GKButton, GKModal, Grid2 } from "components";
import { GKModalProps } from "utils/commonInterface";

interface Props {
  submit: any;
  setMappingModal: any;
  setUploadModal: any;
}

const SheetConfirmation = ({
  setOpen,
  open,
  submit,
  setMappingModal,
  setUploadModal,
}: Props & GKModalProps) => {
  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Proceed"
      footer={
        <Grid2
          container
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
        >
          <GKButton
            variant="contained"
            color="error"
            onClick={() => {
              setOpen(false);
              setMappingModal(false);
              setUploadModal(false);
              submit?.setFieldValue(`sheetTypeName`, "");
              submit.setFieldValue("file", []);
            }}
          >
            No
          </GKButton>
          <GKButton
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(false);
              setMappingModal(false);
              setUploadModal(false);
              submit.handleSubmit();
            }}
          >
            Yes
          </GKButton>
        </Grid2>
      }
    >
      <Grid2 container>
        <Grid2 size={12} textAlign={"center"}>
          Would you like to proceed with importing the trade data from this
          file?
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default SheetConfirmation;
