import { LoadingButton } from "@mui/lab";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMutation } from "@tanstack/react-query";
import {
  FormControlLabel,
  GKClientDataGrid,
  GKSideModal,
  Grid2,
  Switch,
  Typography,
} from "components";
import GKCheckbox from "components/GKCheckbox";
import { useFetchQuery } from "hooks/useQueries";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  orderId: number;
  sendEmailType?: string;
}

const PreTradeConfirmationModal = (props: Props) => {
  const { open, setOpen, orderId, sendEmailType } = props;

  const form = useForm({
    defaultValues: {
      portfolioIds: [],
      whatsappPortfolios: [],
      useMarketPrice: false,
    },
  });

  form.watch();

  const { data, isFetching: preTradeLoading } = useFetchQuery({
    key: ["GET_PRE_TRADE_CONFIRMATION_PORTFOLIO_LIST"],
    enabled: sendEmailType === "TRADE",
    route: `/model_portfolio/${orderId}/order-portfolios/`,
  });

  const { data: ordersData, isFetching: orderLoading } = useFetchQuery({
    key: ["GET_SMALL_CASE_PORTFOLIO_LIST"],
    enabled: sendEmailType === "ORDER",
    route: `/model_portfolio/${orderId}/smallcase-order-portfolios/`,
  });

  const { mutate: sendOrderEmail, isLoading } = useMutation({
    mutationKey: ["SEND_ORDER_EMAIL"],
    mutationFn: (data) => axiosInstance.post("/order-email/", data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: sendTradeEmail, isLoading: tradeLoading } = useMutation({
    mutationKey: ["SEND_TRADE_EMAIL"],
    mutationFn: (data) =>
      axiosInstance.post("/smallcase-trade/email-send/", data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const onSubmit = (data: any) => {
    if (sendEmailType === "TRADE") {
      sendOrderEmail({ ...data, order_aggregator_id: orderId });
    } else {
      sendTradeEmail({ ...data, order_aggregator_id: orderId });
    }
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Portfolio", "clientName", 200, 1),
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography fontWeight={700}>{params?.row?.clientName}</Typography>
            <Typography fontSize={12}>
              {params?.row?.name} - {params?.row?.portfolioId}
            </Typography>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Email", "clientEmail", 300, 1),
      sortable: false,
      align: "left",
      valueGetter: (params) => params.row.clientEmail || "-",
      renderHeader: () => {
        const allEmailsExist = (
          data?.data?.portfolio || ordersData?.data?.data?.portfolio
        )?.every((item: any) => !item.clientEmail);

        const validPortfolios = (
          data?.data?.portfolio || ordersData?.data?.data?.portfolio
        )?.filter(
          (dataItem: any) => dataItem.clientEmail && dataItem.clientPhoneNumber,
        );

        return (
          <Grid2 container gap={1} display={"flex"} alignItems={"center"}>
            <GKCheckbox
              disabled={allEmailsExist}
              indeterminate={allEmailsExist}
              checked={
                validPortfolios?.length ===
                  form.getValues("portfolioIds").length || false
              }
              onChange={(e) => {
                e.stopPropagation();
                form.setValue(
                  "portfolioIds",
                  e.target.checked
                    ? validPortfolios.map((dataItem: any) => dataItem.id)
                    : [],
                );
              }}
            />
            <Typography fontWeight={400} fontSize={12} color={"#6B7280"}>
              Email
            </Typography>
          </Grid2>
        );
      },
      renderCell: (params) => {
        return (
          <Grid2 container gap={1} display={"flex"} alignItems={"center"}>
            <Controller
              name="portfolioIds"
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <GKCheckbox
                  sx={{
                    padding: 0,
                  }}
                  disabled={
                    !params.row.clientPhoneNumber || !params.row.clientEmail
                  }
                  indeterminate={
                    !params.row.clientPhoneNumber || !params.row.clientEmail
                  }
                  checked={value.includes(params.row.id)}
                  onChange={(e) =>
                    onChange(
                      e.target.checked
                        ? [...value, params.row.id]
                        : value.filter((id: string) => id !== params.row.id),
                    )
                  }
                />
              )}
            />
            <Typography>{params.row.clientEmail || "-"}</Typography>
          </Grid2>
        );
      },
    },
    sendEmailType === "ORDER" && {
      ...extraColumnsField("Phone", "clientPhoneNumber", 170, 1),
      sortable: false,
      valueGetter: (params) => params.row.clientPhoneNumber || "-",
      renderHeader: () => {
        const allEmailsExist = (
          data?.data?.portfolio || ordersData?.data?.data?.portfolio
        )?.every((item: any) => !item.clientEmail);

        const validPortfolios = (
          data?.data?.portfolio || ordersData?.data?.data?.portfolio
        )?.filter(
          (dataItem: any) => dataItem.clientEmail && dataItem.clientPhoneNumber,
        );

        return (
          <Grid2 container gap={1} display={"flex"} alignItems={"center"}>
            <GKCheckbox
              disabled={allEmailsExist}
              indeterminate={allEmailsExist}
              checked={
                validPortfolios?.length ===
                  form.getValues("whatsappPortfolios")?.length || false
              }
              onChange={(e) => {
                e.stopPropagation();
                form.setValue(
                  "whatsappPortfolios",
                  e.target.checked
                    ? validPortfolios?.map((dataItem: any) => dataItem.id)
                    : [],
                );
              }}
            />
            <Typography fontWeight={400} fontSize={12} color={"#6B7280"}>
              Phone
            </Typography>
          </Grid2>
        );
      },
      renderCell: (params) => {
        return (
          <Grid2 container gap={1} display={"flex"} alignItems={"center"}>
            <Controller
              name="whatsappPortfolios"
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <GKCheckbox
                  sx={{ padding: 0 }}
                  disabled={
                    !params.row.clientPhoneNumber || !params.row.clientEmail
                  }
                  indeterminate={
                    !params.row.clientPhoneNumber || !params.row.clientEmail
                  }
                  checked={value.includes(params.row.id)}
                  onChange={(e) =>
                    onChange(
                      e.target.checked
                        ? [...value, params.row.id]
                        : value.filter((id: string) => id !== params.row.id),
                    )
                  }
                />
              )}
            />
            <Typography>{params.row.clientPhoneNumber || "-"}</Typography>
          </Grid2>
        );
      },
    },
    // sendEmailType === "ORDER" && {
    //   headerName: "Whatsapp",
    //   field: "whatsapp",
    //   disableColumnMenu: true,
    //   flex: 1,
    //   minWidth: 100,
    //   renderCell: (params) => {
    //     return (
    //       <Controller
    //         name="whatsappPortfolios"
    //         control={form.control}
    //         render={({ field: { onChange, value } }) => (
    //           <GKCheckbox
    //             disabled={!params.row.clientPhoneNumber}
    //             indeterminate={!params.row.clientPhoneNumber}
    //             checked={value.includes(params.row.id)}
    //             onChange={(e) =>
    //               onChange(
    //                 e.target.checked
    //                   ? [...value, params.row.id]
    //                   : value.filter((id: string) => id !== params.row.id)
    //               )
    //             }
    //           />
    //         )}
    //       />
    //     );
    //   },
    // },
    // {
    //   headerName: "Email",
    //   field: "mail",
    //   disableColumnMenu: true,
    //   flex: 1,
    //   minWidth: 100,
    //   renderCell: (params) => {
    //     return (
    //       <Controller
    //         name="portfolioIds"
    //         control={form.control}
    //         render={({ field: { onChange, value } }) => (
    //           <GKCheckbox
    //             disabled={!params.row.clientEmail}
    //             indeterminate={!params.row.clientEmail}
    //             checked={value.includes(params.row.id)}
    //             onChange={(e) =>
    //               onChange(
    //                 e.target.checked
    //                   ? [...value, params.row.id]
    //                   : value.filter((id: string) => id !== params.row.id)
    //               )
    //             }
    //           />
    //         )}
    //       />
    //     );
    //   },
    // },
  ];

  return (
    <GKSideModal
      width="60%"
      open={open}
      setOpen={setOpen}
      onClose={() => {
        setOpen(false);
      }}
      modalTitle={
        sendEmailType === "TRADE" ? "Send Order Sheet" : "Send for Execution"
      }
      footerButton={
        <Grid2 container justifyContent={"flex-end"}>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isLoading}
            disabled={
              isLoading ||
              tradeLoading ||
              (!form.getValues("portfolioIds").length &&
                !form.getValues("whatsappPortfolios").length)
            }
            form="pre-trade-confirmation-form"
          >
            Send
          </LoadingButton>
        </Grid2>
      }
    >
      <form
        id="pre-trade-confirmation-form"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <GKClientDataGrid
          headerComponent={
            <Controller
              name="useMarketPrice"
              control={form.control}
              render={({ field }) => {
                return (
                  <FormControlLabel
                    {...field}
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                      />
                    }
                    label="Use Market Price"
                  />
                );
              }}
            />
          }
          name="preTradeConfirmationModal"
          loading={sendEmailType === "TRADE" ? preTradeLoading : orderLoading}
          // showHeaderGrid={false}
          columns={columns}
          rows={
            sendEmailType === "TRADE"
              ? data?.data?.portfolio
              : ordersData?.data?.data?.portfolio
          }
          maxHeight={"calc(100vh - 355px)"}
        />
      </form>
    </GKSideModal>
  );
};

export default PreTradeConfirmationModal;
