/* eslint-disable no-unneeded-ternary */
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  GET_ALL_FAVORITE_UPLOAD,
  GET_COMPANY_LIST,
  TRANSACTION_UPLOAD_CONTRACT_NOTE,
} from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { handleDowloadFile } from "utils/fileHelper";
import { handleDownloadFile, refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { useFetchQuery } from "./useQueries";

export const useUploadmanager = ({
  isApproveModal,
  fileData,
  setOpen,
}: any) => {
  const {
    state: { userData: userInfo, portfolios },
  } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);

  const [isPortfolioSubmitLoading, setPortfolioSubmitIsLoading] =
    useState(false);

  const [duplicateIds, setDuplicateIds] = useState([]);

  const [selectedId, setSelectedId] = useState(null);

  const [duplicateResponseIds, setDuplicateResponseIds] = useState([]);

  const [duplicatesResponse, setDuplicatesResponse] = useState<any>();

  const [sheetUploadId, setSheetUploadId] = useState(null);

  const [showFavoriteModal, setShowFavoriteModal] = useState(false);

  const [reviewResponse, setReviewResponse] = useState<any>();

  const [isPortfolioCreateModalOpen, setIsPortfolioCreateModalOpen] =
    useState<boolean>(false);

  const [uploadData, setUploadData] = useState(fileData || null);

  const [showDuplicates, setShowDuplicates] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const [allFiles, setAllFiles] = useState([]);

  const [templatesTabs, setTemplatesTabs] = useState(1);

  /** ========================================================================================= */
  const handleNext = () => {
    setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
  };

  /** ========================================================================================= */
  const handleReset = () => {
    setActiveStep(0);
  };

  /** ========================================================================================= */
  const handleReviewWithDupComplete = async (params: any) => {
    setIsLoading(true);
    handleNext();
    await axiosInstance
      ?.post(
        `${params.fileObject.reviewUrlPath}?commit=true&id=${uploadData?.id}`,
        {
          duplicates_ids: [
            ...duplicateResponseIds,
            ...duplicatesResponse?.transactions
              ?.filter((data: any) => !data?.isDuplicate)
              ?.map((data: any) => data?.id),
          ],
        },
      )
      .then((response) => {
        setIsLoading(false);
        setActiveStep(6);
        setAllFiles(
          allFiles?.map((item) => {
            return {
              ...item,
              inProgress:
                item?.data?.id === uploadData?.id ? true : item?.finished,
              finished:
                item?.data?.id === uploadData?.id ? true : item?.finished,
              data:
                item?.data?.id === uploadData?.id
                  ? response?.data?.data
                  : item?.data,
            };
          }),
        );
        refetchQuery(QueryKeys.UPLOAD_MANAGER_LIST);
      })
      .catch((error) => {
        setIsLoading(false);
        setActiveStep(1);
        setAllFiles(
          allFiles?.map((item) => {
            return {
              ...item,
              inProgress:
                item?.data?.id === uploadData?.id ? true : item?.finished,
              finished:
                item?.data?.id === uploadData?.id ? true : item?.finished,
              hasError:
                item?.data?.id === uploadData?.id ? true : item?.hasError,
              status:
                item?.data?.id === uploadData?.id ? "failed" : item?.status,
              message:
                item?.data?.id === uploadData?.id
                  ? error?.response?.data?.message
                  : item?.message,
            };
          }),
        );
        refetchQuery(QueryKeys.UPLOAD_MANAGER_LIST);
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      });
  };
  /** ========================================================================================= */
  const handleReviewComplete = async (params: any) => {
    setIsLoading(true);
    handleNext();
    await axiosInstance
      ?.post(
        `${params.fileObject.reviewUrlPath}?commit=true&id=${uploadData?.id}`,
        {
          duplicates_ids: duplicatesResponse?.transactions?.map(
            (data: any) => data?.id,
          ),
        },
      )
      .then((response) => {
        setIsLoading(false);
        setActiveStep(6);
        setAllFiles(
          allFiles?.map((item) => {
            return {
              ...item,
              inProgress:
                item?.data?.id === uploadData?.id ? true : item?.finished,
              finished:
                item?.data?.id === uploadData?.id ? true : item?.finished,
              data:
                item?.data?.id === uploadData?.id
                  ? response?.data?.data
                  : item?.data,
            };
          }),
        );
        refetchQuery(QueryKeys.UPLOAD_MANAGER_LIST);
      })
      .catch((error) => {
        setAllFiles(
          allFiles?.map((item) => {
            return {
              ...item,
              inProgress:
                item?.data?.id === uploadData?.id ? true : item?.finished,
              finished:
                item?.data?.id === uploadData?.id ? true : item?.finished,
              hasError:
                item?.data?.id === uploadData?.id ? true : item?.hasError,
              status:
                item?.data?.id === uploadData?.id ? "failed" : item?.status,
              message:
                item?.data?.id === uploadData?.id
                  ? error?.response?.data?.message
                  : item?.message,
            };
          }),
        );
        refetchQuery(QueryKeys.UPLOAD_MANAGER_LIST);
        setIsLoading(false);
        setActiveStep(1);
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      });
  };

  /** ========================================================================================= */
  const handleNoDUplicatesReviewComplete = async (params: any) => {
    setIsLoading(true);
    handleNext();
    await axiosInstance
      ?.post(
        `${params.fileObject.reviewUrlPath}?commit=true&id=${uploadData?.id}`,
        {
          duplicates_ids: [
            ...duplicatesResponse?.transactions
              ?.filter((data: any) => !data?.isDuplicate)
              ?.map((data: any) => data?.id),
          ],
        },
      )
      .then((response) => {
        setIsLoading(false);
        setActiveStep(6);
        setAllFiles(
          allFiles?.map((item) => {
            return {
              ...item,
              inProgress:
                item?.data?.id === uploadData?.id ? true : item?.finished,
              finished:
                item?.data?.id === uploadData?.id ? true : item?.finished,
              data:
                item?.data?.id === uploadData?.id
                  ? response?.data?.data
                  : item?.data,
            };
          }),
        );
        refetchQuery(QueryKeys.UPLOAD_MANAGER_LIST);
      })
      .catch((error) => {
        refetchQuery(QueryKeys.UPLOAD_MANAGER_LIST);
        setIsLoading(false);
        setActiveStep(1);
        setAllFiles(
          allFiles?.map((item) => {
            return {
              ...item,
              inProgress:
                item?.data?.id === uploadData?.id ? true : item?.finished,
              finished:
                item?.data?.id === uploadData?.id ? true : item?.finished,
              hasError:
                item?.data?.id === uploadData?.id ? true : item?.hasError,
              status:
                item?.data?.id === uploadData?.id ? "failed" : item?.status,
              message:
                item?.data?.id === uploadData?.id
                  ? error?.response?.data?.message
                  : item?.message,
            };
          }),
        );
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      });
  };

  /** ========================================================================================= */
  const { data: menuOptions } = useFetchQuery({
    key: [QueryKeys.UPLOAD_MANAGER_SELECT_OPTIONS],
    route: TRANSACTION_UPLOAD_CONTRACT_NOTE,
  });

  /** ========================================================================================= */
  const {
    data: favoriteUploadSamples,
    refetch: favoriteRefetch,
    isFetching: facLoading,
  } = useFetchQuery({
    key: [QueryKeys.UPLOAD_MANAGER_FAVORITE_SAMPLES],
    route: GET_ALL_FAVORITE_UPLOAD,
  });

  /** ========================================================================================= */
  const downloadSingleRowErrors = async (id: number, filename: string) => {
    const exactFileName = filename.split(".csv")[0];

    await axiosInstance
      ?.get(`/transaction_upload/${id}/download_errors/?full=false`)
      .then((response: any) => {
        handleDowloadFile({
          fileData: response.data,
          applicaionType: "text/csv",
          fileName: `${exactFileName}.csv`,
          extensionType: "csv",
        });
      })
      .catch((response: any) => {
        const error = response?.json();
        toast.error(error?.message || "Something went wrong");
      });
  };

  /** ========================================================================================= */
  const handleModalTitle = (step: number) => {
    switch (step) {
      case 0:
        return { form: "", title: "Import" };
      case 1:
        return { form: "", title: "Multiple Form" };
      case 2:
        return { form: "portfolio-mapping-form", title: "Portfolio Mapping" };
      case 3:
        return { form: "company-mapping-form", title: "Company Mapping" };
      case 4:
        return { form: "", title: "Review imported data " };
      case 5:
        return { form: "", title: "Transaction Completed" };

      default:
        return { form: "", title: "Import Manager" };
    }
  };

  /** ========================================================================================= */
  const companyFormik = useFormik({
    initialValues: {
      nullCompanies: [],
      fileObject: null,
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      await axiosInstance
        ?.post(`/advisor-company-mappings/`, values.nullCompanies)
        .then(() => {
          handleNext();
          axiosInstance
            .post(`${values.fileObject.reviewUrlPath}?id=${uploadData?.id}`)
            .then((reviewApiResponse) => {
              setActiveStep(4);
              setDuplicatesResponse({
                ...reviewApiResponse?.data,
                transactions: reviewApiResponse?.data?.transactions?.map(
                  (data: any) => {
                    return { ...data };
                  },
                ),
              });
              setReviewResponse({
                ...reviewApiResponse?.data,
                transactions: reviewApiResponse?.data?.transactions?.map(
                  (data: any) => {
                    return { ...data };
                  },
                ),
              });
              setIsLoading(false);
            })
            .catch((error) => {
              setIsLoading(false);
              setActiveStep(1);
              setAllFiles(
                allFiles?.map((item) => {
                  return {
                    ...item,
                    finished:
                      item?.data?.id === uploadData?.id ? true : item?.finished,
                    hasError:
                      item?.data?.id === uploadData?.id ? true : item?.data,
                  };
                }),
              );
              toast.error(
                error?.response?.data?.message ||
                  "Something went wrong, please try again.",
              );
            });
        })
        .catch((error) => {
          setIsLoading(false);
          setActiveStep(1);
          setAllFiles(
            allFiles?.map((item) => {
              return {
                ...item,
                finished:
                  item?.data?.id === uploadData?.id ? true : item?.finished,
                hasError:
                  item?.data?.id === uploadData?.id ? true : item?.hasError,
                status:
                  item?.data?.id === uploadData?.id ? "failed" : item?.status,
                message:
                  item?.data?.id === uploadData?.id
                    ? error?.response?.data?.message
                    : item?.message,
              };
            }),
          );
          toast.error(
            error?.response?.data?.message ||
              "Something went wrong, please try again.",
          );
        });
    },
  });

  /** ========================================================================================= */
  const portfolioFormik = useFormik({
    initialValues: {
      nullPortfolios: [],
      fileVersion: "",
      fileObject: null,
    },
    onSubmit: (values) => {
      axiosInstance
        .post(
          `${values.fileObject.portfolioMappingUrlPath}?id=${uploadData?.id}`,
          values.nullPortfolios?.map((item) => {
            return {
              value: item.value,
              portfolio: item.portfolio,
            };
          }),
        )
        .then((response) => {
          toast.success(
            response?.data?.message ||
              response?.data?.status ||
              "Portfolio successfully mapped.",
          );

          setIsLoading(true);
          handleNext();
          if (values?.fileObject?.companyMapping) {
            axiosInstance
              ?.get(
                `${values.fileObject.companyMappingUrlPath}?id=${uploadData?.id}`,
              )
              .then((companyMappingResponse) => {
                const companies = companyMappingResponse?.data?.data?.filter(
                  (data: any) => data?.value === null,
                );

                companyFormik?.setFieldValue("nullCompanies", companies);

                if (companies?.length !== 0) {
                  setIsLoading(false);
                  setActiveStep(3);
                } else {
                  setActiveStep(4);
                  axiosInstance
                    .post(
                      `${values.fileObject.reviewUrlPath}?id=${uploadData?.id}`,
                    )
                    .then((reviewApiResponse) => {
                      setActiveStep(4);
                      setDuplicatesResponse({
                        ...reviewApiResponse?.data,
                        transactions:
                          reviewApiResponse?.data?.transactions?.map(
                            (data: any) => {
                              return { ...data };
                            },
                          ),
                      });
                      setReviewResponse({
                        ...reviewApiResponse?.data,
                        transactions:
                          reviewApiResponse?.data?.transactions?.map(
                            (data: any) => {
                              return { ...data };
                            },
                          ),
                      });
                      setIsLoading(false);
                    })
                    .catch((error) => {
                      setIsLoading(false);
                      setActiveStep(1);
                      setAllFiles(
                        allFiles?.map((item) => {
                          return {
                            ...item,
                            finished:
                              item?.data?.id === uploadData?.id
                                ? true
                                : item?.finished,
                            hasError:
                              item?.data?.id === uploadData?.id
                                ? true
                                : item?.data,
                          };
                        }),
                      );
                      toast.error(
                        error?.response?.data?.message ||
                          "Something went wrong, please try again.",
                      );
                    });
                }
              })
              .catch((error) => {
                setIsLoading(false);
                setActiveStep(1);
                setAllFiles(
                  allFiles?.map((item) => {
                    return {
                      ...item,
                      finished:
                        item?.data?.id === uploadData?.id
                          ? true
                          : item?.finished,
                      hasError:
                        item?.data?.id === uploadData?.id ? true : item?.data,
                      status:
                        item?.data?.id === uploadData?.id
                          ? "failed"
                          : item?.status,
                      message:
                        item?.data?.id === uploadData?.id
                          ? error?.response?.data?.message
                          : item?.message,
                    };
                  }),
                );
                toast.error(
                  error?.response?.data?.message ||
                    "Something went wrong, please try again.",
                );
              });
          } else {
            setActiveStep(3);
            axiosInstance
              .post(`${values.fileObject.reviewUrlPath}?id=${uploadData?.id}`)
              .then((reviewApiResponse) => {
                setActiveStep(4);
                setDuplicatesResponse({
                  ...reviewApiResponse?.data,
                  transactions: reviewApiResponse?.data?.transactions?.map(
                    (data: any) => {
                      return { ...data };
                    },
                  ),
                });
                setReviewResponse({
                  ...reviewApiResponse?.data,
                  transactions: reviewApiResponse?.data?.transactions?.map(
                    (data: any) => {
                      return { ...data };
                    },
                  ),
                });
                setIsLoading(false);
              })
              .catch((error) => {
                setIsLoading(false);
                setActiveStep(1);
                setAllFiles(
                  allFiles?.map((item) => {
                    return {
                      ...item,
                      finished:
                        item?.data?.id === uploadData?.id
                          ? true
                          : item?.finished,
                      hasError:
                        item?.data?.id === uploadData?.id ? true : item?.data,
                      status:
                        item?.data?.id === uploadData?.id
                          ? "failed"
                          : item?.status,
                      message:
                        item?.data?.id === uploadData?.id
                          ? error?.response?.data?.message
                          : item?.message,
                    };
                  }),
                );
                toast.error(
                  error?.response?.data?.message ||
                    "Something went wrong, please try again.",
                );
              });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setActiveStep(1);
          setAllFiles(
            allFiles?.map((item) => {
              return {
                ...item,
                finished:
                  item?.data?.id === uploadData?.id ? true : item?.finished,
                hasError:
                  item?.data?.id === uploadData?.id ? true : item?.hasError,
                status:
                  item?.data?.id === uploadData?.id ? "failed" : item?.status,
                message:
                  item?.data?.id === uploadData?.id
                    ? error?.response?.data?.message
                    : item?.message,
              };
            }),
          );
          toast.error(
            error?.response?.data?.message ||
              "Something went wrong, please try again.",
          );
        });
    },
  });

  /** ========================================================================================= */
  const formik = useFormik({
    initialValues: {
      fileObject: null,
      fileUploadType: "",
      favoriteType: "",
      filePassword: "",
      sheetTypeId: "",
      fileIndex: null,
      sheetTypeName: "",
      fileBrokerType: "",
      fileType: "",
      fileVersion: "",
      date: "",
      sampleAvailable: false,
      isNewTemplate: false,
      file: [],
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      const appendedFiles = new FormData();
      values.file.forEach((file) => {
        appendedFiles.append(
          values?.fileUploadType === "Portfolio" ? "file" : "files",
          file,
        );
      });

      const formatedDate = dayjs(`${formik.values?.date}`).format("YYYY-MM-DD");
      appendedFiles.append("password", formik.values?.filePassword);
      appendedFiles.append("date", formatedDate);
      /** FILE UPLOAD API============================================================================ */
      if (!isApproveModal) {
        axiosInstance
          .post(values?.fileObject?.urlPath, appendedFiles)
          .then((response) => {
            setUploadData(response?.data);
            if (values.fileObject.portfolioMapping) {
              axiosInstance
                ?.get(
                  `${values?.fileObject?.portfolioMappingUrlPath}?id=${response?.data?.id}`,
                )
                .then((mappingResponse) => {
                  const portfolios = mappingResponse?.data?.data?.filter(
                    (data: any) => data?.portfolio === null,
                  );

                  setAllFiles(
                    allFiles?.map((item, index) => {
                      return {
                        ...item,
                        data:
                          index === formik.values?.fileIndex
                            ? response?.data
                            : item?.data,
                      };
                    }),
                  );
                  portfolioFormik?.setFieldValue("nullPortfolios", portfolios);
                  portfolioFormik?.setFieldValue(
                    "fileVersion",
                    values.fileVersion,
                  );
                  if (portfolios?.length !== 0) {
                    setIsLoading(false);
                    setActiveStep(2);
                  } else if (values.fileObject.companyMapping) {
                    handleNext();
                    /** COMPANY MAPPING API============================================================================ */
                    axiosInstance
                      ?.get(
                        `${values.fileObject.companyMappingUrlPath}?id=${response?.data?.id}`,
                      )
                      .then((companyMappingResponse) => {
                        const companies =
                          companyMappingResponse?.data?.data?.filter(
                            (data: any) => data?.value === null,
                          );
                        companyFormik?.setFieldValue(
                          "nullCompanies",
                          companies,
                        );

                        if (companies?.length !== 0) {
                          setIsLoading(false);
                          setActiveStep(3);
                        } else {
                          setActiveStep(4);
                          axiosInstance
                            .post(
                              `${values.fileObject.reviewUrlPath}?id=${response?.data?.id}`,
                            )
                            .then((reviewApiResponse) => {
                              setActiveStep(4);

                              setDuplicatesResponse({
                                ...reviewApiResponse?.data,
                                transactions:
                                  reviewApiResponse?.data?.transactions?.map(
                                    (data: any) => {
                                      return { ...data };
                                    },
                                  ),
                              });
                              setReviewResponse({
                                ...reviewApiResponse?.data,
                                transactions:
                                  reviewApiResponse?.data?.transactions?.map(
                                    (data: any) => {
                                      return { ...data };
                                    },
                                  ),
                              });
                              setIsLoading(false);
                            })
                            .catch((error) => {
                              setIsLoading(false);
                              setAllFiles(
                                allFiles?.map((item, index) => {
                                  return {
                                    ...item,
                                    hasError:
                                      index === formik.values?.fileIndex
                                        ? true
                                        : item?.hasError,
                                    finished:
                                      index === formik.values?.fileIndex
                                        ? true
                                        : item?.finished,
                                    status:
                                      index === formik.values?.fileIndex
                                        ? "failed"
                                        : item?.status,
                                    message:
                                      index === formik.values?.fileIndex
                                        ? error?.response?.data?.message
                                        : item?.message,
                                  };
                                }),
                              );
                              setActiveStep(1);
                              toast.error(
                                error?.response?.data?.message ||
                                  "Something went wrong, please try again.",
                              );
                            });
                        }
                      })
                      .catch((error) => {
                        setIsLoading(false);
                        setAllFiles(
                          allFiles?.map((item, index) => {
                            return {
                              ...item,
                              hasError:
                                index === formik.values?.fileIndex
                                  ? true
                                  : item?.hasError,
                              finished:
                                index === formik.values?.fileIndex
                                  ? true
                                  : item?.finished,
                            };
                          }),
                        );
                        setActiveStep(1);
                        toast.error(
                          error?.response?.data?.message ||
                            "Something went wrong, please try again.",
                        );
                      });
                  } else {
                    setActiveStep(4);
                    axiosInstance
                      .post(
                        `${values.fileObject.reviewUrlPath}?id=${response?.data?.id}`,
                      )
                      .then((reviewApiResponse) => {
                        setActiveStep(4);
                        setDuplicatesResponse({
                          ...reviewApiResponse?.data,
                          transactions:
                            reviewApiResponse?.data?.transactions?.map(
                              (data: any) => {
                                return { ...data };
                              },
                            ),
                        });
                        setReviewResponse({
                          ...reviewApiResponse?.data,
                          transactions:
                            reviewApiResponse?.data?.transactions?.map(
                              (data: any) => {
                                return { ...data };
                              },
                            ),
                        });
                        setIsLoading(false);
                      })
                      .catch((error) => {
                        setIsLoading(false);
                        setAllFiles(
                          allFiles?.map((item, index) => {
                            return {
                              ...item,
                              hasError:
                                index === formik.values?.fileIndex
                                  ? true
                                  : item?.hasError,
                              finished:
                                index === formik.values?.fileIndex
                                  ? true
                                  : item?.finished,
                              status:
                                index === formik.values?.fileIndex
                                  ? "failed"
                                  : item?.status,
                              message:
                                index === formik.values?.fileIndex
                                  ? error?.response?.data?.message
                                  : item?.message,
                            };
                          }),
                        );
                        setActiveStep(1);
                        toast.error(
                          error?.response?.data?.message ||
                            "Something went wrong, please try again.",
                        );
                      });
                  }
                })
                .catch((error) => {
                  setIsLoading(false);
                  setAllFiles(
                    allFiles?.map((item, index) => {
                      return {
                        ...item,
                        hasError:
                          index === formik.values?.fileIndex
                            ? true
                            : item?.hasError,
                        finished:
                          index === formik.values?.fileIndex
                            ? true
                            : item?.finished,
                        status:
                          index === formik.values?.fileIndex
                            ? "failed"
                            : item?.status,
                        message:
                          index === formik.values?.fileIndex
                            ? error?.response?.data?.message
                            : item?.message,
                      };
                    }),
                  );
                  setActiveStep(1);
                  toast.error(
                    error?.response?.data?.message ||
                      "Something went wrong, please try again.",
                  );
                });
            } else {
              setAllFiles(
                allFiles?.map((item, index) => {
                  return {
                    ...item,
                    finished:
                      index === formik.values?.fileIndex
                        ? true
                        : item?.finished,
                    status:
                      index === formik.values?.fileIndex
                        ? "Completed"
                        : item?.status,
                    data:
                      index === formik.values?.fileIndex
                        ? response?.data
                        : item?.data,
                  };
                }),
              );
              setActiveStep(4);
              setIsLoading(false);
              setDuplicatesResponse({
                transactions: response?.data?.map(
                  (data: any, index: number) => {
                    return { ...data, id: index };
                  },
                ),
              });
              setReviewResponse({
                transactions: response?.data?.map(
                  (data: any, index: number) => {
                    return {
                      ...data,
                      id: index,
                    };
                  },
                ),
              });
            }
          })
          .catch((error) => {
            setIsLoading(false);
            setActiveStep(1);
            setAllFiles(
              allFiles?.map((item, index) => {
                return {
                  ...item,
                  hasError:
                    index === formik.values?.fileIndex ? true : item?.hasError,
                  finished:
                    index === formik.values?.fileIndex ? true : item?.finished,
                  status:
                    index === formik.values?.fileIndex
                      ? "failed"
                      : item?.status,
                  message:
                    index === formik.values?.fileIndex
                      ? error?.response?.data?.message
                      : item?.message,
                };
              }),
            );
            toast.error(
              error?.response?.data?.message ||
                "Something went wrong, please try again.",
            );
          });
      } else {
        if (fileData.portfolioMapping) {
          axiosInstance
            ?.get(`${fileData?.portfolioMappingUrlPath}?id=${fileData?.id}`)
            .then((mappingResponse) => {
              const portfolios = mappingResponse?.data?.data?.filter(
                (data: any) => data?.portfolio === null,
              );

              portfolioFormik?.setFieldValue("nullPortfolios", portfolios);
              portfolioFormik?.setFieldValue("fileVersion", values.fileVersion);
              if (portfolios?.length !== 0) {
                setIsLoading(false);
                setActiveStep(2);
              } else if (fileData.companyMapping) {
                handleNext();
                axiosInstance
                  ?.get(`${fileData.companyMappingUrlPath}?id=${fileData?.id}`)
                  .then((companyMappingResponse) => {
                    const companies =
                      companyMappingResponse?.data?.data?.filter(
                        (data: any) => data?.value === null,
                      );
                    companyFormik?.setFieldValue("nullCompanies", companies);

                    if (companies?.length !== 0) {
                      setIsLoading(false);
                      setActiveStep(3);
                    } else {
                      setActiveStep(4);
                      axiosInstance
                        .post(`${fileData.reviewUrlPath}?id=${fileData?.id}`)
                        .then((reviewApiResponse) => {
                          setActiveStep(4);

                          setDuplicatesResponse({
                            ...reviewApiResponse?.data,
                            transactions:
                              reviewApiResponse?.data?.transactions?.map(
                                (data: any) => {
                                  return { ...data };
                                },
                              ),
                          });
                          setReviewResponse({
                            ...reviewApiResponse?.data,
                            transactions:
                              reviewApiResponse?.data?.transactions?.map(
                                (data: any) => {
                                  return { ...data };
                                },
                              ),
                          });
                          setIsLoading(false);
                        })
                        .catch((error) => {
                          setIsLoading(false);
                          setOpen(false);
                          setActiveStep(1);
                          toast.error(
                            error?.response?.data?.message ||
                              "Something went wrong, please try again.",
                          );
                        });
                    }
                  })
                  .catch((error) => {
                    setIsLoading(false);
                    setOpen(false);
                    setActiveStep(1);
                    toast.error(
                      error?.response?.data?.message ||
                        "Something went wrong, please try again.",
                    );
                  });
              } else {
                setActiveStep(4);
                axiosInstance
                  .post(`${fileData.reviewUrlPath}?id=${fileData?.id}`)
                  .then((reviewApiResponse) => {
                    setActiveStep(4);
                    setDuplicatesResponse({
                      ...reviewApiResponse?.data,
                      transactions: reviewApiResponse?.data?.transactions?.map(
                        (data: any) => {
                          return { ...data };
                        },
                      ),
                    });
                    setReviewResponse({
                      ...reviewApiResponse?.data,
                      transactions: reviewApiResponse?.data?.transactions?.map(
                        (data: any) => {
                          return { ...data };
                        },
                      ),
                    });
                    setIsLoading(false);
                  })
                  .catch((error) => {
                    setIsLoading(false);
                    setOpen(false);
                    setActiveStep(1);
                    toast.error(
                      error?.response?.data?.message ||
                        "Something went wrong, please try again.",
                    );
                  });
              }
            })
            .catch((error) => {
              setIsLoading(false);
              setOpen(false);
              toast.error(
                error?.response?.data?.message ||
                  "Something went wrong, please try again.",
              );
            });
        } else if (fileData.companyMapping) {
          axiosInstance
            ?.get(`${fileData.companyMappingUrlPath}?id=${fileData?.id}`)
            .then((companyMappingResponse) => {
              const companies = companyMappingResponse?.data?.data?.filter(
                (data: any) => data?.value === null,
              );

              companyFormik?.setFieldValue("nullCompanies", companies);

              if (companies?.length !== 0) {
                setIsLoading(false);
                setActiveStep(3);
              } else {
                setActiveStep(4);
                axiosInstance
                  .post(`${fileData.reviewUrlPath}?id=${fileData?.id}`)
                  .then((reviewApiResponse) => {
                    setActiveStep(4);
                    setDuplicatesResponse({
                      ...reviewApiResponse?.data,
                      transactions: reviewApiResponse?.data?.transactions?.map(
                        (data: any) => {
                          return { ...data };
                        },
                      ),
                    });
                    setReviewResponse({
                      ...reviewApiResponse?.data,
                      transactions: reviewApiResponse?.data?.transactions?.map(
                        (data: any) => {
                          return { ...data };
                        },
                      ),
                    });
                    setIsLoading(false);
                  })
                  .catch((error) => {
                    setIsLoading(false);
                    setActiveStep(1);
                    setAllFiles(
                      allFiles?.map((item) => {
                        return {
                          ...item,
                          finished:
                            item?.data?.id === uploadData?.id
                              ? true
                              : item?.finished,
                          hasError:
                            item?.data?.id === uploadData?.id
                              ? true
                              : item?.data,
                        };
                      }),
                    );
                    toast.error(
                      error?.response?.data?.message ||
                        "Something went wrong, please try again.",
                    );
                  });
              }
            });
        } else {
          setActiveStep(4);
          setIsLoading(false);
          axiosInstance
            .post(`${fileData.reviewUrlPath}?id=${fileData?.id}`)
            .then((reviewApiResponse) => {
              setActiveStep(4);
              setDuplicatesResponse({
                ...reviewApiResponse?.data,
                transactions: reviewApiResponse?.data?.transactions?.map(
                  (data: any) => {
                    return { ...data };
                  },
                ),
              });
              setReviewResponse({
                ...reviewApiResponse?.data,
                transactions: reviewApiResponse?.data?.transactions?.map(
                  (data: any) => {
                    return { ...data };
                  },
                ),
              });
              setIsLoading(false);
            })
            .catch((error) => {
              setIsLoading(false);
              setActiveStep(1);
              setAllFiles(
                allFiles?.map((item) => {
                  return {
                    ...item,
                    finished:
                      item?.data?.id === uploadData?.id ? true : item?.finished,
                    hasError:
                      item?.data?.id === uploadData?.id ? true : item?.data,
                    status:
                      item?.data?.id === uploadData?.id
                        ? "failed"
                        : item?.status,
                    message:
                      item?.data?.id === uploadData?.id
                        ? error?.response?.data?.message
                        : item?.message,
                  };
                }),
              );
              toast.error(
                error?.response?.data?.message ||
                  "Something went wrong, please try again.",
              );
            });
        }
      }
    },
  });

  /** ========================================================================================= */
  const { data: companyNames } = useFetchQuery({
    key: [QueryKeys.COMPANY_NAMES, formik.values?.fileUploadType],
    enabled: !!formik.values?.fileUploadType,
    route:
      formik.values?.fileUploadType === "MF" ||
      formik.values?.fileUploadType === "PEQ"
        ? GET_COMPANY_LIST([formik.values?.fileUploadType])
        : GET_COMPANY_LIST(),
    staleTime: 24 * 60 * 60 * 1000,
    refetchOnWindowFocus: true,
    requestBody: {},
  });

  /** ========================================================================================= */
  const handleReturnSize = (file: any) => {
    const totalBytes = file?.size;
    if (totalBytes < 1000000) {
      return `${(totalBytes / 1000).toFixed(2)}KB`;
    }
    return `${(totalBytes / 1000000).toFixed(2)}MB`;
  };

  /** ========================================================================================= */
  const handleReviewPortfolio = async (data: any) => {
    setPortfolioSubmitIsLoading(true);
    setIsLoading(true);
    await axiosInstance
      ?.post(`/client/upload/?commit=true`, {
        clients_data: data,
      })
      .then((response) => {
        setIsLoading(false);
        setPortfolioSubmitIsLoading(false);
        setAllFiles(
          allFiles?.map((item) => {
            return {
              ...item,
              inProgress:
                item?.data?.id === uploadData?.id ? true : item?.finished,
              finished:
                item?.data?.id === uploadData?.id ? true : item?.finished,
              data:
                item?.data?.id === uploadData?.id
                  ? response?.data?.data
                  : item?.data,
            };
          }),
        );
        setActiveStep(6);
      })
      .catch((error) => {
        setPortfolioSubmitIsLoading(false);
        setIsLoading(false);
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      });
  };

  /** ========================================================================================= */
  const handleFilterDuplicateIds = (ids: any) => {
    setReviewResponse({
      ...reviewResponse,
      transactions: duplicatesResponse?.transactions?.filter(
        (data: any, index: number) => ids.includes(index),
      ),
    });
    setDuplicateResponseIds(ids);
    setShowDuplicates(false);
  };

  /** ========================================================================================= */
  const handleRowsReturn = () => {
    if (showDuplicates) {
      return duplicatesResponse?.transactions?.filter(
        (data: any) => data?.isDuplicate,
      );
    }
    if (!showDuplicates && duplicateResponseIds?.length !== 0) {
      return [
        duplicatesResponse?.transactions?.filter((data: any) =>
          duplicateResponseIds?.includes(data?.id),
        ),
        ...duplicatesResponse?.transactions?.filter(
          (data: any) => !data?.isDuplicate,
        ),
      ]?.flat();
    }

    return reviewResponse?.transactions || [];
  };

  /** ========================================================================================= */
  const { mutate: handleDeleteFav } = useMutation({
    mutationFn: (values: { id: number; type: string }) =>
      axiosInstance.delete(
        `/transaction_upload/${values?.id}/delete_template/?type=${values?.type}`,
      ),
    onSuccess: () => {
      favoriteRefetch();
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  /** ========================================================================================= */
  const { mutate: handleAddToFav } = useMutation({
    mutationFn: (values: { id: number; type: string }) =>
      axiosInstance.post(
        `/transaction_upload/${values?.id}/make_default/?type=${values?.type}`,
      ),
    onSuccess: () => {
      favoriteRefetch();
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  /** ========================================================================================= */
  const handleCheckPassword = async (data: any) => {
    const res = await Promise.all(
      data?.map(async (dataItem: any, index: any) => {
        const appendedFiles = new FormData();
        appendedFiles.append("files", dataItem?.file);
        appendedFiles.append("index", index);
        const response = await axiosInstance.post(
          `/auto-forward-password/check_password/`,
          appendedFiles,
        );
        return {
          ...dataItem,
          hasPassword:
            index === Number(response?.data?.data?.index)
              ? response?.data?.data?.isPasswordProtected
              : data?.hasPassword,
        };
      }),
    );
    setAllFiles(res);
  };

  /** ====================================================================================================================================================================================== */
  const handleCheckDate = async (data: any) => {
    const res = await Promise.all(
      data?.map(async (dataItem: any, index: any) => {
        const appendedFiles = new FormData();
        appendedFiles.append("files", dataItem?.file);
        appendedFiles.append("index", index);
        const response = await axiosInstance.post(
          `/auto-forward-password/check_password/?selection=MOTILAL__XLSX_BULK__V0`,
          appendedFiles,
        );
        return {
          ...dataItem,
          isDateRequired:
            index === Number(response?.data?.data?.index)
              ? response?.data?.data?.isDateRequired
              : data?.isDateRequired,
          date:
            index === Number(response?.data?.data?.index)
              ? response?.data?.data?.date
              : data?.date,
        };
      }),
    );
    setAllFiles(res);
  };
  /** ====================================================================================================================================================================================== */

  const handleDownloadSampleFiles = (formikFn: any) => {
    handleDownloadFile({
      name: formikFn?.values?.fileObject?.sampleFileName,
      link: formikFn?.values?.fileObject?.sampleFileLink,
    });
  };

  /** ====================================================================================================================================================================================== */
  useEffect(() => {
    if (isApproveModal) {
      setAllFiles([{ data: fileData, isFinished: false, inProgress: true }]);
      setIsLoading(true);
      setActiveStep(1);
      formik.setFieldValue("fileIndex", 0);
      formik.setFieldValue("fileUploadType", "MF");
      formik.setFieldValue("fileObject", fileData);
      companyFormik.setFieldValue("fileObject", fileData);
      portfolioFormik.setFieldValue("fileObject", fileData);
      formik.setFieldValue("file", [
        { data: fileData, isFinished: false, inProgress: true },
      ]);
      setTimeout(() => {
        formik.submitForm();
      }, 100);
    }
  }, [isApproveModal]);

  return {
    isLoading,
    setIsLoading,
    activeStep,
    formik,
    handleReset,
    handleModalTitle,
    showDuplicates,
    handleReviewComplete,
    setReviewResponse,
    reviewResponse,
    setShowDuplicates,
    favoriteUploadSamples,
    setShowFavoriteModal,
    menuOptions,
    portfolioFormik,
    portfolios,
    isPortfolioCreateModalOpen,
    setIsPortfolioCreateModalOpen,
    companyFormik,
    companyNames,
    userInfo,
    showFavoriteModal,
    favoriteRefetch,
    duplicateIds,
    setDuplicateIds,
    handleReviewPortfolio,
    isPortfolioSubmitLoading,
    handleFilterDuplicateIds,
    setDuplicateResponseIds,
    duplicatesResponse,
    duplicateResponseIds,
    handleRowsReturn,
    sheetUploadId,
    downloadSingleRowErrors,
    handleDeleteFav,
    handleAddToFav,
    setActiveStep,
    handleNoDUplicatesReviewComplete,
    handleReviewWithDupComplete,
    facLoading,
    handleNext,
    allFiles,
    setAllFiles,
    handleCheckPassword,
    setSheetUploadId,
    selectedId,
    setSelectedId,
    handleCheckDate,
    handleReturnSize,
    handleDownloadSampleFiles,
    templatesTabs,
    setTemplatesTabs,
  };
};
