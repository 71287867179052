import { Grid2 } from "@mui/material";
import Lottie from "lottie-react";
import { GKModalProps } from "utils/commonInterface";
import AnimationData from "../assets/Lottie/DeleteLottie.json";
import GKButton from "./GKButton";
import GKModal from "./GKModal";
import GKTypography from "./GKTypography";

interface Props {
  title: string;
  onDelete: () => void;
  isDelete?: boolean;
  deleteMessage?: string;
  messageContent?: string;
  customButtonText?: string;
}

const ConfirmDeleteModal = (props: Props & GKModalProps) => {
  const {
    open,
    setOpen,
    title,
    onDelete,
    isDelete = false,
    deleteMessage,
    messageContent,
    customButtonText = "",
  } = props;

  const deleteMessageHandler = () => {
    if (deleteMessage) return deleteMessage;
    return `Are you sure you want to delete this item? All of your
    data will be permanently removed. This action cannot be
    undone.`;
  };

  const modalMessageHandler = () => {
    if (isDelete) return deleteMessageHandler();
    if (messageContent) return messageContent;
    return `Are you sure you want to ${title.toLowerCase()} this item?`;
  };

  const buttonTextHandle = () => {
    if (isDelete) return `Delete ${title}`;
    if (customButtonText) return customButtonText;
    return "Undo";
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={isDelete ? `Delete ${title}` : `${title}`}
      footer={
        <GKButton
          variant="outlined"
          color="error"
          onClick={() => {
            onDelete();
            setOpen(false);
          }}
        >
          {buttonTextHandle()}
        </GKButton>
      }
    >
      <Grid2
        container
        justifyContent={"center"}
        alignItems={"center"}
        spacing={1}
      >
        <Grid2
          size={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Lottie {...defaultOptions} style={{ height: 100, width: 100 }} />
        </Grid2>
        <Grid2 size={7} textAlign={"center"}>
          <GKTypography fontWeight={500} fontSize={15}>
            {modalMessageHandler()}
          </GKTypography>
        </Grid2>
      </Grid2>
    </GKModal>
  );
};
export default ConfirmDeleteModal;
