import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  Chip,
  GKClientDataGrid,
  GKModal,
  GKSelect,
  Grid2,
  MenuItem,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { groupBy } from "lodash";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { paletteLightTheme } from "utils/ColorTheme";
import { GKModalProps } from "utils/commonInterface";
import { extraColumnsField, refetchQuery } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import useDocumentTitle from "utils/useDocumentTitle";

interface PORTFOLIOLISTTYPE {
  id: number;
  status: string;
  portfolioName: string;
  portfolioId: string;
  quantity: number;
  avgBuyPrice: number;
  holdingValue: number;
}

const AssetsStatusModal = ({ open, setOpen }: GKModalProps) => {
  useDocumentTitle("Basket Assets Status");

  const { assetId, id } = useParams();

  const [portfolioList, setPortfolioList] = useState<PORTFOLIOLISTTYPE[]>([]);

  const { data: addDetailsData } = useFetchQuery({
    key: ["GET_STOCK_DETAILS", id, assetId],
    route: `/model_portfolio/${id}/stock_item/${assetId}/`,
  });

  const { isFetching } = useFetchQuery({
    key: ["GET_ALL_PORTFOLIO_LIST"],
    route: `/model_portfolio/${id}/stock_item/${assetId}/portfolio_status/`,
    success: (res: any) => {
      setPortfolioList(res?.data?.data);
    },
  });

  const { mutate: handleSubmitLinked, isLoading: linkLoading } = useMutation({
    mutationKey: ["ADD_PORTFOLIO_DETAILS"],
    mutationFn: (data: any) =>
      axiosInstance.patch(
        `/model_portfolio/${id}/stock_item/${assetId}/portfolio_status/`,
        { mappedPortfolioStatus: data },
      ),
    onSuccess: () => {
      refetchQuery("GET_STOCK_DETAILS");
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const handleReturnStatusColor = (text: string) => {
    switch (text?.toLowerCase()) {
      case "buy":
        return "primary";
      case "hold":
        return "warning";
      case "exit":
        return "error";

      default:
        return "primary";
    }
  };

  return (
    <GKModal
      maxHeight="450px"
      width={"80%"}
      open={open}
      setOpen={setOpen}
      modalTitle={addDetailsData?.data?.companyName || "-"}
      titleComponent={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 8,
            border: `1px solid ${paletteLightTheme.palette.border.main}`,
            borderRadius: 8,
            padding: 8,
          }}
        >
          <Typography>Portfolio Status</Typography>
          {Object.entries(groupBy(portfolioList, "status"))
            ?.map(([key, cal]) => {
              return {
                name: key,
                length: cal?.length,
              };
            })
            ?.map((dataItem) => {
              return (
                <>
                  <Chip
                    sx={{
                      borderRadius: 1,
                      background: `${
                        paletteLightTheme.palette[
                          `${handleReturnStatusColor(dataItem.name)}`
                        ].main
                      }22`,
                      color: `${
                        paletteLightTheme.palette[
                          `${handleReturnStatusColor(dataItem.name)}`
                        ].main
                      }`,
                      fontWeight: 600,
                      height: "24px",
                    }}
                    label={dataItem?.name}
                  />
                  <Typography
                    sx={{
                      color: `${
                        paletteLightTheme.palette[
                          `${handleReturnStatusColor(dataItem.name)}`
                        ].main
                      }`,
                    }}
                  >
                    {dataItem?.length || 0}
                  </Typography>
                </>
              );
            })}
        </div>
      }
      footer={
        <LoadingButton
          loading={linkLoading}
          variant="contained"
          onClick={() => {
            handleSubmitLinked(
              portfolioList?.map((d) => {
                return {
                  status: d.status,
                  id: d.id,
                };
              }),
            );
          }}
        >
          {"Submit"}
        </LoadingButton>
      }
    >
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <GKClientDataGrid
            loading={isFetching}
            rows={portfolioList || []}
            name="portfolioStatus"
            maxHeight={"calc(100vh - 360px)"}
            columns={[
              {
                ...extraColumnsField("Portfolio", "portfolio", 0, 1),
                renderCell: (params) => {
                  return (
                    <Grid2
                      container
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"flex-start"}
                    >
                      <Typography
                        fontWeight={700}
                        maxWidth={"100%"}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                      >
                        {params?.row?.clientName}
                      </Typography>
                      <Typography fontSize={12}>
                        {params?.row?.portfolioName} -{" "}
                        {params?.row?.portfolioCode}
                      </Typography>
                    </Grid2>
                  );
                },
              },
              {
                ...extraColumnsField("Quantity", "quantity", 0, 1),
                type: "number",
              },
              {
                ...extraColumnsField("Invested Amount", "investedValue", 0, 1),
                valueFormatter: (data) => moneyCommaSeparator(data?.value),
                type: "number",
              },
              {
                ...extraColumnsField("Holding Value", "holdingValue", 0, 1),
                valueFormatter: (data) => moneyCommaSeparator(data?.value),
                type: "number",
              },
              {
                ...extraColumnsField("Unrealised %", "avgBuyPrice", 0, 1),
                valueFormatter: (data) => moneyCommaSeparator(data?.value),
                type: "number",
              },
              {
                ...extraColumnsField("Weightage", "weightage", 0, 1),
                type: "number",
              },
              {
                ...extraColumnsField("Set Portfolio Status", "action", 0, 1),
                renderCell: (params) => {
                  return (
                    <GKSelect
                      value={params.row.status || "BUY"}
                      onChange={(e) => {
                        const newData = portfolioList?.map((data) => {
                          return {
                            ...data,
                            status:
                              params.row.id === data?.id
                                ? e.target.value
                                : data?.status,
                          };
                        });
                        setPortfolioList(newData as any);
                      }}
                    >
                      <MenuItem value={"BUY"}>Buy</MenuItem>
                      <MenuItem value={"HOLD"}>Hold</MenuItem>
                      <MenuItem value={"EXIT"}>Exit</MenuItem>
                    </GKSelect>
                  );
                },
              },
            ]}
          />
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default AssetsStatusModal;
