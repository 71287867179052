import { useLocation } from "react-router-dom";
import LMOrderDetails from ".";
import SmallCaseOrder from "../SmallCaseOrder";

const OrderGeneration = () => {
  const location = useLocation();

  const isLMBroker = new URLSearchParams(location?.search).get("is_lm");
  const orderId = new URLSearchParams(location.search).get("order_id");
  const token = new URLSearchParams(location.search).get("token");

  return isLMBroker === "false" ? (
    <SmallCaseOrder orderId={orderId} token={token} />
  ) : (
    <LMOrderDetails orderId={orderId} token={token} />
  );
};

export default OrderGeneration;
