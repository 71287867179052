import {
  Divider,
  FormControl,
  GKTextField,
  Grid2,
  IconButton,
  MdAdd,
  MdDeleteOutline,
} from "components";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

const ClientNominee = () => {
  const form = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "nominees",
  });

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={12}>
        {fields.map((bank: any, index: number) => (
          <Grid2 container size={12} spacing={1} my={1} key={index}>
            <Grid2 size={3}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name={`nominees.${index}.name`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Nominee Name" />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={3}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name={`nominees.${index}.percentage`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Nominee Percentage" />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={3}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name={`nominees.${index}.relationship`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Relationship" />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={3} textAlign={"right"} alignSelf={"center"} mt={2}>
              <IconButton
                color="error"
                disabled={fields?.length === 1}
                onClick={() => {
                  remove(index);
                }}
              >
                <MdDeleteOutline />
              </IconButton>
            </Grid2>
          </Grid2>
        ))}
      </Grid2>
      <Grid2 size={12}>
        <Divider>
          <IconButton
            onClick={() =>
              append({
                name: "",
                percentage: "",
                relationship: "",
              })
            }
          >
            <MdAdd />
          </IconButton>
        </Divider>
      </Grid2>
    </Grid2>
  );
};

export default ClientNominee;
