/* eslint-disable react/no-danger */
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  Grid2,
  MdOutlineWhatsapp,
  Tooltip,
  Typography,
  styled,
} from "components";
import { Fragment, useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { matchDate } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";

const CustomContainer = styled(Grid2)(() => () => ({
  padding: "16px",
  borderRadius: 10,
  border: "1px solid #E0E0E0",
  marginBottom: 4,
  "&:hover": {
    cursor: "pointer",
  },
}));

interface Props {
  dataItem: any;
  index: number;
  refetch: any;
  setOpenNoteModel?: any;
  setNoteDetails: any;
}

const NotesList = (props: Props) => {
  const { dataItem, index, setOpenNoteModel, setNoteDetails } = props;

  const { theme } = ThemeContainer();

  const [notedId, setNotedId] = useState();

  const { mutate: handleWhatsAppReportSend, isLoading } = useMutation({
    mutationKey: ["WHATSAPP_REPORT_SEND"],
    mutationFn: (id: string | number) =>
      axiosInstance.post(`/send_notes/`, { noteId: id }),
    onSuccess: (response) =>
      toast.success(response.data?.message || "Action completed successfully."),
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  function limitWords(text: string, limit: number) {
    const words = text.split(" ");
    if (words.length > limit) {
      return `${words.slice(0, limit).join(" ")}...`;
    }
    return text;
  }

  return (
    <>
      <CustomContainer
        key={index}
        position={"relative"}
        size={12}
        onClick={() => {
          setNoteDetails(dataItem);
          setOpenNoteModel(true);
        }}
      >
        <Grid2 container>
          <Grid2 size={8} display={"flex"} alignItems={"center"} gap={1}>
            <Tooltip title={dataItem?.title}>
              <Typography
                fontWeight={600}
                fontSize={20}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
                maxWidth={"70%"}
              >
                {dataItem?.title || "-"}
              </Typography>
            </Tooltip>
            {dataItem?.noteTypesList?.map((item: any) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 4,
                    padding: "2px 6px",
                    fontWeight: 400,
                    fontSize: 12,
                    background: `${item?.color}11`,
                    color: item?.color,
                    textTransform: "capitalize",
                    width: "max-content",
                  }}
                >
                  {item?.name}
                </div>
              );
            })}
          </Grid2>
          <Grid2
            size={4}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={1}
          >
            <LoadingButton
              loading={isLoading && notedId === dataItem.id}
              variant="outlined"
              color="dark"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setNotedId(dataItem.id);
                handleWhatsAppReportSend(dataItem.id);
              }}
            >
              <MdOutlineWhatsapp size={18} style={{ marginRight: 4 }} />
              Share on Whatsapp
            </LoadingButton>
          </Grid2>
          <Grid2 size={12} mt={1} mb={2}>
            <Typography
              fontWeight={400}
              fontSize={14}
              color={theme.palette.secondaryText.main}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: limitWords(dataItem?.content || "", 20),
                }}
              />
            </Typography>
          </Grid2>
          <Grid2
            size={9}
            display={"flex"}
            alignItems={"center"}
            gap={2}
            justifyContent={"flex-start"}
          >
            <Typography
              fontWeight={400}
              fontSize={12}
              display={"flex"}
              alignItems="center"
              color={theme.palette.secondaryText.main}
            >
              Client :&nbsp;
              <Typography color={"#202939"} fontSize={12}>
                {dataItem?.client?.clientName || "-"}
              </Typography>
              {" / "}
              <Typography color={"#202939"} fontSize={12}>
                {dataItem?.client?.clientCode || "-"}
              </Typography>
            </Typography>

            <Typography
              key={index}
              fontWeight={400}
              fontSize={12}
              display={"flex"}
              color={theme.palette.secondaryText.main}
              alignItems="center"
            >
              Portfolio :&nbsp;
              {dataItem?.portfolioList.length > 1 && (
                <Tooltip
                  title={dataItem?.portfolioList?.map(
                    (data: any, index: any) => {
                      return (
                        <Fragment key={data?.id}>
                          <Typography fontSize={12} key={dataItem?.id} m={1}>
                            {index + 1}. {data?.name}
                            {" / "}
                            {data?.portfolioId}
                          </Typography>
                        </Fragment>
                      );
                    },
                  )}
                >
                  <Typography
                    fontSize={12}
                    key={dataItem?.id}
                    color={"#202939"}
                  >
                    {dataItem?.portfolioList[0]?.name || "-"}
                    {" / "}
                    {dataItem?.portfolioList[0]?.portfolioId || "-"}
                    {`...   +`}
                    {dataItem?.portfolioList.length - 1}
                  </Typography>
                </Tooltip>
              )}
              {dataItem?.portfolioList.length === 1 && (
                <Typography fontSize={12} key={dataItem?.id} color={"#202939"}>
                  {dataItem?.portfolioList[0]?.name || "-"}
                  {" / "}
                  {dataItem?.portfolioList[0]?.portfolioId || "-"}
                </Typography>
              )}
            </Typography>
          </Grid2>
          <Grid2 size={3} textAlign={"right"}>
            <Typography
              fontWeight={400}
              fontSize={12}
              color={theme.palette.secondaryText.main}
            >
              {matchDate(dataItem?.dateTime, "DD MMM YYYY  hh:mm a") || "-"}
            </Typography>
          </Grid2>
        </Grid2>
      </CustomContainer>
    </>
  );
};

export default NotesList;
