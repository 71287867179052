import { LoadingButton } from "@mui/lab";
import { GridColDef } from "@mui/x-data-grid-pro";
import { handleRemoveUnderscore } from "utils";

import {
  CgMathPlus,
  CommonPagination,
  ConfirmDeleteModal,
  GKButton,
  GKDataGrid,
  Grid2,
  Show,
  Tooltip,
  Typography,
} from "components";
import { useCashManagement } from "hooks/useCashManagement";
import { useState } from "react";
import toast from "react-hot-toast";
import { moneyCommaSeparator } from "utils/MathFunction";
import { TableFiltering } from "utils/TableFilters";
import { TRANSACTION_CASH } from "utils/apiRoutes";
import { UserRoleTypes } from "utils/common";
import { returnParticularColor } from "utils/constants";
import { extraColumnsField, matchDate } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import CashTransactionModal from "./CashTransactionModal";

const CashManagementMainPage = () => {
  useDocumentTitle("Cash Entry");

  const {
    cashTransactions,
    isCashTransactionLoading,
    setOpenCashModal,
    openCashModal,
    userInfo,
    deleteConfirmation,
    setDeleteConfirmation,
    handleMultiDelete,
    selectedIds,
    setSelectedIds,
    deleteLoading,
  } = useCashManagement();

  const [cashEntryData, setCashEntryData] = useState<any>({});

  const cashManagementMainPageColumns: GridColDef[] = [
    {
      ...extraColumnsField("Date", "date", 150, 1),
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("Portfolio", "clientName", 300, 1),
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography
              fontWeight={700}
              maxWidth={"100%"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
            >
              {params?.row?.clientName}
            </Typography>
            <Typography fontSize={12}>
              {params?.row?.portfolioName} - {params?.row?.portfolioCode}
            </Typography>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Particulars", "expenseIncome", 200),
      renderCell: (params) => {
        return (
          <Typography
            fontWeight={600}
            style={{
              width: "fit-content",
              background: `${returnParticularColor(
                params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                  ?.addedField || params?.row?.transactionType,
              )}33`,
              color: returnParticularColor(
                params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                  ?.addedField || params?.row?.transactionType,
              ),
              padding: "4px 8px",
              borderRadius: 6,
            }}
            textTransform={"capitalize"}
          >
            {params?.row?.transactionType?.toLowerCase() === "sell" ||
            params?.row?.transactionType?.toLowerCase() === "buy"
              ? `BILLAMOUNT - ${params?.row?.transactionType}`
              : params?.row?.transactionType}
            &nbsp;
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                ? `/ ${handleRemoveUnderscore(
                    params?.row[
                      `${params?.row?.transactionType?.toLowerCase()}`
                    ]?.addedField,
                  )}`
                : ""}
            </span>
          </Typography>
        );
      },
    },
    // {
    //   flex: 1,
    //   disableColumnMenu: true,
    //   headerName: "Debit",
    //   minWidth: 150,
    //   field: "debit",
    //   align: "right",
    //   headerAlign: "right",
    //   valueGetter: (params) => params.row.debit || "0",
    //   valueFormatter: (params) => moneyCommaSeparator(params.value),
    // },
    // {
    //   flex: 1,
    //   disableColumnMenu: true,
    //   headerName: "Credit",
    //   minWidth: 150,
    //   field: "credit",
    //   align: "right",
    //   headerAlign: "right",
    //   valueGetter: (params) => params.row.credit || "0",
    //   valueFormatter: (params) => moneyCommaSeparator(params.value),
    // },
    {
      ...extraColumnsField("Amount", "billAmount", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.billAmount || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Remark", "remarks", 150, 1),
      renderCell: (params) => {
        const { remarks } = params?.row;
        return (
          <Tooltip title={remarks} arrow placement="left-start">
            <Typography
              textOverflow={"ellipsis"}
              width={"80%"}
              overflow={"hidden"}
            >
              {remarks ? `${remarks}...` : "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  const cusPagination = () => {
    return (
      <CommonPagination
        name="bankStatement"
        totalCount={cashTransactions?.totalCount || 0}
      />
    );
  };

  return (
    <Grid2 container>
      <GKDataGrid
        checkboxSelection
        onRowSelectionModelChange={(params) => {
          setSelectedIds(params);
        }}
        rowSelectionModel={selectedIds}
        loading={isCashTransactionLoading}
        columns={cashManagementMainPageColumns}
        rows={cashTransactions?.data || []}
        onRowClick={(value) => {
          if (
            value.row[`${value?.row?.transactionType?.toLowerCase()}`]
              ?.addedField === "dividend" ||
            value?.row?.transactionType === "BUY" ||
            value?.row?.transactionType === "SELL"
          ) {
            toast.error("This type of transaction id not editable.");
          } else {
            setCashEntryData(value.row);
            setOpenCashModal(true);
          }
        }}
        CustomPagination={!isCashTransactionLoading && cusPagination}
        headerComponent={
          userInfo?.userRole !== UserRoleTypes.client && (
            <Grid2 container gap={1} justifyContent={"end"}>
              {selectedIds?.length !== 0 && (
                <LoadingButton
                  variant="outlined"
                  loading={deleteLoading}
                  size="medium"
                  color="error"
                  onClick={() => {
                    setDeleteConfirmation(true);
                  }}
                >
                  Delete
                </LoadingButton>
              )}
              {selectedIds?.length !== 0 && (
                <GKButton
                  variant="outlined"
                  size="medium"
                  color="primary"
                  onClick={() => {
                    setSelectedIds([]);
                  }}
                >
                  Cancel
                </GKButton>
              )}
              {selectedIds?.length === 0 && (
                <GKButton
                  variant="contained"
                  size="medium"
                  onClick={() => {
                    setCashEntryData({});
                    setOpenCashModal(true);
                  }}
                  startIcon={<CgMathPlus size={18} />}
                >
                  Add Cash Entry
                </GKButton>
              )}
            </Grid2>
          )
        }
        name="bankStatement"
        tableName="bank_statement_list"
        headerFilter={<TableFiltering name="bankStatement" />}
        exportFile={{
          path: TRANSACTION_CASH,
          exportName: "CashTransactionList",
        }}
      />

      <Show.When isTrue={openCashModal}>
        <CashTransactionModal
          open={openCashModal}
          setOpen={setOpenCashModal}
          cashEntryData={cashEntryData}
        />
      </Show.When>

      <Show.When isTrue={deleteConfirmation}>
        <ConfirmDeleteModal
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          onDelete={() => handleMultiDelete(selectedIds)}
          title={"Bulk"}
          isDelete
          deleteMessage={`Are you sure that you want to delete the selected ledger statement & once deleted it cannot be reverted?`}
        />
      </Show.When>
    </Grid2>
  );
};

export default CashManagementMainPage;
