import { GridColDef } from "@mui/x-data-grid-pro";
import {
  CommonPagination,
  GKDataGrid,
  Grid2,
  Show,
  Tooltip,
  Typography,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { FAMILY_ID_BY_CLIENT_ACCESS } from "utils/apiRoutes";
import { extraColumnsField } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import FamilyAccessModal from "./FamilyAccessModal";

export interface FamilyAccessDataInterface {
  id: number;
  clientCode: string;
  name: string;
  familyName: string;
  phone: string;
  countryCode: string;
  email: string;
  pan: string;
  branch: string;
  portfolioCount: number;
  familyId?: string;
}

const FamilyClientAccess = () => {
  const { id } = useParams();

  const [openFamilyAccessModal, setOpenFamilyAccessModal] = useState(false);
  const [familyAccessData, setFamilyAccessData] =
    useState<FamilyAccessDataInterface>();

  const requestBody = useGenerateQueryParams("familyAccess");

  const { data, isLoading } = useFetchQuery({
    key: [QueryKeys.FAMILY_ACCESS, requestBody],
    route: FAMILY_ID_BY_CLIENT_ACCESS(id),
    requestBody,
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={data?.data?.totalCount || 0}
        name="familyAccess"
      />
    );
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Client Name / Code", "name", 0, 1),
      renderCell: (params) => {
        return (
          <Grid2 container>
            <Tooltip title={params?.row?.name}>
              <Grid2 size={12}>
                <Typography
                  fontWeight={700}
                  maxWidth={"100%"}
                  textOverflow={"ellipsis"}
                  overflow={"hidden"}
                >
                  {params?.row?.name}
                </Typography>
                <Typography fontSize={12}>{params?.row?.clientCode}</Typography>
              </Grid2>
            </Tooltip>{" "}
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Email", "email", 0, 1),
    },
    {
      ...extraColumnsField("Phone", "phone", 0, 1),
    },
    {
      ...extraColumnsField("Portfolio Count", "portfolioCount", 0, 1),
    },
    {
      ...extraColumnsField("Access Count", "accessCount", 0, 1),
    },
    // {
    //   ...extraColumnsField("Actions", "action", 100, 1),
    //   headerAlign: "right",
    //   align: "right",
    //   renderCell: (params) => {
    //     return (
    //       <Switch
    //         checked={selectedEmployeeList?.includes(params?.row?.id)}
    //         onChange={(event) => {
    //           let arr = [];
    //           if (event.target.checked) {
    //             arr = [...selectedEmployeeList, params?.row?.id];
    //           } else {
    //             arr = selectedEmployeeList?.filter(
    //               (id: any) => id !== params?.row?.id
    //             );
    //           }
    //           axiosInstance
    //             .patch(`/family/access/${id}/`, { employees: arr })
    //             .then(() => {
    //               refetch();
    //             })
    //             .catch((error) => {
    //               toast.error(
    //                 error?.response?.data?.message ||
    //                   "Something went wrong, please try again."
    //               );
    //             });
    //         }}
    //       />
    //     );
    //   },
    // },
  ];

  return (
    <>
      <GKDataGrid
        columns={columns}
        rows={data?.data?.data || []}
        loading={isLoading}
        CustomPagination={!isLoading && cusPagination}
        name="familyAccess"
        onRowClick={(params) => {
          setFamilyAccessData({ ...params.row, familyId: id });
          setOpenFamilyAccessModal(true);
        }}
      />

      <Show.When isTrue={openFamilyAccessModal}>
        <FamilyAccessModal
          open={openFamilyAccessModal}
          setOpen={setOpenFamilyAccessModal}
          familyAccessData={familyAccessData}
        />
      </Show.When>
    </>
  );
};

export default FamilyClientAccess;
