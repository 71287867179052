/* eslint-disable no-useless-concat */
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ThemeContainer } from "utils/Theme";

interface PieChartProps {
  data: any;
  pieClickData?: any;
  onClick?: (clickedElement: any) => void;
  onHover?: (hoveredElement: any) => void;
}

const ResponsivePieChart = (props: PieChartProps) => {
  const { theme } = ThemeContainer();
  const { data, onClick, onHover, pieClickData } = props;

  const options = {
    chart: {
      type: "pie",
      height: 300,
      width: 300,
    },
    credits: {
      text: "",
      enabled: false,
    },
    title: {
      text: pieClickData ? pieClickData.id : "Sectors",
      verticalAlign: "middle",
      floating: true,
    },
    tooltip: {
      formatter(this: any) {
        const colorBox = `<span style="width:10px;height:10px;border-radius:50%;background-color:${this.color};margin-right:5px;"></span>`;

        return `<div style="font-weight: 600;font-size: 12;">${colorBox}${
          this.key
        } : ${this.y.toFixed(2)}%</div>`;
      },
    },
    plotOptions: {
      pie: {
        innerSize: "40%",
        dataLabels: {
          enabled: false,
        },
        borderColor: "none",
        slicedOffset: 8,
        borderRadius: 0,
        colors: data.map((d: any) => d.color),
        point: {
          events: {
            click(event: any) {
              onClick && onClick({ ...event.point, id: event.point.name });
            },
            mouseOver(event: any) {
              onHover && onHover({ id: event.target.name });
            },
            mouseOut() {
              onHover && onHover(null);
            },
          },
        },
      },
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        animation: false,
      },
    },
    series: [
      {
        name: "Share",
        data: data.map((item: any) => ({
          name: item.id,
          y: item.value,
          color: item.color,
        })),
      },
    ],
    legend: {
      enabled: true,
      align: "right",
      verticalAlign: "bottom",
      layout: "vertical",
      itemStyle: {
        color: theme.palette.grey[700],
      },
      itemHoverStyle: {
        color: theme.palette.dark.main,
      },
      symbolHeight: 15,
      symbolWidth: 15,
      symbolRadius: 2,
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default ResponsivePieChart;
