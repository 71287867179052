import {
  BiInfoCircle,
  Card,
  Grid2,
  Skeleton,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "components";
import dayjs from "dayjs";
import usePortfolioErmSummary from "hooks/usePortfolioErmSummary";
import { handleReturnPositiveNegative } from "utils/colorHelper";
import { moneyCommaSeparator, numberToCurrency } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";

interface PropsInterface {
  cardData: any;
  isLoading?: boolean;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    minWidth: "max-content",
    maxWidth: "1000px",
    boxShadow:
      "0px 0px 10px 10px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    fontSize: 11,
    padding: "25px !important",
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      backgroundColor: theme.palette.light?.main,
    },
  },
}));

const PortfolioSummaryCard = ({ cardData, isLoading }: PropsInterface) => {
  const { summaryCardData, summaryCardFetching } = usePortfolioErmSummary();

  const { theme } = ThemeContainer();

  const summaryData = [
    {
      name: "Net Contribution",
      value: numberToCurrency(cardData?.data?.netContribution || 0),
      amount: cardData?.data?.netContribution,
      description: (
        <Grid2
          container
          spacing={3}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid2>
            <Typography
              fontSize={12}
              fontWeight={600}
              color={theme.palette.primary?.main}
              whiteSpace={"nowrap"}
            >
              Deposit
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={700}
              color={theme.palette.dark.main}
              whiteSpace={"nowrap"}
            >
              {numberToCurrency(summaryCardData?.data?.totalDeposit) ||
                (summaryCardFetching ? <Skeleton animation="wave" /> : "0")}
            </Typography>
          </Grid2>
          <Grid2
            style={{
              fontSize: 20,
              color: theme.palette.primary.main,
            }}
          >
            -
          </Grid2>
          <Grid2>
            <Typography
              fontSize={12}
              fontWeight={600}
              color={theme.palette.primary?.main}
              whiteSpace={"nowrap"}
            >
              Withdraw
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={700}
              color={theme.palette.dark.main}
              whiteSpace={"nowrap"}
            >
              {numberToCurrency(summaryCardData?.data?.totalWithdraw) ||
                (summaryCardFetching ? <Skeleton animation="wave" /> : "0")}
            </Typography>
          </Grid2>
          {/* <Grid2
     
            style={{
              fontSize: 20,
              color: theme.palette.primary.main,
            }}
          >
            -
          </Grid2>
          <Grid2>
            <Typography
              fontSize={12}
              fontWeight={600}
              color={theme.palette.primary?.main}
              whiteSpace={"nowrap"}
            >
              Divided
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={700}
              color={theme.palette.dark.main}
              whiteSpace={"nowrap"}
            >
              {numberToCurrency(summaryCardData?.data?.otherGains) ||
                (summaryCardFetching ? <Skeleton animation="wave" /> : "0")}
            </Typography>
          </Grid2> */}
        </Grid2>
      ),
    },
    {
      name: "Portfolio Value",
      value: numberToCurrency(cardData?.data?.portfolioValue || 0),
      description: (
        <Grid2
          container
          spacing={3}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid2>
            <Typography
              fontSize={12}
              fontWeight={600}
              color={theme.palette.primary?.main}
              whiteSpace={"nowrap"}
            >
              Current Value
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={700}
              color={theme.palette.dark.main}
              whiteSpace={"nowrap"}
            >
              {numberToCurrency(summaryCardData?.data?.currentValue) ||
                (summaryCardFetching ? <Skeleton animation="wave" /> : "0")}
            </Typography>
          </Grid2>
          <Grid2
            style={{
              fontSize: 20,
              color: theme.palette.primary.main,
            }}
          >
            +
          </Grid2>
          <Grid2>
            <Typography
              fontSize={12}
              fontWeight={600}
              color={theme.palette.primary?.main}
              whiteSpace={"nowrap"}
            >
              Ledger Balance
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={700}
              color={theme.palette.dark.main}
              whiteSpace={"nowrap"}
            >
              {numberToCurrency(summaryCardData?.data?.availableCash) ||
                (summaryCardFetching ? <Skeleton animation="wave" /> : "0")}
            </Typography>
          </Grid2>
        </Grid2>
      ),
    },
    {
      name: "Invested Amount",
      value: numberToCurrency(cardData?.data?.investedValue || 0),
    },
    {
      name: "Total Gain",
      value: numberToCurrency(
        +summaryCardData?.data?.otherGains +
          +summaryCardData?.data?.unrealisedGain +
          +summaryCardData?.data?.realisedGain || 0,
      ),
      amount:
        +summaryCardData?.data?.otherGains +
        +summaryCardData?.data?.unrealisedGain +
        +summaryCardData?.data?.realisedGain,
      description: (
        <Grid2
          container
          spacing={3}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid2>
            <Typography
              fontSize={12}
              fontWeight={600}
              color={theme.palette.primary?.main}
              whiteSpace={"nowrap"}
            >
              Unrealised Gain
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography
                fontSize={14}
                fontWeight={700}
                color={theme.palette.dark.main}
                whiteSpace={"nowrap"}
              >
                {numberToCurrency(summaryCardData?.data?.unrealisedGain) ||
                  (summaryCardFetching ? <Skeleton animation="wave" /> : "0")}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={700}
                color={handleReturnPositiveNegative(
                  summaryCardData?.data?.absoluteReturn,
                )}
                whiteSpace={"nowrap"}
              >
                {` (${
                  summaryCardData?.data?.absoluteReturn?.toFixed(2) ||
                  (summaryCardFetching ? <Skeleton animation="wave" /> : "0")
                }%)`}
              </Typography>
            </div>
          </Grid2>
          <Grid2
            style={{
              fontSize: 20,
              color: theme.palette.primary.main,
            }}
          >
            +
          </Grid2>
          <Grid2>
            <Typography
              fontSize={12}
              fontWeight={600}
              color={theme.palette.primary?.main}
              whiteSpace={"nowrap"}
            >
              Realised Gain
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={700}
              color={theme.palette.dark.main}
              whiteSpace={"nowrap"}
            >
              {numberToCurrency(summaryCardData?.data?.realisedGain) ||
                (summaryCardFetching ? <Skeleton animation="wave" /> : "0")}
            </Typography>
          </Grid2>
          <Grid2
            style={{
              fontSize: 20,
              color: theme.palette.primary.main,
            }}
          >
            +
          </Grid2>
          <Grid2>
            <Typography
              fontSize={12}
              fontWeight={600}
              color={theme.palette.primary?.main}
              whiteSpace={"nowrap"}
            >
              Dividend
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={700}
              color={theme.palette.dark.main}
              whiteSpace={"nowrap"}
            >
              {numberToCurrency(summaryCardData?.data?.otherGains) ||
                (summaryCardFetching ? <Skeleton animation="wave" /> : "0")}
            </Typography>
          </Grid2>
        </Grid2>
      ),
    },
    {
      name: "Today's Gain",
      value: `${numberToCurrency(
        cardData?.data?.todaysGain || 0,
      )} / ${moneyCommaSeparator(cardData?.data?.todaysGainPercentage || 0)} %`,
      amount: cardData?.data?.todaysGain,
    },
    {
      name: (
        <Tooltip
          title={`As of ${dayjs(cardData?.data?.lastXirrRefresh).format(
            "DD/MM/YY hh:mm",
          )}`}
        >
          <Typography
            fontSize={14}
            fontWeight={600}
            display={"flex"}
            alignItems={"center"}
          >
            <span>XIRR</span>&nbsp;
            <BiInfoCircle />
          </Typography>
        </Tooltip>
      ),
      amount: cardData?.data?.xirr?.toFixed(2),
      value: `${cardData?.data?.xirr?.toFixed(2) || 0} %`,
    },
  ];

  return (
    <Card>
      <Grid2
        container
        spacing={4}
        display={"grid"}
        gridTemplateColumns={"auto auto auto"}
      >
        {summaryData.map((item, index) => {
          return (
            <Grid2
              container
              key={index}
              display="flex"
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              {item?.description ? (
                <LightTooltip
                  placement="bottom-start"
                  title={item?.description}
                  arrow
                >
                  <div style={{ cursor: "pointer" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        color={theme.palette.dark?.main}
                      >
                        {item.name}
                      </Typography>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {isLoading ? (
                        <Skeleton animation="wave" />
                      ) : (
                        <Typography
                          fontSize={18}
                          fontWeight={700}
                          color={
                            (item.name === "Portfolio Value" ||
                              item.name === "Invested Amount") &&
                            !item?.amount
                              ? theme.palette.primary.main
                              : handleReturnPositiveNegative(
                                  Number(item?.amount),
                                )
                          }
                        >
                          {item.value}
                        </Typography>
                      )}
                    </div>
                  </div>
                </LightTooltip>
              ) : (
                <>
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    color={theme.palette.dark?.main}
                  >
                    {item.name}
                  </Typography>
                  {isLoading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    <Typography
                      fontSize={18}
                      fontWeight={700}
                      color={
                        (item.name === "Portfolio Value" ||
                          item.name === "Invested Amount") &&
                        !item?.amount
                          ? theme.palette.primary.main
                          : handleReturnPositiveNegative(Number(item?.amount))
                      }
                    >
                      {item.value}
                    </Typography>
                  )}
                </>
              )}
            </Grid2>
          );
        })}
      </Grid2>
    </Card>
  );
};
export default PortfolioSummaryCard;
