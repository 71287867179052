import {
  CgMathPlus,
  GKButton,
  GoDotFill,
  Grid2,
  MdOutlineKeyboardArrowDown,
  Popover,
  Show,
  Typography,
} from "components";
import { useUpdateLead } from "hooks/useUpdateLead";
import { useState } from "react";
import { paletteLightTheme } from "utils/ColorTheme";
import { getHexaColor } from "utils/constants";
import { ThemeContainer } from "utils/Theme";
import AddStatusAndSouceModal from "../AddStatusAndSouceModal";

interface Props {
  leadData: {
    id: number;
    leadStatusStr: string;
    leadAssignedToStr: string;
    sourceOfLeadStr: string;
    tagsList: any[];
    hasMeeting: boolean;
    hasNotes: boolean;
    meetingCount: number;
    notesCount: number;
    name: string;
    email: string;
    companyName: string;
    countryCode: string;
    phoneNumber: string;
    lastContacted: string;
    panNumber: string;
    position: string;
    address: string;
    city: string;
    state: string;
    pincode: string;
    country: string;
    leadValue: string;
    description: string;
    conversionStatus: false;
    createdAt: string;
    updatedAt: string;
    branch: number;
    leadStatus: number;
    leadAssignedTo: number;
    sourceOfLead: number;
    tags: any[];
  };
}

const LeadStatusPopup = ({ leadData }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { theme } = ThemeContainer();

  const {
    updateLead,
    setAddStatusModal,
    addStatusModal,
    statusList,
    statusFetching,
  } = useUpdateLead();

  const selectedStatus = statusList?.find(
    (status: any) => +status?.value === +leadData?.leadStatus,
  );

  return (
    <>
      {!statusFetching && (
        <GKButton
          sx={{
            color:
              selectedStatus &&
              (selectedStatus?.colourCode ||
                getHexaColor(selectedStatus?.leadstatus)),
            background:
              selectedStatus && selectedStatus?.colourCode
                ? `${selectedStatus?.colourCode}11`
                : `${getHexaColor(selectedStatus?.leadstatus)}11`,
            ":hover": {
              color:
                selectedStatus &&
                (selectedStatus?.colourCode ||
                  getHexaColor(selectedStatus?.leadstatus)),
              background:
                selectedStatus && selectedStatus?.colourCode
                  ? `${selectedStatus?.colourCode}11`
                  : `${getHexaColor(selectedStatus?.leadstatus)}22`,
            },
          }}
          onClick={(event) => {
            setAnchorEl(event?.currentTarget);
          }}
        >
          {leadData?.leadStatusStr}
          <MdOutlineKeyboardArrowDown size={16} />
        </GKButton>
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        PaperProps={{
          style: {
            width: "12%",
            boxShadow: ` 4px 2px 8px 0px rgba(0, 0, 0, 0.13)`,
            borderRadius: 8,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid2 container sx={{ py: "6px" }}>
          <Grid2
            size={12}
            borderBottom={`solid 1px ${paletteLightTheme.palette.border.main}`}
            py={1}
            px={2}
          >
            <Typography
              fontWeight={400}
              fontSize={12}
              color={theme.palette.secondaryText.main}
            >
              Select Status
            </Typography>
          </Grid2>
          {statusList?.map((dataItem, index) => {
            return (
              <Grid2
                key={index}
                size={12}
                borderBottom={
                  index + 1 === statusList?.length
                    ? ""
                    : `solid 1px ${paletteLightTheme.palette.border.main}`
                }
              >
                <GKButton
                  color="dark"
                  fullWidth
                  startIcon={
                    <GoDotFill
                      size={14}
                      color={
                        dataItem?.colourCode || getHexaColor(dataItem?.name)
                      }
                    />
                  }
                  sx={{
                    px: 2,
                    py: 1,
                    borderRadius: 0,
                    justifyContent: "flex-start",
                    color: dataItem?.colourCode || getHexaColor(dataItem?.name),
                  }}
                  onClick={() => updateLead({ leadStatus: dataItem.value })}
                >
                  {dataItem?.name}
                </GKButton>
              </Grid2>
            );
          })}
          <Grid2
            size={12}
            borderTop={`solid 1px ${paletteLightTheme.palette.border.main}`}
            py={1}
            px={2}
          >
            <GKButton
              fullWidth
              variant="link"
              color="dark"
              sx={{
                textDecoration: "none",
                color: theme.palette.secondaryText.main,
                ":hover": {
                  textDecoration: "none",
                },
              }}
              startIcon={
                <CgMathPlus
                  color={theme.palette.secondaryText.main}
                  size={14}
                />
              }
              onClick={() => setAddStatusModal(true)}
            >
              Add Status
            </GKButton>
          </Grid2>
        </Grid2>
      </Popover>

      <Show.When isTrue={addStatusModal}>
        <AddStatusAndSouceModal
          open={addStatusModal}
          setOpen={setAddStatusModal}
          type={"STATUS"}
        />
      </Show.When>
    </>
  );
};

export default LeadStatusPopup;
