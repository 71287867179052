/* eslint-disable import/no-named-as-default-member */
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  FormControl,
  GKModal,
  GKSelect,
  GKTextField,
  Grid2,
  MenuItem,
} from "components";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { KYC_OPTIONS } from "utils/constants";
import { clientKycModalFormSchema } from "utils/validationSchema";

interface Props {
  showData?: any;
  refetch?: any;
  clientData?: any;
}

type FormValues = {
  pan?: string;
  identification_type?: string;
  kyc_status?: string;
};

const ComplianceKYCModal = ({
  setOpen,
  open,
  showData,
  refetch,
  clientData,
}: Props & GKModalProps) => {
  const { id } = useParams();

  const isDataPresent = showData?.pan;

  const { mutate: createKyc, isLoading } = useMutation({
    mutationKey: ["CREATE_KYC"],
    mutationFn: (data) => axiosInstance.post(`/kyc/${id}/`, data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetch();
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: updateKyc, isLoading: updateKycLoading } = useMutation({
    mutationKey: ["UPDATE_KYC"],
    mutationFn: (data) => axiosInstance.post(`/resendKyc/resendKYC/`, data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetch();
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const form = useForm<FormValues>({
    defaultValues: {
      pan:
        (isDataPresent ? showData?.pan : clientData?.personalDetails?.pan) ||
        "",
      identification_type:
        (isDataPresent && showData?.identificationType) || "",
      kyc_status: (isDataPresent && showData?.kycStatus) || "",
    },
    resolver: yupResolver(clientKycModalFormSchema),
  });

  const onSubmit = async (values: any) => {
    if (isDataPresent) {
      const data: any = {
        kyc_id: showData?.id,
        identification_type: values.identification_type,
      };

      updateKyc(data);
    } else {
      const data: any = {
        client: id,
        pan: values.pan,
        identificationType: values.identification_type,
        kycStatus: values.kyc_status,
      };
      createKyc(data);
    }
  };

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={"Client KYC"}
      footer={
        <LoadingButton
          type="submit"
          variant="contained"
          form="kyc-form"
          size="medium"
          loading={isLoading || updateKycLoading}
        >
          {isDataPresent ? "resubmit" : "save"}
        </LoadingButton>
      }
    >
      <form
        id="kyc-form"
        onSubmit={form.handleSubmit((values) => onSubmit(values))}
      >
        <Grid2 container spacing={1}>
          <Grid2
            container
            size={12}
            spacing={1}
            display={"flex"}
            alignItems={"center"}
          >
            <Grid2 size={12}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name="pan"
                  control={form.control}
                  render={({ field, formState: { errors } }) => (
                    <GKTextField
                      {...field}
                      inputLabel="PAN"
                      placeholder="PAN"
                      onChange={(e) =>
                        field.onChange(e.target.value.toUpperCase())
                      }
                      error={Boolean(errors?.pan)}
                      helperText={
                        Boolean(errors?.pan) && `${errors?.pan?.message}`
                      }
                    />
                  )}
                />
              </FormControl>
            </Grid2>
          </Grid2>
          <Grid2 container size={12}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="identification_type"
                control={form.control}
                render={({ field, formState: { errors } }) => (
                  <GKSelect
                    {...field}
                    inputLabel="KYC Type"
                    error={Boolean(errors?.identification_type)}
                    helperText={
                      Boolean(errors?.identification_type) &&
                      `${errors?.identification_type?.message}`
                    }
                  >
                    <MenuItem style={{ display: "none" }} value="">
                      Select Identification Type
                    </MenuItem>
                    {KYC_OPTIONS?.map((data) => (
                      <MenuItem value={data?.value} key={data?.value}>
                        {data?.name}
                      </MenuItem>
                    ))}
                  </GKSelect>
                )}
              />
            </FormControl>
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default ComplianceKYCModal;
