import {
  FormControl,
  GKButton,
  GKDatePicker,
  GKModal,
  GKSearchSelect,
  Grid2,
  TextField,
} from "components";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { GKModalProps } from "utils/commonInterface";

type FormValues = {
  partner: any[];
  fromDate: string;
  toDate: string;
};

const CreateCalculationCommissionModal = (props: GKModalProps) => {
  const { open, setOpen } = props;

  const form = useForm<FormValues>({
    defaultValues: {
      partner: [],
      fromDate: "",
      toDate: "",
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Create Calculation Commission"
      width={"50%"}
      footer={
        <GKButton
          type="submit"
          variant="contained"
          size="medium"
          form="calculation-commission-form"
        >
          Save
        </GKButton>
      }
    >
      <form style={{ width: "100%" }}>
        <Grid2 container spacing={1} columnSpacing={2}>
          <Grid2 size={6}>
            <GKSearchSelect
              multiple
              disableClearable={false}
              disableCloseOnSelect
              limitTags={5}
              inputLabel="Partner"
              // loading={portFolioFetching}
              options={[]}
              // onChange={(e, val) => {
              //   // const selectedIds = val.map((option: any) => option.value);
              // }}
              value={[]}
              getOptionLabel={(option) => option.name || option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="partner"
                  placeholder={
                    form.getValues("partner").length <= 0 && "Select Portfolio"
                  }
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <FormControl fullWidth variant="standard">
              <GKDatePicker
                inputLabel="From Date"
                name="fromDate"
                value={dayjs(form.getValues("fromDate"))}
                onChange={(value) => {
                  form.setValue("fromDate", dayjs(value).format("YYYY-MM-DD"));
                }}
                disableFuture
                maxDate={dayjs()}
                slotProps={{
                  textField: {
                    error: false,
                    helperText: false,
                  },
                }}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={6}>
            <FormControl fullWidth variant="standard">
              <GKDatePicker
                inputLabel="To Date"
                name="toDate"
                value={dayjs(form.getValues("toDate"))}
                onChange={(value) => {
                  form.setValue("toDate", dayjs(value).format("YYYY-MM-DD"));
                }}
                disableFuture
                maxDate={dayjs()}
                slotProps={{
                  textField: {
                    error: false,
                    helperText: false,
                  },
                }}
              />
            </FormControl>
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default CreateCalculationCommissionModal;
