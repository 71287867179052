import { useAppContext } from "AppContext";
import NotFoundPage from "components/ErrorPage";
import Loader from "components/Loader";
import NoInternetWrapper from "components/NoInternetWrapper";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  error,
  resetErrorBoundary,
}) => <NotFoundPage error={error} resetErrorBoundary={resetErrorBoundary} />;

const CompanyMasterLayout: React.FC = () => {
  const {
    state: { userLoading },
  } = useAppContext();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {userLoading ? (
        <Loader />
      ) : (
        <NoInternetWrapper>
          <Outlet />
        </NoInternetWrapper>
      )}
    </ErrorBoundary>
  );
};

export default CompanyMasterLayout;
