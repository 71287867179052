import { Card, GKButton, Grid2, Skeleton, Typography } from "components";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { moneyCommaSeparator } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";
import { GET_WALLET_TOP_UP_LIST } from "utils/apiRoutes";
import RateCardModal from "./RateCardModal";
import WalletFullHistory from "./WalletFullHistory";
import WalletHistory from "./WalletHistory";
import WalletTopupModal from "./WalletTopupModal";

const Wallet = () => {
  const { theme } = ThemeContainer();

  const [openTopUp, setOpenTopUp] = useState(false);
  const [openRateCardModal, setOpenRateCardModal] = useState(false);

  const { data, isFetching } = useFetchQuery({
    key: ["GET_WALLET_BALANCE"],
    route: GET_WALLET_TOP_UP_LIST,
  });

  return (
    <DashboardLayout title="Wallet">
      <Grid2 container spacing={1}>
        <Grid2 size={12}>
          <Card>
            <Grid2 container spacing={1}>
              <Grid2 size={6}>
                <Typography
                  fontWeight={400}
                  fontSize={12}
                  display={"flex"}
                  alignItems={"center"}
                  color={theme.palette.grey[400]}
                >
                  My Balance
                </Typography>
                <Typography
                  fontWeight={600}
                  fontSize={24}
                  color={theme.palette.dark.main}
                >
                  {isFetching ? (
                    <Skeleton animation="wave" />
                  ) : (
                    `₹ ${moneyCommaSeparator(+data?.data?.data?.amount)}` || (
                      <Skeleton animation="wave" />
                    )
                  )}
                </Typography>
              </Grid2>
              <Grid2
                size={6}
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gap={1}
              >
                <GKButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => setOpenRateCardModal(true)}
                  sx={{ background: theme.palette.light.main }}
                >
                  Add-ons
                </GKButton>
                {/* <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenTopUp(true)}
                >
                  Add Balance
                </Button> */}
              </Grid2>
            </Grid2>
          </Card>
        </Grid2>
        <Grid2 size={4}>
          <WalletFullHistory />
        </Grid2>
        <Grid2 size={8}>
          <WalletHistory />
        </Grid2>
      </Grid2>

      {openTopUp && (
        <WalletTopupModal open={openTopUp} setOpen={setOpenTopUp} />
      )}

      {openRateCardModal && (
        <RateCardModal
          open={openRateCardModal}
          setOpen={setOpenRateCardModal}
        />
      )}
    </DashboardLayout>
  );
};

export default Wallet;
