import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import { FormControl, GKButton, GKModal, GKTextField, Grid2 } from "components";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";

interface FormValues {
  amount: string;
  user?: number;
}

const WalletTopupModal = (props: GKModalProps) => {
  const { open, setOpen } = props;

  const {
    state: {
      userData: { id },
    },
  } = useAppContext();

  const { mutate: handleWalletTopup } = useMutation({
    mutationFn: (data) => axiosInstance.post(`/wallet-top-up/`, data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery("GET_WALLET_BALANCE");
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const form = useForm<FormValues>({
    defaultValues: {
      amount: "",
      user: id,
    },
  });
  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      width={"30%"}
      modalTitle={"Wallet Topup"}
      footer={
        <Grid2 container justifyContent={"flex-end"} gap={1}>
          <GKButton
            variant="outlined"
            size="medium"
            onClick={() => setOpen(false)}
          >
            Cancel
          </GKButton>
          <GKButton
            variant="contained"
            type="submit"
            form="wallet-topup-form"
            size="medium"
          >
            Submit
          </GKButton>
        </Grid2>
      }
    >
      <form
        id="wallet-topup-form"
        onSubmit={form.handleSubmit((values: any) => handleWalletTopup(values))}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name={"amount"}
                control={form.control}
                render={({ field }) => (
                  <GKTextField {...field} inputLabel={"Amount"} />
                )}
              />
            </FormControl>
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default WalletTopupModal;
