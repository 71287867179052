import { Alert, Card, Grid2, Typography } from "components";
import GKGaugeChart from "components/GKGaugeChart";
import { ThemeContainer } from "utils/Theme";

interface Props {
  answers: any;
}

const RiskCard = (props: Props) => {
  const { answers } = props;
  const { theme } = ThemeContainer();
  return (
    <Grid2 container spacing={1}>
      <Grid2 size={12}>
        <Card
          style={{
            display: "flex",
            padding: 8,
          }}
        >
          <Grid2
            size={9}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Typography fontSize={14} fontWeight={500}>
              Client Name
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={700}
              color={theme.palette.primary.main}
            >
              {answers?.name || "-"}
            </Typography>
          </Grid2>
          {answers && answers?.response?.length === 0 ? (
            <Grid2
              size={9}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-end"}
              justifyContent={"center"}
            >
              <Alert severity="warning">
                Please take the questionnaire to view risk score
              </Alert>
            </Grid2>
          ) : (
            <Grid2
              size={3}
              display={"flex"}
              justifyContent={"flex-end"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              {answers && answers?.response?.length !== 0 && (
                <GKGaugeChart
                  value={answers?.response[0]?.points}
                  maxValue={answers?.response[0]?.totalPoints}
                />
              )}
              {answers && answers?.response?.length !== 0 && (
                <Typography fontSize={14} fontWeight={700}>
                  Risk Score{" "}
                  <span
                    style={{
                      fontSize: 15,
                      fontWeight: 800,
                      color: theme.palette.primary.main,
                    }}
                  >
                    {answers?.response[0]?.points}
                  </span>
                </Typography>
              )}
              {answers && answers?.response?.length !== 0 && (
                <Typography fontSize={10} fontWeight={500}>
                  {`[Min Value: 0 - Max Value : ${
                    answers?.response[0]?.totalPoints || 0
                  }]`}
                </Typography>
              )}
            </Grid2>
          )}
        </Card>
      </Grid2>
    </Grid2>
  );
};

export default RiskCard;
