import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import {
  ConfirmDeleteModal,
  Divider,
  GKButton,
  Grid2,
  Show,
  Tab,
  Tabs,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import NoteModal from "Pages/AdviceRegisterNotes/NoteModal";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { CLIENT_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import ClientCard from "./ClientCard";
import ClientNotes from "./ClientNotes";
import ComplianceTab from "./ComplianceTabs";
import EditClientDetails from "./ProfilePages/EditClientDetails";
import AccessPage from "./SettingsPages/Access";
import ClientPermissions from "./SettingsPages/ClientPermissions";
import FamilyPage from "./SumaryPages/Family";
import HoldingsMainPage from "./SumaryPages/Holdings";
import PortfolioPage from "./SumaryPages/Portfolios";

const ClientDetailsPage = () => {
  useDocumentTitle("Client Details");

  const { id } = useParams();

  const navigate = useNavigate();

  const {
    state: { tab },
    dispatch,
  } = useAppContext();

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState(false);

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: any) => axiosInstance.delete(`/client/${data}/`),
    mutationKey: ["DELETE_CLIENT"],
    onSuccess: () => {
      navigate(-1);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: sendRegistrationLink, isLoading: linkLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post("/send-client-reg/", { client: data }),
    mutationKey: ["SEND_REGISTRATION_LINK"],
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again.",
      );
    },
  });

  const { data: clientData, isFetching } = useFetchQuery({
    key: [QueryKeys.CLIENTS_LIST, id],
    route: CLIENT_BY_ID(id),
  });

  return (
    <DashboardLayout title="Client Details" loading={isFetching}>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <ClientCard clientData={clientData?.data} />
        </Grid2>
        <Grid2 size={12} container>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2 size={9}>
            <Tabs
              value={tab?.clientERMPageTabs}
              onChange={(e, val) => {
                dispatch({
                  type: "TAB",
                  data: { ...tab, clientERMPageTabs: val },
                });
              }}
            >
              <Tab label="Profile" />
              <Tab label="Holdings" />
              <Tab label="Portfolios" />
              <Tab label="Family" />
              <Tab label="Onboarding" />
              <Tab label="Notes" />
              <Tab label="Permissions" />
              <Tab label="Access" />
            </Tabs>
          </Grid2>
          <Grid2
            size={3}
            display={tab?.clientERMPageTabs === 7 ? "flex" : "none"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={1}
          >
            <LoadingButton
              loading={linkLoading}
              onClick={() => {
                sendRegistrationLink(id);
              }}
              variant="outlined"
              size="small"
            >
              Send Registration Link
            </LoadingButton>
            <LoadingButton
              loading={isLoading}
              onClick={() => {
                setConfirmationModal(true);
              }}
              variant="outlined"
              color="error"
              size="small"
            >
              Delete Client
            </LoadingButton>
          </Grid2>
          <Grid2
            size={3}
            display={tab?.clientERMPageTabs === 5 ? "flex" : "none"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <GKButton
              onClick={() => {
                setOpenNoteModal(true);
              }}
              variant="contained"
              size="small"
            >
              Add Note
            </GKButton>
          </Grid2>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
        </Grid2>

        <Grid2 size={12}>
          <Show.When isTrue={tab?.clientERMPageTabs === 0}>
            <EditClientDetails />
          </Show.When>
          <Show.When isTrue={tab?.clientERMPageTabs === 1}>
            <HoldingsMainPage />
          </Show.When>
          <Show.When isTrue={tab?.clientERMPageTabs === 2}>
            <PortfolioPage />
          </Show.When>
          <Show.When isTrue={tab?.clientERMPageTabs === 3}>
            <FamilyPage />
          </Show.When>
          <Show.When isTrue={tab?.clientERMPageTabs === 4}>
            <ComplianceTab />
          </Show.When>
          <Show.When isTrue={tab?.clientERMPageTabs === 5}>
            <ClientNotes />
          </Show.When>
          <Show.When isTrue={tab?.clientERMPageTabs === 6}>
            <ClientPermissions />
          </Show.When>
          <Show.When isTrue={tab?.clientERMPageTabs === 7}>
            <AccessPage />
          </Show.When>
        </Grid2>
      </Grid2>
      <ConfirmDeleteModal
        onDelete={() => {
          mutate(id);
        }}
        title="Client"
        isDelete
        setOpen={setConfirmationModal}
        open={confirmationModal}
      />

      <Show.When isTrue={openNoteModal}>
        <NoteModal
          open={openNoteModal}
          setOpen={setOpenNoteModal}
          noteDetails={{}}
          clientId={id}
        />
      </Show.When>
    </DashboardLayout>
  );
};

export default ClientDetailsPage;
