import { Grid2, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import GKButton from "components/GKButton";
import GKDataGrid from "components/GKDataGrid";
import { Show } from "components/Show";
import dayjs from "dayjs";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ADVISOR_COMPANY_CORPORATE_ACTION_LIST } from "utils/apiRoutes";
import { extraColumnsField } from "utils/helpers";
import CorporateActionModal from "./CorporateActionModal";

const columns: GridColDef[] = [
  {
    ...extraColumnsField("Date", "date", 80, 1),
    valueGetter: (row) => dayjs(row?.row?.date).format("DD/MM/YYYY"),
  },
  {
    ...extraColumnsField("Action Type", "type", 80, 1),
  },
  {
    ...extraColumnsField("Details", "details", 400, 1),
    renderCell: (row) => {
      return (
        <>
          {row?.row?.type === "dividend" && (
            <Typography>
              <b>Dividend Per Share:</b> {row?.row?.dividendPerShare}
            </Typography>
          )}

          {(row?.row?.type === "split" ||
            row?.row?.type === "bonus" ||
            row?.row?.type === "reverse_split") && (
            <Grid2 container>
              <Grid2 size={12}>
                <Typography>
                  <b>For Stocks: </b>
                  {row?.row?.forStocks}
                </Typography>
              </Grid2>
              <Grid2 size={12}>
                <Typography>
                  <b>Issued Stocks:</b> {row?.row?.issuedStocks}
                </Typography>
              </Grid2>
            </Grid2>
          )}

          {row?.row?.type === "demerger" && (
            <Grid2 container>
              <Grid2 size={12}>
                <Typography>
                  <b>Existing Asset Quantity: </b>
                  {row?.row?.existingAssetQuantity}
                </Typography>
              </Grid2>
              <Grid2 size={12}>
                <Typography>
                  <b>New Asset Quantity:</b>
                  {row?.row?.newAssetQuantity}
                </Typography>
              </Grid2>
              <Grid2 size={12}>
                <Typography>
                  <b>Cost Acquisition Ratio:</b>
                  {row?.row?.costAcquisitionRatio}
                </Typography>
              </Grid2>
              {row?.row?.demergedCompanies?.map((company: any) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid2 container>
                  <Grid2 size={12}>
                    <Typography>
                      <b>Demerged Company:</b>
                      {company.childCompany}
                    </Typography>
                  </Grid2>
                  <Grid2 size={12}>
                    <Typography className="flex flex-row gap-2">
                      <b>New Asset Quantity:</b>
                      {company.newAssetQuantity}
                    </Typography>
                  </Grid2>
                  <Grid2 size={12}>
                    <Typography className="flex flex-row gap-2">
                      <b>Cost Acquisition Ratio:</b>
                      {company.costAcquisitionRatio}
                    </Typography>
                  </Grid2>
                </Grid2>
              ))}
            </Grid2>
          )}

          {row?.row?.type === "merger" && (
            <Grid2 container>
              <Grid2 size={12}>
                <b>Existing Asset Quantity:</b>
                {row?.row?.existingAssetQuantity}
              </Grid2>
              <Grid2 size={12}>
                <b>New Asset Quantity:</b>
                {row?.row?.newAssetQuantity}
              </Grid2>
              <Grid2 size={12}>
                <b>Parent Company:</b>
                {row?.row?.parentCompany}
              </Grid2>
            </Grid2>
          )}

          {row?.row?.type === "right_issue" && (
            <Grid2 container>
              <Grid2 size={12}>
                <Typography>
                  <b>For Stocks: </b>
                  {row?.row?.forStocks}
                </Typography>
              </Grid2>
              <Grid2 size={12}>
                <Typography>
                  <b>Issued Stocks:</b> {row?.row?.issuedStocks}
                </Typography>
              </Grid2>
              <Grid2 size={12}>
                <b>premium:</b>
                {row?.row?.premium}
              </Grid2>
              <Grid2 size={12}>
                <b>Right Issue Instrument:</b>
                {row?.row?.reGcode}
              </Grid2>
            </Grid2>
          )}

          {row?.row?.type === "REC" && (
            <Grid2 container>
              <Grid2 size={12}>
                <b>premium:</b>
                {row?.row?.premium}
              </Grid2>
              <Grid2 size={12}>
                <b>closingDate:</b>
                {dayjs(row?.row?.closingDate).format("DD/MM/YYYY")}
              </Grid2>
              <Grid2 size={12}>
                <b>Patly Paid Instrument:</b>
                {row?.row?.reGcode}
              </Grid2>
            </Grid2>
          )}
        </>
      );
    },
  },
  {
    ...extraColumnsField("Purpose", "purpose", 150, 1),
    valueGetter: (params) => params?.row?.purpose || "-",
  },
  // {
  //   ...extraColumnsField("Action", "action", 50, 1),
  //   renderCell: (params) => {
  //     return (
  //       <IconButton
  //         onClick={async () => {
  //           await axiosInstance
  //             .delete(`/admin-instrument-ca/${id}/delete`, {
  //               params: {
  //                 ca_id: params.row.caId,
  //                 act_type: params.row.type,
  //               },
  //             })
  //             .then((res) => {
  //               toast.success(res.data.message);
  //               refetch();
  //             })
  //             .catch((error) => {
  //               toast.error(
  //                 error?.response?.data?.message || "Something went wrong"
  //               );
  //             });
  //         }}
  //       >
  //         <AiFillDelete size={20} />
  //       </IconButton>
  //     );
  //   },
  // },
];

const CorporateActionsDetails = () => {
  const { id } = useParams();

  const [isOpenCorporateAction, setIsOpenCorporateAction] = useState(false);

  const requestBody = useGenerateQueryParams("corporateAction");

  const { data, isFetching } = useFetchQuery({
    key: ["GET_COMPANY_CORPORATE_ACTION", requestBody],
    route: ADVISOR_COMPANY_CORPORATE_ACTION_LIST(id),
    requestBody,
  });

  return (
    <>
      <GKDataGrid
        rows={data?.data?.data || []}
        columns={columns}
        loading={isFetching}
        name={"corporateAction"}
        // tableName={"identifiers"}
        columnsButton={false}
        getRowId={(row) => row.caId}
        headerComponent={
          <GKButton
            variant="contained"
            onClick={() => setIsOpenCorporateAction(true)}
          >
            Add Corporate Action
          </GKButton>
        }
      />

      <Show.When isTrue={isOpenCorporateAction}>
        <CorporateActionModal
          open={isOpenCorporateAction}
          setOpen={setIsOpenCorporateAction}
        />
      </Show.When>
    </>
  );
};

export default CorporateActionsDetails;
