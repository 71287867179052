import {
  Button,
  FormControl,
  GKModal,
  GKSearchSelect,
  GKTextField,
  Grid2,
  TextField,
} from "components";
import { Controller } from "react-hook-form";
import { GKModalProps } from "utils/commonInterface";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";

interface Props {
  form: any;
}

const AddDeleteCashModal = (props: Props & GKModalProps) => {
  const { open, setOpen, form } = props;

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Deposite or Withdraw Amount"
      footer={
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
          }}
        >
          Save
        </Button>
      }
    >
      <Grid2 container spacing={1}>
        <Grid2 size={12}>
          <Controller
            control={form.control}
            name="transactionType"
            render={({ field }) => (
              <GKSearchSelect
                {...field}
                disableClearable={false}
                inputLabel="Transaction Type"
                sx={{
                  width: "100%",
                }}
                options={[
                  {
                    name: "Deposit",
                    value: "DEPOSIT",
                  },
                  {
                    name: "Withdraw",
                    value: "WITHDRAW",
                  },
                ]}
                getOptionLabel={(option) => option.name || option}
                onChange={(e, val) => {
                  field.onChange(val?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name={`transactionType`}
                    placeholder={handleReturnAutocompletePlaceholder({
                      placeholder: "Select Transaction Type",
                      value: field.value,
                    })}
                  />
                )}
              />
            )}
          />
        </Grid2>
        <Grid2 size={12}>
          <Controller
            control={form.control}
            name="cash"
            render={({ field }) => (
              <FormControl variant="standard" fullWidth>
                <GKTextField
                  {...field}
                  inputLabel="Amount"
                  placeholder="Amount"
                />
              </FormControl>
            )}
          />
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default AddDeleteCashModal;
