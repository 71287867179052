import { Divider, Grid2, Typography } from "@mui/material";
import { ThemeContainer } from "utils/Theme";

const SimpleLoader = () => {
  const { theme } = ThemeContainer();

  return (
    <Grid2
      container
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      minHeight={"100%"}
      bgcolor={theme.palette.light.main}
      borderRadius={8}
      border={`1px solid ${theme.palette.border.main}`}
    >
      <img
        style={{
          borderRadius: 10,
          filter: `drop-shadow(0px 0px 3px ${theme.palette.primary.main})`,
        }}
        src="/Logos/GridkeyGif.gif"
        alt=" "
        height={80}
        width={80}
      />
      <Divider
        sx={{
          width: "60%",
        }}
      />

      <Typography
        fontSize={12}
        fontWeight={700}
        color={theme.palette.grey[600]}
      >
        Hold on ! We are fetching Data.
      </Typography>
    </Grid2>
  );
};

export default SimpleLoader;
