import { GKModal, Grid2, StatusField, Typography } from "components";
import { GKModalProps } from "utils/commonInterface";
import { moneyCommaSeparatorPoint, numberToCurrency } from "utils/MathFunction";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";

interface Props {
  data: {
    id: number;
    date: string;
    name: string;
    stopLoss: any;
    targetPrice: any;
    deviation: any;
    status: string;
    rationale: any;
    timePeriod: any;
    volatility: any;
  };
}

const AssetHistoryDetailsModal = (props: Props & GKModalProps) => {
  useDocumentTitle("Basket Assets History");

  const { theme } = ThemeContainer();

  const { open, setOpen, data } = props;

  return (
    <GKModal open={open} setOpen={setOpen} modalTitle={data?.name || "-"}>
      <Grid2 container spacing={2}>
        <Grid2 size={12} container justifyContent={"space-between"}>
          <Grid2 display={"flex"} alignItems={"center"} gap={1}>
            <Typography fontSize={12} color={theme.palette.secondaryText.main}>
              Date
            </Typography>{" "}
            {data?.date}
          </Grid2>
          <Grid2 display={"flex"} alignItems={"center"} gap={1}>
            <Typography fontSize={12} color={theme.palette.secondaryText.main}>
              Status
            </Typography>{" "}
            <StatusField statusString={data?.status || "N/A"} />
          </Grid2>
          <Grid2 display={"flex"} alignItems={"center"} gap={1}>
            <Typography fontSize={12} color={theme.palette.secondaryText.main}>
              Target Price
            </Typography>
            {numberToCurrency(data?.targetPrice || 0)}
          </Grid2>
          <Grid2 display={"flex"} alignItems={"center"} gap={1}>
            <Typography fontSize={12} color={theme.palette.secondaryText.main}>
              Stoploss
            </Typography>
            {numberToCurrency(data?.stopLoss || 0)}
          </Grid2>
          <Grid2 display={"flex"} alignItems={"center"} gap={1}>
            <Typography fontSize={12} color={theme.palette.secondaryText.main}>
              Deviation
            </Typography>
            {moneyCommaSeparatorPoint(data?.deviation || 0)}%
          </Grid2>
        </Grid2>
        <Grid2 size={12}>
          <Typography fontSize={12} color={theme.palette.secondaryText.main}>
            Rationale
          </Typography>
          <Typography fontSize={14}>{data.rationale || "-"}</Typography>
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default AssetHistoryDetailsModal;
