import { GridColDef } from "@mui/x-data-grid-pro";
import GKButton from "components/GKButton";
import GKDataGrid from "components/GKDataGrid";
import { Show } from "components/Show";
import StatusField from "components/StatusField";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { CgMathPlus } from "react-icons/cg";
import { useParams } from "react-router-dom";
import { extraColumnsField } from "utils/helpers";
import IdentifierModal from "./IdentifierModal";

interface IdentifierInterface {
  id: string;
  code: string;
  status: string;
  instrument: number;
}

const IdentifierDetails = () => {
  const { id } = useParams();

  const [identifierData, setIdentifierData] =
    useState<IdentifierInterface>(null);
  const [openIdentifierModal, setOpenIdentifierModal] = useState(false);

  const requestBody = useGenerateQueryParams("identifiers");

  const { data, isFetching } = useFetchQuery({
    key: ["GET_COMPANY_IDENTIFIERS", requestBody],
    route: `/advisor-cm/${id}/isin/`,
    requestBody,
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Code", "code", 0, 1),
    },
    {
      ...extraColumnsField("Status", "status", 0, 1),
      valueFormatter: (params) => params.value || "-",
      renderCell: (params) => {
        return <StatusField statusString={params?.row?.status || "N/A"} />;
      },
    },
  ];

  return (
    <>
      <GKDataGrid
        rows={data?.data?.data || []}
        columns={columns}
        loading={isFetching}
        onRowClick={(params) => {
          setIdentifierData(params.row);
          setOpenIdentifierModal(true);
        }}
        headerComponent={
          <GKButton
            variant="contained"
            size="medium"
            onClick={() => {
              setIdentifierData(null);
              setOpenIdentifierModal(true);
            }}
            startIcon={<CgMathPlus size={18} />}
          >
            Add Identifiers
          </GKButton>
        }
        name={"identifiers"}
        // tableName={"identifiers"}
        columnsButton={false}
      />

      <Show.When isTrue={openIdentifierModal}>
        <IdentifierModal
          open={openIdentifierModal}
          setOpen={setOpenIdentifierModal}
          identifierData={identifierData}
        />
      </Show.When>
    </>
  );
};

export default IdentifierDetails;
