import {
  Card,
  CgMathPlus,
  GKButton,
  Grid2,
  LuMail,
  LuPhone,
  SimpleLoader,
  Typography,
} from "components";
import GKNoDataFound from "components/GKNoDataFound";
import { useFetchQuery } from "hooks/useQueries";
import { PaymentGatewayFormValues } from "interfaces/PaymentGateway";
import { map } from "lodash";
import { useNavigate } from "react-router-dom";
import { removeUnderscoreAndFirstLetterCapital } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";
import useDocumentTitle from "utils/useDocumentTitle";

const PaymentGateway = () => {
  useDocumentTitle("Payment Gateway");

  const { theme } = ThemeContainer();

  const navigate = useNavigate();

  const { data, isLoading } = useFetchQuery({
    key: ["PAYMENT_GATEWAY_ACCOUNT_LIST"],
    route: "/razorpay-linked-account/",
  });

  if (isLoading) return <SimpleLoader />;

  return (
    <Grid2 container spacing={1}>
      {data?.data?.data.length === 0 && (
        <Grid2 size={12}>
          <GKNoDataFound
            subText="Setup your payment gateway"
            additionalComponent={
              <GKButton
                variant="contained"
                onClick={() => navigate("/app/connect-payment-gateway")}
                startIcon={<CgMathPlus size={18} />}
              >
                Payment Configure
              </GKButton>
            }
          />
        </Grid2>
      )}
      <Grid2 size={12}>
        {map(data?.data?.data, (dataItem: PaymentGatewayFormValues) => {
          return (
            <Card sx={{ p: 2 }}>
              <Grid2 container>
                <Grid2 container size={8} display={"flex"} spacing={2}>
                  <Grid2 size={12}>
                    <Typography
                      fontSize={18}
                      fontWeight={500}
                      display={"flex"}
                      alignItems={"center"}
                      gap={2}
                    >
                      {dataItem?.beneficiaryName}{" "}
                      <Typography
                        fontSize={12}
                        fontWeight={500}
                        padding={"4px 6px"}
                        borderRadius={"4px"}
                        color={
                          dataItem.status === "activated"
                            ? "#15803D"
                            : "#C35245"
                        }
                        bgcolor={
                          dataItem.status === "activated"
                            ? "#F6FCF6"
                            : "#FAF1F0"
                        }
                      >
                        {removeUnderscoreAndFirstLetterCapital(
                          dataItem?.status,
                        )}
                      </Typography>
                    </Typography>
                  </Grid2>
                  <Grid2 display={"flex"} gap={1}>
                    <Typography>Account no.</Typography>
                    <Typography fontSize={14} fontWeight={500}>
                      {dataItem?.accountNumber}
                    </Typography>
                  </Grid2>
                  <Grid2 display={"flex"} gap={1}>
                    <Typography>Business PAN</Typography>
                    <Typography fontSize={14} fontWeight={500}>
                      {dataItem?.businessPan}
                    </Typography>
                  </Grid2>
                </Grid2>
                <Grid2
                  size={4}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                  gap={3}
                >
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                    fontWeight={500}
                    color={theme.palette.secondaryText.main}
                  >
                    <LuMail size={16} /> {dataItem?.email}
                  </Typography>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                    fontWeight={500}
                    color={theme.palette.secondaryText.main}
                  >
                    <LuPhone size={16} /> {dataItem?.phone}
                  </Typography>
                </Grid2>
              </Grid2>
            </Card>
          );
        })}
      </Grid2>
    </Grid2>
  );
};

export default PaymentGateway;
