import { GridColDef } from "@mui/x-data-grid-pro";
import { GKClientDataGrid, Tooltip, Typography } from "components";
import { useFetchQuery } from "hooks/useQueries";
import { useParams } from "react-router-dom";
import { handleRemoveUnderscore } from "utils";
import { moneyCommaSeparator } from "utils/MathFunction";
import { returnParticularColor } from "utils/constants";
import { matchDate } from "utils/helpers";

interface Props {
  selectedId: any;
  oldIdSelected: any;
}

const ModelBankLedger = ({ selectedId, oldIdSelected }: Props) => {
  const { id } = useParams();

  const { isFetching, data } = useFetchQuery({
    key: ["GET_MODEL_PORTFOLIO_TRADEBOOK", oldIdSelected],
    route: `/model_portfolio/${id}/version/${
      oldIdSelected?.snapshotVersion || selectedId
    }/version_ledger/`,
    enabled: Boolean(selectedId),
  });

  const cashManagementMainPageColumns: GridColDef[] = [
    {
      disableColumnMenu: true,
      headerName: "Date",
      minWidth: 150,
      field: "date",
      valueFormatter: (value) => matchDate(value.value),
    },

    {
      disableColumnMenu: true,
      headerName: "Particulars",
      renderCell: (params) => {
        return (
          <Typography
            fontWeight={600}
            style={{
              width: "fit-content",
              background: `${returnParticularColor(
                params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                  ?.addedField || params?.row?.transactionType,
              )}33`,
              color: returnParticularColor(
                params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                  ?.addedField || params?.row?.transactionType,
              ),
              padding: "4px 8px",
              borderRadius: 6,
            }}
            textTransform={"capitalize"}
          >
            {params?.row?.transactionType?.toLowerCase() === "sell" ||
            params?.row?.transactionType?.toLowerCase() === "buy"
              ? `BILLAMOUNT - ${params?.row?.transactionType}`
              : params?.row?.transactionType}
            &nbsp;
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {params?.row[`${params?.row?.transactionType?.toLowerCase()}`]
                ? `/ ${handleRemoveUnderscore(
                    params?.row[
                      `${params?.row?.transactionType?.toLowerCase()}`
                    ]?.addedField,
                  )}`
                : ""}
            </span>
          </Typography>
        );
      },
      minWidth: 200,
      field: "expenseIncome",
    },

    {
      flex: 1,
      disableColumnMenu: true,
      headerName: "Debit",
      minWidth: 150,
      field: "debit",
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.debit || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      flex: 1,
      disableColumnMenu: true,
      headerName: "Credit",
      minWidth: 150,
      field: "credit",
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.credit || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      flex: 1,
      disableColumnMenu: true,
      headerName: "Ledger Balance",
      minWidth: 150,
      field: "ledgerBalance",
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.ledgerBalance || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      flex: 1,
      disableColumnMenu: true,
      headerName: "Remark",
      minWidth: 150,
      field: "remarks",
      renderCell: (params) => {
        const { remarks } = params?.row;
        return (
          <Tooltip title={remarks} arrow placement="left-start">
            <Typography
              textOverflow={"ellipsis"}
              width={"80%"}
              overflow={"hidden"}
            >
              {remarks ? `${remarks}...` : "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <GKClientDataGrid
      name="modelBankLedger"
      loading={isFetching}
      columns={cashManagementMainPageColumns || []}
      rows={data?.data?.data || []}
    />
  );
};

export default ModelBankLedger;
