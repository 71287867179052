import {
  Divider,
  FormControl,
  GKTextField,
  Grid2,
  IconButton,
  MdAdd,
  MdDeleteOutline,
} from "components";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

const ClientDemat = () => {
  const form = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "dematAccounts",
  });

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={12}>
        {fields.map((demat: any, index: number) => (
          <Grid2 container size={12} spacing={1} my={1} key={index}>
            <Grid2 size={2.6}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name={`dematAccounts.${index}.brokerName`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Demat Broker Name" />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={2.6}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name={`dematAccounts.${index}.dematId`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField {...field} inputLabel="Demat Id" />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={2.6}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name={`dematAccounts.${index}.uniqClientCode`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      inputLabel="UCC"
                      placeholder="Like UCC of Demat A/c"
                    />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={2.6}>
              <FormControl variant="standard" fullWidth>
                <Controller
                  name={`dematAccounts.${index}.depositary`}
                  control={form.control}
                  render={({ field }) => (
                    <GKTextField
                      {...field}
                      inputLabel="Depository"
                      placeholder="Like NSDL, CDSL"
                    />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={1.6} textAlign={"right"} alignSelf={"center"} mt={2}>
              <IconButton
                color="error"
                disabled={fields.length === 1}
                onClick={() => {
                  remove(index);
                }}
              >
                <MdDeleteOutline />
              </IconButton>
            </Grid2>
          </Grid2>
        ))}
      </Grid2>
      <Grid2 size={12}>
        <Divider>
          <IconButton
            onClick={() =>
              append({
                brokerName: "",
                dematId: "",
                uniqClientCode: "",
                depositary: "",
              })
            }
          >
            <MdAdd />
          </IconButton>
        </Divider>
      </Grid2>
    </Grid2>
  );
};

export default ClientDemat;
