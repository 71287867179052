import { GridColDef } from "@mui/x-data-grid-pro";
import {
  CgMathPlus,
  CommonPagination,
  GKButton,
  GKDataGrid,
  Grid2,
  Show,
  Typography,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CLIENT_MANDATE_LIST } from "utils/apiRoutes";
import { extraColumnsField, matchDate } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import { removeUnderscoreAndFirstLetterCapital } from "utils/StringFunctions";
import useDocumentTitle from "utils/useDocumentTitle";
import MandateModal from "./MandateModal";

const RevenueMandate = () => {
  useDocumentTitle("Revenue Mandate");

  const navigate = useNavigate();

  const [openMandateModal, setOpenMandateModal] = useState(false);

  const requestBody = useGenerateQueryParams("revenueMandate");

  const { data, isFetching } = useFetchQuery({
    key: ["REVENUE_MANDATE_LIST", requestBody],
    route: CLIENT_MANDATE_LIST,
    requestBody,
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        name="revenueMandate"
        totalCount={data?.data?.totalCount || 0}
      />
    );
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Portfolio", "clientName", 250, 1),
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography
              fontWeight={700}
              maxWidth={"100%"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
            >
              {params?.row?.clientName}
            </Typography>
            <Typography fontSize={12}>
              {params?.row?.portfolioName} - {params?.row?.portfolioId}
            </Typography>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Frequency", "frequency", 200, 1),
      valueGetter: (row) => row?.row?.frequency || "-",
    },
    {
      ...extraColumnsField("Start Date", "firstCollectionDate", 100, 1),
      type: "date",
      valueFormatter: (value) => matchDate(value?.value),
    },
    {
      ...extraColumnsField("Maximum Amount", "amount", 150, 1),
      valueGetter: (row) => row?.row?.amount,
      valueFormatter: (params) => moneyCommaSeparator(params.value || 0),
      type: "number",
    },
    {
      ...extraColumnsField("Status", "state", 150, 1),
      valueGetter: (row) =>
        removeUnderscoreAndFirstLetterCapital(row?.row?.state),
    },
  ];
  return (
    <>
      <GKDataGrid
        loading={isFetching}
        columns={columns}
        rows={data?.data?.data || []}
        maxHeight={"calc(100vh - 312px)"}
        CustomPagination={!isFetching && cusPagination}
        name="revenueMandate"
        onRowClick={(params) =>
          navigate(
            `/app/revenue/mandate/${params.row.id}/mandate-presentation`,
            {
              state: {
                clientName: params?.row?.clientName,
                portfolioId: params?.row?.portfolioId,
                portfolioName: params?.row?.portfolioName,
              },
            },
          )
        }
        headerComponent={
          <GKButton
            variant="contained"
            onClick={() => setOpenMandateModal(true)}
            startIcon={<CgMathPlus size={16} />}
          >
            Create Mandate
          </GKButton>
        }
      />

      <Show.When isTrue={openMandateModal}>
        <MandateModal open={openMandateModal} setOpen={setOpenMandateModal} />
      </Show.When>
    </>
  );
};

export default RevenueMandate;
