import { Grid2, Typography } from "components";

const WealthAdvisory = () => {
  return (
    <Grid2
      container
      pt={4}
      spacing={3}
      sx={{
        backgroundImage: `url(LandingPage/ctaBg.svg)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        px: {
          xs: "24px",
        },
      }}
    >
      <Grid2 size={12}>
        <Typography
          fontSize={{
            xs: 24,
            lg: 36,
          }}
          fontWeight={700}
          textAlign={"center"}
        >
          See where wealth advisory automation can take your business
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography
          fontSize={16}
          fontWeight={400}
          color={"#9BA0A1"}
          textAlign={"center"}
        >
          The first financial tool you will love and the last you will ever
          need.{" "}
        </Typography>
      </Grid2>
      <Grid2
        size={12}
        textAlign={"center"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          maxHeight: {
            xs: "798px",
          },
        }}
      >
        <img
          src={"/LandingPage/demoImg.svg"}
          alt=""
          style={{
            width: "100%",
            height: "auto",
            maxWidth: "796px",
          }}
        />
      </Grid2>
    </Grid2>
  );
};

export default WealthAdvisory;
