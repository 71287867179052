import { useAppContext } from "AppContext";
import { Grid2, Show, Tab, Tabs } from "components";
import useDocumentTitle from "utils/useDocumentTitle";
import AdviceRegister from "../AdviceRegister";
import ComplianceAgreement from "./ComplianceAgreement";
import ComplianceKYCPage from "./ComplianceKYCPage";
import RiskProfileTab from "./RiskProfileTab";

const ComplianceTab = () => {
  const {
    state: { tab },
    dispatch,
  } = useAppContext();

  useDocumentTitle("Client Onboarding");

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={6} display={"flex"}>
        <Tabs
          variant="standard"
          className="light"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
          onChange={(e, val) => {
            dispatch({ type: "TAB", data: { ...tab, complianceTabs: val } });
          }}
          value={tab?.complianceTabs}
        >
          <Tab label="KYC" />
          <Tab label="Agreement" />
          <Tab label="Risk Profile" />
          <Tab label="Advice Registry" />
        </Tabs>
      </Grid2>
      <Grid2 size={12}>
        <Show.When isTrue={tab?.complianceTabs === 0}>
          <ComplianceKYCPage />
        </Show.When>
        <Show.When isTrue={tab?.complianceTabs === 1}>
          <ComplianceAgreement />
        </Show.When>
        <Show.When isTrue={tab?.complianceTabs === 2}>
          <RiskProfileTab />
        </Show.When>
        <Show.When isTrue={tab?.complianceTabs === 3}>
          <AdviceRegister />
        </Show.When>
      </Grid2>
    </Grid2>
  );
};

export default ComplianceTab;
