import { Grid2, Typography, styled } from "components";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { AdviceRegisterColor } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";

interface Props {
  dataItem: any;
  index: number;
}

const CustomContainer = styled(Grid2)(() => () => ({
  padding: "16px",
  borderRadius: 10,
  border: "1px solid #E0E0E0",
  marginBottom: 4,
  "&:hover": {
    cursor: "pointer",
  },
}));

const RiskProfileAdviceRegister = (props: Props) => {
  const navigate = useNavigate();

  const { dataItem, index } = props;
  const { theme } = ThemeContainer();

  return (
    <CustomContainer
      key={index}
      position={"relative"}
      size={12}
      onClick={() => {
        navigate(
          `/app/risk-profile/${dataItem?.clientId}/questionnaire/${dataItem?.data.id}`,
        );
      }}
    >
      <Grid2 container spacing={1}>
        <Grid2 size={6} display={"flex"}>
          <Typography
            fontWeight={600}
            fontSize={12}
            p={"4px"}
            borderRadius={1}
            color={AdviceRegisterColor[dataItem?.type]}
            bgcolor={`${AdviceRegisterColor[dataItem?.type]}22`}
          >
            Risk Profile
          </Typography>
        </Grid2>
        <Grid2
          size={8}
          display={"flex"}
          alignItems={"center"}
          gap={2}
          justifyContent={"flex-start"}
        >
          <Typography
            fontWeight={400}
            fontSize={12}
            display={"flex"}
            alignItems="center"
            color={theme.palette.secondaryText.main}
          >
            Risk Profile :&nbsp;
            <Typography fontWeight={600} fontSize={12} color={"#000"}>
              {dataItem?.data?.riskStatus}
            </Typography>
          </Typography>
          <Typography
            fontWeight={400}
            fontSize={12}
            display={"flex"}
            alignItems="center"
            color={theme.palette.secondaryText.main}
          >
            Risk Score :&nbsp;
            <Typography fontWeight={600} fontSize={12} color={"#000"}>
              {dataItem?.data?.points}
            </Typography>
          </Typography>
        </Grid2>
        <Grid2
          size={4}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Typography
            fontWeight={400}
            fontSize={12}
            color={theme.palette.secondaryText.main}
          >
            {dayjs(dataItem?.data?.createdAt).format("DD MMM YYYY  hh:mm a") ||
              "-"}
          </Typography>
        </Grid2>
      </Grid2>
    </CustomContainer>
  );
};

export default RiskProfileAdviceRegister;
