import { useAppContext } from "AppContext";
import { Grid2, Show } from "components";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  GET_PORTFOLIO_BY_ID,
  PORTFOLIO_HOLDING_SUMMARY_BY_ID,
  PORTFOLIO_REFRESH_XIRR_BY_ID,
} from "utils/apiRoutes";
import { refetchQuery } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import PortfolioAdminViewTab from "./PortfolioAdminViewTab";
import PortfolioDetailsHeaderCard from "./PortfolioDetailsHeaderCard";
import PortfolioDetailsHeaderSection from "./PortfolioDetailsHeaderSection";
import PortfolioHoldingViewTab from "./PortfolioHoldingViewTab";

const PortfolioDetailsPage = () => {
  useDocumentTitle("Portfolio Details");

  const {
    state: { tab },
  } = useAppContext();

  const { id } = useParams<{ id: string }>();

  const [isXirrRefresh, setIsXirrRefresh] = useState(false);

  const { isFetching: xirrLoading } = useFetchQuery({
    route: PORTFOLIO_REFRESH_XIRR_BY_ID(id),
    key: ["GET_PORTFOLIO_XIRR"],
    enabled: isXirrRefresh,
    success: () => {
      refetchQuery("GET_PORTFOLIO_DETAIL_CARDS");
      setIsXirrRefresh(false);
    },
  });

  const { data: portfolioDetailData, isFetching } = useFetchQuery({
    key: ["GET_PORTFOLIO_DETAIL_BY_ID", id],
    route: GET_PORTFOLIO_BY_ID(id),
  });

  console.log("portfolioDetailData", portfolioDetailData);

  const { isFetching: cardDataLoading, data: holdingSummaryDetails } =
    useFetchQuery({
      key: ["GET_PORTFOLIO_DETAIL_CARDS", id],
      route: PORTFOLIO_HOLDING_SUMMARY_BY_ID(id),
    });

  return (
    <>
      <DashboardLayout title="PortfolioDetailsPage" loading={isFetching}>
        <Grid2 container spacing={2}>
          <PortfolioDetailsHeaderSection
            portfolioDetailData={portfolioDetailData?.data}
            holdingSummaryDetails={holdingSummaryDetails?.data}
          />
          <Show.When isTrue={tab?.portfolioExtendedViewTabs !== 0}>
            <PortfolioDetailsHeaderCard
              cardDataLoading={cardDataLoading}
              holdingSummaryDetails={holdingSummaryDetails?.data}
              xirrLoading={xirrLoading}
              setIsXirrRefresh={setIsXirrRefresh}
            />
          </Show.When>
          <Show.When isTrue={tab?.portfolioTabs === 0}>
            <PortfolioHoldingViewTab
              holdingSummaryDetails={holdingSummaryDetails?.data}
            />
          </Show.When>

          <Show.When isTrue={tab?.portfolioTabs === 1}>
            <PortfolioAdminViewTab
              portfolioDetailData={portfolioDetailData?.data}
              holdingSummaryDetails={holdingSummaryDetails?.data}
            />
          </Show.When>
        </Grid2>
      </DashboardLayout>
    </>
  );
};

export default PortfolioDetailsPage;
