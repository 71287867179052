import styled from "@emotion/styled";
import { Avatar, Button, Grid2, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { ThemeContainer } from "utils/Theme";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface Props {
  src: any;
  buttonLabel: string;
  onDelete: any;
  onUpdate?: any;
  description?: string;
}

const ImageUpload = ({
  src,
  buttonLabel,
  onDelete,
  onUpdate,
  description,
}: Props) => {
  const [imageSrc, setImageSrc] = useState<any>(null);

  const { theme } = ThemeContainer();

  return (
    <Grid2 container alignItems={"center"} gap={1}>
      <Grid2>
        <Avatar
          alt=""
          src={imageSrc || src}
          sx={{
            width: 72,
            height: 72,
            border: "1px solid #eee",
            borderColor: theme.palette.border.main,
          }}
        />
      </Grid2>
      <Grid2 ml={1}>
        <Grid2 container spacing={1}>
          <Grid2>
            <Button
              variant="contained"
              component="label"
              role={undefined}
              tabIndex={-1}
            >
              {buttonLabel || "Upload Profile Photo"}
              <VisuallyHiddenInput
                type="file"
                accept={".jpg, .png, .jpeg"}
                onChange={(e) => {
                  const imageData = e.target.files[0];
                  const imageURL = URL.createObjectURL(imageData);
                  setImageSrc(imageURL);
                  onUpdate(e);
                }}
              />
            </Button>
          </Grid2>
          <Grid2>
            <IconButton
              sx={{
                border: "1px solid",
                borderColor: theme.palette.border.main,
                borderRadius: "8px",
                p: 1,
              }}
              onClick={onDelete}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Grid2>
          <Grid2 size={12}>
            <Typography color={theme.palette.secondaryText.main}>
              {description}
            </Typography>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default ImageUpload;
