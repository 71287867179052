import { Grid2, Typography } from "components";

export const allocationTypee = () => {
  return (
    <>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Allocation :
          </Typography>{" "}
          Allocate new/fresh funds in a model or a company
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Rebalance :
          </Typography>{" "}
          Rebalance client’s portfolio or single company in the client’s
          portfolio
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Exit :
          </Typography>{" "}
          Exit or sell from a client’s portfolio or a single company in the
          client’s portfolio
        </Typography>
      </Grid2>
    </>
  );
};

export const AllocationBy = () => {
  return (
    <>
      <Grid2 size={12}>
        <Typography fontWeight={500}>
          Select your base for order creation, a model or a company.
        </Typography>
      </Grid2>
    </>
  );
};

export const RebalanceBy = () => {
  return (
    <>
      <Grid2 size={12}>
        <Typography fontWeight={500}>
          Select your base for order creation, a model or a company.
        </Typography>
      </Grid2>
    </>
  );
};

export const ModelReferenceWeightage = () => {
  return (
    <>
      <Grid2 size={12}>
        <Typography fontWeight={500}>
          Select your model weightage preference to be used for order creation
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Allocated :
          </Typography>{" "}
          This represents the intended or target weightage of each asset in your
          portfolio at the time of creation of your model portfolio or at the
          time of your last rebalance.
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Current :
          </Typography>{" "}
          This represents the actual distribution of assets in your portfolio at
          the current time. It reflects how the market value of your holdings
          has evolved due to changes in prices or contributions/withdrawals.
        </Typography>
      </Grid2>
    </>
  );
};

export const AllocationReferenceValueModel = () => {
  return (
    <>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Committed value :
          </Typography>{" "}
          The capital that the client has agreed to invest in a given portfolio,
          this option will also consider the existing holdings of the asset
          while creating the orders.
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Committed value :
          </Typography>{" "}
          This option will not consider the existing holdings of the asset while
          creating the orders, it will straight away allocate funds on the basis
          of the total committed value.
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Ledger balance :
          </Typography>{" "}
          This option will use the available ledger balance to allocate funds
          while creating orders.
        </Typography>
      </Grid2>
    </>
  );
};

export const ExitReferenceValue = () => {
  return (
    <>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Current value :
          </Typography>{" "}
          Current value refers to the total present holding value of the
          portfolio, this option will create exit orders in order to achieve the
          specified weightage for the company(selected in the next step) w.r.t
          the current holdings of the portfolio.
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Portfolio value :
          </Typography>{" "}
          Portfolio value refers to the total present holding value of the
          portfolio plus the available ledger balance, this option will create
          exit orders in order to achieve the specified weightage for the
          company(selected in the next step) w.r.t the portfolio value of the
          portfolio.
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Committed value :
          </Typography>{" "}
          The capital that the client has agreed to invest in a given portfolio,
          this option will create exit orders in order to achieve the specified
          weightage for the company(selected in the next step) w.r.t the
          portfolio value of the portfolio.
        </Typography>
      </Grid2>
    </>
  );
};

export const AllocationReferenceValueCompany = () => {
  return (
    <>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Current value :
          </Typography>{" "}
          Current value refers to the total present holding value of the
          portfolio, this option will create orders in order to achieve the
          specified weightage for the company(selected in the next step) w.r.t
          the current holdings of the portfolio.
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Portfolio value :
          </Typography>{" "}
          Portfolio value refers to the total present holding value of the
          portfolio plus the available ledger balance, this option will create
          orders in order to achieve the specified weightage for the
          company(selected in the next step) w.r.t the portfolio value of the
          portfolio.
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Committed value :
          </Typography>{" "}
          The capital that the client has agreed to invest in a given portfolio,
          this option will also consider the existing holdings of the asset
          while creating the orders.
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Committed value - Absolute :
          </Typography>{" "}
          This option will not consider the existing holdings of the asset while
          creating the orders, it will straight away allocate funds on the basis
          of the total committed value.
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Ledger balance :
          </Typography>{" "}
          This option will use the available ledger balance to allocate funds
          while creating orders.
        </Typography>
      </Grid2>
    </>
  );
};

export const RebalanceReferenceValueModel = () => {
  return (
    <>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Current value :
          </Typography>{" "}
          Current value refers to the total present holding value of the
          portfolio, this option will create orders in order to adjust the asset
          allocations within the portfolio to align it with target weightages in
          the model, it won’t use any extra available funds as a part of the
          rebalance.
        </Typography>
      </Grid2>

      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Portfolio value :
          </Typography>{" "}
          Portfolio value refers to the total present holding value of the
          portfolio plus the available ledger balance, this option will create
          orders in order to adjust the asset allocations within the portfolio
          to align it with target weightages in the model, it can use extra
          available funds(available ledger balance) as a part of the rebalance.
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Committed value :
          </Typography>{" "}
          The capital that the client has agreed to invest in a given portfolio,
          this option will create orders in order to adjust the asset
          allocations according to the committed value to align it with target
          weightages in the model.
        </Typography>
      </Grid2>
    </>
  );
};

export const RebalanceReferenceValueCompany = () => {
  return (
    <>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Current value :
          </Typography>{" "}
          Current value refers to the total present holding value of the
          portfolio, this option will create orders in order to adjust the
          allocation of the selected asset within the portfolio to align it with
          target weightage in the model, it won’t use any extra available funds
          as a part of the rebalance.
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Portfolio value :
          </Typography>{" "}
          Portfolio value refers to the total present holding value of the
          portfolio plus the available ledger balance, this option will create
          orders in order to adjust the allocation of the selected asset within
          the portfolio to align it with target weightage in the model, it can
          use extra available funds(available ledger balance) as a part of the
          rebalance.
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Committed value :
          </Typography>{" "}
          The capital that the client has agreed to invest in a given portfolio,
          this option will create orders in order to adjust the allocation of
          the selected asset according to the committed value to align it with
          target weightage in the model.
        </Typography>
      </Grid2>
    </>
  );
};

export const ExitBy = () => {
  return (
    <>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Model :
          </Typography>{" "}
          This option would create orders to exit all the assets in the model
          present in the portfolio according to the specified weightage.
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Portfolio :
          </Typography>{" "}
          This option would create orders to exit all the assets present in the
          portfolio according to the specified weightage.
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Company - Absolute :
          </Typography>{" "}
          This option would create orders to exit the specified weightage of the
          asset from its total present holdings in the portfolio.
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Company - Relative :
          </Typography>{" "}
          This option would create exit/sell orders in order for the asset to
          reach the specified weightage in the portfolio.
        </Typography>
      </Grid2>
    </>
  );
};

export const WeightageDistributionMethod = () => {
  return (
    <>
      <Grid2 size={12}>
        <Typography fontWeight={500}>
          This is relevant only when you recalculate your order after putting
          certain assets(which are being bought as a part of the order) on hold.
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            Prorata :
          </Typography>{" "}
          Selecting this option will redistribute the hold amount into the other
          assets being bought in a prorata basis according to the allocation
          weightages.
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <Typography component="span" fontWeight={750}>
            No Redistribution :
          </Typography>{" "}
          Selecting this option will not redistribute the hold amount into other
          assets and add this amount to the ledger balance.
        </Typography>
      </Grid2>
    </>
  );
};
