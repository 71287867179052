import { GridColDef } from "@mui/x-data-grid-pro";
import { CommonPagination, GKDataGrid } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CLIENT_MANDATE_PRESENTATION_LIST } from "utils/apiRoutes";
import { handleCapitalize } from "utils/common";
import { extraColumnsField, matchDate } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import useDocumentTitle from "utils/useDocumentTitle";
import MandateDetailsPageCard from "./MandateDetailsPageCard";

const MandatePresentation = () => {
  useDocumentTitle("Mandate Presentation");

  const location = useLocation();
  const { clientName, portfolioId, portfolioName } = location.state || {};

  const { id } = useParams();
  const navigate = useNavigate();

  const requestBody = useGenerateQueryParams("mandatePresentation");

  const { data, isFetching } = useFetchQuery({
    key: ["MANDATE_PRESENTATION_LIST", requestBody],
    route: CLIENT_MANDATE_PRESENTATION_LIST(id),
    requestBody,
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        name="mandatePresentation"
        totalCount={data?.data?.totalCount || 0}
      />
    );
  };

  const columns: GridColDef[] = [
    // {
    //   ...extraColumnsField("Settlement Date", "settlementDate", 100, 1),
    //   type: "date",
    //   valueFormatter: (value) => matchDate(value?.value),
    // },
    {
      ...extraColumnsField("Frequency", "frequency", 200, 1),
      valueGetter: (row) => handleCapitalize(row?.row?.frequency || "-"),
    },
    {
      ...extraColumnsField("Total Presentations", "paymentCount", 200, 1),
      valueGetter: (row) =>
        `${row?.row?.paidCount || 0}/${row?.row?.paymentCount || 0}`,
    },
    {
      ...extraColumnsField("Settlement Date", "settlementDate", 150, 1),
      type: "date",
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("Amount", "amount", 150, 1),
      valueGetter: (row) => row?.row?.amount,
      valueFormatter: (params) => moneyCommaSeparator(params.value || 0),
      type: "number",
    },
    {
      ...extraColumnsField("Status", "status", 150, 1),
      valueGetter: (row) => handleCapitalize(row?.row?.status || "-"),
    },
  ];
  return (
    <DashboardLayout title="Mandate Presentation">
      <MandateDetailsPageCard
        clientName={clientName}
        portfolioId={portfolioId}
        portfolioName={portfolioName}
      />
      <GKDataGrid
        loading={isFetching}
        columns={columns}
        rows={data?.data?.data || []}
        CustomPagination={!isFetching && cusPagination}
        name="mandatePresentation"
        onRowClick={(params) =>
          navigate(
            `/app/revenue/mandate/${id}/mandate-presentation/${params.row.id}/transaction`,
            {
              state: {
                clientName: clientName,
                portfolioId: portfolioId,
                portfolioName: portfolioName,
              },
            },
          )
        }
        maxHeight={"calc(100vh - 321px)"}
      />
    </DashboardLayout>
  );
};

export default MandatePresentation;
