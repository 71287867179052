/* eslint-disable import/no-named-as-default-member */
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  DragDrop,
  FormControl,
  FormControlLabel,
  GKCheckbox,
  GKModal,
  GKSelect,
  Grid2,
  MenuItem,
  Show,
} from "components";
import { useAgreement } from "hooks/useAgreement";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import {
  AGREEMENT_SEND_OPTIONS_SOURCE,
  AGREEMENT_SIGNATURE_SOURCE,
} from "utils/constants";

const ComplianceAgreementModal = (props: GKModalProps) => {
  const { setOpen, open } = props;

  const [uploadError, setUploadError] = useState<string | null>(null);

  const { refetch } = useAgreement();

  const { id } = useParams();

  const { mutate: handleUploadAgreement, isLoading } = useMutation({
    mutationKey: ["UPLOAD_AGREEMENT"],
    mutationFn: (data: any) => axiosInstance.post(`/agreements/${id}/`, data),
    onSuccess: (response) => {
      refetch();
      toast.success(response.data?.message || "Action completed successfully.");
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: handleUploadManualAgreement, isLoading: manualLoading } =
    useMutation({
      mutationKey: ["UPLOAD_AGREEMENT"],
      mutationFn: (data: any) =>
        axiosInstance.post(`/agreements/${id}/?manual=true`, data),
      onSuccess: (response) => {
        refetch();
        toast.success(
          response.data?.message || "Action completed successfully.",
        );
        setOpen(false);
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  const form = useForm({
    defaultValues: {
      document: [],
      signatureType: "",
      sendOptions: "",
      agreementStatus: "",
      manual: false,
    },
  });

  form.watch();

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={"Client Agreement"}
      footer={
        <LoadingButton
          type="submit"
          variant="contained"
          form="agreement-form"
          size="medium"
          loading={isLoading || manualLoading}
        >
          Save
        </LoadingButton>
      }
    >
      <form
        id="agreement-form"
        onSubmit={form.handleSubmit((values) => {
          const fileData = new FormData();
          fileData.append("document", values.document[0]);
          fileData.append("sendOptions", values.sendOptions);
          fileData.append("signatureType", values.signatureType);
          fileData.append("client", id);

          if (values.manual) {
            const formData = new FormData();
            formData.append("document", values.document[0]);
            formData.append("client", id);
            handleUploadManualAgreement(formData);
          } else {
            handleUploadAgreement(fileData);
          }
        })}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            <Controller
              name="document"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please upload a document",
                },
              }}
              render={({ field, formState: { errors } }) => (
                <DragDrop
                  uploadError={uploadError}
                  setUploadError={setUploadError}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  supportName=".png, .jpg, .pdf"
                  acceptType={".pdf,.png,.jpg"}
                  error={Boolean(errors.document)}
                />
              )}
            />
          </Grid2>
          <Grid2 container size={12} spacing={1}>
            <Grid2 size={12}>
              <Controller
                name="manual"
                control={form.control}
                render={({ field }) => {
                  return (
                    <FormControlLabel
                      {...field}
                      style={{
                        marginLeft: 0,
                      }}
                      label="Skip Signature"
                      control={
                        <GKCheckbox
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.checked);
                          }}
                        />
                      }
                    />
                  );
                }}
              />
            </Grid2>
            <Show.When isTrue={!form?.getValues("manual")}>
              <Grid2 size={6}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="signatureType"
                    control={form.control}
                    rules={{
                      required: {
                        value: !form.getValues("manual"),
                        message: "Please select a sign type",
                      },
                    }}
                    render={({ field, formState: { errors } }) => (
                      <GKSelect
                        {...field}
                        requiredField={!form.getValues("manual")}
                        inputLabel="Sign Type"
                        displayEmpty
                        error={Boolean(errors.signatureType)}
                        helperText={
                          Boolean(errors.signatureType) &&
                          errors.signatureType?.message
                        }
                      >
                        <MenuItem style={{ display: "none" }} value="">
                          Select Sign Type
                        </MenuItem>
                        {AGREEMENT_SIGNATURE_SOURCE?.map((dataItem, i) => (
                          <MenuItem key={i} value={dataItem.value}>
                            {dataItem.name}
                          </MenuItem>
                        ))}
                      </GKSelect>
                    )}
                  />
                </FormControl>
              </Grid2>
            </Show.When>

            <Show.When isTrue={!form?.getValues("manual")}>
              <Grid2 size={6}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="sendOptions"
                    control={form.control}
                    rules={{
                      required: {
                        value: !form.getValues("manual"),
                        message: "Please select a send agreement via option",
                      },
                    }}
                    render={({ field, formState: { errors } }) => (
                      <GKSelect
                        {...field}
                        inputLabel="Send Agreement Via"
                        displayEmpty
                        error={Boolean(errors.sendOptions)}
                        helperText={
                          Boolean(errors.sendOptions) &&
                          errors.sendOptions?.message
                        }
                        requiredField={!form.getValues("manual")}
                      >
                        <MenuItem style={{ display: "none" }} value="">
                          Send Agreement Via
                        </MenuItem>
                        {AGREEMENT_SEND_OPTIONS_SOURCE?.map((dataItem, i) => (
                          <MenuItem key={i} value={dataItem.value}>
                            {dataItem.name}
                          </MenuItem>
                        ))}
                      </GKSelect>
                    )}
                  />
                </FormControl>
              </Grid2>
            </Show.When>
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default ComplianceAgreementModal;
