import { Drawer, Grid2, IconButton, Typography } from "@mui/material";
import React from "react";
import { RxCross1 } from "react-icons/rx";
import { ThemeContainer } from "utils/Theme";
import SimpleLoader from "./SimpleLoader";

interface GKSideModalProps {
  children: React.ReactNode;
  setOpen?: any;
  open: boolean;
  loading?: boolean;
  width?: any;
  modalTitle: string;
  footerButton?: any;
  onClose?: any;
  titleComponent?: any;
}

const GKSideModal = (props: GKSideModalProps) => {
  const { theme } = ThemeContainer();
  const {
    children,
    setOpen,
    open,
    width,
    footerButton,
    titleComponent,
    modalTitle,
    onClose,
    loading,
  } = props;
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose || (() => setOpen(false))}
      PaperProps={{
        sx: {
          // eslint-disable-next-line object-shorthand
          width: width,
          background: theme.palette.bgColor.main,
        },
      }}
    >
      <Grid2 container height={"100%"}>
        <Grid2
          p={1.5}
          size={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            color={theme.palette.dark.main}
            fontSize={18}
            fontWeight={700}
            whiteSpace={"nowrap"}
          >
            {modalTitle}
          </Typography>
          <span
            style={{
              display: "flex",
              gap: 10,
              alignItems: "center",
            }}
          >
            {titleComponent}
            <IconButton
              onClick={() => {
                if (onClose) {
                  onClose();
                }
                setOpen(false);
              }}
              sx={{
                borderRadius: 6,
                color: theme.palette.secondaryText.main,
                background: "transparent",
                border: "none",
                "&:hover": {
                  color: theme.palette.secondaryText.main,
                  background: "transparent",
                  border: "none",
                },
              }}
            >
              <RxCross1 size={20} />
            </IconButton>
          </span>
        </Grid2>

        <Grid2 size={12} height="calc(100vh - 126px)" p={1.5} overflow={"auto"}>
          {loading ? <SimpleLoader /> : children}
        </Grid2>

        <Grid2
          size={12}
          p={1.5}
          style={{
            background: theme.palette.light?.main,
          }}
        >
          {footerButton}
        </Grid2>
      </Grid2>
    </Drawer>
  );
};

export default GKSideModal;
