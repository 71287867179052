import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { object, string } from "yup";

export const useRegistration = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { state } = useLocation();

  const navigate = useNavigate();

  const validationSchema = object({
    phone: string().required("Please enter phone number"),
    countryCode: string(),
  });

  const form = useForm({
    defaultValues: {
      phone: "",
      countryCode: "+91",
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (value: any) => {
    setIsLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/otp/${value?.countryCode}-${value?.phone}/send/`,
      )
      .then(() => {
        setIsLoading(false);
        navigate("/login/opt-login", {
          state: {
            ...state,
            phone: value.phone,
            countryCode: value?.countryCode,
          },
        });
        toast.success("OTP sent successfully.");
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      });
  };

  return {
    isLoading,
    navigate,
    form,
    onSubmit,
  };
};
