import { LoadingButton } from "@mui/lab";
import { blueGrey } from "@mui/material/colors";
import { useMutation } from "@tanstack/react-query";
import {
  BiCopy,
  Box,
  Button,
  EditIcon,
  FormControl,
  GKModal,
  GKTextField,
  Grid2,
  IconButton,
  LuCheck,
  MdOutlineClose,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";

const ViewStepsModal = ({ open, setOpen }: GKModalProps) => {
  const { theme } = ThemeContainer();

  const [passwordField, setPasswordField] = useState("");

  const [isEditing, setIsEdting] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const { data } = useFetchQuery({
    key: ["GET_MUTUAL_FUND_EMAIL"],
    route: `/advisor-auto-forward/`,
    success: (res: any) => {
      setPasswordField(res?.data?.data?.password);
    },
  });

  const { mutate: updatePassword } = useMutation({
    mutationFn: (requestData: any) =>
      axiosInstance.post(`/advisor-auto-forward/`, requestData),
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
      refetchQuery("GET_MUTUAL_FUND_EMAIL");
    },
    onSuccess: (response: any) => {
      toast.success(
        response?.data?.message || "Action Performed Successfully.",
      );
      refetchQuery("GET_MUTUAL_FUND_EMAIL");
    },
  });

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const { mutate: handleConfirmMail, isLoading } = useMutation({
    mutationFn: (requestData: any) =>
      axiosInstance.post(`/advisor-auto-forward/${requestData}/confirm/`),
    onSuccess: (response: any) => {
      const link = document.createElement("a");
      link.href = response.data.code;
      link.target = "_blank";
      link.click();

      handleNext();

      toast.success(
        response?.data?.message || "Action Performed Successfully.",
      );
      refetchQuery("GET_MUTUAL_FUND_EMAIL");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
      refetchQuery("GET_MUTUAL_FUND_EMAIL");
    },
  });

  const steps = [
    {
      label: "Login to your Google ID ",
      description: (
        <Typography fontWeight={600} fontSize={14} my={2}>
          Login to your Google ID Click the settings icon (top-right) and then
          click See all settings from the dropdown.
        </Typography>
      ),
    },
    {
      label: "Forwarding and POP/IMAP",
      description: (
        <Typography fontWeight={600} fontSize={14} my={2}>
          Next, go to the{" "}
          <strong
            style={{
              color: theme.palette.primary.main,
            }}
          >
            Forwarding and POP/IMAP
          </strong>{" "}
          . Then click{" "}
          <strong
            style={{
              color: theme.palette.primary.main,
            }}
          >
            add a forwarding address.
          </strong>
        </Typography>
      ),
    },
    {
      label: "Copy the email address",
      description: (
        <Typography fontWeight={600} fontSize={14} my={2}>
          Copy the email address mentioned above, paste it into Gmail: {""}
          <strong
            style={{
              color: theme.palette.primary.main,
            }}
          >
            Add a forwarding address
          </strong>{" "}
          {""}
          field, click Next, confirm the pop-up, and click {""}
          <strong
            style={{
              color: theme.palette.primary.main,
            }}
          >
            Proceed.
          </strong>
        </Typography>
      ),
    },
    {
      label: "Verify your permission",
      description: (
        <Typography fontWeight={600} fontSize={14} my={2}>
          Please click the link below to verify your permission. After clicking,
          you will be redirected to a confirmation page. Once confirmed, please
          return here and continue.
        </Typography>
      ),
    },
    {
      label: "Disable forwarding",
      description: (
        <Typography fontWeight={600} fontSize={14} my={2}>
          Next, make sure to select{" "}
          <strong
            style={{
              color: theme.palette.primary.main,
            }}
          >
            Disable forwarding
          </strong>{" "}
          before proceeding. Once you have disabled forwarding, you can proceed
          to create the forwarding filter.
        </Typography>
      ),
    },
    {
      label: "Filter messages like these",
      description: (
        <Typography fontWeight={600} fontSize={14} my={2}>
          Next, return to your inbox and search for the email containing your
          file. Once you find it, click on the three dots in the top right
          corner of the email. Then select{" "}
          <strong
            style={{
              color: theme.palette.primary.main,
            }}
          >
            Filter messages like these.
          </strong>
        </Typography>
      ),
    },
    {
      label: "Set Up Email Filters",
      description: (
        <Typography fontWeight={600} fontSize={14} my={2}>
          <li style={{ marginBottom: 2 }}>
            Add the senders email address in the{" "}
            <strong
              style={{
                color: theme.palette.primary.main,
              }}
            >
              From
            </strong>{" "}
            field.
          </li>
          <li style={{ marginBottom: 2 }}>
            Under{" "}
            <strong
              style={{
                color: theme.palette.primary.main,
              }}
            >
              Subject
            </strong>
            , enter keywords that are always part of the email subject.
          </li>
          <li style={{ marginBottom: 2 }}>
            Check{" "}
            <strong
              style={{
                color: theme.palette.primary.main,
              }}
            >
              Has attachment
            </strong>{" "}
            to ensure that only emails that contain a file attachment are
            forwarded.
          </li>
          <p
            style={{
              fontSize: "1.125rem",
              marginTop: "8px",
              marginRight: "8px",
              fontWeight: "500",
            }}
          >
            Next, click on the
            <strong
              style={{
                color: theme.palette.primary.main,
              }}
            >
              {" "}
              Create Filter{" "}
            </strong>
            option.
          </p>
        </Typography>
      ),
    },
    {
      label: "Select the ( Forward it to )",
      description: (
        <Typography fontWeight={600} fontSize={14} my={2}>
          Finally, select the ({" "}
          <strong
            style={{
              color: theme.palette.primary.main,
            }}
          >
            Forward it to
          </strong>{" "}
          ) option and choose the correct auto-forward Gridkey email address
          from the dropdown. Then click{" "}
          <strong
            style={{
              color: theme.palette.primary.main,
            }}
          >
            {" "}
            Create Filter{" "}
          </strong>{" "}
          . Your auto-forward rule is now set up for this template. Your future
          files will be automatically forwarded and imported to your Gridkey
          account.
        </Typography>
      ),
    },
  ];

  return (
    <GKModal
      open={open}
      width={"70%"}
      maxHeight="500px"
      setOpen={setOpen}
      modalTitle="Mutual Fund Setup"
      footer={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 1,
          }}
        >
          {activeStep !== 0 && (
            <Button
              variant="outlined"
              onClick={() => {
                handleBack();
              }}
            >
              Previous
            </Button>
          )}
          {activeStep !== 7 && (
            <LoadingButton
              loading={isLoading}
              variant="contained"
              onClick={() => {
                activeStep === 3
                  ? handleConfirmMail(data?.data?.data?.id)
                  : handleNext();
              }}
            >
              Next
            </LoadingButton>
          )}
          {activeStep === 7 && (
            <LoadingButton
              loading={isLoading}
              variant="contained"
              onClick={() => {
                setOpen(false);
              }}
            >
              Finish
            </LoadingButton>
          )}
        </Box>
      }
    >
      <Grid2 container rowGap={2} spacing={2}>
        <Grid2 size={6}>
          <FormControl variant="standard" fullWidth>
            <GKTextField
              inputLabel={"Email"}
              value={data?.data?.data?.email}
              slotProps={{
                input: {
                  readOnly: true,
                  endAdornment: (
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${data?.data?.data?.email}`,
                        );
                      }}
                    >
                      <BiCopy />
                    </IconButton>
                  ),
                },
              }}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={6}>
          <FormControl variant="standard" fullWidth>
            <GKTextField
              inputLabel={"Password"}
              placeholder="Enter Password"
              onChange={({ target }) => {
                setPasswordField(target.value);
              }}
              value={passwordField}
              slotProps={{
                input: {
                  endAdornment: isEditing ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        color="success"
                        onClick={() => {
                          updatePassword({
                            password: passwordField,
                          });
                        }}
                      >
                        <LuCheck />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => {
                          setIsEdting(false);
                        }}
                      >
                        <MdOutlineClose />
                      </IconButton>
                    </Box>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setIsEdting(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  ),
                },
              }}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={5}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel>
                  <Typography
                    sx={{ fontWeight: 600, fontSize: 12, color: blueGrey[700] }}
                  >
                    {step.label}
                  </Typography>
                </StepLabel>
                <StepContent>{step.description}</StepContent>
              </Step>
            ))}
          </Stepper>
        </Grid2>
        {activeStep === 0 && (
          <Grid2
            size={7}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            style={{
              padding: 10,
              borderRadius: 10,
            }}
          >
            <img
              src="/AutoImportPics/Gmail1.1.svg"
              style={{
                width: "100%",
                height: "auto",
              }}
              alt="step-1"
            />
          </Grid2>
        )}
        {activeStep === 1 && (
          <Grid2
            size={7}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            style={{
              padding: 10,
              borderRadius: 10,
            }}
          >
            {" "}
            <img
              src="/AutoImportPics/Gmail2.1.svg"
              style={{
                width: "100%",
                height: "auto",
              }}
              alt="step-1"
            />
          </Grid2>
        )}
        {activeStep === 2 && (
          <Grid2
            size={7}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            style={{
              padding: 10,
              borderRadius: 10,
            }}
          >
            <img
              src="/AutoImportPics/Gmail3.1.svg"
              style={{
                width: "100%",
                height: "auto",
              }}
              alt="step-1"
            />
          </Grid2>
        )}
        {activeStep === 3 && (
          <Grid2
            size={7}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            style={{
              padding: 10,
              borderRadius: 10,
            }}
          >
            <img
              src="/AutoImportPics/Gmail4.1.svg"
              style={{
                width: "100%",
                height: "auto",
              }}
              alt="step-1"
            />
          </Grid2>
        )}
        {activeStep === 4 && (
          <Grid2
            size={7}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            style={{
              padding: 10,
              borderRadius: 10,
            }}
          >
            <img
              src="/AutoImportPics/Gmail5.1.svg"
              style={{
                width: "100%",
                height: "auto",
              }}
              alt="step-1"
            />
          </Grid2>
        )}
        {activeStep === 5 && (
          <Grid2
            size={7}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            style={{
              padding: 10,
              borderRadius: 10,
            }}
          >
            {" "}
            <img
              src="/AutoImportPics/Gmail6.1.svg"
              style={{
                width: "100%",
                height: "auto",
              }}
              alt="step-1"
            />
          </Grid2>
        )}
        {activeStep === 6 && (
          <Grid2
            size={7}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            style={{
              padding: 10,
              borderRadius: 10,
            }}
          >
            <img
              src="/AutoImportPics/Gmail7.1.svg"
              style={{
                width: "100%",
                height: "auto",
              }}
              alt="step-1"
            />
          </Grid2>
        )}
        {activeStep === 7 && (
          <Grid2
            size={7}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            style={{
              padding: 10,
              borderRadius: 10,
            }}
          >
            <img
              src="/AutoImportPics/Gmail8.1.svg"
              style={{
                width: "100%",
                height: "auto",
              }}
              alt="step-1"
            />
          </Grid2>
        )}
      </Grid2>
    </GKModal>
  );
};

export default ViewStepsModal;
