import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  GKModal,
  GKSearchSelect,
  GKTextField,
  Grid2,
  TextField,
  Typography,
} from "components";
import useAddPortfolioGroup from "hooks/useAddPortfolioGroup";
import { Controller } from "react-hook-form";
import { GKModalProps } from "utils/commonInterface";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";

const CreatePortfolioGrouping = ({ setOpen, open }: GKModalProps) => {
  const { portfolios, addGroupLoading, form, onSubmit } = useAddPortfolioGroup({
    setOpen,
  });

  return (
    <GKModal
      modalTitle="Create Portfolio Group"
      open={open}
      setOpen={setOpen}
      footer={
        <Grid2
          container
          display={"flex"}
          justifyContent={"flex-end"}
          gap={1}
          alignItems={"center"}
        >
          <LoadingButton
            variant="contained"
            type="submit"
            size="medium"
            form="portfolio-group-form"
            loading={addGroupLoading}
          >
            {"Create Portfolio Group"}
          </LoadingButton>
        </Grid2>
      }
    >
      <form onSubmit={form.handleSubmit(onSubmit)} id="portfolio-group-form">
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="name"
                control={form.control}
                render={({ field }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Portfolio Group Name"
                    placeholder="Portfolio Group Name"
                    error={Boolean(form?.formState?.errors?.name)}
                    helperText={form?.formState?.errors?.name?.message}
                  />
                )}
              />
            </FormControl>
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="portfolios"
              control={form.control}
              render={({ field }) => (
                <GKSearchSelect
                  disableClearable
                  multiple
                  renderOption={(props, option) => {
                    return (
                      <li
                        {...props}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography fontWeight={600}>
                          {option.clientName}
                        </Typography>
                        <Typography>{option.name}</Typography>
                      </li>
                    );
                  }}
                  options={portfolios?.filter((item: any) => item.name !== "")}
                  inputLabel="Portfolio"
                  getOptionLabel={(option) => option.name || option}
                  onChange={(e, val) => {
                    field.onChange(val);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Portfolio",
                        value: field.value,
                      })}
                      error={Boolean(form?.formState?.errors?.portfolios)}
                      helperText={form?.formState?.errors?.portfolios?.message}
                    />
                  )}
                />
              )}
            />
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default CreatePortfolioGrouping;
