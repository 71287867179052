import { CommonPagination, GKDataGrid } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useParams } from "react-router-dom";
import { TableFiltering } from "utils/TableFilters";
import { CLIENT_HOLDING_BY_ID } from "utils/apiRoutes";
import { holdingsMainPageColumns } from "utils/columns";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";

const HoldingsMainPage = () => {
  useDocumentTitle("Client Holdings");

  const { id } = useParams();

  const requestBody = useGenerateQueryParams("clientHolding");

  const {
    data: holdingTotalData,
    isFetching,
    isLoading,
  } = useFetchQuery({
    key: [QueryKeys.CLIENT_HOLDINGS, requestBody],
    route: CLIENT_HOLDING_BY_ID(id),
    requestBody,
  });

  const totalCurrentAmount = () =>
    !isFetching &&
    holdingTotalData?.data?.data?.reduce(
      (acc: number, obj: any) => acc + obj.currentAmount,
      0,
    );

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={holdingTotalData?.data?.totalCount || 0}
        name="clientHolding"
      />
    );
  };

  return (
    <GKDataGrid
      loading={isFetching}
      columns={holdingsMainPageColumns(totalCurrentAmount())}
      rows={holdingTotalData?.data?.data || []}
      getRowId={(row) => row?.isin}
      zeroHolding
      // onRowClick={(row: any) => {
      //   navigate(`/app/holdings/${id}/transactions`, {
      //     state: {
      //       holdingId: id,
      //       name: row?.row?.name,
      //       gcode: row?.row?.gcode,
      //       currentAmount: row?.row?.currentAmount,
      //       investedAmount: row?.row?.investedAmount,
      //       quantity: row?.row?.quantity,
      //       type: "client",
      //     },
      //   });
      // }}
      maxHeight={"calc(100vh - 350px)"}
      CustomPagination={!isLoading && cusPagination}
      name="clientHolding"
      tableName="client_holding_list"
      headerFilter={<TableFiltering name="clientHolding" />}
      exportFile={{
        path: CLIENT_HOLDING_BY_ID(id),
        exportName: "ClientHoldingList",
      }}
    />
  );
};

export default HoldingsMainPage;
