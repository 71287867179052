import { Loader, NoData } from "components";
import MultiLine from "./MultiLineChart";

interface Props {
  multiLineData: any;
  isLoading: boolean;
}

const MultiLineComponent = (props: Props) => {
  const { multiLineData, isLoading: isNavLoading } = props;
  return (
    <>
      {isNavLoading && <Loader />}
      {!isNavLoading && multiLineData?.length > 0 && (
        <MultiLine chartData={multiLineData} />
      )}
      {!isNavLoading && multiLineData?.length === 0 && <NoData />}
    </>
  );
};

export default MultiLineComponent;
