import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  ConfirmDeleteModal,
  Divider,
  GKButton,
  Grid2,
  MdAdd,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import {
  CreateTransactionInterface,
  Transaction,
} from "interfaces/Transaction";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  EQUITY_ETF_TRANSACTION_BY_ID,
  GET_ALL_OTHER_ASSET_BY_ASSET_TYPE_LIST_BY_PORTFOLIO_ID,
} from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";
import ClientModal from "./ClientModal";
import EquityEtfBody from "./EquityEtfBody";
import EquityFormHeader from "./EquityFormHeader";

const EquityEtfForm = () => {
  useDocumentTitle("Transaction");

  const { id } = useParams();
  const navigate = useNavigate();
  const { state, search } = useLocation();

  const anchorTransaction = new URLSearchParams(search).get("anchor");

  const [isPortfolioCreateModalOpen, setIsPortfolioCreateModalOpen] =
    useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const getTransactionType = state?.transactionType === "OTHER-ASSETS";
  const getAssetType = state?.assetType;

  const { data, isFetching } = useFetchQuery({
    key: [QueryKeys.TRANSACTIONS_LIST, id],
    route: getTransactionType
      ? GET_ALL_OTHER_ASSET_BY_ASSET_TYPE_LIST_BY_PORTFOLIO_ID(id, getAssetType)
      : EQUITY_ETF_TRANSACTION_BY_ID(id),
    enabled: !!id,
  });

  const { mutate: handleCreate, isLoading: createLoading } = useMutation({
    mutationKey: ["ADD_TRANSACTION"],
    mutationFn: (data: any) =>
      axiosInstance.post(
        getTransactionType ? "/other_assets/" : `/transaction/`,
        data,
      ),
    onSuccess: (response) => {
      navigate(-1);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: handleUpdateStock, isLoading: mutationUpdateLoading } =
    useMutation({
      mutationKey: ["UPDATE_TRANSACTION"],
      mutationFn: (data: any) =>
        axiosInstance.patch(
          getTransactionType
            ? `/other_assets/${id}/?asset_type=${getAssetType}`
            : `/transaction/${id}/`,
          data,
        ),
      onSuccess: (response) => {
        navigate(-1);
        toast.success(
          response.data?.message || "Action completed successfully.",
        );
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  const { mutate: handleDelete, isLoading: deleteLoading } = useMutation({
    mutationKey: ["DELETE_TRANSACTION"],
    mutationFn: (id: any) =>
      axiosInstance.delete(
        getTransactionType
          ? `/other_assets/${id}/?asset_type=${getAssetType}`
          : `/transaction/${id}/`,
      ),
    onSuccess: (response) => {
      navigate(-1);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const form = useForm<CreateTransactionInterface>({
    defaultValues: {
      portfolio: (data?.data ? data.data.portfolio : +state?.id) || null,
      date: "",
      brokerName: "",
      contractNoteNum: "",
      anchor: false,
      transactions: [
        {
          brokerage: 0,
          gridCode: "",
          transactionType: "",
          amountWithBrokerage: 0,
          billAmount: 0,
          price: 0,
          quantity: 0,
          totalTransactionCharges: 0,
          cashEntry: false,
          totalCharges: {
            gst: 0,
            otherCharges: 0,
            stampCharges: 0,
            stt: 0,
            transactionCharges: 0,
            sebiTurnoverFees: 0,
          },
        },
      ],
    },
    values: {
      ...data?.data,
      portfolio: (data?.data ? data.data.portfolio : +state?.id) || null,
      anchor: anchorTransaction === "true",
      transactions: data?.data?.transactions?.map((dataItem: Transaction) => {
        const {
          totalCharges: {
            gst,
            otherCharges,
            sebiTurnoverFees,
            stampCharges,
            stt,
            transactionCharges,
          },
        } = dataItem;

        return {
          ...dataItem,
          totalTransactionCharges:
            +gst +
            +otherCharges +
            +sebiTurnoverFees +
            +stampCharges +
            +stt +
            +transactionCharges,
        };
      }),
    },
  });

  const watchedTransactions = useWatch({
    control: form.control,
    name: "transactions",
    defaultValue: [],
  });

  const getTransactionValue = (index: number) => {
    const transaction = watchedTransactions[index];

    if (!transaction) return "0.00"; // Handle undefined transactions

    const {
      transactionType,
      quantity,
      price,
      totalTransactionCharges,
      brokerage,
    } = transaction;

    const transactionTotal = (Number(quantity) || 0) * (Number(price) || 0);
    const chargesTotal =
      (Number(totalTransactionCharges) || 0) + (Number(brokerage) || 0);

    const finalAmount = ["SELL", "BUY_BACK"].includes(transactionType)
      ? transactionTotal - chargesTotal
      : transactionTotal + chargesTotal;

    return finalAmount.toFixed(2);
  };

  return (
    <DashboardLayout
      title={id ? "Update Trade" : "Create Trade"}
      loading={isFetching}
    >
      <Grid2
        container
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={1}
      >
        <Typography fontWeight={600} fontSize={20}>
          {id ? "Update Trade" : "Add Trade"}
        </Typography>

        {!state?.id && (
          <GKButton
            variant="outlined"
            size="medium"
            color="dark"
            onClick={() => setIsPortfolioCreateModalOpen(true)}
            startIcon={<MdAdd />}
          >
            Create New Portfolio
          </GKButton>
        )}
      </Grid2>

      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit((values) => {
            const updateData = {
              ...values,
              transactions: values?.transactions?.map((d: any) => {
                return {
                  ...d,
                  cashEntry: d?.cashEntry || false,
                };
              }),
            };
            id ? handleUpdateStock(updateData) : handleCreate(updateData);
          })}
        >
          <Grid2 container spacing={2} mt={1}>
            <Grid2 size={12}>
              <EquityFormHeader />
            </Grid2>
            <Grid2 size={12}>
              <EquityEtfBody getTransactionValue={getTransactionValue} />
            </Grid2>
            <Grid2 size={12}>
              <Divider />
            </Grid2>
            <Grid2
              size={12}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              gap={1}
            >
              {id && (
                <LoadingButton
                  variant="outlined"
                  size="medium"
                  color="error"
                  loading={deleteLoading}
                  onClick={() => setIsDeleteModalOpen(true)}
                >
                  Delete Trade
                </LoadingButton>
              )}
              <LoadingButton
                variant="contained"
                size="medium"
                type="submit"
                loading={createLoading || mutationUpdateLoading}
              >
                {id ? "Update" : "Save"}
              </LoadingButton>
            </Grid2>
          </Grid2>
        </form>
      </FormProvider>

      {isPortfolioCreateModalOpen && (
        <ClientModal
          isModalOpen={isPortfolioCreateModalOpen}
          setIsModalOpen={setIsPortfolioCreateModalOpen}
        />
      )}

      {isDeleteModalOpen && (
        <ConfirmDeleteModal
          open={isDeleteModalOpen}
          setOpen={setIsDeleteModalOpen}
          title="Transaction"
          onDelete={() => handleDelete(id)}
          isDelete
          deleteMessage="Are you sure you want to delete this transaction? This transaction will permanently be removed. This action cannot be undone."
        />
      )}
    </DashboardLayout>
  );
};

export default EquityEtfForm;
