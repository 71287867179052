/* eslint-disable no-debugger */
import { LoadingButton } from "@mui/lab";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid2,
  Radio,
  RadioGroup,
  Typography,
} from "components";
import { useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { paletteLightTheme } from "utils/ColorTheme";
import { ThemeContainer } from "utils/Theme";

const ExternalLeadRisk = () => {
  const { search } = useLocation();

  const { theme } = ThemeContainer();

  const token = new URLSearchParams(search).get("token");

  const advisorId = new URLSearchParams(search).get("advisor_id");

  const leadId = new URLSearchParams(search).get("lead_id");

  const [questionAnswers, setQuestionAnswers] = useState([]);

  const [isError, setIsError] = useState(false);

  const [questionValidation, setQuestionValidation] = useState<any>({});

  const { data } = useQuery({
    queryKey: ["GET_LEAD_RISK_PROFILE_QUESTION"],
    queryFn: () =>
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/lead-riskprofile-req/questionaire/?lead_id=${leadId}&advisor_id=${advisorId}&token=${token}`,
      ),
    onError: () => {
      setIsError(true);
    },
  });

  // ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*
  const { mutate: riskProfileResponse, isLoading: postLoading } = useMutation({
    mutationKey: ["RISK_PROFILE_QUESTION"],
    mutationFn: (data: any) =>
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/lead-riskprofile-req/${leadId}/questionaire-response/`,
        data,
      ),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      setTimeout(() => {
        window.close();
      }, 3000);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong please try again.",
      );
    },
  });

  // ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*
  const handleOptionChange = (questionId: any, optionItem: any) => {
    const dataToSubmit = {
      question: questionId,
      option: optionItem.id,
      optionPoints: optionItem.optionPoints,
    };

    const isExistQuestion = questionAnswers
      .map((item) => item.question)
      .includes(dataToSubmit.question);

    if (isExistQuestion) {
      const indexToRemove = questionAnswers.findIndex(
        (item) => item.question === dataToSubmit.question,
      );

      if (indexToRemove !== -1) {
        questionAnswers.splice(indexToRemove, 1);
      }
    }

    setQuestionAnswers((prevValue) => [...prevValue, dataToSubmit]);

    const updatedValidation = { ...questionValidation, [questionId]: true };
    setQuestionValidation(updatedValidation);
  };

  // ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*
  const isQuestionAnswered = (questionId: any) => {
    return questionValidation[questionId] || false;
  };

  // ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*
  const handleSubmit = async () => {
    const allQuestionsAnswered = data?.data?.data?.riskQuestions?.every(
      (dataItem: any) => isQuestionAnswered(dataItem.id),
    );

    if (!allQuestionsAnswered) {
      toast.error("Please answer all questions before submitting.");
    } else {
      riskProfileResponse({
        client: leadId,
        response: questionAnswers,
        token,
      });
    }
  };

  return isError ? (
    <Grid2 container p={1} height={`100vh`} width={`100vw`}>
      <Grid2
        p={1}
        size={12}
        container
        borderRadius={2}
        maxHeight={`calc(100vh - 8px)`}
        maxWidth={`calc(100vw - 8px)`}
        border={`solid 1px ${paletteLightTheme.palette.border.main}`}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <img
          src={data?.data?.data?.companyLogo || "LandingPage/newLogo.svg"}
          alt=""
          height={"auto"}
          width={"80px"}
        />
        <Typography>
          You have already submitted the questionnaire. Thank you!
        </Typography>
      </Grid2>
    </Grid2>
  ) : (
    <Grid2 container p={1} height={`100vh`} width={`100vw`} overflow={"auto"}>
      <Grid2
        p={1}
        size={12}
        container
        borderRadius={2}
        maxHeight={`calc(100vh - 8px)`}
        maxWidth={`calc(100vw - 8px)`}
        border={`solid 1px ${paletteLightTheme.palette.border.main}`}
      >
        <Grid2 size={6} display={"flex"} alignItems={"center"} gap={1}>
          <img
            src={data?.data?.data?.companyLogo || "Logos/iconLogo.png"}
            alt=""
            height={"auto"}
            width={"80px"}
          />
          <div>
            <Typography fontWeight={400} fontSize={12} color={"#999"}>
              Advisor Name:
              <span
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                  color: theme.palette.dark.main,
                  marginLeft: 4,
                }}
              >
                {data?.data?.data?.advisorName || "advisor name"}
              </span>
            </Typography>
            <Typography fontWeight={400} fontSize={12} color={"#999"}>
              Client Name:
              <span
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                  color: theme.palette.dark.main,
                  marginLeft: 4,
                }}
              >
                {data?.data?.data?.clientName || "Client name"}
              </span>
            </Typography>
            <Typography fontWeight={400} fontSize={12} color={"#999"}>
              Client Code:
              <span
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                  color: theme.palette.dark.main,
                  marginLeft: 4,
                }}
              >
                {data?.data?.data?.clientCode || "-"}
              </span>
            </Typography>
          </div>
        </Grid2>

        <Grid2
          size={6}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <LoadingButton
            loading={postLoading}
            variant="contained"
            onClick={handleSubmit}
          >
            Submit{" "}
          </LoadingButton>
        </Grid2>
        <Grid2 size={12} py={2}>
          <Divider />
        </Grid2>
        <Grid2
          size={12}
          height={`calc(100vh - 133px)`}
          overflow={"auto"}
          pt={1}
        >
          <Grid2 container spacing={1}>
            <Grid2 size={12}>
              {data?.data?.data?.riskQuestions?.map(
                (dataItem: any, index: number) => {
                  return (
                    <Grid2 container key={dataItem?.id}>
                      <Grid2 size={12}>
                        <FormControl>
                          <Typography
                            color={theme.palette.primary.main}
                            fontWeight={800}
                          >
                            <span>{index + 1}. </span>
                            {dataItem.questionText}
                          </Typography>
                          <RadioGroup>
                            {dataItem?.options?.map((optionItem: any) => {
                              return (
                                <FormControlLabel
                                  key={optionItem?.id}
                                  value={optionItem?.id}
                                  onChange={() => {
                                    handleOptionChange(dataItem.id, {
                                      ...optionItem,
                                    });
                                  }}
                                  control={<Radio />}
                                  label={optionItem?.option}
                                />
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      </Grid2>
                    </Grid2>
                  );
                },
              )}
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default ExternalLeadRisk;
