import { useMutation } from "@tanstack/react-query";
import { FormControl, GKButton, GKModal, GKTextField, Grid2 } from "components";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

interface FormValues {
  noteType: string;
}

const AddNoteTypeModal = (props: GKModalProps) => {
  const { open, setOpen } = props;

  const { mutate: createNewNoteType } = useMutation({
    mutationFn: (data) => axiosInstance.post(`/notes-type/`, data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery(QueryKeys.GET_LEAD);
      refetchQuery(QueryKeys.NOTE_TYPE);
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const form = useForm<FormValues>({
    defaultValues: {
      noteType: "",
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={"Add New Note Type"}
      footer={
        <Grid2 container justifyContent={"flex-end"} gap={1}>
          <GKButton
            variant="outlined"
            size="medium"
            onClick={() => setOpen(false)}
          >
            Cancel
          </GKButton>
          <GKButton
            variant="contained"
            type="submit"
            form="create-new-note-type-form"
            size="medium"
          >
            Submit
          </GKButton>
        </Grid2>
      }
    >
      <form
        id="create-new-note-type-form"
        onSubmit={form.handleSubmit((values: any) => createNewNoteType(values))}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name={"noteType"}
                control={form.control}
                rules={{
                  required: true,
                }}
                render={({ field, formState: { errors } }) => (
                  <GKTextField
                    {...field}
                    requiredField
                    inputLabel={"noteType"}
                    error={Boolean(errors?.noteType)}
                  />
                )}
              />
            </FormControl>
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default AddNoteTypeModal;
