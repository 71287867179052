import { GridColDef } from "@mui/x-data-grid-pro";
import { useMutation } from "@tanstack/react-query";
import { GKButton, GKClientDataGrid, Grid2, StatusField } from "components";
import { useFetchQuery } from "hooks/useQueries";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { FEE_PLAN_BY_PORTFOLIO_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField, matchDate } from "utils/helpers";

interface Props {
  action: boolean;
}

const FeePlansTab = (props: Props) => {
  const { action } = props;

  const { id } = useParams();

  const { data, isFetching, refetch } = useFetchQuery({
    key: ["PORTFOLIO_FEE_PLANS"],
    route: FEE_PLAN_BY_PORTFOLIO_ID(id),
  });

  const { mutate: handleMap } = useMutation({
    mutationKey: ["HANDLE_MAP_UNMAP"],
    mutationFn: (id) =>
      axiosInstance.patch(`/portfolio-feeplan-mapping/${id}/`),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetch();
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Fee Plan", "feePlan", 150, 1),
    },
    {
      ...extraColumnsField("Start Date", "startDate", 150, 1),
      valueFormatter: (value) => matchDate(value?.value),
    },
    {
      ...extraColumnsField("End Date", "endDate", 150, 1),
      valueFormatter: (value) => matchDate(value?.value),
    },
    {
      ...extraColumnsField("Status", "status", 150, 1),
      renderCell: (row) => (
        <StatusField statusString={row?.row?.status.toString()} />
      ),
    },
    action && {
      ...extraColumnsField("Actions", "action", 80, 1),
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <Grid2 container justifyContent={"flex-end"} gap={1}>
            <GKButton
              disabled={!params.row.status}
              size="small"
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                handleMap(params.row.id);
              }}
            >
              Unmap
            </GKButton>
          </Grid2>
        );
      },
    },
  ];

  return (
    <GKClientDataGrid
      loading={isFetching}
      columns={columns}
      rows={data?.data?.data || []}
      name="portfolioFeePlans"
    />
  );
};

export default FeePlansTab;
