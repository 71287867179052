import { Grid2, Show, Tab, Tabs, styled } from "components";
import { motion } from "framer-motion";
import { useState } from "react";
import BasketSection from "./BasketSection";
import PartnerSection from "./PartnerSection";
import WiseSection from "./WiseSection";

const StyledTabs = styled(Tabs)(({ theme }) => {
  return {
    padding: 10,
    fontSize: 18,
    color: "#999999",
    fontWeight: 500,
    borderRadius: 20,
    border: `1px solid ${theme.palette.border.main}`,
    background: "#000000",
    "& .MuiButtonBase-root.MuiTab-root": {
      padding: {
        xs: "8px !important",
        lg: "12px 24px !important",
      },
      fontSize: {
        xs: 10,
        lg: 18,
      },
    },
    "& .MuiTabs-flexContainer ": {
      gap: { xs: 4, lg: 8 },
    },
    "& .MuiButtonBase-root.Mui-selected": {
      color: theme.palette.light.main,
      background: "#122322",
      fontWeight: 500,
      borderRadius: 12,
      padding: "16px",
    },
  };
});

const WealthSection = () => {
  const [wealthTabs, setWealthTabs] = useState(0);

  return (
    <Grid2
      container
      py={7}
      sx={{
        backgroundImage: `url(LandingPage/otherSectionBg.svg)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        px: {
          xs: 3,
          lg: 10,
        },
      }}
    >
      <Grid2
        size={12}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        mb={7}
      >
        <StyledTabs
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
          onChange={(_, val) => {
            setWealthTabs(val);
          }}
          value={wealthTabs}
        >
          <Tab label="Wealth Wise" disableRipple />
          <Tab label="Wealth Basket" disableRipple />
          <Tab label="Wealth Partner" disableRipple />
        </StyledTabs>
      </Grid2>
      <Show.When isTrue={wealthTabs === 0}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <WiseSection />
        </motion.div>
      </Show.When>
      <Show.When isTrue={wealthTabs === 1}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <BasketSection />
        </motion.div>
      </Show.When>
      <Show.When isTrue={wealthTabs === 2}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <PartnerSection />
        </motion.div>
      </Show.When>
    </Grid2>
  );
};

export default WealthSection;
