import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  GKButton,
  GKModal,
  Grid2,
  RiSendPlaneLine,
  Typography,
  VscDebugStart,
} from "components";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";

interface Props {
  form: any;
}

const ClientOnboardingRiskModal = (props: Props & GKModalProps) => {
  const { open, setOpen, form } = props;

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(`/riskprofile-request/`, { client: data }),
    mutationKey: ["RISK_PROFILE_CLIENT_REQUEST"],
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
    onSuccess: (res: any) => {
      toast.success(res?.data?.message || "Action Performed Successfully");
      setOpen(false);
    },
  });

  return (
    <GKModal
      width={"40%"}
      setOpen={setOpen}
      open={open}
      modalTitle="Risk Assessment"
    >
      <Grid2 container spacing={1}>
        <Grid2 size={12}>
          <GKButton
            variant="outlined"
            color="dark"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            onClick={() => {
              form?.setValue("riskProfile", null);
              setTimeout(() => {
                setOpen(false);
              }, 10);
            }}
          >
            <Grid2 container>
              <Grid2
                size={12}
                textAlign={"left"}
                display={"flex"}
                alignItems={"center"}
                gap={0.5}
              >
                <VscDebugStart size={16} />{" "}
                <Typography fontWeight={600} fontSize={16} color={"#000"}>
                  Take Quiz
                </Typography>
              </Grid2>
              <Grid2 size={12} textAlign={"left"}>
                <Typography fontSize={12} textTransform={"none"}>
                  {`Evaluate your client's risk tolerance by answering a series of questions tailored to assess the client's financial risk profile.`}
                </Typography>
              </Grid2>
            </Grid2>
          </GKButton>
        </Grid2>
        <Grid2 size={12}>
          <LoadingButton
            loading={isLoading}
            variant="outlined"
            color="dark"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            onClick={() => {
              mutate(form?.getValues("id"));
            }}
          >
            <Grid2 container>
              <Grid2
                size={12}
                textAlign={"left"}
                display={"flex"}
                alignItems={"center"}
                gap={0.7}
              >
                <RiSendPlaneLine size={16} />{" "}
                <Typography fontWeight={600} fontSize={16} color={"#000"}>
                  Send to Client
                </Typography>
              </Grid2>
              <Grid2 size={12} textAlign={"left"}>
                <Typography fontSize={12} textTransform={"none"}>
                  {`Evaluate your client's risk tolerance by sending them a series of questions to answer`}
                </Typography>
              </Grid2>
            </Grid2>
          </LoadingButton>
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default ClientOnboardingRiskModal;
