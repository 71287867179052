import { Grid2, Tab, Tabs } from "components";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import useDocumentTitle from "utils/useDocumentTitle";
import CommissionTab from "./CommissionTab";
import PartnerTab from "./PartnerTab";

const CommissionManager = () => {
  const [tab, setTab] = useState(0);

  useDocumentTitle("Commisiion Manager");

  return (
    <DashboardLayout title={tab === 0 ? "Partner" : "Commission"}>
      <Grid2 container spacing={1}>
        <Grid2 size={12}>
          <Tabs
            variant="fullWidth"
            value={tab}
            onChange={(e, val) => setTab(val)}
          >
            <Tab disableRipple label="Partner" />
            <Tab disableRipple label="Commission Calculations" />
          </Tabs>
        </Grid2>
        <Grid2 size={12}>
          {tab === 0 && <PartnerTab />}
          {tab === 1 && <CommissionTab />}
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default CommissionManager;
