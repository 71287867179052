import { LoadingButton } from "@mui/lab";
import { FormControl, Grid2 } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKDateRangePicker from "components/GKDateRangePicker";
import GKModal from "components/GKModal";
import GKTextField from "components/GKTextField";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";

interface Props {
  type: string;
}

interface HMPFormInterface {
  timestamp?: string;
  endTimestamp?: string;
  unadjustedPrice: string;
  adjustedPrice: string;
  priceType: string;
}

interface CPMFormInterface {
  price: string;
  priceType: string;
}

type FormValues = HMPFormInterface | CPMFormInterface;

const HistoricalPriceModal = (props: Props & GKModalProps) => {
  const { open, setOpen, type } = props;

  const { id } = useParams();

  const form = useForm<FormValues>({
    defaultValues:
      type === "HMP"
        ? {
            timestamp: "",
            endTimestamp: "",
            adjustedPrice: "",
            unadjustedPrice: "",
            priceType: "HMP",
          }
        : { price: "", priceType: "CMP" },
  });

  const { mutate: handlePriceUpdate, isLoading } = useMutation({
    mutationKey: ["UPDATE_ADVISOR_COMPANY"],
    mutationFn: (values: FormValues) =>
      axiosInstance.post(`/advisor-cm/${id}/price/`, values),
    onSuccess: (response) => {
      if (type === "HMP") {
        refetchQuery("GET_ADVISOR_HISTORICAL_PRICE_LIST");
      } else {
        refetchQuery("GET_PRIVATE_EQUITY_BY_ID");
      }
      toast.success(response.data?.message || "Action completed successfully.");
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const handleSubmit = (data: FormValues) => {
    handlePriceUpdate(data);
  };

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={type === "HMP" ? "Add HMP" : "Update CMP"}
      footer={
        <LoadingButton
          type="submit"
          variant="contained"
          size="medium"
          form="historical-price-form"
          loading={isLoading}
        >
          Save
        </LoadingButton>
      }
    >
      <form
        id="historical-price-form"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <Grid2 container spacing={2}>
          {type === "HMP" && (
            <>
              <Grid2 size={12}>
                <Controller
                  name={"timestamp"}
                  control={form.control}
                  render={({ field }) => (
                    <FormControl variant="standard" fullWidth>
                      <GKDateRangePicker
                        {...field}
                        inputLabel="Select Date"
                        name="date"
                        value={[
                          dayjs(form.getValues("timestamp")),
                          dayjs(form.getValues("endTimestamp")),
                        ]}
                        onChange={(value: any) => {
                          form.setValue(
                            "timestamp",
                            dayjs(value[0]).format("YYYY-MM-DD"),
                          );
                          form.setValue(
                            "endTimestamp",
                            dayjs(value[1]).format("YYYY-MM-DD"),
                          );
                        }}
                        disableFuture
                        slotProps={{
                          textField: {
                            error: false,
                            helperText: false,
                          },
                        }}
                      />
                    </FormControl>
                  )}
                />
              </Grid2>

              <Grid2 size={6}>
                <Controller
                  name="adjustedPrice"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormControl variant="standard" fullWidth>
                        <GKTextField
                          {...field}
                          type="number"
                          inputLabel={"Adjusted Price"}
                        />
                      </FormControl>
                    );
                  }}
                />
              </Grid2>
              <Grid2 size={6}>
                <Controller
                  name="unadjustedPrice"
                  control={form.control}
                  render={({ field }) => {
                    return (
                      <FormControl variant="standard" fullWidth>
                        <GKTextField
                          {...field}
                          type="number"
                          inputLabel={"Unadjusted Price"}
                        />
                      </FormControl>
                    );
                  }}
                />
              </Grid2>
            </>
          )}

          {type === "CMP" && (
            <Grid2 size={12}>
              <Controller
                name="price"
                control={form.control}
                render={({ field }) => {
                  return (
                    <FormControl variant="standard" fullWidth>
                      <GKTextField
                        {...field}
                        type="number"
                        inputLabel={"Price"}
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid2>
          )}
        </Grid2>
      </form>
    </GKModal>
  );
};

export default HistoricalPriceModal;
