/* eslint-disable import/no-named-as-default-member */
import { useMutation } from "@tanstack/react-query";
import {
  Card,
  Divider,
  FormControl,
  FormControlLabel,
  GKButton,
  Grid2,
  Radio,
  RadioGroup,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { ThemeContainer } from "utils/Theme";
import { GET_RISK_PROFILE } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import useDocumentTitle from "utils/useDocumentTitle";

const Questionnaire = () => {
  useDocumentTitle("Risk Profile Question");

  const navigate = useNavigate();

  const { id, type } = useParams();
  const { theme } = ThemeContainer();

  const [questionAnswers, setQuestionAnswers] = useState([]);

  const [questionValidation, setQuestionValidation] = useState<any>({});

  // ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*
  const { data } = useFetchQuery({
    key: ["RISK_PROFILE_LIST"],
    route: GET_RISK_PROFILE,
  });

  // ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*
  const { mutate: riskProfileResponse } = useMutation({
    mutationKey: ["RISK_PROFILE_QUESTION"],
    mutationFn: (data: any) => axiosInstance.post(data.url, data.data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      navigate(-1);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  // ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*
  const handleOptionChange = (questionId: any, optionItem: any) => {
    const dataToSubmit = {
      question: questionId,
      option: optionItem.id,
      optionPoints: optionItem.optionPoints,
    };

    const isExistQuestion = questionAnswers
      .map((item) => item.question)
      .includes(dataToSubmit.question);

    if (isExistQuestion) {
      const indexToRemove = questionAnswers.findIndex(
        (item) => item.question === dataToSubmit.question,
      );

      if (indexToRemove !== -1) {
        questionAnswers.splice(indexToRemove, 1);
      }
    }

    setQuestionAnswers((prevValue) => [...prevValue, dataToSubmit]);

    const updatedValidation = { ...questionValidation, [questionId]: true };
    setQuestionValidation(updatedValidation);
  };

  // ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*
  const isQuestionAnswered = (questionId: any) => {
    return questionValidation[questionId] || false;
  };

  // ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*
  const handleSubmit = async () => {
    const allQuestionsAnswered = data?.data?.data?.data?.riskQuestions?.every(
      (dataItem: any) => isQuestionAnswered(dataItem.id),
    );

    if (!allQuestionsAnswered) {
      toast.error("Please answer all questions before submitting.");
    }

    if (type === "lead") {
      riskProfileResponse({
        url: `/lead-riskprofileresponse/${id}/`,
        data: {
          lead: id,
          response: questionAnswers,
        },
      });
    } else {
      riskProfileResponse({
        url: `/riskprofileresponse/${id}/`,
        data: {
          client: id,
          response: questionAnswers,
        },
      });
    }
  };

  return (
    <DashboardLayout
      title={type === "lead" ? "Lead Risk Profile" : "Client Risk Profile"}
    >
      <Grid2 container spacing={1}>
        <Grid2 size={12}>
          <Card>
            <Grid2 container spacing={1}>
              <Grid2 size={12}>
                {data?.data?.data?.data?.riskQuestions?.map(
                  (dataItem: any, index: number) => {
                    return (
                      <Grid2 container key={dataItem?.id}>
                        <Grid2 size={12}>
                          <FormControl>
                            <Typography
                              color={theme.palette.primary.main}
                              fontWeight={800}
                            >
                              <span>{index + 1}. </span>
                              {dataItem.questionText}
                            </Typography>
                            <RadioGroup>
                              {dataItem?.options?.map((optionItem: any) => {
                                return (
                                  <FormControlLabel
                                    key={optionItem?.id}
                                    value={optionItem?.id}
                                    onChange={() => {
                                      handleOptionChange(dataItem.id, {
                                        ...optionItem,
                                      });
                                    }}
                                    control={<Radio />}
                                    label={optionItem?.option}
                                  />
                                );
                              })}
                            </RadioGroup>
                          </FormControl>
                        </Grid2>
                      </Grid2>
                    );
                  },
                )}
              </Grid2>
              <Grid2 size={12}>
                <Divider />
              </Grid2>
              <Grid2
                size={12}
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gap={2}
              >
                <GKButton
                  variant="outlined"
                  size="medium"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </GKButton>
                <GKButton
                  variant="contained"
                  size="medium"
                  onClick={handleSubmit}
                >
                  Save
                </GKButton>
              </Grid2>
            </Grid2>
          </Card>
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default Questionnaire;
