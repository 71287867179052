import {
  Card,
  FiLock,
  Grid2,
  LuKeyRound,
  TbCloudDataConnection,
  Typography,
} from "components";
import { ThemeContainer } from "utils/Theme";

const SecuritySection = () => {
  const { theme } = ThemeContainer();

  const cardData = [
    {
      icon: "/LandingPage/why1.svg",
      title: "Founder's Vision",
      description:
        "Industry experts with a vision to revolutionize wealth management space.",
      style: {
        borderRight: "1px solid #151515",
        borderBottom: "1px solid #151515",
      },
    },
    {
      icon: "/LandingPage/centric.svg",
      title: "Client-Centric Approach",
      description:
        "We ensure that every feature is designed to meet the unique needs of our users.",
      style: {
        borderRight: "1px solid #151515",
        borderBottom: "1px solid #151515",
      },
    },
    {
      icon: "/LandingPage/holistic.svg",
      title: "Holistic Wealth Management",
      description:
        "Gridkey covers every aspect of wealth management in one integrated platform.",
      style: {
        borderBottom: "1px solid #151515",
      },
    },
    {
      icon: "/LandingPage/intuitive.svg",
      title: "Intuitive Tools",
      description:
        "Leverage cutting-edge tools for growing your advisory business.",
      style: {
        borderRight: "1px solid #151515",
      },
    },
    {
      icon: "/LandingPage/driven.svg",
      title: "Data Driven Insights",
      description:
        "Make informed decisions with insights and reports, empowering you to stay ahead in the game.",
      style: {
        borderRight: "1px solid #151515",
      },
    },
    {
      icon: "/LandingPage/innovation.svg",
      title: "Innovation at Core",
      description:
        "We continuously strive to bring innovative solutions to simplify complex financial landscapes.",
    },
  ];
  return (
    <Grid2
      container
      py={12}
      sx={{
        background: `#010606`,
        borderBottom: "#151515 solid 1px",
        borderTop: "#151515 solid 1px",
        px: {
          xs: 3,
          lg: 10,
        },
      }}
      columnSpacing={{
        lg: 5,
      }}
      rowSpacing={{
        xs: 5,
      }}
    >
      <Grid2 size={12} textAlign={"center"} p={5}>
        <Typography
          fontWeight={600}
          fontSize={{
            xs: 24,
            lg: 36,
          }}
        >
          Why Choose{" "}
          <span
            style={{
              background:
                "linear-gradient(90deg, rgba(0,101,128,1) 0%, rgba(37,131,130,1) 100%)",
              WebkitBackgroundClip: " text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Gridkey?
          </span>
        </Typography>
      </Grid2>
      <Grid2
        size={12}
        container
        justifyContent={"center"}
        alignItems={"center"}
        bgcolor={"#000000"}
        pb={10}
      >
        {cardData.map((dataItem) => (
          <Grid2
            size={{ xs: 12, lg: 4 }}
            display={"flex"}
            justifyContent={"flex-start"}
            flexDirection={"column"}
            padding={5}
            gap={1}
            minHeight={"270px"}
            sx={{
              ...dataItem.style,
              alignItems: {
                xs: "center",
                lg: "flex-start",
              },
              "&:hover": {
                background: "linear-gradient(180deg, black, #25838230)",
                transition: "all 0.3s ",
              },
            }}
          >
            <img
              src={dataItem.icon}
              alt=""
              height={"80px"}
              style={{ marginBottom: 8 }}
            />

            <Typography
              fontSize={{
                xs: 14,
                lg: 20,
              }}
              fontWeight={600}
              sx={{
                textAlign: {
                  xs: "center",
                  lg: "left",
                },
              }}
            >
              {dataItem.title}
            </Typography>
            <Typography
              color={"#999999"}
              fontSize={{
                xs: 14,
                lg: 16,
              }}
              sx={{
                textAlign: {
                  xs: "center",
                  lg: "left",
                },
              }}
            >
              {dataItem.description}
            </Typography>
          </Grid2>
        ))}
      </Grid2>
      <Grid2
        size={{ xs: 12, lg: 4.5 }}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          fontWeight={700}
          fontSize={{
            xs: 24,
            lg: 36,
          }}
          mb={2}
          lineHeight={{
            xs: "31px",
            lg: "43.56px",
          }}
          sx={{
            textAlign: {
              xs: "center",
              lg: "left",
            },
          }}
        >
          We are Serious about
          <br />
          <span
            style={{
              background:
                "linear-gradient(90deg, rgba(0,101,128,1) 0%, rgba(37,131,130,1) 100%)",
              WebkitBackgroundClip: " text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Security
          </span>
        </Typography>
      </Grid2>
      <Grid2 size={{ xs: 12, lg: 2.5 }}>
        <Card
          sx={{
            height: "100%",
            background: "#000000",
            display: "flex",
            justifyContent: {
              lg: "center",
              xs: "flex-start",
            },
            alignItems: {
              xs: "flex-start",
            },
            flexDirection: { xs: "row", lg: "column" },
            border: "1px solid #191919",
            backgroundColor: "#070B0B",
          }}
        >
          <TbCloudDataConnection size="24" color={theme.palette.light.main} />
          <Typography mt={{ lg: 3 }} ml={{ xs: 3, lg: 0 }}>
            Cloud based platform
          </Typography>
        </Card>
      </Grid2>
      <Grid2 size={{ xs: 12, lg: 2.5 }}>
        <Card
          sx={{
            height: "100%",
            background: "#000000",
            display: "flex",
            justifyContent: {
              lg: "center",
              xs: "flex-start",
            },
            alignItems: {
              xs: "flex-start",
            },
            flexDirection: { xs: "row", lg: "column" },

            border: "1px solid #191919",
            backgroundColor: "#070B0B",
          }}
        >
          <FiLock size="24" color={theme.palette.light.main} />
          <Typography mt={{ lg: 3 }} ml={{ xs: 3, lg: 0 }}>
            Cutting-edge web security
          </Typography>
        </Card>
      </Grid2>
      <Grid2 size={{ xs: 12, lg: 2.5 }}>
        <Card
          sx={{
            height: "100%",
            background: "#000000",
            display: "flex",
            justifyContent: {
              xs: "flex-start",
            },
            alignItems: {
              xs: "flex-start",
            },
            flexDirection: { xs: "row", lg: "column" },
            border: "1px solid #191919",
            backgroundColor: "#070B0B",
          }}
        >
          <LuKeyRound size="24" color={theme.palette.light.main} />
          <Typography mt={{ lg: 3 }} ml={{ xs: 3, lg: 0 }}>
            Secure 256-bit SSL for max safety{" "}
          </Typography>
        </Card>
      </Grid2>
    </Grid2>
  );
};

export default SecuritySection;
