import { GridColDef } from "@mui/x-data-grid-pro";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import {
  CgMathPlus,
  CommonPagination,
  ConfirmDeleteModal,
  GKButton,
  GKDataGrid,
  Grid2,
  Typography,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  moneyCommaSeparator,
  moneyCommaSeparatorPoint,
} from "utils/MathFunction";
import { TableFiltering } from "utils/TableFilters";
import axiosInstance from "utils/axiosInstance";
import { handleCapitalize, UserRoleTypes } from "utils/common";
import { extraColumnsField, matchDate, refetchQuery } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";

interface Props {
  title: string;
  name: string;
  apiRoute: string;
  apiRouteKey: string;
  tableName: string;
  routeNavigate?: {
    transactionType?: string;
    assetType?: string;
  };
  bulkDelete: string;
}

const EquityEtf = (props: Props) => {
  const {
    title,
    name,
    apiRoute,
    apiRouteKey,
    tableName,
    routeNavigate,
    bulkDelete,
  } = props;

  useDocumentTitle(title);

  const navigate = useNavigate();

  const {
    state: { userData: userInfo },
  } = useAppContext();

  const [selectedIds, setSelectedIds] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);

  const requestBody = useGenerateQueryParams(name);

  const { data, isFetching } = useFetchQuery({
    route: apiRoute,
    key: [apiRouteKey, requestBody],
    requestBody,
  });

  const { mutate: handleMultiDelete } = useMutation({
    mutationFn: () =>
      axiosInstance?.delete(bulkDelete, {
        data: { ids: selectedIds },
      }),
    onSuccess: (response) => {
      refetchQuery(apiRouteKey);
      setDeleteConfirmation(false);
      setSelectedIds([]);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const cusPagination = () => {
    return (
      <CommonPagination name={name} totalCount={data?.data?.totalCount || 0} />
    );
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Date", "date", 150, 1),
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("Portfolio", "clientName", 250, 1),
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography
              fontWeight={700}
              maxWidth={"100%"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
            >
              {params?.row?.clientName}
            </Typography>
            <Typography fontSize={12}>
              {params?.row?.portfolioName} - {params?.row?.portfolioCode}
            </Typography>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Company Name", "companyName", 150, 1),
    },
    {
      ...extraColumnsField("NSE/BSE Code", "nseCode", 150, 1),
      renderCell: (params) => {
        return (
          <Typography>
            {params?.row?.nseCode || params?.row?.bseCode}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Transaction Type", "transactionType", 150, 1),
      valueFormatter: (params) => handleCapitalize(params?.value),
    },
    {
      ...extraColumnsField("Quantity", "quantity", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params?.row?.quantity || "0",
      valueFormatter: (params: any) => moneyCommaSeparatorPoint(params?.value),
    },
    {
      ...extraColumnsField("Price", "price", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params?.row?.price || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Brokerage", "brokerage", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.brokerage || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField(
        "Amount With Brokerage",
        "amountWithBrokerage",
        190,
        1,
      ),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.amountWithBrokerage || "-",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("STT", "stt", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.stt || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("GST", "gst", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.gst || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Stamp Charges", "stampCharges", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.stampCharges || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Transaction Charges", "transactionCharges", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.transactionCharges || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("SEBI Turnover Fees", "sebiTurnoverFees", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.sebiTurnoverFees || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Other Charges", "otherCharges", 170, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.otherCharges || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Bill Amount", "billAmount", 150, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.billAmount || "0",
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("ISIN Code", "isinCode", 150, 1),
      align: "right",
      headerAlign: "right",
    },
    {
      ...extraColumnsField("Contract Note Number", "contractNoteNum", 190, 1),
      valueGetter: (params) => params.row.contractNoteNum || "-",
      valueFormatter: (params) => params.value,
    },
    {
      ...extraColumnsField("Broker Name", "brokerName", 150, 1),
    },
  ];

  const getColumnVisibility = columns.filter((column) =>
    routeNavigate?.transactionType === "OTHER-ASSETS"
      ? column.field !== "contractNoteNum" && column.field !== "nseCode"
      : column,
  );

  return (
    <>
      <GKDataGrid
        checkboxSelection
        onRowSelectionModelChange={(params) => {
          setSelectedIds(params);
        }}
        rowSelectionModel={selectedIds}
        loading={isFetching}
        columns={getColumnVisibility}
        rows={data?.data?.data || []}
        onRowClick={(row) => {
          if (row?.row?.transactionType !== "BUY_BACK") {
            navigate(`/app/edit-transaction/${row?.id}`, {
              state: {
                transactionType: routeNavigate?.transactionType || "",
                assetType: routeNavigate?.assetType || "",
                id: row?.row?.portfolio,
              },
            });
          }
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              stt: false,
              gst: false,
              stampCharges: false,
              otherCharges: false,
              transactionCharges: false,
              sebiTurnoverFees: false,
            },
          },
        }}
        CustomPagination={!isFetching && cusPagination}
        name={name}
        headerComponent={
          userInfo?.userRole !== UserRoleTypes.client && (
            <Grid2 container gap={1} justifyContent={"end"}>
              {selectedIds?.length !== 0 && (
                <GKButton
                  variant="outlined"
                  size="medium"
                  color="error"
                  onClick={() => {
                    setDeleteConfirmation(true);
                  }}
                >
                  Delete
                </GKButton>
              )}
              {selectedIds?.length !== 0 && (
                <GKButton
                  variant="outlined"
                  size="medium"
                  color="primary"
                  onClick={() => {
                    setSelectedIds([]);
                  }}
                >
                  Cancel
                </GKButton>
              )}
              {selectedIds?.length === 0 && (
                <>
                  <GKButton
                    variant="contained"
                    size="medium"
                    onClick={() => {
                      navigate("/app/create-transaction", {
                        state: {
                          transactionType: routeNavigate?.transactionType || "",
                          assetType: routeNavigate?.assetType || "",
                        },
                      });
                    }}
                    startIcon={<CgMathPlus size={14} />}
                  >
                    Add Trade
                  </GKButton>
                </>
              )}
            </Grid2>
          )
        }
        tableName={tableName}
        headerFilter={<TableFiltering name={name} />}
        exportFile={{
          path: apiRoute,
          exportName: "TransactionList",
        }}
      />

      {deleteConfirmation && (
        <ConfirmDeleteModal
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          onDelete={() => handleMultiDelete()}
          title={"Bulk Statement"}
          isDelete
          deleteMessage={`Are you sure that you want to delete the selected stocks & once deleted it cannot be reverted?`}
        />
      )}
    </>
  );
};

export default EquityEtf;
