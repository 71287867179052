import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  FormControl,
  GKModal,
  GKSearchSelect,
  GKTextField,
  Grid2,
  TextField,
} from "components";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery, smallCaseBroker } from "utils/helpers";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";

interface Props {
  brokerData?: any;
}

const AddBrokerModal = (props: Props & GKModalProps) => {
  const { id } = useParams();
  const { open, setOpen, brokerData } = props;

  const form = useForm({
    defaultValues: {
      broker: brokerData?.id ? brokerData?.broker : "",
      email: brokerData?.id ? brokerData?.email : "",
      ucc: brokerData?.id ? brokerData?.ucc : "",
      portfolio: id,
      isAutoApprove: brokerData?.id ? brokerData?.isAutoApprove : false,
    },
  });

  const { mutate: handleCreateBroker, isLoading } = useMutation({
    mutationKey: ["CREATE_BROKER"],
    mutationFn: (data) => axiosInstance.post("/smallcase-gateway/", data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery("GET_BROKER_DETAILS");
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: handleUpdateBroker, isLoading: updateBrokerLoading } =
    useMutation({
      mutationKey: ["UPDATE_BROKER"],
      mutationFn: (data) =>
        axiosInstance.patch(`/smallcase-gateway/${brokerData?.id}`, data),
      onSuccess: (response) => {
        toast.success(
          response.data?.message || "Action completed successfully.",
        );
        refetchQuery("GET_BROKER_DETAILS");
        setOpen(false);
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  return (
    <GKModal
      modalTitle={
        brokerData?.id ? "Update Broker Integration" : "Add Broker Integration"
      }
      open={open}
      setOpen={setOpen}
      width={brokerData?.id ? "30%" : "50%"}
      footer={
        <Grid2 size={12} textAlign={"right"}>
          <LoadingButton
            variant="contained"
            size="medium"
            type="submit"
            form="broker-form"
            loading={isLoading || updateBrokerLoading}
          >
            {brokerData?.id ? "Update" : "Save"}
          </LoadingButton>
        </Grid2>
      }
    >
      <form
        id="broker-form"
        onSubmit={form.handleSubmit((values: any) =>
          brokerData?.id
            ? handleUpdateBroker(values)
            : handleCreateBroker(values),
        )}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={brokerData?.id ? 12 : 4}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="email"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter email",
                  },
                }}
                render={({ field, formState: { errors } }) => (
                  <GKTextField
                    {...field}
                    requiredField
                    inputLabel="Client Email"
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message as string}
                  />
                )}
              />
            </FormControl>
          </Grid2>
          {!brokerData?.id && (
            <>
              <Grid2 size={4}>
                <Controller
                  control={form.control}
                  name="broker"
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter broker",
                    },
                  }}
                  render={({ field, formState: { errors } }) => {
                    return (
                      <GKSearchSelect
                        {...field}
                        requiredField
                        disableClearable
                        onChange={(e, val) => {
                          field.onChange(val?.value);
                        }}
                        value={
                          smallCaseBroker.find(
                            (dataItem) => dataItem.value === field.value,
                          )?.name
                        }
                        inputLabel="Broker"
                        options={smallCaseBroker || []}
                        getOptionLabel={(option) => option?.name || option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={handleReturnAutocompletePlaceholder({
                              placeholder: "Select Broker",
                              value: field.value,
                            })}
                            error={Boolean(errors.broker)}
                            helperText={errors.broker?.message as string}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid2>
              <Grid2 size={4}>
                <FormControl variant="standard" fullWidth>
                  <Controller
                    name="ucc"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter ucc",
                      },
                      maxLength: {
                        value: 20,
                        message: "Maximum length is 10 characters",
                      },
                    }}
                    render={({ field, formState: { errors } }) => (
                      <GKTextField
                        {...field}
                        requiredField
                        inputLabel="UCC"
                        error={Boolean(errors.ucc)}
                        helperText={errors.ucc?.message as string}
                        placeholder="Like UCC of Demat A/c"
                      />
                    )}
                  />
                </FormControl>
              </Grid2>
            </>
          )}
        </Grid2>
      </form>
    </GKModal>
  );
};

export default AddBrokerModal;
