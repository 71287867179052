/* eslint-disable react/no-array-index-key */
import { useMutation } from "@tanstack/react-query";
import {
  Alert,
  CircularProgress,
  FiTrash2,
  GKButton,
  GKClientDataGrid,
  GKModal,
  GKTextField,
  Grid2,
  IconButton,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { GET_PORTFOLIO_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { extraColumnsField } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

const PortfolioMappingsTab = (props: GKModalProps) => {
  const { open, setOpen } = props;

  const { id } = useParams();

  const [portfolioMappings, setPortfolioMappings] = useState<any>();

  const { isFetching } = useFetchQuery({
    key: [QueryKeys.PORTFOLIOS_LIST, id],
    route: GET_PORTFOLIO_BY_ID(id),
    success: (response: any) => {
      setPortfolioMappings(response?.data);
    },
  });

  const { mutate: handleMappingSubmit, isLoading } = useMutation({
    mutationKey: ["UPDATE_MAPPINGS"],
    mutationFn: (data: any) =>
      axiosInstance.patch(`/portfolios/${Number(id)}/`, data),
    onSuccess: (response) => {
      setOpen(false);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={"Mappings"}
      footer={
        <GKButton
          variant="contained"
          size="medium"
          disabled={
            isLoading ||
            portfolioMappings?.mappings?.filter(
              (data: any) => data.value === "",
            )?.length !== 0
          }
          type="submit"
          onClick={async (event) => {
            event.preventDefault();
            handleMappingSubmit(portfolioMappings);
          }}
        >
          {isLoading ? <CircularProgress size={20} /> : "Save"}
        </GKButton>
      }
      titleComponent={
        <GKButton
          variant="outlined"
          size="medium"
          onClick={() => {
            setPortfolioMappings({
              ...portfolioMappings,
              mappings: [
                {
                  format: "GRIDKEY_CSV",
                  owner: "ADVISOR",
                  portfolio: portfolioMappings.id,
                  value: "",
                },
                ...portfolioMappings.mappings,
              ],
            });
          }}
        >
          Add New Row
        </GKButton>
      }
    >
      <Grid2 container spacing={1}>
        <Grid2 size={12}>
          <Alert severity="info">
            Create portfolio code mappings for import via gridkey template
          </Alert>
        </Grid2>

        <Grid2 size={12}>
          <GKClientDataGrid
            name="portfolioMappings"
            loading={isFetching}
            maxHeight={"210px"}
            showHeaderGrid={false}
            getRowId={(option) => option.uniqueId}
            columns={[
              {
                ...extraColumnsField("Portfolio Code", "Portfolio Code", 0, 1),
                renderCell: (params) => {
                  const index = params?.api?.getRowIndexRelativeToVisibleRows(
                    params?.row?.uniqueId,
                  );
                  return (
                    <GKTextField
                      onChange={(event) => {
                        const newData = portfolioMappings?.mappings?.map(
                          (data: any) => {
                            return {
                              ...data,
                              value:
                                data?.id === params?.row?.id
                                  ? event?.target?.value
                                  : data?.value,
                            };
                          },
                        );
                        setPortfolioMappings({
                          ...portfolioMappings,
                          mappings: newData,
                        });
                      }}
                      value={portfolioMappings?.mappings[index]?.value}
                      placeholder="Enter"
                    />
                  );
                },
              },
              {
                ...extraColumnsField("action", "action", 0, 0),
                align: "right",
                headerAlign: "right",
                renderCell: (params) => {
                  const index = params?.api?.getRowIndexRelativeToVisibleRows(
                    params?.row?.uniqueId,
                  );
                  return (
                    <IconButton
                      className="outline"
                      color="error"
                      onClick={() => {
                        setPortfolioMappings({
                          ...portfolioMappings,
                          mappings: portfolioMappings.mappings.filter(
                            (value: any, i: number) => i !== index,
                          ),
                        });
                      }}
                    >
                      <FiTrash2 size={20} />
                    </IconButton>
                  );
                },
              },
            ]}
            rows={
              portfolioMappings?.mappings.map(
                (dataItem: any, index: number) => {
                  return {
                    ...dataItem,
                    uniqueId: index,
                  };
                },
              ) || []
            }
          />
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default PortfolioMappingsTab;
