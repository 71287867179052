import { isNumber } from "lodash";

export const handleToFixed = (num: number) => {
  return isNumber(num) ? num.toFixed(2) : "";
};

export const moneyCommaSeparator = (value: any) => {
  if (value === " ") {
    return " ";
  }
  return isNumber(value)
    ? value?.toLocaleString("en-IN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : "0.00";
};

export const moneyCommaSeparatorPoint = (value: any) => {
  if (value === " ") {
    return " ";
  }
  return isNumber(value)
    ? value?.toLocaleString("en-IN", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    : "0.00";
};

export const numberToCurrency = (value: any) => {
  if (value === " ") {
    return " ";
  }
  const formattedValue = isNumber(value)
    ? value?.toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
      })
    : "0.00";

  return Math.abs(value) === 0
    ? formattedValue.replace("-", "")
    : formattedValue;
};

export const exponentialToDecimal = (value: any) => {
  if (value === " ") {
    return " ";
  }
  if (value.toString().includes("e")) {
    return Number(value).toFixed(2);
  }
  return isNumber(value) ? String(value?.toFixed(2)) : 0.0;
};

export const handleFoatValues = ({
  num,
  toFixed,
}: {
  num: number;
  toFixed?: number;
}) => {
  const newNum = isNumber(num) ? num : 0;

  return newNum % newNum < 0 ? newNum : newNum?.toFixed(toFixed || 2);
};
