import { Radio, RadioProps } from "@mui/material";
import { RiRadioButtonFill } from "react-icons/ri";
import { ThemeContainer } from "utils/Theme";

const GKRadio = (props: RadioProps) => {
  const { theme } = ThemeContainer();

  return (
    <Radio
      {...props}
      checkedIcon={
        <RiRadioButtonFill size={22} color={theme.palette.primary.main} />
      }
    />
  );
};

export default GKRadio;
