import {
  Grid2,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "components";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ThemeContainer } from "utils/Theme";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 0,
    backdropFilter: "blur(100px)",
    color: theme.palette.dark.main,
    fontSize: 11,
    background: "#40404033",
    border: "solid 1px #ffffff44",
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      backgroundColor: "#ffffff33",
    },
  },
}));

const Nav = () => {
  const { theme } = ThemeContainer();

  const [tooltipOpen, setTooltipOpen] = useState(true);
  const [hoverIndex, setHoverIndex] = useState(null);

  const token = localStorage.getItem("token");

  const { pathname } = useLocation();

  const navLinks = [
    {
      name: "Home",
      className: "",
      target: "",
      to: "/home",
    },
    {
      name: "Product",
      className: "",
      target: "",
      to: "/product/wealth-wise",
      child: [
        {
          name: "Wealth Wise",
          className: "",
          target: "",
          to: "product/wealth-wise",
          isLast: true,
        },
        {
          name: "Wealth Basket",
          className: "",
          target: "",
          to: "product/wealth-basket",
        },
        {
          name: "Wealth Partner",
          className: "",
          target: "",
          to: "product/wealth-partner",
        },
      ],
    },
    {
      name: "About Us",
      className: "",
      target: "",
      to: "about-us",
    },
    token && {
      name: "FAQ",
      className: "",
      target: "",
      to: "faq",
    },
    // {
    //   name: !token ? "Login/Register" : "Dashboard",
    //   className: "",
    //   target: "",
    //   to: !token ? "/login" : "/app/dashboard",
    // },
  ];
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Grid2
      container
      justifyContent={"space-between"}
      alignItems={"center"}
      onMouseLeave={() => setTooltipOpen(false)}
    >
      {navLinks.filter(Boolean).map((link, index) => (
        <LightTooltip
          open={tooltipOpen && index === hoverIndex}
          arrow
          placement="bottom"
          title={
            link.child ? (
              <Grid2
                container
                style={{
                  background: theme.palette.dark.main,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {link?.child?.map((childLink) => (
                  <NavLink
                    key={childLink.name}
                    to={childLink.to || "/"}
                    style={({ isActive }) => {
                      return {
                        color: isActive
                          ? `${theme.palette.primary.main}`
                          : theme.palette.dark.main,
                        borderBottom: "1px solid #ffffff33",
                        backdropFilter: "blur(100px",
                        padding: "10px 30px",
                        fontSize: 16,
                        fontWeight: 500,
                        textDecoration: "none",
                      };
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setTooltipOpen(false);
                    }}
                  >
                    <span>{childLink.name}</span>
                  </NavLink>
                ))}
              </Grid2>
            ) : (
              ""
            )
          }
          key={link.name}
        >
          <NavLink
            key={link.name}
            to={link.to || "/"}
            target={link.target}
            style={() => {
              return {
                color:
                  pathname?.includes(link.to) ||
                  (link.child && pathname.includes("product"))
                    ? theme.palette.light.main
                    : "#CCCCCC",
                background: "transparent",
                fontSize: 16,
                fontWeight:
                  pathname?.includes(link.to) ||
                  (link.child && pathname.includes("product"))
                    ? 800
                    : 400,
                padding: 20,
                textDecoration: "none",
                borderRadius: 10,
              };
            }}
            onClick={(e) => e.stopPropagation()}
            onMouseEnter={() => {
              setTooltipOpen(true);
              setHoverIndex(index);
            }}
          >
            {link.name}
          </NavLink>
        </LightTooltip>
      ))}
    </Grid2>
  );
};

export default Nav;
