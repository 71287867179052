import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { GET_COMPANY_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { panRegex } from "utils/validationSchema";
import * as Yup from "yup";

export const useCompanyInformation = () => {
  const {
    state: { userData: userInfo },
  } = useAppContext();

  const [showCropModal, setShowCropModal] = useState(false);

  const companyID = userInfo?.companyId;

  const phoneValidation = Yup.object({
    phoneNumber: Yup.string().required("Please enter phone number"),
    name: Yup.string()
      .required("Please enter company name")
      .test(
        "whitespace-only",
        "Name cannot be empty or whitespace only",
        (value) => {
          return typeof value === "string" && value.trim().length > 0;
        },
      ),
    // gst: Yup.string().required("Please enter GSTN"),
    // sebiNumber: Yup.string().required("Please enter SEBI registration number"),
    panCard: Yup.string()
      .matches(panRegex, "PAN should have this format ABCDE1234A")
      .min(10, "Please enter valid pan")
      .max(10, "Please enter valid pan"),
    address: Yup.string().required("Please enter address"),
    pincode: Yup.string().required("Please enter pincode"),
    city: Yup.string().required("Please enter city"),
    state: Yup.string().required("Please enter state"),
    country: Yup.string().required("Please enter country"),
    pan: Yup.string()
      .matches(panRegex, "PAN should have this format ABCDE1234A")
      .min(10, "PAN should have 10 character")
      .max(10, "PAN should have 10 character"),
  });

  const { mutate: handleUpdate } = useMutation({
    mutationKey: ["UPDATE_COMPANY"],
    mutationFn: (data: any) =>
      axiosInstance.patch(`/company/${companyID}/`, data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery(QueryKeys.COMPANY_INFO);
      refetchQuery(QueryKeys.GET_USER_DETAIL);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { data: companyData, isFetching: companyFetching } = useFetchQuery({
    key: [QueryKeys.COMPANY_INFO, companyID],
    route: GET_COMPANY_BY_ID(companyID),
    enabled: Boolean(companyID),
  });

  const form = useForm<any>({
    defaultValues: {
      accountName: "",
      accountNumber: "",
      accountType: "",
      address1: "",
      address: "",
      address2: "",
      address3: "",
      bank: "",
      city: "",
      corrAddress1: "",
      corrAddress: "",
      corrAddress2: "",
      corrAddress3: "",
      corrCity: "",
      corrCountry: "",
      corrPincode: "",
      corrState: "",
      country: "",
      facebook: "",
      gst: "",
      instagram: "",
      internal: false,
      isfcCode: "",
      licenseNo: "",
      linkedin: "",
      name: "",
      panCard: "",
      phoneNumber: "",
      pincode: "",
      primaryContactEmail: "",
      primaryContactName: "",
      primaryContactNumber: "",
      sameBillingChecked: false,
      sebiNumber: "",
      secondaryContactEmail: "",
      secondaryContactName: "",
      secondaryContactNumber: "",
      state: "",
      twitter: "",
      website: "",
      whatsappNumber: "",
      countryCode: "+91",
      secondaryCountryCode: "+91",
      whatsappCountryCode: "+91",
      primaryCountryCode: "+91",
      youtube: "",
      logo: [],
    },
    values: { ...companyData?.data, logo: companyData?.data?.files || [] },
    resolver: yupResolver(phoneValidation),
  });

  const onSubmit = (values: any) => {
    const data = new FormData();
    data.append("logo", values?.logo[0]);
    data.append("accountName", values?.accountName);
    data.append("accountNumber", values?.accountNumber);
    data.append("accountType", values?.accountType);
    data.append("whatsappCountryCode", values?.whatsappCountryCode);
    data.append("address1", values?.address1);
    data.append("address", values?.address);
    data.append("address2", values?.address2);
    data.append("address3", values?.address3);
    data.append("bank", values?.bank);
    data.append("city", values?.city);
    data.append("corrAddress1", values?.corrAddress1);
    data.append("corrAddress", values?.corrAddress);
    data.append("corrAddress2", values?.corrAddress2);
    data.append("corrAddress3", values?.corrAddress3);
    data.append("corrCity", values?.corrCity);
    data.append("corrCountry", values?.corrCountry);
    data.append("corrPincode", values?.corrPincode);
    data.append("corrState", values?.corrState);
    data.append("country", values?.country);
    data.append("facebook", values?.facebook);
    data.append("gst", values?.gst);
    data.append("instagram", values?.instagram);
    data.append("internal", values?.internal);
    data.append("isfcCode", values?.isfcCode);
    data.append("licenseNo", values?.licenseNo);
    data.append("linkedin", values?.linkedin);
    data.append("name", values?.name);
    data.append("panCard", values?.panCard);
    data.append("phoneNumber", values?.phoneNumber);
    data.append("pincode", values?.pincode);
    data.append("primaryContactEmail", values?.primaryContactEmail);
    data.append("primaryContactName", values?.primaryContactName);
    data.append("primaryContactNumber", values?.primaryContactNumber);
    data.append("sameBillingChecked", values?.sameBillingChecked);
    data.append("sebiNumber", values?.sebiNumber);
    data.append("secondaryContactEmail", values?.secondaryContactEmail);
    data.append("secondaryContactName", values?.secondaryContactName);
    data.append("secondaryContactNumber", values?.secondaryContactNumber);
    data.append("state", values?.state);
    data.append("twitter", values?.twitter);
    data.append("website", values?.website);
    data.append("whatsappNumber", values?.whatsappNumber);
    data.append("youtube", values?.youtube);
    data.append("countryCode", values?.countryCode);
    data.append("secondaryCountryCode", values?.secondaryCountryCode);
    data.append("primaryCountryCode", values?.primaryCountryCode);
    handleUpdate(data);
  };

  return {
    data: companyData,
    companyFetching,
    showCropModal,
    setShowCropModal,
    form,
    onSubmit,
    handleUpdate,
  };
};
