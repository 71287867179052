import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { Alert, GKButton, GKClientDataGrid, GKModal, Grid2 } from "components";
import { useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { approveAutoContractColumns } from "utils/columns";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

interface Props {
  recordID: any;
  recordData: any;
  approveCompanyModal?: any;
  setApproveCompanyModal?: any;
  setPasswordModal?: any;
  passwordModal?: any;
}

const ApproveAutoContract = (props: Props & GKModalProps) => {
  const {
    setOpen,
    open,
    recordID,
    recordData,
    approveCompanyModal,
    setApproveCompanyModal,
    setPasswordModal,
    passwordModal,
  } = props;

  const [showDuplicates, setShowDuplicates] = useState(false);

  const [selectIds, setSelectIds] = useState([]);

  const { mutate: handleSubmit, isLoading } = useMutation({
    mutationFn: (ids: any) =>
      axiosInstance.post(
        `/transaction_upload/review/?commit=true&id=${recordID}`,
        {
          duplicates_ids: ids,
        },
      ),
    onSuccess: (response) => {
      if (approveCompanyModal) {
        setApproveCompanyModal(false);
      }
      if (passwordModal) {
        setPasswordModal(false);
      }
      setOpen(false);
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery(QueryKeys.UPLOAD_MANAGER_LIST);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Approve Auto Contract"
      width={"70%"}
      footer={
        <Grid2 container justifyContent={"flex-end"} gap={1}>
          {!showDuplicates && (
            <LoadingButton
              onClick={() => {
                handleSubmit(selectIds);
              }}
              loading={isLoading}
              variant="contained"
              size={"medium"}
            >
              Save & Finish
            </LoadingButton>
          )}
        </Grid2>
      }
    >
      <Grid2 container spacing={1}>
        <Grid2
          size={12}
          sx={{
            display:
              recordData?.filter((data: any) => data.isDuplicate)?.length === 0
                ? "none"
                : "block",
            justifyContent: "space-between",
          }}
        >
          <Alert severity="warning">
            There are duplicate found in the file that you have uploaded please
            review your sheet
          </Alert>
        </Grid2>
        <Grid2
          size={12}
          sx={{
            display:
              recordData?.filter(
                (data: any) => data.Error === "" || data.error === "",
              )?.length !== 0
                ? "none"
                : "flex",
            justifyContent: "space-between",
          }}
        >
          <Alert severity="error">
            There are errors found in the sheet. Please review them and
            re-upload the file.
          </Alert>
          <GKButton variant="outlined" color="error">
            Download Errors
          </GKButton>
        </Grid2>
        <Grid2 size={12}>
          <GKClientDataGrid
            name="approveAutoContract"
            maxHeight={"calc(100vh - 450px)"}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(params) => {
              setSelectIds([...new Set([...selectIds, ...params])]);
            }}
            rows={
              showDuplicates
                ? recordData.filter((data: any) => data.isDuplicate)
                : recordData || []
            }
            columns={approveAutoContractColumns}
            headerComponent={
              recordData?.filter((data: any) => data.isDuplicate)?.length !==
                0 && (
                <GKButton
                  onClick={() => {
                    setShowDuplicates(!showDuplicates);
                  }}
                  variant="outlined"
                  color="dark"
                  size="medium"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {showDuplicates ? "Back" : "View Duplicates"}
                </GKButton>
              )
            }
          />
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default ApproveAutoContract;
