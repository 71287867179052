import { Grid2 } from "components";
import { motion } from "framer-motion";
import { useEffect, useRef } from "react";
import HeroSection from "./HeroSection";
import SecuritySection from "./SecuritySection";
import WealthSection from "./WealthSection";

const HomePage = () => {
  const myRef = useRef(null);

  useEffect(() => {
    myRef.current.scrollIntoView();
  });
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div ref={myRef} />
      <Grid2 container>
        <Grid2 size={12}>
          <HeroSection />
        </Grid2>
        <Grid2 size={12}>
          <WealthSection />
        </Grid2>

        <Grid2 size={12}>
          <SecuritySection />
        </Grid2>
      </Grid2>
    </motion.div>
  );
};

export default HomePage;
