import { GridColDef } from "@mui/x-data-grid-pro";
import { useQueries } from "@tanstack/react-query";
import ExecutionSheetModal from "Pages/Settings/ExecutionSheetModal";
import {
  Button,
  GKClientDataGrid,
  Grid2,
  IoIosSend,
  LuDownload,
  Typography,
} from "components";
import dayjs from "dayjs";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { moneyCommaSeparatorPoint } from "utils/MathFunction";
import axiosInstance from "utils/axiosInstance";
import { extraColumnsField } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import OrderByIdPopOverAction from "./OrderByIdPopOverAction";

const EquityBasketOrderManager = () => {
  useDocumentTitle("Order Management");

  const { id } = useParams();

  const navigate = useNavigate();

  const [showSheetModal, setShowSheetModal] = useState(false);

  const [{ data, isFetching }] = useQueries({
    queries: [
      {
        queryKey: ["GET_ALL_MODEL_PROTFOLIO_ORDERS"],
        queryFn: () =>
          axiosInstance.get(`/model_portfolio/${id}/order-manager/`),
        retry: false,
        onError: (error: any) => {
          toast.error(
            error?.response?.data?.message ||
              "Something went wrong, please try again.",
          );
        },
      },
    ],
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Name", "employeeName", 150, 1),
    },
    {
      ...extraColumnsField("Order Type", "orderType", 400, 1),
    },
    {
      ...extraColumnsField("Model Portfolio", "version", 150, 1),
    },
    {
      ...extraColumnsField("Order Date", "orderDate", 100, 1),
      renderCell: (params) => {
        return (
          <Typography>
            {dayjs(params?.row?.orderDate).format("DD MMM YYYY")}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Order Time", "orderDateTime", 100, 1),
      renderCell: (params) => {
        return (
          <Typography>
            {dayjs(params?.row?.orderDate).format("hh:mm a")}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Portfolio Count", "numPortfolios", 120, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (param) => param?.row?.numPortfolios || "-",
      valueFormatter: (params) => moneyCommaSeparatorPoint(params.value),
    },
    {
      ...extraColumnsField("Actions", "action", 80, 0),
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <Grid2 container justifyContent={"flex-end"} gap={1} display={"flex"}>
            <OrderByIdPopOverAction
              item={params.row}
              icon={<LuDownload size={20} />}
              type={["CSV", "ZIP"]}
            />
            <OrderByIdPopOverAction
              item={params.row}
              icon={<IoIosSend size={20} />}
              type={["TRADE", "ORDER"]}
            />
          </Grid2>
        );
      },
    },
  ];

  return (
    <DashboardLayout
      title="Order Manager"
      toolbar={
        <Grid2 container>
          <Button
            variant="contained"
            size="medium"
            onClick={() => {
              setShowSheetModal(true);
            }}
          >
            Add Execution Sheet
          </Button>
        </Grid2>
      }
    >
      <Grid2 container>
        <Grid2 size={12}>
          <GKClientDataGrid
            density="compact"
            name="modelPortfolioOrderManager"
            columns={columns}
            loading={isFetching}
            rows={data?.data?.data || []}
            exportFile={{
              path: `/model_portfolio/${id}/order-manager/`,
              exportName: "EquityBasketOrdersList",
            }}
            onRowClick={(params) => {
              navigate(
                `/app/equity-basket/${id}/order-manager/${params?.row?.id}/portfolios`,
                {
                  state: {
                    data: params?.row,
                  },
                },
              );
            }}
          />
        </Grid2>
      </Grid2>

      {showSheetModal && (
        <ExecutionSheetModal
          open={showSheetModal}
          setOpen={setShowSheetModal}
        />
      )}
    </DashboardLayout>
  );
};

export default EquityBasketOrderManager;
