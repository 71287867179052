import { GridColDef } from "@mui/x-data-grid-pro";
import { CommonPagination, GKDataGrid, Grid2, Typography } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useNavigate, useParams } from "react-router-dom";
import { moneyCommaSeparator } from "utils/MathFunction";
import { FAMILY_ID_BY_PORTFOLIO } from "utils/apiRoutes";
import { handleReturnPositiveNegative } from "utils/colorHelper";
import { extraColumnsField, matchDate } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";

const PortfolioTab = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const requestBody = useGenerateQueryParams("familyPortfolio", {
    defaultShowZeroHolding: true,
  });

  const { data: allClientPortfolios, isLoading } = useFetchQuery({
    key: [QueryKeys.HOLDINGS_LIST, requestBody],
    route: FAMILY_ID_BY_PORTFOLIO(id),
    requestBody,
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={allClientPortfolios?.data?.totalCount || 0}
        name="familyPortfolio"
      />
    );
  };

  const clientPortfoliosColumns: GridColDef[] = [
    {
      ...extraColumnsField("Portfolio", "clientName", 200, 1),
      renderCell: (params) => {
        return (
          <Grid2
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography fontWeight={700}>{params?.row?.clientName}</Typography>
            <Typography fontSize={12}>
              {params?.row?.name} - {params?.row?.portfolioId}
            </Typography>
          </Grid2>
        );
      },
    },
    {
      ...extraColumnsField("Net Contribution", "netContribution", 150, 1),
      type: "number",
      valueGetter: (param) => param?.row?.netContribution,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Invested Value", "investedValue", 150, 1),
      type: "number",
      valueGetter: (param) => param?.row?.investedValue,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Current Value", "currentValue", 150, 1),
      type: "number",
      valueGetter: (param) => param?.row?.currentValue,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
    },
    {
      ...extraColumnsField("Unrealised Gain", "unrealisedGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.unrealisedGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { unrealisedGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(unrealisedGain)}>
            {moneyCommaSeparator(unrealisedGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Unrealised Gain %", "absoluteReturn", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.absoluteReturn,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(value?.row?.absoluteReturn)}
          >
            {moneyCommaSeparator(value?.row?.absoluteReturn)}%
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain", "todaysGain", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGain,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        const { todaysGain } = value?.row;
        return (
          <Typography color={handleReturnPositiveNegative(todaysGain)}>
            {moneyCommaSeparator(todaysGain)}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Today's Gain %", "todaysGainPercentage", 150, 1),
      type: "number",
      valueGetter: (params) => params.row.todaysGainPercentage,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (value) => {
        return (
          <Typography
            color={handleReturnPositiveNegative(
              value?.row?.todaysGainPercentage,
            )}
          >
            {moneyCommaSeparator(value?.row?.todaysGainPercentage)}%
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("XIRR", "xirr", 70, 1),
      type: "number",
      valueGetter: (param) => param?.row?.xirr,
      valueFormatter: (params) => moneyCommaSeparator(params.value),
      renderCell: (param) => (
        <Typography>{param?.row?.xirr?.toFixed(1) || "0"}</Typography>
      ),
    },
    {
      ...extraColumnsField(
        "Last Transaction Date",
        "lastTransactionDate",
        200,
        1,
      ),
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("Last Uploaded Date", "lastUploadDate", 200, 1),
      valueFormatter: (value) => matchDate(value.value),
    },
  ];

  return (
    <GKDataGrid
      loading={isLoading}
      columns={clientPortfoliosColumns}
      rows={allClientPortfolios?.data?.data || []}
      onRowClick={(row) => {
        navigate(`/app/holdings/portfolio/  ${row.row.id}`);
      }}
      zeroHolding
      name="familyPortfolio"
      exportFile={{
        path: FAMILY_ID_BY_PORTFOLIO(id),
        exportName: "FamilyPortfolioList",
      }}
      CustomPagination={!isLoading && cusPagination}
    />
  );
};

export default PortfolioTab;
