import { useAppContext } from "AppContext";
import {
  Alert,
  GKSearchSelect,
  Grid2,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "components";
import { Controller, useFormContext } from "react-hook-form";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";

interface Props {
  fields: any[];
}

const BrokerCompanyMapping = (props: Props) => {
  const { theme } = ThemeContainer();
  const { fields } = props;

  const form = useFormContext();

  const {
    state: { companies, userData: userInfo },
  } = useAppContext();

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography fontSize={16}>Company Mapping</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Alert sx={{ mb: 2 }} severity="info">
                We couldn&#39;t find the following codes, please map them to the
                right companies.
              </Alert>
              {fields &&
                fields?.length !== 0 &&
                fields.map((item: any, index: number) => (
                  <Grid2 size={12} my={1} key={index}>
                    <Controller
                      name={`step3.company.${index}.value`}
                      control={form.control}
                      render={({ field }) => (
                        <GKSearchSelect
                          {...field}
                          disableClearable
                          renderOption={(props, option) => (
                            <li
                              {...props}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 4,
                              }}
                            >
                              <Typography
                                color={theme.palette.light.main}
                                fontSize={8}
                                style={{
                                  background:
                                    option?.instType === "EQ"
                                      ? theme.palette.secondary.main
                                      : theme.palette.warning.main,
                                  padding: "2px 4px",
                                  borderRadius: 4,
                                }}
                              >
                                {option?.instType}
                              </Typography>
                              <Typography whiteSpace={"nowrap"}>
                                {option?.name}
                              </Typography>
                            </li>
                          )}
                          options={companies || []}
                          getOptionLabel={(option) =>
                            option?.companyName || option
                          }
                          inputLabel={item?.advisorCompany}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={`step3.company.${index}.value`}
                              placeholder={handleReturnAutocompletePlaceholder({
                                placeholder: "Select Company",
                                value: field.value,
                              })}
                            />
                          )}
                          onChange={(e, val) => {
                            field.onChange(val?.value);
                            form.setValue(
                              `step3.company.${index}.nseBseCode`,
                              val.bse || val.nse,
                            );
                            form.setValue(
                              `step3.company.${index}.company`,
                              userInfo.companyId,
                            );
                          }}
                          value={
                            companies?.find(
                              (data: any) => data?.gridCode === field.value,
                            )?.companyName || ""
                          }
                        />
                      )}
                    />
                  </Grid2>
                ))}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BrokerCompanyMapping;
