import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { FormControl, GKModal, GKTextField, Grid2 } from "components";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { object, string } from "yup";

const BranchCreateModal = (props: GKModalProps) => {
  const { open, setOpen } = props;

  const { mutate: handleAddFamily, isLoading: isSubmitting } = useMutation({
    mutationFn: (data: any) => axiosInstance.post("/family/", data),
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
    onSuccess: (response) => {
      refetchQuery(QueryKeys.FAMILY_LIST);
      refetchQuery("GET_ALL_FAMILY");
      toast.success(response.data?.message || "Action completed successfully.");
      setOpen(false);
    },
  });

  const validationSchema = object({
    name: string()
      .required("Please enter name")
      .test("no-empty-spaces", "Field cannot contain only spaces", (value) => {
        return value && value.trim().length > 0;
      }),
  });

  const form = useForm({
    defaultValues: {
      name: "",
    },
    resolver: yupResolver(validationSchema),
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Add New Family"
      width={"30%"}
      footer={
        <LoadingButton
          variant="contained"
          size="medium"
          type="submit"
          form="add-family-form"
          loading={isSubmitting}
        >
          {"Create Family"}
        </LoadingButton>
      }
    >
      <form
        onSubmit={form.handleSubmit((values) => handleAddFamily(values))}
        id="add-family-form"
      >
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            <FormControl variant="standard" fullWidth>
              <Controller
                name="name"
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <GKTextField
                    {...field}
                    inputLabel="Family Name"
                    placeholder="Family Name"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </FormControl>
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default BranchCreateModal;
