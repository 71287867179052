import { useParams } from "react-router-dom";
import { GET_LEAD_MEETING_BY_ID } from "utils/apiRoutes";
import { useFetchQuery } from "./useQueries";

export const useMeeting = () => {
  const { id } = useParams<string>();

  const {
    data: getAllLeadMeeting,
    isLoading: isLeadMeetingLoading,
    refetch,
  } = useFetchQuery({
    key: ["GET_ALL_LEAD_MEETINGS", id],
    retry: 0,
    route: GET_LEAD_MEETING_BY_ID(id),
  });

  return { getAllLeadMeeting, isLeadMeetingLoading, refetch };
};
