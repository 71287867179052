import { useAppContext } from "AppContext";
import { Button, Grid2, Switch, Typography } from "components";
import useAccountSetting from "Pages/AccountSetting/useAccountSetting";
import { Controller, useForm } from "react-hook-form";
import useDocumentTitle from "utils/useDocumentTitle";

const LedgerSettings = () => {
  useDocumentTitle("Ledger");

  const {
    state: {
      userData: { broker },
    },
  } = useAppContext();

  const { getCompanySetting, handleUpdateCompanySetting } = useAccountSetting();

  const form = useForm({
    defaultValues: {
      deductTaxFromDividend: false,
    },
    values: {
      deductTaxFromDividend: getCompanySetting?.data?.deductTaxFromDividend,
    },
  });

  return (
    <form
      onSubmit={form.handleSubmit((values) =>
        handleUpdateCompanySetting(values),
      )}
    >
      <Grid2 container spacing={2}>
        <Grid2 size={12} display={"flex"} alignItems={"center"} gap={1}>
          <Controller
            name="deductTaxFromDividend"
            control={form.control}
            render={({ field }) => (
              <Switch
                {...field}
                disabled={!broker?.addDividendToLedger}
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
          <Typography fontSize={16}>
            Account for TDS in dividend accrual
          </Typography>
        </Grid2>
        <Grid2 size={12} display={"flex"} justifyContent={"flex-end"}>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default LedgerSettings;
