import { GridColDef } from "@mui/x-data-grid-pro";
import { CommonPagination } from "components";
import GKButton from "components/GKButton";
import GKDataGrid from "components/GKDataGrid";
import { Show } from "components/Show";
import StatusField from "components/StatusField";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { CgMathPlus } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { ADVISOR_COMPANY_MASTER_LIST } from "utils/apiRoutes";
import { extraColumnsField, matchDate } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import CompanyModal from "./CompanyModal";

interface Props {
  name: string;
  tableName?: string;
  type: string;
  routeKey: string;
}

const PrivateEquity = (props: Props) => {
  const { name, type, routeKey } = props;

  const navigate = useNavigate();

  const [openCompanyModal, setOpenCompanyModal] = useState(false);

  const requestBody = useGenerateQueryParams(name);

  const { data, isFetching } = useFetchQuery({
    key: [routeKey, requestBody],
    route: ADVISOR_COMPANY_MASTER_LIST,
    requestBody,
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Start Date", "startingDate", 100, 1),
      valueFormatter: (params) => matchDate(params?.value),
    },
    { ...extraColumnsField("Company Name", "name", 250, 1) },
    { ...extraColumnsField("Short Name", "shortName", 150, 1) },
    { ...extraColumnsField("Cap", "cap", 100, 1) },
    {
      ...extraColumnsField("CMP", "cmp", 100, 1),
      valueGetter: (row) => moneyCommaSeparator(row?.row?.cmp) || 0,
    },
    {
      ...extraColumnsField("Type", "instType", 100, 1),
    },
    {
      ...extraColumnsField("Sub Type", "instSubType", 100, 1),
    },
    {
      ...extraColumnsField("Status", "status", 100, 1),
      valueFormatter: (params) => params.value || "-",
      renderCell: (params) => {
        return <StatusField statusString={params?.row?.status || "N/A"} />;
      },
    },
  ];

  const cusPagination = () => {
    return (
      <CommonPagination name={name} totalCount={data?.data?.totalCount || 0} />
    );
  };

  return (
    <>
      <GKDataGrid
        rows={data?.data?.data || []}
        columns={columns}
        loading={isFetching}
        headerComponent={
          <GKButton
            variant="contained"
            size="medium"
            onClick={() => setOpenCompanyModal(true)}
            startIcon={<CgMathPlus size={18} />}
          >
            Add Company
          </GKButton>
        }
        name={name}
        // tableName={tableName}
        onRowClick={(params) =>
          navigate(`/company-master/equity/${params?.row?.gcode}`)
        }
        CustomPagination={!isFetching && cusPagination}
      />

      <Show.When isTrue={openCompanyModal}>
        <CompanyModal
          open={openCompanyModal}
          setOpen={setOpenCompanyModal}
          type={type}
          refetchKey={routeKey}
        />
      </Show.When>
    </>
  );
};

export default PrivateEquity;
