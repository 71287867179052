import { Grid2 } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import GKButton from "components/GKButton";
import GKClientDataGrid from "components/GKClientDataGrid";
import { Show } from "components/Show";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { GET_ADVISOR_HISTORICAL_PRICE_LIST } from "utils/apiRoutes";
import { extraColumnsField, matchDate } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import HistoricalPriceModal from "./HistoricalPriceModal";

export interface HistoricalPriceObj {
  adjustedPrice: string;
  unadjustedPrice: string;
  timestamp: string;
  instrument: number;
}

const HistoricalPricesDetails = () => {
  const { id } = useParams();

  const [openPriceModal, setOpenPriceModal] = useState(false);
  const [type, setType] = useState("HMP");

  const { data, isFetching } = useFetchQuery({
    key: ["GET_ADVISOR_HISTORICAL_PRICE_LIST"],
    route: GET_ADVISOR_HISTORICAL_PRICE_LIST(id),
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("TimeStamp", "timestamp", 0, 1),
      valueFormatter: (value) => matchDate(value?.value),
    },
    {
      ...extraColumnsField("Adjusted Price", "adjustedPrice", 0, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => +params.row.adjustedPrice || "0",
      valueFormatter: (params) => moneyCommaSeparator(+params.value),
    },
    {
      ...extraColumnsField("Unadjusted Price", "unadjustedPrice", 0, 1),
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => +params.row.unadjustedPrice || "0",
      valueFormatter: (params) => moneyCommaSeparator(+params.value),
    },
  ];

  return (
    <>
      <GKClientDataGrid
        loading={isFetching}
        columns={columns}
        rows={
          data?.data?.data.map(
            (dataItem: HistoricalPriceObj, index: number) => {
              return {
                ...dataItem,
                id: index,
              };
            },
          ) || []
        }
        name="historicalPrice"
        columnsButton={false}
        headerComponent={
          <Grid2
            container
            gap={1}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <GKButton
              variant="outlined"
              color="dark"
              onClick={() => {
                setType("HMP");
                setOpenPriceModal(true);
              }}
            >
              Add HMP
            </GKButton>
            <GKButton
              variant="outlined"
              color="dark"
              onClick={() => {
                setType("CMP");
                setOpenPriceModal(true);
              }}
            >
              Update CMP
            </GKButton>
          </Grid2>
        }
      />

      <Show.When isTrue={openPriceModal}>
        <HistoricalPriceModal
          open={openPriceModal}
          setOpen={setOpenPriceModal}
          type={type}
        />
      </Show.When>
    </>
  );
};

export default HistoricalPricesDetails;
