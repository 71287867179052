import { GridColDef } from "@mui/x-data-grid-pro";
import GKClientDataGrid from "components/GKClientDataGrid";
import { useFetchQuery } from "hooks/useQueries";
import { useParams } from "react-router-dom";
import { extraColumnsField, matchDate } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";

const FeeDepositHistory = () => {
  const { id } = useParams();

  const { data, isFetching } = useFetchQuery({
    key: ["GET_FEE_DEPOSIT_BY_PORTFOLIO_ID"],
    route: "/portfolio-advance-deposit/",
    requestBody: {
      portfolio: id,
    },
  });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Date", "date", 120, 1),
      valueFormatter: (value) => matchDate(value?.value),
    },
    {
      ...extraColumnsField("Amount", "amount", 120, 1),
      valueGetter: (row) => row?.row?.amount,
      valueFormatter: (params) => moneyCommaSeparator(params.value || 0),
      type: "number",
    },
    {
      ...extraColumnsField("Adjusted against Invoice No.", "invoice", 150, 1),
    },
  ];

  return (
    <GKClientDataGrid
      loading={isFetching}
      columns={columns}
      rows={data?.data?.data || []}
      name="advanceFeeHistory"
      maxHeight={"calc(100vh - 399px)"}
    />
  );
};

export default FeeDepositHistory;
