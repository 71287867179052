import { GridColDef } from "@mui/x-data-grid-pro";
import { CommonPagination, GKDataGrid, Typography } from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { WALLET_HISTORY } from "utils/apiRoutes";
import { extraColumnsField, matchDate } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";

const WalletHistory = () => {
  const requestBody = useGenerateQueryParams("walletHistory");

  const { data: walletHistoryList, isFetching: walletHistoryListFetching } =
    useFetchQuery({
      key: ["GET_WALLET_HISTORY", requestBody],
      route: WALLET_HISTORY,
      requestBody,
    });

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={walletHistoryList?.data?.totalCount || 0}
        name="walletHistory"
      />
    );
  };

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Date", "date", 100, 1),
      valueFormatter: (value) => matchDate(value.value),
    },
    {
      ...extraColumnsField("Particulars", "particulars", 250, 1),
      valueGetter: (params) => params?.row?.chargesType?.type || "-",
      renderCell: (params) => {
        return (
          <Typography>{`${params?.row?.chargesType?.type} ${
            params?.row?.chargesType?.subType
              ? ` - ${params?.row?.chargesType?.subType}`
              : ""
          } `}</Typography>
        );
      },
    },
    {
      ...extraColumnsField("Client", "client", 200, 1),
      valueGetter: (params) => params?.row?.client?.name || "-",
      renderCell: (params) => {
        return (
          <Typography>
            {params?.row?.client?.id
              ? `${params?.row?.client?.name} - ${params?.row?.client?.clientCode}`
              : "-"}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Amount", "amount", 100, 1),
      type: "number",
      valueGetter: (params) => params.row.amount,
      valueFormatter: (params) => `₹${moneyCommaSeparator(+params.value)}`,
    },
    {
      ...extraColumnsField("Balance", "bal", 100, 1),
      type: "number",
      valueGetter: (params) => params.row.bal,
      valueFormatter: (params) => `₹${moneyCommaSeparator(+params.value)}`,
    },
  ];

  return (
    <GKDataGrid
      density="compact"
      loading={walletHistoryListFetching}
      columns={columns}
      rows={walletHistoryList?.data?.data || []}
      maxHeight={`calc(100vh - 338px)`}
      CustomPagination={!walletHistoryListFetching && cusPagination}
      name="walletHistory"
      columnsButton={false}
      exportFile={{
        path: WALLET_HISTORY,
        exportName: "WalletHistory",
      }}
    />
  );
};

export default WalletHistory;
