import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { GKButton, GKModal, Grid2, Show } from "components";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import PortfolioImportCompanyMapping from "./PortfolioImportCompanyMapping";
import PortfolioImportStepOne from "./PortfolioImportStepOne";
import ReviewMapping from "./ReviewMapping";
import StepWiseLoader from "./StepWiseLoader";

interface Props {
  anchorDate: string;
}

interface FormValues {
  fileObject: Record<string, any>;
  activeStep: number;
  step1: {
    format: string;
    transactionType: string;
    anchorDate: string;
    portfolioId: number;
    files: any[];
  };
  step2: {
    company: any[];
  };
  step3: {
    reviewResponse: Record<string, any>;
    showDuplicates: boolean;
    duplicateIds: any[];
  };
}

const PortfolioImportModal = (props: Props & GKModalProps) => {
  const { open, setOpen, anchorDate } = props;

  const { id } = useParams();

  const form = useForm<FormValues>({
    defaultValues: {
      fileObject: {},
      activeStep: 0,
      step1: {
        format: "GRIDKEY_HOLDINGS_CSV",
        transactionType: "Holdings",
        anchorDate: anchorDate || "",
        portfolioId: +id,
        files: [],
      },
      step2: {
        company: [],
      },
      step3: {
        reviewResponse: {},
        showDuplicates: false,
        duplicateIds: [],
      },
    },
  });

  const {
    fields: companyField,
    append: companyAppend,
    remove: companyRemove,
  } = useFieldArray({
    control: form.control,
    name: "step2.company",
  });

  form.watch();

  const watchActiveStep = form.getValues("activeStep");

  const handleNextStep = () => {
    form.setValue("activeStep", watchActiveStep + 1);
  };

  const handlePreviousStep = () => {
    setOpen(false);
  };

  const handleModalTitle = (step: number) => {
    switch (step) {
      case 0:
        return { form: "", title: "Import Files" };
      case 1:
        return { form: "company-mapping-form", title: "Company Mapping" };
      case 2:
        return { form: "", title: "Review imported data " };
      case 3:
        return { form: "", title: "Transaction Completed" };

      default:
        return { form: "", title: "Import Files" };
    }
  };

  const { mutate: handleReviewMapping, isLoading: handleReviewMappingLoading } =
    useMutation({
      mutationKey: ["BROKER_COMPANY_MAPPING_COMMIT_TRUE"],
      mutationFn: (data: any) =>
        axiosInstance.post("/transaction_upload/review_portfolio/", {
          id: data,
          commit: true,
          portfolioId: +id,
        }),
      onSuccess: (response) => {
        toast.success(
          response.data?.message || "Action completed successfully.",
        );
        refetchQuery("GET_ALL_ANCHOR_TRANSACTION");
        setOpen(false);
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  const { mutate: reviewMapping, isLoading: reviewMappingLoading } =
    useMutation({
      mutationKey: ["BROKER_COMPANY_MAPPING"],
      mutationFn: (data: number) =>
        axiosInstance.post("transaction_upload/review_portfolio/", {
          id: data,
          commit: false,
          portfolioId: +id,
        }),
      onSuccess: (response) => {
        form.setValue("step3.reviewResponse", response.data);
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  const { mutate: handleCompanyMapping } = useMutation({
    mutationKey: ["BROKER_COMPANY_MAPPING_POST"],
    mutationFn: (data) =>
      axiosInstance.post("/advisor-company-mappings/", data),
    onSuccess: (response: any) => {
      toast.success(response.data?.message || "Action completed successfully.");
      reviewMapping(form.getValues("fileObject")?.id);
      handleNextStep();
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: companyMapping, isLoading: companyMappingLoading } =
    useMutation({
      mutationKey: ["BROKER_COMPANY_MAPPING"],
      mutationFn: (id: number) =>
        axiosInstance.get(`/transaction_upload/company_mappings/?id=${id}`),
      onSuccess: (response) => {
        const companies = response?.data?.data?.filter(
          (data: any) => data?.value === null,
        );

        if (companies?.length !== 0) {
          companyRemove();
          companies.forEach((company: any) => companyAppend(company));
        } else {
          handleNextStep();
          reviewMapping(form.getValues("fileObject")?.id);
        }
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  const { mutate: importedData, isLoading: importedLoading } = useMutation({
    mutationKey: ["BROKER_API_DATA"],
    mutationFn: (data: any) =>
      axiosInstance.post("/transaction_upload/upload_portfolio/", data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      handleNextStep();
      form.setValue("fileObject", response?.data?.data);
      companyMapping(response?.data?.data?.id);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const loading =
    importedLoading ||
    companyMappingLoading ||
    reviewMappingLoading ||
    handleReviewMappingLoading;

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle={`${handleModalTitle(watchActiveStep)?.title} - Holdings`}
      width={"60%"}
      footer={
        <Grid2
          container
          gap={1}
          justifyContent={watchActiveStep !== 0 ? "space-between" : "flex-end"}
        >
          {watchActiveStep !== 0 && (
            <GKButton
              variant="outlined"
              color="dark"
              size={"medium"}
              onClick={handlePreviousStep}
            >
              Cancel
            </GKButton>
          )}

          <Show.When isTrue={!loading}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 8,
              }}
            >
              {[0, 1].includes(watchActiveStep) && (
                <LoadingButton
                  variant="contained"
                  size={"medium"}
                  type="submit"
                  form="portfolio-import-form"
                  loading={importedLoading}
                >
                  Next
                </LoadingButton>
              )}

              {watchActiveStep === 2 && (
                <LoadingButton
                  variant="contained"
                  size={"medium"}
                  loading={handleReviewMappingLoading}
                  onClick={() => {
                    handleNextStep();
                    handleReviewMapping(form.getValues("fileObject")?.id);
                  }}
                >
                  Finish
                </LoadingButton>
              )}
            </div>
          </Show.When>
        </Grid2>
      }
    >
      <FormProvider {...form}>
        <form
          id="portfolio-import-form"
          onSubmit={form.handleSubmit((values: any) => {
            if (watchActiveStep === 0) {
              const formData = new FormData();

              formData.append("format", "GRIDKEY_HOLDINGS_CSV");
              formData.append(
                "transactionType",
                values?.step1?.transactionType,
              );
              formData.append("anchorDate", values.step1.anchorDate);
              formData.append("portfolioId", values.step1.portfolioId);
              formData.append("files", values.step1.files[0]);

              importedData(formData);
            } else {
              handleCompanyMapping(values?.step2?.company);
            }
          })}
        >
          <Grid2 container spacing={1}>
            <Grid2 size={12}>
              <Show.When isTrue={loading}>
                <StepWiseLoader
                  activeStep={watchActiveStep + 1}
                  loading={loading}
                />
              </Show.When>
            </Grid2>
            <Grid2 size={12}>
              <Show.When isTrue={!loading && watchActiveStep === 0}>
                <PortfolioImportStepOne />
              </Show.When>
            </Grid2>
            <Grid2 size={12}>
              <Show.When isTrue={!loading && watchActiveStep === 1}>
                <PortfolioImportCompanyMapping fields={companyField} />
              </Show.When>
            </Grid2>
            <Grid2 size={12}>
              <Show.When isTrue={!loading && watchActiveStep === 2}>
                <ReviewMapping />
              </Show.When>
            </Grid2>
          </Grid2>
        </form>
      </FormProvider>
    </GKModal>
  );
};

export default PortfolioImportModal;
