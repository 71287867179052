import { LoadingButton } from "@mui/lab";
import {
  Button,
  ConfirmDeleteModal,
  Divider,
  FiTrash2,
  FormControl,
  FormControlLabel,
  GKClientDataGrid,
  GKDatePicker,
  GKSearchSelect,
  GKTextField,
  Grid2,
  IconButton,
  Radio,
  RadioGroup,
  Show,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "components";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import DashboardLayout from "layouts/DashboardLayout";
import { sum } from "lodash";
import React from "react";
import { Controller } from "react-hook-form";
import { numberToCurrency } from "utils/MathFunction";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { hanldeReturnNaturalRows } from "utils/formHelpers";
import { validateNotWhitespaceOnly } from "utils/validationSchema";
import { v4 as uuid } from "uuid";
import ModelBankLedger from "./ModelBankLedger";
import ModelHoldings from "./ModelHoldings";
import ModelOverview from "./ModelOverview";
import ModelTradebook from "./ModelTradebook";
import VersionUploadModal from "./VersionUploadModal";
import useModelPortfolioVersion from "./useModelPortfolioVersion";

const AddModelportfolioVersionModal = () => {
  const {
    handleAddSubmit,
    insert,
    theme,
    companyMasterData,
    openUploadModal,
    setOpenUploadModal,
    submitLoading,
    form,
    tabs,
    setTabs,
    handleUpdateVersion,
    isLoading,
    editModelPortfolioVersion,
    setModelPortfolioVersion,
    getDataByIdLoading,
    modalPortfolioDelete,
    modelPortdolioData,
    modelCardData,
    getAllOldModels,
    setOldIdSelected,
    oldIdSelected,
    modelPortfolioId,
    replace,
    equityBasketDetailsData,
    modalPortfolioDefault,
  } = useModelPortfolioVersion();

  const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);

  const [totalWeightage, setTotalWeightage] = React.useState(0);

  return (
    <DashboardLayout title="Add Model Portfolio" loading={getDataByIdLoading}>
      <Grid2 container spacing={1}>
        <Show.When isTrue={modelPortfolioId && !editModelPortfolioVersion}>
          <Grid2 size={4}>
            <Typography fontWeight={600} fontSize={18}>
              {modelPortdolioData?.data?.version}
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 4,
              }}
            >
              <Typography
                fontWeight={400}
                fontSize={12}
                color={theme.palette.grey[600]}
              >
                Model Creation Date
              </Typography>
              <Typography fontWeight={600} fontSize={14}>
                {dayjs(modelPortdolioData?.data?.date).format("DD MMM YYYY")}
              </Typography>
            </div>
          </Grid2>
          <Grid2
            size={8}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={1}
          >
            <Show.When isTrue={modelPortfolioId && !editModelPortfolioVersion}>
              <GKSearchSelect
                options={getAllOldModels?.data?.data || []}
                getOptionLabel={(opt) => opt?.snapshotName || opt}
                onChange={(e, val) => {
                  setOldIdSelected(val);
                }}
                sx={{
                  width: "300px",
                }}
                disableClearable={false}
                renderInput={(props) => {
                  return (
                    <TextField
                      {...props}
                      placeholder={handleReturnAutocompletePlaceholder({
                        placeholder: "Select Version",
                        value: oldIdSelected,
                      })}
                    />
                  );
                }}
              />
            </Show.When>
            <Show.When
              isTrue={
                modelPortfolioId &&
                !editModelPortfolioVersion &&
                !modelPortdolioData?.data?.rebalanced
              }
            >
              <Button
                variant="outlined"
                color="dark"
                onClick={() => {
                  setModelPortfolioVersion(true);
                  setTotalWeightage(
                    sum(form.getValues("stocks")?.map((d) => d?.allocation)),
                  );
                }}
              >
                Edit Model
              </Button>
            </Show.When>
            <Show.When
              isTrue={
                modelPortfolioId &&
                !editModelPortfolioVersion &&
                !modelPortdolioData?.data?.rebalanced &&
                !modelPortdolioData?.data?.primary
              }
            >
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setDeleteConfirmation(true);
                }}
              >
                Delete Model
              </Button>
            </Show.When>
            <Show.When
              isTrue={
                modelPortfolioId &&
                !editModelPortfolioVersion &&
                !modelPortdolioData?.data?.rebalanced &&
                !modelPortdolioData?.data?.primary
              }
            >
              <Button
                variant="outlined"
                color="success"
                onClick={() => {
                  modalPortfolioDefault(modelPortfolioId);
                }}
              >
                Make As Default
              </Button>
            </Show.When>
          </Grid2>
          <Grid2 size={12}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      width={"14.2%"}
                      sx={{
                        borderRight: `1px solid ${theme.palette.grey[300]}`,
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize={12}
                        color={theme.palette.grey[600]}
                      >
                        Net Contribution
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {numberToCurrency(
                          modelCardData?.data?.data?.netContribution,
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      width={"14.2%"}
                      sx={{
                        borderBottom: `1px solid ${theme.palette.grey[300]}`,
                        borderRight: `1px solid ${theme.palette.grey[300]}`,
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize={12}
                        color={theme.palette.grey[600]}
                      >
                        {" "}
                        Portfolio Value
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {numberToCurrency(
                          modelPortdolioData?.data?.portfolioValue,
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      width={"14.2%"}
                      sx={{
                        borderRight: `1px solid ${theme.palette.grey[300]}`,
                        borderBottom: `1px solid ${theme.palette.grey[300]}`,
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize={12}
                        color={theme.palette.grey[600]}
                      >
                        Ledger Balance
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {numberToCurrency(
                          modelPortdolioData?.data?.ledgerBalance,
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      width={"14.2%"}
                      sx={{
                        borderRight: `1px solid ${theme.palette.grey[300]}`,
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize={12}
                        color={theme.palette.grey[600]}
                      >
                        Dividend
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {numberToCurrency(modelCardData?.data?.data?.dividend)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      width={"14.2%"}
                      sx={{
                        borderBottom: `1px solid ${theme.palette.grey[300]}`,
                        borderRight: `1px solid ${theme.palette.grey[300]}`,
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        fontSize={12}
                        color={theme.palette.grey[600]}
                      >
                        Minimum Investement Value
                      </Typography>
                      <Typography fontWeight={600} fontSize={14}>
                        {numberToCurrency(
                          modelPortdolioData?.data?.minimumInvestement,
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid2>
        </Show.When>
        <Show.When isTrue={modelPortfolioId && !editModelPortfolioVersion}>
          <Grid2 size={12}>
            <Tabs
              value={tabs}
              onChange={(e, val) => {
                setTabs(val);
              }}
            >
              <Tab label="Overview" />
              <Tab label="Holdings" />
              <Tab label="Trade Book" />
              <Tab label="Ledger" />
            </Tabs>
            <Divider />
          </Grid2>
        </Show.When>
        <Show.When isTrue={tabs === 0 && !editModelPortfolioVersion}>
          <Grid2 size={12}>
            <ModelOverview
              selectedId={modelPortfolioId}
              oldIdSelected={oldIdSelected}
            />
          </Grid2>
        </Show.When>
        <Show.When isTrue={tabs === 1 && !editModelPortfolioVersion}>
          <Grid2 size={12}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <ModelHoldings
                selectedId={modelPortfolioId}
                oldIdSelected={oldIdSelected}
              />
            </motion.div>
          </Grid2>
        </Show.When>
        <Show.When isTrue={editModelPortfolioVersion}>
          <Grid2 size={12}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <form
                id="equity-basket-version-form"
                onSubmit={
                  modelPortfolioId
                    ? form?.handleSubmit(handleUpdateVersion)
                    : form?.handleSubmit(handleAddSubmit)
                }
              >
                <Grid2 container spacing={1} size={12}>
                  <Grid2 size={2}>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Please enter version name",
                        },
                        validate: validateNotWhitespaceOnly,
                      }}
                      control={form.control}
                      name="versionName"
                      render={({ field }) => (
                        <FormControl variant="standard" fullWidth>
                          <GKTextField
                            {...field}
                            inputLabel="Model Portfolio Name"
                            placeholder="Model Portfolio Name"
                            name="versionName"
                            error={Boolean(form.formState.errors?.versionName)}
                            helperText={
                              Boolean(form.formState.errors?.versionName) &&
                              `${form.formState.errors?.versionName?.message}`
                            }
                          />
                        </FormControl>
                      )}
                    />
                  </Grid2>
                  <Grid2 size={2}>
                    <Controller
                      control={form.control}
                      name="date"
                      render={({ field }) => (
                        <FormControl variant="standard" fullWidth>
                          <GKDatePicker
                            {...field}
                            onChange={(e) => {
                              field.onChange(dayjs(e).format("YYYY-MM-DD"));
                            }}
                            value={dayjs(field.value)}
                            inputLabel="Model Portfolio start date"
                          />
                        </FormControl>
                      )}
                    />
                  </Grid2>
                  <Grid2 size={2}>
                    <Controller
                      control={form.control}
                      disabled={modelPortdolioData?.data?.data?.isARebalance}
                      name="startValue"
                      rules={{
                        min: 1,
                        required: {
                          value: true,
                          message: "Portfolio value should be greater than 0",
                        },
                      }}
                      render={({ field }) => (
                        <FormControl variant="standard" fullWidth>
                          <GKTextField
                            {...field}
                            disabled={
                              modelPortdolioData?.data?.data?.isARebalance
                            }
                            inputLabel="Model Portfolio Value"
                            placeholder="Model Portfolio Value"
                            error={Boolean(form.formState.errors?.startValue)}
                            helperText={
                              Boolean(form.formState.errors?.startValue) &&
                              `${form.formState.errors?.startValue?.message}`
                            }
                          />
                        </FormControl>
                      )}
                    />
                  </Grid2>
                  <Grid2 size={2}>
                    <Controller
                      disabled={
                        equityBasketDetailsData?.data
                          ?.minimumInvestmentValueCheck
                      }
                      control={form.control}
                      name="minimumInvestement"
                      render={({ field }) => (
                        <FormControl variant="standard" fullWidth>
                          <GKTextField
                            {...field}
                            onChange={(e: any) => {
                              field.onChange(+e.target.value);
                            }}
                            inputLabel="Minimum Invested Value"
                            placeholder="Minimum Invested Value"
                          />
                        </FormControl>
                      )}
                    />
                  </Grid2>
                  <Show.When isTrue={editModelPortfolioVersion}>
                    <Grid2 ml={"auto"} mt={"auto"} mb={"auto"}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setOpenUploadModal(true);
                          setTabs(0);
                        }}
                      >
                        Upload Model
                      </Button>
                    </Grid2>
                  </Show.When>
                  <Grid2 size={12}>
                    <Divider />
                  </Grid2>
                  <Grid2
                    size={12}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <Typography>Weightage Type</Typography>
                    <Controller
                      control={form.control}
                      name="equalWeightage"
                      render={({ field }) => {
                        return (
                          <FormControl
                            sx={{
                              "& .MuiFormGroup-root.MuiRadioGroup-root": {
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                              },
                            }}
                          >
                            <RadioGroup
                              {...field}
                              onChange={(eve, val) => {
                                field.onChange(val);
                                const equalAllocation =
                                  100 / form.getValues("stocks")?.length;
                                const newData = form
                                  .getValues("stocks")
                                  ?.map((da) => {
                                    return {
                                      ...da,
                                      allocation: JSON.parse(val)
                                        ? equalAllocation
                                        : 0,
                                      weightage: JSON.parse(val)
                                        ? equalAllocation
                                        : 0,
                                    };
                                  });
                                setTotalWeightage(
                                  sum(newData?.map((d) => d?.allocation)),
                                );
                                form.setValue("stocks", newData);
                              }}
                            >
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="Custom Weighted"
                              />
                              <FormControlLabel
                                value
                                control={<Radio />}
                                label="Equal Weighted"
                              />
                            </RadioGroup>
                          </FormControl>
                        );
                      }}
                    />
                  </Grid2>
                  <Grid2 size={12}>
                    <GKClientDataGrid
                      maxHeight={"4000vh"}
                      name="addModelPortfoliol"
                      getRowId={(row) => row.uuid}
                      disableRowSelectionOnClick
                      rows={
                        form.getValues("stocks")?.map((d, index) => {
                          return {
                            ...d,
                            uuidIndex: index,
                          };
                        }) || []
                      }
                      columns={[
                        {
                          minWidth: 300,
                          field: "stock",
                          disableColumnMenu: true,
                          valueGetter: (params) => {
                            return params?.row?.companyName || "";
                          },
                          renderCell: (params) => {
                            const index = params.row.uuidIndex;
                            return (
                              <Controller
                                rules={{
                                  required: true,
                                }}
                                control={form.control}
                                name={`stocks.${index}.companyName`}
                                render={({ field, fieldState: { error } }) => {
                                  return (
                                    <GKSearchSelect
                                      {...field}
                                      disableClearable={false}
                                      sx={{
                                        width: "100%",
                                      }}
                                      options={
                                        hanldeReturnNaturalRows({
                                          options: companyMasterData,
                                          values: form.getValues("stocks"),
                                          selectedValueKey: "isinCode",
                                          optionsValueKey: "isin",
                                        }) || []
                                      }
                                      getOptionLabel={(option) =>
                                        option.name || option
                                      }
                                      renderOption={(props, option) => (
                                        <li
                                          {...props}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 4,
                                          }}
                                        >
                                          <Typography
                                            color={theme.palette.light.main}
                                            fontSize={8}
                                            style={{
                                              background:
                                                option?.instType === "EQ"
                                                  ? theme.palette.secondary.main
                                                  : theme.palette.warning.main,
                                              padding: "2px 4px",
                                              borderRadius: 4,
                                            }}
                                          >
                                            {option?.instType}
                                          </Typography>
                                          <Typography whiteSpace={"nowrap"}>
                                            {option?.name}
                                          </Typography>
                                        </li>
                                      )}
                                      onChange={(e, val) => {
                                        form.setValue(
                                          `stocks.${index}.isinCode`,
                                          val?.value,
                                        );
                                        form.setValue(
                                          `stocks.${index}.cap`,
                                          val?.cap,
                                        );
                                        form.setValue(
                                          `stocks.${index}.gridCode`,
                                          val?.gridCode,
                                        );
                                        form.setValue(
                                          `stocks.${index}.companyName`,
                                          val?.name,
                                        );
                                        form.setValue(
                                          `stocks.${index}.sector`,
                                          val?.sector,
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                          name={`stocks.${index}.isinCode`}
                                          placeholder={handleReturnAutocompletePlaceholder(
                                            {
                                              placeholder: "Select Company",
                                              value: field.value,
                                            },
                                          )}
                                          error={!!error}
                                        />
                                      )}
                                    />
                                  );
                                }}
                              />
                            );
                          },
                        },
                        {
                          minWidth: 300,
                          disableColumnMenu: true,
                          field: "weightage",
                          headerName: `Weightage (${totalWeightage?.toFixed(
                            2,
                          )})`,
                          type: "number",
                          valueGetter: (params) => {
                            return params?.row?.allocation
                              ? +params?.row?.allocation
                              : 0;
                          },
                          renderCell: (params) => {
                            const index = params.row.uuidIndex;

                            return (
                              <Controller
                                control={form.control}
                                name={`stocks.${index}.allocation`}
                                rules={{
                                  required: true,
                                }}
                                render={({ field, fieldState: { error } }) => {
                                  return form.getValues("equalWeightage") ===
                                    "true" ? (
                                    <Typography>
                                      {form
                                        ?.getValues(
                                          `stocks.${index}.allocation`,
                                        )
                                        ?.toFixed(2)}
                                    </Typography>
                                  ) : (
                                    <GKTextField
                                      {...field}
                                      onBlur={() => {
                                        setTotalWeightage(
                                          sum(
                                            form
                                              .getValues("stocks")
                                              ?.map((d) => d?.allocation),
                                          ),
                                        );
                                      }}
                                      name={`stocks.${index}.allocation`}
                                      type="number"
                                      value={form.getValues(
                                        `stocks.${index}.allocation`,
                                      )}
                                      placeholder="Weightage"
                                      onChange={(e) => {
                                        form.setValue(`stocks.${index}`, {
                                          ...form.getValues(`stocks.${index}`),
                                          allocation: +e.target.value,
                                          weightage:
                                            (+form.getValues("startValue") /
                                              +e.target.value) *
                                            100,
                                        });
                                      }}
                                      error={!!error}
                                    />
                                  );
                                }}
                              />
                            );
                          },
                        },

                        {
                          field: "action",
                          headerName: "Actions",
                          disableColumnMenu: true,
                          align: "right",
                          headerAlign: "right",
                          sortable: false,
                          renderCell: (params) => {
                            const index = params.row.uuidIndex;
                            return (
                              <IconButton
                                disabled={form.getValues("stocks").length === 1}
                                onClick={() => {
                                  const equalAllocation =
                                    100 /
                                    form
                                      .getValues("stocks")
                                      ?.filter((d, i) => i !== index)?.length;

                                  const updatedData = JSON.parse(
                                    form.getValues("equalWeightage"),
                                  )
                                    ? form
                                        .getValues("stocks")
                                        ?.filter((d, i) => i !== index)
                                        ?.map((da) => {
                                          return {
                                            ...da,
                                            allocation: equalAllocation,
                                            weightage: equalAllocation,
                                          };
                                        })
                                    : form
                                        .getValues("stocks")
                                        ?.filter((d, i) => i !== index);
                                  setTotalWeightage(
                                    sum(updatedData?.map((d) => d?.allocation)),
                                  );
                                  form.setValue("stocks", updatedData);
                                }}
                                color="error"
                                className="outline"
                              >
                                <FiTrash2 size={18} />
                              </IconButton>
                            );
                          },
                        },
                      ]}
                      headerComponent={
                        <Grid2 container justifyContent={"flex-end"}>
                          <Button
                            onClick={() => {
                              insert(0, {
                                isinCode: "",
                                allocation: null,
                                targetPrice: null,
                                stopLoss: null,
                                weightage: null,
                                rationale: "",
                                sector: "",
                                gridCode: "",
                                cap: "",
                                companyName: "",
                                uuid: uuid(),
                              });
                              if (
                                JSON.parse(form.getValues("equalWeightage"))
                              ) {
                                const equalAllocation =
                                  100 / form.getValues("stocks")?.length;
                                const newData = form
                                  .getValues("stocks")
                                  ?.map((da) => {
                                    return {
                                      ...da,
                                      allocation: equalAllocation,
                                      weightage: equalAllocation,
                                    };
                                  });
                                setTotalWeightage(
                                  sum(newData?.map((d) => d?.allocation)),
                                );
                                replace(newData);
                              }
                            }}
                            variant="lightBg"
                            size="small"
                          >
                            Add Stock
                          </Button>
                        </Grid2>
                      }
                    />
                  </Grid2>
                </Grid2>
                <Grid2 size={12} mt={2}>
                  <Grid2 container justifyContent={"flex-end"} gap={1}>
                    <Show.When
                      isTrue={modelPortfolioId && editModelPortfolioVersion}
                    >
                      <Button
                        variant="outlined"
                        color="dark"
                        onClick={() => {
                          setModelPortfolioVersion(false);
                          setTabs(0);
                        }}
                      >
                        Cancel
                      </Button>
                    </Show.When>
                    <Show.When isTrue={editModelPortfolioVersion}>
                      <LoadingButton
                        variant="contained"
                        type="submit"
                        size="medium"
                        form="equity-basket-version-form"
                        loading={submitLoading || isLoading}
                      >
                        {"Submit"}
                      </LoadingButton>
                    </Show.When>
                  </Grid2>
                </Grid2>
              </form>
            </motion.div>
          </Grid2>
        </Show.When>
        <Show.When isTrue={tabs === 2 && !editModelPortfolioVersion}>
          <Grid2 size={12}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <ModelTradebook
                selectedId={modelPortfolioId}
                oldIdSelected={oldIdSelected}
              />
            </motion.div>
          </Grid2>
        </Show.When>
        <Show.When isTrue={tabs === 3 && !editModelPortfolioVersion}>
          <Grid2 size={12}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <ModelBankLedger
                selectedId={modelPortfolioId}
                oldIdSelected={oldIdSelected}
              />
            </motion.div>
          </Grid2>
        </Show.When>
      </Grid2>

      {openUploadModal && (
        <VersionUploadModal
          open={openUploadModal}
          form={form}
          setOpen={setOpenUploadModal}
        />
      )}

      {deleteConfirmation && (
        <ConfirmDeleteModal
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          onDelete={() => modalPortfolioDelete(modelPortfolioId)}
          title="Model Portfolio"
          isDelete
        />
      )}
    </DashboardLayout>
  );
};

export default AddModelportfolioVersionModal;
