import {
  Grid2,
  IconButton,
  LuDownload,
  Show,
  Typography,
  styled,
} from "components";
import dayjs from "dayjs";
import { useState } from "react";
import { AdviceRegisterColor } from "utils/queryKey";
import { ThemeContainer } from "utils/Theme";
import RationaleModal from "./RationaleModal";

interface Props {
  dataItem: any;
  index: number;
}

const CustomContainer = styled(Grid2)(() => () => ({
  padding: "16px",
  borderRadius: 10,
  border: "1px solid #E0E0E0",
  marginBottom: 4,
  "&:hover": {
    cursor: "pointer",
  },
}));

const OrderAdviceRegister = (props: Props) => {
  const { dataItem, index } = props;

  const [openRationaleModal, setOpenRationaleModal] = useState(false);
  const { theme } = ThemeContainer();

  return (
    <>
      <CustomContainer
        key={index}
        size={12}
        onClick={() => {
          setOpenRationaleModal(true);
        }}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={6} display={"flex"}>
            <Typography
              fontWeight={600}
              fontSize={12}
              p={"4px"}
              borderRadius={1}
              textTransform={"capitalize"}
              color={AdviceRegisterColor[dataItem?.type]}
              bgcolor={`${AdviceRegisterColor[dataItem?.type]}22`}
            >
              {dataItem?.type}
            </Typography>
          </Grid2>
          <Grid2
            size={6}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={1}
          >
            <IconButton
              style={{
                borderRadius: 4,
              }}
              onClick={() => window.open(dataItem?.data?.file, "_blank")}
            >
              <LuDownload size={14} />
            </IconButton>
          </Grid2>
          <Grid2
            size={10}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={2}
          >
            <Typography
              fontWeight={500}
              fontSize={12}
              display={"flex"}
              alignItems="center"
            >
              Portfolio :&nbsp;
              <Typography fontWeight={700} fontSize={12}>
                {dataItem?.data?.modelPortfolio}
              </Typography>
            </Typography>
            <Typography
              fontWeight={500}
              fontSize={12}
              display={"flex"}
              alignItems="center"
            >
              Version :&nbsp;
              <Typography fontWeight={700} fontSize={12}>
                {dataItem?.data?.modelPortfolioVersion}
              </Typography>
            </Typography>
            <Typography
              fontWeight={500}
              fontSize={12}
              display={"flex"}
              alignItems="center"
            >
              Order Type :&nbsp;
              <Typography fontWeight={700} fontSize={12}>
                {dataItem?.data?.orderType}
              </Typography>
            </Typography>
          </Grid2>
          <Grid2
            size={2}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Typography
              fontWeight={400}
              fontSize={12}
              color={theme.palette.secondaryText.main}
            >
              {dayjs(dataItem?.data?.orderDate).format(
                "DD MMM YYYY  hh:mm a",
              ) || "-"}
            </Typography>
          </Grid2>
        </Grid2>
      </CustomContainer>

      <Show.When isTrue={openRationaleModal}>
        <RationaleModal
          open={openRationaleModal}
          setOpen={setOpenRationaleModal}
          content={dataItem?.data?.rationale}
        />
      </Show.When>
    </>
  );
};

export default OrderAdviceRegister;
