import { Grid2 } from "components";
import AdvanceDeposit from "../FeePlans/AdvanceDeposit";
import SubscriptionPlan from "../FeePlans/SubscriptionPlan";
import EditManagementFeePage from "./EditManagementFeePage";
import EditPerformanceFeePage from "./EditPerformanceFeePage";

interface Props {
  data: any;
}

const RevenueProductsTab = (props: Props) => {
  const { data } = props;

  const isManagementFee = data?.revenueProducts?.includes("management_fee");
  const isPerformanceFee = data?.revenueProducts?.includes("performance_fee");
  const isSubscriptionFee = data?.revenueProducts?.includes("subscription_fee");
  const isAdvanceDeposit = data?.revenueProducts?.includes("advance_deposit");

  return (
    <Grid2 container spacing={1}>
      {isManagementFee && (
        <Grid2 size={12}>
          <EditManagementFeePage />
        </Grid2>
      )}

      {isPerformanceFee && (
        <Grid2 size={12} mt={2}>
          <EditPerformanceFeePage />
        </Grid2>
      )}

      {isSubscriptionFee && (
        <Grid2 size={12}>
          <SubscriptionPlan />
        </Grid2>
      )}
      {isAdvanceDeposit && (
        <Grid2 size={12}>
          <AdvanceDeposit />
        </Grid2>
      )}
    </Grid2>
  );
};

export default RevenueProductsTab;
