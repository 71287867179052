import { FormControl, Grid2 } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import GKButton from "components/GKButton";
import GKDatePicker from "components/GKDatePicker";
import GKModal from "components/GKModal";
import GKTextField from "components/GKTextField";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";

interface Props {
  portfolioId: string;
  highWatermarkData: any;
}

const HighWaterMarkFormModal = (props: Props & GKModalProps) => {
  const { open, setOpen, portfolioId, highWatermarkData } = props;

  const form = useForm({
    defaultValues: {
      highWatermark: highWatermarkData?.highWatermark || "",
      date: highWatermarkData?.date || "",
    },
  });

  const { mutate: addHighWatermark } = useMutation({
    mutationKey: ["ADD_HIGH_WATERMARK"],
    mutationFn: (values: { highWatermark: string; date: string }) =>
      axiosInstance.post(
        `/portfolio-high-watermark/?portfolio=${portfolioId}`,
        values,
      ),
    onSuccess: (response) => {
      setOpen(false);
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery("GET_HIGH_WATERMARK_HISTORY_BY_PORTFOLIO_ID");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const { mutate: updateHighWatermark } = useMutation({
    mutationKey: ["UPDATE_HIGH_WATERMARK"],
    mutationFn: (values: { highWatermark: string; date: string }) =>
      axiosInstance.patch(
        `/portfolio-high-watermark/${highWatermarkData.id}/?portfolio=${portfolioId}`,
        values,
      ),
    onSuccess: (response) => {
      setOpen(false);
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery("GET_HIGH_WATERMARK_HISTORY_BY_PORTFOLIO_ID");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  return (
    <GKModal
      modalTitle={"Add High Watermark"}
      open={open}
      setOpen={setOpen}
      footer={
        <GKButton variant="contained" type="submit" form="high-watermark-form">
          Save
        </GKButton>
      }
    >
      <form
        id="high-watermark-form"
        onSubmit={form.handleSubmit((values) =>
          highWatermarkData.id
            ? updateHighWatermark(values)
            : addHighWatermark(values),
        )}
      >
        <Grid2 container spacing={2}>
          <Grid2 size={6}>
            <Controller
              name="date"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please select a date",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl variant="standard" fullWidth>
                  <GKDatePicker
                    {...field}
                    inputLabel="Date"
                    value={dayjs(field.value)}
                    onChange={(value) =>
                      field.onChange(dayjs(value).format("YYYY-MM-DD"))
                    }
                    disableFuture
                    slotProps={{
                      textField: {
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                </FormControl>
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="highWatermark"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter high watermark amount",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl variant="standard" fullWidth>
                  <GKTextField
                    {...field}
                    inputLabel={"High Watermark Amount"}
                    error={!!error}
                    helperText={error?.message}
                  />
                </FormControl>
              )}
            />
          </Grid2>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default HighWaterMarkFormModal;
