import {
  Alert,
  GKButton,
  GKClientDataGrid,
  Grid2,
  HiOutlineExclamationTriangle,
  IoCloseCircleOutline,
  TbArrowBackUp,
  Typography,
} from "components";
import { useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { handleDowloadFile } from "utils/fileHelper";
import { brokerTypeLedger, returnReviewHeaders } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";

const ReviewMapping = () => {
  const form = useFormContext();

  const reviewData = form.getValues("step4");

  const { theme } = ThemeContainer();

  const downloadSingleRowErrors = async () => {
    await axiosInstance
      ?.get(
        `/transaction_upload/${
          form.getValues("fileObject")?.id
        }/download_errors/?full=false`,
      )
      .then((response: any) => {
        handleDowloadFile({
          fileData: response?.data,
          applicaionType: "text/csv",
          fileName: `${form.getValues("step1.type")?.toLowerCase()}`,
          extensionType: "csv",
        });
      })
      .catch((response: any) => {
        const error = response?.json();
        toast.error(error?.message || "Something went wrong");
      });
  };

  const transactions = reviewData?.reviewResponse?.transactions || [];

  return (
    <Grid2 size={12} container spacing={1}>
      <Grid2
        size={12}
        sx={{
          display:
            reviewData?.reviewResponse?.transactions?.filter(
              (data: any) => data.isDuplicate,
            )?.length === 0
              ? "none"
              : "flex",
        }}
      >
        <Alert
          severity="warning"
          icon={<HiOutlineExclamationTriangle size={14} />}
          style={{
            width: "100%",
          }}
        >
          There may be duplicate trades, please review your import.
        </Alert>
      </Grid2>
      <Grid2
        size={12}
        sx={{
          display:
            reviewData?.reviewResponse?.transactions?.filter(
              (data: any) => data.error !== "",
            )?.length === 0
              ? "none"
              : "flex",
          justifyContent: "space-between",
        }}
      >
        <Alert
          sx={{ width: "100%" }}
          severity="error"
          icon={<IoCloseCircleOutline size={14} />}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>There are errors found in the import.</Typography>

            <GKButton
              style={{
                marginLeft: 10,
                padding: 0,
              }}
              color="error"
              variant="link"
              onClick={() => {
                downloadSingleRowErrors();
              }}
            >
              Download Errors
            </GKButton>
          </div>
        </Alert>
      </Grid2>
      <Grid2 size={12}>
        <GKClientDataGrid
          name="brokerReviewMapping"
          headerTitle={
            <div style={{ marginRight: 8 }}>
              <Typography fontSize={14} fontWeight={600} whiteSpace={"nowrap"}>
                Review Imported data
              </Typography>
              <Typography
                whiteSpace={"nowrap"}
                fontSize={12}
                color={theme?.palette?.grey[700]}
              >
                Total Entries:{" "}
                {reviewData?.reviewResponse?.transactions?.length}
              </Typography>
            </div>
          }
          density="compact"
          pagination={false}
          checkboxSelection={form.getValues("step4.showDuplicates")}
          disableRowSelectionOnClick
          onRowSelectionModelChange={(params) => {
            form.setValue("step4.duplicateIds", params);
          }}
          rowSelection={transactions?.map((data: any) =>
            form.getValues("step4.duplicateIds")?.includes(data.id),
          )}
          maxHeight={"calc(100vh - 500px)"}
          rows={
            form.getValues("step4.showDuplicates")
              ? transactions.filter((dataItem: any) => dataItem.isDuplicate)
              : transactions
          }
          columns={
            brokerTypeLedger?.includes(form.getValues("step1.type"))
              ? returnReviewHeaders("cash")
              : // .filter(
                //     (dataItem) => dataItem.field !== "subTransactionType",
                //   )
                returnReviewHeaders("stock")
          }
          initialState={{
            columns: {
              columnVisibilityModel: {
                stt: false,
                gst: false,
                stampCharges: false,
                otherCharges: false,
                transactionCharges: false,
                sebiTurnoverFees: false,
              },
            },
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          headerComponent={
            <Grid2 container justifyContent={"flex-end"} alignItems={"center"}>
              <Grid2>
                {reviewData?.reviewResponse?.transactions?.filter(
                  (data: any) => data.isDuplicate,
                )?.length === 0 ? (
                  ""
                ) : (
                  <GKButton
                    onClick={() => {
                      form.setValue(
                        "step4.showDuplicates",
                        !form.getValues("step4.showDuplicates"),
                      );
                    }}
                    variant="outlined"
                    color="dark"
                    size="medium"
                    style={{ whiteSpace: "nowrap" }}
                    startIcon={
                      form.getValues("step4.showDuplicates") ? (
                        <TbArrowBackUp />
                      ) : (
                        ""
                      )
                    }
                  >
                    {form.getValues("step4.showDuplicates")
                      ? "Back"
                      : "View Duplicates"}
                  </GKButton>
                )}
              </Grid2>
            </Grid2>
          }
        />
      </Grid2>
    </Grid2>
  );
};

export default ReviewMapping;
