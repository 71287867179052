/* eslint-disable react/no-danger */
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  CgMathPlus,
  Divider,
  FormControl,
  GKButton,
  GKEditor,
  GKSideModal,
  GKTextField,
  Grid2,
  Paper,
  Typography,
} from "components";
import { useFetchQuery } from "hooks/useQueries";
import { isEmpty, replace } from "lodash";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CUSTOM_AGREEMENT_VARIABLES } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { refetchQuery } from "utils/helpers";
import { ThemeContainer } from "utils/Theme";
import { validateNotWhitespaceOnly } from "utils/validationSchema";
import CustomAgreementPreviewFile from "./CustomAgreementPreviewFile";

interface CustomAgreementModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedRow?: any;
}

const CustomAgreementModal = (props: CustomAgreementModalProps) => {
  const { open, setOpen, selectedRow } = props;

  const editorRef = useRef(null);

  const { theme } = ThemeContainer();

  const [openPreview, setOpenPreview] = useState(false);

  // ============================================================================
  const { data } = useFetchQuery({
    key: ["GET_ALL_VARIABLES"],
    route: CUSTOM_AGREEMENT_VARIABLES,
  });

  // ============================================================================
  const { mutate, isLoading } = useMutation({
    mutationKey: ["CREATE_CUSTOME_AGREEMENT"],
    mutationFn: async (val: any) =>
      axiosInstance.post("/custom-agreement/", {
        content: replace(val?.content, /<p><br\s?\/?><\/p>/g, " "),
        title: val?.title,
      }),
    onSuccess: () => {
      refetchQuery("GET_ALL_CUSTOM_AGREEMENTS");
      setOpen(false);
    },
  });

  // ============================================================================
  const { mutate: handleUpdate, isLoading: updateLoading } = useMutation({
    mutationKey: ["CREATE_CUSTOME_AGREEMENT"],
    mutationFn: async (val: any) =>
      axiosInstance.patch(`/custom-agreement/${selectedRow?.id}/`, {
        content: replace(val?.content, /<p><br\s?\/?><\/p>/g, " "),
        title: val?.title,
      }),
    onSuccess: () => {
      refetchQuery("GET_ALL_CUSTOM_AGREEMENTS");
      setOpen(false);
    },
  });

  // ============================================================================
  const form = useForm({
    defaultValues: {
      title: selectedRow?.title || "",
      content: selectedRow?.content || "",
    },
  });

  // ============================================================================
  const appendVariable = (variableValue: string) => {
    if (!editorRef.current) {
      console.error("Editor reference is not available");
      return;
    }

    const editor = editorRef && (editorRef?.current as any);

    editor.model.change((writer: any) => {
      const { selection } = editor.model.document;
      const position = selection.getFirstPosition();
      writer.insertText(`{{${variableValue}}}`, position);
    });
  };

  // ============================================================================
  const replaceVariables = (val: string) => {
    const variablePattern = /{{\s*[^}]+\s*}}/g;
    return val.replace(variablePattern, "_________________");
  };

  form.watch("content");

  return (
    <>
      <GKSideModal
        open={open}
        setOpen={setOpen}
        width={"100%"}
        modalTitle="Custom Template"
        footerButton={
          <Grid2 container justifyContent={"flex-end"} gap={1}>
            <LoadingButton
              loading={isLoading || updateLoading}
              variant="contained"
              type="submit"
              form="custom-agreement-form"
            >
              Save
            </LoadingButton>
          </Grid2>
        }
      >
        <form
          id="custom-agreement-form"
          onSubmit={form.handleSubmit((values) => {
            if (selectedRow) {
              handleUpdate(values);
            } else {
              mutate(values);
            }
          })}
        >
          <Grid2 container spacing={1}>
            <Grid2 size={12}>
              <FormControl fullWidth variant="standard">
                <Controller
                  name="title"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter template title",
                    },
                    validate: validateNotWhitespaceOnly,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <GKTextField
                      {...field}
                      inputLabel="Template Title"
                      error={!!error}
                      requiredField
                      helperText={error?.message}
                    />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={12}>
              <Typography fontWeight={600} fontSize={16}>
                Dynamic Values
              </Typography>
              <Divider sx={{ my: 1 }} />
              {data?.data?.data.map((variable: any) => (
                <GKButton
                  sx={{
                    margin: "4px",
                    "& .MuiButton-icon": {
                      marginRight: "2px",
                    },
                  }}
                  variant="outlined"
                  color="dark"
                  size="extraSmall"
                  key={variable.value}
                  onClick={() => appendVariable(variable.value)}
                  startIcon={<CgMathPlus size={14} />}
                >
                  {variable.name}
                </GKButton>
              ))}
            </Grid2>
            <Grid2 size={6}>
              <Controller
                name="content"
                control={form.control}
                render={({ field }) => (
                  <div
                    style={{
                      border: `1px solid ${theme.palette.border.main}`,
                      borderRadius: "8px",
                      marginTop: "4px",
                    }}
                  >
                    <GKEditor
                      inputLabel=""
                      value={field.value}
                      onChange={(val: any) => {
                        field.onChange(val);
                      }}
                      onReady={(editor: any) => {
                        editorRef.current = editor;
                      }}
                    />
                  </div>
                )}
              />
            </Grid2>
            {!isEmpty(form.getValues("content")) && (
              <Grid2 size={6}>
                <Paper
                  sx={{
                    padding: 2,
                    "& table": {
                      borderCollapse: "collapse",
                    },
                    "& th, td": {
                      border: " 1px solid #ddd",
                    },
                  }}
                >
                  <div
                    className="ck-content"
                    dangerouslySetInnerHTML={{
                      __html: replaceVariables(form.getValues("content")),
                    }}
                  />
                </Paper>
              </Grid2>
            )}
          </Grid2>
        </form>
      </GKSideModal>

      <CustomAgreementPreviewFile
        open={openPreview}
        setOpen={setOpenPreview}
        content={form.getValues("content")}
      />
    </>
  );
};

export default CustomAgreementModal;
