import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    minWidth: "max-content",
    maxWidth: "1000px",
    boxShadow:
      "0px 0px 10px 10px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    fontSize: 11,
    padding: "25px !important",
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      backgroundColor: theme.palette.light?.main,
    },
  },
}));

export default LightTooltip;
