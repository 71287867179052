import { GridColDef } from "@mui/x-data-grid-pro";
import { GKClientDataGrid, Typography } from "components";
import { useFetchQuery } from "hooks/useQueries";
import { FULL_WALLET_HISTORY } from "utils/apiRoutes";
import { extraColumnsField } from "utils/helpers";
import { moneyCommaSeparator } from "utils/MathFunction";
import useDocumentTitle from "utils/useDocumentTitle";

const WalletFullHistory = () => {
  useDocumentTitle("Wallet");

  const { data: walletHistoryList, isFetching: walletHistoryListFetching } =
    useFetchQuery({
      key: ["GET_WALLET_FULL_HISTORY"],
      route: FULL_WALLET_HISTORY,
    });

  const columns: GridColDef[] = [
    {
      ...extraColumnsField("Particulars", "chargesType", 0, 1),
      valueGetter: (params) => params?.row?.chargesType || "-",
      renderCell: (params) => {
        return (
          <Typography>
            {`${params?.row?.chargesType} ${
              params?.row.chargesSubType
                ? ` - ${params?.row.chargesSubType}`
                : ""
            } `}
          </Typography>
        );
      },
    },
    {
      ...extraColumnsField("Amount", "totalAmount", 0, 1),
      type: "number",
      valueGetter: (params) => params.row.totalAmount,
      valueFormatter: (params) => `₹${moneyCommaSeparator(+params.value)}`,
    },
  ];

  return (
    <GKClientDataGrid
      name="walletFullHistory"
      showSearch={false}
      headerTitle={
        <Typography fontSize={16} fontWeight={500}>
          Item wise Total Charges
        </Typography>
      }
      density="compact"
      rows={
        walletHistoryList?.data?.data.map((dataItem: any, index: number) => {
          return {
            ...dataItem,
            id: index,
          };
        }) || []
      }
      columns={columns}
      loading={walletHistoryListFetching}
      pagination={false}
      columnsButton={false}
    />
  );
};

export default WalletFullHistory;
