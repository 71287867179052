import {
  BsCheckCircle,
  BsCircle,
  CircularProgress,
  Divider,
  Grid2,
  Typography,
  styled,
  useTheme,
} from "components";

interface Props {
  activeStep: number;
  loading?: boolean;
  stepColors?: {
    completed?: string;
    pending?: string;
  };
  iconSize?: number;
}

const CustomSection = styled("section")({
  display: "flex",
  alignItems: "center",
  gap: 8,
  width: "100%",
});

const StepWiseLoader = ({
  activeStep,
  loading = false,
  stepColors = {},
  iconSize = 15,
}: Props) => {
  const theme = useTheme();
  const currentActiveStep = activeStep - 1;

  // Define default colors if none are provided
  const {
    completed = theme.palette.success.main,
    pending = theme.palette.grey[600],
  } = stepColors;

  const steps = [
    {
      label: "File Upload",
      subLabel: "File uploaded successfully.",
      show: true,
    },
    {
      label: "Company Mapping",
      subLabel: "Company Mapping successfully.",
      show: true,
    },
    { label: "Review File", subLabel: "Review successfully.", show: true },
    {
      label: "Transaction Upload",
      subLabel: "Transactions uploaded successfully.",
      show: true,
    },
  ];

  const renderIcon = (index: number) => {
    if (currentActiveStep < index)
      return <BsCircle size={iconSize} color={pending} />;
    if (loading && currentActiveStep === index)
      return <CircularProgress size={iconSize} />;
    if (currentActiveStep > index)
      return <BsCheckCircle size={iconSize} color={completed} />;
    return null;
  };

  return (
    <Grid2 container justifyContent="center" alignItems="center">
      {steps.map(
        (step, index) =>
          step.show && (
            <Grid2
              key={step.label}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <CustomSection>
                {renderIcon(index)}
                <Typography
                  variant="body2"
                  fontWeight={700}
                  color={theme.palette.grey[700]}
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {step.label}
                </Typography>
                {index < steps.length - 1 && (
                  <Divider sx={{ width: 30, marginRight: 1 }} />
                )}
              </CustomSection>
            </Grid2>
          ),
      )}
    </Grid2>
  );
};

export default StepWiseLoader;
