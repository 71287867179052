import { useAppContext } from "AppContext";
import { AnimationComponent, Box, Grid2, Show, Tab, Tabs } from "components";
import DashboardLayout from "layouts/DashboardLayout";
import PortfolioList from "Pages/Portfolio/PortfolioList";
import PortfolioGroupList from "Pages/PortfolioGrouping/PortfolioGroupList";
import HoldingsSummary from "Pages/PortfolioSummary";
import EquityCashSection from "Pages/PortfolioSummary/EquityCashSection";
import React from "react";
import { ThemeContainer } from "utils/Theme";

const DashboardMenu = () => {
  const {
    state: { tab: tabValue },
    dispatch,
  } = useAppContext();

  const { theme } = ThemeContainer();

  const tabs = [
    { label: "Summary", component: HoldingsSummary, showEquityCash: true },
    { label: "Portfolio", component: PortfolioList, showEquityCash: true },
    {
      label: "Portfolio Group",
      component: PortfolioGroupList,
      showEquityCash: false,
    },
  ];

  return (
    <DashboardLayout title="Dashboard">
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Grid2 container>
              <Grid2 size={6}>
                <Tabs
                  sx={{
                    "& .MuiTabs-flexContainer": {
                      backgroundColor: theme.palette.light.main,
                    },
                  }}
                  onChange={(e, val) => {
                    dispatch({
                      type: "TAB",
                      data: { ...tabValue, tabValueDashboard: val },
                    });
                  }}
                  value={tabValue?.tabValueDashboard}
                >
                  {tabs.map((tab, index) => (
                    <Tab key={index} label={tab.label} disableRipple />
                  ))}
                </Tabs>
              </Grid2>
              <Show.When
                isTrue={tabs[tabValue?.tabValueDashboard]?.showEquityCash}
              >
                <Grid2 size={6} textAlign="right">
                  <EquityCashSection />
                </Grid2>
              </Show.When>
            </Grid2>
          </Box>
        </Grid2>
        <Grid2 size={12}>
          {tabs.map((tab, index) => (
            <Show.When
              key={index}
              isTrue={tabValue?.tabValueDashboard === index}
            >
              <AnimationComponent>
                {React.createElement(tab.component)}
              </AnimationComponent>
            </Show.When>
          ))}
        </Grid2>
      </Grid2>
    </DashboardLayout>
  );
};

export default DashboardMenu;
