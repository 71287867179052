import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import {
  FormControl,
  FormControlLabel,
  GKEditor,
  GKModal,
  Grid2,
  Radio,
  RadioGroup,
} from "components";
import { Controller, useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";

interface Props {
  setHoldingOpen: (value: boolean) => void;
}

const SendOrderTypeModal = (props: Props & GKModalProps) => {
  const { open, setOpen, setHoldingOpen } = props;

  const form = useFormContext();

  const { mutate: handleCreateTrade, isLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.post(`/direct-order-generate/`, data),
    onSuccess: (response) => {
      setOpen(false);
      setHoldingOpen(false);
      toast.success(response.data?.message || "Action completed successfully.");
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      modalTitle="Send Orders"
      footer={
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={form.handleSubmit((values) => {
            const data = {
              ...values,
              stockList: values.stockList
                .filter((stock: { cmp: number }) => stock.cmp !== 0)
                .filter(
                  (stockList: { quantity: number; transactionType: string }) =>
                    stockList.quantity > 0 && stockList.transactionType,
                )
                .map(
                  (dataItem: {
                    gcode: string;
                    transactionType: string;
                    quantity: number;
                    price: number;
                  }) => {
                    return {
                      gridCode: dataItem.gcode,
                      transactionType: dataItem.transactionType,
                      quantity: dataItem.quantity,
                      price: dataItem.price,
                    };
                  },
                ),
            };

            handleCreateTrade(data);
          })}
        >
          Send
        </LoadingButton>
      }
    >
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Controller
            name="isOrderExecution"
            control={form.control}
            render={({ field }) => (
              <FormControl>
                <RadioGroup
                  {...field}
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue
                  name="radio-buttons-group"
                  onChange={(e) => field.onChange(e.target.value === "true")}
                >
                  <FormControlLabel
                    value
                    control={<Radio />}
                    label="Send for Execution"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Send as Recommendations"
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Grid2>
        <Grid2 size={12}>
          <Controller
            name="rationale"
            control={form.control}
            render={({ field }) => (
              <GKEditor {...field} inputLabel="Rationale" />
            )}
          />
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default SendOrderTypeModal;
