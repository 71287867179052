/* eslint-disable no-unneeded-ternary */
import { useAppContext } from "AppContext";
import { useFormik } from "formik";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import toast from "react-hot-toast";
import { GET_COMPANY_LIST } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { QueryKeys } from "utils/queryKey";

export const useReconManager = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    state: { userData: userInfo, portfolios },
  } = useAppContext();

  const [reviewResponse, setReviewResponse] = useState<any>();

  const [isPortfolioCreateModalOpen, setIsPortfolioCreateModalOpen] =
    useState<boolean>(false);

  const [uploadData, setUploadData] = useState(null);

  const [showDuplicates, setShowDuplicates] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const [allFiles, setAllFiles] = useState([]);

  const handleModalTitle = (step: number) => {
    switch (step) {
      case 0:
        return { form: "", title: "Import" };
      case 1:
        return { form: "", title: "Multiple Form" };
      case 2:
        return { form: "portfolio-mapping-form", title: "Portfolio Mapping" };
      case 3:
        return { form: "company-mapping-form", title: "Company Mapping" };
      case 4:
        return { form: "", title: "Review imported data " };
      case 5:
        return { form: "", title: "Transaction Completed" };

      default:
        return { form: "", title: "Import Manager" };
    }
  };

  /** ====================================================================================================================================================================================== */
  const handleNext = () => {
    setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
  };

  /** ====================================================================================================================================================================================== */
  const handleReset = () => {
    setActiveStep(0);
  };

  /** ====================================================================================================================================================================================== */
  const { data: companyNames } = useFetchQuery({
    key: [QueryKeys.COMPANY_NAMES],
    route: GET_COMPANY_LIST(),
    staleTime: 24 * 60 * 60 * 1000,
    refetchOnWindowFocus: true,
  });

  /** ====================================================================================================================================================================================== */
  const handleSubmitFile = async (values: any, appendedFiles: any) => {
    const response = await axiosInstance.post(
      `/recon_upload/?broker_format=${values?.version}`,
      appendedFiles,
    );
    return response;
  };

  /** ====================================================================================================================================================================================== */
  const companyFormik = useFormik({
    initialValues: {
      nullCompanies: [],
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      await axiosInstance
        ?.post(`/advisor-company-mappings/`, values.nullCompanies)
        .then(() => {
          handleNext();
          axiosInstance
            .post(`/recon_upload/${uploadData?.id}/review/`)
            .then((reviewApiResponse) => {
              setActiveStep(4);
              setReviewResponse({
                ...reviewApiResponse?.data,
                transactions: reviewApiResponse?.data?.transactions?.map(
                  (data: any) => {
                    return { ...data };
                  },
                ),
              });
              setIsLoading(false);
            })
            .catch((error) => {
              setIsLoading(false);
              setActiveStep(1);
              setAllFiles(
                allFiles?.map((item) => {
                  return {
                    ...item,
                    finished:
                      item?.data?.id === uploadData?.id ? true : item?.finished,
                    hasError:
                      item?.data?.id === uploadData?.id ? true : item?.data,
                  };
                }),
              );
              toast.error(
                error?.response?.data?.message ||
                  "Something went wrong, please try again.",
              );
            });
        })
        .catch((error) => {
          setIsLoading(false);
          setActiveStep(1);
          setAllFiles(
            allFiles?.map((item) => {
              return {
                ...item,
                finished:
                  item?.data?.id === uploadData?.id ? true : item?.finished,
                hasError:
                  item?.data?.id === uploadData?.id ? true : item?.hasError,
                status:
                  item?.data?.id === uploadData?.id ? "failed" : item?.status,
                message:
                  item?.data?.id === uploadData?.id
                    ? error?.response?.data?.message
                    : item?.message,
              };
            }),
          );
          toast.error(
            error?.response?.data?.message ||
              "Something went wrong, please try again.",
          );
        });
    },
  });

  /** ====================================================================================================================================================================================== */
  const portfolioFormik = useFormik({
    initialValues: {
      nullPortfolios: [],
    },
    onSubmit: (values) => {
      axiosInstance
        .post(
          `/recon_upload/${uploadData?.id}/mappings/`,
          values.nullPortfolios?.map((item) => {
            return {
              value: item.value,
              portfolio: item.portfolio,
            };
          }),
        )
        .then((response) => {
          toast.success(
            response?.data?.message ||
              response?.data?.status ||
              "Portfolio successfully mapped.",
          );

          setIsLoading(true);
          handleNext();
          axiosInstance
            ?.get(`/recon_upload/${uploadData?.id}/company_mappings/`)
            .then((companyMappingResponse) => {
              const companies = companyMappingResponse?.data?.data?.filter(
                (data: any) => data?.value === null,
              );

              companyFormik?.setFieldValue("nullCompanies", companies);

              if (companies?.length !== 0) {
                setIsLoading(false);
                setActiveStep(3);
              } else {
                setActiveStep(4);
                axiosInstance
                  .post(`/recon_upload/${uploadData?.id}/review/`)
                  .then((reviewApiResponse) => {
                    setActiveStep(4);
                    setReviewResponse({
                      ...reviewApiResponse?.data,
                      transactions: reviewApiResponse?.data?.transactions?.map(
                        (data: any) => {
                          return { ...data };
                        },
                      ),
                    });
                    setIsLoading(false);
                  })
                  .catch((error) => {
                    setIsLoading(false);
                    setActiveStep(1);
                    setAllFiles(
                      allFiles?.map((item) => {
                        return {
                          ...item,
                          finished:
                            item?.data?.id === uploadData?.id
                              ? true
                              : item?.finished,
                          hasError:
                            item?.data?.id === uploadData?.id
                              ? true
                              : item?.data,
                        };
                      }),
                    );
                    toast.error(
                      error?.response?.data?.message ||
                        "Something went wrong, please try again.",
                    );
                  });
              }
            })
            .catch((error) => {
              setIsLoading(false);
              setActiveStep(1);
              setAllFiles(
                allFiles?.map((item) => {
                  return {
                    ...item,
                    finished:
                      item?.data?.id === uploadData?.id ? true : item?.finished,
                    hasError:
                      item?.data?.id === uploadData?.id ? true : item?.data,
                    status:
                      item?.data?.id === uploadData?.id
                        ? "failed"
                        : item?.status,
                    message:
                      item?.data?.id === uploadData?.id
                        ? error?.response?.data?.message
                        : item?.message,
                  };
                }),
              );
              toast.error(
                error?.response?.data?.message ||
                  "Something went wrong, please try again.",
              );
            });
        })
        .catch((error) => {
          setIsLoading(false);
          setActiveStep(1);
          setAllFiles(
            allFiles?.map((item) => {
              return {
                ...item,
                finished:
                  item?.data?.id === uploadData?.id ? true : item?.finished,
                hasError:
                  item?.data?.id === uploadData?.id ? true : item?.hasError,
                status:
                  item?.data?.id === uploadData?.id ? "failed" : item?.status,
                message:
                  item?.data?.id === uploadData?.id
                    ? error?.response?.data?.message
                    : item?.message,
              };
            }),
          );
          toast.error(
            error?.response?.data?.message ||
              "Something went wrong, please try again.",
          );
        });
    },
  });

  /** ====================================================================================================================================================================================== */
  const formik = useFormik({
    initialValues: {
      broker: "",
      fileType: "",
      version: "",
      isPasswordRequired: false,
      title: "",
      favoriteType: "",
      filePassword: "",
      sheetTypeId: "",
      fileIndex: null,
      sheetTypeName: "",
      fileBrokerType: "",
      fileVersion: "",
      isNewTemplate: false,
      file: [],
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      const appendedFiles = new FormData();
      values.file.forEach((file: any) => {
        appendedFiles.append("files", file);
      });
      appendedFiles.append("password", formik.values?.filePassword);
      /** FILE UPLOAD API============================================================================ */

      handleSubmitFile(values, appendedFiles)
        .then((response) => {
          setUploadData(response?.data);
          axiosInstance
            ?.get(`/recon_upload/${response?.data?.id}/mappings/`)
            .then((mappingResponse) => {
              const portfolios = mappingResponse?.data?.data?.filter(
                (data: any) => data?.portfolio === null,
              );

              setAllFiles(
                allFiles?.map((item, index) => {
                  return {
                    ...item,
                    data:
                      index === formik.values?.fileIndex
                        ? response?.data
                        : item?.data,
                  };
                }),
              );
              portfolioFormik?.setFieldValue("nullPortfolios", portfolios);
              if (portfolios?.length !== 0) {
                setIsLoading(false);
                setActiveStep(2);
              } else if (portfolios?.length === 0) {
                handleNext();
                /** COMPANY MAPPING API============================================================================ */
                axiosInstance
                  ?.get(`/recon_upload/${response?.data?.id}/company_mappings/`)
                  .then((companyMappingResponse) => {
                    const companies =
                      companyMappingResponse?.data?.data?.filter(
                        (data: any) => data?.value === null,
                      );
                    companyFormik?.setFieldValue("nullCompanies", companies);

                    if (companies?.length !== 0) {
                      setIsLoading(false);
                      setActiveStep(3);
                    } else {
                      setActiveStep(4);
                      axiosInstance
                        .post(`/recon_upload/${response?.data?.id}/review/`)
                        .then((reviewApiResponse) => {
                          setActiveStep(4);
                          setReviewResponse({
                            ...reviewApiResponse?.data,
                            transactions:
                              reviewApiResponse?.data?.transactions?.map(
                                (data: any) => {
                                  return { ...data };
                                },
                              ),
                          });
                          setIsLoading(false);
                        })
                        .catch((error) => {
                          setIsLoading(false);
                          setAllFiles(
                            allFiles?.map((item, index) => {
                              return {
                                ...item,
                                hasError:
                                  index === formik.values?.fileIndex
                                    ? true
                                    : item?.hasError,
                                finished:
                                  index === formik.values?.fileIndex
                                    ? true
                                    : item?.finished,
                                status:
                                  index === formik.values?.fileIndex
                                    ? "failed"
                                    : item?.status,
                                message:
                                  index === formik.values?.fileIndex
                                    ? error?.response?.data?.message
                                    : item?.message,
                              };
                            }),
                          );
                          setActiveStep(1);
                          toast.error(
                            error?.response?.data?.message ||
                              "Something went wrong, please try again.",
                          );
                        });
                    }
                  })
                  .catch((error) => {
                    setIsLoading(false);
                    setAllFiles(
                      allFiles?.map((item, index) => {
                        return {
                          ...item,
                          hasError:
                            index === formik.values?.fileIndex
                              ? true
                              : item?.hasError,
                          finished:
                            index === formik.values?.fileIndex
                              ? true
                              : item?.finished,
                        };
                      }),
                    );
                    setActiveStep(1);
                    toast.error(
                      error?.response?.data?.message ||
                        "Something went wrong, please try again.",
                    );
                  });
              } else {
                setActiveStep(4);
                axiosInstance
                  .post(`/recon_upload/${response?.data?.id}/review/`)
                  .then((reviewApiResponse) => {
                    setActiveStep(4);
                    setReviewResponse({
                      ...reviewApiResponse?.data,
                      transactions: reviewApiResponse?.data?.transactions?.map(
                        (data: any) => {
                          return { ...data };
                        },
                      ),
                    });
                    setIsLoading(false);
                  })
                  .catch((error) => {
                    setIsLoading(false);
                    setAllFiles(
                      allFiles?.map((item, index) => {
                        return {
                          ...item,
                          hasError:
                            index === formik.values?.fileIndex
                              ? true
                              : item?.hasError,
                          finished:
                            index === formik.values?.fileIndex
                              ? true
                              : item?.finished,
                          status:
                            index === formik.values?.fileIndex
                              ? "failed"
                              : item?.status,
                          message:
                            index === formik.values?.fileIndex
                              ? error?.response?.data?.message
                              : item?.message,
                        };
                      }),
                    );
                    setActiveStep(1);
                    toast.error(
                      error?.response?.data?.message ||
                        "Something went wrong, please try again.",
                    );
                  });
              }
            })
            .catch((error) => {
              setIsLoading(false);
              setAllFiles(
                allFiles?.map((item, index) => {
                  return {
                    ...item,
                    hasError:
                      index === formik.values?.fileIndex
                        ? true
                        : item?.hasError,
                    finished:
                      index === formik.values?.fileIndex
                        ? true
                        : item?.finished,
                    status:
                      index === formik.values?.fileIndex
                        ? "failed"
                        : item?.status,
                    message:
                      index === formik.values?.fileIndex
                        ? error?.response?.data?.message
                        : item?.message,
                  };
                }),
              );
              setActiveStep(1);
              toast.error(
                error?.response?.data?.message ||
                  "Something went wrong, please try again.",
              );
            });
        })
        .catch((error) => {
          setIsLoading(false);
          setActiveStep(1);
          setAllFiles(
            allFiles?.map((item, index) => {
              return {
                ...item,
                hasError:
                  index === formik.values?.fileIndex ? true : item?.hasError,
                finished:
                  index === formik.values?.fileIndex ? true : item?.finished,
                status:
                  index === formik.values?.fileIndex ? "failed" : item?.status,
                message:
                  index === formik.values?.fileIndex
                    ? error?.response?.data?.message
                    : item?.message,
              };
            }),
          );
          toast.error(
            error?.response?.data?.message ||
              "Something went wrong, please try again.",
          );
        });
    },
  });

  /** ====================================================================================================================================================================================== */
  const downloadPortfolioSampleFile = async (url: any) => {
    const GetFileType = (value: any) => {
      switch (value) {
        default:
          return {
            name: `Sample.csv`,
            type: `application/csv`,
          };
      }
    };

    try {
      const response = await axiosInstance.get(url, {
        responseType: "arraybuffer",
      });

      const blob = new Blob([response.data], {
        type: GetFileType(formik.values.fileType).type,
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = GetFileType(formik.values.fileType).name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    }
  };

  /** ====================================================================================================================================================================================== */
  const handleCheckPassword = async (data: any) => {
    const res = await Promise.all(
      data?.map(async (dataItem: any, index: any) => {
        const appendedFiles = new FormData();
        appendedFiles.append("files", dataItem?.file);
        appendedFiles.append("index", index);
        const response = await axiosInstance.post(
          `/auto-forward-password/check_password/`,
          appendedFiles,
        );
        return {
          ...dataItem,
          hasPassword:
            index === Number(response?.data?.data?.index)
              ? response?.data?.data?.isPasswordProtected
              : data?.hasPassword,
        };
      }),
    );
    setAllFiles(res);
  };

  return {
    isLoading,
    setIsLoading,
    activeStep,
    formik,
    handleReset,
    handleModalTitle,
    showDuplicates,
    setShowDuplicates,
    portfolioFormik,
    portfolios,
    isPortfolioCreateModalOpen,
    setIsPortfolioCreateModalOpen,
    companyFormik,
    companyNames,
    userInfo,
    downloadPortfolioSampleFile,
    setActiveStep,
    handleNext,
    allFiles,
    setAllFiles,
    handleCheckPassword,
    reviewResponse,
  };
};
