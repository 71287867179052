import { Button, Card, Grid2, Typography } from "@mui/material";
import { paletteLightTheme } from "utils/ColorTheme";
import { ThemeContainer } from "utils/Theme";

interface Props {
  index: number;
  data: {
    id: number;
    file: string;
    visible: boolean;
    uploadedBy: string;
    name: string;
    createdOn: string;
  };
  size: string;
}

const GKFilePreviewCard = ({ index, data, size }: Props) => {
  const { theme } = ThemeContainer();

  return (
    <Button
      sx={{ padding: 0, mb: 1 }}
      fullWidth
      href={data?.file}
      target="__blank"
    >
      <Grid2 container key={index} size={12}>
        <Grid2 size={12}>
          <Card
            sx={{
              borderColor: paletteLightTheme.palette.border.main,
              p: 1,
              borderRadius: 2,
            }}
          >
            <Grid2 container alignItems={"center"}>
              <Grid2 container alignContent={"center"} gap={1} size={12}>
                <Grid2 display={"flex"}>
                  <img
                    onError={({ currentTarget }) => {
                      currentTarget.src =
                        "https://gridkey.in/Logos/iconLogo.png";
                    }}
                    src={`/FileIcons/${data?.name.split(".").pop()}.svg`}
                    height={30}
                    width={30}
                    alt=""
                    style={{
                      borderRadius: 4,
                      border: "1px solid",
                      borderColor: paletteLightTheme.palette.border.main,
                      padding: 4,
                    }}
                  />
                </Grid2>
                <Grid2>
                  <Typography fontSize={12} fontWeight={600} textAlign={"left"}>
                    {data?.name}
                  </Typography>
                  <Typography
                    color={theme.palette.secondaryText.main}
                    fontSize={12}
                    textAlign={"left"}
                  >
                    {size}
                  </Typography>
                </Grid2>
              </Grid2>
            </Grid2>
          </Card>
        </Grid2>
      </Grid2>
    </Button>
  );
};

export default GKFilePreviewCard;
