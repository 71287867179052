import { inRange } from "lodash";

export enum DashboardType {
  INVESTOR,
  ADVISOR,
  ASSOCIATE,
}

export enum SampleFilesUrls {
  SAMPLE_STOCK = "/transaction_upload/export_stock_csv/",
  SAMPLE_CASH = "/transaction_upload/export_case_csv/",
  SAMPLE_CLIENTS = "/client/export_csv/",
  SAMPLE_PORTFOLIOS = "/portfolios/export_sample_csv/",
}

export enum FetchDataURL {
  TRANSACTION = "/transaction/",
  COMPANY_MASTER_LIST = "/v1/companies/search/paged",
  PORTFOLIOS_LIST = "/portfolios/",
  CLIENTS_LIST = "/client/",
  CLIENTS_COMPLIANCE_LIST = "/client/compliance/",
  LEAD_LIST = "/lead/",
}

export enum UserRoleTypes {
  advisor = "advisor",
  client = "client",
  employee = "employee",
  partner = "partner",
}

export const token = localStorage.getItem(`token`);

export interface SelectOption {
  [x: string]: any;
  name: string;
  value: any;
}

export const blockInvalidChar = (e: any) =>
  ["+", "-"].includes(e.key) && e.preventDefault();

export const handleCapitalize = (str: string) => {
  const newStr = str?.toLowerCase() || " ";

  const firstLetter = newStr?.charAt(0);

  const firstLetterCap = firstLetter?.toUpperCase();

  const remainingLetters = newStr?.slice(1);

  const capitalizedWord = firstLetterCap + remainingLetters;

  return capitalizedWord;
};

export const breakpoints = {
  xl: [993, 1284],
  lg: [769, 992],
  md: [501, 768],
  sm: [321, 500],
  xs: [0, 320],
};

export const handleGetScreenSize = (width: number) => {
  if (inRange(width, breakpoints.xl[0], breakpoints.xl[1])) {
    return "xl";
  }
  if (inRange(width, breakpoints.lg[0], breakpoints.lg[1])) {
    return "lg";
  }
  if (inRange(width, breakpoints.md[0], breakpoints.md[1])) {
    return "md";
  }
  if (inRange(width, breakpoints.sm[0], breakpoints.sm[1])) {
    return "sm";
  }

  return "xs";
};
