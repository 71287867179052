import { CircularProgress, Divider, Typography } from "@mui/material";

const GKOverlayLoader = () => {
  return (
    <div
      style={{
        position: "absolute",
        zIndex: 9999,
        top: 0,
        left: 0,
        backdropFilter: "blur(5px)",
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 16,
      }}
    >
      <CircularProgress size={40} />
      <Divider sx={{ width: "100vw" }} />
      <Typography fontWeight={600} fontSize={20}>
        Hold on tight, we are fetching data.
      </Typography>
    </div>
  );
};

export default GKOverlayLoader;
