import { Button, FormControl, GKDatePicker, GKModal } from "components";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { GKModalProps } from "utils/commonInterface";

interface Props {
  form: any;
}

const UpdateNextRebalanceDate = ({
  open,
  setOpen,
  form,
}: Props & GKModalProps) => {
  return (
    <GKModal
      open={open}
      modalTitle="Next Rebalance Date"
      setOpen={setOpen}
      footer={
        <Button
          onClick={() => {
            setOpen(false);
          }}
          variant="contained"
        >
          Submit
        </Button>
      }
    >
      <Controller
        control={form.control}
        name="nextRebalanceDate"
        render={({ field }) => (
          <FormControl variant="standard" fullWidth>
            <GKDatePicker
              {...field}
              onChange={(e) => {
                field.onChange(dayjs(e).format("YYYY-MM-DD"));
              }}
              value={dayjs(field.value)}
              inputLabel="Model Portfolio start date"
            />
          </FormControl>
        )}
      />
    </GKModal>
  );
};

export default UpdateNextRebalanceDate;
