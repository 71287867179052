/* eslint-disable react/no-this-in-sfc */
/* eslint-disable func-names */
import { Show, Typography } from "components";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import HighchartsSunburst from "highcharts/modules/sunburst";
import { useEffect, useState } from "react";
import { numberToCurrency } from "utils/MathFunction";

HighchartsSunburst(Highcharts);

const PortfolioChart = ({ data }: any) => {
  const [hoverData, setHoverData] = useState<any>();

  const chartOptions = {
    title: {
      text: ` `,
      align: "center",
      verticalAlign: "middle",
      style: {
        fontSize: "1.1em",
      },
    },
    chart: {
      style: {
        fontFamily: "Inter",
      },
      height: 220,
      width: 335,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    exporting: {
      enabled: false,
    },
    credits: {
      text: "",
      enabled: false,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        point: {
          events: {
            mouseOut: function () {
              setHoverData({
                name: "Total Gain",
                y: data?.totalReturns,
              });
            },
            mouseOver: function (this) {
              setHoverData(this);
            },
          },
        },
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "100%"],
        size: "190%",
      },
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        animation: false,
      },
    },
    colors: ["#39627A", "#709D88", "#FCAA67"],
    tooltip: {
      enabled: false,
    },
    series: [
      {
        type: "pie",
        name: " ",
        innerSize: "75%",
        data: [
          ["Unrealised Gain", data?.unrealisedGain],
          ["Realised Gain", data?.realisedGain],
          ["Dividend", data?.dividend],
        ],
      },
    ],
  };

  useEffect(() => {
    setHoverData({
      name: "Total Gain",
      y: data?.totalReturns,
    });
  }, [data]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Show.When isTrue={hoverData}>
        <div
          style={{
            position: "absolute",
            bottom: 40,
            zIndex: 1,
          }}
        >
          <Typography color={"#697586 "} fontSize={14} textAlign={"center"}>
            {hoverData?.name}
          </Typography>
          <Typography fontWeight={600} fontSize={18} textAlign={"center"}>
            {numberToCurrency(hoverData?.y || 0)}
          </Typography>
        </div>
      </Show.When>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default PortfolioChart;
