import { LoadingButton } from "@mui/lab";
import { Grid2, Popover } from "@mui/material";
import { useAppContext } from "AppContext";
import { useState } from "react";
import toast from "react-hot-toast";
import { RiDownloadLine } from "react-icons/ri";
import axiosInstance from "utils/axiosInstance";
import { handleDowloadFile } from "utils/fileHelper";
import { ThemeContainer } from "utils/Theme";
import GKButton from "./GKButton";

interface Props {
  path: string;
  name: string;
  paramsValue?: {
    key?: string;
    value?: string;
  };
  filter?: any;
}

const ExportCsv = (props: Props) => {
  const {
    state: { userData },
  } = useAppContext();

  const { theme } = ThemeContainer();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { path, name, paramsValue, filter } = props;

  const [exportFile, setExportFile] = useState({
    isLoading: false,
    exportType: "",
  });

  const handleLoadingFalse = (value: boolean, type: string) => {
    setExportFile((prev) => {
      return {
        ...prev,
        isLoading: value,
        exportType: type,
      };
    });
  };

  const handleExportCsvDownload = async (contain: any) => {
    const requestBody = {
      [paramsValue?.key]: paramsValue?.value,
      filter,
    };

    await axiosInstance
      .get(`${path}${contain}/`, { params: requestBody })
      .then((response) => {
        handleDowloadFile({
          fileData: response.data,
          applicaionType:
            contain === "export_csv" ? "text/csv" : "application/pdf",
          fileName: `${userData?.name}_${name}`,
          extensionType: contain === "export_csv" ? "csv" : "pdf",
        });
        toast.success("Your file is successfully downloaded.");
        setAnchorEl(null);
        handleLoadingFalse(false, "");
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
        setAnchorEl(null);
        handleLoadingFalse(false, "");
      })
      .finally(() => {
        setAnchorEl(null);
        handleLoadingFalse(false, "");
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 10,
      }}
    >
      <GKButton
        style={{
          padding: "6px 10px",
          color: theme.palette.secondaryText.main,
          border: "1px solid #E3E8EF",
          whiteSpace: "nowrap",
        }}
        onClick={handleClick}
        startIcon={<RiDownloadLine size={16} />}
      >
        Export Data
      </GKButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid2 container flexDirection={"column"} p={1}>
          <LoadingButton
            loading={
              exportFile?.exportType === "export_csv" && exportFile?.isLoading
            }
            color="dark"
            onClick={() => {
              handleLoadingFalse(true, "export_csv");
              handleExportCsvDownload("export_csv");
            }}
          >
            <img
              src={`/FileIcons/csv.svg`}
              height={22}
              width={22}
              alt=""
              style={{
                marginRight: 8,
              }}
            />
            Download CSV
          </LoadingButton>
          <LoadingButton
            color="dark"
            loading={
              exportFile?.exportType === "export_pdf" && exportFile?.isLoading
            }
            onClick={() => {
              handleLoadingFalse(true, "export_pdf");
              handleExportCsvDownload("export_pdf");
            }}
          >
            <img
              src={`/FileIcons/pdf.svg`}
              height={22}
              width={22}
              alt=""
              style={{
                marginRight: 8,
              }}
            />
            Download PDF
          </LoadingButton>
        </Grid2>
      </Popover>
    </div>
  );
};

export default ExportCsv;
