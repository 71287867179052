import {
  LARGE_CAP_COLOR,
  MID_CAP_COLOR,
  SMALL_CAP_COLOR,
} from "utils/constants";

export const handleCapColors = (str: string) => {
  switch (str) {
    case "small":
      return SMALL_CAP_COLOR;
    case "medium":
      return MID_CAP_COLOR;
    case "large":
      return LARGE_CAP_COLOR;
    case "small cap":
      return SMALL_CAP_COLOR;
    case "medium cap":
      return MID_CAP_COLOR;
    case "large cap":
      return LARGE_CAP_COLOR;

    default:
      return LARGE_CAP_COLOR;
  }
};
