import { GridColDef } from "@mui/x-data-grid-pro";
import SendNotificationModal from "Pages/ReportManager/SendNotificationModal";
import {
  CommonPagination,
  GKButton,
  GKDataGrid,
  Grid2,
  IconButton,
  LuDownload,
  LuSend,
  TfiPieChart,
  Tooltip,
  Typography,
} from "components";
import useGenerateQueryParams from "hooks/useGenerateQueryParams";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { moneyCommaSeparatorPoint } from "utils/MathFunction";
import { REPORTS } from "utils/apiRoutes";
import { extraColumnsField, matchDate } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import useDocumentTitle from "utils/useDocumentTitle";

export interface ReportList {
  id: number;
  name: string;
  reportType: string;
  format: string;
  timePeriod: string;
  createdOn: string;
  portfolioCount: number;
  file: string;
}

const ReportsManager = () => {
  useDocumentTitle("Report Manager");

  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [reportData, setReportData] = useState({});

  const requestBody = useGenerateQueryParams("report");

  const { data: reportList, isFetching } = useFetchQuery({
    key: [QueryKeys.REPORTS_LIST, requestBody],
    route: REPORTS,
    requestBody,
  });

  const cusPagination = () => {
    return (
      <CommonPagination
        totalCount={reportList?.data?.totalCount || 0}
        name="report"
      />
    );
  };

  const reportsColumns: GridColDef[] = [
    {
      ...extraColumnsField("Report Name", "name", 250, 1),
    },
    {
      ...extraColumnsField("Report Type", "reportType", 250, 1),
    },
    {
      ...extraColumnsField("Format", "format", 70, 1),
    },
    {
      ...extraColumnsField("Time Period", "timePeriod", 250, 1),
    },
    {
      ...extraColumnsField("Schedule Type", "isSchedule", 120, 1),
      renderCell: (params) => (params?.row?.isSchedule ? "AUTO" : "MANUAL"),
    },
    {
      ...extraColumnsField("Created On", "createdOn", 130, 1),
      renderCell: (value) => (
        <Typography>{matchDate(value?.row?.createdOn)}</Typography>
      ),
    },
    {
      ...extraColumnsField("Portfolio Count", "portfolioCount", 130, 1),
      valueFormatter: (params) => moneyCommaSeparatorPoint(params.value),
      type: "number",
    },
    {
      ...extraColumnsField("Actions", "action", 100, 1),
      type: "action",
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <Grid2 container justifyContent={"flex-end"} gap={1} display={"flex"}>
            <IconButton
              disabled={params.row.reportType === "Stock Wise Holding Report"}
              onClick={() => {
                setReportData(params.row);
                setIsOpenModal(true);
              }}
            >
              <LuSend size={20} />
            </IconButton>
            <Tooltip title="Download PDF">
              <IconButton
                onClick={() => {
                  fetch(params?.row?.file)
                    .then((response) => response.blob())
                    .then((blob) => {
                      const link = document.createElement("a");
                      link.href = window.URL.createObjectURL(blob);
                      link.download = params?.row?.name;
                      link.click();
                    });
                }}
              >
                <LuDownload size={20} />
              </IconButton>
            </Tooltip>
          </Grid2>
        );
      },
    },
  ];

  return (
    <>
      <GKDataGrid
        rows={reportList?.data?.data || []}
        columns={reportsColumns}
        loading={isFetching}
        CustomPagination={!isFetching && cusPagination}
        maxHeight={"calc(100vh - 357px)"}
        exportFile={{
          path: REPORTS,
          exportName: "ReportManagerList",
        }}
        name="report"
        headerComponent={
          <GKButton
            variant="contained"
            onClick={() => navigate("/app/generate-report")}
            startIcon={<TfiPieChart size={16} />}
          >
            Generate Reports
          </GKButton>
        }
      />

      {isOpenModal && (
        <SendNotificationModal
          open={isOpenModal}
          setOpen={setIsOpenModal}
          reportData={reportData}
          setReportData={setReportData}
        />
      )}
    </>
  );
};

export default ReportsManager;
