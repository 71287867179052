import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { GKButton, GKModal, Grid2 } from "components";
import { useState } from "react";
import { GKModalProps } from "utils/commonInterface";
import stepFive from "../../assets/emailConfigure/step-five.svg";
import stepFour from "../../assets/emailConfigure/step-four.svg";
import stepOne from "../../assets/emailConfigure/step-one.svg";
import stepThree from "../../assets/emailConfigure/step-three.svg";
import stepTwo from "../../assets/emailConfigure/step-two.svg";

const steps = [
  {
    label: "Open my Account",
    description: `Open myaccount.google.com and Navigate to the Security tab`,
  },
  {
    label: "2-Step Verification",
    description: "If your 2-step verification is not on, turn it on first.",
  },
  {
    label: "Open App passwords",
    description: `After turning on the 2-step verification, check for "App passwords" option at the bottom.
                  If you don't see "App passwords" option, click on the below mentioned link:
                  https://myaccount.google.com/apppasswords`,
  },
  {
    label: "Create new App-specific Password",
    description: `Add app name to create an app specific password. This app name will be visible as the sender name to the recipient when any email is triggered through the the system.`,
  },
  {
    label: "Copy the generated password",
    description: `After clicking on the create button, you will see a 16 lettered app password on the screen.
                  Copy this password and paste it in the password field for the SMTP setup on gridkey, remove the spaces in the password before submitting.`,
  },
];

const EmailConfigureStepModal = (props: GKModalProps) => {
  const { open, setOpen } = props;

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const loadImg = () => {
    switch (activeStep) {
      case 0:
        return stepOne;
      case 1:
        return stepTwo;
      case 2:
        return stepThree;
      case 3:
        return stepFour;
      case 4:
        return stepFive;
      default:
        return stepOne;
    }
  };

  return (
    <GKModal
      open={open}
      setOpen={setOpen}
      width={"70%"}
      modalTitle={"Follow these steps for generate app password"}
      footer={
        <Grid2
          container
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
        >
          <GKButton
            variant="outlined"
            color="dark"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            Back
          </GKButton>
          <GKButton
            variant="contained"
            onClick={() => (activeStep !== 4 ? handleNext() : setOpen(false))}
          >
            {activeStep === 4 ? "Complete" : "Next"}
          </GKButton>
        </Grid2>
      }
    >
      <Grid2 container>
        <Grid2 size={5} display={"flex"} alignItems={"center"}>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            connector={<div />}
          >
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel>
                  <Typography fontSize={16} fontWeight={600} color="#0D121C">
                    {step.label}
                  </Typography>
                </StepLabel>
                <StepContent>
                  <Typography fontSize={14} color="#0D121C">
                    {step.description}
                  </Typography>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Grid2>
        <Grid2 size={7} display={"flex"} justifyContent={"flex-end"}>
          <img
            loading="lazy"
            src={loadImg()}
            alt=""
            width={"90%"}
            height={"auto"}
          />
        </Grid2>
      </Grid2>
    </GKModal>
  );
};

export default EmailConfigureStepModal;
