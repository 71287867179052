import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { useAppContext } from "AppContext";
import {
  ConfirmDeleteModal,
  Divider,
  FormControl,
  GKButton,
  GKDatePicker,
  GKModal,
  GKSearchSelect,
  GKTextField,
  Grid2,
  Show,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "components";
import dayjs from "dayjs";
import { useFetchQuery } from "hooks/useQueries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { ETF_TRANSACTION_BY_ID } from "utils/apiRoutes";
import axiosInstance from "utils/axiosInstance";
import { GKModalProps } from "utils/commonInterface";
import { refetchQuery } from "utils/helpers";
import { QueryKeys } from "utils/queryKey";
import { handleReturnAutocompletePlaceholder } from "utils/StringFunctions";
import { ThemeContainer } from "utils/Theme";

interface Props {
  portfolioDetailData: any;
  etfData: any;
}

const DividendEditModal = (props: Props & GKModalProps) => {
  const { open, setOpen, portfolioDetailData, etfData } = props;
  const { id } = useParams<{ id: string }>();
  const { theme } = ThemeContainer();

  const {
    state: { companies },
  } = useAppContext();

  const [confirmationModal, setConfirmationModal] = useState(false);

  const { data: getETFByIdData } = useFetchQuery({
    key: ["GET_ETF_BY_ID", etfData?.id],
    route: ETF_TRANSACTION_BY_ID(etfData?.id),
    enabled: !!etfData?.id,
  });

  const { mutate: deleteETFTransaction, isLoading: deleteETFLoading } =
    useMutation({
      mutationFn: () => axiosInstance.delete(`/etf_transaction/${etfData.id}/`),
      onSuccess: (response) => {
        toast.success(
          response.data?.message || "Action completed successfully.",
        );
        refetchQuery(QueryKeys.PORTFOLIO_EFT_TRANSACTION_LIST);
        setOpen(false);
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please try again.",
        );
      },
    });

  const { mutate: editETFTransaction, isLoading } = useMutation({
    mutationFn: (data: any) =>
      axiosInstance.patch(`/etf_transaction/${etfData.id}/`, data),
    onSuccess: (response) => {
      toast.success(response.data?.message || "Action completed successfully.");
      refetchQuery(QueryKeys.PORTFOLIO_EFT_TRANSACTION_LIST);
      setOpen(false);
    },
    onError: (error: any) => {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please try again.",
      );
    },
  });

  const form = useForm({
    defaultValues: {
      portfolio: +id || "",
      date: "",
      isinCode: "",
      gridCode: "",
      quantity: "",
      transactionType: "ETF_DIVIDEND",
    },
    values: getETFByIdData?.data,
  });

  return (
    <GKModal
      modalTitle="Edit ETF/MF Interest"
      open={open}
      setOpen={setOpen}
      width={"65%"}
      titleComponent={
        etfData?.id && (
          <LoadingButton
            loading={deleteETFLoading}
            color="error"
            variant="outlined"
            onClick={() => {
              setConfirmationModal(true);
            }}
          >
            Delete
          </LoadingButton>
        )
      }
      footer={
        <Grid2
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
        >
          <GKButton
            variant="outlined"
            size="medium"
            form="cash-form"
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </GKButton>
          <LoadingButton
            color="dark"
            variant="outlined"
            size="medium"
            type="submit"
            form="dividend-form"
            loading={isLoading}
          >
            Update
          </LoadingButton>
        </Grid2>
      }
    >
      <form
        id="dividend-form"
        onSubmit={form.handleSubmit((values: any) => {
          if (dayjs(values?.date).year() < 1980) {
            toast.error(
              "You are not allowed to add transaction for this date.",
            );
          } else {
            editETFTransaction(values);
          }
        })}
      >
        <Grid2 container spacing={1}>
          <Grid2 size={12}>
            <Typography fontWeight={600} fontSize={18} color={"#202939"}>
              {portfolioDetailData?.name}
            </Typography>
            <Typography
              color={theme.palette.secondaryText.main}
              fontWeight={500}
              fontSize={12}
            >
              {portfolioDetailData?.portfolioId}
            </Typography>
          </Grid2>
          <Grid2 size={12}>
            <Divider />
          </Grid2>
          <Grid2 size={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Company</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Units</TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ minWidth: "300px" }}>
                      <Controller
                        name="gridCode"
                        control={form.control}
                        rules={{
                          required: {
                            value: true,
                            message: "Please Select Company",
                          },
                        }}
                        render={({ field, formState: { errors } }) => (
                          <GKSearchSelect
                            {...field}
                            requiredField
                            disableClearable
                            renderOption={(props, option) => (
                              <li
                                {...props}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 4,
                                }}
                              >
                                <Typography
                                  color={theme.palette.light.main}
                                  fontSize={8}
                                  style={{
                                    background:
                                      option?.instType === "EQ"
                                        ? theme.palette.secondary.main
                                        : theme.palette.warning.main,
                                    padding: "2px 4px",
                                    borderRadius: 4,
                                  }}
                                >
                                  {option?.instType}
                                </Typography>
                                <Typography whiteSpace={"nowrap"}>
                                  {option?.companyName}
                                </Typography>
                              </li>
                            )}
                            options={
                              companies.filter(
                                (dataItem) => dataItem.instType === "ETF",
                              ) || []
                            }
                            getOptionLabel={(option) =>
                              option?.companyName || option
                            }
                            onChange={(e, val) => {
                              field.onChange(val?.value);
                              form.setValue("isinCode", val?.isinCode || "");
                            }}
                            value={
                              companies?.find(
                                (dataItem: { value: string }) =>
                                  dataItem.value === field.value,
                              ) || ""
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={handleReturnAutocompletePlaceholder(
                                  {
                                    placeholder: "Select Company",
                                    value: field.value,
                                  },
                                )}
                                error={Boolean(errors.gridCode)}
                                helperText={errors.gridCode?.message as string}
                              />
                            )}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <FormControl variant="standard" fullWidth>
                        <Controller
                          name={"date"}
                          control={form.control}
                          rules={{
                            required: {
                              value: true,
                              message: "Please Select Transaction Date",
                            },
                          }}
                          render={({ field, formState: { errors } }) => (
                            <GKDatePicker
                              {...field}
                              requiredField
                              value={dayjs(field.value)}
                              onChange={(value) => {
                                field.onChange(
                                  dayjs(value).format("YYYY-MM-DD"),
                                );
                              }}
                              disableFuture
                              slotProps={{
                                textField: {
                                  error: !!errors?.date,
                                  helperText: errors?.date?.message as string,
                                },
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl variant="standard" fullWidth>
                        <Controller
                          name={"quantity"}
                          control={form.control}
                          rules={{
                            required: {
                              value: true,
                              message: "Please Enter Units",
                            },
                          }}
                          render={({ field, formState: { errors } }) => (
                            <GKTextField
                              {...field}
                              type="number"
                              requiredField
                              error={!!errors.quantity}
                              helperText={errors.quantity?.message as string}
                              onChange={(e: any) =>
                                field.onChange(e.target.valueAsNumber)
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid2>

          <Show.When isTrue={confirmationModal}>
            <ConfirmDeleteModal
              open={confirmationModal}
              setOpen={setConfirmationModal}
              onDelete={() => deleteETFTransaction()}
              title={""}
              isDelete
            />
          </Show.When>
        </Grid2>
      </form>
    </GKModal>
  );
};

export default DividendEditModal;
