/* eslint-disable react/no-danger */
import { LoadingButton } from "@mui/lab";
import { useAppContext } from "AppContext";
import {
  Divider,
  FiEdit,
  FormControlLabel,
  GKButton,
  Grid2,
  Loader,
  Show,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Typography,
} from "components";
import useRevenueManager from "hooks/useRevenueManager";
import DashboardLayout from "layouts/DashboardLayout";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { moneyCommaSeparator } from "utils/MathFunction";
import useDocumentTitle from "utils/useDocumentTitle";
import EditManagementFeeModal from "./EditManagementFeeModal";
import MapPortfolioModal from "./MapPortfolioModal";
import PortfoliosTab from "./PortfoliosTab";
import RevenueProductsTab from "./RevenueProductsTab";

type SubscriptionPlanType = {
  frequency?: string;
  amount?: string;
  discountAmount?: number;
  taxAmount?: string;
  withoutTaxAmount?: string;
};

type FormValues = {
  name: string;
  description: string;
  isDefault: boolean;
  status: boolean;
  totalPortfolio: string;
  totalAumMapped: string;
  totalFeeCollected: string;
  totalFeeGenerated: string;
  managementFee: {
    feeType: string;
    feeMethod: string;
    calculationBenchmark: string;
    calculationFrequency: string;
    fee: number;
    billFrequency: string;
    outOfPocketExpense: string;
    minimumFee: number;
    maximumFee: number;
    highwatermark: boolean;
    managementFeeSlab: any[];
    calculationMethod: string;
    highwatermarkAmount: number;
    softHurdle: number;
    hardHurdle: number;
    outOfPocketExpenses: number;
    productName: string;
    hsnCode: string;
  };
  performanceFee: {
    feeType: string;
    feeMethod: string;
    calculationBenchmark: string;
    calculationFrequency: string;
    fee: number;
    billFrequency: string;
    outOfPocketExpense: string;
    minimumFee: number;
    maximumFee: number;
    highwatermark: boolean;
    managementFeeSlab: any[];
    calculationMethod: string;
    highwatermarkAmount: number;
    softHurdle: boolean;
    hardHurdle: boolean;
    outOfPocketExpenses: number;
    minesManagementFee: boolean;
    productName: string;
    hsnCode: string;
    hurdle: number;
  };
  subscriptionFee?: {
    isFree: boolean;
    subscriptionPlan: SubscriptionPlanType[];
  };
  advanceDeposit?: {
    productName: string;
    hsnCode: string;
    fee: string;
  };
};

const EditFeePlan = () => {
  useDocumentTitle("Fee Plan Details");
  const {
    getFeePlanById,
    getFeePlanFetching,
    updateFeePlan,
    updateFeePlanLoading,
  } = useRevenueManager();

  const [editFeeModal, setEditFeeModal] = useState(false);

  const {
    state: { tab },
    dispatch,
  } = useAppContext();

  const [isMapPortfolioOpen, setIsMapPortfolioOpen] = useState(false);

  const form = useForm<FormValues>({
    defaultValues: {
      name: getFeePlanById?.data?.data?.name || "",
      description: "",
      isDefault: false,
      status: false,
      totalPortfolio: "",
      totalAumMapped: "",
      totalFeeCollected: "",
      totalFeeGenerated: "",
      managementFee: {
        feeType: "",
        feeMethod: "",
        calculationBenchmark: "",
        fee: 0,
        calculationFrequency: "",
        billFrequency: "",
        highwatermark: false,
        minimumFee: 0,
        maximumFee: 0,
        outOfPocketExpenses: 0,
        managementFeeSlab: [{ lowerLimit: "", upperLimit: "", slabFee: "" }],
        highwatermarkAmount: 0,
        calculationMethod: "",
        softHurdle: 0,
        hardHurdle: 0,
        productName: "",
        hsnCode: "",
      },
      performanceFee: {
        feeType: "",
        feeMethod: "",
        calculationBenchmark: "",
        fee: 0,
        calculationFrequency: "",
        billFrequency: "",
        highwatermark: false,
        minimumFee: 0,
        maximumFee: 0,
        outOfPocketExpenses: 0,
        managementFeeSlab: [{ lowerLimit: "", upperLimit: "", slabFee: "" }],
        highwatermarkAmount: 0,
        calculationMethod: "trailing",
        softHurdle: false,
        hardHurdle: true,
        minesManagementFee: false,
        productName: "",
        hsnCode: "",
        hurdle: 0,
      },
      subscriptionFee: {
        isFree: false,
        subscriptionPlan: [
          {
            frequency: "",
            amount: "",
            discountAmount: 0,
            taxAmount: "",
            withoutTaxAmount: "",
          },
        ],
      },
      advanceDeposit: {
        productName: "",
        hsnCode: "",
        fee: "",
      },
    },
    values: getFeePlanById?.data?.data,
  });

  const onSubmit = (data: FormValues | any) => updateFeePlan(data);

  return (
    <DashboardLayout title="Edit Fee Plan">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          {getFeePlanFetching && <Loader />}
          <Grid2 container spacing={1} display={"flex"} alignItems={"center"}>
            <Grid2 size={6}>
              <Typography fontSize={18} fontWeight={700}>
                {form.getValues("name")}
              </Typography>
            </Grid2>
            <Grid2
              size={6}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              gap={1}
            >
              <GKButton
                variant="outlined"
                color="dark"
                size="small"
                onClick={() => setEditFeeModal(true)}
                startIcon={<FiEdit />}
              >
                Edit
              </GKButton>
              <Divider orientation="vertical" sx={{ height: "40px" }} />
              <Controller
                name="status"
                control={form.control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px !important",
                      },
                      "&.MuiFormControlLabel-labelPlacementEnd": {
                        marginLeft: "0px !important",
                      },
                    }}
                    control={<Switch {...field} checked={field.value} />}
                    label={"Active"}
                    labelPlacement="end"
                  />
                )}
              />
              {/* <Controller
                name="isDefault"
                control={form.control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px !important",
                      },
                      "&.MuiFormControlLabel-labelPlacementEnd": {
                        marginLeft: "0px !important",
                      },
                    }}
                    control={<Switch {...field} checked={field.value} />}
                    label={"Set as Default Fee Plan"}
                  />
                )}
              /> */}
              <GKButton
                variant="contained"
                onClick={() => setIsMapPortfolioOpen(true)}
              >
                Map Portfolio
              </GKButton>{" "}
            </Grid2>
            {form.getValues("description") && (
              <Grid2 size={12} maxHeight={"150px"} overflow={"auto"}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: form.getValues("description"),
                  }}
                />
              </Grid2>
            )}
            <Grid2 size={12}>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ padding: 16, borderBottom: "none" }}>
                        <Typography fontSize={12} color={"#667588"}>
                          Total Portfolios
                        </Typography>
                        <Typography
                          fontSize={18}
                          fontWeight={600}
                          color={"#667588"}
                        >
                          {form.getValues("totalPortfolio")}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ padding: 16, borderBottom: "none" }}>
                        <Typography fontSize={12} color={"#667588"}>
                          Total AUM Mapped
                        </Typography>
                        <Typography
                          fontSize={18}
                          fontWeight={600}
                          color={"#667588"}
                        >
                          {moneyCommaSeparator(
                            +form.getValues("totalAumMapped"),
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ padding: 16, borderBottom: "none" }}>
                        <Typography fontSize={12} color={"#667588"}>
                          Total Fee Collected
                        </Typography>
                        <Typography
                          fontSize={18}
                          fontWeight={600}
                          color={"#667588"}
                        >
                          {moneyCommaSeparator(
                            +form.getValues("totalFeeCollected"),
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ padding: 16, borderBottom: "none" }}>
                        <Typography fontSize={12} color={"#667588"}>
                          Total Fee Generated
                        </Typography>
                        <Typography
                          fontSize={18}
                          fontWeight={600}
                          color={"#667588"}
                        >
                          {moneyCommaSeparator(
                            +form.getValues("totalFeeGenerated"),
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2}>
            <Grid2 size={12}>
              <Tabs
                value={tab.editFeePlanTab}
                onChange={(_, val) => {
                  dispatch({
                    type: "TAB",
                    data: { ...tab, editFeePlanTab: val },
                  });
                }}
              >
                <Tab disableRipple label="Revenue Products" />
                <Tab disableRipple label="Portfolios" />
              </Tabs>
              <Divider />
            </Grid2>
            <Grid2 size={12}>
              {tab.editFeePlanTab === 0 && (
                <RevenueProductsTab data={getFeePlanById?.data?.data} />
              )}
              {tab.editFeePlanTab === 1 && <PortfoliosTab />}
            </Grid2>

            {tab.editFeePlanTab === 0 && (
              <Grid2 size={12} display={"flex"} justifyContent={"flex-end"}>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  size="medium"
                  loading={updateFeePlanLoading}
                >
                  Save
                </LoadingButton>
              </Grid2>
            )}
          </Grid2>
        </form>
      </FormProvider>

      <Show.When isTrue={isMapPortfolioOpen}>
        <MapPortfolioModal
          open={isMapPortfolioOpen}
          setOpen={setIsMapPortfolioOpen}
          name={form.getValues("name")}
        />
      </Show.When>

      <Show.When isTrue={editFeeModal}>
        <EditManagementFeeModal
          open={editFeeModal}
          setOpen={setEditFeeModal}
          getFeePlanById={getFeePlanById}
        />
      </Show.When>
    </DashboardLayout>
  );
};

export default EditFeePlan;
